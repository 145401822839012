import React from 'react';
// import useTableSorting from '../../Hooks/useTableSorting';
// import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Loading from '../../Component/Loading';
import Filters from '../../Component/Insights/Filters';
import { formatNumber } from '../../Utils/Utils';
// import { NoRecordFound } from '../../Component/NoRecordFound';
import useServiceReports from './Hooks/useServiceReports';
// import { SelectBox } from '../../Component/Inputs';
// import { SortbyAppointment } from '../../Context/constants';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { IoFilterSharp } from 'react-icons/io5';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
const ServiceReports = () => {
    const {
        loader,
        dateFilter,
        selectClinic,
        Reports,
        // headCells,
        clinicList,
        dateRangeDropdown,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        filterPopup,
        setFilterPopup,
        customDate,
        setCustomDate,
        userdata,
        setAction,
        getServicesDetail,
        setAppointmentFilter,
        appointmentFilter,
    } = useServiceReports()
    // const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const { CustomButton } = useMuiCompontent()


    return <>
        {loader && <Loading />}
        <div className=" py-3">
            <div className="row ">
                <div className="col-12  position-relative px-0 ">
                    <div className='w-100 text-end'>
                        <CustomButton onClick={() => setFilterPopup((pre) => !pre)} className='' variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<IoFilterSharp />}  >Filters</CustomButton>
                    </div>
                    <Filters setDateRangeDropdown={setDateRangeDropdown}
                        dateFilter={dateFilter}
                        selectClinic={selectClinic}
                        setSelectClinic={setSelectClinic}
                        clinicList={clinicList}
                        setDateFilter={setDateFilter}
                        dateRangeDropdown={dateRangeDropdown}
                        page="SERVICE_REPORT"
                        filterPopup={filterPopup}
                        setFilterPopup={setFilterPopup}
                        customDate={customDate}
                        setCustomDate={setCustomDate}
                        userdata={userdata}
                        setAction={setAction}
                        getDetail={getServicesDetail}
                        appointmentFilter={appointmentFilter}
                        setAppointmentFilter={setAppointmentFilter}
                    />
                </div>
                <div className="col-12 px-0">
                    <div className="row d-flex justify-content-center" style={{ fontSize: "13px" }}>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{formatNumber(Reports?.servicesBookedCount)}</h4>
                                <p className='mb-0'>Total Bookings</p>
                            </div>
                        </div>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{`${Reports?.currencyImage ?? ""} ${formatNumber(Reports?.totalRevenue)}`}</h4>
                                <p className='mb-0'>Revenue Earned</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {
                    selectClinic[0]?.clinicId !== "ALL_CLINCI" && <>
                        <div className="col-12 px-0 mt-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='mb-0'>Top 10 Service</p>
                                <div className="col-2 ">
                                    <SelectBox
                                        placeholder="Select Discount Type"
                                        // value={formik?.values?.discountType}
                                        name="Sortby"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        // handleChange={}
                                        option={SortbyAppointment}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0">
                            <TableContainer className="text_box_height_insights " >
                                {!Reports?.report?.length &&
                                    <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /></div>
                                }
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        Reports?.report?.length ? <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={Reports?.report?.length} /> : ""
                                    }
                                    <TableBody className="table_overflow_scroll" >
                                        {stableSort(Reports?.report?.length ? Reports?.report : [], getComparator(order, orderBy)).map(
                                            (item, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow id="hover_tr"   >
                                                    <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                        {index+1}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left">Image</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.name}</TableCell>
                                                    <TableCell id={labelId} align="left"> {formatNumber(item?.totalBookings)}</TableCell>
                                                    <TableCell id={labelId} align="left"> {formatNumber(item?.totalRevenue)}</TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                       
                        </div>
                    </>
                } */}

                {
                    // selectClinic[0]?.clinicId === "ALL_CLINCI" && 
                    <>
                        <div className="col-md-6 col-12 ps-0 mt-3">
                            <div className="col shadow-sm p-4">
                                <h1 className="mb-3 " style={{ fontSize: "13px" }}>Total Booked</h1>
                                <div style={{ width: '100%', height: 320 }}>
                                    <ResponsiveContainer>
                                        <AreaChart
                                            data={Reports?.insightsServicesBooked}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="Date" />
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="count" stroke="#38C4B9" fill="#dcf2f0" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 ps-0 mt-3">
                            <div className="col shadow-sm p-4">
                                <h1 className="mb-3 " style={{ fontSize: "13px" }}>Revenue Earned</h1>
                                <div style={{ width: '100%', height: 320 }}>
                                    <ResponsiveContainer>
                                        <AreaChart
                                            data={Reports?.insightsTotalRevenue}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="Date" />
                                            <YAxis tickFormatter={(value) => formatNumber(value, 0)} />
                                            <Tooltip formatter={(value) => `${Reports?.currencyImage ?? ""} ${value}`} />
                                            <Area type="monotone" dataKey="Revenue" stroke="#38C4B9" fill="#dcf2f0" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div >
        </div >
    </>;
};

export default ServiceReports;