import React from 'react'
import { Errors } from '../../Component/Error';
import Loading from '../../Component/Loading';
import { Avatar, Badge, styled } from '@mui/material';
import useAddPatient from './Hooks/useAddPatient';
import { PatientImageFilePath } from '../../Context/api';
import PhoneNumber from '../../Component/PhoneInput';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { BloodGroupOptions, GenderOptions, MaritalStatusOptions, PatientRelationOptions, TitleOptions } from '../../Context/constants';
import { SearchAbleSelectBox, SelectBox } from '../../Component/Inputs';
import { MdOutlineCameraAlt } from 'react-icons/md';
import DeleteProfile from '../../Component/DeleteProfile';
import useUploadImage from '../../Hooks/useUploadImage';
import { FaArrowLeft } from 'react-icons/fa';
const AddPatients = ({ type }) => {
    const {
        id,
        addPatint,
        loader,
        imgUrl,
        ListDataCountry,
        ListState,
        FileRef,
        handalDOB,
        setImgUrl,
        handalSearchAbleSelectBox,
        setLoader,
        navigate
    } = useAddPatient(type)
    const { CustomButton } = useMuiCompontent()
    const { UploadImage } = useUploadImage()
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => {
                            if (!id) {
                                navigate("/patients")
                            } else {
                                navigate(`/patients/${id}/sub-members`)
                            }
                        }} />
                        {
                            !id ? <h2 className='page_name'>Add Patient</h2> : <h2 className='page_name'>Add Member</h2>
                        }
                    </div>
                </header>
                <div className="row common-white-bg-box mx-0 shadow d-flex align-content-start  position-relative px-2">

                    {/* <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Patient ID</label>
                            </div>
                            <div className="col-4">
                                <input type="text" readOnly maxLength={50} className="form-control" />
                            </div>
                        </div>
                    </div> */}
                    {
                        id && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Relation<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <SelectBox
                                        placeholder="Select Relation"
                                        value={addPatint.values.relation.trim()}
                                        name="relation"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={(e) => addPatint.setFieldValue("relation", e.target.value)}
                                        option={PatientRelationOptions}
                                    />
                                    <Errors formikfun={addPatint} name={"relation"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Relation<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SelectBox
                                                placeholder="Select Relation"
                                                value={addPatint.values.relation.trim()}
                                                name="relation"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => addPatint.setFieldValue("relation", e.target.value)}
                                                option={PatientRelationOptions}
                                            />
                                            <Errors formikfun={addPatint} name={"relation"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Title"
                                    value={addPatint.values.title.trim()}
                                    name="title"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addPatint.setFieldValue("title", e.target.value)}
                                    option={TitleOptions}
                                />
                                <Errors formikfun={addPatint} name={"title"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Title"
                                            value={addPatint.values.title.trim()}
                                            name="title"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addPatint.setFieldValue("title", e.target.value)}
                                            option={TitleOptions}
                                        />
                                        <Errors formikfun={addPatint} name={"title"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" maxLength={50} name='firstName' onChange={(e) => addPatint.setFieldValue("firstName", e.target.value)}
                                    value={addPatint.values.firstName} />
                                <Errors formikfun={addPatint} name={"firstName"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" maxLength={50} name='firstName' onChange={(e) => addPatint.setFieldValue("firstName", e.target.value)}
                                            value={addPatint.values.firstName} />
                                        <Errors formikfun={addPatint} name={"firstName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" maxLength={50} name='lastName' onChange={(e) => addPatint.setFieldValue("lastName", e.target.value)}
                                    value={addPatint.values.lastName} />
                                <Errors formikfun={addPatint} name={"lastName"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" maxLength={50} name='lastName' onChange={(e) => addPatint.setFieldValue("lastName", e.target.value)}
                                            value={addPatint.values.lastName} />
                                        <Errors formikfun={addPatint} name={"lastName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Gender"
                                    value={addPatint.values.gender}
                                    name="gender"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addPatint.setFieldValue("gender", e.target.value)}
                                    option={GenderOptions}
                                />
                                <Errors formikfun={addPatint} name={"gender"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Gender"
                                            value={addPatint.values.gender}
                                            name="gender"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addPatint.setFieldValue("gender", e.target.value)}
                                            option={GenderOptions}
                                        />
                                        <Errors formikfun={addPatint} name={"gender"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="date" className="hidden form-control position-relative"
                                    onChange={(e) => { handalDOB(e); addPatint.setFieldValue("dob", e.target.value) }}
                                    value={addPatint.values.dob}
                                    max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} />
                                <Errors formikfun={addPatint} name={"dob"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="date" className="hidden form-control position-relative"
                                            onChange={(e) => { handalDOB(e); addPatint.setFieldValue("dob", e.target.value) }}
                                            value={addPatint.values.dob}
                                            max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} />
                                        <Errors formikfun={addPatint} name={"dob"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" name='age' disabled={true} value={addPatint.values.age} />
                                <Errors formikfun={addPatint} name={"age"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" name='age' disabled={true} value={addPatint.values.age} />
                                        <Errors formikfun={addPatint} name={"age"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Blood Group"
                                    value={addPatint.values.bloodGroup}
                                    name="bloodGroup"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addPatint.setFieldValue("bloodGroup", e.target.value)}
                                    option={BloodGroupOptions}
                                />
                                <Errors formikfun={addPatint} name={"bloodGroup"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Blood Group"
                                            value={addPatint.values.bloodGroup}
                                            name="bloodGroup"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addPatint.setFieldValue("bloodGroup", e.target.value)}
                                            option={BloodGroupOptions}
                                        />
                                        <Errors formikfun={addPatint} name={"bloodGroup"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Marital Status<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Marital Status"
                                    value={addPatint.values.maritalStatus}
                                    name="maritalStatus"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addPatint.setFieldValue("maritalStatus", e.target.value)}
                                    option={MaritalStatusOptions}
                                />
                                <Errors formikfun={addPatint} name={"maritalStatus"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Marital Status<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Marital Status"
                                            value={addPatint.values.maritalStatus}
                                            name="maritalStatus"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addPatint.setFieldValue("maritalStatus", e.target.value)}
                                            option={MaritalStatusOptions}
                                        />
                                        <Errors formikfun={addPatint} name={"maritalStatus"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4 mobile_number_input_box">
                                <div className="input_group_mobile mb-0">
                                    <PhoneNumber
                                        width={false}
                                        Require={false}
                                        Name="number"
                                        HandleChange={addPatint}
                                        Value={addPatint.values.number}
                                    disabledInput={true}
                                    />
                                </div>
                                <Errors formikfun={addPatint} name={"number"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7 mobile_number_input_box">
                                        <div className="input_group_mobile mb-0">
                                            <PhoneNumber
                                                width={false}
                                                Require={false}
                                                Name="number"
                                                HandleChange={addPatint}
                                                Value={addPatint.values.number}
                                            // disabledInput={true}
                                            />
                                        </div>
                                        <Errors formikfun={addPatint} name={"number"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4 ">
                                <input type="email" className="form-control" name='email' onChange={(e) => addPatint.setFieldValue("email", e.target.value)}
                                    value={addPatint.values.email} />
                                <Errors formikfun={addPatint} name={"email"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="email" className="form-control" name='email' onChange={(e) => addPatint.setFieldValue("email", e.target.value)}
                                            value={addPatint.values.email} />
                                        <Errors formikfun={addPatint} name={"email"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  pb-2 mt-4 " >
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={addPatint.values.street}
                                    onChange={(e) => addPatint.setFieldValue(`street`, e.target.value)}
                                />
                                <Errors formikfun={addPatint} name={"street"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                                    </div>

                                    <div className="col-lg-7">
                                        <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={addPatint.values.address}
                                            onChange={(e) => addPatint.setFieldValue(`address`, e.target.value)}
                                        />
                                        <Errors formikfun={addPatint} name={"address"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-2 " >
                        {/* <div className="row">
                            <div className="col-3">
                            </div>
                            <div className="col-4">
                                <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={addPatint.values.address}
                                    onChange={(e) => addPatint.setFieldValue(`address`, e.target.value)}
                                />
                                <Errors formikfun={addPatint} name={"address"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        {/* <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label> */}
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={addPatint.values.street}
                                            onChange={(e) => addPatint.setFieldValue(`street`, e.target.value)}
                                        />
                                        <Errors formikfun={addPatint} name={"street"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-2 " >
                        {/* <div className="row">
                            <div className="col-3">
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    placeholder="Select Country"
                                    multiple={false}
                                    option={ListDataCountry}
                                    getOptionLabel="countryName"
                                    isOptionEqualToValue="id"
                                    value={addPatint?.values?.country}
                                    name="country"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={addPatint} name={"country"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        {/* <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label> */}
                                    </div>
                                    <div className="col-lg-7">
                                        <SearchAbleSelectBox
                                            placeholder="Select Country"
                                            multiple={false}
                                            option={ListDataCountry}
                                            getOptionLabel="countryName"
                                            isOptionEqualToValue="id"
                                            value={addPatint?.values?.country}
                                            name="country"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={addPatint} name={"country"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  pt-2 " >
                        {/* <div className="row">
                            <div className="col-3">
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    placeholder="Select State"
                                    multiple={false}
                                    option={ListState}
                                    getOptionLabel="stateName"
                                    isOptionEqualToValue="stateId"
                                    value={addPatint?.values?.state}
                                    name="state"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={addPatint} name={"state"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        {/* <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label> */}
                                    </div>
                                    <div className="col-lg-7">
                                        <SearchAbleSelectBox
                                            placeholder="Select State"
                                            multiple={false}
                                            option={ListState}
                                            getOptionLabel="stateName"
                                            isOptionEqualToValue="stateId"
                                            value={addPatint?.values?.state}
                                            name="state"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={addPatint} name={"state"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-2 " >
                        {/* <div className="row">
                            <div className="col-3">
                            </div>
                            <div className="col-4">
                                <input
                                    type="text"
                                    maxLength={50}
                                    className="form-control"
                                    name="city"
                                    placeholder="City"
                                    value={addPatint.values.city}
                                    onChange={(e) => addPatint.setFieldValue(`city`, e.target.value)}
                                />
                                <Errors formikfun={addPatint} name={"city"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        {/* <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label> */}
                                    </div>
                                    <div className="col-lg-7">
                                        <input
                                            type="text"
                                            maxLength={50}
                                            className="form-control"
                                            name="city"
                                            placeholder="City"
                                            value={addPatint.values.city}
                                            onChange={(e) => addPatint.setFieldValue(`city`, e.target.value)}
                                        />
                                        <Errors formikfun={addPatint} name={"city"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-2 "  >
                        {/* <div className="row">
                            <div className="col-3">
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" name="zipCode"
                                    onChange={(e) => addPatint.setFieldValue(`zipCode`, e.target.value)} value={addPatint.values.zipCode}
                                    maxLength={10}
                                    placeholder="ZIP Code"
                                />
                                <Errors formikfun={addPatint} name={"zipCode"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        {/* <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label> */}
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" name="zipCode"
                                            onChange={(e) => addPatint.setFieldValue(`zipCode`, e.target.value)} value={addPatint.values.zipCode}
                                            maxLength={10}
                                            placeholder="ZIP Code"
                                        />
                                        <Errors formikfun={addPatint} name={"zipCode"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Display Picture</label>
                            </div>
                            <div className="col-4 d-flex  align-items-start flex-column">
                                <Badge
                                    style={{ background: "#fff" }}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="Remy Sharp"  >
                                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                            </MdOutlineCameraAlt>
                                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl)}
                                            />
                                        </SmallAvatar>
                                    }
                                >
                                    <Avatar  sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                    </Avatar>
                                </Badge>
                                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Display Picture</label>
                                    </div>
                                    <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                        <Badge
                                            style={{ background: "#fff" }}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar alt="Remy Sharp"  >
                                                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                    </MdOutlineCameraAlt>
                                                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                        onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl,imgUrl)}
                                                    />
                                                </SmallAvatar>
                                            }
                                        >
                                            <Avatar  sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                            </Avatar>
                                        </Badge>
                                        {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end my-3 ">
                        <CustomButton variant="contained" onClick={addPatint.handleSubmit} >Save</CustomButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddPatients