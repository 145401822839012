import React, { createContext, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "rc-time-picker/assets/index.css";
import "./App.css";
import "../src/styles/Sidebar.css"
import "../src/styles/Loader.css"
import "../src/styles/checkedstyle.css"
import "../src/styles/SelectMultiple.css"
import "../src/styles/Credential.css"
import { useLocation } from "react-router-dom";
import AllRoutes from "./AllRoutes";
export const NotificationsState = createContext()
function App() {
  const { pathname } = useLocation()
  const splitLocation = pathname.split("/");
  const [notificationcount, setNotificationcount] = useState(0)
  const [socket, setSocket] = useState(null)
  const link = ["about", "blog", "pricing", "features", "contact"]
  return (
    <div className={`${link.includes(splitLocation[1]) && "bg-color"}`}>
      <NotificationsState.Provider value={{ setNotificationcount, notificationcount, socket, setSocket }}>
        <AllRoutes />
      </NotificationsState.Provider>
    </div>
  );
}

export default App;
