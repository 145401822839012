import {DeletefileApi, listcountry,liststatebycountry, pictureInsertAPi, specializationListWithService } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

export const COUNTRY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listcountry(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const STATE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(liststatebycountry(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPLOAD_IMAGE = async (body) => {
    try {
        let response = await axiosInstance.post(pictureInsertAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const DELETE_FILE = async (body) => {
    try {
        let response = await axiosInstance.post(DeletefileApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const SPECIALIZATION_LIST_WITH_SERVICES = async (body) => {
    try {
        let response = await axiosInstance.post(specializationListWithService(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

