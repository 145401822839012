import React from "react";
import Loading from "../../Component/Loading";
import { activeStatusOption, teamRolesOption, } from "../../Context/constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Button, TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import useTableSorting from "../../Hooks/useTableSorting";
import useTeamsList from "./Hooks/useTeamsList";
import userLogo from "../../Assets/Images/dummyimg.png"
import { addTeamDetails } from "../../Redux/ReducerFile/TeamDetails";
import { TeamImageFilePath } from "../../Context/api";
import { NoRecordFound } from "../../Component/NoRecordFound";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { FiRefreshCcw } from "react-icons/fi";
import { SelectBox } from "../../Component/Inputs";
import { ArchiveModal, ResendEmailModal } from "./Component/Team";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { BiArchiveIn } from "react-icons/bi";
import { ButtonGroup } from "react-bootstrap";


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';


const Teams = () => {
  const {
    headCells,
    loader,
    listTeams,
    paginationTotalCount,
    controller,
    showArchiv,
    archivedata,
    Filter,
    resendInvitation,
    resendInvitationFun,
    changeEmail,
    setChangeEmail,
    handleAllowLogin,
    setAnchorEl,
    setController,
    setShowArchiv,
    navigate,
    archiveUnarchive,
    dispatch,
    handleChangeFilter,
    HandleSearchList,
    setArchiveData,
    reset,
    setResendInvitation,
    handleClose,
    handleToggle,
    handleMenuItemClick,
    handleClick,
    selectedIndex,
    options,
    open,
    anchorRef,
  } = useTeamsList()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  const { CustomButton, GreenSwitch } = useMuiCompontent()





  return <>
    {loader && <Loading />}
    <div className="common-component-box">
      <header className="body_header">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="page_name">Team</h2>
        </div>
      </header>
      <div className=" common-white-bg-box mx-0 shadow px-2 d-flex justify-content-between flex-column">
        <div>
          <header className="body_header d-flex justify-content-between align-items-center py-2 ps-2">
            <ul className="headerlist px-0">
              <li className="phone_hide me-3">
                <input type="text" onChange={handleChangeFilter} value={Filter?.team} placeholder="Search" className="form-control filter_input" name="team" />
              </li>
              <li className="me-3" style={{ width: 200 }}>
                <SelectBox
                  placeholder="Select"
                  value={Filter?.archive}
                  name="archive"
                  keys="value"
                  label="label"
                  menuValeu="value"
                  handleChange={handleChangeFilter}
                  option={activeStatusOption}
                />
              </li>
              <li className="me-3" style={{ width: 200 }}>
                <SelectBox
                  placeholder="Select"
                  value={Filter?.role}
                  name="role"
                  keys="value"
                  label="label"
                  menuValeu="value"
                  handleChange={handleChangeFilter}
                  option={teamRolesOption}
                />
              </li>
              <li className="phone_hide me-3">
                <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
              </li>
              <li className="me-3 d-flex justify-content-center align-items-center" >
                <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                  <FiRefreshCcw />
                </IconButton>
              </li>
              <li className="me-3">
                <ButtonGroup
                  style={{ background: "#38C4B9", }}
                  variant="contained"
                  ref={anchorRef}
                  aria-label="Button group with a nested menu"
                >
                  <Button style={{ color: '#fff',textTransform: "capitalize"  }} onClick={handleClick}>{options[selectedIndex]}</Button>
                  <Button
                    style={{ color: '#fff',textTransform: "capitalize"  }}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  sx={{ zIndex: 1 }}
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu" autoFocusItem>
                            {options.map((option, index) => (
                              <MenuItem
                                key={option}
                                selected={index === selectedIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </li>
            </ul>
            {/* <ul className="headerlist px-0 phone_hide">
              <li>
                <CustomButton variant="contained"
                  style={{ background: "#f5f5f5", color: "black" }}
                  // onClick={() => { setFiltershow(!filtershow) }}
                  startIcon={<ExitToAppIcon />}
                >export</CustomButton>
              </li>
            </ul> */}
          </header>
          <TableContainer >
            {listTeams?.length === 0 &&
              <div className="col-sm-12 mt-3 text-center " >
                <NoRecordFound />
              </div>
            }
            <Table
              id="table_mui"
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              {
                listTeams?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={listTeams?.length} />
              }
              <TableBody className="table_overflow_scroll" >
                {stableSort(listTeams, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${row?.userId}`;
                    return <React.Fragment key={row?.userId || index}><TableRow id="hover_tr">
                      <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                        {row?.image === null ? <img alt="" src={userLogo} width={45} height={45} style={{ borderRadius: "50%" }} /> : <img alt="" src={TeamImageFilePath + row?.image} width={45} height={45} style={{ borderRadius: "50%" }} />}
                      </TableCell>
                      <TableCell id={labelId} align="left" >{row?.firstName + " " + row?.lastName}</TableCell>
                      <TableCell id={labelId} align="left">{row?.gender}</TableCell>
                      <TableCell id={labelId} align="left">{row?.mobile ? row?.mobile : "--"}</TableCell>
                      <TableCell id={labelId} align="left">{row?.email}</TableCell>
                      <TableCell id={labelId} align="left">{row?.invitationStatus}</TableCell>
                      <TableCell className="p-0 px-3" id={labelId} align="left"><GreenSwitch size="small" onChange={(e) => handleAllowLogin(e, row)} checked={row?.allowLogin} /></TableCell>
                      <TableCell className="py-0" id={labelId} align="right">
                        <MoreVertIcon
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        />
                        <ul className="dropdown-menu dropdown-menu-end me-3" >
                          <li><button className="dropdown-item" href="#"
                            onClick={() => {
                              setAnchorEl(null)
                              dispatch(addTeamDetails(row))
                              navigate(`/team/details`)
                            }} ><i className="fa-solid fa-eye me-2 all_icons_color"></i>View Details</button></li>


                          <li><button className="dropdown-item" href="#"
                            onClick={() => {
                              setAnchorEl(null)
                              setShowArchiv(true)
                              setArchiveData(row)
                            }}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus === true ? "Archive" : "Unarchive"} Member`}</button></li>



                          {
                            row?.invitationStatus === "Pending" && <li><button className="dropdown-item" href="#"
                              onClick={() => {
                                setAnchorEl(null)
                                if (row?.invitationStatus === "Pending") {
                                  setResendInvitation(true);
                                  resendInvitationFun.setFieldValue("oldEmail", row?.email);
                                  resendInvitationFun.setFieldValue("staffId", row?.userId)
                                  resendInvitationFun.setFieldValue("firstName", row?.firstName)
                                  resendInvitationFun.setFieldValue("mobile", row?.mobile)
                                }
                              }}><i className="fa-solid fa-calendar-check all_icons_color me-2"></i>Resend Invitation </button></li>
                          }
                        </ul>
                      </TableCell>
                    </TableRow>
                    </React.Fragment>
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <nav className=" w-100  " aria-label="pagination">
          {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
        </nav>
      </div>
    </div>
    <ArchiveModal showArchiv={showArchiv} setShowArchiv={setShowArchiv} archivedata={archivedata} archiveUnarchive={archiveUnarchive} CustomButton={CustomButton} />
    <ResendEmailModal resendInvitation={resendInvitation} setResendInvitation={setResendInvitation} resendInvitationFun={resendInvitationFun} CustomButton={CustomButton} changeEmail={changeEmail} setChangeEmail={setChangeEmail} />
  </>;
};

export default Teams;
