import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Fab, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material'
import Loading from '../../Component/Loading'
import { FormikProvider, useFormik } from 'formik';
import { FieldArray, ErrorMessage } from 'formik';
import { CreateCalendarFormValidation, } from '../../Context/Validation'
import moment from 'moment-timezone';
import { Errors } from '../../Component/Error'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { TimeFrameOptions } from '../../Context/constants'
import { SelectBox } from '../../Component/Inputs'
import { FaArrowLeft } from 'react-icons/fa'
import { EDIT_VACCINATION_FORM, VACCINATION_FORM_LIST } from '../Repository/VaccinationRepo'
import { IoMdAdd } from 'react-icons/io'
import { RiSubtractFill } from 'react-icons/ri'
const UpdateCalendarOverview = () => {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { CustomButton } = useMuiCompontent()
    const { vaccinationCategoryId, vaccinationFormId } = useParams()

    const getVaccinationFormDetails = async () => {
        const payload = {
            vaccinationFormId: vaccinationFormId,
        };
        setLoader(true)
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            formik.setFieldValue("vaccinationFormName", response?.data?.formName)
            formik.setFieldValue("calendarArray", response?.data?.vaccinationFormFieldArray)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    // update vaccination calendar 
    const formik = useFormik({
        validationSchema: CreateCalendarFormValidation,
        initialValues: {
            calendarArray: [],
            vaccinationFormName: ""
        },
        onSubmit: async (values) => {
            let payload = {
                categoryId: vaccinationCategoryId,
                vaccinationFormId: vaccinationFormId,
                form: values?.calendarArray,
                vaccinationFormName: values?.vaccinationFormName
            }
            setLoader(true)
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoader(false)
                navigate(`/appointment/today-agenda/overview/${vaccinationCategoryId}`)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })

    useEffect(() => {
        getVaccinationFormDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center  ">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(`/appointment/today-agenda/overview/${vaccinationCategoryId}`) }} />
                        <h2 className='page_name'>Edit Vaccination Form </h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 shadow px-3">
                    <div className="row px-0 mx-0 mb-4">
                        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Form Name<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input maxLength={50} type="text" value={formik?.values?.vaccinationFormName} onChange={(e) => formik?.setFieldValue("vaccinationFormName", e.target.value)} className="form-control" id="exampleFormControlInput1" placeholder="" />
                                    <Errors formikfun={formik} name={"vaccinationFormName"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="calendarArray"
                            render={arrayHelpers => (
                                <div>
                                    {
                                        formik?.values?.calendarArray?.map((cur, index, array) => (
                                            <div key={index}>
                                                <div className="row">
                                                    <div className="col-12 text-end" >
                                                        {
                                                            index !== 0 &&
                                                            <Tooltip title="Remove Form" placement="left">
                                                                <Fab onClick={() => arrayHelpers.remove(index)} size="small" style={{ background: "#38C4B9", color: "#fff" }} aria-label="add" className='me-2'>
                                                                    <RiSubtractFill style={{ fontSize: "20px" }} />
                                                                </Fab>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="row px-0 mx-0">
                                                    <div className="col-sm-12  pb-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Vaccination Name<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className="form-control"
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.vaccineName`, e.target.value)}
                                                                    value={formik.values?.calendarArray?.[index]?.vaccineName}
                                                                    maxLength={50}
                                                                    name={`calendarArray.${index}.vaccineName`} />
                                                                <ErrorMessage name={`calendarArray.${index}.vaccineName`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Brand Name<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="text" className="form-control"
                                                                    name={`calendarArray.${index}.brandName`}
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.brandName`, e.target.value)}
                                                                    value={formik.values?.calendarArray[index].brandName}
                                                                    maxLength={50}
                                                                />
                                                                <ErrorMessage name={`calendarArray.${index}.brandName`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Select One<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className='d-flex w-100 align-items-center ps-2' style={{
                                                                    backgroundColor: "#fff ",
                                                                    border: "2px solid #f0f0f0 ",
                                                                    height: " 90%",
                                                                    borderRadius: "5px"
                                                                }}>
                                                                    <RadioGroup
                                                                        row
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        value={formik.values?.calendarArray[index].dueType}
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue(`calendarArray.${index}.dueType`, e.target.value);
                                                                            formik.setFieldValue(`calendarArray.${index}.dueAge`, "")
                                                                            formik.setFieldValue(`calendarArray.${index}.dueAgeDuration`, "")
                                                                            formik.setFieldValue(`calendarArray.${index}.dueDate`, "")
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value="dueDate" control={<Radio size='small' />} label="Due Date" />
                                                                        <FormControlLabel value="dueAge" control={<Radio size='small' />} label="Age" />
                                                                    </RadioGroup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Given On<span className='Star_Mark'></span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="date" className="form-control position-relative" name={`calendarArray.${index}.givenOn`} id="datefield"
                                                                    value={formik.values?.calendarArray[index].givenOn ? moment(formik.values?.calendarArray[index].givenOn).format("YYYY-MM-DD") : ""}
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.givenOn`, e.target.value)}
                                                                // max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                                />
                                                                <ErrorMessage name={`calendarArray.${index}.givenOn`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Due Date<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="date" className="form-control position-relative" name={`calendarArray.${index}.dueDate`} id="datefield"
                                                                    value={formik.values?.calendarArray[index].dueDate ? moment(formik.values?.calendarArray[index].dueDate).format("YYYY-MM-DD") : ""}
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueDate`, e.target.value)}
                                                                    disabled={formik.values?.calendarArray[index].dueType === "dueDate" ? false : true}
                                                                />
                                                                <ErrorMessage name={`calendarArray.${index}.dueDate`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <input type="number" className="form-control" name={`calendarArray.${index}.dueAge`}
                                                                    maxLength={3}
                                                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueAge`, e.target.value)}
                                                                    value={formik.values?.calendarArray[index].dueAge}
                                                                    disabled={formik.values?.calendarArray[index].dueType === "dueAge" ? false : true}
                                                                />
                                                                <ErrorMessage name={`calendarArray.${index}.dueAge`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Time Frame<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <SelectBox
                                                                    placeholder="Select"
                                                                    value={formik.values?.calendarArray[index].dueAgeDuration}
                                                                    name={`calendarArray.${index}.dueAgeDuration`}
                                                                    keys="value"
                                                                    label="label"
                                                                    menuValeu="value"
                                                                    handleChange={(e) => formik.setFieldValue(`calendarArray.${index}.dueAgeDuration`, e.target.value)}
                                                                    option={TimeFrameOptions}
                                                                    disabled={formik.values?.calendarArray[index].dueType === "dueAge" ? false : true}
                                                                />
                                                                <ErrorMessage name={`calendarArray.${index}.dueAgeDuration`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <label className="form-label mb-0">Comments<span className='Star_Mark'>*</span></label>
                                                            </div>
                                                            <div className="col-4">
                                                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                                                    rows="1"
                                                                    name={`calendarArray.${index}.comment`}
                                                                    maxLength={20}
                                                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                                    onChange={(e) => formik.setFieldValue(`calendarArray.${index}.comment`, e.target.value)}
                                                                    value={formik.values?.calendarArray[index].comment}
                                                                ></textarea>
                                                                <ErrorMessage name={`calendarArray.${index}.comment`}>{msg => <div className="error">{msg}</div>}</ErrorMessage>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex justify-content-end mt-3 ">
                                                    {
                                                        array?.length === index + 1 &&

                                                        <Tooltip title="Add Form" placement="left">
                                                            <Fab onClick={() => arrayHelpers.push({ vaccineName: "", brandName: "", givenOn: "", dueDate: "", dueAge: "", comment: "", dueType: "dueDate", dueAgeDuration: "" })} size="small" style={{ background: "#38C4B9", color: "#fff" }} aria-label="add" className='me-2'>
                                                                <IoMdAdd style={{ fontSize: "20px" }} />
                                                            </Fab>
                                                        </Tooltip>

                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className=" row  mt-4  pe-3 ">
                                        <div className="col-12 d-flex justify-content-end pb-4">
                                            <CustomButton className='' variant="contained" onClick={formik.handleSubmit} >update</CustomButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </FormikProvider>
                </div>
            </div>
        </>
    )
}

export default UpdateCalendarOverview
