import React from "react";
import { Chip, } from "@mui/material";
import Loading from "../../Component/Loading";
import { Modal } from "react-bootstrap";
import useAddSpecialization from "./Hooks/useAddSpecialization";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { FaArrowLeft } from "react-icons/fa";

const AddSpecialization = ({ type }) => {
    const {
        loader,
        openModal,
        SpecializationData,
        id,
        UserData,
        allselect,
        handleChange,
        handleSubmit,
        navigate,
    } = useAddSpecialization(type)
    const { CustomButton } = useMuiCompontent()
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => {
                            if (!id) {
                                navigate("/profile/addClinic")
                                if (type !== "LOGIN" && UserData.userId) {
                                    navigate("/profile/addClinic")
                                } else {
                                    navigate("/alldetails/business-details/addClinic")
                                }
                            } else {
                                navigate(`/profile/clinic/edit/${id}`)
                            }
                        }} />
                        <h2 className="page_name">Specialization & Service</h2>
                    </div>
                </header>
                <div className=" common-white-bg-box  d-flex  flex-column justify-content-between  mx-0 pt-2 px-2  shadow pb-3">
                    <div className="">
                        {
                            SpecializationData?.map((item, specializationIndex) => {
                                if (item?.services?.length > 0) {
                                    return <React.Fragment key={specializationIndex}>
                                        <div className="col-12 Specialization_list_with_service px-3 ">
                                            <div className="Specialization_box justify-content-between">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor={"jjj"}>
                                                        {item?.specializationName}
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="form-check-label me-2" htmlFor={item?.specializationId}>
                                                        Select All
                                                    </label>
                                                    <input className="form-check-input" type="checkbox" id={item?.specializationId}
                                                        checked={item?.services?.length > 0 ? item.services.every((check) => check.isChecked) : false} onChange={(e) => allselect(e, item.specializationId, specializationIndex)}
                                                    />
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                            <div className="services_box">
                                                {
                                                    item?.services?.map((cur, serviceIndex) => {
                                                        return <Chip key={serviceIndex} label={cur?.serviceName}
                                                            style={{ color: ` ${cur?.isChecked ? "#fff" : "#000"}`, background: ` ${cur?.isChecked ? "#12BABA" : "#ebebeb"}`, cursor: "pointer" }}
                                                            className="mb-2"
                                                            onClick={() => handleChange(cur, item?.specializationId, serviceIndex, specializationIndex)}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>

                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                    <div className="d-flex justify-content-end pt-5 ">
                        {
                            SpecializationData?.length > 0 && <CustomButton variant="contained" className="" onClick={handleSubmit} > Save</CustomButton>
                        }
                    </div>
                </div>
            </div>
            <Modal show={openModal} centered>
                <Modal.Body className="px-0">
                    <div className="modal-body text-center cus-i">
                        <i className="fa-solid fa-ban mb-3 all_icons_color mt-2 ms-2"></i>
                        <p className="mb-0" style={{ color: "black", fontSize: "23px" }}>No Specialization Details Found</p>
                        {
                            type !== "LOGIN" && <>
                                <p className="mb-0" style={{ color: "gray", fontSize: "15px" }}>If you want add Specialization & service , Please </p>
                                <p style={{ color: "gray", fontSize: "15px" }}> click on this link <span onClick={() => navigate("/expertise")} style={{ color: "#333483", cursor: 'pointer', textDecoration: "underline" }}>#add specialization </span></p>
                            </>
                        }
                        {type !== "LOGIN" ? <CustomButton variant="contained" onClick={() => navigate("/profile/addClinic")} >Back Page</CustomButton> :
                            <CustomButton variant="contained" className="mt-4" onClick={() => navigate("/alldetails/business-details/addClinic")} >Back Page</CustomButton>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddSpecialization;
