import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import { contactInfoValidation, pattern, personalInfoValidation } from '../../../../Context/Validation';
import { teamProof } from '../../../../Context/api';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import useReduxData from '../../../../Hooks/useReduxData';
import { addUserData, updateStaffProfile } from '../../../../Redux/ReducerFile/UserData';
import useUploadImage from '../../../../Hooks/useUploadImage';
import { EDIT_CONTACT_INFO, EDIT_PERSONAL_INFO, EDUCATION_LIST, GET_CONTACT_INFO_DETAILS, GET_PERESANAL_INFO_DETAILS, SPECIALIZATION_LIST_WITH_SERVICES_STAFF } from '../../../Repository/StaffRepo';
import { COUNTRY_LIST, STATE_LIST } from '../../../Repository/CommonRepo';
import { CLINIC_LIST, DELETE_ACCOUNT, USER_SWITCH_PROFILE_LIST } from '../../../Repository/ProfileRepo';
import { RoleTitle, commonMessages } from '../../../../Context/constants';
import { TIMEZONE } from '../../../../Utils/Utils';

const useTeamProfile = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [showform, setShowForm] = useState(1)
    const [ListState, setListState] = useState([])
    const [ListDataCountry, setListDataCountry] = useState([])
    const [alldegreeList, setAlldegreeList] = useState([1, 2])
    const [proofData, setProofData] = useState('')
    const [specializationVlaue, setSpecializationVlaue] = useState([])
    const [switchProfileList, setSwitchProfileList] = useState([])
    const [ChooseLocationList, setChooseLocationList] = useState([])
    const [chooseprofileData, setChooseprofileData] = useState(null)
    const [chooseLocationData, setChooseLocationData] = useState(null)
    const [clinicList, setClinicList] = useState([])
    const [loader, setLoader] = useState(false)
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedCount] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoadingClinicList, setIsLoadingClinicList] = useState(true)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [SpecializationList, setSpecializationList] = useState([])
    const [deleteAccount, setDeleteAccount] = useState(false)
    const [isProfile, setIsProfile] = useState(true)
    const [openModal, setOpenModal] = useState({
        user: false,
        clinic: false,
        data: null
    })
    const navigate = useNavigate()
    const [viewPDF, setViewPDF] = useState(false)
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const { reduxData: UserData, dispatch } = useReduxData("userDataRDX")
    const { UploadImage } = useUploadImage()
    const FileRef = useRef()
    useEffect(() => {
        listcountrys()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (showform === 2) {
            getPeresanalInfo()
        } if (showform === 3) {
            getEducationList()
        }
        if (showform === 4) {
            getContactInfo()
        }
        if (showform === 5) {
            getservicesList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showform])
    useEffect(() => {
        if (clinicList.length === 0 && selectedPage === 1) {
            getListproviderclinics(selectedPage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);

    const handleScroll = (e) => {
        const target = e.target;
        if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
            if (selectedPage !== totalPages) {
                setSelectedPage((prevPage) => {
                    const newPage = prevPage + 1;
                    setIsLoadingClinicList(true)
                    getListproviderclinics(newPage);
                    return newPage;
                });
            }
        }
    };
    //  user switch profile list
    const getUserSwitchProfileList = async () => {
        setLoader(true)
        const payload = {
            roleTitle: UserData?.roleTitle,
            providerId: UserData?.providerId,
            staffId: UserData?.userId
        };
        setLoader(true)
        try {
            let response = await USER_SWITCH_PROFILE_LIST(payload)
            setSwitchProfileList(response.data)
            setLoader(false)
        } catch (error) {
            setSwitchProfileList([])
            toast.error(error.message)
            setLoader(false)
        }
    }
    // get choose location
    const getChooseLocation = async () => {
        const payload = {
            activeStatus: true,
            providerId: (chooseprofileData?.roleTitle === RoleTitle?.STAFF||chooseprofileData?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofileData?.providerId : chooseprofileData?.userId,
            staffId: (chooseprofileData?.roleTitle === RoleTitle?.STAFF||chooseprofileData?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofileData.userId : undefined,
            roleTitle: chooseprofileData?.roleTitle
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setChooseLocationList(response.data)
        } catch (error) {
            setChooseLocationList([])
        } finally {
            setLoader(false)
        }
    }
    // get list state
    const getStateList = async (countryId, StateId) => {
        let payload = {
            countryId: countryId,
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
            if (countryId && StateId) {
                const StateDetails = response.data.filter((cur) => cur?.stateId === StateId)
                contactInfoFormik.setFieldValue("state", StateDetails)
            }

        } catch (error) {
            setListState([])
            toast.error(error.message)
            contactInfoFormik.setFieldValue("state", [])
        } finally {
            setLoader(false)
        }
    }
    // get list counry api
    async function listcountrys(countryId) {
        let payload = {
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
            if (countryId) {
                const countryDetails = response.data.filter((cur) => cur?.id === countryId)
                contactInfoFormik.setFieldValue("country", countryDetails)
            }
        } catch (error) {
            setListDataCountry([])
        } finally {
            setLoader(false)
        }
    }
    //  get provider clinic clist 
    const getListproviderclinics = async (newPage) => {
        const payload = {
            activeStatus: null,
            page: newPage,
            count: selectedCount,
            staffId: (UserData.roleTitle === RoleTitle.STAFF || UserData.roleTitle === RoleTitle.FRONT_DESK) ? UserData?.userId : undefined,
            providerId: (UserData.roleTitle === RoleTitle.STAFF || UserData.roleTitle === RoleTitle.FRONT_DESK) ? UserData?.providerId : UserData?.userId,
            roleTitle: UserData.roleTitle,
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList((pre) => [...pre, ...response.data])
            let totalPages = Math.ceil(response.totalCount / selectedCount)
            setTotalPages(totalPages)
            setLoader(false)
        } catch (error) {
            setClinicList([])
            setLoader(false)
        }
    }
    // get peresanal info
    const getPeresanalInfo = async () => {
        let payload = {
            staffId: UserData?.userId
        }
        setLoader(true)
        try {
            let response = await GET_PERESANAL_INFO_DETAILS(payload)
            peresanalInfoFormik.setValues(response.data)
            setImgUrl(response.data?.image)
            setLoader(false)

        } catch (error) {
            toast.error(error.message);
            setLoader(false)
        }
    }
    // get contact details
    let getContactInfo = async () => {
        let payload = {
            staffId: UserData?.userId
        }
        setLoader(true)
        try {
            let response = await GET_CONTACT_INFO_DETAILS(payload)
            contactInfoFormik.setValues(response.data)
            contactInfoFormik.setFieldValue("country", [])
            contactInfoFormik.setFieldValue("state", [])
            setImgUrl(response.data?.image)
            setLoader(false)
            if (response?.data?.countryId) {
                listcountrys(response?.data?.countryId)
            }
            if (response?.data?.countryId && response?.data?.stateId) {
                getStateList(response?.data?.countryId, response?.data?.stateId)
            }
        } catch (error) {
            toast.error(error.message);
            setLoader(false)
        }
    }
    //get education List
    let getEducationList = async () => {
        let payload = {
            staffId: UserData?.userId
        }
        setLoader(true)
        try {
            let response = await EDUCATION_LIST(payload)
            setAlldegreeList(response?.data)
            setLoader(false)
        } catch (error) {
            setAlldegreeList([])
            setLoader(false)
        }
    }
    // get list services  Api
    const getservicesList = async () => {
        setLoader(true)
        const payload = {
            roleTitle: RoleTitle.STAFF,
            staffId: UserData?.userId,
            providerId: UserData?.providerId,
        };

        try {
            let response = await SPECIALIZATION_LIST_WITH_SERVICES_STAFF(payload)
            setSpecializationList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setSpecializationList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }

    }
    // handal change birthDate
    const handalDOB = (e) => {
        peresanalInfoFormik.setFieldValue("dob", e.target.value)
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        peresanalInfoFormik.setFieldValue("age", age ?? "")
    }
    //add Peresonal Information
    const peresanalInfoFormik = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            age: "",
            bloodGroup: "",
            language: "",
            experience: ""
        },
        validationSchema: personalInfoValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (UserData?.roleTitle === RoleTitle?.STAFF) {
                if (values?.experience === undefined || values?.experience === null || values.experience === '') {
                    errors.experience = "Please enter experience";
                } else if (Number(values.experience) < 0) {
                    errors.experience = "Experience must be zero or greater";
                } else {
                    delete errors.experience;
                }
            }
            return errors;
        },
        onSubmit: async (value) => {
            let { experience, activeStatus, providerIdOfStaff, userId, deletedStatus, roleId, roleName, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: providerIdOfStaff,
                staffId: userId,
                image: imgUrl,
                dob: moment(value.dob).format("YYYY-MM-DD"),
                experience: UserData?.roleTitle === RoleTitle?.STAFF ? experience : undefined,
                roleTitle: UserData?.roleTitle
            }
            setLoader(true)
            try {
                let response = await EDIT_PERSONAL_INFO(payload)
                toast.success(response?.message)
                setLoader(false)
                dispatch(updateStaffProfile(payload))
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    //Contact Information
    const contactInfoFormik = useFormik({
        initialValues: {
            mobile: "",
            email: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zip: "",
        },
        validationSchema: contactInfoValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (value) => {
            let { authId, activeStatus, providerIdOfStaff, userId, deletedStatus, roleId, roleName, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: providerIdOfStaff,
                staffId: userId,
                state: value?.state[0]?.stateId,
                country: value?.country?.[0]?.id
            }
            setLoader(true)
            try {
                let response = await EDIT_CONTACT_INFO(payload)
                toast.success(response?.message)
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }

    })
    const handleCloseAccount = async (event) => {
        if (event === "YES") {
            const payload = {
                providerId: UserData?.userId,
                filter: UserData?.roleTitle
            }
            setLoader(true)
            try {
                let response = await DELETE_ACCOUNT(payload)
                toast.success(response.message)
                setTimeout(() => {
                    dispatch(addUserData({}));
                    localStorage.clear();
                    navigate('/')
                }, 1500)
                setLoader(false)
                setDeleteAccount(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }

        } else {
            setDeleteAccount(false)
        }
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            getStateList(newValue?.[0]?.id, name)
            contactInfoFormik.setFieldValue(name, newValue)
            contactInfoFormik.setFieldValue("state", [])
        } else {
            contactInfoFormik.setFieldValue(name, newValue)
        }
    }
    const nextLocationTab = () => {
        if (chooseprofileData?.status === 412) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 206) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 1006) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 403) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 500) {
            return toast.error(commonMessages.NetworkError);
        }
        setIsProfile(false)
        getChooseLocation()
    }
    const handleChooseLocation = (data) => {
        // if(UserData?.location?.clinicId === data?.clinicId)return 
        setChooseLocationData(data)
        setChooseprofileData((pre) => ({
            ...pre, location: {
                clinicId: data?.clinicId,
                clinicName: data?.clinicName
            }
        }))
    }
    const handleSwitchProfile = () => {
        if (!chooseprofileData.timezoneName) {
            chooseprofileData.timezoneName = TIMEZONE()
        }
        dispatch(addUserData(chooseprofileData))
        navigate("/dashboard")
    }
    const handleChooseProfile = (data) => {
        // if (UserData.userId === data.userId) return
        setChooseprofileData(data)
    }
    const handleClosedModal = (type) => {
        if (type === "user") {
            setChooseprofileData(null)
        }
        if (type === "clinic") {
            setChooseLocationData(null)
            setChooseprofileData((pre) => {
                const { location, ...preData } = pre;
                return preData;
            });
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleOpenModal = (type, data) => {
        if (type === "user") {
            getUserSwitchProfileList()
            setChooseprofileData(null)
            setChooseLocationData(null)
            setIsProfile(true)
        }
        setOpenModal((prev) => ({
            ...prev,
            data,
            [type]: true,
        }));
    };

    const backToProfileList = () => {
        setIsProfile(true)
        setChooseLocationData(null)
        setChooseprofileData((pre) => {
            const { location, ...preData } = pre;
            return preData;
        });
    }

    const headCells = [
        { id: "degree", numeric: false, disablePadding: true, label: "Degree" },
        { id: "university", numeric: true, disablePadding: false, label: "University", showIcon: true },
        { id: "yearOfPassing", numeric: true, disablePadding: false, label: "Year of Passing", showIcon: true },
        { id: "registrationNumber", numeric: true, disablePadding: false, label: "Registration Number ", showIcon: true },
        { id: "Document", numeric: false, disablePadding: false, label: "Document", },
        { id: "Action", numeric: false, disablePadding: false, label: "Action", showLeft: true },
    ];
    const headCellsServices = [
        { id: "image", numeric: false, disablePadding: true, label: "#" },
        { id: "specializationName", numeric: true, disablePadding: false, label: "Specialization" },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services" },
        // { id: "Status", numeric: true, disablePadding: false, showLeft: true, label: "Status" },
    ];
    return {
        UploadImage,
        handalDOB,
        setViewPDF,
        setProofData,
        navigate,
        setLoader,
        handalSearchAbleSelectBox,
        setSpecializationVlaue,
        setImgUrl,
        setShowForm,
        handleScroll,
        dispatch,
        setController,
        setDeleteAccount,
        handleCloseAccount,
        handleChooseProfile,
        setChooseLocationData,
        handleClosedModal,
        handleOpenModal,
        handleChooseLocation,
        handleSwitchProfile,
        backToProfileList,
        getChooseLocation,
        setIsProfile,
        nextLocationTab,
        isProfile,
        switchProfileList,
        openModal,
        SpecializationList,
        chooseprofileData,
        chooseLocationData,
        ChooseLocationList,
        paginationTotalCount,
        controller,
        deleteAccount,
        headCellsServices,
        headCells,
        isLoadingClinicList,
        clinicList,
        UserData,
        alldegreeList,
        loader,
        imgUrl,
        showform,
        peresanalInfoFormik,
        specializationVlaue,
        contactInfoFormik,
        ListState,
        proofData,
        ListDataCountry,
        teamProof,
        StaffDetails,
        viewPDF,
        FileRef,
    }
}

export default useTeamProfile
