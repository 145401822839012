import { Tooltip } from '@mui/material';
import React from 'react'
import useDeleteFile from '../Hooks/useDeleteFile';
const DeleteProfile = ({ setImgUrl, deletedData, isDeleted, setIsEditGroup = () => { } }) => {
    const { deleteFileFun } = useDeleteFile()
    return (
        <>
            <Tooltip title="Delete" placement="right" style={{ cursor: "pointer" }}>
                {/* <span className="delete_icon_for_image" onClick={() => { deleteFileFun(setImgUrl, deletedData, isDeleted) }}><i className="fa-solid fa-trash-can all_icons_color" ></i></span> */}
                <span className="delete_icon_for_image all_icons_color mt-2 ms-3 cursor text-danger" onClick={() => { deleteFileFun(setImgUrl, deletedData, isDeleted, setIsEditGroup) }}>Delete</span>
            </Tooltip>
        </>
    )
}

export default DeleteProfile