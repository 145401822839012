import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading";
import moment from "moment-timezone";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { Errors } from "../../Component/Error";
import { SelectBox } from "../../Component/Inputs";
import { useFormik } from "formik";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { TimeFrameOptions } from "../../Context/constants";
import { VaccinationFormVaidation } from "../../Context/Validation";
import { UPDATE_VACCINATION_CALENDAR_PATIENT, VACCINATION_CALENDAR_PATIENT } from "../Repository/PatientRepo";
import { FaArrowLeft } from "react-icons/fa";

const VaccineFormUpdate = ({ type }) => {
    const [loader, setLoader] = useState(false)
    const { id, formId, subpatientId } = useParams()
    const { CustomButton } = useMuiCompontent()
    const navigate = useNavigate()
    const getVaccinationDetails = async () => {
        let payload = {
            vaccinationFormFieldId: Number(formId)
        }
        setLoader(true)
        try {
            let response = await VACCINATION_CALENDAR_PATIENT(payload)
            formik.setValues(response?.data[0])
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }
    // add given date 
    const formik = useFormik({
        initialValues: {
            formName: "",
            vaccineName: "",
            brandName: "",
            givenOn: "",
            dueDate: "",
            dueAge: '',
            dueAgeDuration: "",
            comment: "",
            dueType: ""
        },
        validationSchema: VaccinationFormVaidation,
        onSubmit: async (values) => {
            const { appointmentEndDate, appointmentStartDate, appointmentNumber, doctor, formId, vaccinationFormFieldId, ...restValue } = values
            let payload = {
                ...restValue,
                vaccinationFormFieldId,
                formId
            }
            setLoader(true)
            try {
                let response = await UPDATE_VACCINATION_CALENDAR_PATIENT(payload)
                toast.success(response.message)
                setLoader(false)
                if (type === "Patients") {
                    navigate(`/patients/${id}/vaccine-calendar`)
                } else {
                    navigate(`/patients/${id}/sub-members/${subpatientId}/vaccine-calendar`)
                }
            } catch (error) {
                setLoader(false)
                toast.error(error.message)
            }

        }
    })
    useEffect(() => {
        getVaccinationDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => {
                            if (type === "Patients") {
                                navigate(`/patients/${id}/vaccine-calendar`)
                            } else {
                                navigate(`/patients/${id}/sub-members/${subpatientId}/vaccine-calendar`)
                            }
                        }} />
                        <h2 className='page_name'>Vaccination Details</h2>
                    </div>
                </header>
                <div className="row common-white-bg-box mx-0 shadow d-flex align-content-start  position-relative px-2">
                    <div className="col-sm-12 mt-3 pb-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Vaccination Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control"
                                    onChange={(e) => formik.setFieldValue(`vaccineName`, e.target.value)}
                                    value={formik.values?.vaccineName}
                                    maxLength={50}
                                    name={`vaccineName`} />
                                <Errors name={"vaccineName"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Brand Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control"
                                    name={`brandName`}
                                    onChange={(e) => formik.setFieldValue(`brandName`, e.target.value)}
                                    value={formik.values?.brandName}
                                    maxLength={50}
                                />
                                <Errors name={"brandName"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Given On<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="date" className="form-control position-relative" name={`givenOn`} id="datefield"
                                    value={formik.values?.givenOn ? moment(formik.values?.givenOn).format("YYYY-MM-DD") : ""}
                                    onChange={(e) => formik.setFieldValue(`givenOn`, e.target.value)}
                                    max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} />
                                <Errors name={"givenOn"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Select One<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <div className='d-flex w-100 align-items-center ps-2' style={{
                                    backgroundColor: "#fff ",
                                    border: "2px solid #f0f0f0 ",
                                    height: " 90%",
                                    borderRadius: "5px"
                                }}>

                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={formik.values?.dueType}
                                        onChange={(e) => {
                                            formik.setFieldValue(`dueType`, e.target.value);
                                            formik.setFieldValue(`dueDate`, "")
                                            formik.setFieldValue(`dueAgeDuration`, "")
                                            formik.setFieldValue(`dueDate`, "")
                                        }}
                                    >
                                        <FormControlLabel value="dueDate" control={<Radio size='small' />} label="Due Date" />
                                        <FormControlLabel value="dueAge" control={<Radio size='small' />} label="Age" />
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Due Date<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="date" className="form-control position-relative" name={`dueDate`} id="datefield"
                                    value={formik.values?.dueDate ? moment(formik.values?.dueDate).format("YYYY-MM-DD") : ""}
                                    onChange={(e) => formik.setFieldValue(`dueDate`, e.target.value)}
                                    disabled={formik.values?.dueType === "dueDate" ? false : true}
                                />
                                <Errors name={"dueDate"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="number" className="form-control" name={`dueAge`}
                                    maxLength={3}
                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                    onChange={(e) => formik.setFieldValue(`dueAge`, e.target.value)}
                                    value={formik.values?.dueAge}
                                    disabled={formik.values?.dueType === "dueAge" ? false : true}
                                />
                                <Errors name={"dueAge"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Time Frame<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select"
                                    value={formik.values?.dueAgeDuration}
                                    name={`dueAgeDuration`}
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => formik.setFieldValue(`dueAgeDuration`, e.target.value)}
                                    option={TimeFrameOptions}
                                    disabled={formik.values?.dueType === "dueAge" ? false : true}
                                />
                                <Errors name={"dueAgeDuration"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Comments<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <textarea className="form-control" id="exampleFormControlTextarea1"
                                    rows="1"
                                    name={`comment`}
                                    maxLength={20}
                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                    onChange={(e) => formik.setFieldValue(`comment`, e.target.value)}
                                    value={formik.values?.comment}
                                ></textarea>
                                <Errors name={"comment"} formikfun={formik} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end my-3 ">
                        <CustomButton variant="outlined" className="me-2" onClick={() => navigate(type === "Patients" ? `/patients/${id}/vaccine-calendar` : `/patients/${id}/sub-members/${subpatientId}/vaccine-calendar`)}>cancel</CustomButton>
                        <CustomButton variant="contained" onClick={formik.handleSubmit} >Update</CustomButton>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VaccineFormUpdate;
