import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone';
import { useReactToPrint } from 'react-to-print';
import Loading from '../../Component/Loading';
import { Tooltip } from '@mui/material';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { GET_EPRESCRIPTION_DETAILS } from '../Repository/PatientRepo';
import { useNavigate, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Dosage } from '../../Utils/Utils';

const EPrescription = ({ type }) => {
    const [ePrescriptionList, setEPrescriptionList] = useState([])
    const [loader, setLoader] = useState(false)
    const componentRef = useRef();
    const { CustomButton } = useMuiCompontent()
    const { id, prescriptionNumber, subpatientId } = useParams()
    const navigate = useNavigate()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        getListSelectedMedicines()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getListSelectedMedicines = async () => {
        setLoader(true)
        let payload = {
            prescriptionNumber: prescriptionNumber
        }
        try {
            let response = await GET_EPRESCRIPTION_DETAILS(payload)
            setEPrescriptionList(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setEPrescriptionList([])
            setLoader(false)
        }
    }
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor"
                            onClick={() => {
                                if (type === "Patients") {
                                    navigate(`/patients/${id}/e-prescription`)
                                } else {
                                    navigate(`/patients/${id}/sub-members/${subpatientId}/e-prescription`)
                                }
                            }} />
                        <h2 className='page_name'>E-prescription</h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 shadow position-relative px-2">
                    <div className="row mx-0 px-0">
                        <div className="col-3 ms-auto d-flex justify-content-end pe-0 mb-2">
                            <Tooltip title=" Download PDF " placement="left" style={{ cursor: "pointer" }}>
                                <CustomButton
                                    onClick={handlePrint}
                                    variant="contained" >print</CustomButton>
                            </Tooltip>
                        </div>
                        <div className=" tbl-border p-0 mx-0" ref={componentRef}>
                            <div className="m-0 ps-3">
                                <h1 className="rx p-0 m-0">Rx</h1>
                            </div>
                            <div className="d-flex justify-content-between p-4">
                                <div className="col-lg-4">
                                    <div className="botm-border">
                                        <p className="p-0 m-0 mb-1">
                                            <b>Patient Name :</b> <span> {(ePrescriptionList?.[0]?.patientFirstName ? ePrescriptionList?.[0]?.patientFirstName : "") + " " + (ePrescriptionList?.[0]?.patientLastName ? ePrescriptionList?.[0]?.patientLastName : "")}</span>
                                        </p>
                                    </div>
                                    <div className="botm-border mt-2">
                                        <p className="p-0 m-0 mb-1"><b>Age :</b> <span> {ePrescriptionList?.[0]?.age} y</span></p>
                                    </div>
                                    <div className="botm-border mt-2">
                                        <p className="p-0 m-0 mb-1"><b>Gender :</b> <span> {ePrescriptionList?.[0]?.gender}</span></p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="botm-border">
                                        <p className="p-0 m-0 mb-1">
                                            <b>Prescription Number :</b> <span> {ePrescriptionList?.[0]?.prescriptionNumber}</span>
                                        </p>
                                    </div>
                                    <div className="botm-border mt-2">
                                        <p className="p-0 m-0 mb-1"><b>Date :</b> <span> {moment(ePrescriptionList?.[0]?.startDate).format("DD/MM/YYYY")}</span></p>
                                    </div>

                                </div>
                                <div className="col-lg-1"></div>
                            </div>
                            <div className="p-4 " style={{ height: "400px" }}>
                                <table className="table table-bordered tbl-border bootstrap_table_responsive">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Medicine</th>
                                            <th >Medicine type</th>
                                            <th>Strength</th>
                                            <th>Standard</th>
                                            <th>Duration</th>
                                            <th>Dosage Instructions</th>
                                            <th>Quantity</th>
                                            <th>Notes</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            ePrescriptionList?.map((item, index) => {
                                                return <tr>
                                                    <th>{index + 1}</th>
                                                    <td>{item?.medicineName}</td>
                                                    <td>{item?.medicineType}</td>
                                                    <td>{item?.doseAmount + item?.doseType}</td>
                                                    {/* <td>{` ${item?.medicineIntakeType === "STANDARD" ? `${item?.morningMedCount}-${item?.afternoonMedCount}-${item?.nightMedCount}` : `Every ${item?.medicineIntakeDurationAmount + item?.customMedCountTimeFrame}`} `}</td> */}
                                                    <td>{Dosage(item)}</td>
                                                    <td>{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</td>
                                                    <td> {item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</td>
                                                    <td>{item?.medicineIntakeAmount}</td>
                                                    <td>{item?.notes ? item?.notes : "N/A"}</td>
                                                </tr>

                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EPrescription