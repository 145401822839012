import React from "react";
import { Errors } from "../../Component/Error";
import Loading from "../../Component/Loading";
import useChangePin from "./Hooks/useChangePin";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const ChangePin = () => {
  const {
    formik,
    loader,
    showPassword,
    handleClickShowPassword
  } = useChangePin()
  const { CustomButton } = useMuiCompontent()

  return <>
    {loader && <Loading />}
    <div className=" d-flex justify-content-between flex-column" style={{ height: "100%" }}>
      <div className="row px-3">
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Old PIN<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4 ">
              <div className="col position-relative">
                <input type={showPassword.oldPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' name="street1" value={formik.values.oldpin}
                  onChange={(e) => formik.setFieldValue("oldpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("oldPin")}>{showPassword.oldPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"oldpin"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                <label className="form-label mb-0">Old PIN<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                <div className="col position-relative">
                <input type={showPassword.oldPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' name="street1" value={formik.values.oldpin}
                  onChange={(e) => formik.setFieldValue("oldpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("oldPin")}>{showPassword.oldPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"oldpin"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">New PIN<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4 ">
              <div className="col position-relative">
                <input type={showPassword.newPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' value={formik.values.newpin}
                  onChange={(e) => formik.setFieldValue("newpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("newPin")}>{showPassword.newPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"newpin"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                <label className="form-label mb-0">New PIN<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                <div className="col position-relative">
                <input type={showPassword.newPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' value={formik.values.newpin}
                  onChange={(e) => formik.setFieldValue("newpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("newPin")}>{showPassword.newPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"newpin"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Confirm New PIN<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4 ">
              <div className="col position-relative">
                <input type={showPassword.confirmPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' value={formik.values.confirmpin}
                  onChange={(e) => formik.setFieldValue("confirmpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("confirmPin")}>{showPassword.confirmPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"confirmpin"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                <label className="form-label mb-0">Confirm New PIN<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                <div className="col position-relative">
                <input type={showPassword.confirmPin ? 'text' : 'password'} maxLength={4} className="form-control" placeholder='****' value={formik.values.confirmpin}
                  onChange={(e) => formik.setFieldValue("confirmpin", e.target.value)}
                  onInput={(e) => {
                    if (e.target.value.length > 4) {
                      e.target.value = e.target.value.slice(0, 4);
                    }
                  }}
                />
                <span className="HIDEN-ICON" onClick={() => handleClickShowPassword("confirmPin")}>{showPassword.confirmPin ? <VisibilityOff className="filter_icons" /> : <Visibility className="filter_icons" />}</span>
              </div>
              <Errors formikfun={formik} name={"confirmpin"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-2">
          <CustomButton onClick={formik.handleSubmit} variant="contained" type="button" style={{ background: "#38C4B9", padding: "6px 8px", fontSize: "13px" }}> Save</CustomButton>
        </div>
      </div>
    </div>


  </>
};

export default ChangePin;
