import { useState, useEffect } from 'react'
import moment from "moment-timezone";
import { RoleTitle } from '../../../Context/constants';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { addUnavailabilityValidation } from '../../../Context/Validation';
import useReduxData from '../../../Hooks/useReduxData';
import useMinimumDate from '../../../Hooks/useMinimumDate';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLINIC_LIST } from '../../Repository/ProfileRepo';
import { TIME_ZONE_LIST } from '../../Repository/SettingRepo';
import { DELETE_UN_AVAILABILITY, INSERT_UN_AVAILABILITY, UN_AVAILABILITY_LIST } from '../../Repository/AvailabilityRepo';

const useUnavailability = () => {
    const [loader, setLoader] = useState(false)
    const [clinicModal, setClinicModal] = useState(false)
    const [UnavailabilityAdd, SetUnavailabilityAdd] = useState(false);
    const [clinicList, setClinicList] = useState([])
    const [unavilabilityList, setUnavilabilityList] = useState([])
    const [listTimeZone, setListTimeZone] = useState([])
    const [UnavailabilityDetele, SetUnavailabilityDetele] = useState(false);
    const [deletesdata, setDeleteData] = useState({})
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const isFrontDeskProfile = StaffDetails?.roleId === RoleTitle?.FRONT_DESK
    const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
    const { minimumdate } = useMinimumDate()
    const navigate = useNavigate()
    const [unAvailabilityDefault, updateDefaultUnAvailability] = useState({
        arrayOfTimings: [
            {
                startTime: "",
                endTime: "",
            },
        ],
    },
    )

    const { pathname } = useLocation();
    const splitLocation = pathname.split("/");
    useEffect(() => {
        if (isFrontDeskProfile) {
            navigate("/team/details/profile")
        }
        getlistUnavailability()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // add time unAvailability
    const addtimesUnavailability = (day) => {
        let tempArr = unAvailabilityDefault;
        if (tempArr.arrayOfTimings.length > 2)
            return toast.warn("Timings can not be more than 3");
        tempArr.arrayOfTimings.push({
            startTime: "",
            endTime: "",
        });
        updateDefaultUnAvailability({ ...tempArr });
    };
    // close time unavailability button
    const removetimeUnavailability = (index) => {
        let tempArr = unAvailabilityDefault;
        tempArr.arrayOfTimings.splice(index, 1);
        updateDefaultUnAvailability({ ...tempArr });
    };
    // get list clinic 
    const getListproviderclinics = async () => {
        const payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            activeStatus: true,
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
        } catch (error) {
            setClinicList([])
            toast.error(error?.message)
        } finally {
            setLoader(false)
        }
    }
    // get time zone
    const getlistTimeZone = async () => {
        setLoader(true)
        const payload = {
            activeStatus: true,
        };
        try {
            let response = await TIME_ZONE_LIST(payload)
            setListTimeZone(response.data)
            if (userdata?.timezoneName) {
                let timeZoneDetails = response?.data?.filter((cur, index) => cur?.timezone === userdata?.timezoneName)
                addUnavailabililty.setFieldValue("timeZone", timeZoneDetails)
            }
        } catch (error) {
            toast.error(error.message)
            setListTimeZone([])
        }
        finally {
            setLoader(false)
        }
    }
    // get list unavailbility 
    const getlistUnavailability = async () => {
        setLoader(true)
        let payload = {
            // providerId: userdata.roleTitle === RoleTitle?.SERVICE_PROVIDER ? userdata.userId : undefined,
            staffId: StaffDetails?.userId,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            roleTitle: userdata?.roleTitle,
            clinicId: userdata?.location?.clinicId
        }
        try {
            let response = await UN_AVAILABILITY_LIST(payload)
            setUnavilabilityList(response?.data)
            setLoader(false)
        } catch (error) {
            setUnavilabilityList([])
            setLoader(false)
        }
    }
    //removeSelection 3 items
    const removeSelection = (index) => {
        addUnavailabililty.values[`EndTime${index}`] = undefined
        addUnavailabililty.values[`StartTime${index}`] = undefined
    }

    const setTime = (Start_Time, End_Time, timeZone) => {
        let startTime = moment(Start_Time).format('HH:mm').split(':')
        let endTime = moment(End_Time).format('HH:mm').split(':')
        let startDateTime = moment.tz(Start_Time, timeZone).set({ h: startTime[0], minute: startTime[1], second: 0 })
        let endDateTime = moment.tz(End_Time, timeZone).set({ h: endTime[0], minute: endTime[1], second: 0 })
        return { startDateTime, endDateTime }
    }

    // add unavailability 
    const addUnavailabililty = useFormik({
        initialValues: {
            timeZone: [],
            fromDate: "",
            toDate: "",
            StartTime0: "",
            EndTime0: "",
            StartTime1: "",
            EndTime1: "",
            StartTime2: "",
            EndTime2: "",
        },
        validationSchema: addUnavailabilityValidation,
        onSubmit: async (values) => {
            if ((values.fromDate > values.toDate) && (values.fromDate !== values.toDate)) return toast.warn(" Start date can not be greater than end date ");
            let data =
            {
                clinicId: userdata?.location?.clinicId,
                timeZone: values?.timeZone?.[0]?.timezone,
                // startDate: moment(values.fromDate).startOf('day').utc(),
                // endDate: moment(values.toDate).endOf('day').utc(),
                startDate: moment.tz(values.fromDate, values?.timeZone?.[0]?.timezone).startOf('day').utc(),
                endDate: moment.tz(values.toDate, values?.timeZone?.[0]?.timezone).endOf('day').utc(),
                providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                roleTitle: userdata?.roleTitle,
                staffId: StaffDetails?.userId,
                arrayOfTimings: [],
            }
            if (unAvailabilityDefault?.arrayOfTimings.length === 1 && (values.StartTime0.length === 0 || values.EndTime0.length === 0)) {
                return toast.warn("Please enter time")
            }
            if (new Date(values.StartTime0).getTime() === new Date(values.EndTime0).getTime())
                return toast.warn("Start time and  end time can not be equal");
            if (values.StartTime0 > values.EndTime0)
                return toast.warn("Start time can not be greater than end time ");
            if (values.StartTime0 && values.EndTime0) {
                const { startDateTime, endDateTime } = setTime(values.StartTime0, values.EndTime0, values?.timeZone?.[0]?.timezone)
                data.arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (unAvailabilityDefault?.arrayOfTimings.length === 2 && (values.StartTime1.length === 0 || values.EndTime1.length === 0)) {
                return toast.warn("Please enter time")
            }
            if (new Date(values.StartTime1).getTime() === new Date(values.EndTime1).getTime())
                return toast.warn("Start time and  end time can not be equal");
            if (values.StartTime1 > values.EndTime1)
                return toast.warn(
                    "Start time  can not be greater than end  time "
                );
            if (values.StartTime1 && values.EndTime1) {
                const { startDateTime, endDateTime } = setTime(values.StartTime1, values.EndTime1, values?.timeZone?.[0]?.timezone)
                data.arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            if (unAvailabilityDefault?.arrayOfTimings.length === 3 && (values.StartTime2.length === 0 || values.EndTime2.length === 0)) {
                return toast.warn("Please enter time")
            }
            if (new Date(values.StartTime2).getTime() === new Date(values.EndTime2).getTime())
                return toast.warn("Start time and  end time can not be equal");
            if (values.StartTime2 > values.EndTime2)
                return toast.warn(
                    "Start time  can not be greater than end  time "
                );
            if (values.StartTime2 && values.EndTime2) {
                const { startDateTime, endDateTime } = setTime(values.StartTime2, values.EndTime2, values?.timeZone?.[0]?.timezone)
                data.arrayOfTimings.push({
                    startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
                    endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
                });
            }
            setLoader(true)
            try {
                let response = await INSERT_UN_AVAILABILITY(data)
                setLoader(false)
                toast.success(response.message)
                addUnavailabililty.resetForm()
                updateDefaultUnAvailability({ arrayOfTimings: [{ startTime: "", endTime: "" }] })
                SetUnavailabilityAdd(false)
                getlistUnavailability()
                navigate("/team/details/unavailability")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })

    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "timeZone") {
            addUnavailabililty.setFieldValue(name, newValue)
        } else {
            addUnavailabililty.setFieldValue(name, newValue)
        }
    }
    // delete unavailability
    const handleClose = async (event) => {
        if (event === "YES") {
            const data = { unavailabilityId: deletesdata.id, }
            setLoader(true)
            try {
                let response = await DELETE_UN_AVAILABILITY(data)
                setLoader(false)
                toast.success(response.message)
                SetUnavailabilityDetele(false)
                getlistUnavailability()
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        } else {
            SetUnavailabilityDetele(false)
        }
    }
    useEffect(() => {
        if (splitLocation[splitLocation?.length - 1] === "set-unavailability") {
            getListproviderclinics();
            getlistTimeZone()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        isFrontDeskPermission,
        loader,
        unavilabilityList,
        UnavailabilityAdd,
        addUnavailabililty,
        clinicList,
        clinicModal,
        minimumdate,
        UnavailabilityDetele,
        unAvailabilityDefault,
        userdata,
        removeSelection,
        SetUnavailabilityDetele,
        getListproviderclinics,
        removetimeUnavailability,
        setDeleteData,
        setClinicModal,
        SetUnavailabilityAdd,
        addtimesUnavailability,
        handleClose, handalSearchAbleSelectBox, listTimeZone, getlistTimeZone, navigate
    }
}

export default useUnavailability
