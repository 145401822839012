import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Errors } from '../../Component/Error';
import Loading from '../../Component/Loading';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import useAddTeam from './Hooks/useAddTeam';
import { TeamImageFilePath } from '../../Context/api';
import PhoneNumber from '../../Component/PhoneInput';
import { BloodGroupOptions, GenderOptions_TEAM, TitleOptions } from '../../Context/constants';
import { SearchAbleSelectBox, SelectBox } from '../../Component/Inputs';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Chip, styled, Tooltip, } from '@mui/material';
import { MdOutlineCameraAlt } from 'react-icons/md';
// import DeleteProfile from '../../Component/DeleteProfile';
import { FaArrowLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
const AddTeams = () => {
    const {
        loader,
        imgUrl,
        addTeams,
        clinicList,
        // servicesList,
        FileRef,
        UploadImage,
        handalDOB,
        setImgUrl,
        handalSearchAbleSelectBox,
        setLoader,
        RemoveUploadFiles,
        clinicWithServicesList,
        handleChangeServices,
        allselect,
        handleChangeCinicSwitch,
        isFrontDeskPage
    } = useAddTeam()
    const { GreenSwitch, CustomButton } = useMuiCompontent()
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };




    const navigate = useNavigate()

    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">

                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => {navigate("/team")}} />
                        <h2 className='page_name'> {isFrontDeskPage ? "Add Front Desk" : " Add Team"}</h2>
                    </div>
                </header>
                <div className=" px-3 row common-white-bg-box mx-0 shadow ">
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Display Picture</label>
                                    </div>
                                    <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                        <Badge
                                            style={{ background: "#fff" }}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar alt="Remy Sharp"  >
                                                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                    </MdOutlineCameraAlt>
                                                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                        onChange={(e) => UploadImage(e, FileRef, "TEAM", setLoader, setImgUrl, imgUrl)}
                                                    />
                                                </SmallAvatar>
                                            }
                                        >
                                            <Avatar sx={{ width: 80, height: 80 }} src={TeamImageFilePath + imgUrl} >
                                            </Avatar>
                                        </Badge>
                                        {/* {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: null }} isDeleted={true} />} */}
                                        {imgUrl &&
                                            <Tooltip title="Delete" placement="right" style={{ cursor: "pointer" }}>
                                                <span className="delete_icon_for_image all_icons_color mt-2 ms-3 cursor text-danger" onClick={() => { RemoveUploadFiles("SUBMIT_HARD_DELETE"); }} >Delete</span>
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Title"
                                            value={addTeams?.values?.title.trim()}
                                            name="title"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addTeams.setFieldValue("title", e.target.value)}
                                            option={TitleOptions}
                                        />
                                        <Errors formikfun={addTeams} name={"title"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input maxLength={50} type="text" className="form-control" name='firstName' onChange={(e) => addTeams.setFieldValue("firstName", e.target.value)}
                                            value={addTeams.values.firstName}
                                        />
                                        <Errors formikfun={addTeams} name={"firstName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input maxLength={50} type="text" className="form-control" name='lastName' onChange={(e) => addTeams.setFieldValue("lastName", e.target.value)}
                                            value={addTeams.values.lastName}
                                        />
                                        <Errors formikfun={addTeams} name={"lastName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Gender"
                                            value={addTeams.values.gender}
                                            name="gender"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addTeams.setFieldValue("gender", e.target.value)}
                                            option={GenderOptions_TEAM}
                                        />
                                        <Errors formikfun={addTeams} name={"gender"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="date" className="form-control position-relative" name='dob' onChange={handalDOB} value={addTeams.values.dob}
                                            max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                        />
                                        <Errors formikfun={addTeams} name={"dob"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="number" className="form-control" name='age' disabled={true} value={addTeams.values.age} />
                                        <Errors formikfun={addTeams} name={"age"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Blood Group"
                                            value={addTeams.values.bloodGroup}
                                            name="bloodGroup"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => addTeams.setFieldValue("bloodGroup", e.target.value)}
                                            option={BloodGroupOptions}
                                        />
                                        <Errors formikfun={addTeams} name={"bloodGroup"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7 mobile_number_input_box">
                                        <div className="input_group_mobile mb-0">
                                            <PhoneNumber
                                                width={false}
                                                Require={false}
                                                Name="number"
                                                HandleChange={addTeams}
                                                Value={addTeams.values.number}
                                            // disabledInput={true}
                                            />
                                        </div>
                                        <Errors formikfun={addTeams} name={"number"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Email address<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="email" className="form-control" id="exampleFormControlInput1" name='email'
                                            value={addTeams.values.email}
                                            onChange={(e) => addTeams.setFieldValue(`email`, e.target.value)} />
                                        <Errors formikfun={addTeams} name={"email"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    {
                        isFrontDeskPage && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SearchAbleSelectBox
                                                multiple={true}
                                                option={clinicList}
                                                getOptionLabel="clinicName"
                                                isOptionEqualToValue="clinicId"
                                                value={addTeams?.values?.clinicId}
                                                name="clinicId"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={addTeams} name={"clinicId"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                    }

                    {/* <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SearchAbleSelectBox
                                            groupBy='specializationName'
                                            multiple={true}
                                            option={servicesList}
                                            getOptionLabel="serviceName"
                                            isOptionEqualToValue="serviceId"
                                            value={addTeams?.values?.services}
                                            name="services"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={addTeams} name={"services"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div> */}

                    {
                        !isFrontDeskPage &&
                        <div className="col-sm-12  py-3 " >
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Clinic & Services<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className='col-9 ps-0 ps-1 pe-0'>

                                            {
                                                clinicWithServicesList?.map((cur) => {
                                                    return <Accordion key={cur?.clinicId} expanded={expanded === `panel${cur?.clinicId}`} onChange={handleChange(`panel${cur?.clinicId}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${cur?.clinicId}bh-content`}
                                                            id={`panel${cur?.clinicId}bh-header`}
                                                        >
                                                            <div className='d-flex justify-content-between w-100 align-items-center'>
                                                                {cur?.clinicName}
                                                                <GreenSwitch
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    inputProps={{ 'aria-label': `Panel ${cur?.clinicId} switch` }}
                                                                    checked={cur?.isChecked}
                                                                    onChange={(e) => handleChangeCinicSwitch(e, cur?.clinicId)}
                                                                />
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            {
                                                                cur?.specializationArray?.map((item, specializationIndex) => {
                                                                    if (item?.services?.length > 0) {
                                                                        return <React.Fragment key={specializationIndex}>
                                                                            <div className="col-12 Specialization_list_with_service px-3 ">
                                                                                <div className="Specialization_box justify-content-between">
                                                                                    <div className="form-check">
                                                                                        <label className="form-check-label" htmlFor={"jjj"}>
                                                                                            {item?.specializationName}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="form-check-label me-2" htmlFor={item?.specializationId}>
                                                                                            Select All
                                                                                        </label>
                                                                                        <input className="form-check-input cursor" type="checkbox" id={item?.specializationId}
                                                                                            checked={item?.services?.length > 0 ? item.services.every((check) => check.isChecked) : false}
                                                                                            onChange={(e) => allselect(e, cur?.clinicId, item.specializationId)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <hr className="mt-0" />
                                                                                <div className="services_box">
                                                                                    {
                                                                                        item?.services?.map((servicesItem) => {
                                                                                            return <Chip key={servicesItem?.serviceId} label={servicesItem?.serviceName}
                                                                                                style={{ color: ` ${servicesItem?.isChecked ? "#fff" : "#000"}`, background: ` ${servicesItem?.isChecked ? "#12BABA" : "#ebebeb"}`, cursor: "pointer" }}
                                                                                                className="mb-2"
                                                                                                onClick={() => handleChangeServices(servicesItem?.isChecked, cur?.clinicId, item.specializationId, servicesItem?.serviceId)}
                                                                                            />
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>
                                                                    } else {
                                                                        return null
                                                                    }
                                                                })
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                })
                                            }



                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end px-0 py-3">
                        <CustomButton variant="contained" onClick={addTeams.handleSubmit} >Save</CustomButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTeams
