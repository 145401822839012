import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import useReduxData from "../../../Hooks/useReduxData";
import { STAFF_LOCATION_LIST, UPDATE_STAFF_LOCATION } from "../../Repository/StaffRepo";
import { useNavigate } from "react-router-dom";
import { RoleTitle } from "../../../Context/constants";

const useTeamLocation = () => {
    const [LocationListdata, setLocationListdata] = useState([])
    const [active] = useState(false)
    const [searchvalue] = useState('')
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [loader, setLoader] = useState(false)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const navigate = useNavigate()
    const isFrontDeskProfile = StaffDetails?.roleId === RoleTitle?.FRONT_DESK
    const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
    useEffect(() => {
        getLocationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active, searchvalue, controller])
    // get list location  Api
    const getLocationList = async () => {
        setLoader(true)
        let payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            staffId: StaffDetails?.userId,
            count: controller.rowsPerPage,
            page: controller.page
        }
        try {
            let response = await STAFF_LOCATION_LIST(payload)
            setLocationListdata(response.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setLocationListdata([])
            setLoader(false)
            toast.error(error?.message)
            setPaginationTotalCount(0)
        }
    }
    // statuschange
    const statuschange = async (checked, Item) => {
        let payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            staffId: StaffDetails?.userId,
            isChecked: checked,
            clinicId: Item?.clinicId
        }
        setLoader(true)
        try {
            let response = await UPDATE_STAFF_LOCATION(payload)
            toast.success(response?.message)
            getLocationList()
            setLoader(false)
        } catch (error) {
            toast.error(error?.message)
            setLoader(false)
        }
    }
    return {
        loader,
        LocationListdata,
        controller,
        paginationTotalCount,
        setController,
        statuschange,
        navigate,
        isFrontDeskProfile,
        isFrontDeskPermission
    }
}

export default useTeamLocation

