import React, { useState, useEffect } from 'react'
import moment from "moment-timezone";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { RoleTitle } from '../../Context/constants';
import { Avatar, Tooltip } from '@mui/material';
import useReduxData from '../../Hooks/useReduxData';
import Loading from '../../Component/Loading';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { Modal } from 'react-bootstrap';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { IoMdAdd } from 'react-icons/io';
import { motion } from 'framer-motion';
import { AVAILABILITY_LIST, DELETE_AVAILABILITY } from '../Repository/AvailabilityRepo';
import { clinicPictures } from '../../Context/api';

const TeamsAvailability = () => {
  const navigate = useNavigate();
  const [avilabilityList, setAvilabilityList] = useState([])
  const [loader, setLoader] = useState(true)
  const [deleteAvailabilityModal, setDeleteAvailabilityModal] = useState(false)
  const [deleteAvailabilityDetials, setDeleteAvailabilityDetails] = useState(null)
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
  const { CustomButton } = useMuiCompontent()
  const isFrontDeskProfile = StaffDetails?.roleId === RoleTitle?.FRONT_DESK
  useEffect(() => {
    if (isFrontDeskProfile) {
      navigate("/team/details/profile")
    }
    getListAvailability()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get list availability
  const getListAvailability = async () => {
    setLoader(true)
    let payload = {
      staffId: StaffDetails.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      roleTitle: userdata?.roleTitle,
      clinicId: userdata?.location?.clinicId
    }
    try {
      let response = await AVAILABILITY_LIST(payload)
      setAvilabilityList(response?.data)
      setLoader(false)
    } catch (error) {
      setAvilabilityList([])
      setLoader(false)
    }
  }
  // delete availability
  const deleteAvailabilityFun = async (event) => {
    if (event === "YES") {
      const data = { availabilityId: deleteAvailabilityDetials?.id, }
      setLoader(true)
      try {
        let response = await DELETE_AVAILABILITY(data)
        setLoader(false)
        toast.success(response.message)
        setDeleteAvailabilityModal(false)
        getListAvailability()
        setDeleteAvailabilityDetails(null)
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    } else {
      setDeleteAvailabilityModal(false)
    }
  }
  return (
    <>
      {loader && <Loading />}
      <motion.div className=' py-3' style={{ height: "100%" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.7 }}
      >
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <CustomButton startIcon={<IoMdAdd />} variant="contained" onClick={() => navigate(`/team/availability/set-availability`)} > Set Availability</CustomButton>
          </div>
        </header>
        <div className="col-lg-12 ">
          <div className="row availability__">
            {avilabilityList?.length === 0 &&
              <div className="col-sm-12 mt-3 text-center" >
                <NoRecordFound />
              </div>
            }
            {
              avilabilityList?.map((cur, index) => {
                return <React.Fragment key={cur?.id || index}>
                  <div className="col-sx-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 availability__box  pt-2 mb-5">
                    <div className="shadow  p-2 rounded"  >
                      <div className="d-flex justify-content-between align-items-center py-2 w-100" style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="d-flex align-items-center"  style={{ width: 'calc(100% - 20px)' }}>
                          <Avatar src={clinicPictures + cur?.clinicImage} className="me-2" sx={{ width: 40, height: 40 }} />
                          <div style={{ width: 'calc(100% - 40px)' }}>
                            <h5 className="mb-0 location TEXT_ELLIPSIS me-3"> {`${cur.clinicName.charAt(0).toUpperCase()}${cur.clinicName.slice(1)}`} </h5>
                            <h5 className="mb-0 location TEXT_ELLIPSIS me-3">{`${cur.city.charAt(0).toUpperCase()}${cur.city.slice(1)}`}</h5>
                          </div>
                        </div>
                        <div>
                          <Tooltip className="me-2" title="Delete" placement="left" style={{ cursor: "pointer" }}>
                            <i className="fa-solid fa-trash-can all_icons_color"
                              onClick={() => { setDeleteAvailabilityModal(true); setDeleteAvailabilityDetails(cur) }}
                            ></i>
                          </Tooltip>
                          <Tooltip title="Edit" placement="top" style={{ cursor: "pointer" }}>
                            <i className="fa-solid fa-pen-to-square all_icons_color" onClick={() => { navigate(`/team/availability/${cur.id}`, { state: { clinciId: cur.clinicId, clinicName: cur.clinicName, oldFromDate: cur?.startDate, oldToDate: cur?.EndDate } }) }} ></i>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="d-flex py-2" style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="col-6">
                          <ul>
                            <li><h5 className="mb-0 location">Start Date</h5></li>
                            <li> <p className="time_zone mb-1">{`${moment(cur.startDate)?.tz(userdata?.timezoneName)?.format('ll')} `}</p></li>
                          </ul>
                        </div>
                        <div className="col-6 d-flex flex-column align-items-end  ">
                          <ul>
                            <li><h5 className="mb-0 location text-end">End Date</h5></li>
                            <li> <p className="time_zone mb-1">{`${moment(cur.EndDate)?.tz(userdata?.timezoneName)?.format('ll')} `}</p></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-   AVAILABILITY-TIME-OVERFLOW">
                        {
                          cur.availableDays?.map((availableDays, availableDaysIndex) => {
                            return <React.Fragment key={availableDays?.id || availableDaysIndex}>
                              <div className="row availability_time_day mx-0 px-0 mb-2 mt-2">
                                <div className="col-3 day"><p>{availableDays?.name}</p></div>
                                <div className="col-9 time">
                                  <ul>
                                    {availableDays?.isChecked ?
                                      availableDays?.arrayOfTimings?.map((time, index2) => {
                                        return <li key={time?.availabilityDayId || index}>{`${moment(time?.startTime)?.tz(userdata?.timezoneName)?.format("hh:mm A")}-${moment(time?.endTime)?.tz(userdata?.timezoneName)?.format("hh:mm A")}`}</li>
                                      }) : <li>Closed</li>
                                    }
                                  </ul>
                                </div>
                              </div>
                            </React.Fragment>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              })
            }
          </div>
        </div>
      </motion.div>
      {/* delete modal availability  */}
      <Modal show={deleteAvailabilityModal} onHide={() => setDeleteAvailabilityModal(false)} centered>
        <Modal.Body>
          <div className="modal-body text-center cus-i">
            <i className="fa-solid fa-trash-can mb-4 all_icons_color"></i>
            <p className='m-0'>Are you sure?</p>
            <p>You want to delete this availability</p>
            <CustomButton variant="contained" className="me-3" onClick={() => deleteAvailabilityFun("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => deleteAvailabilityFun("NO")}> No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TeamsAvailability