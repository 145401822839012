
import React from "react";
import { useNavigate } from "react-router-dom";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import useReduxData from "../../Hooks/useReduxData";
const PrivateErrorPage = () => {
    const navigate = useNavigate()
    const { CustomButton } = useMuiCompontent()
    const { reduxData: userdata } = useReduxData("userDataRDX")
    return <>
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 d-flex align-items-center pt-5">
                        <div>
                            <div className="col-lg-12">
                                <h1 style={{ color: "#2b124c", fontSize: "5.5rem" }} >
                                    <b>Oops!</b>
                                </h1>
                            </div>
                            <div className="col-lg-12">
                                <p className="fs-3">
                                    We can't seem to find what you are looking for.
                                </p>
                            </div>
                            <div className="mt-5 d-flex">
                                <CustomButton variant="contained" className="ms-2" onClick={() => { navigate("/") }}>{`Go Back ${userdata?.userId ? "Dashboard" : "Home"}`}</CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 pt-5 d-flex align-items-end">
                        <img src={require("../../Assets/Images/animation-example.gif")} className="img-fluid mt-5" alt="" />
                    </div>
                </div>
            </div>
        </>
    </>;
};

export default PrivateErrorPage;
