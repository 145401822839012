import React from 'react'

const TextBox = (props) => {
  return (
    <div className='w-100 TEXT-BOX'>
        {props?.textBoxData}
    </div>
  )
}

export default TextBox