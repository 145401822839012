import React, { useState } from 'react';

const ReadMoreButton = ({ text, maxLength, className }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            {isExpanded ? (
                <>
                    {text}
                    <button className='border-0 bg-body text-primary fw-6 ms-1' onClick={(e) => { e.stopPropagation(); setIsExpanded(!isExpanded) }}>Read Less</button>
                </>
            ) : (
                <>
                    <span className={className}> {text.slice(0, maxLength)} </span>
                    {text.length > maxLength && (
                        <>  <span>...</span> <button className='border-0 bg-body text-primary fw-6' onClick={(e) => { e.stopPropagation(); setIsExpanded(!isExpanded) }}>Read More</button> </>
                    )}
                </>
            )}
        </>
    );
};

export default ReadMoreButton;