import React, { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading";
import axios from 'axios';
import { ticketAddApi } from "../../../Context/api";
import useReduxData from "../../../Hooks/useReduxData";
import { RoleTitle, commonMessages,  } from "../../../Context/constants";
import { Buffer } from "buffer";
import { useFormik } from "formik";
import { createTicketValidation } from "../../../Context/Validation";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { Errors } from '../../../Component/Error';
import { SelectBox } from "../../../Component/Inputs";
import PdfImage from '../../../Assets/Images/home/pdf.svg';
import xlsImage from "../../../Assets/Images/xlsx.png"
import textImage from "../../../Assets/Images/text-file.webp"
import DocImage from "../../../Assets/Images/doc.png"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PriorityOptions } from '../../../Context/constants';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import useDragDrop from "../../../Hooks/useDrag&Drop";
import { projectId } from "../../../Context/envoirment";
import { FaArrowLeft } from "react-icons/fa";
const AddTicket = () => {

    const [loader, setLoader] = useState(false)
    const [attachmentFile, setAttachFile] = useState([])
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const navigate = useNavigate()
    const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragDrop()
    let encodeRequest = (input) => {
        let salt = "123";
        const buffer = Buffer.from(salt, "utf-8");
        const base64EncodedSalt = buffer.toString("base64");
        let encodePayload = Buffer.from(JSON.stringify(input), "utf-8").toString("base64");
        let encodeHash = Buffer.from("####", "utf-8").toString("base64");
        let delimiter = "@@@";
        let base64Payload = encodePayload + delimiter + encodeHash + delimiter + base64EncodedSalt;
        return base64Payload;
    };
    const handleFileChange = (files) => {
        const data = Array.from(files);
        const temp = [...attachmentFile]
        for (const file of data) {
            if (data.length > 5) return toast.warn("You can select only 5 items")
            if ((data.length + attachmentFile.length) > 5) return toast.warn("You can select only 5 items")
            temp.push({ path: URL.createObjectURL(file), file: file });
        }
        setAttachFile(temp)
    };
    const addTicket = useFormik({
        initialValues: {
            subject: "",
            description: "",
            priority: ""
        },
        validationSchema: createTicketValidation,
        onSubmit: (values) => {
            let date = moment().format();
            const formData = new FormData();
            formData.append("project_id", projectId);
            formData.append("reportDate", date);
            formData.append("source", "ONLINE");
            formData.append("subject", values.subject);
            formData.append("description", values.description);
            formData.append("priority", values.priority);
            formData.append("parent_id", userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata.userId.toString() : userdata?.providerId.toString());
            formData.append("reportedBy", JSON.stringify({
                id: userdata?.userId,
                firstName: userdata?.firstName,
                lastName: userdata?.lastName,
                email: userdata?.email,
            })
            );
            formData.append("type", "TICKET");
            let formDataObject = {};
            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }
            const formDataObjectRR = JSON.parse(formDataObject.reportedBy);
            formDataObject.reportedBy = formDataObjectRR
            const newFormData = new FormData();
            attachmentFile.forEach(element => {
                newFormData.append("files", element.file)
            });
            newFormData.append("input", encodeRequest(formDataObject));
            setLoader(true);
            axios.post(ticketAddApi(), newFormData).then((resp) => {
                if (resp.data.code === 200) {
                    toast.success(resp.data.message);
                    addTicket.resetForm();
                    setAttachFile([])
                    navigate("/setting/help-desk")
                } else {
                    toast.error(resp.data.message);
                }
                setLoader(false);
            })
                .catch(() => {
                    toast.error(commonMessages.NetworkError);
                    setLoader(false);
                });
        },
    });
    const DeleteAttachmentFile = (index) => {
        setAttachFile((pre) => {
            let temp = [...pre]
            temp.splice(index, 1)
            return temp
        })
    }


    return <>
        {loader && <Loading />}
        <div className="common-component-box">
            <header className="body_header">
                <div className="d-flex  align-items-center w-100 ">
                    <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/setting/help-desk")} />
                    <h2 className="page_name"> Create Tickets</h2>
                </div>
            </header>
            <div className="p-2 common-white-bg-box mx-0 shadow d-flex justify-content-between flex-column">
                <div className="row">
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Subject<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input value={addTicket?.values?.subject}  onChange={(e) => addTicket.setFieldValue("subject", e.target.value)} type="text" className="form-control" />
                                <Errors formikfun={addTicket} name={"subject"} />
                            </div>
                        </div> */}
                        <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-5">
                                <label className="form-label mb-0">Subject<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-lg-7">
                                <input value={addTicket?.values?.subject} onChange={(e) => addTicket.setFieldValue("subject", e.target.value)} type="text" className="form-control" />
                                <Errors formikfun={addTicket} name={"subject"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        </div>
                    </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Priority<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Priority"
                                    value={addTicket?.values?.priority}
                                    name="priority"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addTicket.setFieldValue("priority", e.target.value)}
                                    option={PriorityOptions}
                                />
                                <Errors formikfun={addTicket} name={"priority"} />
                            </div>
                        </div> */}
                        <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-5">
                                <label className="form-label mb-0">Priority<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-lg-7">
                                <SelectBox
                                    placeholder="Select Priority"
                                    value={addTicket?.values?.priority}
                                    name="priority"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => addTicket.setFieldValue("priority", e.target.value)}
                                    option={PriorityOptions}
                                />
                                <Errors formikfun={addTicket} name={"priority"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        </div>
                    </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <CKEditor
                                    editor={ClassicEditor}
                                    name='description'
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        addTicket.setFieldValue("description", data)
                                    }}
                                    data={addTicket.values?.description}
                                />
                                <Errors formikfun={addTicket} name={"description"} />
                            </div>
                        </div> */}
                        <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-5">
                                <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-lg-7">
                                <CKEditor
                                    editor={ClassicEditor}
                                    name='description'
                                    onChange={(event, editor) => {
                                        const data = editor.getData()
                                        addTicket.setFieldValue("description", data)
                                    }}
                                    data={addTicket.values?.description}
                                />
                                <Errors formikfun={addTicket} name={"description"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        </div>
                    </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Attachment<span className='Star_Mark'>*</span></label>
                                <p style={{ color: "gray", fontSize: "13px" }}>You con upload up to 5 Image  (jpeg and Png) and Media File (mp4)</p>
                            </div>
                            <div className="col-4">
                                <div className="bodrerer" style={{ border: `2px dashed #aaaaaa`, maxWidth: "100%" }}
                                      onDragEnter={handleDragEnter}
                                      onDragLeave={handleDragLeave}
                                      onDragOver={handleDragOver}
                                      onDrop={(e) => handleDrop(e, handleFileChange,"multiple")}
                                >
                                    <label htmlFor="inputTag">
                                        <AiOutlineCloudUpload className="fs-4" />
                                        <input id="inputTag" type="file" name="file" multiple onChange={(event) => handleFileChange(event.target.files)} />
                                        <br />
                                        <p className="mb-0">Drag & Drop To Upload</p>
                                        <br />
                                        <span id="imageName"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-4">
                                
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                    <label className="form-label mb-0">Attachment</label>
                                    <p style={{ color: "gray", fontSize: "13px" }}>You con upload up to 5 Image  (jpeg and Png) and Media File (mp4)</p>
                                    </div>
                                    <div className="col-lg-7 d-flex justify-content-between flex-column align-items-center">
                                    <div className="bodrerer" style={{ border: `2px dashed #aaaaaa`, maxWidth: "100%" }}
                                      onDragEnter={handleDragEnter}
                                      onDragLeave={handleDragLeave}
                                      onDragOver={handleDragOver}
                                      onDrop={(e) => handleDrop(e, handleFileChange,"multiple")}
                                >
                                    <label htmlFor="inputTag">
                                        <AiOutlineCloudUpload className="fs-4" />
                                        <input id="inputTag" type="file" name="file" multiple onChange={(event) => handleFileChange(event.target.files)} />
                                        <br />
                                        <p className="mb-0">Drag & Drop To Upload</p>
                                        <br />
                                        <span id="imageName"></span>
                                    </label>
                                </div>
                        </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-12 Ticket_Attachment mb-3 pt-3">
                        <div className="row">
                            {[...attachmentFile].filter(cur => {
                                const filetype = cur?.file.name.split('.').pop();
                                return filetype === "mp4" || filetype === "wmv" || filetype === "mov";
                            }).map((cur, index) => (
                                <div className="col-3 mb-3 mx-0 position-relative" key={index}>
                                    <video src={cur?.path} alt="" autoPlay controls></video>
                                    <span onClick={() => DeleteAttachmentFile(index)}><IoMdCloseCircleOutline /></span>
                                </div>
                            ))}
                            {[...attachmentFile].filter(cur => {
                                const filetype = cur?.file.name.split('.').pop();
                                return filetype !== "mp4" && filetype !== "wmv" && filetype !== "mov";
                            }).map((cur, index) => (
                                <div className="col-2 mb-3 mx-0 position-relative" key={index}>
                                    {['jpg', 'jpeg', 'png'].includes(cur?.file.name.split('.').pop()) && <img src={cur?.path} alt="" />}
                                    {['pdf'].includes(cur?.file.name.split('.').pop()) && (<img src={PdfImage} alt="" width="100%" height="100%" />)}
                                    {['xlsx', 'xls'].includes(cur?.file.name.split('.').pop()) && (<img src={xlsImage} alt="" width="100%" height="100%" />)}
                                    {['txt', 'doc'].includes(cur?.file.name.split('.').pop()) && (<img src={textImage} alt="" width="100%" height="100%" />)}
                                    {(['jpg', 'png', 'jpeg', 'pdf', 'xlsx', 'xls', 'txt', 'doc'].includes(cur?.file.name.split('.').pop()) === false) && (<img src={DocImage} alt="" width="100%" height="100%" />)}
                                    <span onClick={() => DeleteAttachmentFile(index)}><IoMdCloseCircleOutline /></span>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-2">
                        <CustomButton onClick={addTicket.handleSubmit} variant="contained" className="mx-3">submit</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default AddTicket;
