import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { TableContainer, Tooltip } from '@mui/material';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import Loading from '../../Component/Loading';
import useTableSorting from '../../Hooks/useTableSorting';
import useReduxData from '../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { NoRecordFound } from '../../Component/NoRecordFound';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { RoleTitle } from '../../Context/constants';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { IoFilterSharp } from 'react-icons/io5';
import { EPRESCRIPTION_LIST } from '../Repository/PatientRepo';
import { IoMdArrowDropdown } from 'react-icons/io';
const EprescriptionHistory = ({ type }) => {
    const [appointmentData, setAppointmentData] = useState([])
    const [count, setCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [loader, setloader] = useState(false)
    const [filtershow, setFiltershow] = useState(false)
    const [dateFilter, setDateFilter] = useState('')
    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [reset, setReset] = useState(false)
    const [date, setDate] = useState({ startDate: '', endDate: '', })
    const { id, subpatientId } = useParams()
    const { reduxData: userData, } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const { CustomButton } = useMuiCompontent()
    const navigate = useNavigate()
    useEffect(() => {
        appointments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset, controller])
    useEffect(() => {
        if (filtershow) {
            window.addEventListener("click", () => setDateRangeDropdown(false))
        }
    }, [filtershow])
    const appointments = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            // patientId: 168,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
        }
        if (dateFilter === 'TODAY') {
            payload.startDate = moment().startOf('day')
            payload.endDate = moment().endOf('day')
        } else if (dateFilter === 'LAST7') {
            payload.startDate = moment().subtract(7, 'days').startOf('day')
            payload.endDate = moment().endOf('day')
        } else if (dateFilter === 'LAST30') {
            payload.startDate = moment().subtract(30, 'days').startOf('day')
            payload.endDate = moment().endOf('day')
        } else {
            payload.startDate = moment().startOf('day')
            payload.endDate = moment().add(1, 'year').endOf('year')
        }
        if (dateFilter === 'CUSTOM' && date.startDate === '' && date.endDate === '') return toast.warn('Please select start date and end date ')
        if (dateFilter === 'CUSTOM' && date.startDate === '') return toast.warn('Please select start date')
        if (dateFilter === 'CUSTOM' && date.endDate === '') return toast.warn('Please select end date')
        if (date.startDate > date.endDate) return toast.warn('Start date can not be greater than end date ')
        else if (dateFilter === 'CUSTOM') {
            payload.startDate = new Date(date.startDate)
            payload.endDate = moment(date.endDate).endOf('day')
        }
        setloader(true)
        try {
            let response = await EPRESCRIPTION_LIST(payload)
            setAppointmentData(response.data)
            setCount(response?.totalCount)
            setloader(false)
        } catch (error) {
            // toast.error(error.message);
            setAppointmentData([])
            setloader(false)
            setCount(0)
        }

    }
    const headCells = [
        { id: "appointmentNumber", numeric: false, disablePadding: true, label: "Appointment Number", showIcon: true },
        { id: "startDate", numeric: false, disablePadding: true, label: "Appointment Date", showIcon: true },
        { id: "startDate", numeric: true, disablePadding: false, label: "Appointment Time" },
        { id: "doctorFirstName", numeric: true, disablePadding: false, label: "Prescribed by", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const resetfun = () => {
        setDateFilter("TODAY")
        setReset((pre) => !pre)
    }
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div>
                    <header className="body_header mb-0" >
                        <div className="d-flex  justify-content-end align-items-center">
                            <div>
                                <ul className="headerlist">
                                    <li>
                                        <div className="dropdown-center ms-4">
                                            <Tooltip title="filter" placement="left" style={{ cursor: "pointer" }}>
                                                <CustomButton variant="contained" className='mt-2' startIcon={<IoFilterSharp />} onClick={() => { setFiltershow(!filtershow); }} style={{ background: "#f5f5f5", color: "black" }}  >Filters</CustomButton>
                                            </Tooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </header>
                    <TableContainer >
                        {appointmentData?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >
                                <NoRecordFound />
                            </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                appointmentData?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={appointmentData?.length} />
                            }
                            <TableBody>

                                {
                                    stableSort(appointmentData, getComparator(order, orderBy))?.map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={row?.prescriptionId}>
                                                <TableRow hover id="hover_tr"   >
                                                    <TableCell id={labelId} align="left" className="ps-3" padding="none">{row?.appointmentNumber}</TableCell>
                                                    <TableCell id={labelId}  >
                                                        {moment(row?.startDate).tz(userData?.timezoneName).format('ll')}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" >{moment(row?.startDate).tz(userData?.timezoneName).format('LT')} - {moment(row?.endDate).tz(userData?.timezoneName).format('LT')}</TableCell>
                                                    <TableCell id={labelId} align="left">{row?.doctorFirstName + " " + row?.doctorLastName}</TableCell>
                                                    <TableCell id={labelId} align="right">
                                                        <i className="fa-solid fa-eye me-2 all_icons_color" onClick={() => { navigate(`${row?.prescriptionNumber}`) }}></i>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='w-100 '>
                    {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                </div>
            </div>
            <Offcanvas placement="end" className="shadow Appointment_Offcanvas_style" show={filtershow} onHide={() => {
                setFiltershow(false)
            }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Filter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        <div className="col-12 mb-2  mb-3  ">
                            <label className="form-label mb-0">Date Range </label>
                            <div className="Appoinment_date_range ">
                                <input onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} value={dateFilter === "TODAY" ? "Today's" : dateFilter === "LAST7" ? "Last 7 Days" : dateFilter === "LAST30" ? "Last 30 Days" : dateFilter === "CUSTOM" ? "Custom" : null} type="text" />
                                <span><IoMdArrowDropdown style={{ fontSize: "21px" }} onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} /></span>
                                {
                                    dateRangeDropdown && <div className="Appoinment_date_range_box" onClick={(event) => event.stopPropagation()}>
                                        <ul className="w-100  px-3 py-2" >
                                            <li className="" onClick={() => { setDateFilter('TODAY'); setDateRangeDropdown(false) }} >
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1"
                                                    defaultChecked={dateFilter === 'TODAY'} />
                                                <label className="form-check-label ps-2" htmlFor="exampleRadios2" >
                                                    Today's
                                                </label>
                                            </li>
                                            <li onClick={() => { setDateFilter('LAST7'); setDateRangeDropdown(false) }}>
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option1"
                                                    defaultChecked={dateFilter === 'LAST7'} />
                                                <label className="form-check-label ps-2" htmlFor="exampleRadios3" >
                                                    Last 7 Days
                                                </label>
                                            </li>
                                            <li onClick={() => { setDateFilter('LAST30'); setDateRangeDropdown(false) }}>
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option1"
                                                    defaultChecked={dateFilter === 'LAST30'} />
                                                <label className="form-check-label ps-2" htmlFor="exampleRadios4" >
                                                    Last 30 Days
                                                </label>
                                            </li>
                                            <li onClick={() => { setDateRangeDropdown(true); setDateFilter('CUSTOM') }}>
                                                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4Custom" value="option1"
                                                    defaultChecked={dateFilter === 'CUSTOM'} />
                                                <label className="form-check-label ps-2" htmlFor="exampleRadios4Custom">
                                                    Custom
                                                </label>
                                            </li>
                                            {
                                                dateFilter === 'CUSTOM' ?
                                                    <li className="mt-2 ps-4">
                                                        <div className="d-flex position-relative">
                                                            <input type="date" className="form-control position-relative" name='startDate' id="datefield" value={date.startDate ? moment(date.startDate).format("YYYY-MM-DD") : ""}
                                                                onChange={(e) => setDate((pre) => ({ ...pre, "startDate": e.target.value }))} />
                                                            <span style={{ padding: "0 2px" }} className="mt-2"> To</span>
                                                            <input type="date" className="form-control position-relative" name='endDate' id="datefield" value={date.endDate ? moment(date.endDate).format("YYYY-MM-DD") : ""}
                                                                onChange={(e) => setDate((pre) => ({ ...pre, "endDate": e.target.value }))} />
                                                        </div>
                                                    </li>
                                                    : null
                                            }
                                        </ul>
                                    </div>
                                }

                            </div>
                        </div>
                        {/* <div className="col-12 mb-3">
                            <label className="form-label mb-0">Staff</label>
                            <SearchAbleSelectBox
                                multiple={true}
                                option={teamFilterList}
                                getOptionLabel="name"
                                isOptionEqualToValue="id"
                                value={teamSelected}
                                name="staff"
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                        </div> */}
                        <div className="col-12 d-flex justify-content-end align-items-center">
                            <CustomButton variant="contained" className="mx-3" onClick={resetfun}>Reset</CustomButton>
                            <CustomButton variant="contained" className="" onClick={appointments}>Search</CustomButton>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default EprescriptionHistory


