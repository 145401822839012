import React from 'react';
import { Switch } from '@mui/material';

const DebitCard = ({ cardDetails, isList, IsActiveCard, setDeleteCard, cardToken, handleChangeDefaultCard }) => {
    const { brand, last4, exp_month, exp_year, holder_name } = cardDetails;
    return (
        <div className="credit_card">
            <div className="credit_card_inner">
                <div className="front">
                    <img alt='' src="https://i.ibb.co/PYss3yv/map.png" className="map-img" />
                    <div className="credit_card_row">
                        <img alt='' src="https://i.ibb.co/G9pDnYJ/chip.png" width="60px" />
                        <p style={{ textTransform: "uppercase", letterSpacing: "2px" }}>{brand}</p>
                        {/* <img alt='' src="https://i.ibb.co/WHZ3nRJ/visa.png" width="60px" /> */}
                    </div>
                    <div className="credit_card_row card-no">
                        <p className='m-0'>****</p>
                        <p className='m-0'>****</p>
                        <p className='m-0'>****</p>
                        <p className='m-0'>{last4 || '0000'}</p>
                    </div>

                    <div className="credit_card_row card_holder_name flex-column" style={{ marginTop: `${isList ? "0px" : "40px"}` }}>
                        {
                            isList && <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                <Switch title='Card Activate/Deactivate  ' size='small' checked={IsActiveCard} onClick={() => handleChangeDefaultCard(IsActiveCard, cardToken)} />
                                <i className="fa-solid fa-trash-can  ms-2 cur" title='Delete Card' style={{ color: "#050c14" }} onClick={() => setDeleteCard({ open: true, data: cardToken })} />
                            </div>
                        }
                        <div className='w-100 d-flex  justify-content-between'>
                            <p className='m-0'> {holder_name || "CARD HOLDER"} </p>
                            <p className='m-0'>{exp_month ? `${exp_month}/${exp_year}` : 'MM/YY'}</p>

                        </div>
                    </div>
                </div>
                <div className="back">
                    <img alt='' src="https://i.ibb.co/PYss3yv/map.png" className="map-img" />
                    <div className="card_bar"></div>
                    <div className="credit_card_row card-cvv">
                        <div>
                            <img alt='' src="https://i.ibb.co/S6JG8px/pattern.png" />
                        </div>
                        <p className='m-0'>{"***"}</p>
                    </div>
                    <div className="credit_card_row card-text">
                        <p>This is a virtual card </p>
                    </div>
                    <div className="credit_card_row signature">
                        <p>CUSTOMER SIGNATURE</p>
                        <img alt='' src="https://i.ibb.co/WHZ3nRJ/visa.png" width="80px" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DebitCard;
