import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import { pattern, updatepatientsValidation } from '../../../Context/Validation';
import { useOutletContext, useParams } from 'react-router-dom';
import useReduxData from '../../../Hooks/useReduxData';
import { COUNTRY_LIST, STATE_LIST } from '../../Repository/CommonRepo';
import { UPDATE_MEMBER, UPDATE_PATIENT } from '../../Repository/PatientRepo';
import { RoleTitle } from '../../../Context/constants';

const useEditPatient = (type) => {
    const [imgUrl, setImgUrl] = useState(null);
    const [ListDataCountry, setListDataCountry] = useState([])
    const [ListState, setListState] = useState([])
    const [loader, setLoader] = useState(false)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
    const FileRef = useRef()
    const { id, subpatientId } = useParams()
    const { getpatientDetails } = useOutletContext()
    useEffect(() => {
        addPatint.setValues(patientdetails)
        addPatint.setFieldValue("zipCode", patientdetails?.zip)
        setImgUrl(patientdetails?.image)
        if (patientdetails?.country_id) {
            listcountrys(patientdetails?.country_id)
        }
        if (patientdetails?.country_id && patientdetails?.state_id) {
            getStateList(patientdetails?.country_id, patientdetails?.state_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientdetails])

    // get list state
    const getStateList = async (countryId, StateId) => {
        let payload = {
            countryId: countryId,
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
            if (countryId && StateId) {
                const StateDetails = response.data.filter((cur) => cur?.stateId === StateId)
                addPatint.setFieldValue("state", StateDetails)
            }

        } catch (error) {
            setListState([])
            toast.error(error.message)
            addPatint.setFieldValue("state", [])
        } finally {
            setLoader(false)
        }
    }
    // get list counry api
    async function listcountrys(countryId) {
        let payload = {
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
            if (countryId) {
                const countryDetails = response.data.filter((cur) => cur?.id === countryId)
                addPatint.setFieldValue("country", countryDetails)
            }
        } catch (error) {
            setListDataCountry([])
        } finally {
            setLoader(false)
        }
    }
    // handal change birthDate
    const handalDOB = (e) => {
        addPatint.setFieldValue("dob", e.target.value)
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        addPatint.setFieldValue("age", age ?? "")
    }
    // update patient 
    const addPatint = useFormik({
        initialValues: {
            patientId: "",
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            age: "",
            bloodGroup: "",
            maritalStatus: "",
            number: "",
            email: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zipCode: "",
            relation: "",
        },
        validationSchema: updatepatientsValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            if (id && subpatientId && values.relation == null) {
                errors.relation = "Please select relation"
            } else {
                delete errors.relation
            }
            return errors;
        },

        onSubmit: async (value) => {
            const { country_id, state_name, state_id, country_name, zip, dob, state, country, number, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                mobile: number,
                dob: moment(dob).format('YYYY-MM-DD'),
                state: state[0]?.stateId,
                country: country?.[0]?.id,
                image: imgUrl,
                patientId: type === "Patients" ? id : undefined,
                relation: type === "subPatients" ? restValue.relation : undefined,
                memberId: type === "subPatients" ? subpatientId : undefined
            }
            setLoader(true)
            try {
                let response = type === "Patients" ? await UPDATE_PATIENT(payload) : await UPDATE_MEMBER(payload)
                toast.success(response?.message)
                getpatientDetails()
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            getStateList(newValue?.[0]?.id, name)
            addPatint.setFieldValue(name, newValue)
            addPatint.setFieldValue("state", [])
        } else {
            addPatint.setFieldValue(name, newValue)
        }
    }
    return {
        handalDOB,
        setImgUrl,
        handalSearchAbleSelectBox,
        setLoader,
        addPatint,
        loader,
        imgUrl,
        ListDataCountry,
        ListState,
        id,
        subpatientId,
        FileRef,
    }
}

export default useEditPatient
