import React from 'react'
import { SearchAbleSelectBox, SelectBox } from '../../Component/Inputs';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { motion } from 'framer-motion';
import { AppointmentStatus, AppointmentStatusOptions, GendersOptions, SortbyAppointment } from '../../Context/constants';

const Filters = ({ setDateRangeDropdown, dateFilter, selectClinic, setSelectClinic, clinicList, setDateFilter, setPillsActive, dateRangeDropdown, page, filterPopup,
    setFilterPopup, setSelectGender, select, getDetail, setAction, setCustomDate, customDate, userdata, sorting, SetSorting, selectGender, appointmentFilter, setAppointmentFilter }) => {
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "clinic") {
            if (Array.isArray(newValue) && newValue.length !== 0) {
                setSelectClinic(newValue);
            } else {
                if (page !== "RUSH_REPORT") {
                    setSelectClinic([{ clinicName: "All", clinicId: "ALL_CLINCI" }]);
                } else {
                    setSelectClinic([clinicList?.[0]]);
                }
            }
            if (setPillsActive) {
                setPillsActive(page === "BOOKING_DETAILS" ? "Booking" : "all")
            }
        }
        if (name === "gender") {
            setSelectGender(newValue)
        }
        if (name === "Status_filter") {
            setAppointmentFilter(newValue)
        }
    }

    const apply = () => {
        getDetail()
    }
    const reset = () => {
        if (page === "BOOKING_DETAILS" || page === "PATIENT_REPORT" || page === "STAFF_REPORT" || page === "SERVICE_REPORT" || page === "RUSH_REPORT") {
            setAction((pre) => !pre)
            // setSelectClinic([{ clinicName: "All", clinicId: "ALL_CLINCI" }])
            setSelectClinic([{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }])
            setDateFilter("TODAY")
            setCustomDate({
                startDate: "",
                endDate: ""
            })
            setAppointmentFilter([
                { label: "Booked", value: "BOOKED" },
                { label: "Cancelled", value: "CANCELLED" },
                { label: "CheckedOut", value: "CHECKOUT" },
            ])

        }
        if (page === "PATIENT_REPORT" || page === "STAFF_REPORT") {
            SetSorting("TOTAL_BOOKING")
        }
        if (page === "BOOKING_DETAILS") {
            setPillsActive("Booking")
        }
        if (page === "DEMOGRAPHICS") {
            setSelectGender(GendersOptions)
        }

        setFilterPopup(false);

    }

    const { CustomButton } = useMuiCompontent()
    if (filterPopup) {
        return (
            <motion.div className={`FILTER-INSIGHT shadow`}
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: 10 }}
                transition={{ duration: 0.5 }}>
                {
                    page !== "DEMOGRAPHICS" && <div className="row m-1 ">
                        <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                            <h4>Filters</h4>
                        </div>
                        <div className="col-12 mt-3" style={{ borderBottom: "2px solid #ededed" }}>
                            <p style={{ fontSize: "13px", color: "gray" }} className='m-0'>Date Range</p>
                            <ul className="w-100   py-2 d-flex p-0" >
                                <li className="" onClick={() => { setDateFilter('TODAY'); setDateRangeDropdown(false) }} >
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1"
                                        defaultChecked={dateFilter === 'TODAY'} />
                                    <label className="form-check-label ps-2" htmlFor="exampleRadios2" >
                                        Today's
                                    </label>
                                </li>
                                <li className='mx-3' onClick={() => { setDateFilter('LAST7'); setDateRangeDropdown(false) }}>
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option1"
                                        defaultChecked={dateFilter === 'LAST7'} />
                                    <label className="form-check-label ps-2" htmlFor="exampleRadios3" >
                                        Last 7 Days
                                    </label>
                                </li>
                                <li onClick={() => { setDateFilter('LAST30'); setDateRangeDropdown(false) }}>
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option1"
                                        defaultChecked={dateFilter === 'LAST30'} />
                                    <label className="form-check-label ps-2" htmlFor="exampleRadios4" >
                                        Last 30 Days
                                    </label>
                                </li>
                                <li onClick={() => { setDateRangeDropdown(true); setDateFilter('CUSTOM') }} className='ms-3'>
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4Custom" value="option1"
                                        defaultChecked={dateFilter === 'CUSTOM'} />
                                    <label className="form-check-label ps-2" htmlFor="exampleRadios4Custom">
                                        Custom
                                    </label>
                                </li>
                                {
                                    dateFilter === 'CUSTOM' ?
                                        <li className=" ps-4">
                                            <div className="d-flex position-relative">
                                                <input type="date" className="form-control position-relative" name='startDate' id="datefield" value={customDate?.startDate}
                                                    onChange={(e) => setCustomDate((pre) => ({ ...pre, "startDate": e.target.value }))} />
                                                <span style={{ padding: "0 2px" }} className="mt-2"> To</span>
                                                <input type="date" className="form-control position-relative" name='endDate' id="datefield" value={customDate?.endDate}
                                                    onChange={(e) => setCustomDate((pre) => ({ ...pre, "endDate": e.target.value }))} />
                                            </div>
                                        </li>
                                        : null
                                }
                            </ul>
                        </div>

                        <div className="col-3 mt-3" >
                            <p style={{ fontSize: "13px", color: "gray" }} className='mb-2'>Location</p>
                            <SearchAbleSelectBox
                                multiple={false}
                                // option={page !== "RUSH_REPORT" ? [{ clinicName: "All", clinicId: "ALL_CLINCI" }, ...clinicList] : clinicList}
                                option={clinicList}
                                getOptionLabel="clinicName"
                                isOptionEqualToValue="clinicId"
                                value={selectClinic}
                                name="clinic"
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                        </div>
                        {
                            page !== "DEMOGRAPHICS" && <div className="col-3 mt-3 ">
                                <p style={{ fontSize: "13px", color: "gray" }} className='mb-2'>Status</p>
                                <SearchAbleSelectBox
                                    multiple={true}
                                    option={AppointmentStatusOptions?.filter((cur, index) => cur?.value !== AppointmentStatus?.RESCHEDULED)}
                                    getOptionLabel="label"
                                    isOptionEqualToValue="value"
                                    value={appointmentFilter}
                                    name="Status_filter"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                            </div>
                        }


                        {
                            // (page === "PATIENT_REPORT" || page === "STAFF_REPORT")
                            (page === "not_found")
                            && <div className="col-2 mt-3 ">
                                <p style={{ fontSize: "13px", color: "gray" }} className='mb-2'>Sort by</p>
                                <SelectBox
                                    placeholder="Select Discount Type"
                                    value={sorting}
                                    name="Sortby"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => SetSorting(e.target.value)}
                                    option={SortbyAppointment}
                                />
                            </div>}


                    </div>
                }
                {
                    page === "DEMOGRAPHICS" && <div className="row mx-1">
                        <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                            <h4>Filters</h4>
                        </div>
                        <div className="col-4 mt-3">
                            <p style={{ fontSize: "13px", color: "gray" }} className='mb-2'>Gender</p>
                            <SearchAbleSelectBox
                                multiple={true}
                                // option={page !== "RUSH_REPORT" ? [{ clinicName: "All", clinicId: "ALL_CLINCI" }, ...clinicList] : clinicList}
                                option={GendersOptions}
                                getOptionLabel="label"
                                isOptionEqualToValue="value"
                                value={selectGender}
                                name="gender"
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 text-end">
                        <CustomButton variant="contained" onClick={() => { reset() }} className='me-3' >Reset</CustomButton>
                        <CustomButton variant="contained" onClick={() => { apply() }} >Apply</CustomButton>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default Filters
