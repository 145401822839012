import { addPatientToGroupAppt, setupList, appointmentFilterList, appointmentHistory, appointmentPatientList, appointmentsById, changeAppointmentsStatus, checkOutSlip, getTimeSlot, insertAppointment, listallMedicines, listAppointments, removePatientFromGroupAppt, insertSetup, checkoutPatinet, insertEprescription, deleteAgenda, insertTestReport, assignvaccinationcalender, assignVaccinationCalenderList, listWaitingAppts, removePatientApi } from "../../Context/api"
import { commonMessages, statusCode } from "../../Context/constants"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"
////////////////////////////////////////// APPOINTMENT/////////////////////////////////////////

export const INSERT_APPOINTMENT = async (body) => {
    try {
        let response = await axiosInstance.post(insertAppointment(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const APPOINTMENT_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listAppointments(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_APPOINTMENT_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(appointmentsById(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CHANGE_APPOINTMENT_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(changeAppointmentsStatus(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const APPOINTMENT_FILTER_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(appointmentFilterList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const TIME_SLOT_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(getTimeSlot(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const APPOINTMENT_history = async (body) => {
    let response = await axiosInstance.post(appointmentHistory(), body)
    if (response.status !== statusCode.OK) {
        throw new Error({ message: response.data.message || commonMessages.NetworkError, code: response.status })
    }
    return response.data
}

//////////////////////////////////// PATINT DETAILS BY APPOINTMENT ////////////////////////////////////

export const PATIENT_LIST_TO_GROUP_APPOINTMENT = async (body) => {
    try {
        let response = await axiosInstance.post(appointmentPatientList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_PATIENT_TO_GROUP_APPOINTMENT = async (body) => {
    try {
        let response = await axiosInstance.post(addPatientToGroupAppt(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_PATIENT_TO_GROUP_APPOINTMENT = async (body) => {
    try {
        let response = await axiosInstance.post(removePatientFromGroupAppt(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_CHECKOUT_SLIP_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(checkOutSlip(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

///////////////////////////////////////  CHECK OUT  //////////////////////////////////////////////
export const SETUP_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(setupList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_SETUP = async (body) => {
    try {
        let response = await axiosInstance.post(insertSetup(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_CHECKOUT_APPOINTMENT = async (body) => {
    try {
        let response = await axiosInstance.post(checkoutPatinet(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

///////////////////////////////////////  TODAY AGENDA  //////////////////////////////////////////////

export const MEDICINE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listallMedicines(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_EPRESCRIPTION = async (body) => {
    try {
        let response = await axiosInstance.post(insertEprescription(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_AGENDA = async (body) => {
    try {
        let response = await axiosInstance.post(deleteAgenda(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_TEST_REPORT = async (body) => {
    try {
        let response = await axiosInstance.post(insertTestReport(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const ASSIGN_VACCINATION_CALENDAR = async (body) => {
    try {
        let response = await axiosInstance.post(assignvaccinationcalender(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ASSIGN_VACCINATION_CALENDAR_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(assignVaccinationCalenderList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

// Waiting List

export const WAITING_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listWaitingAppts(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const REMOVE_PATIENT_WAITING = async (body) => {
    try {
        let response = await axiosInstance.post(removePatientApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}



















