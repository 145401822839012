import {addmember, addpatient, allergyHistoryDetails, appointmentHistory, eprescriptionDetails, eprescriptionList, insertPet, LifestyleHistoryDetails, listPatient, listPatientById, listPetApi, MedicalHistoryApi, MedicalHistoryListApi, mergeAccount, testList, updateMember, updatePatient, updatePetApi, updateVaccinationCalenderPatient, vaccinationCalenderPatient, vitalHistoryDetails } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"
//////////////////////////////// PATIENT //////////////////////////////////////

export const PATIENT_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listPatient(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_PATIENT = async (body) => {
    try {
        let response = await axiosInstance.post(addpatient(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_PATIENT_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(listPatientById(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_PATIENT = async (body) => {
    try {
        let response = await axiosInstance.post(updatePatient(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_MEMBER = async (body) => {
    try {
        let response = await axiosInstance.post(addmember(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_MEMBER = async (body) => {
    try {
        let response = await axiosInstance.post(updateMember(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const MERGE_ACCOUNT = async (body) => {
    try {
        let response = await axiosInstance.post(mergeAccount(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


//////////////////////////////// PET //////////////////////////////////////

export const INSERT_PET = async (body) => {
    try {
        let response = await axiosInstance.post(insertPet(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const PET_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listPetApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_PET = async (body) => {
    try {
        let response = await axiosInstance.post(updatePetApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

///////////////////////////////////////  ADDITIONAL DETAILS ////////////////////////////////////////

export const VITAL_HISTORY_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(vitalHistoryDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
    
}
export const ALLERGIES_HISTORY_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(allergyHistoryDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
    
}
export const LIFESTYLE_HISTORY_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(LifestyleHistoryDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
    
}
export const MEDICAL_HISTORY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(MedicalHistoryListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
    
}
export const INSERT_MEDICAL_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(MedicalHistoryApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const VACCINATION_CALENDAR_PATIENT = async (body) => {
    try {
        let response = await axiosInstance.post(vaccinationCalenderPatient(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_VACCINATION_CALENDAR_PATIENT = async (body) => {
    try {
        let response = await axiosInstance.post(updateVaccinationCalenderPatient(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const APPOINTMENT_HISTORY = async (body) => {
    try {
        let response = await axiosInstance.post(appointmentHistory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const TEST_REPORT_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(testList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EPRESCRIPTION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(eprescriptionList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_EPRESCRIPTION_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(eprescriptionDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}








