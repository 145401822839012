import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'
import useReduxData from '../../Hooks/useReduxData'
import { RoleTitle } from '../../Context/constants'

const TeamMember = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const ActiveTab = splitPath[splitPath.length - 1]
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const isFrontDeskProfile = StaffDetails?.roleId === RoleTitle?.FRONT_DESK
    return (
        <>
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/team")} />
                        <h2 className='page_name'>Team Details</h2>
                    </div>
                </header>
                <div className="common-white-bg-box mx-0 shadow  position-relative pt-0 ">
                    <div className="col-12 pb-3 position-sticky top-0 z-3 pt-2 tab_scroll" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
                        <ul className="nav  mb-0  pb-1 px-2 " style={{ width: "480px" }}>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${ActiveTab === "profile" && "active_teams_frofile"}`} onClick={() => navigate("profile")} id="personal" type="button">Profile</button>
                            </li>
                            {
                                !isFrontDeskProfile && <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${ActiveTab === "availability" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`availability`)}>Availability</button>
                                </li>
                            }
                            {
                                !isFrontDeskProfile && <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${ActiveTab === "unavailability" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`unavailability`)} >Unavailability</button>
                                </li>
                            }
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "location" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`location`)} >Location</button>
                            </li>
                            {/* <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "specialization" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`specialization`)} >Specialization</button>
                            </li> */}
                        </ul>
                    </div>
                    <div className='px-3 STAFF-OUTLET-HIEGHT '>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamMember
