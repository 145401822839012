import React from 'react'
import moment from "moment-timezone";
import { Avatar, Tooltip } from '@mui/material';
import Loading from '../../Component/Loading';
import useUnavailability from './Hooks/useUnavailability';
import { NoRecordFound } from '../../Component/NoRecordFound';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { IoMdAdd } from 'react-icons/io';
import { motion } from 'framer-motion';
import { Modal } from 'react-bootstrap';
import { clinicPictures } from '../../Context/api';
const TeamsUnAvailability = () => {
    const {
        loader,
        unavilabilityList,
        UnavailabilityDetele,
        SetUnavailabilityDetele,
        setDeleteData,
        handleClose,
        userdata,
        navigate,
    } = useUnavailability()

    const { CustomButton } = useMuiCompontent()
    return (
        <>
            {loader && <Loading />}
            <motion.div className=' py-3' style={{ height: "100%" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}>
                <header className="body_header" >
                    <div className="">
                        <CustomButton variant="contained" startIcon={<IoMdAdd />} onClick={() => { navigate("set-unavailability") }} >Set Unavailability</CustomButton>
                    </div>


                </header>
                <div className="row my-3">
                    {unavilabilityList?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound />  </div>}
                    {
                        unavilabilityList?.map((cur, index) => {
                            return <React.Fragment key={index}>
                                <div className="col-sx-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-4 mt-3 " >
                                    <div className="avail-col2 shadow">
                                        <div className="d-flex justify-content-between align-items-center w-100 py-2 mb-2 " style={{ borderBottom: "2px solid #ededed" }}>
                                            <div className="d-flex align-items-center" style={{ width: 'calc(100% - 20px)' }}>
                                                <Avatar src={clinicPictures + cur?.clinicImage} className="me-2" sx={{ width: 40, height: 40 }} />
                                                <div>
                                                    <h5 className="mb-0 location  me-3  TEXT_ELLIPSIS"> {`${cur.clinicName.charAt(0).toUpperCase()}${cur.clinicName.slice(1)}`}</h5>
                                                    <h5 className="mb-0 location  me-3  TEXT_ELLIPSIS" style={{ color: "gray", fontWeight: "400" }}>{`${cur?.city.charAt(0).toUpperCase()}${cur?.city.slice(1)}`}</h5>
                                                </div>
                                            </div>
                                            <Tooltip title="Delete" placement="left" style={{ cursor: "pointer" }}>
                                                <i className="fa-solid fa-trash-can all_icons_color"
                                                    onClick={() => { SetUnavailabilityDetele(true); setDeleteData({ clinicId: cur.clinicId, id: cur.unavailabilityId }) }}
                                                ></i>
                                            </Tooltip>
                                        </div>
                                        <div className="d-flex py-2 mb-2" style={{ borderBottom: "2px solid #ededed" }}>
                                            <div className="col-6">
                                                <ul>
                                                    <li><h5 className="mb-0 location">Start Date</h5></li>
                                                    <li> <p className="time_zone mb-1">{moment(cur?.startDate)?.tz(userdata?.timezoneName)?.format('ll')}</p></li>
                                                </ul>
                                            </div>
                                            <div className="col-6 d-flex flex-column align-items-end  ">
                                                <ul>
                                                    <li><h5 className="mb-0 location">End Date</h5></li>
                                                    <li> <p className="time_zone mb-1">{moment(cur?.endDate)?.tz(userdata?.timezoneName)?.format('ll')}</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            cur?.arrayOfTimings?.map((curs, indexs) => {
                                                return <React.Fragment key={indexs}>
                                                    <div className=" mb-2 d-flex align-items-center justify-content-between" >
                                                        <div className="d-flex justify-content-between cus-border-unavailablety" style={{ padding: ".3rem .5rem" }} >
                                                            <small>{moment(curs?.startTime)?.tz(userdata?.timezoneName)?.format("hh:mm ")}</small>
                                                            <div style={{ width: "1px", background: "#38C4B9" }}></div>
                                                            <small>{moment(curs?.startTime)?.tz(userdata?.timezoneName)?.format(" A")}</small>
                                                        </div>
                                                        <p className="mb-0 mx-2">To</p>
                                                        <div className="d-flex justify-content-between cus-border-unavailablety" style={{ padding: ".3rem .5rem" }}  >
                                                            <small>{moment(curs?.endTime)?.tz(userdata?.timezoneName)?.format('hh:mm')}</small>
                                                            <div style={{ width: "1px", background: "#38C4B9" }}></div>
                                                            <small>{moment(curs?.endTime)?.tz(userdata?.timezoneName)?.format('A')}</small>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            })
                                        }
                                    </div>
                                </div>
                            </React.Fragment>
                        })
                    }
                </div>
            </motion.div>
            <Modal show={UnavailabilityDetele} onHide={() => SetUnavailabilityDetele(false)} centered>
                <Modal.Body>
                    <div className="modal-body text-center cus-i">
                        <i className="fa-solid fa-trash-can  all_icons_color"></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to delete this unavailability</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES",)}> Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default TeamsUnAvailability
