// import { useState } from 'react';
// import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { CustomTextField } from './Inputs';
const PhoneNumber = ({ Name, Value, HandleChange, disabledInput, width, countrySelectComponent }) => {
    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    // const [countryMaxLength, setCountryMaxLength] = useState(15);
    // const formatPhoneNumber = (value) => {
    //     if (!value) return '';
    //     const phoneNumber = value.replace(/[^\d]/g, '');
    //     const countryCode = phoneNumber.slice(0, 3);
    //     const number = phoneNumber.slice(3);
    //     return `+${countryCode} ${number}`;
    // };
    // const handleCountryChange = (selectedCountry) => {
    //     const countryName = selectedCountry;
    //     if (countryName === 'IN') {
    //         setCountryMaxLength((pre) => 15);
    //     } else {
    //         setCountryMaxLength((pre) => 20);
    //     }
    // }; 
    return (
        // <PhoneInput
        //     disabled={disabledInput}
        //     className={`${width && "w-100"}`}
        //     // defaultCountry={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? 'IN' : undefined}
        //     // countrySelectComponent={(timeZone == "Asia/Calcutta" || timeZone === "Asia/Kolkata") ? "null" : undefined}
        //     countrySelectComponent={countrySelectComponent}
        //     defaultCountry={'IN'}
        //     countryCallingCodeEditable={false}
        //     international
        //     withCountryCallingCode
        //     value={Value && `${Value.includes("+") ? "" : "+"}${Value}`}
        //     onChange={(e) => { HandleChange.setFieldValue(Name, e) }}
        //     format={formatPhoneNumber}
        //     maxLength={countryMaxLength}  // Set the dynamic maxLength
        //     onCountryChange={(country) => handleCountryChange(country)}
        // />
        <CustomTextField
            disabled={disabledInput}
            onChange={(e) => {
                let inputValue = e.target.value;
                let numericValue = inputValue.replace(/\D/g, ''); 
                let maxValue = 10;
                numericValue = numericValue.slice(0, maxValue); 

                HandleChange.setFieldValue(Name, numericValue); 
            }}
            name={Name}
            value={Value||""}
            type="text"
            variant='outlined'
            style={{ width: "100%" }}
            color='primary'
            inputProps={{
                inputMode: 'numeric', 
                pattern: '[0-9]*' 
            }}
        />
    );
};
export default (PhoneNumber)