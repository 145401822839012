import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { GET_NOTIFICATION_DETAILS, UPDATE_NOTIFICATION } from "../../Repository/SettingRepo";
const useNotification = () => {
    const [list, setList] = useState({})
    const [loader, setLoader] = useState(false)
    const { reduxData:userData } = useReduxData("userDataRDX")
    const id = userData.userId
    useEffect(() => {
        getlistnotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getlistnotifications = async () => {
        setLoader(true)
        try {
            let response = await GET_NOTIFICATION_DETAILS({ userId: id })
            setList(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    const updatesetting = async (data, event) => {
        setLoader(true)
        let payload = {
            ...data,
            roleTitle: userData?.roleTitle
        }
        try {
            let response = await UPDATE_NOTIFICATION(payload)
            getlistnotifications()
            // let updateNotificationsData = { ...list }
            // switch (event) {
            //     case "appointments_email":
            //         updateNotificationsData.Appointments.email = !updateNotificationsData.Appointments.email
            //         break;

            //     case "appointments_sms":
            //         updateNotificationsData.Appointments.sms = !updateNotificationsData.Appointments.sms
            //         break;

            //     case "appointments_push":
            //         updateNotificationsData.Appointments.push = !updateNotificationsData.Appointments.push
            //         break;

            //     case "waiting_list_email":
            //         updateNotificationsData.WaitingList.email = !updateNotificationsData.WaitingList.email

            //         break;

            //     case "waiting_list_sms":
            //         updateNotificationsData.WaitingList.sms = !updateNotificationsData.WaitingList.sms

            //         break;

            //     case "waiting_list_push":
            //         updateNotificationsData.WaitingList.push = !updateNotificationsData.WaitingList.push

            //         break;

            //     case "Staff_Availability_email":
            //         updateNotificationsData.StaffAvailability.email = !updateNotificationsData.StaffAvailability.email

            //         break;

            //     case "Staff_Availability_sms":
            //         updateNotificationsData.StaffAvailability.sms = !updateNotificationsData.StaffAvailability.sms

            //         break;

            //     case "Staff_Availability_push":
            //         updateNotificationsData.StaffAvailability.push = !updateNotificationsData.StaffAvailability.push

            //         break;
            //     case "Staff_UnAvailability_email":
            //         updateNotificationsData.StaffUnavailability.email = !updateNotificationsData.StaffUnavailability.email

            //         break;

            //     case "Staff_UnAvailability_sms":
            //         updateNotificationsData.StaffUnavailability.sms = !updateNotificationsData.StaffUnavailability.sms
            //         break;

            //     case "Staff_UnAvailability_push":
            //         updateNotificationsData.StaffUnavailability.push = !updateNotificationsData.StaffUnavailability.push
            //         break;
            //     case "Service_Assignment_email":
            //         updateNotificationsData.Service_Assignment.email = !updateNotificationsData.Service_Assignment.email

            //         break;

            //     case "Service_Assignment_sms":
            //         updateNotificationsData.Service_Assignment.sms = !updateNotificationsData.Service_Assignment.sms

            //         break;

            //     case "Service_Assignment_push":
            //         updateNotificationsData.Service_Assignment.push = !updateNotificationsData.Service_Assignment.push

            //         break;
            //     case "Clinic_Assignment_email":
            //         updateNotificationsData.Clinic.email = !updateNotificationsData.Clinic.email

            //         break;

            //     case "Clinic_Assignment_sms":
            //         updateNotificationsData.Clinic.sms = !updateNotificationsData.Clinic.sms

            //         break;

            //     case "Clinic_Assignment_push":
            //         updateNotificationsData.Clinic.push = !updateNotificationsData.Clinic.push

            //         break;

            //     default:
            //         break;

            // }
            // setList(updateNotificationsData)
            toast.success(response.message)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }

        
    }
    return {
        list, id, updatesetting, loader,userData
    }
}
export default useNotification
