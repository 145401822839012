import React from 'react';
import { Area, AreaChart,  CartesianGrid,  ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Filters from '../../Component/Insights/Filters';
import Loading from '../../Component/Loading';
import useRushHours from './Hooks/useRushHours';
import { IoFilterSharp } from 'react-icons/io5';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { formatNumber } from '../../Utils/Utils';
const RushHours = () => {
    const {
        loader,
        dateFilter,
        clinicList,
        rushHourDetail,
        dateRangeDropdown,
        selectClinic,
        setDateRangeDropdown,
        setDateFilter,
        setSelectClinic,
        filterPopup,
        setFilterPopup,
        customDate,
        setCustomDate,
        setAction,
        userdata,
        getRushHourDetail,
        setAppointmentFilter,
        appointmentFilter,
    } = useRushHours()
    const { CustomButton } = useMuiCompontent()



    return <>
        {loader && <Loading />}
        <div className="py-3">
            <div className="row ">
                <div className="col-12  position-relative px-0 ">
                    <div className='w-100 text-end'>
                        <CustomButton onClick={() => setFilterPopup((pre) => !pre)} className='' variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<IoFilterSharp />}  >Filters</CustomButton>
                    </div>
                    <Filters setDateRangeDropdown={setDateRangeDropdown}
                        dateFilter={dateFilter}
                        selectClinic={selectClinic}
                        setSelectClinic={setSelectClinic}
                        clinicList={clinicList}
                        setDateFilter={setDateFilter}
                        dateRangeDropdown={dateRangeDropdown}
                        page="RUSH_REPORT"
                        filterPopup={filterPopup}
                        setFilterPopup={setFilterPopup}
                        setAction={setAction}
                        setCustomDate={setCustomDate}
                        customDate={customDate}
                        userdata={userdata}
                        getDetail={getRushHourDetail}
                        appointmentFilter={appointmentFilter}
                        setAppointmentFilter={setAppointmentFilter}
                    />
                </div>
                <div className="col-12 px-0">
                    <div className="row d-flex justify-content-center" style={{ fontSize: "13px" }}>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{formatNumber(rushHourDetail?.bookedAppointmentCount)}</h4>
                                <p className='mb-0'>Total Bookings</p>
                            </div>
                        </div>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{`${rushHourDetail?.currencyImage??""} ${formatNumber(rushHourDetail?.totalRevenueEarned)}`}</h4>
                                <p className='mb-0'>Revenue Earned</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12  d-flex  justify-content-center  px-0  my-5">
                    {/* <ResponsiveContainer width="80%" height={400}>
                        <BarChart
                            width={500}
                            height={500}
                            data={rushHourDetail}
                            margin={{ top: 20, right: 30, left: 20, bottom: 5, }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis yAxisId="left" orientation="left" stroke="gray" />
                            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                            <Tooltip />
                            <Bar yAxisId="left" dataKey="Bookings" fill="#81D1CB" />
                            <Legend />
                        </BarChart>
                    </ResponsiveContainer> */}

                    <div className="col-md-10 col-12 ps-0 mt-3">
                        <div className="col -sm p-4">
                            <div style={{ width: '100%', height: 320 }}>
                                <ResponsiveContainer>
                                    <AreaChart
                                        data={rushHourDetail?.graphHours}
                                        margin={{
                                            top: 10,
                                            right: 30,
                                            left: 0,
                                            bottom: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="time" />
                                        <YAxis />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="Bookings" stroke="#38C4B9" fill="#dcf2f0" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">

                </div>
            </div>
        </div>
    </>;
};

export default RushHours;
