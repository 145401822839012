import React, { Suspense } from "react";
import { IoFilterSharp } from "react-icons/io5";
import appointments_calendar from '../../Assets/Images/streamline_waiting-appointments-calendar-solid.png';
import ph_calendar from '../../Assets/Images/ph_calendar-fill.png';
import round_cancel from '../../Assets/Images/ic_round-cancel.png';
import bxs_injection from '../../Assets/Images/bxs_injection.png';
import useDashboard from "./hooks/useDashboard";
import Loading from "../../Component/Loading";
import { formatNumber } from "../../Utils/Utils";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, LineChart } from 'recharts';
import { Avatar } from "@mui/material";
import moment from "moment-timezone";
import { PatientImageFilePath } from "../../Context/api";
import FilterCom from "./compontent/FilterCom";
import { RoleTitle } from "../../Context/constants";


const Dashboard = () => {

  const {
    dashboardData,
    loader,
    dateRangeDropdown,
    clinicList,
    filerModal,
    filters,
    userdata,
    setFilters,
    setDateRangeDropdown,
    getDetails,
    reset,
    apply,
    handleClickFilterIcon
  } = useDashboard()


  return (
    <>

      <Suspense fallback={<h1>Loading Dashboard...</h1>}>
        {loader && <Loading />}
        <div className="common-component-box">
          <header className="body_header" >
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="page_name">Dashboard</h2>
            </div>
          </header>
          <div className="common-white-bg-box_dashboard pt-0 ">
            <div className="row ">
              <div className="col-lg-7  pt-0">
                <div className="pb-2">
                  <div className="d-flex justify-content-end pb-2 position-relative">
                    <div className="col-lg-6 justify-content-end d-flex dashboard_text_color ">
                      <IoFilterSharp style={{ fontSize: "22px", cursor: 'pointer' }} onClick={() => handleClickFilterIcon("overView", { topService: false, toMember: false, })} /> &nbsp; &nbsp; <p className="m-0">Filters</p>
                      <FilterCom
                        dateRangeDropdown={dateRangeDropdown}
                        setDateRangeDropdown={setDateRangeDropdown}
                        getDetails={getDetails}
                        reset={reset}
                        apply={apply}
                        clinicList={clinicList}
                        setFilters={setFilters}
                        open={filerModal?.overView}
                        selectClinic={filters?.overView?.clinic}
                        dateRange={filters?.overView?.dateRange}
                        openModalKey="overView"
                        userdata={userdata}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <div className="rounded shadow_new p-3">
                        <div className="d-flex">
                          <div className="col-lg-3">
                            <div className="position-relative dashboard_icon">
                              <img alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" height={20} width={20} src={appointments_calendar} />
                            </div>
                          </div>
                          <div className="col-lg-9 ps-2">
                            <h4 className="pt-1 m-0 numbers_font dashboard_text_color"><b>{formatNumber(dashboardData?.appointmentsCount?.bookedAppointmentCount)}</b></h4>
                            <p className="m-0 p-0 text_light_small">Booked Appointments</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pb-3">
                      <div className="rounded shadow_new p-3">
                        <div className="d-flex">
                          <div className="col-lg-3">
                            <div className="position-relative dashboard_icon">
                              <img alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" height={24} width={24} src={ph_calendar} />
                            </div>
                          </div>
                          <div className="col-lg-9 ps-2">
                            <h4 className="pt-1 m-0 numbers_font dashboard_text_color"><b>{formatNumber(dashboardData?.appointmentsCount?.rescheduledAppointmentCount)}</b></h4>
                            <p className="m-0 p-0 text_light_small">Rescheduled Appointments</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 pb-3">
                      <div className="rounded shadow_new p-3">
                        <div className="d-flex">
                          <div className="col-lg-3">
                            <div className="position-relative dashboard_icon">
                              <img alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" height={24} width={24} src={round_cancel} />
                            </div>
                          </div>
                          <div className="col-lg-9 ps-2">
                            <h4 className="pt-1 m-0 numbers_font dashboard_text_color"><b>{formatNumber(dashboardData?.appointmentsCount?.cancelledAppointmentCount)}</b></h4>
                            <p className="m-0 p-0 text_light_small">Cancelled Appointments</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 pb-3">
                      <div className="rounded shadow_new p-3">
                        <div className="d-flex">
                          <div className="col-lg-3">
                            <div className="position-relative dashboard_icon">
                              <img alt="" className="img-fluid position-absolute top-50 start-50 translate-middle" height={22} width={22} src={bxs_injection} />
                            </div>
                          </div>
                          <div className="col-lg-9 ps-2">
                            <h4 className="pt-1 m-0 numbers_font dashboard_text_color"><b>{formatNumber(dashboardData?.appointmentsCount?.vaccineCount)}</b></h4>
                            <p className="m-0 p-0 text_light_small">Vaccinations</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">

                    </div>
                    <div className="col-lg-4">

                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-between pb-2">
                    <div className="col-lg-6"><p className="m-0 dashboard_text_color"><b>Upcoming Appointments</b></p></div>
                    <div className="col-lg-6 text-end"></div>
                  </div>
                  <div className="row">
                    <div className="">
                      <div className="rounded shadow_new DASHBOARD_UPCOMING_LIST ">
                        {
                          dashboardData?.upcomingAppointments?.length === 0 && <div className=" h-100 flex-fill d-flex justify-content-center align-items-center">
                            <p className="m-0" style={{ fontSize: "15px", color: 'gray' }}>No upcoming appointments</p>
                          </div>
                        }
                        {
                          dashboardData?.upcomingAppointments?.length > 0 && dashboardData?.upcomingAppointments?.map((cur, index) => {
                            return <div className="d-flex p-3" style={{ borderBottom: "1px dashed gray" }} key={index}>
                              <div className="col-lg-1 pt-1">
                                <Avatar
                                  src={PatientImageFilePath + cur?.patientProfilePicture}
                                  sx={{ width: 45, height: 45 }}
                                />
                              </div>
                              <div className="col-lg-11">
                                <div className="d-flex justify-content-between pb-2">
                                  <div className="col-lg-6 ps-2">
                                    <p className="p-0 m-0 appt_font_weight upcoming_appt dashboard_text_color">{cur?.patientFirstName ? cur?.patientFirstName : ""} {cur?.patientLastName ? cur?.patientLastName : ""}</p>
                                    <p className="m-0 p-0 text_light_small">{cur?.serviceName}</p>
                                    <p className="m-0 p-0 text_light_small">#{cur?.appointmentNumber}</p>
                                  </div>
                                  <div className="col-lg-6 text-end">
                                    <p className="m-0 p-0 text_light_small">{moment(cur?.startDate).calendar()}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="py-3 pt-0 ">
                  <div className="d-flex justify-content-between position-relative pb-2">
                    <div className="col-lg-6"><p className="m-0 dashboard_text_color"><b>Top Services</b></p></div>
                    <div className="col-lg-6 justify-content-end d-flex dashboard_text_color ">
                      <IoFilterSharp style={{ fontSize: "22px", cursor: 'pointer' }} onClick={() => handleClickFilterIcon("topService", { overView: false, toMember: false, })} /> &nbsp; &nbsp; <p className="m-0">Filters</p>
                      <FilterCom
                        dateRangeDropdown={dateRangeDropdown}
                        setDateRangeDropdown={setDateRangeDropdown}
                        getDetails={getDetails}
                        reset={reset}
                        apply={apply}
                        clinicList={clinicList}
                        setFilters={setFilters}
                        open={filerModal?.topService}
                        selectClinic={filters?.topService?.clinic}
                        dateRange={filters?.topService?.dateRange}
                        openModalKey="topService"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className=" shadow_new rounded py-2">
                        <div style={{ width: '100%', height: 300 }}>

                          {
                            dashboardData?.topServices?.length === 0 && <div className=" h-100 flex-fill d-flex justify-content-center align-items-center">
                              <p className="m-0" style={{ fontSize: "15px", color: 'gray' }}>No  top services</p>
                            </div>
                          }
                          {
                            dashboardData?.topServices?.length > 0 && <ResponsiveContainer>
                              <BarChart
                                width={500}
                                height={300}
                                data={dashboardData?.topServices}
                                margin={{
                                  top: 5,
                                  right: 30,
                                  left: 20,
                                  bottom: 5,
                                }}
                                barSize={40}
                              >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="serviceName"
                                  tick={{ fontSize: 11 }}
                                />
                                <YAxis tick={{ fontSize: 14 }} />
                                <Tooltip />
                                <Bar dataKey="count" fill="#38C4B9" activeBar={<Rectangle fill="pink" stroke="#38C4B9" />} />
                              </BarChart>
                            </ResponsiveContainer>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  userdata?.roleTitle !== RoleTitle?.STAFF && <div className="py-2">
                    <div className="d-flex justify-content-between position-relative pb-2">
                      <div className="col-lg-6"><p className="m-0 dashboard_text_color"><b>Top Team Members</b></p></div>
                      <div className="col-lg-6 justify-content-end d-flex dashboard_text_color">
                        <IoFilterSharp style={{ fontSize: "22px", cursor: 'pointer' }} onClick={() => handleClickFilterIcon("toMember", { overView: false, topService: false, })} /> &nbsp; &nbsp; <p className="m-0">Filters</p>
                        <FilterCom
                          dateRangeDropdown={dateRangeDropdown}
                          setDateRangeDropdown={setDateRangeDropdown}
                          getDetails={getDetails}
                          reset={reset}
                          apply={apply}
                          clinicList={clinicList}
                          setFilters={setFilters}
                          open={filerModal?.toMember}
                          selectClinic={filters?.toMember?.clinic}
                          dateRange={filters?.toMember?.dateRange}
                          openModalKey="toMember"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className=" shadow_new rounded py-2">
                          <div style={{ width: '100%', height: 340 }}>

                            {
                              dashboardData?.providerTopTeamMembers?.length === 0 && <div className=" h-100 flex-fill d-flex justify-content-center align-items-center">
                                <p className="m-0" style={{ fontSize: "15px", color: 'gray' }}>No  top team member</p>
                              </div>
                            }
                            {
                              dashboardData?.providerTopTeamMembers?.length > 0 && <ResponsiveContainer>
                                <LineChart
                                  width={500}
                                  height={300}
                                  data={dashboardData?.providerTopTeamMembers}
                                  margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="name" tick={{ fontSize: 11 }} />
                                  <YAxis tick={{ fontSize: 14 }} />
                                  <Tooltip />
                                  <Line connectNulls type="monotone" dataKey="count" stroke="#38C4B9" activeDot={{ r: 8 }} tick={{ fontSize: 11 }} />
                                </LineChart>
                              </ResponsiveContainer>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
};
export default Dashboard;
