import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Errors } from "../../Component/Error";
import { Button } from "@mui/material";
import Loading from "../../Component/Loading";
import { motion } from 'framer-motion';
import OtpInput from "react-otp-input";
import PhoneNumber from "../../Component/PhoneInput";
import useSignUp from "./Hooks/useSignUp";
import { MaskedEmail, TIMEZONE } from "../../Utils/Utils";
const SignUp = () => {
  const {
    loader,
    active,
    formik,
    userData,
    VerifyOTPFun,
    timer,
    createPinFun,
    newUser,
    NewUserPinFun,
    resenOTP,
  } = useSignUp()
  return (
    <div>
      <ToastContainer />
      {loader && <Loading />}
      <div className="container-fluid px-0 Credential_page">
        <div className="container Credential_center_page shadow_hide">
          <div className="row">
            <div className="col-sm-6 login_left_page " style={{ overflowX: "hidden" }}>
              <div className="row">
                <div className="col  h-25 mt-5 mx-5">
                  <img
                    src={require("../../Assets/Images/LOGO 1 (1) 1.png")}
                    alt="winkdoclogo.png"
                    className="img-fluid"
                    width={80}
                    height={80}
                  />
                </div>
              </div>
              <div className="row  login_form d-flex justify-content-center align-items-center   "  >
                {active === 1 &&
                  <motion.div className="col-lg-8 my-5 col-sm-12 col-10"
                    initial={{ x: 300 }}
                    animate={{ x: 0 }}
                    exit={{ x: -300 }}
                    transition={{ duration: 0.5 }}>
                    <h1>Sign Up </h1>
                    <form action="" onSubmit={formik.handleSubmit}>
                      <div className="row mx-1  d-flex login_input_section  align-self-center">
                        <div className="col-10 px-0">
                          {
                            TIMEZONE() === "Asia/Kolkata" ?
                              <PhoneNumber
                                Require={false}
                                Name="phone"
                                HandleChange={formik}
                                Value={formik?.values?.phone}
                                countrySelectComponent="null"
                              />
                              :
                              <input type="email" className="w-100 " placeholder="Enter your email "  {...formik.getFieldProps("email")} name="email"
                              />
                          }
                        </div>
                        <div className="col-2 px-0 d-flex justify-content-end align-items-center">
                          <button className="text-end ">
                            {
                              TIMEZONE() === "Asia/Kolkata" ? <AiOutlinePhone className=" text-secondary" /> : null
                            }
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col" >
                          <Errors formikfun={formik} name={`${TIMEZONE() === "Asia/Kolkata" ? "phone" : "email"}`} />
                        </div>
                      </div>
                      <div className="row mt-4 mb-3">
                        <div className="col">
                          <Button type="submit" variant="contained" className="w-100 fs-6" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px" }}> Continue</Button>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col  text-center fs-6 d-flex align-items-center justify-content-center">
                          <p className="mb-0">Already have an account?</p>
                          <NavLink to={"/login"} style={{ color: "#002080", fontSize: "1rem", cursor: "pointer", marginLeft: "4px", textDecoration: "none", }} >
                            Log In
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  </motion.div>}
                {active === 2 &&
                  <motion.div
                    className="col-lg-8 mt-3 col-sm-12 col-10"
                    initial={{ x: 300 }}
                    animate={{ x: 0 }}
                    exit={{ x: -300 }}
                    transition={{ duration: 0.5 }}>
                    <h1>Verify OTP</h1>
                    <form action="" >
                      <div className="row my-4">
                        <div className="col ps-3" style={{ color: "gray" }}>
                          <span style={{ wordBreak: "break-all" }}>{`We have send OTP on your ${TIMEZONE() === "Asia/Kolkata" ? `******${userData?.mobile?.toString()?.slice(9)}` : MaskedEmail(userData?.email)}`} </span>
                        </div>
                      </div>
                      <div style={{ marginLeft: "-3px" }} id="otp" className="row verify_inputs d-flex w-100 flex-row justify-content-between mt-2">
                        <div className="col px-2 w-100 ">
                          <OtpInput isInputSecure={false} name="otp" onChange={(e) => VerifyOTPFun.setFieldValue("otp", e)} value={VerifyOTPFun.values.otp} numInputs={4}
                            containerStyle={{ width: "100%", display: "flex", justifyContent: "space-between", }}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            inputStyle={{ border: "1px solid #BFBFBF", width: "45px", height: "45px", fontSize: "17px", color: "black", fontWeight: "400", caretColor: "#38C4B9", }}
                            focusStyle={{ border: "1px solid #38C4B9", outline: "none", }}
                          />
                        </div>
                        <div style={{ height: "10px" }}>
                          <Errors formikfun={VerifyOTPFun} name={"otp"} />
                        </div>
                      </div>
                      <div className="row mt-0">
                        <div className="col text-end fs-3  px-3  d-flex justify-content-between align-items-center">
                          <p className="m-0   mt-2" style={{ fontSize: "13px", color: "gray" }}>
                            {timer > 0 && <span>OTP will expire in {timer} Second  </span>}
                          </p>
                          {
                            timer > 0 ? null : <Button type="button" onClick={() => { resenOTP() }} variant="text" style={{ fontSize: "11px", }}> Resend OTP</Button>
                          }
                        </div>
                      </div>
                      <div className="row my-5">
                        <div className="col mt-">
                          <Button type="button" variant="contained" onClick={VerifyOTPFun.handleSubmit} className="w-100 fs-6" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px" }}> Continue</Button>
                        </div>
                      </div>
                    </form>
                  </motion.div>
                }
                {active === 3 &&
                  <motion.div
                    className="col-lg-8 mt-3 col-sm-12 col-10"
                    initial={{ x: 300 }}
                    animate={{ x: 0 }}
                    exit={{ x: -300 }}
                    transition={{ duration: 0.5 }}>
                    {newUser ? <h1>Enter PIN</h1> : <h1>Create PIN</h1>}
                    {
                      newUser ? <form action="" >
                        <div style={{ marginLeft: "-3px" }} id="otp" className="row verify_inputs d-flex w-100 flex-row justify-content-between mt-2" >
                          <div className="col px-2 w-100 ">
                            <OtpInput
                              isInputSecure={false}
                              name="otp"
                              onChange={(e) => NewUserPinFun.setFieldValue("pin", e)}
                              value={NewUserPinFun.values.pin}
                              numInputs={4}
                              containerStyle={{ width: "100%", display: "flex", justifyContent: "space-between", }}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{ border: "1px solid #BFBFBF", width: "45px", height: "45px", fontSize: "17px", color: "black", fontWeight: "400", caretColor: "#38C4B9", }}
                              focusStyle={{ border: "1px solid #38C4B9", outline: "none", }}
                            />
                          </div>
                          <div style={{ height: "10px" }}>
                            <Errors formikfun={NewUserPinFun} name={"pin"} />
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col mt-">
                            <Button type="button" variant="contained" onClick={VerifyOTPFun.handleSubmit} className="w-100 fs-6" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px" }}> Continue</Button>
                          </div>
                        </div>
                      </form> : <form >
                        <div className="row" style={{ height: '100px' }}>
                          <div className="col">
                            <h5 style={{ color: "gray", opacity: " 0.7", fontSize: "18px", }}>
                              New PIN
                            </h5>
                            <OtpInput
                              isInputSecure={false} name="newpin" onChange={(e) => createPinFun.setFieldValue("newpin", e)}
                              value={createPinFun.values.newpin}
                              numInputs={4}
                              containerStyle={{ width: "100%", display: "flex", justifyContent: "space-between", }}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{ border: "1px solid #BFBFBF", width: "45px", height: "45px", fontSize: "17px", color: "black", fontWeight: "400", caretColor: "#38C4B9", }}
                              focusStyle={{ border: "1px solid #38C4B9", outline: "none", }}
                            />
                          </div>
                          <Errors formikfun={createPinFun} name={"newpin"} />
                        </div>
                        <div className="row mt-2" style={{ height: '40px' }}>
                          <div className="col">
                            <h5 style={{ color: "gray", opacity: " 0.7", fontSize: "18px", }}  >
                              Confirm New PIN
                            </h5>
                            <OtpInput
                              isInputSecure={false} name="confirmpin" onChange={(e) => createPinFun.setFieldValue("confirmpin", e)}
                              value={createPinFun.values.confirmpin}
                              numInputs={4}
                              containerStyle={{ width: "100%", display: "flex", justifyContent: "space-between", }}
                              isInputNum={true}
                              shouldAutoFocus={true}
                              inputStyle={{ border: "1px solid #BFBFBF", width: "45px", height: "45px", fontSize: "17px", color: "black", fontWeight: "400", caretColor: "#38C4B9", }}
                              focusStyle={{ border: "1px solid #38C4B9", outline: "none", }}
                            />
                            <Errors formikfun={createPinFun} name={"confirmpin"} />
                          </div>
                        </div>
                        <div style={{ marginLeft: "-2px", height: '40px' }} id="otp" className="row verify_inputs d-flex w-100 flex-row justify-content-between mt-2 " >
                        </div>
                        <div className="row my-5">
                          <div className="col">
                            <Button type="button" onClick={createPinFun.handleSubmit} variant="contained" className="w-100 fs-6" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px" }}> Save</Button>
                          </div>
                        </div>
                      </form>
                    }

                  </motion.div>
                }
              </div>
            </div>
            <div className="col-sm-6 px-0 d-none d-md-block login_right_page">
              {
                active === 1 && <img src={require("../../Assets/Images/forgot 1.png")} alt="" className=" shadow_hide" />
              }
              {
                active === 2 && <img src={require("../../Assets/Images/OTP 1.png")} alt="" className=" shadow_hide" />
              }
              {
                active === 3 && <img src={require("../../Assets/Images/22-removebg-preview 1.png")} alt="" className=" shadow_hide" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
