import  { useRef } from 'react'
import html2canvas from 'html2canvas';

const useHtml2canvas = () => {
    const contentRef = useRef(null);
    const downloadContent = () => {
        // Set the desired height (500px) for the content before capturing it
        // contentRef.current.style.height = '600px';
        html2canvas(contentRef.current).then((canvas) => {
            // Reset the height back to its original value after capturing
            // contentRef.current.style.height = '';
            const downloadLink = document.createElement('a');
            downloadLink.href = canvas.toDataURL('image/png');
            downloadLink.download = 'downloaded_content.png';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    };

    return {
        contentRef,
        downloadContent 
    }
}

export default useHtml2canvas
