import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { RoleTitle, commonMessages } from "../../../Context/constants";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { createpinValidation, loginvalidation, loginvalidationWithEmail, pattern } from "../../../Context/Validation";
import { addUserData } from "../../../Redux/ReducerFile/UserData";
import useReduxData from "../../../Hooks/useReduxData";
import { AddBasicDetails } from "../../../Redux/ReducerFile/BasicDetails";
import { AddBusinessDetails } from "../../../Redux/ReducerFile/BusinessDetails";
import { TIMEZONE } from "../../../Utils/Utils";
import { CREATE_PIN, LOGIN } from "../../Repository/CredentialRepo";
import { getDeviceInfo } from "../../../Utils/getDeviceInfo";
import { AddClinicFildData } from "../../../Redux/ReducerFile/ClinicFildData";
import { CLINIC_LIST } from "../../Repository/ProfileRepo";
const useLogin = () => {
  const [active, setActive] = useState(1)
  const [userList, setUserList] = useState([])
  const [chooseprofile, setChooseProfile] = useState(null)
  const [chooseLocationData, setChooseLocationData] = useState(null)
  const [ChooseLocationList, setChooseLocationList] = useState([])
  const [showPassword, setShowPassword] = useState({ newPin: false, confirmPin: false })
  const navigate = useNavigate();
  const [loader, setloader] = useState(false)
  const initValueForIndia = { phone: "", password: "", }
  const initValueForOther = { email: "", password: "" }
  const { reduxData: UserData, dispatch } = useReduxData("userDataRDX")
  const { deviceType, deviceName } = getDeviceInfo();

  const handleClickShowPassword = (type) => {
    setShowPassword((pre) => ({
      ...pre, [type]: !pre[type]
    }))
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // get choose location
  const getChooseLocation = async () => {
    const payload = {
      activeStatus: true,
      providerId: (chooseprofile?.roleTitle === RoleTitle?.STAFF || chooseprofile?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofile?.providerId : chooseprofile?.userId,
      staffId: (chooseprofile?.roleTitle === RoleTitle?.STAFF || chooseprofile?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofile?.userId : undefined,
      roleTitle: chooseprofile?.roleTitle,
    }
    setloader(true)
    try {
      let response = await CLINIC_LIST(payload)
      setChooseLocationList(response.data)
    } catch (error) {
      setChooseLocationList([])
    } finally {
      setloader(false)
    }
  }

  // login 
  const formik = useFormik({
    initialValues: (TIMEZONE() === "Asia/Kolkata") ? initValueForIndia : initValueForOther,
    validationSchema: (TIMEZONE() === "Asia/Kolkata") ? loginvalidation : loginvalidationWithEmail,
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      if (TIMEZONE() === "Asia/Kolkata") {
        if (values?.phone?.length > 0 && (values?.phone.match(pattern?.PHONE_NO))) {
          delete errors.phone
        } else {
          errors.phone = "Please enter a valid mobile number ";
        }
      }
      return errors;
    },
    onSubmit: async (values, { resetForm }) => {
      const data = {
        // mobile: formatPhoneNumber(values.phone)?.replace(/^0+|\s+/g, ''),
        mobile: `${values.phone}`,
        pin: values.password, timeZone: TIMEZONE(), device: deviceName, deviceType: deviceType
      }
      const dataEmail = { email: values.email, pin: values.password, timeZone: TIMEZONE(), device: deviceName, deviceType: deviceType }
      setloader(true)
      dispatch(AddBasicDetails({}));
      dispatch(AddBusinessDetails({}))
      try {
        let response = await LOGIN((TIMEZONE() === "Asia/Kolkata") ? data : dataEmail)
        setUserList(response.data.profiles)
        setActive(2)
        resetForm()
        setloader(false)
      } catch (error) {
        toast.error(error.message)
        setloader(false)
      }
    },
  });

  const UserLoginFun = (item) => {
    let userRecord = { ...item }
    if (userRecord?.status === 200) {
      setActive(3)
      getChooseLocation()
    } else if (userRecord?.status === 412 || userRecord?.status === 206) {
      localStorage.setItem("S_userdata", JSON.stringify(userRecord));
      // toast.error(userRecord?.signupStatus);
      navigate("/alldetails/basic-details")
    }
    // else if (userRecord?.status === 206) {
    //   localStorage.setItem("S_userdata", JSON.stringify(userRecord));
    //   // toast.error(userRecord?.signupStatus);
    //   navigate("/alldetails/business-details")
    // }
    else if (userRecord?.status === 1006) {
      setActive(4)
      // toast.error(userRecord?.signupStatus);
    } else if (userRecord?.status === 403) {
      toast.error(userRecord?.signupStatus);
    }
    else if (userRecord?.status === 500) {
      toast.error(commonMessages.NetworkError);
    }
    else {
      setloader(false)
      toast.error(item?.message);
    }
  }
  useEffect(() => {
    document.title = "Winkdoc"
  }, [])

  const handleChooseProfile = (data) => {
    setChooseProfile(data)
  }
  const Login = () => {
    if (!chooseLocationData) return
    let userDetails = { ...chooseprofile }
    if (!userDetails.timezoneName) {
      userDetails.timezoneName = TIMEZONE()
    }
    localStorage.clear()
    dispatch(AddBusinessDetails({}))
    dispatch(AddBasicDetails({}));
    dispatch(AddClinicFildData({}));
    navigate("/dashboard")
    dispatch(addUserData(userDetails))
    toast.success(userDetails?.message);
  }
  const handleChooseLocation = (data) => {
    setChooseLocationData(data)
    setChooseProfile((pre) => ({
      ...pre, location: {
        clinicId: data?.clinicId,
        clinicName: data?.clinicName
      }
    }))
  }


  const PinCreate = useFormik({
    initialValues: {
      newpin: '',
      confirmpin: '',
    },
    validationSchema: createpinValidation,
    onSubmit: async (values) => {
      setloader(true)
      let payload = {
        pin: values.newpin,
        confirmPin: values.confirmpin,
        // userId: chooseprofile?.userId,
        userId: chooseprofile?.authId,
        roleTitle: chooseprofile?.roleTitle
      }
      try {
        let response = await CREATE_PIN(payload)
        toast.success(response?.message);
        setloader(false)
        setActive(1)
        PinCreate.resetForm()
      } catch (error) {
        toast.error(error.message)
        setloader(false)
      }
    }
  })

  useEffect(() => {
    if (active === 1) {
      setChooseLocationData(null)
      setChooseProfile(null)
    }

  }, [active])

  return {
    navigate,
    UserLoginFun,
    setActive,
    setChooseProfile,
    handleChooseProfile,
    handleChooseLocation,
    setChooseLocationData,
    Login,
    handleClickShowPassword,
    handleMouseDownPassword,
    PinCreate,
    ChooseLocationList,
    chooseLocationData,
    UserData,
    loader,
    formik,
    userList,
    active,
    chooseprofile,
    showPassword,
  }
}

export default useLogin
