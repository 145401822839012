import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Man from '../../Assets/Images/dummyimg.png';
import { IconButton, TableContainer, Tooltip } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import { addPatinetDetails } from "../../Redux/ReducerFile/Patientdetails";
import Loading from "../../Component/Loading";
import useTableSorting from "../../Hooks/useTableSorting";
import usePatients from "./Hooks/usePatients";
import { PatientImageFilePath } from "../../Context/api";
import { NoRecordFound } from "../../Component/NoRecordFound";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { FiRefreshCcw } from "react-icons/fi";
import viewIcon from "../../Assets/Icons/MicrosoftTeams-image (2).png"
import { Modal } from "react-bootstrap";
const Submember = () => {
    const {
        id,
        headCells,
        loader,
        controller,
        paginationTotalCount,
        petList,
        listpatients,
        filter,
        navigate,
        handleClose,
        dispatch,
        setController,
        handlSearchbox, search, reset, searchvalue, disable, tab, ChangeTab, handleClosedModal, handleDeletePatient, handleOpenModal, OpenModal
    } = usePatients()
    const { CustomButton } = useMuiCompontent()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                <div className="row " >
                    <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 order-lg-0 order-1 col-lg-4 mb-lg-0 mb-2 mt-lg-3 ps-0" >
                                <ul className="nav  mb-0  ">
                                    <li className="nav-item" role="presentation">
                                        <button className={`teams_frofile_stepper_btn me-2 ${tab === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => ChangeTab("Member")} >Member</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className={`teams_frofile_stepper_btn ${tab === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Pet")} >Pet</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 order-lg-1 order-0  col-lg-8  mt-3 px-0 pb-2" >
                                <ul className="mb-0 d-flex justify-content-lg-end justify-content-start ps-0">
                                    <li>
                                        <input type="text" onChange={handlSearchbox} value={searchvalue.patient} placeholder="Search" className="form-control filter_input" name="patient" />
                                    </li>
                                    <li>
                                        <CustomButton variant="contained" className="ms-3" disabled={disable} onClick={search} >search </CustomButton>
                                    </li>
                                    <li className="mx-2 d-flex justify-content-center align-items-center" >
                                        <IconButton onClick={reset} size="small" aria-label="delete" disabled={disable} style={{ color: "#000", cursor: "pointer" }}>
                                            <FiRefreshCcw />
                                        </IconButton>
                                    </li>
                                    <li className=" d-flex justify-content-center align-items-center" >
                                        {
                                            tab === 1 ? <CustomButton variant="contained" onClick={() => { handleClose(); navigate(`/patients/${id && id}/sub-members/add-patient`) }} >Add </CustomButton> :
                                                <CustomButton variant="contained" onClick={() => { handleClose(); navigate(`/patients/${id && id}/sub-members/add-pet`) }} >Add </CustomButton>
                                        }

                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <TableContainer className="">
                            {
                                (filter && listpatients?.length === 0) ?
                                    <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div>
                                    : (!filter && petList?.length === 0) ? <div className="col-sm-12 mt-3 text-center " >
                                        <NoRecordFound />
                                    </div> : null
                            }

                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                {
                                    filter ?
                                        <>
                                            {
                                                listpatients.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={listpatients.length} />
                                            }
                                            <TableBody>
                                                {stableSort(listpatients, getComparator(order, orderBy)).map(
                                                    (row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return <React.Fragment key={index}><TableRow id="hover_tr"  >
                                                            <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                                <img alt="" src={row.image === null ? Man : PatientImageFilePath + row.image} width={45} height={45} style={{ borderRadius: "50%" }} />
                                                            </TableCell>
                                                            <TableCell id={labelId} align="left" >{(row?.firstName ? row?.firstName : "") + "  " + (row?.lastName ? row?.lastName : "")}</TableCell>
                                                            <TableCell id={labelId} align="left"> {row?.gender}</TableCell>
                                                            <TableCell id={labelId} align="left">{row?.mobile}</TableCell>
                                                            <TableCell id={labelId} align="left">{row?.email}</TableCell>
                                                            <TableCell id={labelId} align="right">
                                                                <ul className="actionul w-100 d-flex justify-content-end">
                                                                    <li className="ps-3">
                                                                        <img src={viewIcon} alt="" className='cursor' onClick={() => { navigate(`${row?.id}`, { state: { section: "member" } }); dispatch(addPatinetDetails(row)) }} width={18} height={18} title='view' />
                                                                    </li>
                                                                    <li className="ps-2">
                                                                        <Tooltip title="Delete patient " placement="left" style={{ cursor: "pointer" }}>
                                                                            <div>
                                                                                <i className="fa-solid fa-trash-can all_icons_color" onClick={() => handleOpenModal("deletePatient", row)}></i>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                        </TableRow>
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </TableBody>
                                        </>
                                        : <>
                                            {
                                                petList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={petList?.length} />
                                            }
                                            <TableBody>
                                                {stableSort(petList, getComparator(order, orderBy)).map(
                                                    (row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return <React.Fragment key={index}><TableRow id="hover_tr"  >
                                                            <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                                <img alt="" src={row.image === null ? Man : PatientImageFilePath + row.image} width={45} height={45} style={{ borderRadius: "50%" }} />
                                                            </TableCell>
                                                            <TableCell id={labelId} align="left" >{row?.firstName}</TableCell>
                                                            <TableCell id={labelId} align="left"> {row?.gender}</TableCell>
                                                            <TableCell id={labelId} align="left">{row?.petType === "other" ? row?.petTypeField : row?.petType}</TableCell>
                                                            <TableCell id={labelId} align="right">
                                                                <ul className="actionul w-100 d-flex justify-content-end">
                                                                    <li className="ps-3">
                                                                        <img src={viewIcon} alt="" className='cursor' onClick={() => { navigate(!id ? `${row?.patientId}` : `${row?.id}`, { state: { section: "pet" } }) }} width={18} height={18} title='view' />
                                                                    </li>
                                                                    <li className="ps-2">
                                                                        <Tooltip title="Delete patient " placement="left" style={{ cursor: "pointer" }}>
                                                                            <div>
                                                                                <i className="fa-solid fa-trash-can all_icons_color" onClick={() => handleOpenModal("deletePatient", row)}></i>
                                                                            </div>
                                                                        </Tooltip>
                                                                    </li>
                                                                </ul>
                                                            </TableCell>
                                                        </TableRow>
                                                        </React.Fragment>
                                                    }
                                                )}
                                            </TableBody>
                                        </>
                                }
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <nav className="row" aria-label="pagination">
                    <div className="col-12">
                        {
                            !id && paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
                        }
                    </div>
                </nav>
            </div>
            <Modal show={OpenModal?.deletePatient} onHide={() => handleClosedModal("deletePatient")} centered>
                <Modal.Body className='px-0'>
                    <div className="modal-body text-center cus-i px-0">
                        <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to delete this member</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleDeletePatient("YES")} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleDeletePatient("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Submember;