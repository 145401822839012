/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading";
import { Chip } from "@mui/material";
import { motion } from 'framer-motion';
import { SPECIALIZATION_LIST_WITH_SERVICES_STAFF, UPDATE_SPECIALIZATION_WITH_SERVICES } from "../Repository/StaffRepo";
import useReduxData from "../../Hooks/useReduxData";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { RoleTitle } from "../../Context/constants";
const TeamSpecialization = () => {
    const [apiSpecialisedValues, setApiSpecializationvalue] = useState([])
    const [SpecializationData, setSpecializationData] = useState([])
    const [loader, setLoader] = useState(false)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const { CustomButton } = useMuiCompontent()
    const navigate = useNavigate()
    const { id } = useParams()
    const isFrontDeskProfile = StaffDetails?.roleId === RoleTitle?.FRONT_DESK
    useEffect(() => {
        getSpecializationListwithServiceList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getSpecializationListwithServiceList = async () => {
        const payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            staffId: StaffDetails?.userId,
            clinicId: +id,
        }
        setLoader(true)
        try {
            let response = await SPECIALIZATION_LIST_WITH_SERVICES_STAFF(payload)
            let responseData = response?.data
            let setValues = []
            responseData.forEach((specialisation) => {
                let serviceArray = []
                specialisation.services.forEach((service) => {
                    if (service?.isChecked) {
                        serviceArray.push(service.serviceId)
                    }
                })
                setValues.push({ specializationId: specialisation.specializationId, service: serviceArray })
            })
            setApiSpecializationvalue(setValues)
            setSpecializationData(response?.data)
            setLoader(false)
        } catch (error) {
            setSpecializationData([])
            setLoader(false)
        }
    }

    // statuschange
    const handleSubmit = async () => {
        let servicesdata = []
        let data = [...apiSpecialisedValues]
        data?.forEach((cur) => {
            cur.service.forEach((item => {
                servicesdata?.push(item)
            }))
        })
        if (!servicesdata.length) {
            return toast.warn("Please assign at least one service")
        }
        let payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
            staffId: StaffDetails?.userId,
            services: servicesdata,
            clinicId: +id
        }
        setLoader(true)
        try {
            let response = await UPDATE_SPECIALIZATION_WITH_SERVICES(payload)
            toast.success(response?.message)
            getSpecializationListwithServiceList()
            setLoader(false)
        } catch (error) {
            toast.error(error?.message)
            setLoader(false)
        }
    }
    const allselect = (e, specializationId, specializationIndex) => {
        const checked = e.target.checked;
        let localData = [...apiSpecialisedValues];
        let apiValues = [...SpecializationData];
        if (checked === false) {
            localData.forEach((specialisation) => {
                if (specialisation.specializationId === specializationId) {
                    specialisation.service = [];
                }
            });
            apiValues[specializationIndex].services.forEach((service) => service.isChecked = false);

        } else {
            localData.forEach((specialisation) => {
                if (specialisation.specializationId == specializationId) {
                    apiValues[specializationIndex].services.forEach((service) => {
                        if (!specialisation.service.includes(service.serviceId)) {
                            specialisation.service.push(service.serviceId);
                        }
                        service.isChecked = true;
                    });
                }
            });
        }

        setApiSpecializationvalue(localData);
        setSpecializationData(apiValues);
    };

    const handleChange = (datas, specializationId, serviceIndex, specializationIndex) => {
        if (isFrontDeskProfile) return
        const checked = datas.isChecked
        let localData = [...apiSpecialisedValues]
        let apiValues = [...SpecializationData]
        if (checked === false) {
            apiValues[specializationIndex].services[serviceIndex].isChecked = true
            localData.forEach((specialisation) => {
                if (specialisation.specializationId === specializationId) {
                    specialisation.service.length ? specialisation.service.push(datas.serviceId) : specialisation.service = [datas.serviceId]
                }
            })
        } else {
            apiValues[specializationIndex].services[serviceIndex].isChecked = false
            localData.forEach((specialisation) => {
                if (specialisation.specializationId === specializationId) {
                    specialisation.service = specialisation.service.filter((serviceId) => serviceId !== datas.serviceId)
                }
            })
        }
        setApiSpecializationvalue(localData)
        setSpecializationData(apiValues)
    }

    return (
        <>
            {loader && <Loading />}
            <motion.div className=" common-component-box  "
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >

                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/team/details/location")} />
                        <h2 className='page_name'>Specialization & Services</h2>
                    </div>
                </header>

                <div className="common-white-bg-box d-flex justify-content-between flex-column  mx-0    shadow px-3">
                    <div className=" mt-4">
                        {
                            SpecializationData?.map((item, specializationIndex) => {
                                if (item?.services?.length > 0) {
                                    return <React.Fragment key={specializationIndex}>
                                        <div className="col-12 Specialization_list_with_service px-3 ">
                                            <div className="Specialization_box justify-content-between">
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor={"jjj"}>
                                                        {item?.specializationName}
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="form-check-label me-2" htmlFor={item?.specializationId}>
                                                        Select All
                                                    </label>
                                                    <input disabled={isFrontDeskProfile} className="form-check-input cursor" type="checkbox" id={item?.specializationId}
                                                        checked={item?.services?.length > 0 ? item.services.every((check) => check.isChecked) : false}
                                                        onChange={(e) => allselect(e, item.specializationId, specializationIndex)}
                                                    />
                                                </div>
                                            </div>
                                            <hr className="mt-0" />
                                            <div className="services_box">
                                                {
                                                    item?.services?.map((cur, serviceIndex) => {
                                                        return <Chip key={serviceIndex} label={cur?.serviceName}
                                                            style={{ color: ` ${cur?.isChecked ? "#fff" : "#000"}`, background: ` ${cur?.isChecked ? "#12BABA" : "#ebebeb"}`, cursor: "pointer" }}
                                                            className="mb-2"
                                                            onClick={() => handleChange(cur, item?.specializationId, serviceIndex, specializationIndex)}
                                                        />
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </React.Fragment>
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>

                    <div className="d-flex justify-content-end pb-2 ">
                        {
                            SpecializationData?.length > 0 && <CustomButton variant="contained" className="" onClick={handleSubmit} > Update</CustomButton>
                        }
                    </div>
                </div>

            </motion.div>
        </>
    )
}

export default TeamSpecialization
