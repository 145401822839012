import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { clinicPdf } from "../../../Context/api";
import { addClinicValidation } from "../../../Context/Validation";
import { useNavigate } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import { AddClinicFildData } from "../../../Redux/ReducerFile/ClinicFildData";
import { COUNTRY_LIST, STATE_LIST } from "../../Repository/CommonRepo";
import { INSERT_CLINIC, UPLOAD_FILE_PDF } from "../../Repository/ProfileRepo";
const useAddClinic = (type) => {
    const [ListState, setListState] = useState([])
    const [ListDataCountry, setListDataCountry] = useState([])
    const [check, setCheck] = useState(true)
    const [policyData, setPolicyData] = useState('')
    const { reduxData: UserData, dispatch } = useReduxData("userDataRDX")
    const SignupUserData = JSON.parse(localStorage.getItem("S_userdata"))
    const { reduxData: ClinicFildData } = useReduxData("ClinicFildDataRDX")
    const getData = JSON.parse(localStorage?.getItem("service"))
    const [show, setShow] = useState(false)
    const [loader, setLoader] = useState(false)
    const [clinicToggle, setClinicToggle] = useState(false)
    const [DisplayPicture, setDisplayPicture] = useState(null)
    const FileRef = useRef(null)
    const pdfRep = useRef(null)
    const [specializationWithService, setspecializationWithService] = useState({
        service: [],
        specialization: []
    })
    const navigate = useNavigate()
    useEffect(() => {
        listcountrys()
        let servicesId = []
        let specializationId = [];
        getData?.forEach((cur) => {
            if (cur?.service.length > 0) {
                specializationId.push(cur?.specializationId)
            }
            cur?.service?.forEach((item) => {
                servicesId?.push(item)
            })
        })
        setspecializationWithService({
            service: servicesId,
            specialization: specializationId
        })
        if (Object.keys(ClinicFildData).length !== 0) {
            formik?.setValues(ClinicFildData)
            setPolicyData(ClinicFildData?.policyData)
            setCheck(ClinicFildData?.check)
            setDisplayPicture(ClinicFildData?.DisplayPicture)
            if (ClinicFildData?.country) {
                handalchangeCountry(ClinicFildData?.country)
            }
        }
        localStorage.setItem("addClinic", JSON.stringify(true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // get list counry api
    async function listcountrys() {
        let payload = {
            activeStatus: true
        }
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
        } catch (error) {
            setListDataCountry([])
        }
    }
    const uploadPdf = async (e) => {
        if (!e) return
        const fd = new FormData()
        const fileTypes = { pdf_File_Type: ['application/pdf'] }
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!e.type.includes(fileTypes.pdf_File_Type)) return toast.warn('Only PDF format allowed')
        fd.append('file', e)
        fd.append('type', 'CLINICPROOF')
        fd.append('previousFileName', policyData)
        fd.append('id', null)
        setLoader(true)
        try {
            let response = await UPLOAD_FILE_PDF(fd)
            pdfRep.current.value = null;
            toast.success(response.message)
            setPolicyData(response.data?.db_name)
            setLoader(false)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }

    }
    // add clinic 
    const formik = useFormik({
        initialValues: {
            clinicName: "",
            registrationNumber: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zipCode: "",
        },
        validationSchema: addClinicValidation,
        onSubmit: async (values) => {
            if (specializationWithService?.service.length === 0) {
                return toast.warn(" Please select services ");
            }
            if (!policyData) {
                return toast.warn(" Please upload proof ");
            }
            let payload = {
                providerId: (type === "LOGIN" && SignupUserData?.userId) ? SignupUserData?.userId : UserData.userId,
                businessId: type === "LOGIN" ? SignupUserData?.businessProfileId : UserData?.businessProfileId,
                clinicName: values?.clinicName,
                clinicRegistrationNumber: values.registrationNumber,
                proof: policyData,
                services: specializationWithService?.service,
                activeStatus: check,
                address: values?.address,
                street: values.street,
                city: values.city,
                stateId: values.state?.[0]?.stateId,
                countryId: values?.country?.[0]?.id,
                zip: values.zipCode,
                image: DisplayPicture
            };
            setLoader(true)
            try {
                let response = await INSERT_CLINIC(payload)
                toast.success(response.message);
                if (type !== "LOGIN" && UserData.userId) {
                    navigate(setTimeout(() => navigate('/profile'), 1500))
                    localStorage.setItem("addClinic", JSON.stringify(true))
                } else {
                    navigate(setTimeout(() => navigate('/alldetails/business-details'), 1500))
                }
                localStorage.removeItem("service");
                dispatch(AddClinicFildData({}))
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        },
    });
    // 
    const handalchangeCountry = async (value, name) => {
        formik.setFieldValue(name, value)
        let payload = {
            countryId: value?.[0]?.id,
            activeStatus: true
        }
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
        } catch (error) {
            setListState([])
            toast.error(error.message)
            formik.setFieldValue("state", [])
        }

    }
    const addClinicFildDataFun = () => {
        let data = {
            ...formik?.values,
            policyData,
            check: check,
            DisplayPicture: DisplayPicture
        }
        dispatch(AddClinicFildData(data))
    }
    const handleClose = (event) => {
        if (event === "YES") {
            setCheck(!check)
            setClinicToggle(false)
        } else {
            setClinicToggle(false)
        }
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            handalchangeCountry(newValue, name)
            formik.setFieldValue("state", [])
        } else {
            formik.setFieldValue(name, newValue)
        }
    }
    return {
        UserData,
        formik,
        loader,
        ListDataCountry,
        ListState,
        check,
        show,
        clinicPdf,
        policyData,
        specializationWithService,
        clinicToggle,
        DisplayPicture,
        FileRef,
        pdfRep,
        setPolicyData,
        uploadPdf,
        setShow,
        addClinicFildDataFun,
        setClinicToggle,
        setDisplayPicture,
        handleClose,
        handalSearchAbleSelectBox,
        setLoader
    }
}

export default useAddClinic
