import React, { useEffect } from "react";
import "moment-timezone";
import "rc-time-picker/assets/index.css";
import moment from "moment-timezone";
import Loading from "../../Component/Loading";
import { Avatar, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import useAvailability from "./Hooks/useAvailability";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { DeleteAvailabiltyModal, DeleteUnAvailabiltyModal, NotFoundClinicModal, SetUnAvailabilityModal } from "./Compontent/UnAvailability";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clinicPictures } from "../../Context/api";
const Availability = () => {
  const {
    navigate,
    setDeleteData,
    SetUnavailabilityAdd,
    removeSelection,
    removetimeUnavailability,
    SetUnavailabilityDetele,
    addtimesUnavailability,
    setClinicModal,
    handleClose,
    setTap,
    SetavailabilityDetele,
    handleDeleteAvailability,
    setDeleteAvailabilityDetails,
    availabilityDetele,
    CustomButton,
    tap,
    loader,
    avilabilityList,
    unavilabilityList,
    UnavailabilityAdd,
    addUnavailabililty,
    clinicList,
    date,
    unAvailabilityDefault,
    clinicModal,
    UnavailabilityDetele,
    userdata, handalSearchAbleSelectBox,
    listTimeZone, anchorEl, setAnchorEl, open,
    isFrontDeskPermission
  } = useAvailability()
  useEffect(() => {
    document.title = "Availability"
  }, [])
  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Current Availability</h2>
          </div>
        </header>
        <div className="row common-white-bg-box mx-0 shadow">
          <div className="col-sm-12" style={{ height: "100%" }}>
            <div className="d-flex justify-content-between">
              <ul className="nav  mb-0  " >
                <li className="nav-item" role="presentation">
                  <button className={`teams_frofile_stepper_btn me-2 ${tap === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setTap(1)} >Availability</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`teams_frofile_stepper_btn ${tap === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTap(2)} >Unavailability</button>
                </li>
              </ul>

              <Button
                variant="contained"
                style={{ color: "black", background: "#e0e0e0", textTransform: "capitalize" }}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => setAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                set
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => { setAnchorEl(null); navigate("/availability/set-availability") }}>Availability</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); SetUnavailabilityAdd(true); navigate("set-unavailability") }}>Unavailability</MenuItem>
              </Menu>



            </div>

            <div className="tab-content" id="myTabContent" >
              {
                tap === 1 && <div>
                  <div className="row availability__">
                    {avilabilityList?.length === 0 &&
                      <div className="col-sm-12 mt-3 text-center " >
                        <NoRecordFound />
                      </div>
                    }
                    {
                      avilabilityList?.map((cur, index) => {
                        return <React.Fragment key={cur?.id}>
                          <div className="col-sx-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 availability__box  pt-2 mb-5">
                            <div className="col shadow  p-2 rounded"  >
                              <div className="d-flex justify-content-between align-items-center py-2 w-100" style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="d-flex align-items-center" style={{ width: 'calc(100% - 20px)' }} >
                                  <Avatar src={clinicPictures + cur?.clinicImage} className="me-2" sx={{ width: 40, height: 40 }} />
                                  <div style={{ width: 'calc(100% - 40px)' }}>
                                    <h5 className="mb-0 me-3 location TEXT_ELLIPSIS"> {`${cur?.clinicName.charAt(0).toUpperCase()}${cur.clinicName.slice(1)}`}</h5>
                                    <h5 className="mb-0 me-3 location TEXT_ELLIPSIS">{`${cur?.city?.charAt(0)?.toUpperCase()}${cur?.city?.slice(1)}`}</h5>
                                  </div>
                                </div>
                                {
                                  !isFrontDeskPermission && <div className="text-end" >
                                    <Tooltip className="" title="Delete" placement="left" style={{ cursor: "pointer" }}>
                                      <i className="fa-solid fa-trash-can all_icons_color"
                                        onClick={() => { SetavailabilityDetele(true); setDeleteAvailabilityDetails(cur) }}
                                      ></i>
                                    </Tooltip>

                                    <Tooltip title="Edit" placement="top" style={{ cursor: "pointer" }}>
                                      <i className="fa-solid fa-pen-to-square all_icons_color" onClick={() => { navigate(`/availability/${cur.id}`, { state: { oldFromDate: cur?.fromDate, oldToDate: cur?.toDate } }); localStorage.setItem("clinicId", JSON.stringify(cur.clinicId)) }} ></i>
                                    </Tooltip>
                                  </div>
                                }

                              </div>
                              <div className="d-flex py-2" style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="col-6">
                                  <ul>
                                    <li><h5 className="mb-0 location">Start Date</h5></li>
                                    <li> <p className="time_zone mb-1">{`${moment(cur.startDate)?.tz(userdata?.timezoneName)?.format('ll')} `}</p></li>
                                  </ul>
                                </div>
                                <div className="col-6 d-flex flex-column align-items-end  ">
                                  <ul>
                                    <li><h5 className="mb-0 location">End Date</h5></li>
                                    <li> <p className="time_zone mb-1">{`${moment(cur.EndDate)?.tz(userdata?.timezoneName)?.format('ll')} `}</p></li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-   AVAILABILITY-TIME-OVERFLOW">
                                {
                                  cur.availableDays?.map((availableDays, availableDaysIndex) => {
                                    return (
                                      <React.Fragment key={availableDays?.id || availableDaysIndex}>
                                        <div className="row availability_time_day mx-0 px-0 mb-2 mt-2">
                                          <div className="col-3 day"><p>{availableDays?.name}</p></div>
                                          <div className="col-9 time">
                                            <ul>
                                              {availableDays?.isChecked ?
                                                availableDays?.arrayOfTimings?.map((time, index2) => {
                                                  return (
                                                    <li key={time?.id || index2}>
                                                      {`${moment(time?.startTime)?.tz(userdata?.timezoneName)?.format("hh:mm A")}-${moment(time?.endTime)?.tz(userdata?.timezoneName)?.format("hh:mm A")}`}
                                                    </li>
                                                  );
                                                }) : <li key="closed">Closed</li>
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      </React.Fragment>
                                    );
                                  })
                                }

                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      })
                    }
                  </div>
                </div>
              }


              {/* unAvailability list */}

              {
                tap === 2 && <div >
                  <div className="row">
                    {unavilabilityList?.length === 0 &&
                      <div className="col-sm-12 mt-3 text-center " >
                        <NoRecordFound />
                      </div>
                    }
                    {
                      unavilabilityList?.map((cur, index) => {
                        return <React.Fragment key={cur?.unavailabilityId || index}>
                          <div className="col-sx-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3  mb-4 mt-3 " >
                            <div className="avail-col2 shadow">
                              <div className="d-flex justify-content-between align-items-center w-100 py-2 mb-2 w-100" style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="d-flex align-items-center" style={{ width: 'calc(100% - 20px)' }}>
                                  <Avatar src={clinicPictures + cur?.clinicImage} className="me-2" sx={{ width: 40, height: 40 }} />
                                  <div style={{ width: 'calc(100% - 40px)' }}>
                                    <h5 className="mb-0 location me-3  TEXT_ELLIPSIS"> {`${cur.clinicName.charAt(0).toUpperCase()}${cur.clinicName.slice(1)}`}</h5>
                                    <h5 className="mb-0 location me-3  TEXT_ELLIPSIS" style={{ color: "gray", fontWeight: "400" }}>{`${cur?.city.charAt(0).toUpperCase()}${cur?.city.slice(1)}`}</h5>
                                  </div>
                                </div>
                                {
                                  !isFrontDeskPermission && <Tooltip title="Delete" placement="left" style={{ cursor: "pointer" }}>
                                    <i  className="fa-solid fa-trash-can all_icons_color"
                                      onClick={() => { SetUnavailabilityDetele(true); setDeleteData({ clinicId: cur.clinicId, id: cur?.unavailabilityId }) }}
                                    ></i>
                                  </Tooltip>
                                }

                              </div>
                              <div className="d-flex py-2 mb-2" style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="col-6">
                                  <ul>
                                    <li><h5 className="mb-0 location">Start Date</h5></li>
                                    <li> <p className="time_zone mb-1">{moment(cur?.startDate)?.tz(userdata?.timezoneName)?.format('ll')}</p></li>
                                  </ul>
                                </div>
                                <div className="col-6 d-flex flex-column align-items-end  ">
                                  <ul>
                                    <li><h5 className="mb-0 location">End Date</h5></li>
                                    <li> <p className="time_zone mb-1">{moment(cur?.endDate)?.tz(userdata?.timezoneName)?.format('ll')}</p></li>
                                  </ul>
                                </div>
                              </div>



                              {/* <div className="d-flex justify-content-between">
                                <p className="mb-0">{`${cur.clinicName.charAt(0).toUpperCase()}${cur.clinicName.slice(1)}`}</p>
                                <p className="mb-0">{`${cur?.city.charAt(0).toUpperCase()}${cur?.city.slice(1)}`}</p>
                              </div>
                              <hr className="mb-2 mt-2" />
                              <div className="row mb-3">
                                <div className="col-12">
                                  <input type="text" className="form-control timezon_input" id="exampleFormControlInput1" placeholder={`${cur?.timezone}`} value="" disabled />
                                </div>
                              </div> */}
                              {/* <div className="w-100 mb-2 d-flex align-items-center justify-content-between ">
                                <div className="d-flex justify-content-between align-items-center form-control" style={{ padding: ".2rem .2rem" }}   >
                                  <small>{moment(cur?.dateFrom)?.tz(userdata?.timezoneName)?.format('D-MM-YYYY')}</small>
                                  <i className="fa-solid fa-calendar all_icons_color"></i>
                                </div>
                                <p className="mb-0 mx-2">To</p>
                                <div className="d-flex justify-content-between form-control align-items-center" style={{ padding: ".2rem .2rem" }}>
                                  <small>{moment(cur?.dateTo)?.tz(userdata?.timezoneName)?.format('D-MM-YYYY')}</small>
                                  <i className="fa-solid fa-calendar all_icons_color"></i>
                                </div>
                              </div> */}


                              {
                                cur?.arrayOfTimings?.map((curs, indexs) => {
                                  return <React.Fragment key={indexs}>
                                    <div className=" mb-2 d-flex align-items-center justify-content-between" >
                                      <div className="d-flex justify-content-between cus-border-unavailablety" style={{ padding: ".3rem .5rem" }} >
                                        <small>{moment(curs?.startTime)?.tz(userdata?.timezoneName)?.format("hh:mm ")}</small>
                                        <div style={{ width: "1px", background: "#38C4B9" }}></div>
                                        <small>{moment(curs?.startTime)?.tz(userdata?.timezoneName)?.format(" A")}</small>
                                      </div>
                                      <p className="mb-0 mx-2">To</p>
                                      <div className="d-flex justify-content-between cus-border-unavailablety" style={{ padding: ".3rem .5rem" }}  >
                                        <small>{moment(curs?.endTime)?.tz(userdata?.timezoneName)?.format('hh:mm')}</small>
                                        <div style={{ width: "1px", background: "#38C4B9" }}></div>
                                        <small>{moment(curs?.endTime)?.tz(userdata?.timezoneName)?.format('A')}</small>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                })
                              }
                              {/* <div className=" text-end edit-icon mt-3 action_btn_availability">
                                <Tooltip title="Delete" placement="left" style={{ cursor: "pointer" }}>
                                  <i className="fa-solid fa-trash-can all_icons_color"
                                    onClick={() => { SetUnavailabilityDetele(true); setDeleteData({ clinicId: cur.clinicId, id: cur?.id }) }}
                                  ></i>
                                </Tooltip>
                              </div> */}
                            </div>
                          </div>
                        </React.Fragment>
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* SET unavailability */}
      <SetUnAvailabilityModal SetUnavailabilityAdd={SetUnavailabilityAdd} UnavailabilityAdd={UnavailabilityAdd} addUnavailabililty={addUnavailabililty} unAvailabilityDefault={unAvailabilityDefault} moment={moment} handalSearchAbleSelectBox={handalSearchAbleSelectBox} clinicList={clinicList} listTimeZone={listTimeZone}
        date={date} addtimesUnavailability={addtimesUnavailability} removeSelection={removeSelection} CustomButton={CustomButton} removetimeUnavailability={removetimeUnavailability} />
      <DeleteUnAvailabiltyModal CustomButton={CustomButton} UnavailabilityDetele={UnavailabilityDetele} SetUnavailabilityDetele={SetUnavailabilityDetele} handleClose={handleClose} />
      <DeleteAvailabiltyModal SetavailabilityDetele={SetavailabilityDetele} availabilityDetele={availabilityDetele} handleDeleteAvailability={handleDeleteAvailability} CustomButton={CustomButton} />
      <NotFoundClinicModal clinicModal={clinicModal} setClinicModal={setClinicModal} CustomButton={CustomButton} navigate={navigate} />
    </>
  );
};

export default Availability;
