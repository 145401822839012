import React from 'react'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
const SYF = () => {
    const { CustomButton } = useMuiCompontent()
    return (
        <>
            <div className="row d-flex justify-content-between flex-column " style={{ height: "100%" }}>
                <div className="col-sm-12  py-4 " >
                    {/* <div className="row">
                        <div className="col-3">
                            <label className="form-label mb-0">Enter Feedback<span className='Star_Mark'>*</span></label>
                        </div>
                        <div className="col-4">
                            <textarea className="form-control" cols="4" rows="6" name="description" />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="row">
                                <div className="col-lg-5">
                                    <label className="form-label mb-0">Enter Feedback<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-lg-7">
                                    <textarea className="form-control" cols="4" rows="6" name="description" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                        </div>
                    </div>
                </div>
                <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-2">
                    <CustomButton variant="contained" >Save</CustomButton>
                </div>
            </div>
        </>
    )
}

export default SYF
