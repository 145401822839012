import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading";
import moment from "moment-timezone";
import { useReactToPrint } from 'react-to-print';
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { Tooltip } from "@mui/material";
import { RoleTitle } from "../../Context/constants";
import { FaArrowLeft } from "react-icons/fa";
import { GET_CHECKOUT_SLIP_DETAILS } from "../Repository/AppointmentsRepo";
import useReduxData from "../../Hooks/useReduxData";
import { Dosage } from "../../Utils/Utils";

const CheckoutSlip = ({ type }) => {
    const [loader, setLoader] = useState(false)
    const { id, subpatientId, ApptId } = useParams()
    const [checkoutSlipData, setcheckoutSlipData] = useState({})
    const { CustomButton } = useMuiCompontent()
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const navigate = useNavigate()
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const getListSelectedMedicines = async () => {
        setLoader(true)
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: userData.roleTitle === RoleTitle.STAFF ? userData?.providerId : userData?.userId,
            appointmentId: ApptId,
            roleTitle: userData.roleTitle,
        }

        try {
            let response = await GET_CHECKOUT_SLIP_DETAILS(payload)
            setcheckoutSlipData(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setLoader(false)
            setcheckoutSlipData({})
        }
    }
    useEffect(() => {
        getListSelectedMedicines()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => {
                            if (type === "Patients") {
                                navigate(`/patients/${id}/checkout-slip`)
                            } else {
                                navigate(`/patients/${id}/sub-members/${subpatientId}/checkout-slip`)
                            }
                        }} />
                        <h2 className='page_name'>Checkout Slip</h2>
                    </div>
                </header>
                <div className="row common-white-bg-box mx-0 shadow d-flex align-content-start  position-relative px-2">
                    <div className='p-0 pt-2 text_box_height' >
                        <div className="row px-0 mx-0">
                            <div className="col-3 ms-auto d-flex justify-content-end pe-0 mb-2">
                                <Tooltip title=" Download PDF " placement="left" style={{ cursor: "pointer" }}>
                                    <CustomButton
                                        onClick={handlePrint}
                                        variant="contained"  >print</CustomButton>
                                </Tooltip>
                            </div>
                            <div className="container bg p-0" ref={componentRef}>
                                <div className="col-lg-12 bg2">
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="imgs p-2 ms-2"><img src={require("../../Assets/UploadDocumentImage/profile.png")} alt="" /></div>
                                        <div    >
                                            <h3 className="text-lig">{checkoutSlipData?.clinicName}</h3>
                                            <p className="m-0 text-lig">
                                                {` ${checkoutSlipData?.city?.charAt(0)?.toUpperCase()}${checkoutSlipData?.city?.slice(1)}, ${checkoutSlipData?.state?.charAt(0).toUpperCase()}${checkoutSlipData?.state?.slice(1)}, ${checkoutSlipData?.country?.charAt(0)?.toUpperCase()}${checkoutSlipData?.country?.slice(1)},${checkoutSlipData?.zipCode}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between pt-3 px-3 pb-1 appointment_details">
                                    <p><b>Patient Name :</b> {(checkoutSlipData?.patientFirstName ? checkoutSlipData?.patientFirstName : "") + " " + (checkoutSlipData?.patientLastName ? checkoutSlipData?.patientLastName : "")}</p>
                                    <p><b>Appointment Date/Time :</b>{`${moment(checkoutSlipData?.apptStartDate)?.format("DD/MM/YYYY")} , ${moment(checkoutSlipData?.apptStartDate)?.format("hh:mm A")}  `}</p>
                                </div>
                                <div className="px-2 bgimg">
                                    <div className="cover-border px-3 py-3">
                                        <div className="d-flex  justify-content-start align-items-center mb-2">
                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone  ms-2 me-3" alt="" />
                                            <h5 >Vitals</h5>
                                        </div>
                                        <div className="bordrbottom">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>Blood Pressure</h6>
                                                    <p className="p-text">{checkoutSlipData?.bp ? checkoutSlipData?.bp : "-"}</p>
                                                </div>
                                                <div className="col">
                                                    <h6>Pulse</h6>
                                                    <p className="p-text">{checkoutSlipData?.pulse ? checkoutSlipData?.pulse : "-"}</p>
                                                </div>
                                                <div className="col">
                                                    <h6>Weight</h6>
                                                    <p className="p-text">{checkoutSlipData?.weight ? (`${checkoutSlipData?.weight} Kg`) : "-"}</p>
                                                </div>
                                                <div className="col">
                                                    <h6>Height</h6>
                                                    <p className="p-text">{checkoutSlipData?.height ? (`${checkoutSlipData?.height} Cm`) : "-"}</p>
                                                </div>
                                                <div className="col">
                                                    <h6>Temperature</h6>
                                                    <p className="p-text">{checkoutSlipData?.temperature && checkoutSlipData?.temperature}<sup>&#176;</sup>F</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone  ms-2 me-3" alt="" />
                                            <h5 >Symptoms</h5>
                                        </div>
                                        <div className="bordrbottom">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="p-text">
                                                        {checkoutSlipData?.symptom?.map((item, indexSym) => <span key={indexSym} className='ms-1 mt-1'>{item?.symptoms}{checkoutSlipData?.symptom?.length > indexSym + 1 ? ',' : ''}</span>)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone ms-2 me-3" alt="" />
                                            <h5 >Chronic Disease</h5>
                                        </div>
                                        <div className="bordrbottom">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="p-text">
                                                        {checkoutSlipData?.chronicDisease?.map((item, indexpre) => <span key={indexpre} className='ms-1 mt-1'>{item?.chronicDiseases}{checkoutSlipData?.symptom?.length > indexpre + 1 ? ',' : ''}</span>)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                            <img src={require("../../Assets/UploadDocumentImage/prescription.png")} className="icone  ms-2 me-3" alt="" />
                                            <h5 >RX</h5>
                                        </div>
                                        <div className="bordrbottom">
                                            <table className="table bootstrap_table_responsive">
                                                <thead >
                                                    <tr >
                                                        <td style={{ backgroundColor: "transparent" }}>Medicine</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Strength</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Standard</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Duration</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Dosage Instructions</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Quantity</td>
                                                        <td style={{ backgroundColor: "transparent" }}>Notes</td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {checkoutSlipData?.rx?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item?.medicineName}</td>
                                                            <td>{item?.doseAmount + item?.doseType}</td>
                                                            {/* <td>{` ${item?.medicineIntakeType === "STANDARD" ? `${item?.morningMedCount}-${item?.afternoonMedCount}-${item?.nightMedCount}` : `Every ${item?.medicineIntakeDurationAmount + item?.customMedCountTimeFrame}`} `}</td> */}
                                                            <td>{Dosage(item)}</td>
                                                            <td>{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</td>
                                                            <td> {item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</td>
                                                            <td>{item?.medicineIntakeAmount}</td>
                                                            <td>{item?.notes}</td>
                                                        </tr>
                                                    )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                            <img src={require("../../Assets/UploadDocumentImage/Doctor.png")} className="icone ms-2 me-3" alt="" />
                                            <h5 >Diagnosis</h5>
                                        </div>
                                        <div className="bordrbottom">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="p-text">
                                                        {checkoutSlipData?.diagnosis}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex  justify-content-start align-items-center my-2">
                                            <img src={require("../../Assets/UploadDocumentImage/blood-sample.png")} className="icone ms-2 me-3" alt="" />
                                            <h5 >Test Required</h5>
                                        </div>
                                        <div className="bordrbottom mb-4">
                                            <div className="row">
                                                <div className="col">
                                                    <p className="p-text">
                                                        {checkoutSlipData?.tests?.map((item, indextests) => <span key={indextests} className='ms-1 mt-1'>{item?.testsName}{checkoutSlipData?.tests?.length > indextests + 1 ? ',' : ''}</span>)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around py-3">
                                    <div className="d-flex justify-content-start align-items-center"> <img src={require("../../Assets/UploadDocumentImage/surgeon.png")} className="icone me-3" alt="" />
                                        <p className=" mb-0"><b>Doctor’s Name :</b> {(checkoutSlipData?.doctorFirstName && checkoutSlipData.doctorLastName) ? checkoutSlipData?.doctorFirstName + " " + checkoutSlipData.doctorLastName : "-"}</p>
                                    </div>
                                    <div className="d-flex justify-content-start align-items-center"> <img src={require("../../Assets/UploadDocumentImage/calendar.png")} className="icone me-3" alt="" />
                                        <p className=" mb-0"><b>Follow Up Date :</b> {checkoutSlipData?.followUpDate ? moment(checkoutSlipData?.followUpDate)?.format("DD/MM/YYYY") : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckoutSlip;
