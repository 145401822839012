import { toast } from "react-toastify";
import { fileTypes } from "../Context/constants";
import { UPLOAD_IMAGE } from "../Pages/Repository/CommonRepo";
const useUploadImage = () => {
    const UploadImage = async (event, FileRef, type, setLoader, setImgUrl, imgUrl, fileId = null) => {
        const fileData = event.target.files[0];
        const fd = new FormData()
        if (fileData?.size / 1024 / 1024 > 10) return toast.warn("Please upload image less than 10 MB");
        if (!fileTypes.Img_Type.includes(fileData?.type)) return toast.warn("Only image format allowed");
        if (fileData?.size / 1024 / 1024 < 10 && fileTypes.Img_Type.includes(fileData?.type)) {
            fd.append('type', type)
            fd.append('file', fileData)
            fd.append('previousFileName', imgUrl)
            fd.append('id', fileId)          
            setLoader(true)
            try {
                let response = await UPLOAD_IMAGE(fd)
                setImgUrl(response?.data?.db_name)
                FileRef.current.value = null;
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setImgUrl(null)
                setLoader(false)
            }
        }
    };
    return {
        UploadImage
    }
}
export default useUploadImage