import { IconButton } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react'
import { BsEmojiSmile } from "react-icons/bs";
import { IoSend } from "react-icons/io5";
import Picker from '@emoji-mart/react'
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { UPLOAD_ATTCHAMENT } from '../../Repository/ChatRepo';
import { toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';
import useDeleteFile from '../../../Hooks/useDeleteFile';

const Footer = ({ messageList, setSendMessage, sendMessage, handleSendMessage, userInfo, handleBlockUser, Tab, setAttchament, attchament, setLoader, editMessage }) => {
  const [showPicker, setShowPicker] = useState(false);
  const iconRef = useRef(null);
  const pickerRef = useRef(null);
  const uploadAttchamentRaf = useRef(null)
  const { CustomButton } = useMuiCompontent()
  const { deleteFileFun } = useDeleteFile()
  const handleEmojiSelect = (emoji) => {
    setSendMessage((pre) => pre + emoji.native)
    // setShowPicker(false);
  };
  const togglePicker = () => {
    setShowPicker(!showPicker);
  };
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target) && !iconRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };
  const UploadImage = async (event) => {
    const fileData = event.target.files[0];
    const fd = new FormData()
    if (fileData?.size / 1024 / 1024 > 10) return toast.warn("Please upload image less than 10 MB");
    if (fileData?.size / 1024 / 1024 < 10) {
      fd.append('type', "CHAT")
      fd.append('file', fileData)
      fd.append('previousFileName', attchament)
      fd.append('id', editMessage?.messageId ? editMessage?.messageId : null)
      setLoader(true)
      try {
        let response = await UPLOAD_ATTCHAMENT(fd)
        setAttchament(response?.data?.db_name)
        uploadAttchamentRaf.current.value = null;
        setLoader(false)
      } catch (error) {
        toast.error(error.message)
        setAttchament(null)
        setLoader(false)
      }
    }
  };
  useEffect(() => {
    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);
  const handleChangeMessage = (e) => {
    const { value } = e.target
    setSendMessage(value)
  }
  return (
    // messageList
    <>
      {userInfo ? <form onSubmit={handleSendMessage} >
        <div className='FOOTER px-2'>
          {(Tab === "PATIENT" || Tab === "STAFF") && userInfo?.blockedStatus ? <>
            <div className='d-flex justify-content-center align-items-center w-100'>
              <p className='m-0 me-5' style={{ color: 'gray' }}>You have blocked this user</p>
              <CustomButton variant="contained" className="me-3" onClick={() => handleBlockUser(userInfo)} >Unblock</CustomButton>
            </div>
          </> : Tab === "GROUP" && !messageList?.adminStatus && messageList?.onlyAdminCanMessage ?
            <div className='d-flex justify-content-center align-items-center w-100'>
              <p className='m-0 me-5' style={{ color: 'gray' }}>Only admin  can message </p>
            </div> :
            <>
              <div className='d-flex align-items-center position-relative flex-fill'
              //  style={{ width: "90%" }}

              >
                {
                  attchament && <div className=' SEND_ATTCHAMENT'><p className='m-0'>{attchament}</p>
                    {
                      !editMessage && <RxCross2 className='cursor' onClick={() => { deleteFileFun(setAttchament, { fileName: attchament, type: "CHAT" }, true) }} />
                    }

                  </div>
                }
                <div style={{ position: 'absolute', top: -430, left: 0, zIndex: 1000 }}>
                  {showPicker && (<div ref={pickerRef} ><Picker onEmojiSelect={handleEmojiSelect} previewPosition="top" /></div>)}
                </div>
                <IconButton onClick={togglePicker} ref={iconRef}>
                  <BsEmojiSmile className='fs-4 cursor' />
                </IconButton>
                <input type="text" value={sendMessage} onChange={handleChangeMessage} placeholder="Send message" className="form-control filter_input ms-2" style={{ height: "40px" }} name="services" />
              </div>
              <div className='d-flex align-items-center justify-content-center'
              // style={{ width: "10%" }}
              >
                <i className="fa-solid fa-paperclip mx-3 fs-5 cursor  " onClick={() => uploadAttchamentRaf.current.click()}  ></i>
                <IconButton type='submit' aria-label="Send" disabled={!sendMessage.trim() && !attchament} >
                  <IoSend style={{ color: "#38c4b9" }} />
                </IconButton>
                <input ref={uploadAttchamentRaf} type="file" className='d-none ' name="file" onChange={UploadImage} />
              </div>
            </>
          }
        </div>
      </form> : null
      }
    </>
  )
}

export default Footer