 export const getDeviceInfo = () => {
  const userAgent = navigator?.userAgent;
  const platform = navigator?.platform;
  let deviceType, deviceName;

  // Get device type
  if (/Android/i.test(userAgent)) {
      deviceType = 'Android';
  } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      deviceType = 'iOS';
  } else if (/Windows/i.test(userAgent)) {
      deviceType = 'Windows';
  } else if (/Mac/i.test(userAgent)) {
      deviceType = 'Mac';
  } else if (/Linux/i.test(userAgent)) {
      deviceType = 'Linux';
  } else {
      deviceType = 'Unknown';
  }

  // Get device name
  if (/iPhone|iPod/i.test(userAgent)) {
      deviceName = 'iPhone';
  } else if (/iPad/i.test(userAgent)) {
      deviceName = 'iPad';
  } else if (/Android/i.test(userAgent)) {
      // Extract device name from userAgent or platform if needed
      deviceName = 'Android Device';
  } else if (/Windows/i.test(userAgent)) {
      deviceName = platform.startsWith('Win') ? 'Windows PC' : 'Windows Device';
  } else if (/Mac/i.test(userAgent)) {
      deviceName = 'Mac';
  } else if (/Linux/i.test(userAgent)) {
      deviceName = 'Linux PC';
  } else {
      deviceName = 'Unknown Device';
  }

  return { deviceType, deviceName };
};