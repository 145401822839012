import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
const Insights = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const splitPath = pathname.split("/")
  const ActiveTab = splitPath[splitPath.length - 1]
  useEffect(() => {
    document.title = "Insights"
  }, [])
  return <>
    <div className="common-component-box">
      <header className="body_header" >
        <div className="d-flex justify-content-start align-items-center">
          <h2>Insights</h2>
        </div>
      </header>

      <div className="common-white-bg-box mx-0 shadow   pt-0 ">
        <div className="col-12 pb-3 position-sticky top-0 z-3 pt-2" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
          <ul className="nav  mb-0  pb-1 px-2 ">
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn me-2 ${ActiveTab === "bookig-details" && "active_teams_frofile"}`} onClick={() => navigate("bookig-details")} id="personal" type="button">Booking Details</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "patient-report" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`patient-report`)}>Patient Reports</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "staff-report" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`staff-report`)} >Team Reports</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "service-report" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`service-report`)} >Services Reports</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "rush-hours" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("rush-hours")} >Rush  Hours</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "demographics" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate("demographics")} >Demographics</button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "feedback" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`feedback`)} >Feedback</button>
            </li> */}
          </ul>
        </div>
        <div className='px-2 PATIENT-OUTLET-HIEGHT mx-3 '>
          <Outlet />
        </div>
      </div>
    </div>
  </>;
};

export default Insights;
