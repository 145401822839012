import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { basicDetailsValidation, pattern } from "../../../../Context/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useDisableBrowserBackButton from "../../../../Hooks/useDisableBrowserBackButton";
import { AddBasicDetails } from "../../../../Redux/ReducerFile/BasicDetails";
import useReduxData from "../../../../Hooks/useReduxData";
import { GET_BASIC_DETAILS, UPDATE_BASIC_DETAILS } from "../../../Repository/ProfileRepo";

const useBasicDetails = () => {
  const [imgUrl, setImgUrl] = useState(null);
  const [loader, setLoader] = useState(false)
  useDisableBrowserBackButton()
  const navigate = useNavigate();
  const {dispatch } = useReduxData("BasicDetailsRDX")
  const userdata = JSON.parse(localStorage.getItem("S_userdata"))
  useEffect(() => {
    getListproviderbasic();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formik = useFormik({
    initialValues: {
      title: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
    },
    validationSchema: basicDetailsValidation,
    validateOnChange: true,
    validate: (values) => {
      const errors = {};
      if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
        delete errors.mobile
      } else {
        errors.mobile = "Please enter a valid mobile number ";
      }
      return errors;
    },
    onSubmit: async (values) => {
      const { title, mobile, email, firstName, lastName } = { ...values }
      let payload = {
        title,
        mobile,
        email,
        firstName,
        lastName,
        userId: userdata.userId,
        authId: userdata?.authId,
        image: imgUrl,
      };
      setLoader(true)
      try {
        let response = await UPDATE_BASIC_DETAILS(payload)
        toast.success(response.message);
        getListproviderbasic()
        navigate("/alldetails/business-details")
        setLoader(false)
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    },
  });

  // get provider basice details  list 
  const getListproviderbasic = async () => {
    setLoader(true)
    const payload = {
      rolesToList: [userdata?.roleTitle],
      getStaffList: false,
      providerId: userdata?.userId,
      activeStatus: true,
    };
    try {
      let response = await GET_BASIC_DETAILS(payload)
      formik.setValues(response?.data)
      dispatch(AddBasicDetails(response?.data));
      setImgUrl(response?.data?.image);
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
      dispatch(AddBasicDetails({}));
    }
  }
  return {
    loader,
    formik,
    imgUrl,
    setImgUrl,
    setLoader,
    userdata
  }
}

export default useBasicDetails
