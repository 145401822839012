import React from "react";
import {  IoMdAddCircleOutline, IoMdCloseCircleOutline } from "react-icons/io";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Errors } from "../../Component/Error";
import { Modal } from "react-bootstrap";
import Loading from "../../Component/Loading";
import useSetAvailability from "./Hooks/useSetAvailability";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import moment from "moment-timezone";
import { SearchAbleSelectBox } from "../../Component/Inputs";
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
const SetAvailbility = () => {
  const { loader,
    AvailabilityAdd,
    availabilityDefault,
    clinicModal,
    listTimeZone,
    userdata,
    showHideTimings,
    addtimes,
    removetime,
    removeSection, handalSearchAbleSelectBox
  } = useSetAvailability()
  const navigate = useNavigate()
  const { GreenSwitch, CustomButton } = useMuiCompontent()
  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box">
        {/* <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Current Availability sdafasf</h2>
          </div>
        </header> */}
        <header className="body_header" >
                <div className="d-flex justify-content-start align-items-center ">
                    <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(-1) }} />
                    <h2 className="page_name">Set Availability</h2>
                </div>
            </header>
        <div className="row common-white-bg-box shadow position-relative d-flex align-content-start mx-0" style={{ borderBottom: "2px solid #ededed" }}>
          <div className="col-sm-2 mt-2">
            <label className="form-label mb-0"> Clinic<span className='Star_Mark'>*</span></label>
            <input type="text" className="form-control"
              maxLength={50}
              value={userdata?.location?.clinicName}
              readOnly
            />
          </div>
          <div className="col-sm-2 col-md-6 col-lg-2 col-xl-2 col-xxl-2 mt-2">
            <label className="form-label mb-0">Timezone<span className='Star_Mark'>*</span></label>
            <SearchAbleSelectBox
              multiple={false}
              option={listTimeZone}
              getOptionLabel="text"
              isOptionEqualToValue="id"
              value={AvailabilityAdd.values.timeZone}
              name="timeZone"
              handalSearchAbleSelectBox={handalSearchAbleSelectBox}
            />
            <Errors formikfun={AvailabilityAdd} name={"timeZone"} />
          </div>

          <div className="col-sm-2 mt-2">
            <label className="form-label mb-0">Start Date<span className='Star_Mark'>*</span></label>
            <input type="date"
              // min={minimumdate}
              className="form-control position-relative" name="fromDate" id="datefield"
              value={AvailabilityAdd.values.fromDate?moment(AvailabilityAdd.values.fromDate).format("YYYY-MM-DD"):""}
              onChange={(e) => AvailabilityAdd.setFieldValue("fromDate", e.target.value)}
            />
            <Errors formikfun={AvailabilityAdd} name={"fromDate"} />
          </div>
          <div className="col-sm-2 mt-2">
            <label className="form-label mb-0">End Date<span className='Star_Mark'>*</span></label>
            <input type="date"
              // min={minimumdate}
              className="form-control position-relative" name="toDate" id="datefield"
              value={AvailabilityAdd.values.toDate?moment(AvailabilityAdd.values.toDate).format("YYYY-MM-DD"):""}
              onChange={(e) => AvailabilityAdd.setFieldValue("toDate", e.target.value)}
            />
            <Errors formikfun={AvailabilityAdd} name={"toDate"} />
          </div>
          <div className="col-12 pt-2" style={{ borderBottom: "2px solid #ededed" }}></div>
          <div className="col-sm-12 px-2  SetAvailability_table_overflow ">
            <div className="table-responsive">
              <table className="table cus-table SetAvailability_table">
                <tbody className="trwidth">
                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Sunday</div>
                    </td>
                    <td style={{ width: "40px" }}>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="sunday" checked={availabilityDefault[0].isChecked}
                        onChange={() => showHideTimings(0)}
                      />
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {availabilityDefault[0].isChecked ? "Open" : "Closed"}
                    </td>
                    <td style={{ padding: "0" }}>
                      {availabilityDefault[0].isChecked &&
                        availabilityDefault[0].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} name={`sunStartTime${index}`}
                                      format="hh:mm A"
                                      onChange={(e) => { AvailabilityAdd.setFieldValue(`sunStartTime${index}`, e) }} value={AvailabilityAdd.values[`sunStartTime${index}`] ? AvailabilityAdd.values[`sunStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`sunEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`sunEndTime${index}`, e)} value={AvailabilityAdd.values[`sunEndTime${index}`] ? AvailabilityAdd.values[`sunEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(0)}  >
                                        <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(0, index); removeSection("Sunday", index) }}>
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Monday</div>
                    </td>
                    <td>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="monday" checked={availabilityDefault[1].isChecked}
                        onChange={() => showHideTimings(1)}
                      />
                    </td>
                    <td style={{ width: "40px", fontSize: "13px" }}>
                      {availabilityDefault[1].isChecked ? "Open" : "Closed"}
                    </td>

                    <td style={{ padding: "0" }}>
                      {availabilityDefault[1].isChecked &&
                        availabilityDefault[1].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`monStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`monStartTime${index}`, e)} value={AvailabilityAdd.values[`monStartTime${index}`] ? AvailabilityAdd.values[`monStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`monEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`monEndTime${index}`, e)} value={AvailabilityAdd.values[`monEndTime${index}`] ? AvailabilityAdd.values[`monEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(1)}  >
                                        <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(1, index); removeSection("Monday", index) }}  >
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Tuesday</div>
                    </td>
                    <td style={{ width: "40px", fontSize: "13px" }}>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="tuesday" checked={availabilityDefault[2].isChecked}
                        onChange={() => showHideTimings(2)}
                      />
                    </td>
                    <td>
                      {availabilityDefault[2].isChecked ? "Open" : "Closed"}
                    </td>

                    <td style={{ padding: "0" }}>
                      {availabilityDefault[2].isChecked &&
                        availabilityDefault[2].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`tuesStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`tuesStartTime${index}`, e)} value={AvailabilityAdd.values[`tuesStartTime${index}`] ? AvailabilityAdd.values[`tuesStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`tuesEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`tuesEndTime${index}`, e)} value={AvailabilityAdd.values[`tuesEndTime${index}`] ? AvailabilityAdd.values[`tuesEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(2)}  >
                                        <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(2, index); removeSection("Tuesday", index) }}  >
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Wednesday</div>
                    </td>
                    <td style={{ width: "40px", fontSize: "13px" }}>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="wednesday" checked={availabilityDefault[3].isChecked}
                        onChange={() => showHideTimings(3)}
                      />
                    </td>
                    <td>
                      {availabilityDefault[3].isChecked === true
                        ? "Open"
                        : "Closed"}
                    </td>
                    <td style={{ padding: "0" }}>
                      {availabilityDefault[3].isChecked &&
                        availabilityDefault[3].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`wedStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`wedStartTime${index}`, e)} value={AvailabilityAdd.values[`wedStartTime${index}`] ? AvailabilityAdd.values[`wedStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`wedEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`wedEndTime${index}`, e)} value={AvailabilityAdd.values[`wedEndTime${index}`] ? AvailabilityAdd.values[`wedEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(3)} >
                                        <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(3, index); removeSection("Wednesday", index) }}  >
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Thursday</div>
                    </td>
                    <td style={{ width: "40px" }}>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="thursday" checked={availabilityDefault[4].isChecked}
                        onChange={() => showHideTimings(4)}
                      />
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {availabilityDefault[4].isChecked ? "Open" : "Closed"}
                    </td>
                    <td style={{ padding: "0" }}>
                      {availabilityDefault[4].isChecked &&
                        availabilityDefault[4].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`thursStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`thursStartTime${index}`, e)} value={AvailabilityAdd.values[`thursStartTime${index}`] ? AvailabilityAdd.values[`thursStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`thursEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`thursEndTime${index}`, e)} value={AvailabilityAdd.values[`thursEndTime${index}`] ? AvailabilityAdd.values[`thursEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(4)}        >
                                        <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(4, index); removeSection("Thursday", index) }}  >
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ width: "40px" }}>
                      <div className="cus-week1">Friday</div>
                    </td>
                    <td>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="friday" checked={availabilityDefault[5].isChecked}
                        onChange={() => showHideTimings(5)} />
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {availabilityDefault[5].isChecked ? "Open" : "Closed"}
                    </td>
                    <td style={{ padding: "0" }}>
                      {availabilityDefault[5].isChecked &&
                        availabilityDefault[5].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`friStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`friStartTime${index}`, e)} value={AvailabilityAdd.values[`friStartTime${index}`] ? AvailabilityAdd.values[`friStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`friEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`friEndTime${index}`, e)} value={AvailabilityAdd.values[`friEndTime${index}`] ? AvailabilityAdd.values[`friEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (<button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(5)} >
                                      <IoMdAddCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                    </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(5, index); removeSection("Friday", index) }}
                                      >
                                        <IoMdCloseCircleOutline className="all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="cus-week1">Saturday</div>
                    </td>
                    <td>
                      <GreenSwitch inputProps={{ "aria-label": "controlled" }} name="saturday" checked={availabilityDefault[6].isChecked}
                        onChange={() => showHideTimings(6)}
                      />
                    </td>
                    <td style={{ fontSize: "13px" }}>
                      {availabilityDefault[6].isChecked ? "Open" : "Closed"}
                    </td>
                    <td style={{ padding: "0" }}>
                      {availabilityDefault[6].isChecked &&
                        availabilityDefault[6].arrayOfTimings.map(
                          (cur, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr >
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`satStartTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`satStartTime${index}`, e)} value={AvailabilityAdd.values[`satStartTime${index}`] ? AvailabilityAdd.values[`satStartTime${index}`] : null}
                                    />
                                  </td>
                                  <td style={{ width: "40px", textAlign: "center" }}>TO</td>
                                  <td>
                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                      name={`satEndTime${index}`}
                                      onChange={(e) => AvailabilityAdd.setFieldValue(`satEndTime${index}`, e)} value={AvailabilityAdd.values[`satEndTime${index}`] ? AvailabilityAdd.values[`satEndTime${index}`] : null}
                                    />
                                  </td>
                                  <td>
                                    {index === 0 ? (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimes(6)}  >
                                        <IoMdAddCircleOutline className=" all_react_icons_color mt-2 ms-2  " />
                                      </button>
                                    ) : (
                                      <button style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetime(6, index); removeSection("Saturday", index) }}  >
                                        <IoMdCloseCircleOutline className=" all_react_icons_color mt-2 ms-2" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-end mt-3 position-absolute bottom-0  p-3 px-3 ">
            <CustomButton variant="contained" onClick={AvailabilityAdd.handleSubmit} >Save</CustomButton>
          </div>
        </div>
      </div>
      {/* no data clinic modal  */}
      <Modal show={clinicModal} centered>
        <Modal.Body className="px-0">
          <div className="modal-body text-center cus-i">
            <i className="fa-solid fa-ban mb-3 all_react_icons_color mt-2 ms-2"></i>
            <p className="mb-0" style={{ color: "black", fontSize: "23px" }}>No Clinic Details Found</p>
            <p className="mb-0" style={{ color: "gray", fontSize: "15px" }}>If you want add clinic , Please </p>
            <p style={{ color: "gray", fontSize: "15px" }}>click this link <span onClick={() => navigate("/profile")} style={{ color: "#333483", cursor: 'pointer', textDecoration: "underline" }}>#add clinic</span></p>
            <CustomButton variant="contained" onClick={() => navigate("/availability")} >Close</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SetAvailbility;
