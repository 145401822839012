import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment-timezone';
import { TableContainer } from '@mui/material';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import Loading from '../../Component/Loading';
import useTableSorting from '../../Hooks/useTableSorting';
import useReduxData from '../../Hooks/useReduxData';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { RoleTitle } from '../../Context/constants';
import { APPOINTMENT_LIST } from '../Repository/AppointmentsRepo';
const CheckoutAppointment = ({ type }) => {
    const [appointmentData, setAppointmentData] = useState([])
    const [count, setCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const { id, subpatientId } = useParams()
    const [loader, setloader] = useState(false)
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const navigate = useNavigate()
    useEffect(() => {
        appointments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])

    const appointments = async () => {
        let payload = {
            providerId: userData.roleTitle === RoleTitle.STAFF ? userData?.providerId : userData?.userId,
            patients: [type === "Patients" ? id : MemberType ? subpatientId : subpatientId],
            staffId: userData.roleTitle === RoleTitle.STAFF ? userData?.userId : undefined,
            clinics: [],
            services: [],
            status: ["CHECKOUT"],
            page: controller.page,
            roleTitle: userData.roleTitle,
            count: controller.rowsPerPage
        }
        setloader(true)
        try {
            let response = await APPOINTMENT_LIST(payload)
            setAppointmentData(response?.data)
            setloader(false)
            setCount(response.totalCount)
        } catch (error) {
            setAppointmentData([])
            setloader(false)
            setCount(0)
        }

    }
    const headCells = [
        { id: "appointmentNumber", numeric: false, disablePadding: true, label: "Appointment Number", showIcon: true },
        { id: "apptStartDate", numeric: false, disablePadding: true, label: "Date", showIcon: true },
        { id: "time", numeric: true, disablePadding: false, label: "Time" },
        { id: "doctor", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "visitType", numeric: true, disablePadding: false, label: "Visit Type", showIcon: true },
        { id: "Status", numeric: true, disablePadding: false, label: "Status", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div>
                    <TableContainer >
                        {appointmentData?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >  <NoRecordFound />  </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                appointmentData.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={appointmentData.length} />
                            }
                            <TableBody>

                                {
                                    stableSort(appointmentData, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow hover id="hover_tr" >
                                                <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                    {row?.appointmentNumber}
                                                </TableCell>
                                                <TableCell id={labelId}  >
                                                    {moment(row.startDate).tz(userData?.timezoneName).format('ll')}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{moment(row.startDate).tz(userData?.timezoneName).format('LT')} - {moment(row.endDate).tz(userData?.timezoneName).format('LT')}</TableCell>
                                                <TableCell id={labelId} align="left">{row.doctorFirstName + " " + row.doctorLastName}</TableCell>
                                                <TableCell id={labelId} align="left">{row.visitType}</TableCell>
                                                <TableCell id={labelId} align="left">{row.Status}</TableCell>
                                                <TableCell id={labelId} align="right" className=''>
                                                    <i className="fa-solid fa-eye me-2 all_icons_color" title='Checkout Slip ' onClick={() => { navigate(`${row?.appointmentId}`); }}></i>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='w-100 3'>
                    {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                </div>
            </div>
        </>
    )
}

export default CheckoutAppointment
