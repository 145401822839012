import React from "react";
import { Calendar } from 'react-big-calendar'
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment'
import { Modal } from "react-bootstrap";
import useCalendar from "./Hooks/useCalendar";
import Loading from "../../Component/Loading";
import BookAppointment from "./Component/BookAppointment";
import RescheduleAppointment from "./Component/RescheduleAppointment";
import CancelAppointment from "./Component/CancelAppointment";
import { DeleteUnAvailabiltyModal } from "../Availability/Compontent/UnAvailability";
import { AppointmentStatus, RoleTitle } from "../../Context/constants";
import { SearchAbleSelectBox } from "../../Component/Inputs";
const AppointmentCalendar = () => {
  const {
    calendarStyle,
    myEventsList,
    handleSelectEvent,
    handleSelectSlot,
    setViewModal,
    opneviewfun,
    onRangeChange,
    handleChangeView,
    setCheck,
    setIsactiveSlot,
    applyByCouponCodeOffers,
    cancelOffer,
    ApplyOffer,
    handalSearchAbleSelectBox,
    clossOffcanvas,
    setOpenCreateModal,
    setBookAppointmentModal,
    getlistPatient,
    navigate,
    SetUnavailabilityDetele,
    handleClose,
    getservicesList,
    selectedSlotTime,
    filterStaffList,
    filterStaffValue,
    UnavailabilityDetele,
    OpenCreateModal,
    patientFilterList,
    CustomButton,
    localizer,
    userdata,
    loader,
    calendarList,
    viewModal,
    cancelAppointment,
    bookAppointmentModal,
    createAppointment,
    modalCancel,
    SlotLodaer,
    Staff_DETAILS,
    SERVICES_DETAILS,
    servicesList,
    teamList,
    TimeslotList,
    isActiveSlot,
    offersList,
    OFERS_DETAILS,
    check,
    modalReschdule,
    apptDetails,
    appointmentReschedule,
    isFrontDeskPermission
  } = useCalendar()
  return <>
    {loader && <Loading />}
    <div className="common-component-box">
      <header className="body_header" >
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="page_name">Calendar</h2>
        </div>
      </header>
      <div className="px-4 common-white-bg-box mx-0 shadow position-relative">
        <header className="body_header" >
          <div className="d-flex justify-content-end align-items-center">
            <ul className="headerlist d-flex justify-content-end align-items-end">
              {
                userdata.roleTitle === RoleTitle.SERVICE_PROVIDER && <li className="me-2" style={{ width: 200 }}>
                  <SearchAbleSelectBox
                    multiple={false}
                    option={filterStaffList}
                    getOptionLabel="name"
                    isOptionEqualToValue="id"
                    value={filterStaffValue}
                    name="staff_filter"
                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                  />
                </li>
              }
              <li className="" style={{ width: 200 }}>
                <input type="text" className="form-control"
                  maxLength={25}
                  value={userdata?.location?.clinicName}
                  readOnly
                />
              </li>
            </ul>
          </div>
        </header>
        <div className="myCustomHeight">
          <Calendar
            localizer={localizer}
            // defaultView="week"
            events={calendarList}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 670 }}
            views={{ week: true, month: true, day: true }}
            onSelectEvent={handleSelectEvent}
            dayPropGetter={calendarStyle}
            eventPropGetter={myEventsList}
            onRangeChange={onRangeChange}
            onView={handleChangeView}
            onSelectSlot={handleSelectSlot}
            selectable
            popup
            step={15}
          />
     
        </div>
      </div>
    </div>
    {/*view*/}
    <Modal size='sm' show={viewModal} onHide={() => setViewModal(false)} centered>
      <Modal.Header closeButton className='py-2'>
        <Modal.Title>Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body className=' pt-3 pb-0'>
        <div className="">
          <div className="">
          </div>
          <div className="mb-4 d-flex  flex-column">
            <CustomButton variant="contained" onClick={() => opneviewfun("view")}>View Details</CustomButton>
            {
              !(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) && <CustomButton variant="contained" onClick={() => { opneviewfun("cancel") }} className='my-2'>Cancel Appointment</CustomButton>
            }
            {
              !(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT || apptDetails?.Status === AppointmentStatus.RESCHEDULED) && <CustomButton variant="contained" onClick={() => opneviewfun("Reschedule")} >Reschedule Appt.</CustomButton>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>
    {/*Create Open Modal */}
    <Modal size='sm' show={OpenCreateModal} onHide={() => setOpenCreateModal(false)} centered>
      <Modal.Header closeButton className='py-2'>
        <Modal.Title>Schedule</Modal.Title>
      </Modal.Header>
      <Modal.Body className=' pt-3 pb-0'>
        <div className="">
          <div className="">
          </div>
          <div className="mb-4 d-flex  flex-column">
            <CustomButton variant="contained" onClick={() => { setBookAppointmentModal(true); getlistPatient(); getservicesList(userdata?.location?.clinicId) }}>book Appointment</CustomButton>
            {
              !isFrontDeskPermission && <CustomButton variant="contained" onClick={() => navigate("set-unavailability", { state: { ...selectedSlotTime } })} className='my-2'>set Unavailability</CustomButton>
            }
          </div>
        </div>
      </Modal.Body>
    </Modal>


    {/* OpenCreateModal */}
    <CancelAppointment modalCancel={modalCancel} clossOffcanvas={clossOffcanvas} cancelAppointment={cancelAppointment} CustomButton={CustomButton} />
    <DeleteUnAvailabiltyModal CustomButton={CustomButton} UnavailabilityDetele={UnavailabilityDetele} SetUnavailabilityDetele={SetUnavailabilityDetele} handleClose={handleClose} />
    {/* create appoinment  */}
    <BookAppointment patientFilterList={patientFilterList} servicesList={servicesList} teamList={teamList} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer} bookAppointmentModal={bookAppointmentModal}
      clossOffcanvas={clossOffcanvas} handalSearchAbleSelectBox={handalSearchAbleSelectBox}
      createAppointment={createAppointment} userdata={userdata}
      isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} moment={moment} offersList={offersList}
      applyByCouponCodeOffers={applyByCouponCodeOffers} cancelOffer={cancelOffer} ApplyOffer={ApplyOffer}
      OFERS_DETAILS={OFERS_DETAILS} SERVICES_DETAILS={SERVICES_DETAILS} check={check} setCheck={setCheck} Staff_DETAILS={Staff_DETAILS}
    />
    <RescheduleAppointment clossOffcanvas={clossOffcanvas} modalReschdule={modalReschdule} apptDetails={apptDetails} userdata={userdata}
      moment={moment} appointmentReschedule={appointmentReschedule} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer}
      isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} CustomButton={CustomButton} />
  </>;
};

export default AppointmentCalendar;
