import React from 'react'
import { Errors } from '../../Component/Error';
import moment from 'moment-timezone';
import Loading from '../../Component/Loading';
import { Avatar, Badge, styled } from '@mui/material';
import useEditPatient from './Hooks/useEditPatient';
import { PatientImageFilePath } from '../../Context/api';
import PhoneNumber from '../../Component/PhoneInput';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { SearchAbleSelectBox, SelectBox } from '../../Component/Inputs';
import { BloodGroupOptions, GenderOptions, MaritalStatusOptions, PatientRelationOptions, TitleOptions } from '../../Context/constants';
import DeleteProfile from '../../Component/DeleteProfile';
import { MdOutlineCameraAlt } from 'react-icons/md';
import useUploadImage from '../../Hooks/useUploadImage';
const UpdatePatient = ({ type }) => {
  const {
    addPatint,
    loader,
    imgUrl,
    ListDataCountry,
    ListState,
    id,
    subpatientId,
    handalDOB,
    setImgUrl, FileRef, handalSearchAbleSelectBox, setLoader
  } = useEditPatient(type)
  const { CustomButton } = useMuiCompontent()

  const { UploadImage } = useUploadImage()
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    color: "#fff"
  }));
  return (
    <>
      {loader && <Loading />}
      <div className="row  ">
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Patient ID</label>
            </div>
            <div className="col-4">
              <input type="text" value={addPatint?.values?.patientId||""} readOnly className="form-control" disabled={true} maxLength={50} name='patientId' />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Patient ID</label>
                </div>
                <div className="col-lg-7">
                  <input type="text" value={addPatint?.values?.patientId} readOnly className="form-control" disabled={true} maxLength={50} name='patientId' />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        {
          type === "subPatients" && <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Relation<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <SelectBox
                  placeholder="Select Relation"
                  value={addPatint?.values?.relation}
                  name="relation"
                  keys="value"
                  label="label"
                  menuValeu="value"
                  handleChange={(e) => addPatint.setFieldValue("relation", e.target.value)}
                  option={PatientRelationOptions}
                />
                <Errors formikfun={addPatint} name={"relation"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Relation<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <SelectBox
                      placeholder="Select Relation"
                      value={addPatint?.values?.relation}
                      name="relation"
                      keys="value"
                      label="label"
                      menuValeu="value"
                      handleChange={(e) => addPatint.setFieldValue("relation", e.target.value)}
                      option={PatientRelationOptions}
                    />
                    <Errors formikfun={addPatint} name={"relation"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
        }
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <SelectBox
                placeholder="Select Title"
                value={addPatint?.values?.title?.trim()}
                name="title"
                keys="value"
                label="label"
                menuValeu="value"
                handleChange={(e) => addPatint.setFieldValue("title", e.target.value)}
                option={TitleOptions}
              />
              <Errors formikfun={addPatint} name={"title"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <SelectBox
                    placeholder="Select Title"
                    value={addPatint?.values?.title?.trim()}
                    name="title"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={(e) => addPatint.setFieldValue("title", e.target.value)}
                    option={TitleOptions}
                  />
                  <Errors formikfun={addPatint} name={"title"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <input type="text" className="form-control" maxLength={50} name='firstName' onChange={(e) => addPatint.setFieldValue("firstName", e.target.value)}
                value={addPatint.values.firstName} />
              <Errors formikfun={addPatint} name={"firstName"} />
                </div>
            </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="text" className="form-control" maxLength={50} name='firstName' onChange={(e) => addPatint.setFieldValue("firstName", e.target.value)}
                    value={addPatint.values.firstName} />
                  <Errors formikfun={addPatint} name={"firstName"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <input type="text" className="form-control" maxLength={50} name='lastName' onChange={(e) => addPatint.setFieldValue("lastName", e.target.value)}
                value={addPatint.values.lastName} />
              <Errors formikfun={addPatint} name={"lastName"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="text" className="form-control" maxLength={50} name='lastName' onChange={(e) => addPatint.setFieldValue("lastName", e.target.value)}
                    value={addPatint.values.lastName} />
                  <Errors formikfun={addPatint} name={"lastName"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <SelectBox
                placeholder="Select Gender"
                value={addPatint.values.gender}
                name="gender"
                keys="value"
                label="label"
                menuValeu="value"
                handleChange={(e) => addPatint.setFieldValue("gender", e.target.value)}
                option={GenderOptions}
              />
              <Errors formikfun={addPatint} name={"gender"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <SelectBox
                    placeholder="Select Gender"
                    value={addPatint.values.gender}
                    name="gender"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={(e) => addPatint.setFieldValue("gender", e.target.value)}
                    option={GenderOptions}
                  />
                  <Errors formikfun={addPatint} name={"gender"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <input type="date" className="form-control position-relative"
                max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} value={addPatint.values.dob?moment(addPatint.values.dob).format("YYYY-MM-DD"):""}
                onChange={handalDOB}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="date" className="form-control position-relative"
                    max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} value={moment(addPatint.values.dob).format("YYYY-MM-DD")}
                    onChange={handalDOB}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <input type="text" className="form-control" name='age' disabled={true} value={addPatint.values.age} />
              <Errors formikfun={addPatint} name={"age"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="text" className="form-control" name='age' disabled={true} value={addPatint.values.age} />
                  <Errors formikfun={addPatint} name={"age"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <SelectBox
                placeholder="Select Blood Group"
                value={addPatint.values.bloodGroup}
                name="bloodGroup"
                keys="value"
                label="label"
                menuValeu="value"
                handleChange={(e) => addPatint.setFieldValue("bloodGroup", e.target.value)}
                option={BloodGroupOptions}
              />
              <Errors formikfun={addPatint} name={"bloodGroup"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <SelectBox
                    placeholder="Select Blood Group"
                    value={addPatint.values.bloodGroup}
                    name="bloodGroup"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={(e) => addPatint.setFieldValue("bloodGroup", e.target.value)}
                    option={BloodGroupOptions}
                  />
                  <Errors formikfun={addPatint} name={"bloodGroup"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Marital Status<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <SelectBox
                placeholder="Select Marital Status"
                value={addPatint.values.maritalStatus}
                name="maritalStatus"
                keys="value"
                label="label"
                menuValeu="value"
                handleChange={(e) => addPatint.setFieldValue("maritalStatus", e.target.value)}
                option={MaritalStatusOptions}
              />
              <Errors formikfun={addPatint} name={"maritalStatus"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Marital Status<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <SelectBox
                    placeholder="Select Marital Status"
                    value={addPatint.values.maritalStatus}
                    name="maritalStatus"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={(e) => addPatint.setFieldValue("maritalStatus", e.target.value)}
                    option={MaritalStatusOptions}
                  />
                  <Errors formikfun={addPatint} name={"maritalStatus"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4 mobile_number_input_box">
              <div className="input_group_mobile mb-0">
                <PhoneNumber
                  disabledInput={true}
                  width={false}
                  Require={false}
                  Name="mobile"
                  HandleChange={addPatint}
                  Value={addPatint.values.mobile}
                // disabledInput={true}
                />
              </div>
              <Errors formikfun={addPatint} name={"mobile"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7 mobile_number_input_box">
                  <div className="input_group_mobile mb-0">
                    <PhoneNumber
                      width={false}
                      Require={false}
                      Name="mobile"
                      HandleChange={addPatint}
                      Value={addPatint.values.mobile}
                    // disabledInput={true}
                    />
                  </div>
                  <Errors formikfun={addPatint} name={"mobile"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4 ">
              <input type="email" className="form-control" disabled={true} name='email' onChange={(e) => addPatint.setFieldValue("email", e.target.value)}
                value={addPatint.values.email} />
              <Errors formikfun={addPatint} name={"email"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="email" className="form-control" disabled={true} name='email' onChange={(e) => addPatint.setFieldValue("email", e.target.value)}
                    value={addPatint.values.email} />
                  <Errors formikfun={addPatint} name={"email"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  pb-2 mt-4 " >
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
            </div>
            <div className="col-4">
              <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={addPatint.values.street}
                onChange={(e) => addPatint.setFieldValue(`street`, e.target.value)}
              />
              <Errors formikfun={addPatint} name={"street"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-7">
                  <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={addPatint.values.address}
                    onChange={(e) => addPatint.setFieldValue(`address`, e.target.value)}
                  />
                  <Errors formikfun={addPatint} name={"address"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-2 " >
          {/* <div className="row">
            <div className="col-3">
            </div>
            <div className="col-4">
              <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={addPatint.values.address}
                onChange={(e) => addPatint.setFieldValue(`address`, e.target.value)}
              />
              <Errors formikfun={addPatint} name={"address"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                </div>
                <div className="col-lg-7">
                  <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={addPatint.values.street}
                    onChange={(e) => addPatint.setFieldValue(`street`, e.target.value)}
                  />
                  <Errors formikfun={addPatint} name={"street"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-2 " >
          {/* <div className="row">
            <div className="col-3">
            </div>
            <div className="col-4">
              <SearchAbleSelectBox
                placeholder="Select Country"
                multiple={false}
                option={ListDataCountry}
                getOptionLabel="countryName"
                isOptionEqualToValue="id"
                value={addPatint?.values?.country}
                name="country"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
              <Errors formikfun={addPatint} name={"country"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                </div>
                <div className="col-lg-7">
                  <SearchAbleSelectBox
                    placeholder="Select Country"
                    multiple={false}
                    option={ListDataCountry}
                    getOptionLabel="countryName"
                    isOptionEqualToValue="id"
                    value={addPatint?.values?.country}
                    name="country"
                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                  />
                  <Errors formikfun={addPatint} name={"country"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-2 " >
          {/* <div className="row">
            <div className="col-3">
            </div>
            <div className="col-4">
              <SearchAbleSelectBox
                placeholder="Select State"
                multiple={false}
                option={ListState}
                getOptionLabel="stateName"
                isOptionEqualToValue="stateId"
                value={addPatint?.values?.state}
                name="state"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
              <Errors formikfun={addPatint} name={"state"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                </div>
                <div className="col-lg-7">
                  <SearchAbleSelectBox
                    placeholder="Select State"
                    multiple={false}
                    option={ListState}
                    getOptionLabel="stateName"
                    isOptionEqualToValue="stateId"
                    value={addPatint?.values?.state}
                    name="state"
                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                  />
                  <Errors formikfun={addPatint} name={"state"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-2 " >
          {/* <div className="row">
            <div className="col-3">
            </div>
            <div className="col-4">
              <input
                type="text"
                maxLength={50}
                className="form-control"
                name="city"
                placeholder="City"
                value={addPatint.values.city}
                onChange={(e) => addPatint.setFieldValue(`city`, e.target.value)}
              />
              <Errors formikfun={addPatint} name={"city"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                </div>
                <div className="col-lg-7">
                  <input
                    type="text"
                    maxLength={50}
                    className="form-control"
                    name="city"
                    placeholder="City"
                    value={addPatint.values.city}
                    onChange={(e) => addPatint.setFieldValue(`city`, e.target.value)}
                  />
                  <Errors formikfun={addPatint} name={"city"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-2 "  >
          {/* <div className="row">
            <div className="col-3">
            </div>
            <div className="col-4">
              <input type="text" className="form-control" name="zipCode"
                onChange={(e) => addPatint.setFieldValue(`zipCode`, e.target.value)} value={addPatint.values.zipCode}
                maxLength={10}
                placeholder="ZIP Code"
              />
              <Errors formikfun={addPatint} name={"zipCode"} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                </div>
                <div className="col-lg-7">
                  <input type="text" className="form-control" name="zipCode"
                    onChange={(e) => addPatint.setFieldValue(`zipCode`, e.target.value)} value={addPatint.values.zipCode}
                    maxLength={10}
                    placeholder="ZIP Code"
                  />
                  <Errors formikfun={addPatint} name={"zipCode"} />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
          {/* <div className="row">
            <div className="col-3">
              <label className="form-label mb-0">Display Picture</label>
            </div>
            <div className="col-4 d-flex  align-items-start flex-column">
              <Badge
                style={{ background: "#fff" }}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                  <SmallAvatar alt="Remy Sharp"  >
                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                    </MdOutlineCameraAlt>
                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                      onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl)} />
                  </SmallAvatar>
                }
              >
                <Avatar  sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                </Avatar>
              </Badge>
              {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: type === "Patients" ? id : subpatientId }} isDeleted={true} />}
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-7">
              <div className="row">
                <div className="col-lg-5">
                  <label className="form-label mb-0">Display Picture</label>
                </div>
                <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                  <Badge
                    style={{ background: "#fff" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp"  >
                        <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                        </MdOutlineCameraAlt>
                        <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                          onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl, imgUrl, type === "Patients" ? id : subpatientId)} />
                      </SmallAvatar>
                    }
                  >
                    <Avatar sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                    </Avatar>
                  </Badge>
                  {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: type === "Patients" ? id : subpatientId }} isDeleted={true} />}
                </div>
              </div>
            </div>
            <div className="col-lg-5">
            </div>
          </div>
        </div>
        <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end py-3">
          <CustomButton variant="contained" onClick={addPatint.handleSubmit} >Update</CustomButton>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default UpdatePatient
