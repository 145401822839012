import {  importformApi, insertVaccinationCategory, insertVaccinationForm, listVaccinationFormApi, updateVaccinationCategory, UpdateVaccinationForm, vaccinationCategoryListApi } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"
////////////////////////////// VACCINATION /////////////////////////////////

export const INSERT_VACCINATION_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(insertVaccinationCategory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_VACCINATION_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(updateVaccinationCategory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const VACCINATION_CATEGORY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(vaccinationCategoryListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const VACCINATION_FORM_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listVaccinationFormApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const INSERT_VACCINATION_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(insertVaccinationForm(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_VACCINATION_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(UpdateVaccinationForm(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const IMPORT_VACCINATION_FORM = async (body) => {
    try {
        let response = await axiosInstance.post(importformApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}












