import React from 'react'
import { Errors } from '../../Component/Error';
import moment from 'moment-timezone';
import Loading from '../../Component/Loading';
import { Avatar, Badge, styled } from '@mui/material';
import useAddPet from './Hooks/useAddPet';
import { PatientImageFilePath } from '../../Context/api';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { PetGenderOptions, PetTypeOptions } from '../../Context/constants';
import { SelectBox } from '../../Component/Inputs';
import { MdOutlineCameraAlt } from 'react-icons/md';
import DeleteProfile from '../../Component/DeleteProfile';
import { FaArrowLeft } from 'react-icons/fa';
const AddPet = () => {
    const { loader, imgUrl, addpet, UploadImage, setImgUrl, FileRef, navigate, id, handalDOB, setLoader } = useAddPet()
    const { CustomButton } = useMuiCompontent()
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(`/patients/${id}/sub-members`) }} />
                        <h2 className='page_name'>Add Pet</h2>
                    </div>
                </header>
                <div className=" row common-white-bg-box mx-0 d-flex justify-content-between  shadow position-relative px-2 ">

                    <div>
                        {/* <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Pet ID</label>
                                </div>
                                <div className="col-4">
                                    <input type="text" readOnly maxLength={50} className="form-control" />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control" value={addpet.values.firstName}
                                        onChange={(e) => addpet.setFieldValue("firstName", e.target.value)} />
                                    <Errors formikfun={addpet} name={"firstName"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" maxLength={50} className="form-control" value={addpet.values.firstName}
                                                onChange={(e) => addpet.setFieldValue("firstName", e.target.value)} />
                                            <Errors formikfun={addpet} name={"firstName"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Pet Type<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <SelectBox
                                        placeholder="Select Pet Type"
                                        value={addpet.values.petType}
                                        name="petType"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={(e) => addpet.setFieldValue("petType", e.target.value)}
                                        option={PetTypeOptions}
                                    />
                                    <Errors formikfun={addpet} name={"petType"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Pet Type<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SelectBox
                                                placeholder="Select Pet Type"
                                                value={addpet.values.petType}
                                                name="petType"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => addpet.setFieldValue("petType", e.target.value)}
                                                option={PetTypeOptions}
                                            />
                                            <Errors formikfun={addpet} name={"petType"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        {
                            addpet.values.petType === "Others" && <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Other<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4">
                                        <input type="text" maxLength={50} className="form-control" value={addpet.values.petTypeField}
                                            onChange={(e) => addpet.setFieldValue("petTypeField", e.target.value)} />
                                        <Errors formikfun={addpet} name={"petTypeField"} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Breed<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control" value={addpet.values.breed}
                                        onChange={(e) => addpet.setFieldValue("breed", e.target.value)} />
                                    <Errors formikfun={addpet} name={"breed"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Breed<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" maxLength={50} className="form-control" value={addpet.values.breed}
                                                onChange={(e) => addpet.setFieldValue("breed", e.target.value)} />
                                            <Errors formikfun={addpet} name={"breed"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="date" value={addpet.values.dob?moment(addpet.values.dob).format("YYYY-MM-DD"):""} className="form-control position-relative"
                                        onChange={(e) => { handalDOB(e); addpet.setFieldValue("dob", e.target.value) }}
                                    />
                                    <Errors formikfun={addpet} name={"dob"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="date" value={moment(addpet.values.dob).format("YYYY-MM-DD")} className="form-control position-relative"
                                                onChange={(e) => { handalDOB(e); addpet.setFieldValue("dob", e.target.value) }}
                                                max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                            />
                                            <Errors formikfun={addpet} name={"dob"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                   
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                <input type="text" className="form-control" name='age' disabled={true} value={addpet.values.age} />
                                                <Errors formikfun={addpet} name={"age"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <SelectBox
                                        placeholder="Select Gender"
                                        value={addpet.values.gender}
                                        name="gender"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={(e) => addpet.setFieldValue("gender", e.target.value)}
                                        option={PetGenderOptions}
                                    />
                                    <Errors formikfun={addpet} name={"gender"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <SelectBox
                                                placeholder="Select Gender"
                                                value={addpet.values.gender}
                                                name="gender"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => addpet.setFieldValue("gender", e.target.value)}
                                                option={PetGenderOptions}
                                            />
                                            <Errors formikfun={addpet} name={"gender"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Display Picture</label>
                                </div>
                                <div className="col-4 d-flex  align-items-start flex-column">
                                    <Badge
                                        style={{ background: "#fff" }}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                        badgeContent={
                                            <SmallAvatar alt="Remy Sharp"  >
                                                <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                </MdOutlineCameraAlt>
                                                <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                    onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl)}
                                                />
                                            </SmallAvatar>
                                        }
                                    >
                                        <Avatar  sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                        </Avatar>
                                    </Badge>
                                    {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Display Picture</label>
                                        </div>
                                        <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                            <Badge
                                                style={{ background: "#fff" }}
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <SmallAvatar alt="Remy Sharp"  >
                                                        <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                        </MdOutlineCameraAlt>
                                                        <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                            onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl,imgUrl)}
                                                        />
                                                    </SmallAvatar>
                                                }
                                            >
                                                <Avatar sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                                </Avatar>
                                            </Badge>
                                            {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end'>
                        <div className="col-12 d-flex justify-content-end  align-items-end pb-2 mt-2">
                            <CustomButton variant="contained" onClick={addpet.handleSubmit} >Save</CustomButton>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddPet
