import { addNewConversationApi, addnewMemberTogroupAPi, addOrRemoveAdminAccessApi, allMessageListApi, blockUserApi, conversationListApi, deleteConversationsApi, deleteMessageApi, groupDetailsAPi, lastMessageApi, leaveGroupApi, readMessage, staffListApi, updateGroupInfoApi, updateGroupSettingsApi, uploadAttchamentApi, } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

export const CONVERSATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(conversationListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ADD_NEW_CONVERSATION = async (body) => {
    try {
        let response = await axiosInstance.post(addNewConversationApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_CONVERSATION = async (body) => {
    try {
        let response = await axiosInstance.post(deleteConversationsApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const BLOCK_USER = async (body) => {
    try {
        let response = await axiosInstance.post(blockUserApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const LEAVE_GROUP = async (body) => {
    try {
        let response = await axiosInstance.post(leaveGroupApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}



export const UPLOAD_ATTCHAMENT = async (body) => {
    try {
        let response = await axiosInstance.post(uploadAttchamentApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const STAFF_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(staffListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_ALL_MESSAGE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(allMessageListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_LAST_MESSAGE = async (body) => {
    try {
        let response = await axiosInstance.post(lastMessageApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_MESSAGE = async (body) => {
    try {
        let response = await axiosInstance.post(deleteMessageApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_GROUP_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(groupDetailsAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const ADD_NEW_MEMBER_TO_GROUP = async (body) => {
    try {
        let response = await axiosInstance.post(addnewMemberTogroupAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const ADD_AND_REMOVE_ADMIN_ACCESS = async (body) => {
    try {
        let response = await axiosInstance.post(addOrRemoveAdminAccessApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_GROUP_SETTING = async (body) => {
    try {
        let response = await axiosInstance.post(updateGroupSettingsApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_GROUP_INFO = async (body) => {
    try {
        let response = await axiosInstance.post(updateGroupInfoApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const READ_MESSAGE = async (body) => {
    try {
        let response = await axiosInstance.post(readMessage(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}








