import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NotificationsState } from "../../../App";
import useReduxData from "../../../Hooks/useReduxData";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { RoleTitle } from "../../../Context/constants";
import { NOTIFICATION_LIST, READ_NOTIFICATION } from "../../Repository/NotificationsRepo";
const useNotification = () => {
  const [paginationTotalCount, setPaginationTotalCount] = useState(0)
  const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
  const [notificationList, setNotificationList] = useState([])
  const [loader, setLoader] = useState(false)
  const [filter, setFilter] = useState("ALL")
  const { notificationcount, setNotificationcount } = useContext(NotificationsState)
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { CustomButton } = useMuiCompontent()
  useEffect(() => {
    getListNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controller, filter])
  useEffect(() => {
    document.title = "Notification"
  }, [])
  const headCells = [
    { id: "#", numeric: false, disablePadding: false, label: "#", showLeft: false },
    { id: "title", numeric: false, disablePadding: false, label: "Title", showLeft: false, showIcon: true },
    { id: "Description", numeric: false, disablePadding: false, label: "Description", showLeft: false },
    { id: "date", numeric: false, disablePadding: false, label: "Date", showLeft: true, padding: true, showIcon: true },
  ];

  // notification list
  async function getListNotification() {
    setLoader(true)
    const { page, rowsPerPage, } = controller;
    const payload = {
      page: page,
      count: rowsPerPage,
      providerId: userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? userdata?.userId : undefined,
      staffId: (userdata?.roleTitle === RoleTitle?.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.userId : undefined,
      filter: filter,
      roleTitle: userdata?.roleTitle
    };
    try {
      let response = await NOTIFICATION_LIST(payload)
      setNotificationList(response?.data)
      setPaginationTotalCount(response?.totalCount)
      setNotificationcount(response?.unreadCount)
      setLoader(false)
    } catch (error) {
      // if (error.message !== "No vaccination category found") toast.error(error.message)
      setNotificationList([])
      setPaginationTotalCount(0)
      setLoader(false)
    }
  }

  async function readNotification(notificationId) {

    setLoader(true)
    let payload = {
      notificationId: notificationId !== "ALL" ? notificationId : undefined,
      providerId: userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? userdata?.userId : undefined,
      staffId: (userdata?.roleTitle === RoleTitle?.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.userId : undefined,
      roleTitle: userdata?.roleTitle,
      filter: notificationId === "ALL" ? "ALL" : undefined
    }
    try {
      let response = await READ_NOTIFICATION(payload)
      setLoader(false)
      getListNotification()
      toast.success(response?.message)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }
  return {
    headCells,
    loader,
    setFilter,
    setController,
    notificationList,
    controller,
    notificationcount,
    CustomButton,
    readNotification,
    paginationTotalCount,
    filter
  }
}

export default useNotification
