import React from 'react'
import Header from './Header'
import ChatArea from './ChatArea'
import Footer from './Footer'

const Chatbox = ({setUserInfo,windowWidth,editMessage,handleOpenModal,setSendMessage, sendMessage,messageList,handleSendMessage,userInfo,Tab,handleBlockUser,setAttchament,attchament,setLoader,handleDeleteMessage,setEditMessage}) => {
    return (
        <>
            <Header handleOpenModal={handleOpenModal} userInfo={userInfo} Tab={Tab} windowWidth={windowWidth}setUserInfo={setUserInfo} />
            <ChatArea setAttchament={setAttchament}  messageList={messageList} userInfo={userInfo} Tab={Tab} handleDeleteMessage={handleDeleteMessage} setEditMessage={setEditMessage} editMessage={editMessage} setSendMessage={setSendMessage} />
            <Footer messageList={messageList} setLoader={setLoader} attchament={attchament} setAttchament={setAttchament} setSendMessage={setSendMessage} sendMessage={sendMessage}  handleSendMessage={handleSendMessage} handleBlockUser={handleBlockUser} userInfo={userInfo}Tab={Tab}editMessage={editMessage}  />
        </>
    )
}

export default Chatbox