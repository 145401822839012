import React from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Errors } from '../../../../Component/Error';
import { SelectBox } from "../../../../Component/Inputs";
import PdfImage from '../../../../Assets/Images/home/pdf.svg';
import xlsImage from "../../../../Assets/Images/xlsx.png"
import textImage from "../../../../Assets/Images/text-file.webp"
import DocImage from "../../../../Assets/Images/doc.png"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { PriorityOptions } from '../../../../Context/constants';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { IoMdCloseCircleOutline } from 'react-icons/io';
const CreateTicket = ({createTicket,setCreateTicket,setAttachFile,addTicket,attachmentFile,DeleteAttachmentFile,CustomButton,handleFileChange}) => {
    return (
        <Offcanvas placement="end" className="shadow Appointment_Offcanvas_style" show={createTicket} onHide={() => {
        setCreateTicket(false);
        setAttachFile([]);
        addTicket.resetForm()
    }}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create Ticket</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <div className="row">
                <div className="col-12 mb-3">
                    <label className="form-label mb-0">Subject<span className='Star_Mark'>*</span></label>
                    <input value={addTicket?.values?.subject} onChange={(e) => addTicket.setFieldValue("subject", e.target.value)} type="text" className="form-control" />
                    <Errors formikfun={addTicket} name={"subject"} />
                </div>
                <div className="col-sm-12 mb-3">
                    <label className="form-label mb-0">Priority</label>
                    <SelectBox
                        placeholder="Select Priority"
                        value={addTicket?.values?.priority}
                        name="priority"
                        keys="value"
                        label="label"
                        menuValeu="value"
                        handleChange={(e) => addTicket.setFieldValue("priority", e.target.value)}
                        option={PriorityOptions}
                    />
                    <Errors formikfun={addTicket} name={"priority"} />
                </div>
                <div className="col-sm-12 mb-3">
                    <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                    <CKEditor
                        editor={ClassicEditor}
                        name='description'
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            addTicket.setFieldValue("description", data)
                        }}
                        data={addTicket.values?.description}
                    />
                    <Errors formikfun={addTicket} name={"description"} />
                </div>
                <div className="col-12 mb-3">
                    <label className="form-label mb-0">Attachment</label>
                    <div className="bodrerer" style={{ border: `2px dashed #aaaaaa`, maxWidth: "100%" }}>
                        <label htmlFor="inputTag">
                            <AiOutlineCloudUpload className="fs-4" />
                            <input id="inputTag" type="file" name="file" multiple onChange={(event) => handleFileChange(event)} />
                            <br />
                            <p className="mb-0">Drag & Drop To Upload</p>
                            <br />
                            <span id="imageName"></span>
                        </label>
                    </div>
                </div>
                <div className="col-12 Ticket_Attachment mb-3">
                    <div className="row">
                        {[...attachmentFile].filter(cur => {
                            const filetype = cur?.file.name.split('.').pop();
                            return filetype === "mp4" || filetype === "wmv" || filetype === "mov";
                        }).map((cur, index) => (
                            <div className="col-12 mb-3 mx-0 position-relative" key={index}>
                                <video src={cur?.path} alt="" autoPlay controls></video>
                                <span onClick={() => DeleteAttachmentFile(index)}><IoMdCloseCircleOutline /></span>
                            </div>
                        ))}
                        {[...attachmentFile].filter(cur => {
                            const filetype = cur?.file.name.split('.').pop();
                            return filetype !== "mp4" && filetype !== "wmv" && filetype !== "mov";
                        }).map((cur, index) => (
                            <div className="col-4 mb-3 mx-0 position-relative" key={index}>
                                {['jpg', 'jpeg', 'png'].includes(cur?.file.name.split('.').pop()) && <img src={cur?.path} alt="" />}
                                {['pdf'].includes(cur?.file.name.split('.').pop()) && (<img src={PdfImage} alt="" width="100%" height="100%" />)}
                                {['xlsx', 'xls'].includes(cur?.file.name.split('.').pop()) && (<img src={xlsImage} alt="" width="100%" height="100%" />)}
                                {['txt', 'doc'].includes(cur?.file.name.split('.').pop()) && (<img src={textImage} alt="" width="100%" height="100%" />)}
                                {(['jpg', 'png', 'jpeg', 'pdf', 'xlsx', 'xls', 'txt', 'doc'].includes(cur?.file.name.split('.').pop()) === false) && (<img src={DocImage} alt="" width="100%" height="100%" />)}
                                <span onClick={() => DeleteAttachmentFile(index)}><IoMdCloseCircleOutline /></span>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="col-12 text-center ">
                    <CustomButton onClick={addTicket.handleSubmit} variant="contained" className="mx-3">submit</CustomButton>
                </div>
            </div>
        </Offcanvas.Body>
    </Offcanvas>
  )
}

export default CreateTicket
