import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useOutletContext, useParams } from 'react-router-dom';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import moment from 'moment-timezone';
import { UPDATE_PET } from '../../Repository/PatientRepo';


const useUpdatePet = (type) => {
    const [imgUrl, setImgUrl] = useState(null);
    const [other, setOther] = useState(false)
    const { id, subpatientId } = useParams()
    const [loader, setLoader] = useState(false)
    const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
    const { getpatientDetails } = useOutletContext()
    useEffect(() => {
        updatePetfun.setValues(patientdetails)
        setImgUrl(patientdetails?.image)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { UploadImage } = useUploadImage()
    const FileRef = useRef()
    // pet details by id
    const updatePetfun = useFormik({
        initialValues: {
            firstName: "",
            gender: "",
            petType: "",
            breed: "",
            dob: "",
            otherPetType: "",
            age: "",
            petTypeField: ""
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please enter  name").matches(/^(?![\s-])[\w\s-]+$/, "white space is not required"),
            gender: Yup.string().required("Please select gender "),
            petType: Yup.string().required("Please select pet type "),
            breed: Yup.string().required("Please enter  breed"),
            dob: Yup.string().required("Please choose  age"),
            petTypeField: other ? Yup.string().nullable().required("Please enter pet type ") : Yup.string().nullable(),
        }),
        onSubmit: async (value) => {
            const { id: petId, ...restValue } = value
            let data = {
                patientId: type === "Patients" ? id : subpatientId,
                firstName: restValue.firstName,
                gender: restValue.gender,
                petType: restValue.petType,
                breed: restValue.breed,
                image: imgUrl,
                petTypeField: restValue?.petTypeField,
                dob: moment(restValue?.dob).format('YYYY-MM-DD'),
                age: restValue.age,
            }
            setLoader(true)
            try {
                let response = await UPDATE_PET(data)
                toast.success(response?.message)
                setLoader(false)
                getpatientDetails()
                // navigate(`/patients/${id}/sub-members`)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }


    })
    // handal change birthDate
    const handalDOB = (e) => {
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        updatePetfun.setFieldValue("age", age ?? "")
    }

    useEffect(() => {
        if (updatePetfun.values.petType === "other") {
            setOther(true)
        }
        else {
            setOther(false)
        }
    }, [updatePetfun.values.petType])

    return {
        loader,
        imgUrl,
        updatePetfun,
        UploadImage,
        setImgUrl,

        handalDOB,
        setLoader,
        FileRef,
        patientdetails,
        id, subpatientId,
    }
}

export default useUpdatePet
