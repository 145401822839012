import axios from "axios"
import { createPinApi, decline, forgotpin, loginApi, logoutApi, resendApi, signUpApi, validateAuthToken, verifyApi } from "../../Context/api"
import { commonMessages, statusCode } from "../../Context/constants"
import handleError from "../../Utils/handleError"

export const LOGIN = async (body) => {
    try {
        let response = await axios.post(loginApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const SIGNUP = async (body) => {
    let response = await axios.post(signUpApi(), body)
    if (response.status !== statusCode.OK ) {
        throw new Error({message:response.data.message || commonMessages.NetworkError,code:response.status})
    }
    return response.data
}

export const RESEND_OTP = async (body) => {
    try {
        let response = await axios.post(resendApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const VERIFY_OTP = async (body) => {
    try {
        let response = await axios.post(verifyApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CREATE_PIN = async (body) => {
    try {
        let response = await axios.post(createPinApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const FORGOT_PIN = async (body) => {
    try {
        let response = await axios.post(forgotpin(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const  VALIDATE_AUTH_TOKEN = async (body) => {
    try {
        let response = await axios.post(validateAuthToken(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const  DECLINE_INTITATION = async (body) => {
    try {
        let response = await axios.post(decline(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const LOGOUT = async (body) => {
    try {
        let response = await axios.post(logoutApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}








