import { Chip, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'
import { Modal, Offcanvas } from 'react-bootstrap'
import { Errors } from '../../Component/Error'
import moment from 'moment-timezone'
import Loading from '../../Component/Loading'
import useTableSorting from '../../Hooks/useTableSorting'
import useAdditionalDetails from './Hooks/useAdditionalDetails'

const AdditionalDetails = ({ type }) => {
    const {
        CustomButton,
        loader,
        formik,
        headCells,
        MedicalHistoryList,
        addMadical,
        deleteModal,
        historyModal,
        tab,
        patientVitalDetails,
        allPatientVitalHistory,
        patientLifestyleDetails,
        allPatientLifestyleHistory,
        allergiesHistory,
        allergiesAllHistory,
        deleteLifeStyleFun,
        setHistoryModal,
        setAddMadical,
        setDeletedModal,
        ChangeTab,
        getVitalsDetailsAllHistory,
        getLifestyleDetailsAllHistory,
        getAllergiesDetailsAllHistory,
    } = useAdditionalDetails(type)
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)


    return (
        <>
            {loader && <Loading />}
            <div className="">
                <div className="row px-1">
                    <div className="col-12 px-0 mt-3 d-flex justify-content-between pb-3" style={{ borderBottom: "2px solid #ededed" }}>
                        <ul className="nav  mb-0  ">
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${tab === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => ChangeTab("Vitals")} >Vitals</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${tab === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Lifestyle")} >Lifestyle</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${tab === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Allergies")} >Allergies</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${tab === 4 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("MadicalHistory")} >Medical History</button>
                            </li>
                        </ul>
                        {tab === 4 && <CustomButton variant="contained" onClick={() => setAddMadical(true)}>Add</CustomButton>}

                    </div>
                </div>
                {tab === 1 &&
                    <div className="row mt-3 ">
                        <div className="col-sm-12 ">
                            <div className="row">
                                {/* <div className="col-3">
                                </div> */}
                                <div className="col-12 d-flex justify-content-end align-items-center px-0"   >
                                    <p className='mb-0 me-3 d-flex' style={{ color: "gray", fontSize: "13px" }}>Last Updated Date : {patientVitalDetails?.createdOn ? moment(patientVitalDetails?.createdOn)?.format('ll') : "--"}</p>
                                    <CustomButton variant="contained" onClick={() => { getVitalsDetailsAllHistory(); setHistoryModal((pre) => ({ ...pre, vitals: true })) }}>History</CustomButton>
                                </div>
                                {/* <div className="col-9 d-flex justify-content-end align-items-center px-0"   >
                                    <p className='mb-0 me-3' style={{ color: "gray", fontSize: "13px" }}>Last Updated Date : {patientVitalDetails?.createdOn ? moment(patientVitalDetails?.createdOn)?.format('ll') : "--"}</p>
                                    <CustomButton variant="contained" onClick={() => { getVitalsDetailsAllHistory(); setHistoryModal((pre) => ({ ...pre, vitals: true })) }}>History</CustomButton>                                
                                    </div> */}
                                    </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">BP(SYS/DIA)</label>
                                </div>
                                <div className="col-4">
                                    <input type="text" value={patientVitalDetails?.bp ? patientVitalDetails?.bp : ""} className="form-control" placeholder='SYS/DIA' disabled />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">BP(SYS/DIA)</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" value={patientVitalDetails?.bp ? patientVitalDetails?.bp : "-"} className="form-control" placeholder='SYS/DIA' disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Pulse(BPM)</label>
                                </div>
                                <div className="col-4">
                                    <input type="number" maxLength={3} value={patientVitalDetails?.pulse ? patientVitalDetails?.pulse : ""} className="form-control" placeholder='enter pulse' name='pulse' disabled />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Pulse(BPM)</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="number" maxLength={3} value={patientVitalDetails?.pulse ? patientVitalDetails?.pulse : "-"} className="form-control" placeholder='enter pulse' name='pulse' disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Weight(KG)</label>
                                </div>
                                <div className="col-4">
                                    <input type="number" className="form-control" value={patientVitalDetails?.weight ? patientVitalDetails?.weight : ""} placeholder='weight in kg' name='weight' disabled />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Weight(KG)</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="number" className="form-control" value={patientVitalDetails?.weight ? patientVitalDetails?.weight : "-"} placeholder='weight in kg' name='weight' disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Height(CM)</label>
                                </div>
                                <div className="col-4">
                                    <input type="number" className="form-control" value={patientVitalDetails?.height ? patientVitalDetails?.height : ""} placeholder='height in cm' name='height' disabled />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Height(CM)</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="number" className="form-control" value={patientVitalDetails?.height ? patientVitalDetails?.height : "-"} placeholder='height in cm' name='height' disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Body Temperature(°F)</label>
                                </div>
                                <div className="col-4">
                                    <input type="number" className="form-control" value={patientVitalDetails?.temperature ? patientVitalDetails?.temperature : ""} placeholder=' body temperature in °F' disabled />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Body Temperature(°F)</label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="number" className="form-control" value={patientVitalDetails?.temperature ? patientVitalDetails?.temperature : "-"} placeholder=' body temperature in °F' disabled />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    tab === 2 && <div className="row mt-3">
                        <div className="col-sm-12 ">
                            <div className="row">
                                <div className="col-3">
                                </div>
                                <div className="col-9 d-flex justify-content-end align-items-center px-0"   >
                                    <p className='mb-0 me-3' style={{ color: "gray", fontSize: "13px" }}>Last Updated Date : {patientLifestyleDetails?.appointmentDate ? moment(patientLifestyleDetails?.appointmentDate)?.format('ll') : "--"}</p>
                                    <CustomButton variant="contained" onClick={() => { getLifestyleDetailsAllHistory(); setHistoryModal((pre) => ({ ...pre, LifeStyles: true })) }}  >History</CustomButton>                                </div>
                            </div>
                        </div>
                        <div className="col-12 tab_scroll">
                            {
                                !patientLifestyleDetails ?
                                    <h3 className='text-center mt-5' style={{ color: "gray", fontSize: "12px" }}>No record found  </h3> : <table className="table bootstrap_table_responsive">
                                        <thead>
                                            <tr>
                                                <td >Habit Name</td>
                                                <td >Habit</td>
                                                <td >Consumption</td>
                                                <td >Past Habit</td>
                                                <td >Record</td>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                patientLifestyleDetails?.lifestyleArray?.map((cur, index) => {
                                                    return <tr key={index}>
                                                        <td style={{ color: "gray" }}>{cur?.lifestyleName}</td>
                                                        <td style={{ color: "gray" }}>{`${cur?.habit ? "YES" : "NO"}`}</td>
                                                        <td style={{ color: "gray" }}>{cur?.consumption ? cur?.consumption : "--"}</td>
                                                        <td style={{ color: "gray" }}> {`${cur?.pastHabit ? "YES" : "NO"}`}</td>
                                                        <td style={{ color: "gray" }}> {cur?.record ? cur?.record : "--"}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                            }


                        </div>
                    </div>
                }
                {
                    tab === 3 && <div className="row mt-3">
                        <div className="col-sm-12 ">
                            <div className="row">
                                <div className="col-3">
                                </div>
                                <div className="col-9 d-flex justify-content-end align-items-center px-0"   >
                                    <CustomButton variant="contained" onClick={() => { getAllergiesDetailsAllHistory(); setHistoryModal((pre) => ({ ...pre, Allergies: true })) }}>History</CustomButton>                             </div>
                            </div>
                        </div>
                        <div className="col-12">
                            {
                                !allergiesHistory && <h3 className='text-center mt-5' style={{ color: "gray", fontSize: "12px" }}>No record found  </h3>
                            }
                            {
                                allergiesHistory?.allergyArray?.map((cur, index) => {
                                    return <Chip key={cur?.allergyId} label={cur?.allergy}
                                        style={{ color: `#fff`, background: `#12BABA`, cursor: "pointer" }}
                                        className="mb-2 me-2"
                                    />
                                })
                            }
                        </div>
                    </div>
                }
                {
                    tab === 4 && <div className="row mt-3">
                        <div className="col-12">
                            <TableContainer className=""  >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        MedicalHistoryList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={MedicalHistoryList?.length} />
                                    }
                                    <TableBody>
                                        {MedicalHistoryList?.length === 0 &&
                                            <TableRow><TableCell colSpan={20} align="center" style={{ color: "gray", fontSize: "12px", borderBottom: "0" }}  >No record found</TableCell></TableRow>
                                        }

                                        {stableSort(MedicalHistoryList, getComparator(order, orderBy))?.map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}>
                                                    <TableRow id="hover_tr">
                                                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                            {/* {controller?.rowsPerPage * (controller?.page - 1) + index + 1} */}
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell id={labelId} align="left" >{row?.medicalHistoryName}</TableCell>
                                                        <TableCell id={labelId} align="left" >{moment(row?.medicalHistoryDate).format('LL')}</TableCell>
                                                        <TableCell id={labelId} align="right" >{row?.medicalHistoryPlace}</TableCell>
                                                        {/* <TableCell id={labelId} align="right" >
                                                            <Tooltip title="Delete" placement="left" style={{ cursor: "pointer" }}>
                                                                <i style={{ cursor: "pointer" }} className="fa-solid fa-trash-can ms-3 all_icons_color" onClick={() => { setDeletedData(row); setDeletedModal(true) }} ></i>
                                                            </Tooltip>
                                                        </TableCell> */}
                                                    </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                }
            </div>
            <Modal show={addMadical} onHide={() => setAddMadical(false)} centered>
                <Modal.Body>
                    <div className="modal-body adddetail">
                        <div className="text-center">
                            <h4 className="modal-title mb-3">Add Medical History</h4>
                        </div>
                        <div className="row modalform">
                            <div className="col-sm-12  py-4 ">
                                <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <input type="text" maxLength={50} className="form-control"
                                            value={formik?.values?.name}
                                            onChange={(e) => formik?.setFieldValue("name", e.target.value)}
                                        />
                                        <Errors formikfun={formik} name={"name"} />                                </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-4 ">
                                <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Date<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <input type="date" maxLength={3}
                                            className="form-control position-relative"
                                            value={formik?.values?.date}
                                            onChange={(e) => formik?.setFieldValue("date", e.target.value)}
                                        />
                                        <Errors formikfun={formik} name={"date"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12  py-4 ">
                                <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Place<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-9">
                                        <input type="text" className="form-control"
                                            value={formik?.values?.place}
                                            onChange={(e) => formik?.setFieldValue("place", e.target.value)}
                                        />
                                        <Errors formikfun={formik} name={"place"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-end mt-3 ">
                                <CustomButton variant="outlined" className='me-3' onClick={() => setAddMadical(false)}  > cancel</CustomButton>
                                <CustomButton variant="contained" onClick={formik.handleSubmit} > Save</CustomButton>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={deleteModal} centered onHide={() => setDeletedModal(false)}>
                <Modal.Body className="px-0">
                    <div className="modal-body text-center cus-i">
                        <i className="fa-solid fa-trash-can mb-4 all_icons_color"></i>
                        <p>Are you sure?</p>
                        <p>You want to Delete this medical history</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => deleteLifeStyleFun("YES",)} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => deleteLifeStyleFun("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Offcanvas placement="end" className="Offcanvas_style shadow" show={historyModal?.vitals} onHide={() => setHistoryModal((pre) => ({ ...pre, vitals: false }))}>
                <Offcanvas.Header closeButton className="py-0 pt-2">
                    <Offcanvas.Title>Vitals History</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row g-3">
                        {
                            allPatientVitalHistory?.map((cur, index) => {
                                return <React.Fragment key={index}>
                                    <div className="col-12">
                                        <p className='mb-0'>{moment(cur?.createdOn)?.format('ll')}</p>
                                        <div className="col shadow  p-2" style={{ border: "1.5px solid gray" }}>
                                            <div className="row">
                                                <div className="col-sm-6 mb-2 ">
                                                    <label className="form-label mb-0 mb-0">BP(SYS/DIA)</label>
                                                    <p className='mb-0' style={{ color: 'gray', fontSize: "15px" }}>{cur?.bp ? cur?.bp : "-"}</p>
                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label className="form-label mb-0 mb-0">Pulse(BPM)</label>
                                                    <p className='mb-0' style={{ color: 'gray', fontSize: "15px" }}>{cur?.pulse ? cur?.pulse : "-"}</p>
                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label className="form-label mb-0 mb-0">Weight(KG)</label>
                                                    <p className='mb-0' style={{ color: 'gray', fontSize: "15px" }}>{cur?.weight ? cur?.weight : "-"}</p>
                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label className="form-label mb-0 mb-0">Height(CM)</label>
                                                    <p className='mb-0' style={{ color: 'gray', fontSize: "15px" }}>{cur?.height ? cur?.height : "-"}</p>
                                                </div>
                                                <div className="col-sm-6 mb-2">
                                                    <label className="form-label mb-0 mb-0">Body Temperature(°F)</label>
                                                    <p className='mb-0' style={{ color: 'gray', fontSize: "15px" }}>{cur?.temperature ? cur?.temperature : "-"}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </React.Fragment>
                            })
                        }

                    </div>

                </Offcanvas.Body>
            </Offcanvas >
            <Offcanvas style={{
                width: "650px",
                height: "calc(100vh - 66px)",
                marginTop: "66px"
            }} placement="end" className=" shadow" show={historyModal?.LifeStyles} onHide={() => { setHistoryModal((pre) => ({ ...pre, LifeStyles: false })) }}>
                <Offcanvas.Header closeButton className="py-0 pt-2">
                    <Offcanvas.Title>Life Styles History</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        {
                            allPatientLifestyleHistory?.map((cur, index) => {

                                return <div className="col-12 mb-3" key={index}>
                                    <p className='mb-0'>{moment(cur?.appointmentDate)?.format('ll')}</p>
                                    <div className="col shadow  p-2" style={{ border: "1.5px solid gray" }}>
                                        <table className="table bootstrap_table_responsive table-hover">
                                            <thead>
                                                <tr>
                                                    <td >Substanc Name</td>
                                                    <td >Habit</td>
                                                    <td >Consumption</td>
                                                    <td >Past Habit</td>
                                                    <td >Record</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cur?.lifestyleArray?.map((lifeStyleData, index) => {
                                                        return <tr>
                                                            <td style={{ color: "gray" }}>{lifeStyleData?.lifestyleName}</td>
                                                            <td style={{ color: "gray" }}>{`${lifeStyleData?.habit ? "YES" : "NO"}`}</td>
                                                            <td style={{ color: "gray" }}>{lifeStyleData?.consumption ? lifeStyleData?.consumption : "--"}</td>
                                                            <td style={{ color: "gray" }}> {`${lifeStyleData?.pastHabit ? "YES" : "NO"}`}</td>
                                                            <td style={{ color: "gray" }}> {lifeStyleData?.record ? lifeStyleData?.record : "--"}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            })
                        }

                    </div>
                </Offcanvas.Body>
            </Offcanvas >
            <Offcanvas placement="end" className="Offcanvas_style shadow" show={historyModal?.Allergies} onHide={() => setHistoryModal((pre) => ({ ...pre, Allergies: false }))}>
                <Offcanvas.Header closeButton className="py-0 pt-2">
                    <Offcanvas.Title>Allergies History</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="row">
                        {
                            allergiesAllHistory?.map((cur, index) => {
                                return <div className="col-12 mb-3" key={index}>
                                    <p className='mb-0'>{moment(cur?.appointmentDate)?.format('ll')}</p>
                                    <div className="col shadow  p-2" style={{ border: "1.5px solid gray" }}>
                                        {
                                            cur?.allergyArray?.map((allergiesData, index) => {
                                                return <Chip key={allergiesData?.allergyId} label={allergiesData?.allergy}
                                                    style={{ color: `#fff`, background: `#12BABA`, cursor: "pointer" }}
                                                    className="mb-2 me-2"
                                                />
                                            })
                                        }

                                    </div>
                                </div>
                            })
                        }

                    </div>
                </Offcanvas.Body>
            </Offcanvas >
        </>
    )
}

export default AdditionalDetails


