import React from 'react';
import useTableSorting from '../../Hooks/useTableSorting';
import { Avatar, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
// import { SortbyAppointment } from '../../Context/constants';
import Loading from '../../Component/Loading';
import Filters from '../../Component/Insights/Filters';
import { formatNumber } from '../../Utils/Utils';
// import { NoRecordFound } from '../../Component/NoRecordFound';
import useTeamReport from './Hooks/useTeamReport';
// import { SelectBox } from '../../Component/Inputs';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
// import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from 'recharts';
import { IoFilterSharp } from 'react-icons/io5';
import { TeamImageFilePath } from '../../Context/api';
const TeamReport = () => {
    const {
        loader,
        selectClinic,
        clinicList,
        Reports,
        headCells,
        dateRangeDropdown,
        dateFilter,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        filterPopup,
        setFilterPopup,
        SetSorting,
        sorting,
        userdata,
        customDate, setCustomDate,
        setAction,
        getTeamDetail,
        setAppointmentFilter,
        appointmentFilter,
    } = useTeamReport()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const { CustomButton } = useMuiCompontent()
    // const data = [
    //     {
    //         name: 'Monday',
    //         month: 'January',
    //         uv: 4000,
    //         pv: 2400,
    //         amt: 2400,
    //     },
    //     {
    //         name: 'Tuesday',
    //         month: 'February',
    //         uv: 3000,
    //         pv: 1398,
    //         amt: 2210,
    //     },
    //     {
    //         name: 'Wednesday',
    //         month: 'March',
    //         uv: 2000,
    //         pv: 9800,
    //         amt: 2290,
    //     },
    //     {
    //         name: 'Thursday',
    //         month: 'April',
    //         uv: 2780,
    //         pv: 3908,
    //         amt: 2000,
    //     },
    //     {
    //         name: 'Friday',
    //         month: 'May',
    //         uv: 1890,
    //         pv: 4800,
    //         amt: 2181,
    //     },
    //     {
    //         name: 'Saturday',
    //         month: 'June',
    //         uv: 2390,
    //         pv: 3800,
    //         amt: 2500,
    //     },
    //     {
    //         name: 'Sunday',
    //         month: 'July',
    //         uv: 3490,
    //         pv: 4300,
    //         amt: 2100,
    //     },
    // ];
    return <>
        {loader && <Loading />}
        <div className="py-3">
            <div className="row">
                <div className="col-12  position-relative px-0 ">
                    <div className='w-100 text-end'>
                        <CustomButton onClick={() => setFilterPopup((pre) => !pre)} className='' variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<IoFilterSharp />}  >Filters</CustomButton>
                    </div>
                    <Filters
                        setDateRangeDropdown={setDateRangeDropdown}
                        dateFilter={dateFilter}
                        selectClinic={selectClinic}
                        setSelectClinic={setSelectClinic}
                        clinicList={clinicList}
                        setDateFilter={setDateFilter}
                        dateRangeDropdown={dateRangeDropdown}
                        page="STAFF_REPORT"
                        getDetail={getTeamDetail}
                        setAction={setAction}
                        setCustomDate={setCustomDate}
                        customDate={customDate}
                        SetSorting={SetSorting}
                        sorting={sorting}
                        userdata={userdata}
                        setFilterPopup={setFilterPopup}
                        filterPopup={filterPopup}
                        appointmentFilter={appointmentFilter}
                        setAppointmentFilter={setAppointmentFilter}
                    />
                </div>
                <div className="col-12 px-0">
                    <div className="row d-flex justify-content-center" style={{ fontSize: "13px" }}>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{formatNumber(Reports?.bookedAppointmentCount||0)}</h4>
                                <p className='mb-0'>Total Bookings</p>
                            </div>
                        </div>
                        <div className="col-3 p-1">
                            <div className="col py-4 shadow p-2 d-flex justify-content-center align-items-center flex-column" style={{ background: "#fff", borderRadius: "5px" }}>
                                <h4 style={{ color: "#38C4B9" }}>{`${Reports?.currencyImage??""} ${formatNumber(Reports?.totalRevenueEarned||0)}`}</h4>
                                <p className='mb-0'>Revenue Earned</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // selectClinic[0]?.clinicId !== "ALL_CLINCI" &&
                    <>
                        <div className="col-12 px-0 mt-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='mb-0'>Top Team Member</p>
                            </div>
                        </div>
                        <div className="col-12 px-0">
                            <TableContainer className="text_box_height_insights" >
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={Reports?.topteam?.length} />

                                    <TableBody className="table_overflow_scroll" >
                                        {stableSort(Reports?.topteam?.length ? Reports?.topteam : [], getComparator(order, orderBy)).map(
                                            (item, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow id="hover_tr"   >
                                                    <TableCell className="ps-3" id={labelId} scope="row"style={{padding:"6px 17px "}} >
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" style={{padding:"6px 17px "}}>
                                                        <Avatar src={(TeamImageFilePath + item?.image)} />
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" style={{padding:"6px 17px "}}>{item?.name}</TableCell>
                                                    <TableCell id={labelId} align="left" style={{padding:"6px 17px "}}> {formatNumber(item?.count)}</TableCell>
                                                    <TableCell id={labelId} align="left" style={{padding:"6px 17px "}}>{Reports?.currencyImage??""} {formatNumber(item?.RevenueEarned)}</TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }

                {/* {
                    selectClinic[0]?.clinicId === "ALL_CLINCI" && <>
                        <div className="col-md-6 col-12 ps-0 mt-3">
                            <div className="col shadow-sm p-4">
                                <h1 className="mb-3 " style={{ fontSize: "13px" }}>Total Booked</h1>
                                <div style={{ width: '100%', height: 320 }}>
                                    <ResponsiveContainer>
                                        <AreaChart
                                            data={data}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="uv" stroke="#38C4B9" fill="#dcf2f0" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-12 ps-0 mt-3">
                            <div className="col shadow-sm p-4">
                                <h1 className="mb-3 " style={{ fontSize: "13px" }}>Revenue Earned</h1>
                                <div style={{ width: '100%', height: 320 }}>
                                    <ResponsiveContainer>
                                        <AreaChart
                                            data={data}
                                            margin={{
                                                top: 10,
                                                right: 30,
                                                left: 0,
                                                bottom: 0,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Area type="monotone" dataKey="uv" stroke="#38C4B9" fill="#dcf2f0" />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>


                    </>

                } */}
            </div >
        </div >
    </>;
};

export default TeamReport;
