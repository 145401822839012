
const useDragDrop = () => {
    const handleDragEnter = (e) => {
        e.preventDefault();
        // setIsDragging(true);
    };

    const handleDragLeave = () => {
        // setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, uploadPdf, type, index = undefined) => {
        e.preventDefault();
        // setIsDragging(false);
        let files;
        if (type === "multiple") {
            files = e.dataTransfer.files;
        } else {
            files = e.dataTransfer.files[0];
        }
        uploadPdf(files, index);
    }
    return {
        handleDragEnter,
        handleDragLeave,
        handleDragOver,
        handleDrop
    }
}

export default useDragDrop