import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useReduxData from '../../Hooks/useReduxData'
import { toast } from 'react-toastify'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Loading from '../../Component/Loading'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { activeStatusOption, dailogAllMessage, publishStatusOption, RoleTitle } from '../../Context/constants'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import useTableSorting from '../../Hooks/useTableSorting'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { EDIT_VACCINATION_FORM, VACCINATION_FORM_LIST } from '../Repository/VaccinationRepo'
import { SelectBox } from '../../Component/Inputs'
import { FiRefreshCcw } from 'react-icons/fi'
import { BiArchiveIn } from 'react-icons/bi'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { TbSwitchHorizontal } from "react-icons/tb";
import { Modal } from 'react-bootstrap'
import { FaArrowLeft } from 'react-icons/fa'
const VaccinationCalendar = () => {
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate()
    const [VaccinationFormList, setVaccinationFormList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [Filter, setFilter] = useState({
        archive: true,
        publishStatus: 'ALL',
        formName: "",
        disable_button: true
    })
    const [openModal, setOpenModal] = useState({
        public: false,
        archive: false,
        importform: false,
        data: null
    })
    const { id } = useParams()
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "formName", numeric: true, disablePadding: false, label: "Vaccination Form", isShorted: true },
        { id: "publishStatus", numeric: true, disablePadding: false, label: "Status", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    /////////////////////////// list vaccination form list //////////////////////////
    async function getVaccinationFormList() {
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, formName, publishStatus } = Filter;
        const keyword = formName.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            page: page,
            count: rowsPerPage,
            activeStatus: archive,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            vaccinationCategoryId: id,
            publishStatus: publishStatus === "ALL" ? undefined : publishStatus
        };
        setLoader(true)
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            setVaccinationFormList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            // if (error.message !== "No vaccination form found") toast.error(error.message)
            setVaccinationFormList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }
    }



    ////////////////////////////// archive/unarchive //////////////////////////

    const handleClose = async (event, data) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                activeStatus: !data.activeStatus,
                vaccinationFormId: data?.vaccinationFormId,
                categoryId: id
            }
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                if (VaccinationFormList?.length === 1 && controller.page === 1) {
                    getVaccinationFormList()
                }
                else if (VaccinationFormList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getVaccinationFormList()
                setLoader(false)
                handleClosedModal("archive")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
        else {
            handleClosedModal("archive")
        }
    }

    const handlePublicForm = async (event, data) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                publishStatus: !data.publishStatus,
                vaccinationFormId: data?.vaccinationFormId,
                categoryId: id
            }
            try {
                let response = await EDIT_VACCINATION_FORM(payload)
                toast.success(response.message)
                setLoader(false)
                if (VaccinationFormList?.length === 1 && controller.page === 1) {
                    getVaccinationFormList()
                }
                else if (VaccinationFormList?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getVaccinationFormList()
                handleClosedModal("public")
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
        else {
            handleClosedModal("public")
        }
    }

    useEffect(() => {
        getVaccinationFormList()
        navigate({ replace: true, state: null })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])


    const handleOpenModal = (type, data = null) => {
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, formName: "", disable_button: true, publishStatus: "ALL" })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }


    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(`/vaccination`) }} />
                        <h2 className='page_name'>Vaccination Calendar </h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 shadow px-2 pt-3 d-flex justify-content-between flex-column" >
                    <div>


                        <header className="body_header d-flex justify-content-between align-items-center py-2 ps-2">
                            <ul className="headerlist px-0">
                                <li className='phone_hide me-2'>
                                    <input type="text" onChange={handleChangeFilter} value={Filter?.formName} placeholder="Search" className="form-control filter_input" name="formName" />
                                </li>
                                <li className="me-2 archive_width" style={{ width: 150 }}>
                                    <SelectBox
                                        placeholder="Select"
                                        value={Filter?.archive}
                                        name="archive"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={handleChangeFilter}
                                        option={activeStatusOption}
                                    />
                                </li>
                                <li className="me-2 archive_width" style={{ width: 150 }}>
                                    <SelectBox
                                        placeholder="Select"
                                        value={Filter?.publishStatus}
                                        name="publishStatus"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={handleChangeFilter}
                                        option={publishStatusOption}
                                    />
                                </li>
                                <li className='phone_hide'>
                                    <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
                                </li>
                                <li className="mx-2 d-flex justify-content-center align-items-center" >
                                    <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                        <FiRefreshCcw />
                                    </IconButton>
                                </li>
                                <li className="">
                                    <CustomButton variant="contained" onClick={() => navigate('add-form')} >Create Form</CustomButton>
                                </li>
                            </ul>
                            {/* <ul className="headerlist px-0 phone_hide">

                                <li>
                                    <CustomButton variant="contained"
                                        style={{ background: "#f5f5f5", color: "black" }}
                                        // onClick={() => { setFiltershow(!filtershow) }}
                                        startIcon={<ExitToAppIcon />}
                                    >export</CustomButton>
                                </li>
                            </ul> */}
                        </header>
                        <TableContainer  >
                            {VaccinationFormList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table"
                            >
                                {
                                    VaccinationFormList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={VaccinationFormList?.length} />
                                }
                                <TableBody className="table_overflow_scroll" >
                                    {stableSort(VaccinationFormList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow id="hover_tr"
                                                style={{ cursor: 'pointer' }}>
                                                <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                    {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{row?.formName}</TableCell>
                                                <TableCell id={labelId} align="left" >{row?.publishStatus ? "PUBLISH" : "DRAFT"}</TableCell>
                                                <TableCell id={labelId} align="right"  >
                                                    <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu dropdown-menu-end me-3">
                                                        <li><button className={`dropdown-item`} onClick={() => navigate(`update-form/${row?.vaccinationFormId}`)}><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Vaccination Form</button></li>
                                                        <li><button className={`dropdown-item`} onClick={() => navigate(`clone/${row?.vaccinationFormId}`)}><i className="fa-regular fa-clone all_icons_color me-2" />Clone</button></li>
                                                        <li><button className="dropdown-item"
                                                            onClick={() => handleOpenModal("archive", row)}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus ? "Archive" : "Unarchive"} Vaccination Form`}</button>
                                                        </li>
                                                        <li><button className="dropdown-item"
                                                            onClick={() => handleOpenModal("public", row)}><TbSwitchHorizontal className="all_react_icons_color me-2" />{`${!row?.publishStatus ? " Publish" : "Draft"} Vaccination Form`}</button>
                                                        </li>
                                                    </ul>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <nav className=" w-100  " aria-label="pagination">
                        {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                    </nav>
                </div>
            </div>
            <Modal show={openModal?.archive} onHide={() => handleClosedModal("archive")} centered>
                <Modal.Body>
                    <div className="modal-body text-center">
                        <BiArchiveIn className="all_react_icons_color " />
                        <p className="mb-0">Are you sure?</p>
                        <p>{openModal?.data?.activeStatus ? dailogAllMessage?.vaccinationForm?.Archive : dailogAllMessage?.vaccinationForm?.UnArchive}</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES", openModal?.data)} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.public} onHide={() => handleClosedModal("public")} centered>
                <Modal.Body>
                    <div className="modal-body text-center">
                        <BiArchiveIn className="all_react_icons_color " />
                        <p className="mb-0">Are you sure? </p>
                        <p>{!openModal?.data?.publishStatus ? dailogAllMessage?.PublicVaccinationForm?.public : dailogAllMessage?.PublicVaccinationForm?.draft}</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handlePublicForm("YES", openModal?.data)} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handlePublicForm("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default VaccinationCalendar
