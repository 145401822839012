/* eslint-disable eqeqeq */
import React from 'react';
import { Stepper, Step, StepLabel, Box, TableContainer, TableBody, TableCell, TableRow, Table, Fab, Tooltip, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Loading from '../../Component/Loading';
import useCheckOut from './Hooks/useCheckOut';
import useTableSorting from '../../Hooks/useTableSorting';
import { SearchAbleSelectBox } from '../../Component/Inputs';
import { AddAllergy, AddChronicDisease, AddSymptoms, AddTest } from './Component/CheckOutModal';
import StepButton from '@mui/material/StepButton';
import { FaArrowLeft } from 'react-icons/fa';
import { Dosage } from '../../Utils/Utils';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';

const CheckOut = () => {
    const {
        handleBack,
        handleNext,
        handleStep,
        ColorlibStepIcon,
        navigate,
        handleChangeLifeStyle,
        LifeStyleTimeFrame,
        openModalFun,
        setModal,
        saveAllData,
        handalSearchAbleSelectBox,
        setEPrescriptionViewModal,
        userdata,
        EPrescriptionViewModal,
        CustomButton,
        loader,
        activeStep,
        addVitals,
        LifeStyleList,
        AddAllergies,
        allergiesList,
        addSymptoms,
        symptonData,
        addChronicDisease,
        chronicData,
        listSelectedMedicines,
        addTestsRequested,
        testRequestData,
        modal,
        newAddChronicDisease,
        addDiagnosis,
        minimumdate,
        addFollowUpDate,
        newAddSymptoms,
        newAddTestsRequested,
        newAddallergies,
        headCells,
        completed,
        ColorlibConnector,
        steps,
        isFrontDeskPermission,
    } = useCheckOut()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return <>
        {loader && <Loading />}
        <div className="common-component-box">
            <header className="body_header" >
                <div className="d-flex justify-content-start align-items-center">
                    <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(-1) }} />
                    <h2 className='page_name'>Checkout Patient </h2>
                </div>
            </header>
            <div className="common-white-bg-box mx-0 shadow px-2">
                <Stack sx={{ width: '100%', height: "100%" }} className=' py-3 px-2 d-flex  justify-content-between' >
                    <div>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton onClick={handleStep(index)}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        <div className="row">
                            {
                                activeStep === 0 && <div className="col-12">
                                    <div className="row mt-3">
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">BP(SYS/DIA)</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="text" className="form-control" placeholder='SYS/DIA' name='bp' onInput={(e) => {
                                                                let maxLength = 6;
                                                                e.target.maxLength = maxLength;
                                                                if (e.target.value.length > maxLength) {
                                                                    e.target.value = e.target.value.slice(0, maxLength);
                                                                }
                                                            }}
                                                                value={addVitals?.values?.bp} onChange={(e) => addVitals?.setFieldValue("bp", e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Pulse(BPM)</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="number" maxLength={3} className="form-control" placeholder='enter pulse' name='pulse'
                                                                value={addVitals?.values?.pulse} onChange={(e) => addVitals?.setFieldValue("pulse", e.target.value)}
                                                                onInput={(e) => {
                                                                    let maxLength = e.target.value.includes('.') ? 5 : 3;
                                                                    e.target.maxLength = maxLength;
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Weight(KG)</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="number" className="form-control" placeholder='weight in kg' name='weight'
                                                                value={addVitals?.values?.weight} onChange={(e) => addVitals?.setFieldValue("weight", e.target.value)}
                                                                onInput={(e) => {
                                                                    let maxLength = e.target.value.includes('.') ? 5 : 3;
                                                                    e.target.maxLength = maxLength;
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Height(CM)</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="number" className="form-control" placeholder='height in cm' name='height'
                                                                value={addVitals?.values?.height} onChange={(e) => addVitals?.setFieldValue("height", e.target.value)}
                                                                onInput={(e) => {
                                                                    let maxLength = e.target.value.includes('.') ? 5 : 3;
                                                                    e.target.maxLength = maxLength;
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Body Temperature(°F)</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <input type="number" className="form-control" placeholder=' body temperature in °F' name='temperature'
                                                                value={addVitals?.values?.temperature} onChange={(e) => addVitals?.setFieldValue("temperature", e.target.value)}
                                                                onInput={(e) => {
                                                                    let maxLength = e.target.value.includes('.') ? 5 : 3;
                                                                    e.target.maxLength = maxLength;
                                                                    if (e.target.value.length > maxLength) {
                                                                        e.target.value = e.target.value.slice(0, maxLength);
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeStep === 1 &&
                                <div className="col-12">
                                    <div className="row mt-3">
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            {/* <div className="row">
                                                <div className="col-3">
                                                    <label className="form-label mb-0">Life Style Habit</label>
                                                </div>
                                                <div className="col-9">
                                                    <div className="row">
                                                        {
                                                            LifeStyleList?.map((cur, index) => {
                                                                return <>
                                                                    <div className="col-sm-12 p-2">
                                                                        <div className="col shadow-sm p-3">
                                                                            <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                <label className="form-label mb-0" style={{ marginRight: "1em", color: "#000" }}>
                                                                                    {cur?.lifeStyleName}
                                                                                </label>
                                                                                <div>
                                                                                    <div className="form-check form-check-inline">
                                                                                        <input className="form-check-input" type="radio" name={`habit_${cur?.lifeStyleId}`} id={`_inlineRadio${cur?.lifeStyleId}`} value={true}
                                                                                            checked={cur?.[`habit_${cur?.lifeStyleId}`] === true}
                                                                                            onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`habit_${cur?.lifeStyleId}`], cur)}
                                                                                        />
                                                                                        <label className="form-check-label mb-0" htmlFor={`_inlineRadio${cur?.lifeStyleId}`}>Yes</label>
                                                                                    </div>
                                                                                    <div className="form-check form-check-inline">
                                                                                        <input className="form-check-input" type="radio" name={`habit_${cur?.lifeStyleId}`} id={`_inlineRadio1${cur?.lifeStyleId}`} value={false}
                                                                                            checked={cur?.[`habit_${cur?.lifeStyleId}`] === false}
                                                                                            onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`habit_${cur?.lifeStyleId}`], cur)}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={`_inlineRadio1${cur?.lifeStyleId}`}>No</label>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            {cur?.[`habit_${cur?.lifeStyleId}`] == true &&
                                                                                <>
                                                                                    <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> Consumption </label>

                                                                                    <div className="row mx-0 px-0 ">
                                                                                        <div className="col-3 px-0">
                                                                                            {
                                                                                                <input type="number" maxLength={2} className='form-control text-center text-dark' name='consumption'
                                                                                                    onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                                                                    onChange={(e) => handleChangeLifeStyle(e, index)}
                                                                                                    value={cur?.consumption}
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-xxl-6  col-md-10 col-lg-9 col-sm-9">
                                                                                            <ul className="nav mb-0 nav-pills px-0 d-flex justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                    <button style={{ padding: "6px" }} className="tab-btn-color px-3 active w-100" id="pills-BeforeFood-tab" data-bs-toggle="pill"
                                                                                                        data-bs-target="#pills-BeforeFood" type="button" role="tab" aria-controls="pills-BeforeFood"
                                                                                                        aria-selected="true" onClick={() => LifeStyleTimeFrame("days", index)}  >Days</button>
                                                                                                </li>
                                                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                    <button style={{ padding: "6px" }} className="tab-btn-color px-3 w-100 " id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                                                        data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                                                        aria-selected="false"
                                                                                                        onClick={() => LifeStyleTimeFrame("weeks", index)}
                                                                                                    >Weeks</button>
                                                                                                </li>
                                                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                    <button style={{ padding: "6px" }} className="tab-btn-color px-3 w-100 " id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                                                        data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                                                        aria-selected="false"
                                                                                                        onClick={() => LifeStyleTimeFrame("months", index)}
                                                                                                    >Months</button>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {
                                                                                cur?.[`habit_${cur?.lifeStyleId}`] == false && <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                    <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> Past {cur?.lifeStyleName} Habit</label>
                                                                                    <div className=''>
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input className="form-check-input" type="radio" name={`pastHabit_${cur?.lifeStyleId}`} id={`inlineRadio${cur?.lifeStyleId}`} value={true}
                                                                                                checked={cur?.[`pastHabit_${cur?.lifeStyleId}`] === true}
                                                                                                onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`pastHabit_${cur?.lifeStyleId}`], cur)}
                                                                                            />
                                                                                            <label className="form-check-label" htmlFor={`inlineRadio${cur?.lifeStyleId}`}>Yes</label>
                                                                                        </div>
                                                                                        <div className="form-check form-check-inline">
                                                                                            <input className="form-check-input" type="radio" name={`pastHabit_${cur?.lifeStyleId}`} id={`inlineRadio1${cur?.lifeStyleId}`} value={false}
                                                                                                checked={cur?.[`pastHabit_${cur?.lifeStyleId}`] === false}
                                                                                                onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`pastHabit_${cur?.lifeStyleId}`], cur)}
                                                                                            />
                                                                                            <label className="form-check-label" htmlFor={`inlineRadio1${cur?.lifeStyleId}`}>No</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                cur?.[`pastHabit_${cur?.lifeStyleId}`] === true && <>
                                                                                    <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> {cur?.lifeStyleName} Record</label>
                                                                                    <textarea rows="2" className="form-control"
                                                                                        value={cur?.[`${cur?.record}`]}
                                                                                        name={`record`}
                                                                                        onChange={(e) => handleChangeLifeStyle(e, index)}
                                                                                    />
                                                                                </>
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div> */}



                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Life Style Habit</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="row">
                                                                {
                                                                    LifeStyleList?.map((cur, index) => {
                                                                        return <>
                                                                            <div className="col-sm-12 p-2">
                                                                                <div className="col shadow-sm p-3">
                                                                                    <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                        <label className="form-label mb-0" style={{ marginRight: "1em", color: "#000" }}>
                                                                                            {cur?.lifeStyleName}
                                                                                        </label>
                                                                                        <div>
                                                                                            <div className="form-check form-check-inline">
                                                                                                <input className="form-check-input" type="radio" name={`habit_${cur?.lifeStyleId}`} id={`_inlineRadio${cur?.lifeStyleId}`} value={true}
                                                                                                    checked={cur?.[`habit_${cur?.lifeStyleId}`] === true}
                                                                                                    onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`habit_${cur?.lifeStyleId}`], cur)}
                                                                                                />
                                                                                                <label className="form-check-label mb-0" htmlFor={`_inlineRadio${cur?.lifeStyleId}`}>Yes</label>
                                                                                            </div>
                                                                                            <div className="form-check form-check-inline">
                                                                                                <input className="form-check-input" type="radio" name={`habit_${cur?.lifeStyleId}`} id={`_inlineRadio1${cur?.lifeStyleId}`} value={false}
                                                                                                    checked={cur?.[`habit_${cur?.lifeStyleId}`] === false}
                                                                                                    onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`habit_${cur?.lifeStyleId}`], cur)}
                                                                                                />
                                                                                                <label className="form-check-label" htmlFor={`_inlineRadio1${cur?.lifeStyleId}`}>No</label>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                    {cur?.[`habit_${cur?.lifeStyleId}`] == true &&
                                                                                        <>
                                                                                            <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> Consumption </label>

                                                                                            <div className="row">
                                                                                                <div className="col-3">
                                                                                                    {
                                                                                                        <input type="number" maxLength={2} className='form-control text-center text-dark' name='consumption'
                                                                                                            onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                                                                            onChange={(e) => handleChangeLifeStyle(e, index)}
                                                                                                            value={cur?.consumption}
                                                                                                        />
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col-9">
                                                                                                    <ul className="nav mb-0 nav-pills justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                            <button style={{ padding: "6px" }} className={`tab-btn-color px-3  w-100 ${cur?.TimeFrame === "days" ? "active" : ""}`} id="pills-BeforeFood-tab" data-bs-toggle="pill"
                                                                                                                data-bs-target="#pills-BeforeFood" type="button" role="tab" aria-controls="pills-BeforeFood"
                                                                                                                aria-selected="true" onClick={() => LifeStyleTimeFrame("days", index)}  >Days</button>
                                                                                                        </li>
                                                                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                            <button style={{ padding: "6px" }} className={`tab-btn-color px-3  w-100 ${cur?.TimeFrame === "weeks" ? "active" : ""}`} id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                                                                data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                                                                aria-selected="false"
                                                                                                                onClick={() => LifeStyleTimeFrame("weeks", index)}
                                                                                                            >Weeks</button>
                                                                                                        </li>
                                                                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                                                            <button style={{ padding: "6px" }} className={`tab-btn-color px-3  w-100 ${cur?.TimeFrame === "months" ? "active" : ""}`} id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                                                                data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                                                                aria-selected="false"
                                                                                                                onClick={() => LifeStyleTimeFrame("months", index)}
                                                                                                            >Months</button>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        cur?.[`habit_${cur?.lifeStyleId}`] == false && <div className='w-100 d-flex justify-content-between align-items-center'>
                                                                                            <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> Past {cur?.lifeStyleName} Habit</label>
                                                                                            <div className=''>
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <input className="form-check-input" type="radio" name={`pastHabit_${cur?.lifeStyleId}`} id={`inlineRadio${cur?.lifeStyleId}`} value={true}
                                                                                                        checked={cur?.[`pastHabit_${cur?.lifeStyleId}`] === true}
                                                                                                        onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`pastHabit_${cur?.lifeStyleId}`], cur)}
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`inlineRadio${cur?.lifeStyleId}`}>Yes</label>
                                                                                                </div>
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <input className="form-check-input" type="radio" name={`pastHabit_${cur?.lifeStyleId}`} id={`inlineRadio1${cur?.lifeStyleId}`} value={false}
                                                                                                        checked={cur?.[`pastHabit_${cur?.lifeStyleId}`] === false}
                                                                                                        onChange={(e) => handleChangeLifeStyle(e, index, !cur?.[`pastHabit_${cur?.lifeStyleId}`], cur)}
                                                                                                    />
                                                                                                    <label className="form-check-label" htmlFor={`inlineRadio1${cur?.lifeStyleId}`}>No</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        cur?.[`pastHabit_${cur?.lifeStyleId}`] === true && <>
                                                                                            <label className="form-label mb-0 mt-3" style={{ marginRight: "1em", color: "#000" }}> {cur?.lifeStyleName} Record</label>
                                                                                            <textarea rows="2" className="form-control"
                                                                                                value={cur?.record}
                                                                                                name={`record`}
                                                                                                onChange={(e) => handleChangeLifeStyle(e, index)}
                                                                                            />
                                                                                        </>
                                                                                    }

                                                                                </div>

                                                                            </div>
                                                                        </>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Allergies</label>
                                                        </div>
                                                        <div className="col-lg-7 d-flex">
                                                            <div className="col-lg-11 w-100">
                                                                <SearchAbleSelectBox
                                                                    multiple={true}
                                                                    option={allergiesList}
                                                                    getOptionLabel="name"
                                                                    isOptionEqualToValue="id"
                                                                    value={AddAllergies?.values?.Allergies}
                                                                    name="Allergies"
                                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                                />
                                                            </div>
                                                            <div className="col-lg-1 text-center px-4">

                                                                {
                                                                    !isFrontDeskPermission && <Tooltip title=" Add Symptoms" placement="right" style={{ cursor: "pointer" }}>
                                                                        <Fab color="primary" aria-label="add" size="small" className='mt-1' style={{ background: "#38C4B9", width: "27px", height: "27px", minHeight: "0" }}
                                                                            onClick={() => openModalFun("Allergies")}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Tooltip>
                                                                }

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Symptoms</label>
                                                        </div>
                                                        <div className="col-lg-7 d-flex">
                                                            <div className="col-lg-11 w-100">
                                                                <SearchAbleSelectBox
                                                                    multiple={true}
                                                                    option={symptonData}
                                                                    getOptionLabel="name"
                                                                    isOptionEqualToValue="id"
                                                                    value={addSymptoms?.values?.symptoms}
                                                                    name="symptoms"
                                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                                />
                                                            </div>
                                                            <div className="col-lg-1 text-center px-4">
                                                                {
                                                                    !isFrontDeskPermission && <Tooltip title=" Add Symptoms" placement="right" style={{ cursor: "pointer" }}>
                                                                        <Fab color="primary" aria-label="add" size="small" className='mt-1  ' style={{ background: "#38C4B9", width: "27px", height: "27px", minHeight: "0" }}
                                                                            onClick={() => openModalFun("Symptoms")}>
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Tooltip>
                                                                }

                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-2">
                                                        
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0">Chronic Disease</label>
                                                        </div>
                                                        <div className="col-lg-7 d-flex">
                                                            <div className="col-lg-11 w-100">
                                                                <SearchAbleSelectBox
                                                                    multiple={true}
                                                                    option={chronicData}
                                                                    getOptionLabel="name"
                                                                    isOptionEqualToValue="id"
                                                                    value={addChronicDisease?.values?.chronicDisease}
                                                                    name="chronicDisease"
                                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                                />
                                                            </div>
                                                            <div className="col-lg-1 text-center px-4">
                                                                {
                                                                    !isFrontDeskPermission && <Tooltip title=" Add Chronic Disease" placement="right" style={{ cursor: "pointer" }}>
                                                                        <Fab color="primary" aria-label="add" size="small" className='mt-1' style={{ background: "#38C4B9", width: "27px", height: "27px", minHeight: "0" }}
                                                                            onClick={() => openModalFun("ChronicDisease")}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Tooltip>
                                                                }

                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-2">
                                                        
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label mb-0"> Test Requested</label>
                                                        </div>
                                                        <div className="col-lg-7 d-flex">
                                                            <div className="col-lg-11 w-100">
                                                                <SearchAbleSelectBox
                                                                    multiple={true}
                                                                    option={testRequestData}
                                                                    getOptionLabel="name"
                                                                    isOptionEqualToValue="id"
                                                                    value={addTestsRequested?.values?.tests}
                                                                    name="tests"
                                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                                />
                                                            </div>
                                                            <div className="col-lg-1 text-center px-4">


                                                                {
                                                                    !isFrontDeskPermission && <Tooltip title=" Add Test" placement="right" style={{ cursor: "pointer" }}>
                                                                        <Fab color="primary" aria-label="add" size="small" className='mt-1' style={{ background: "#38C4B9", width: "27px", height: "27px", minHeight: "0" }}
                                                                            onClick={() => openModalFun("Tests")}
                                                                        >
                                                                            <AddIcon />
                                                                        </Fab>
                                                                    </Tooltip>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-lg-2">
                                                        
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                activeStep === 2 && <div className="col-12">
                                    <div className="row mt-3">
                                        <div className="col-12">
                                            <TableContainer>
                                                {listSelectedMedicines.length === 0 &&
                                                    <div className="col-sm-12 mt-3 text-center " ><h3 style={{ color: "gray", fontSize: "20px" }}>No record found  </h3></div>
                                                }
                                                <Table
                                                    id="table_mui"
                                                    aria-labelledby="tableTitle"
                                                    size={"medium"}
                                                    aria-label="enhanced table"
                                                >
                                                    {listSelectedMedicines.length !== 0 && <>
                                                        <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={listSelectedMedicines.length} />
                                                    </>}
                                                    <TableBody className="table_overflow_scroll">
                                                        {stableSort(listSelectedMedicines, getComparator(order, orderBy)).map((item, index) => {
                                                            const labelId = `enhanced-table-checkbox-${index}`;
                                                            return <React.Fragment key={index}><TableRow id="hover_tr">
                                                                {/* <TableCell id={labelId} align="left">{item?.patientFirstName + " " + item?.patientLastName}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.age}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.gender}</TableCell> */}
                                                                <TableCell id={labelId} align="left">{item?.prescriptionNumber}</TableCell>
                                                                <TableCell id={labelId} align="left">{moment(item?.startDate).format("DD/MM/YYYY")}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.medicineName}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.medicineType}</TableCell>
                                                                <TableCell id={labelId} align="left">{Dosage(item)}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.doseAmount + item?.doseType}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</TableCell>
                                                                <TableCell id={labelId} align="left">{item?.medicineIntakeAmount}</TableCell>
                                                                <TableCell id={labelId} align="left">
                                                                    <ul className="actionul w-100 d-flex justify-content-end">
                                                                        <li className="" onClick={() => setEPrescriptionViewModal({ open: true, data: item })}>
                                                                            <Tooltip title="View " placement="top" style={{ cursor: "pointer" }}>
                                                                                <div>
                                                                                    <i className="fa-solid fa-eye me-2 all_icons_color"></i>
                                                                                </div>
                                                                            </Tooltip>
                                                                        </li>
                                                                    </ul>
                                                                </TableCell>
                                                            </TableRow>
                                                            </React.Fragment>
                                                        }
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeStep === 3 && <div className="col-12">
                                <div className="row mt-3">
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Diagnosis</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <textarea rows="3" value={addDiagnosis?.values?.diagnosis}
                                                            onChange={(e) => addDiagnosis?.setFieldValue("diagnosis", e.target.value)}
                                                            className="form-control position-relative" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">  Follow Up Date</label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <input type="date" min={minimumdate} className="form-control position-relative" value={addFollowUpDate?.values?.dateTime} onChange={(e) => addFollowUpDate.setFieldValue("dateTime", e.target.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 2 }} >
                        <Box sx={{ flex: '1 1 auto' }} />
                        <CustomButton disabled={activeStep === 0} variant='contained' className='me-3' onClick={handleBack}>
                            Back
                        </CustomButton>
                        {activeStep !== steps.length &&
                            <CustomButton variant='contained' onClick={() => {
                                if (activeStep === 3) {
                                    saveAllData()
                                } else {
                                    handleNext()
                                }
                            }}>
                                {activeStep === 3 ? 'Submit' : 'Next'}
                            </CustomButton>
                        }
                    </Box>
                </Stack>
            </div >
        </div >
        <AddSymptoms modal={modal} setModal={setModal} newAddSymptoms={newAddSymptoms} CustomButton={CustomButton} />
        <AddChronicDisease modal={modal} setModal={setModal} newAddChronicDisease={newAddChronicDisease} CustomButton={CustomButton} />
        <AddTest modal={modal} setModal={setModal} newAddTestsRequested={newAddTestsRequested} CustomButton={CustomButton} />
        <AddAllergy modal={modal} setModal={setModal} newAddallergies={newAddallergies} CustomButton={CustomButton} />
        <Modal show={EPrescriptionViewModal?.open} onHide={() => setEPrescriptionViewModal({ open: false, data: null })} centered>
            <Modal.Body>
                <div className="modal-body viewdetail">
                    <div className="text-center">
                        <h4 className="modal-title mb-3" >E-prescription Summary</h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <label className="form-label mb-0">E-prescription Number</label>
                            <p>{EPrescriptionViewModal?.data?.prescriptionNumber}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Date</label>
                            <p>{moment(EPrescriptionViewModal?.data?.startDate).tz(userdata?.timezoneName).format('ll')}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Medicine</label>
                            <p>{EPrescriptionViewModal?.data?.medicineName}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Medicine type</label>
                            <p>{EPrescriptionViewModal?.data?.medicineType}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Standard</label>
                            <p>{Dosage(EPrescriptionViewModal?.data)}</p>
                        </div>

                        <div className="col-sm-6">
                            <label className="form-label mb-0">Strength</label>
                            <p>{EPrescriptionViewModal?.data?.doseAmount + EPrescriptionViewModal?.data?.doseType}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Duration</label>
                            <p>{EPrescriptionViewModal?.data?.medicineIntakeDurationAmount + " " + EPrescriptionViewModal?.data?.medicineIntakeDurationType}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Dosage Instructions</label>
                            <p>{EPrescriptionViewModal?.data?.medicineIntakeTime !== "CUSTOM" ? EPrescriptionViewModal?.data?.medicineIntakeTime : EPrescriptionViewModal?.data?.medicineIntakeCustomTime}</p>
                        </div>
                        <div className="col-sm-6">
                            <label className="form-label mb-0">Quantity</label>
                            <p>{EPrescriptionViewModal?.data?.medicineIntakeAmount}</p>
                        </div>
                        <div className="col-sm-12">
                            <label className="form-label mb-0">Notes</label>
                            <p>{EPrescriptionViewModal?.data?.notes?EPrescriptionViewModal?.data?.notes:"N/A"}</p>
                        </div>
                    </div>
                </div>

            </Modal.Body>
        </Modal>
    </>;
};

export default CheckOut;

