import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { listproviderclinics } from '../../Context/api';
import { axiosInstance } from '../../Context/CraeteAxios';
import { toast } from 'react-toastify';
import { commonMessages } from '../../Context/constants';
import useReduxData from '../../Hooks/useReduxData';
const Feedback = () => {
    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [ratingSort, setRatingSort] = useState(1)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    // const [selectClinic, setSelectClinic] = useState('ALL_CLINCI')
    const [clinicList, setClinicList] = useState([])
    // const [loader, setloader] = useState(false)
    // get list clinic 
    const getListproviderclinics = () => {
        const data = {
            userId: userdata.userId,
            roleTitle: userdata.roleTitle
        }
        // setloader(true)
        axiosInstance.post(listproviderclinics(), data).then((res) => {
            if (res.data.status === "200") {
                setClinicList(res.data.data)
            } else if (res.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            } else {

            }
            // setloader(false)
        }).catch(() => {
            // setloader(false)
            toast.error(commonMessages.NetworkError)
        })
    }

    useEffect(() => {
        getListproviderclinics()
        window.addEventListener("click", () => {
            setDateRangeDropdown(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        <div className="my-3">
            <div className="row ">
                <div className="col-12 px-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="Appoinment_date_range col-xxl-2 col-xl-3 col-lg-4 col-md-5 ">
                            <input onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} type="text" />
                            <span><BiChevronDown style={{ fontSize: "27px" }} onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} /></span>
                            {
                                dateRangeDropdown && <div className="Appoinment_date_range_box" onClick={(event) => event.stopPropagation()}>
                                    <ul className="w-100  px-3 py-0 pt-3" >
                                        <li className="" onClick={() => { setDateRangeDropdown(false) }} >
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1"
                                            />
                                            <label className="form-check-label ps-2" htmlFor="exampleRadios2" >
                                                Top Reviews
                                            </label>
                                        </li>
                                        <li onClick={() => { setDateRangeDropdown(false) }}>
                                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option1"
                                            />
                                            <label className="form-check-label ps-2" htmlFor="exampleRadios3" >
                                                Critical Reviews
                                            </label>
                                        </li>
                                    </ul>
                                    <div className='px-2'>
                                        <hr className='my-0' />
                                        <p className='mb-2 text-center ' style={{ color: "gray", fontSize: "15px" }} >Sort by Star rating</p>
                                        <ul className='rating_sort_button'>
                                            <li className={`${ratingSort === 1 && "active"}`} onClick={() => setRatingSort(1)}>1</li>
                                            <li className={`${ratingSort === 2 && "active"}`} onClick={() => setRatingSort(2)}>2</li>
                                            <li className={`${ratingSort === 3 && "active"}`} onClick={() => setRatingSort(3)}>3</li>
                                            <li className={`${ratingSort === 4 && "active"}`} onClick={() => setRatingSort(4)}>4</li>
                                            <li className={`${ratingSort === 5 && "active"}`} onClick={() => setRatingSort(5)}>5</li>
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-xxl-2 col-xl-3 col-lg-4 col-md-5">
                            <select className="form-select  "
                            //  onChange={e => { setSelectClinic(e.target.value); }}
                              >
                                <option value="ALL_CLINCI">All</option>
                                {
                                    clinicList?.map((cur, index) => {
                                        return <option value={cur?.clinicId}>{cur?.clinicName}</option>
                                    })
                                }
                            </select>
                        </div>
                    </div>

                </div>
                <div className="col-6 mt-3">
                    <div className="row  mb-3">
                        <div className="col-12 ">
                            <div className="row feedback_card">
                                <div className="col-sm-4 px-0">
                                    <img alt='' src={require("../../Assets/Images/1.jpg")} />
                                </div>
                                <div className="col-sm-8 ">
                                    <h5 className="active title">Cleaning so good</h5>
                                    <p className='discription'>Loremipsumdo lorsitamet,consecteturad Lorem ipsumdo lorsitamet,consecteturad...</p>
                                    <p className='name'>Vikey</p>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <Rating
                                            name="text-feedback"
                                            size='small'
                                            value={2}
                                            readOnly
                                            precision={0.5}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />
                                        <small className='date'>05-05-2022</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row  mb-3 ">
                        <div className="col-12 ">
                            <div className="row feedback_card">
                                <div className="col-sm-4 px-0">
                                    <img alt='' src={require("../../Assets/Images/1.jpg")} />
                                </div>
                                <div className="col-sm-8 ">
                                    <h5 className=" title">Cleaning so good</h5>
                                    <p className='discription'>Loremipsumdo lorsitamet,consecteturad Lorem ipsumdo lorsitamet,consecteturad...</p>
                                    <p className='name'>Vikey</p>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <Rating
                                            size='small'
                                            name="text-feedback"
                                            value={2}
                                            readOnly
                                            precision={0.5}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />
                                        <small className='date'>05-05-2022</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row  mb-3">
                        <div className="col-12 ">
                            <div className="row feedback_card">
                                <div className="col-sm-4 px-0">
                                    <img alt='' src={require("../../Assets/Images/1.jpg")} />
                                </div>
                                <div className="col-sm-8 ">
                                    <h5 className=" title">Cleaning so good</h5>
                                    <p className='discription'>Loremipsumdo lorsitamet,consecteturad Lorem ipsumdo lorsitamet,consecteturad...</p>
                                    <p className='name'>Vikey</p>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <Rating
                                            name="text-feedback"
                                            size='small'
                                            value={2}
                                            readOnly
                                            precision={0.5}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />
                                        <small className='date'>05-05-2022</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row  mb-3">
                        <div className="col-12 ">
                            <div className="row feedback_card">
                                <div className="col-sm-4 px-0">
                                    <img alt='' src={require("../../Assets/Images/1.jpg")} />
                                </div>
                                <div className="col-sm-8 ">
                                    <h5 className=" title">Cleaning so good</h5>
                                    <p className='discription'>Loremipsumdo lorsitamet,consecteturad Lorem ipsumdo lorsitamet,consecteturad...</p>
                                    <p className='name'>Vikey</p>

                                    <div className="d-flex justify-content-between align-items-center">
                                        <Rating
                                            name="text-feedback"
                                            value={2}
                                            size='small'
                                            readOnly
                                            precision={0.5}
                                            emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                        />
                                        <small className='date'>05-05-2022</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="col-6 ps-5 pt-3">
                    <div className="row feedback_details">
                        <div className="col-12">
                            <img alt='' src={require("../../Assets/Images/1.jpg")} />
                        </div>
                        <div className="col-12 text-center py-2">
                            <h5 className='title'>Cleaning so good</h5>
                        </div>
                        <div className="col-12">
                            <p className='discription'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                        <div className="col-12 d-flex  justify-content-between mt-3"  >
                            <Rating
                                name="text-feedback"
                                value={2}
                                size='small'
                                readOnly
                                precision={0.5}
                                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                            />
                            <ul className='ps-0'>
                                <li>Vikey</li>
                                <li><small className='date'>05-05-2022</small></li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>;
};

export default Feedback;
