import { insertservices, insertSpecialization, listservices, Listspecialization, updateservicedetails, UpdateSpecialization } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

////////////////////////////// SPECIALIZATION /////////////////////////////////

export const INSERT_SPECIALIZATION = async (body) => {
    try {
        let response = await axiosInstance.post(insertSpecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const SPECIALIZATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(Listspecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const UPDATE_SPECIALIZATION = async (body) => {
    try {
        let response = await axiosInstance.post(UpdateSpecialization(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

////////////////////////////// SERVICES /////////////////////////////////

export const INSERT_SERVICE = async (body) => {
    try {
        let response = await axiosInstance.post(insertservices(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const SERVICE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listservices(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_SERVICE = async (body) => {
    try {
        let response = await axiosInstance.post(updateservicedetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}