import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Modal } from "react-bootstrap";
import Man from '../../Assets/Images/dummyimg.png';
import { Button, ButtonGroup, IconButton, Radio, TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import Loading from "../../Component/Loading";
import useTableSorting from "../../Hooks/useTableSorting";
import usePatients from "./Hooks/usePatients";
import { PatientImageFilePath } from "../../Context/api";
import { NoRecordFound } from "../../Component/NoRecordFound";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { FiRefreshCcw } from "react-icons/fi";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IoMdAdd } from "react-icons/io";
import viewIcon from "../../Assets/Icons/MicrosoftTeams-image (2).png"
import { SelectBox } from '../../Component/Inputs';
import { RoleTitle, StaffPersonalOption } from "../../Context/constants";
const Patients = () => {
  const {
    headCells,
    loader,
    controller,
    paginationTotalCount,
    listpatients,
    searchvalue,
    OpenModal,
    disable,
    margePatientList,
    mergeAccountId,
    userdata,
    setMergeAccountId,
    navigate,
    setController,
    handlSearchbox,
    search,
    reset,
    handleOpenModal,
    handleClosedModal, mergeAccountType, setMergeAccountType,
    handleDeletePatient, MerageCells, handleMergeAccount, handleChooseDuplicate
  } = usePatients()
  const { CustomButton } = useMuiCompontent()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  const {
    EnhancedTableHead: MergeEnhancedTableHead,
    order: Mergeorder,
    orderBy: MergeorderBy,
    stableSort: MergestableSort,
    getComparator: MergegetComparator,
    handleRequestSort: MergehandleRequestSort
  } = useTableSorting(MerageCells)
  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Patients</h2>
          </div>
        </header>
        <div className="common-white-bg-box mx-0 shadow px-2 py-2 d-flex justify-content-between flex-column">
          <div>
            <div className="w-100 d-flex justify-content-between">
              <ul className="headerlist px-0">
                <li>
                  <input type="text" onChange={handlSearchbox} value={searchvalue.patient} placeholder="Search by patient name" className="form-control filter_input" name="patient" />
                </li>
                {
                  userdata.roleTitle === RoleTitle.STAFF && <li className="ms-3" style={{ width: 200 }}><SelectBox
                    placeholder="Select"
                    value={searchvalue?.personal}
                    name="personal"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={handlSearchbox}
                    option={StaffPersonalOption}
                  />
                  </li>
                }
                <li>
                  <CustomButton variant="contained" className="ms-3" disabled={disable} onClick={search} >search </CustomButton>
                </li>
                <li className="mx-3 d-flex justify-content-center align-items-center" >
                  <IconButton onClick={reset} size="small" aria-label="delete" disabled={disable} style={{ color: "#000", cursor: "pointer" }}>
                    <FiRefreshCcw />
                  </IconButton>
                </li>
                <li>
                  <CustomButton variant="contained" startIcon={<IoMdAdd />} onClick={() => navigate("/patients/add")} style={{ background: "#38C4B9", padding: "5px 8px", fontSize: "13px", width: "125px" }}> Add Patient</CustomButton>
                </li>
              </ul>
              <div className="web_hide">
                <div className="dropdown-center ms-2">
                  <CustomButton variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<ExitToAppIcon />} >export</CustomButton>
                </div>
              </div>
            </div>
            <TableContainer className="">
              {listpatients?.length === 0 && <div className="col-sm-12 mt-3 text-center " >  <NoRecordFound /> </div>}
              <Table
                id="table_mui"
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                {
                  listpatients.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={listpatients.length} />
                }
                <TableBody>
                  {stableSort(listpatients, getComparator(order, orderBy)).map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return <React.Fragment key={index}><TableRow id="hover_tr"  >
                      <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                        <img alt="" src={row.image === null ? Man : PatientImageFilePath + row.image} width={45} height={45} style={{ borderRadius: "50%" }} />
                      </TableCell>
                      <TableCell id={labelId} align="left" >{(row?.firstName ? row?.firstName : "") + "  " + (row?.lastName ? row?.lastName : "")}</TableCell>
                      <TableCell id={labelId} align="left"> {row?.gender}</TableCell>
                      <TableCell id={labelId} align="left">{row?.mobile}</TableCell>
                      <TableCell id={labelId} align="left">{row?.email}</TableCell>
                      <TableCell id={labelId} align="right">
                        <ul className="actionul w-100 d-flex justify-content-end">
                          <li className="">

                            <div>
                              <i title="Account Merge" className="fa-solid fa-code-merge all_icons_color" onClick={() => handleOpenModal("mergeCountPopup1", row)}></i>
                            </div>
                          </li>
                          <li className="px-2">
                            <img src={viewIcon} alt="" className='cursor' onClick={() => navigate(`${row?.id}`, { state: { section: "member" } })} width={18} height={18} title='view' />
                          </li>
                          <li className="">
                            <div>
                              <i title="Delete patient " className="fa-solid fa-trash-can all_icons_color" onClick={() => handleOpenModal("deletePatient", row)}></i>
                            </div>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                    </React.Fragment>
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <nav className="w-100" aria-label="pagination">
            {
              paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
            }
          </nav>
        </div>
      </div>
      <Modal show={OpenModal?.deletePatient} onHide={() => handleClosedModal("deletePatient")} centered>
        <Modal.Body className='px-0'>
          <div className="modal-body text-center cus-i px-0">
            <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
            <p className='mb-0'>Are you sure?</p>
            <p>You want to delete this patient</p>
            <CustomButton variant="contained" className="me-3" onClick={() => handleDeletePatient("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => handleDeletePatient("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={OpenModal?.mergeCountPopup1} onHide={() => handleClosedModal("mergeCountPopup1")} centered>
        <Modal.Body className='px-0 '>
          <div className="modal-body text-center cus-i px-0">
            <h5 className='mb-0'>Account Merge</h5>

          </div>
          <div className="w-100  my-3 px-3">
            {/* <SelectBox
              placeholder="Select Gender"
              value={addMergeAccountType.values.mergeAccountType}
              name="mergeAccountType"
              keys="value"
              label="label"
              menuValeu="value"
              handleChange={(e) => addMergeAccountType.setFieldValue("mergeAccountType", e.target.value)}
              option={MergePatient}
            />
            <Errors formikfun={addMergeAccountType} name={"mergeAccountType"} /> */}
            <ButtonGroup
              className="w-100"
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
            >
              <Button className="w-50" variant={`${mergeAccountType === "DUPLICATE" ? "contained" : " outlined"}`} style={mergeAccountType === "DUPLICATE" ? { background: "#38C4B9", padding: "5px 8px", fontSize: "13px", textTransform: "capitalize" } : { color: "#38C4B9", border: "1px solid #38C4B9", padding: "5px 8px", fontSize: '13px', textTransform: "capitalize" }} onClick={() => setMergeAccountType("DUPLICATE")}>Merge As Duplicate</Button>
              <Button className="w-50" variant={`${mergeAccountType === "FAMILY" ? "contained" : " outlined"}`} style={mergeAccountType === "FAMILY" ? { background: "#38C4B9", padding: "5px 8px", fontSize: "13px", textTransform: "capitalize" } : { color: "#38C4B9", border: "1px solid #38C4B9", padding: "5px 8px", fontSize: '13px', textTransform: "capitalize" }} onClick={() => setMergeAccountType("FAMILY")} > Merge As Family Member</Button>
            </ButtonGroup>
          </div>
          <div className="w-100 text-end px-3">
            <CustomButton variant="contained" onClick={() => handleChooseDuplicate("YES")} >Continue</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={OpenModal?.mergeCount} onHide={() => handleClosedModal("mergeCount")} centered>
        <Modal.Body className='px-0 pb-0'>
          <div className="modal-body  cus-i px-0">
            <h3 className="modal-title mb-0 text-center ">Account Merge</h3>
            <div className=" MERGE_ACCOUNT">
              <div className="col-12 d-flex ">
                <TableContainer style={{ overflowX: "auto" }}>
                  <Table
                    id="table_mui"
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    {
                      margePatientList?.length !== 0 && <MergeEnhancedTableHead order={Mergeorder} orderBy={MergeorderBy} onRequestSort={MergehandleRequestSort} rowCount={margePatientList?.length} />
                    }
                    <TableBody>
                      {MergestableSort(margePatientList, MergegetComparator(Mergeorder, MergeorderBy)).map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return <React.Fragment key={index}><TableRow id="hover_tr" style={{ background: `${OpenModal?.data?.auth_id === row?.auth_id && "#f1f1f1"}`, cursor: 'pointer' }}
                          onClick={() => {
                            if (OpenModal?.data?.auth_id !== row?.auth_id) {
                              setMergeAccountId(row?.id)
                            }
                          }}
                        >
                          <TableCell id={labelId} scope="row" padding="none" >
                            <Radio
                              disabled={OpenModal?.data?.auth_id === row?.auth_id}
                              checked={row?.id === mergeAccountId}
                              value={row?.id}
                              name="radio-buttons"
                            />
                          </TableCell>
                          <TableCell id={labelId} align="left" >{(row?.firstName ? row?.firstName : "") + "  " + (row?.lastName ? row?.lastName : "")}</TableCell>
                          <TableCell id={labelId} align="left">{row?.gender}</TableCell>
                          <TableCell id={labelId} align="left">{row?.mobile}</TableCell>
                          <TableCell id={labelId} align="left">{row?.email}</TableCell>
                        </TableRow>
                        </React.Fragment>
                      }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="w-100 text-end px-3">
              <CustomButton variant="outlined" className="me-3" onClick={() => handleClosedModal("mergeCount")}  > cancel</CustomButton>
              <CustomButton variant="contained" className="" onClick={handleMergeAccount} disabled={!mergeAccountId}>account merge</CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Patients;
