import React from 'react';
import {

    CartesianGrid,
    Cell,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    AreaChart,
    Area,
    Label
} from 'recharts';
import Loading from '../../Component/Loading';
import Filters from '../../Component/Insights/Filters';
import useBookingDetails from './Hooks/useBookingDetails';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { IoFilterSharp } from 'react-icons/io5';

const BookingDetails = () => {
    const {
        loader,
        dateFilter,
        selectClinic,
        clinicList,
        dateRangeDropdown,
        totalBookedAppointments,
        totalcancelledAppointments,
        totalrescheduledAppointment,
        totalAppointmentCount,
        totalRevenueCount,
        graphData,
        filterPopup,
        customDate,
        totalcheckoutAppointmentCount,
        appointmentFilter,
        setAppointmentFilter,
        setCustomDate,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        setPillsActive,
        setFilterPopup,
        getBookingDetail,
        setAction,
        userdata,
        currencyImage,
    } = useBookingDetails()
    const { CustomButton } = useMuiCompontent()

    const data2 = [
        { name: ' Book Appointment', value: totalBookedAppointments, color: "#0088FE" },
        { name: ' Reschedule Appointment', value: totalrescheduledAppointment, color: "#00C49F" },
        { name: ' Cancel Appointment', value: totalcancelledAppointments, color: "#FFBB28" },
        { name: ' Checkout Appointment', value: totalcheckoutAppointmentCount, color: "#28dfff" },
    ];

    return <>
        {loader && <Loading />}
        <div className=" py-3">
            <div className="row" >
                <div className="col-12  position-relative px-0 ">
                    <div className='w-100 text-end'>
                        <CustomButton onClick={() => setFilterPopup((pre) => !pre)} className='' variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<IoFilterSharp />}  >Filters</CustomButton>
                    </div>
                    <Filters
                        setDateRangeDropdown={setDateRangeDropdown}
                        dateFilter={dateFilter}
                        selectClinic={selectClinic}
                        setSelectClinic={setSelectClinic}
                        clinicList={clinicList}
                        setDateFilter={setDateFilter}
                        setPillsActive={setPillsActive}
                        dateRangeDropdown={dateRangeDropdown}
                        page="BOOKING_DETAILS"
                        filterPopup={filterPopup}
                        setFilterPopup={setFilterPopup}
                        getDetail={getBookingDetail}
                        setAction={setAction}
                        setCustomDate={setCustomDate}
                        customDate={customDate}
                        userdata={userdata}
                        appointmentFilter={appointmentFilter}
                        setAppointmentFilter={setAppointmentFilter}
                    />
                </div>
                <div className="col-8 ps-0">
                    <div className="col shadow-sm p-4">
                        <h1 className="mb-3 " style={{ fontSize: "13px" }}>Appointment Booked</h1>
                        <div style={{ width: '100%', height: 420 }}>
                            <ResponsiveContainer>
                                <AreaChart
                                    data={graphData}
                                    margin={{
                                        top: 10,
                                        right: 30,
                                        left: 0,
                                        bottom: 0,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Area type="monotone" dataKey="count" stroke="#38C4B9" fill="#dcf2f0" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="col-4 pe-0">
                    <div className="col shadow-sm p-4">
                        <h1 className="mb-3 " style={{ fontSize: "13px" }}>Appointment details</h1>
                        <div style={{ width: '100%', height: 200 }}>
                            <ResponsiveContainer>
                                <PieChart >
                                    <Pie
                                        data={data2}
                                        innerRadius={60}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        paddingAngle={5}
                                        dataKey="value"
                                    >
                                        {data2.map((cur, index) => (
                                            <Cell key={`cell-${index}`} fill={cur?.color} />
                                        ))}
                                        <Label
                                            value={`${currencyImage ?? ""} ${totalRevenueCount} `}
                                            position="center"
                                            fontWeight={"600"}
                                            // dy={-12}
                                            fontSize={15}
                                            fill="#000"
                                        />
                                        {/* <Label
                                            value={`${totalAppointmentCount}`}
                                            position="center"
                                            fontSize={12}
                                            dy={10}
                                            fill="#000"
                                        /> */}

                                    </Pie>
                                    <Tooltip
                                        formatter={(value, name) => [`${value}`, `${name}`]}
                                        contentStyle={{ fontSize: '14px' }}
                                    />

                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#0088FE" }}>Appointment Booked</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalBookedAppointments}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#00C49F" }}>Appointment Rescheduled</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalrescheduledAppointment}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#FFBB28" }}>Appointment Cancelled</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalcancelledAppointments}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#28dfff" }}>Appointment Checkout</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalcheckoutAppointmentCount}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9">  <p style={{ fontSize: "13px" }}> Total Appointment </p></div>
                            <div className="col-3 text-end">  <p style={{ fontSize: "13px" }}>{totalAppointmentCount}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>;
};

export default BookingDetails;
