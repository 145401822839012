import { useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addSpecializationValidation } from "../../../Context/Validation";
import { useEffect } from "react";
import useReduxData from "../../../Hooks/useReduxData";
import useUploadImage from "../../../Hooks/useUploadImage";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { INSERT_SPECIALIZATION, SPECIALIZATION_LIST, UPDATE_SPECIALIZATION } from "../../Repository/ExpertiseRepo";
import { RoleTitle } from "../../../Context/constants";
const useSpecialization = () => {
    const [showAdd, setShowAdd] = useState(false);
    const [showArchiv, setShowArchiv] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);
    const [previoursImage, setprevioursImage] = useState(null)
    const [SpecializationListdata, setSpecializationListdata] = useState([])
    const [archivedata, setArchiveData] = useState({})
    const [editId, setEditId] = useState({})
    const [loader, setLoader] = useState(true)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [Filter, setFilter] = useState({
        archive: true,
        specialization: "",
        disable_button: true
    })
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()

    useEffect(() => {
        getspecializationList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const headCells = [
        { id: "image", numeric: false, disablePadding: true, label: "Images" },
        { id: "specializationName", numeric: true, disablePadding: false, label: "Specialization", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action" }
    ];
    // !!!!  upload image
    const { UploadImage } = useUploadImage()
    // add specialization Api
    const addSpecialization = useFormik({
        initialValues: {
            specializationName: "",
        },
        validationSchema: addSpecializationValidation,
        onSubmit: async (values) => {
            let payload = {
                specializationName: values.specializationName,
                providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                businessId: userdata?.businessProfileId,
                image: imgUrl,
                clinicId: userdata?.location?.clinicId
            }
            setLoader(true)
            try {
                let response = await INSERT_SPECIALIZATION(payload)
                toast.success(response.message)
                getspecializationList()
                setShowAdd(false)
                addSpecialization.resetForm()
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    // archive/unarchive Api          
    const archiveUnarchive = async (event, data) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                specializationId: data.specializationId,
                userId: userdata.userId,
                activeStatus: !data.archive,
                businessId: userdata?.businessProfileId,
            }
            try {
                let response = await UPDATE_SPECIALIZATION(payload)
                toast.success(response.message)
                if (SpecializationListdata?.length === 1 && controller.page === 1) {
                    getspecializationList()
                }
                else if (SpecializationListdata?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getspecializationList()
                setLoader(false)
                setShowArchiv(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        } else {
            setShowArchiv(false)
        }

    }
    // get list specialization  Api
    const getspecializationList = async () => {
        const keyword = Filter?.specialization?.trim();
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            activeStatus: Filter?.archive,
            keyword: (controller?.searchPage && keyword) ? keyword : undefined,
            page: controller.page,
            count: controller?.rowsPerPage,
            clinicId: userdata?.location?.clinicId
        }
        setLoader(true)
        try {
            let response = await SPECIALIZATION_LIST(payload)
            setSpecializationListdata(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setSpecializationListdata([])
            setPaginationTotalCount(0)
            if (controller.page > 1) {
                setController((pre) => ({ ...pre, page: pre.page - 1 }))
            }
            setLoader(false)
        }
    }
    // view update data Api 
    const upadteservices = async (data) => {
        setEditId(data)
        setLoader(true)
        let payload = {
            specializationId: data.specializationId,
            activeStatus: data?.archive
        }
        try {
            let response = await SPECIALIZATION_LIST(payload)
            setprevioursImage(response?.data?.image)
            setImgUrl(response?.data?.image)
            updateSpecialization.setValues(response.data)
            setShowEdit(true)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    //  upadate Specialization Api
    const updateSpecialization = useFormik({
        initialValues: {
            specializationName: "",
        },
        validationSchema: addSpecializationValidation,
        onSubmit: async (values) => {
            let payload = {
                specializationId: editId.specializationId,
                userId: userdata.userId,
                specializationName: values.specializationName,
                image: imgUrl,
                businessId: userdata?.businessProfileId,
            }
            setLoader(true)
            try {
                let response = await UPDATE_SPECIALIZATION(payload)
                toast.success(response.message)
                getspecializationList()
                setShowEdit(false)
                updateSpecialization.resetForm()
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }


    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, specialization: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }
    return {
        headCells,
        loader,
        showEdit,
        showAdd,
        addSpecialization,
        updateSpecialization,
        imgUrl,
        SpecializationListdata,
        paginationTotalCount,
        archivedata,
        showArchiv,
        controller,
        Filter,
        CustomButton,
        previoursImage,
        getspecializationList,
        setprevioursImage,
        setShowAdd,
        setImgUrl,
        upadteservices,
        setShowArchiv,
        archiveUnarchive,
        UploadImage,
        setShowEdit,
        setArchiveData,
        setController,
        reset,
        handleChangeFilter,
        HandleSearchList,
        setLoader
    }
}

export default useSpecialization
