import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import { addTeamsValidation, pattern } from '../../../Context/Validation';
import moment from "moment";
import { useLocation, useNavigate } from 'react-router-dom';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import { CLINIC_LIST } from '../../Repository/ProfileRepo';
import { CLINIC_WITH_SERVICES_LIST, INSERT_FRONT_DESK, INSERT_STAFF } from '../../Repository/StaffRepo';
import { RoleTitle } from '../../../Context/constants';

const useAddTeam = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [clinicList, setClinicList] = useState([])
    const [check, setCheck] = useState(true)
    const [servicesList] = useState([])
    const [clinicWithServicesList, setClinicWithServicesList] = useState([])
    const [loader, setLoader] = useState(false)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { UploadImage } = useUploadImage()
    const FileRef = useRef()
    const navigate = useNavigate()
    const { pathname } = useLocation();

    const isFrontDeskPage = pathname === "/team/add-frontdesk"
    useEffect(() => {
        if (isFrontDeskPage) {
            getListClinic()
        } else {
            getClinicWithServicesList()
        }
        // getSpecializationListwithServiceList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // handal change birthDate
    const handalDOB = (e) => {
        addTeams.setFieldValue("dob", e.target.value)
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        addTeams.setFieldValue("age", age ?? "")
    }
    //  get  clinic clist 
    const getListClinic = async () => {
        setLoader(true)
        const payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
        }
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setLoader(false)
        } catch (error) {
            setClinicList([])
            setLoader(false)
            toast.error(error?.message)
        }
    }
    // get list Specialization with Service  Api
    // const getSpecializationListwithServiceList = async () => {
    //     setLoader(true)
    //     const payload = {
    //         providerId: userdata.userId,
    //     }
    //     try {
    //         let response = await SPECIALIZATION_LIST_WITH_SERVICES_STAFF(payload)
    //         let servicesdata = []
    //         for (let i = 0; i < response?.data?.length; i++) {
    //             for (let j = 0; j < response?.data[i]?.services?.length; j++) {
    //                 servicesdata.push({
    //                     specializationName: response?.data[i]?.specializationName,
    //                     serviceName: response?.data[i]?.services[j]?.serviceName,
    //                     serviceId: response?.data[i]?.services[j]?.serviceId
    //                 })
    //             }
    //         }
    //         if (servicesdata.length > 0) {
    //             setServicesList(servicesdata)
    //         }
    //         setLoader(false)
    //     } catch (error) {
    //         setServicesList([])
    //     }
    // }


    const getClinicWithServicesList = async () => {
        setLoader(true)
        const payload = {
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
        }
        try {
            let response = await CLINIC_WITH_SERVICES_LIST(payload)
            setClinicWithServicesList(response?.data)

            setLoader(false)
        } catch (error) {
            setClinicWithServicesList([])
        }
    }


    const handleChangeCinicSwitch = (e, clinicId) => {
        const { checked } = e?.target
        setClinicWithServicesList((pre) => {
            let temp = [...pre]
            let data = temp?.map((cur) => {
                if (cur?.clinicId === clinicId) {
                    return {
                        ...cur,
                        isChecked: checked,
                        specializationArray: cur?.specializationArray?.map((specializationItem) => {
                            return {
                                ...specializationItem,
                                isChecked: checked,
                                services: specializationItem?.services?.map((servicesItem) => {
                                    return { ...servicesItem, isChecked: checked, }
                                })
                            }
                        })
                    }
                }
                else return cur
            })
            return data
        })

    }

    const allselect = (e, clinicId, specializationId) => {
        const { checked } = e?.target
        setClinicWithServicesList((pre) => {
            let temp = [...pre]
            let data = temp?.map((cur) => {
                if (cur?.clinicId === clinicId) {
                    return {
                        ...cur,
                        specializationArray: cur?.specializationArray?.map((specializationItem) => {
                            if (specializationItem?.specializationId === specializationId && cur?.isChecked) {
                                return {
                                    ...specializationItem,
                                    isChecked: checked,
                                    services: specializationItem?.services?.map((servicesItem) => {
                                        return { ...servicesItem, isChecked: checked, }
                                    })
                                }
                            }
                            return specializationItem

                        })
                    }
                }
                else return cur
            })
            return data
        })
    };

    const handleChangeServices = (checked, clinicId, specializationId, servicesId) => {
        setClinicWithServicesList((pre) => {
            let temp = [...pre]
            let data = temp?.map((cur) => {
                if (cur?.clinicId === clinicId) {
                    return {
                        ...cur,
                        specializationArray: cur?.specializationArray?.map((specializationItem) => {
                            if (specializationItem?.specializationId === specializationId) {
                                return {
                                    ...specializationItem,
                                    services: specializationItem?.services?.map((servicesItem) => {
                                        if (servicesItem?.serviceId === servicesId && cur?.isChecked) {
                                            return { ...servicesItem, isChecked: !checked, }
                                        }
                                        else return servicesItem
                                    })
                                }
                            }
                            return specializationItem

                        })
                    }
                }
                else return cur
            })
            return data
        })
    };



    const getSelectedServices = (data) => {
        return data.map(clinic => {
            const services = clinic?.specializationArray?.flatMap(specialization => specialization?.services?.filter(service => service?.isChecked).map(service => service?.serviceId));
            return {
                clinicId: clinic.clinicId,
                services: services
            };
        }).filter(clinic => clinic.services.length > 0);
    };
    console.log(getSelectedServices(clinicWithServicesList),'clinicWithServicesList')

    // add teams 
    const addTeams = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            age: "",
            bloodGroup: "",
            number: "",
            email: "",
            services: [],
            clinicId: []
        },
        validationSchema: addTeamsValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.number?.length > 0 && (values?.number.match(pattern?.PHONE_NO))) {
                delete errors.number
            } else {
                errors.number = "Please enter a valid mobile number ";
            }
            if (!isFrontDeskPage || values?.clinicId?.length > 0) {
                delete errors.clinicId

            } else {
                errors.clinicId = "Please select clinic ";
            }
            return errors;
        },
        onSubmit: async (value) => {
            let { services, number, ...restValue } = value;

            let isCheckedClinic = clinicWithServicesList?.every((cur) => !cur?.isChecked);
            if (!isFrontDeskPage && isCheckedClinic) return toast.warn("Please choose at least one  clinic");

            let hasUncheckedServicesInCheckedClinic = clinicWithServicesList.some(clinic => {
                if (clinic?.isChecked) {
                    // Check if none of the services in this clinic are checked
                    let hasCheckedServices = clinic?.specializationArray.some(specializationItem =>
                        specializationItem?.services?.some(service => service?.isChecked)
                    );
                    return !hasCheckedServices; // Return true if no services are checked
                }
                return false;
            });

            if (!isFrontDeskPage && hasUncheckedServicesInCheckedClinic) return toast.warn("Please choose at least one service");
            let payload = {
                ...restValue,
                image: imgUrl,
                mobile: value.number,
                providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
                clinicId: isFrontDeskPage ? value.clinicId?.map((cur) => cur?.clinicId) : undefined,
                dob: moment(value.dob).format('YYYY-MM-DD'),
                roleTitle: userdata.roleTitle,
                timezoneName: userdata?.timezoneName,
                clinicAndServices: !isFrontDeskPage ? getSelectedServices(clinicWithServicesList) : undefined

            }
            setLoader(true)
            try {
                let response = isFrontDeskPage ? await INSERT_FRONT_DESK(payload) : await INSERT_STAFF(payload)
                toast.success(response.data)
                navigate("/team")
                setLoader(false)
            } catch (error) {
                setLoader(false)
                toast.error(error?.message)
            }
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "clinicId") {
            addTeams.setFieldValue(name, newValue)
        } else {
            addTeams.setFieldValue(name, newValue)
        }
    }

    return {
        loader,
        imgUrl,
        addTeams,
        clinicList,
        servicesList,
        FileRef,
        check,
        clinicWithServicesList,
        isFrontDeskPage,
        UploadImage,
        handalDOB,
        setCheck,
        setImgUrl,
        handalSearchAbleSelectBox,
        setLoader,
        setClinicWithServicesList,
        handleChangeServices,
        allselect,
        handleChangeCinicSwitch
    }
}

export default useAddTeam
