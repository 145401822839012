import {  useState } from "react";
import { useFormik } from "formik";
import { ChangePinValidation } from "../../../Context/Validation"
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import useReduxData from "../../../Hooks/useReduxData";
import { UPDATE_PIN } from "../../Repository/SettingRepo";
const useChangePin = () => {
    const [loader, setLoader] = useState(false)
    const [showPassword, setShowPassword] = useState({
        oldPin: false,
        newPin: false,
        confirmPin: false,
    })
    const { reduxData: userData } = useReduxData("userDataRDX")
    const handleClickShowPassword = (type) => {
        setShowPassword((pre) => ({
            ...pre, [type]: !pre[type]
        }))
    };
    const formik = useFormik({
        initialValues: {
            oldpin: "",
            newpin: "",
            confirmpin: "",
        },
        validationSchema: ChangePinValidation,
        onSubmit: async (values) => {
            setLoader(true)
            let payload = {
                userId: userData?.userId,
                newPin: Number(values.newpin),
                confirmPin: Number(values.confirmpin),
                oldPin: Number(values.oldpin),
                roleTitle: RoleTitle?.SERVICE_PROVIDER
            }
            try {
                let response = await UPDATE_PIN(payload)
                toast.success(response.message)
                formik.resetForm()
                setLoader(false)
            } catch (error) {
                toast.error(error?.message);
                setLoader(false)
            }
        },
    });

    return {
        formik,
        loader,
        showPassword,
        handleClickShowPassword
    }
}

export default useChangePin
