import React from "react";
import { ToastContainer } from "react-toastify";
import { Errors } from "../../Component/Error";
import PdfImage from '../../Assets/Images/home/pdf.svg';
import Modal from 'react-bootstrap/Modal';
import { Avatar, Badge, styled } from "@mui/material";
import Loading from "../../Component/Loading";
import useAddClinic from "./Hooks/useAddClinic";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { MdOutlineCameraAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom/dist";
import useDeleteFile from "../../Hooks/useDeleteFile";
import { SearchAbleSelectBox } from "../../Component/Inputs";
import DeleteProfile from "../../Component/DeleteProfile";
import { AiOutlineCloudUpload } from "react-icons/ai";
import useDragDrop from "../../Hooks/useDrag&Drop";
import useUploadImage from "../../Hooks/useUploadImage";
import { clinicPictures } from "../../Context/api";
import { FaArrowLeft } from "react-icons/fa";

const AddClinic = ({ type }) => {
  const {
    UserData,
    formik,
    loader,
    ListDataCountry,
    ListState,
    check,
    show,
    clinicPdf,
    policyData,
    specializationWithService,
    clinicToggle,
    DisplayPicture,
    FileRef,
    pdfRep,
    setPolicyData,
    uploadPdf,
    setShow,
    addClinicFildDataFun,
    setClinicToggle,
    setDisplayPicture,
    handleClose,
    handalSearchAbleSelectBox,
    setLoader
  } = useAddClinic(type)
  const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragDrop()
  const { deleteFileFun } = useDeleteFile()
  const { UploadImage } = useUploadImage()
  const { CustomButton } = useMuiCompontent()
  const navigate = useNavigate();
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    color: "#fff"
  }));
  return (
    <>
      {loader && <Loading />}
      {!UserData?.userId && <ToastContainer />}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-start align-items-center">
            <FaArrowLeft className="me-2 cursor" onClick={() => navigate(type === "LOGIN" ? "/alldetails/business-details" : "/Profile")} />
            <h2 className="page_name">Add Clinic</h2>
          </div>
        </header>
        <div className="row common-white-bg-box  d-flex align-content-start position-relative mx-0 pt-2  shadow">
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Clinic Name<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" maxLength={50} className="form-control" name="clinicName" value={formik.values.clinicName}
                  onChange={(e) => formik.setFieldValue(`clinicName`, e.target.value)}
                />
                <Errors formikfun={formik} name={"clinicName"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Clinic Name<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" maxLength={50} className="form-control" name="clinicName" value={formik.values.clinicName}
                      onChange={(e) => formik.setFieldValue(`clinicName`, e.target.value)}
                    />
                    <Errors formikfun={formik} name={"clinicName"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Display Picture</label>
                <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
              </div>
              <div className="col-4 d-flex  align-items-start flex-column">
                <Badge
                  style={{ background: "#fff" }}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp"  >
                      <MdOutlineCameraAlt
                        onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }}
                      >
                      </MdOutlineCameraAlt>
                      <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "CLINIC", setLoader, setDisplayPicture)} />
                    </SmallAvatar>
                  }
                >
                  <Avatar  sx={{ width: 80, height: 80 }} src={clinicPictures + DisplayPicture} >
                  </Avatar>
                </Badge>
                {DisplayPicture && <DeleteProfile setImgUrl={setDisplayPicture} deletedData={{ fileName: DisplayPicture, type: "CLINIC", id: null }} isDeleted={true} />}
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Display Picture</label>
                    <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                  </div>
                  <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                    <Badge
                      style={{ background: "#fff" }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp"  >
                          <MdOutlineCameraAlt
                            onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }}
                          >
                          </MdOutlineCameraAlt>
                          <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "CLINIC", setLoader, setDisplayPicture,DisplayPicture)} />
                        </SmallAvatar>
                      }
                    >
                      <Avatar  sx={{ width: 80, height: 80 }} src={clinicPictures + DisplayPicture} >
                      </Avatar>
                    </Badge>
                    {DisplayPicture && <DeleteProfile setImgUrl={setDisplayPicture} deletedData={{ fileName: DisplayPicture, type: "CLINIC", id: null }} isDeleted={true} />}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Clinic Registration Number<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" name="registrationNumber" value={formik.values.registrationNumber}
                  onChange={(e) => formik.setFieldValue(`registrationNumber`, e.target.value)}
                  maxLength={10}
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                />
                <Errors formikfun={formik} name={"registrationNumber"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Clinic Registration Number<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" className="form-control" name="registrationNumber" value={formik.values.registrationNumber}
                      onChange={(e) => formik.setFieldValue(`registrationNumber`, e.target.value)}
                      maxLength={10}
                      onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                    />
                    <Errors formikfun={formik} name={"registrationNumber"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Specializations & Services<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" className="form-control" value={specializationWithService?.service?.length}
                  onClick={() => { addClinicFildDataFun(); navigate("addSpecialization") }}
                  style={{ cursor: "pointer" }}
                  maxLength={10}
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Specializations & Services<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" className="form-control" value={specializationWithService?.service?.length}
                      onClick={() => { addClinicFildDataFun(); navigate("addSpecialization") }}
                      style={{ cursor: "pointer" }}
                      maxLength={10}
                      onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>

          <div className="col-sm-12  pb-2 mt-3 " >
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={formik.values.street}
                  onChange={(e) => formik.setFieldValue(`street`, e.target.value)}
                />
                <Errors formikfun={formik} name={"street"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={formik.values.address}
                      onChange={(e) => formik.setFieldValue(`address`, e.target.value)}
                    />
                    <Errors formikfun={formik} name={"address"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-2 " >
            {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-4">
                <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={formik.values.address}
                  onChange={(e) => formik.setFieldValue(`address`, e.target.value)}
                />
                <Errors formikfun={formik} name={"address"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-7">
                    <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={formik.values.street}
                      onChange={(e) => formik.setFieldValue(`street`, e.target.value)}
                    />
                    <Errors formikfun={formik} name={"street"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-2 " >
            {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-4">
                <SearchAbleSelectBox
                  placeholder="Select Country"
                  multiple={false}
                  option={ListDataCountry}
                  getOptionLabel="countryName"
                  isOptionEqualToValue="id"
                  value={formik?.values?.country}
                  name="country"
                  handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                />
                <Errors formikfun={formik} name={"country"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-7">
                    <SearchAbleSelectBox
                      placeholder="Select Country"
                      multiple={false}
                      option={ListDataCountry}
                      getOptionLabel="countryName"
                      isOptionEqualToValue="id"
                      value={formik?.values?.country}
                      name="country"
                      handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                    />
                    <Errors formikfun={formik} name={"country"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-2 " >
            {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-4">
                <SearchAbleSelectBox
                  placeholder="Select State"
                  multiple={false}
                  option={ListState}
                  getOptionLabel="stateName"
                  isOptionEqualToValue="stateId"
                  value={formik?.values?.state}
                  name="state"
                  handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                />
                <Errors formikfun={formik} name={"state"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-7">
                    <SearchAbleSelectBox
                      placeholder="Select State"
                      multiple={false}
                      option={ListState}
                      getOptionLabel="stateName"
                      isOptionEqualToValue="stateId"
                      value={formik?.values?.state}
                      name="state"
                      handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                    />
                    <Errors formikfun={formik} name={"state"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " >
            {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-4">
                <input
                  type="text"
                  maxLength={50}
                  className="form-control"
                  name="city"
                  placeholder="City"
                  value={formik.values.city}
                  onChange={(e) => formik.setFieldValue(`city`, e.target.value)}
                />
                <Errors formikfun={formik} name={"city"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-7">
                    <input
                      type="text"
                      maxLength={50}
                      className="form-control"
                      name="city"
                      placeholder="City"
                      value={formik.values.city}
                      onChange={(e) => formik.setFieldValue(`city`, e.target.value)}
                    />
                    <Errors formikfun={formik} name={"city"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 pt-0 " style={{ borderBottom: "2px solid #ededed" }} >
            {/* <div className="row">
              <div className="col-3">
              </div>
              <div className="col-4">
                <input type="text" className="form-control" name="zipCode"
                  onChange={(e) => formik.setFieldValue(`zipCode`, e.target.value)} value={formik.values.zipCode}
                  maxLength={10}
                  placeholder="ZIP Code"
                />
                <Errors formikfun={formik} name={"zipCode"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                  </div>
                  <div className="col-lg-7">
                    <input type="text" className="form-control" name="zipCode"
                      onChange={(e) => formik.setFieldValue(`zipCode`, e.target.value)} value={formik.values.zipCode}
                      maxLength={10}
                      placeholder="ZIP Code"
                    />
                    <Errors formikfun={formik} name={"zipCode"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Registration Proof<span className='Star_Mark'>*</span></label>
                <p style={{ color: "gray", fontSize: "13px" }}>PDF only</p>
              </div>
              <div className="col-4 d-flex justify-content-between flex-column align-items-center">
                <div className="bodrerer w-100  mx-0" style={{ border: `${!policyData ? "2px dashed #aaaaaa" : "none"}` }}
                  onDragEnter={handleDragEnter}
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e, uploadPdf)}
                >
                  <label htmlFor="inputTag">
                    <AiOutlineCloudUpload className="fs-4 " />
                    {policyData && <img src={PdfImage} alt="" />}
                    <input id="inputTag" type="file" name="file"
                      ref={pdfRep}
                      onChange={(e) => { uploadPdf(e.target.files[0]) }}
                    />
                    <br />
                    {
                      !policyData && <p className="mb-0">Drag file(s) here or <span>click to upload</span></p>
                    }
                    <br />
                  </label>

                </div>
                {
                  policyData && <p className=" m-0 ms-3 mt-2 all_icons_color text-danger" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setPolicyData, { fileName: policyData, type: "CLINICPROOF", id: null }, true)}>Delete</p>
                }

              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Registration Proof<span className='Star_Mark'>*</span></label>
                    <p style={{ color: "gray", fontSize: "13px" }}>PDF only</p>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex justify-content-between flex-column align-items-center">
                      <div className="bodrerer w-100  mx-0" style={{ border: `${!policyData ? "2px dashed #aaaaaa" : "none"}` }}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, uploadPdf)}
                      >
                        <label htmlFor="inputTag">
                          <AiOutlineCloudUpload className="fs-4 " />
                          {policyData && <img src={PdfImage} alt="" />}
                          <input id="inputTag" type="file" name="file"
                            ref={pdfRep}
                            onChange={(e) => { uploadPdf(e.target.files[0]) }}
                          />
                          <br />
                          {
                            !policyData && <p className="mb-0">Drag file(s) here or <span>click to upload</span></p>
                          }
                          <br />
                        </label>
                      </div>
                      {
                        policyData && <p className=" m-0 ms-3 mt-2 all_icons_color text-danger" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setPolicyData, { fileName: policyData, type: "CLINICPROOF", id: null }, true)}>Delete</p>
                      }

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          {/* {
            type !== "LOGIN" && <div className="col-sm-12  py-3 "   >
              <div className="row">
                <div className="col-3">
                </div>
                <div className="col-4">
                  <GreenSwitch name="active" checked={check} onClick={() => setClinicToggle(true)} />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault" >
                    {
                      check ? " Disable Clinic " : "  Enable  Clinic "
                    }
                  </label>
                </div>
              </div>
            </div>
          } */}

          <div className="col-lg-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end my-3" >
            <CustomButton
              variant="contained" className="me-2" onClick={formik.handleSubmit} > Save</CustomButton>
          </div>
        </div>
      </div>
      <Modal size="xl" show={show} onHide={() => setShow(false)} centered>
        <Modal.Body className="p-0" style={{ height: '600px' }}>
          <iframe src={clinicPdf + policyData} title={clinicPdf + policyData} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      <Modal show={clinicToggle} onHide={() => setClinicToggle(false)} centered>
        <Modal.Body className='px-0'>
          <div className="modal-body text-center cus-i px-0">
            {/* <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i> */}
            <p className='mb-0'>Are you sure?</p>
            <p>You want to  {check ? " disable  " : "  enable  "} this clinic</p>
            <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddClinic;
