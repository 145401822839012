import React from "react";
import moment from "moment";
import Loading from "../../Component/Loading";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import useOffersList from "./Hooks/useOffersList";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { FiRefreshCcw } from "react-icons/fi";
import useTableSorting from "../../Hooks/useTableSorting";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import MoreVertIcon from '@mui/icons-material/MoreVert';
const Offers = () => {
  const { sortlist, sortdata, offerListData, loader, handlSearchbox, navigate, paginationTotalCount, setController,
    controller, userdata, CustomButton, searchvalue, disable, search, reset, headCells, setAnchorEl } = useOffersList()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  const { GreenSwitch } = useMuiCompontent()
  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box ">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2>Offers</h2>
          </div>
        </header>
        <div className="px-2 common-white-bg-box px-0 d-flex justify-content-between  flex-column   mx-0 shadow  py-2">
          <div>
            <header className="body_header" >
              <div className="d-flex justify-content-between align-items-center">
                <ul className="headerlist px-0">
                  <li>
                    <input type="text" onChange={(e) => handlSearchbox(e.target.value)} value={searchvalue} placeholder="search" className="form-control filter_input" name="actualPrice" />
                  </li>
                  <li>
                    <CustomButton variant="contained" className="ms-3" disabled={disable} onClick={search} >search </CustomButton>
                  </li>
                  <li className="mx-3 d-flex justify-content-center align-items-center" >
                    <IconButton onClick={reset} size="small" aria-label="delete" disabled={disable} style={{ color: "#000", cursor: "pointer" }}>
                      <FiRefreshCcw />
                    </IconButton>
                  </li>
                  <li>
                    <CustomButton variant="contained" className="me-3" onClick={() => navigate("/offers/add")} > Add Offer</CustomButton>
                  </li>
                </ul>
                <ul className="headerlist">
                  <li>
                    <div className="dropdown-center cus-sort">
                      <div type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{ padding: "0", fontSize: "13px" }}>
                        Sort by :-
                      </div>
                      <ul className="dropdown-menu  dropdown-menu-end shadow">
                        <li><button className="dropdown-item" href="#"><div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" defaultChecked={sortdata.active} onClick={() => sortlist("active")} id="flexCheckChecked" />
                          <label className="form-check-label" htmlFor="flexCheckChecked">
                            Active
                          </label>
                        </div></button></li>
                        <li><button className="dropdown-item" href="#"><div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" defaultChecked={sortdata.inActive} onClick={() => sortlist("inactive")} />
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Inactive
                          </label>
                        </div></button></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </header>
            <TableContainer className="" style={{ minHeight: "250px" }}>
              {offerListData?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /></div>}
              <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
                {
                  offerListData?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={offerListData?.length} />
                }
                <TableBody className="table_overflow_scroll" >
                  {stableSort(offerListData, getComparator(order, orderBy))?.map((item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return <React.Fragment key={index}><TableRow id="hover_tr">
                      <TableCell className="ps-3" id={labelId} scope="row" padding="none">
                        {item?.offerName}
                      </TableCell>
                      <TableCell id={labelId} align="left">{item?.couponCode}</TableCell>
                      <TableCell id={labelId} align="left">{item?.offerType}</TableCell>
                      <TableCell id={labelId} align="left">{moment(item?.dateFrom)?.tz(userdata?.timezoneName)?.format('ll')}</TableCell>
                      <TableCell id={labelId} align="left">{moment(item?.dateTo)?.tz(userdata?.timezoneName)?.format('ll')}</TableCell>
                      <TableCell className="py-0" id={labelId} align="right"><GreenSwitch checked={item.active} size="small" /></TableCell>
                      <TableCell className="py-0" id={labelId} align="right">
                          <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                          <ul className="dropdown-menu dropdown-menu-end">
                            <li><button className="dropdown-item" href="#"
                              onClick={() => {
                                setAnchorEl(null)
                                navigate(`/offers/view/${item?.offerId}`)
                                localStorage.setItem("serviceId_in_offers", JSON.stringify(item?.serviceId))
                              }} ><i className="fa-solid fa-eye me-2 all_icons_color"></i>View Offer</button></li>
                            <li><button className="dropdown-item" href="#"
                              onClick={() => {
                                setAnchorEl(null)
                              }}><i className="fa-solid fa-circle-xmark me-2 all_icons_color"></i>Delete  Offer</button></li>
                            <li><button className="dropdown-item" href="#"
                              onClick={() => {
                                setAnchorEl(null)
                                navigate(`/offers/${item?.offerId}`)
                                localStorage.setItem("serviceId_in_offers", JSON.stringify(item?.serviceId))
                              }}><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Offer </button></li>
                          </ul>
                      </TableCell>
                    </TableRow>
                    </React.Fragment>
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>



          {/* <div className="row mt-4 px-0 mx-0 text_box_height "  >
            {offerListData?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
            {
              offerListData?.map((cur, index) => {
                return <React.Fragment key={index}>
                  <div className="col-xxl-3 col-xl-4  col-lg-6 col-md-6    ">
                    <div className="ribbons-wrapper Offers" onClick={() => { navigate(`/offers/${cur.offerId}`); localStorage.setItem("serviceId_in_offers", JSON.stringify(cur.serviceId)); }}>
                      <div className={`${cur.active === "true" ? " offer-active " : "offer-inactive"}`}>
                        <div className="wrap">
                          <span className={`${cur.active === "true" ? "ribbon-active" : "ribbon-inactive"}`}>{`${cur?.isExpired ? "Expire" : cur.active === "true" ? "Active" : "inActive"}`} </span>
                        </div>
                        <div className="row d-flex justify-content-center align-items-center">
                          <img className="mb-3" src={cur?.image === null ? offers : OfferImageFilePath + cur?.image} height={180} />
                          <h5 className="offers_name">{cur.offerName?.split(' ')?.map(item => item?.charAt(0)?.toUpperCase() + item?.slice(1)).join(' ')}</h5>
                          <p className="text-muted">{`${moment(cur.dateFrom).tz(userdata?.timezoneName).format("ll")}  /  ${moment(cur.dateTo).tz(userdata?.timezoneName).format("YYYY-MM-DD")}`}</p>
                          <p className="percentage"> {`${cur?.offerType === "FLAT" ? `Flat ${cur?.currencySymbol}${cur?.discountAmount} discount` : `${cur.percentage}% discount`}`}  </p>
                          <p className="Audience mb-0">{`Audience : `}
                            {
                              cur?.audienceGender?.map((gender, indexGender) => <span key={indexGender}> {`${gender?.charAt(0)?.toUpperCase()}${gender?.slice(1)?.toLowerCase()}`} { }{cur?.audienceGender?.length > indexGender + 1 ? ',' : ''}</span>)
                            }
                          </p>
                          <p className="services mb-0">{`Services : `}
                            <span>{cur?.services[0]?.name}{cur?.services?.length > 1 && `. . .`}</span>
                          </p>
                          <p className="services mb-0">{`Coupon Code : `}
                            <span>{cur?.couponCode}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              })
            }
          </div> */}
          <nav className="w-100" aria-label="pagination">
            {<Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
          </nav>
        </div>

      </div>


    </>
  );
};

export default Offers;

