import { useState, useEffect, useRef } from 'react'
import { addOffersValidation } from "../../../Context/Validation";
import { toast } from "react-toastify";
import { commonMessages } from "../../../Context/constants";
import { listservices, updateoffers, viewoffer } from "../../../Context/api";
import moment from "moment";
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosInstance } from '../../../Context/CraeteAxios';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import useMinimumDate from '../../../Hooks/useMinimumDate';

const useEditOffers = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [check, setCheck] = useState(null)
    const [Agecheck, setAgeCheck] = useState(false)
    const [serviceListData, setServiceListData] = useState([])
    const [seletedService, setSeletedService] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()
    const FileRef = useRef()
    const { reduxData: userdata } = useReduxData("userDataRDX")
    // upload image
    const { UploadImage } = useUploadImage("OFFER", setLoader, setImgUrl)
    const { minimumdate } = useMinimumDate()
    useEffect(() => {
        getservicesList()
        upadteservices()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (serviceListData?.length > 0) {
            const services = serviceListData?.filter((cur, index) => {
                const selectedServiceId = seletedService[index]?.id;
                return cur?.serviceId === selectedServiceId;
            });
            formik.setFieldValue("serviceId", services)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceListData])
    //update offers view Api
    const upadteservices = (data) => {
        setLoader(true)
        axiosInstance.post(viewoffer(), { userId: userdata.userId, offerId: id }).then((res) => {
            if (res.data.status === "200") {
                setCheck(res.data.data.active)
                setImgUrl(res.data.data?.image)
                setAgeCheck((!res.data.data?.ageFrom && !res.data.data?.ageTo) === true ? false : true)
                formik.setFieldValue("offerName", res.data.data?.offerName)
                formik.setFieldValue("audienceGender", res.data.data?.audienceGender?.map((cur, index) => {
                    if (cur === "MALE") return { label: "Male", value: "MALE" }
                    if (cur === "FEMALE") return { label: "Female", value: "FEMALE" }
                    if (cur === "OTHER") return { label: "Other", value: "OTHER" }
                    return cur
                }))
                setSeletedService(res.data.data?.services)
                formik.setFieldValue("dateFrom", res.data.data?.dateFrom)
                formik.setFieldValue("dateTo", res.data.data?.dateTo)
                formik.setFieldValue("discountType", res.data.data?.offerType)
                formik.setFieldValue("couponCode", res.data.data?.couponCode ? res.data.data?.couponCode : "")
                formik.setFieldValue("minimumCartValue", res.data.data?.minimumCartValue ? res.data.data?.minimumCartValue : '')
                formik.setFieldValue("percentage", res.data.data?.percentage ? res.data.data?.percentage : "")
                formik.setFieldValue("maxDiscountUpto", res.data.data?.maxDiscountUpto ? res.data.data?.maxDiscountUpto : "")
                formik.setFieldValue("discountAmount", res.data.data?.discountAmount ? res.data.data?.discountAmount : "")
                formik.setFieldValue("description", res.data.data?.description)
                formik.setFieldValue("terms", res.data.data?.terms)
                formik.setFieldValue("age", [res.data.data?.ageFrom ? res.data.data?.ageFrom : 0, res.data.data?.ageTo ? res.data.data?.ageTo : 0])
            } else if (res.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            } else {
                toast.error(res.data.message)
            }
            setLoader(false)
        }).catch(() => {
            toast.error(commonMessages.NetworkError)
            setLoader(false)
        })
    }
    //services list
    const getservicesList = () => {
        let data = {
            userId: userdata.userId,
            archive: false,
            keyword: '',
            page: "",
            count: "",
            roleTitle: userdata.roleTitle
        }
        axiosInstance.post(listservices(), { ...data }).then((res) => {
            if (res.data.status === "200") {
                setServiceListData(res.data.data)
            } else if (res.data.status === "500") {
                setServiceListData([])
            } else {
                setServiceListData([])
            }
        }).catch(() => {
            setLoader(false)

        })
    }
    // update offers
    const formik = useFormik({
        initialValues: {
            offerName: "",
            audienceGender: [],
            age: [20, 37],
            serviceId: [],
            dateFrom: "",
            dateTo: "",
            discountType: "",
            percentage: "",
            maxDiscountUpto: "",
            minimumCartValue: "",
            terms: "",
            description: "",
            couponCode: "",
            discountAmount: ""
        },
        validationSchema: addOffersValidation,
        // validationSchema: "",
        onSubmit: value => {
            if ((value.dateFrom > value.dateTo) && (value.dateFrom !== value.dateTo)) return toast.warn("Start date cannot be greater than  end date");
            value.dateFrom = moment(value.dateFrom).startOf('day').utc()
            value.dateTo = moment(value.dateTo).endOf('day').utc()
            if (value.percentage >= 100) {
                return toast.warn("Percentage cannot be more than 100%")
            }
            let data = {
                ...value,
                active: check,
                userId: userdata.userId,
                image: imgUrl,
                serviceId: value?.serviceId?.map((cur, index) => cur?.serviceId),
                audienceGender: value?.audienceGender?.map((cur, index) => cur?.value),
                maxDiscountUpto: value?.discountType === "FLAT" ? "" : value?.maxDiscountUpto,
                percentage: value?.discountType === "FLAT" ? "" : value.percentage,
                discountAmount: value?.discountType === "FLAT" ? value?.discountAmount : "",
                age: Agecheck ? value?.age : null,
                offerId: id
            }
            setLoader(true)
            axiosInstance.post(updateoffers(), data).then((res) => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                    setTimeout(() => {
                        navigate('/offers')
                    }, 1000)
                } else if (res.data.status === "500") {
                    toast.error(commonMessages.NetworkError);
                    setLoader(false)
                } else {
                    setLoader(false)
                    toast.error(res.data.message)
                }
            }).catch(() => {
                toast.error(commonMessages.net)
                setLoader(false)
            })
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "audienceGender") {
            formik?.setFieldValue("audienceGender", newValue)
        } else {
            formik.setFieldValue("serviceId", newValue)
        }
    }
    return {
        loader,
        formik,
        imgUrl,
        serviceListData,
        minimumdate,
        check,
        Agecheck,
        id,
        UploadImage,
        setCheck,
        setAgeCheck,
        setImgUrl,
        FileRef,
        handalSearchAbleSelectBox
    }
}

export default useEditOffers
