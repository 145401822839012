// import { RoleTitle } from "../Context/constants";

import { DELETE_FILE } from "../Pages/Repository/CommonRepo";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const formatNumber = (number, Fixed = 2) => {
  if (number !== null && number !== undefined) {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(Fixed) + 'M';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(Fixed) + 'K';
    } else {
      return number.toLocaleString();
    }
  } else {
    return ''; // or any other default value if number is undefined or null
  }
};

export const MaskedEmail = (email) => {
  // Mask part of the username with asterisks
  const maskedEmail = email?.replace(/(.*?)@/, (match, p1) => {
    const visiblePart = p1?.substring(0, 2); // Show the first 2 characters
    const maskedPart = p1?.substring(2).replace(/./g, '*'); // Mask the rest
    return visiblePart + maskedPart + '@';
  });
  return maskedEmail;
};


export const PdfItem = (pdfName) => {
  const parts = pdfName?.split('_');
  const mergedValue = parts?.slice(1)?.join('');
  return mergedValue
};

export const TIMEZONE = () => {
  if (timeZone === "Asia/Calcutta" || timeZone === "Asia/Kolkata") {
    let indiaTimezone = "Asia/Kolkata"
    return indiaTimezone
  } else {
    return timeZone
  }
}


export const Dosage = (item) => {

  let dosageString = "--";
  if (item?.medicineIntakeType === "STANDARD") {
    if (item?.medicineIntakeMorning && item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "M-A-E"
    } else if (item?.medicineIntakeMorning && item?.medicineIntakeAfternoon) {
      dosageString = "M-A"
    }
    else if (item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "A-E"
    }
    else if (item?.medicineIntakeEvening && item?.medicineIntakeMorning) {
      dosageString = "M-E"
    }
    else if (item?.medicineIntakeEvening && item?.medicineIntakeMorning) {
      dosageString = "M-E"
    }
    else if (item?.medicineIntakeMorning && !item?.medicineIntakeAfternoon && !item?.medicineIntakeEvening) {
      dosageString = "M"
    }
    else if (!item?.medicineIntakeMorning && item?.medicineIntakeAfternoon && !item?.medicineIntakeEvening) {
      dosageString = "A"
    } else if (!item?.medicineIntakeMorning && !item?.medicineIntakeAfternoon && item?.medicineIntakeEvening) {
      dosageString = "E"
    }
    else {
      dosageString = "--"
    }

  } else {
    dosageString = item?.customMedCount + item?.customMedCountTimeFrame
  }
  return dosageString
}

export const featurePermission = ({ permission = {}, userdata = {}, section }) => {
  let active = true
  // if (userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER) {
  active = permission?.[section]?.isActive ?? true
  // }
  return active
}

//   type = "SOFTDELETE",//action type
//   getImages = [],//For  backUp and privious check image
//   imagePath = "defaultPath", //file path
//   setAllImage = () => { },//setter function deleted image
//   allImage = [],// deleted image array
//   setImage = () => { }, //function uploaded image
//   Images = null//uploaded image
// } = {}) => {

//   let getImage = (Array.isArray(getImages) ? getImages : [getImages]).filter(Boolean);
//   console.log(getImage, "getImage")

//   let storedImage = [...allImage].filter(Boolean);
//   console.log(storedImage, "storedImagestoredImage")

//   if (type === "SOFTDELETE") {
//     setAllImage((prev) => [...prev, Images]);
//     setImage(null);
//     return;
//   }

//   let imageForDeletion = [];
//   if (type === "DISCARD_HARD_DELETE") {
//     storedImage = storedImage.filter(item => !getImage.includes(item));
//     console.log(storedImage, "storedImage")
//     imageForDeletion = [...storedImage].filter(Boolean);
//     if (!getImage.includes(Images)) {
//       imageForDeletion.push(Images)
//     }
//     console.log(imageForDeletion, "imageForDeletion")
//   }
//   if (type === "SUBMIT_HARD_DELETE") {
//     if (storedImage?.length > 0) {
//       imageForDeletion = storedImage?.filter(item => item !== Images);
//     }
//     //  else {
//     //   imageForDeletion = [Images].filter(Boolean);;
//     // }
//   }
//   imageForDeletion = imageForDeletion.filter(Boolean);
//   if (!imageForDeletion.length) {
//     console.log("No valid images to delete. Skipping API call.");
//     return;
//   }

//   try {
//     let payload = {
//       fileName: imageForDeletion,
//       type: imagePath,
//     };
//     if (imageForDeletion.length === 0) return
//     await DELETE_FILE(payload);
//     setAllImage([]);
//     setImage(null);

//   } catch (error) {
//     console.error("Error deleting image:", error);
//   }
// };
export const removeFile = async ({
  type = "SOFTDELETE",
  getImages = [],
  imagePath = "defaultPath",
  setAllImage = () => { },
  allImage = [],
  setImage = () => { },
  Images = null
} = {}) => {
  const getImage = Array.isArray(getImages) ? getImages.filter(Boolean) : [getImages].filter(Boolean);
  const storedImage = allImage.filter(Boolean);
  if (type === "SOFTDELETE") {
    setAllImage((prev) => [...prev, Images]);
    setImage(null);
    return;
  }
  let imageForDeletion = [...storedImage];
  if (type === "DISCARD_HARD_DELETE") {
    imageForDeletion = storedImage.filter(item => !getImage.includes(item));
    console.log(imageForDeletion, "imageForDeletionimageForDeletion")
    if (!getImage.includes(Images)) {
      imageForDeletion.push(Images);
    }
  }
  if (type === "SUBMIT_HARD_DELETE" && storedImage.length > 0) {
    imageForDeletion = storedImage.filter(item => item !== Images);
  }
  imageForDeletion = imageForDeletion.filter(Boolean);
  if (!imageForDeletion.length) {
    return;
  }
  try {
    const payload = {
      fileName: imageForDeletion,
      type: imagePath
    };
    await DELETE_FILE(payload);
    setAllImage([]);
    setImage(null);
  } catch (error) {
    console.error("Error deleting image:", error);
  }
};
export const TruncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return text?.substring(0, maxLength) + '...';
  }
  return text;
};

