import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "teamsDetails",
    initialState: {},
    reducers: {
        addTeamDetails(state, action) {
            return state = action.payload;
        },
    },
});
export const { addTeamDetails } = reducer.actions;
export default reducer.reducer;