import { useEffect, useState } from 'react'
import useReduxData from '../../../Hooks/useReduxData'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { addLifeStyleValidation } from '../../../Context/Validation'
import { EDIT_LIFESTYLE, INSERT_LIFESTYLE, LIFESTYLE_LIST } from '../../Repository/SettingRepo'
import { RoleTitle } from '../../../Context/constants'
const useLifeStyle = () => {
    const [showMOdal, setShowModal] = useState(false)
    const [deleteModal, setDeletedModal] = useState(false)
    const [loader, setLoader] = useState(true)
    const [listLifeStyle, setListLifeStyle] = useState([])
    const [ChooseLifestyle, setChooseLifestyle] = useState(null)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Action, setAction] = useState(false)
    const [Filter, setFilter] = useState({
        archive: true,
        disable_button: true,
        business_category: ""
    })
    // hooks
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    useEffect(() => {
        getListLifeStyle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, Action])
    //add Record
    const formik = useFormik({
        initialValues: {
            name: ""
        },
        validationSchema: addLifeStyleValidation,
        onSubmit: async (values, { resetForm }) => {
            setLoader(true)
            let payload = {
                lifeStyleName: values?.name,
                userId: ChooseLifestyle ? undefined : userdata?.userId,
                lifeStyleId: ChooseLifestyle ? ChooseLifestyle?.lifeStyleId : undefined,
                roleTitle: userdata?.roleTitle,
                providerId: (userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId
            }
            try {
                let response = ChooseLifestyle ? await EDIT_LIFESTYLE(payload) : await INSERT_LIFESTYLE(payload)
                setShowModal(false)
                resetForm()
                toast.success(response.message)
                getListLifeStyle()
            } catch (error) {
                toast.error(error.message)
            }
            finally {
                setLoader(false)
            }
        }
    })
    //List Life Style
    const getListLifeStyle = async () => {
        setLoader(true)
        const { page, rowsPerPage } = controller;
        const { archive, business_category } = Filter;
        const keyword = business_category.trim();
        const payload = keyword ? {
            keyword,
            activeStatus: archive,
            userId: userdata?.userId,
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : undefined
        } : {
            page,
            count: rowsPerPage,
            activeStatus: archive,
            userId: userdata?.userId,
            providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : undefined
        };
        try {
            let response = await LIFESTYLE_LIST(payload)
            setListLifeStyle(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            toast.error(error.message)
            setPaginationTotalCount(0)
            setListLifeStyle([])
        }
        finally {
            setLoader(false)
        }
    }
    const deleteLifeStyleFun = async (event) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                lifeStyleId: ChooseLifestyle?.lifeStyleId,
                activeStatus: !ChooseLifestyle?.activeStatus
            }
            try {
                let response = await EDIT_LIFESTYLE(payload)
                toast.success(response.message)
                setLoader(false)
                getListLifeStyle()
                setDeletedModal(false)
                setChooseLifestyle(null)
            } catch (error) {
                toast.error(error.message)
                setDeletedModal(false)
                setLoader(false)
            }
        } else {
            setDeletedModal(false)
            setLoader(false)
        }
    }

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }
    const reset = () => {
        setAction((pre) => !pre)
        setFilter({
            archive: true,
            disable_button: true,
            business_category: ""
        })
    }
    return {
        deleteLifeStyleFun,
        setShowModal,
        setChooseLifestyle,
        ChooseLifestyle,
        setDeletedModal,
        showMOdal,
        listLifeStyle,
        loader,
        formik,
        deleteModal,
        controller,
        setController,
        paginationTotalCount,
        Filter,
        handleChangeFilter,
        reset,
        getListLifeStyle
    }
}

export default useLifeStyle
