import React from "react";
import Loading from "../../Component/Loading";
import useNotification from "./Hooks/useNotification";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { RoleTitle } from "../../Context/constants";

const Notification = () => {
  const { list, id, updatesetting, loader, userData } = useNotification()
  const { GreenSwitch } = useMuiCompontent()
  return <>
    {loader && <Loading />}
    <div className="">
      <div className=" " >
        <div className="table-responsive ">
          <table className="table cus-table bootstrap_table_responsive">
            <thead>
              <tr>
                <td width="70%"></td>
                <td width="10%">E-mail</td>
                {/* <td  width="10%">SMS</td> */}
                <td width="10%">Push</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Appointments</td>
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Appointments?.email}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Appointments", status: { email: !list?.Appointments?.email } }, "appointments_email")} />
                </td>
                {/* <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Appointments?.sms}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Appointments", status: { sms: !list?.Appointments?.sms } }, "appointments_sms")} />
                </td> */}
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Appointments?.push}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Appointments", status: { push: !list?.Appointments?.push } }, "appointments_push")} />
                </td>
              </tr>
              <tr>
                <td> <span></span> Waiting List</td>
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.WaitingList?.email}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "WaitingList", status: { email: !list?.WaitingList?.email } }, "waiting_list_email")} />
                </td>
                {/* <td className="p-0">
                  <GreenSwitch size="medium" checked={list.WaitingList?.sms}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "WaitingList", status: { sms: !list?.WaitingList?.sms } }, "waiting_list_sms")} />
                </td> */}
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.WaitingList?.push}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "WaitingList", status: { push: !list?.WaitingList?.push } }, "waiting_list_push")} />
                </td>
              </tr>
              {
                userData?.roleTitle !== RoleTitle?.FRONT_DESK && <tr>
                  <td > {userData.roleTitle === RoleTitle.SERVICE_PROVIDER ? "Team Availability" : "Availability"}  </td>
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.StaffAvailability?.email}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "StaffAvailability", status: { email: !list?.StaffAvailability?.email } }, "Staff_Availability_email")} />
                  </td>
                  {/* <td className="p-0">
                      <GreenSwitch size="medium" checked={list.StaffAvailability?.sms}
                        onChange={(e) => updatesetting({ userId: id, notificationType: "StaffAvailability", status: { sms: !list?.StaffAvailability?.sms } }, "Staff_Availability_sms")} />
                    </td> */}
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.StaffAvailability?.push}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "StaffAvailability", status: { push: !list?.StaffAvailability?.push } }, "Staff_Availability_push")} />
                  </td>
                </tr>
              }
              {
                userData?.roleTitle !== RoleTitle?.FRONT_DESK && <tr>
                  <td> {userData.roleTitle === RoleTitle.SERVICE_PROVIDER ? "Team UnAvailability" : "UnAvailability"} </td>
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.StaffUnavailability?.email}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "StaffUnavailability", status: { email: !list?.StaffUnavailability?.email } }, "Staff_UnAvailability_email")} />
                  </td>
                  {/* <td className="p-0">
                      <GreenSwitch size="medium" checked={list.StaffUnavailability?.sms}
                        onChange={(e) => updatesetting({ userId: id, notificationType: "StaffUnavailability", status: { sms: !list?.StaffUnavailability?.sms } }, "Staff_UnAvailability_sms")} />
                    </td> */}
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.StaffUnavailability?.push}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "StaffUnavailability", status: { push: !list?.StaffUnavailability?.push } }, "Staff_UnAvailability_push")} />
                  </td>
                </tr>
              }

              {
                userData?.roleTitle !== RoleTitle?.FRONT_DESK && <tr>
                  <td> Service Assignment</td>
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.Service_Assignment?.email}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "Service_Assignment", status: { email: !list?.Service_Assignment?.email } }, "Service_Assignment_email")} />

                  </td>
                  {/* <td className="p-0">
     <GreenSwitch size="medium" checked={list.Service_Assignment?.sms}
       onChange={(e) => updatesetting({ userId: id, notificationType: "Service_Assignment", status: { sms: !list?.Service_Assignment?.sms } }, "Service_Assignment_sms")} />
   </td> */}
                  <td className="p-0">
                    <GreenSwitch size="medium" checked={list.Service_Assignment?.push}
                      onChange={(e) => updatesetting({ userId: id, notificationType: "Service_Assignment", status: { push: !list?.Service_Assignment?.push } }, "Service_Assignment_push")} />
                  </td>
                </tr>
              }


              <tr>
                <td> Clinic Assignment</td>
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Clinic?.email}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Clinic", status: { email: !list?.Clinic?.email } }, "Clinic_Assignment_email")} />
                </td>
                {/* <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Clinic?.sms}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Clinic", status: { sms: !list?.Clinic?.sms } }, "Clinic_Assignment_sms")} />
                </td> */}
                <td className="p-0">
                  <GreenSwitch size="medium" checked={list.Clinic?.push}
                    onChange={(e) => updatesetting({ userId: id, notificationType: "Clinic", status: { push: !list?.Clinic?.push } }, "Clinic_Assignment_push")} />
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </>;
};

export default Notification;
