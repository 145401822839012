import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "userData",
    initialState: {},
    reducers: {
        addUserData(state, action) {
            return state = action.payload;
        },
        updateUserData(state, action) {
            const { img, name, } = action?.payload
            state.profileImage = img
            if (name) { state.name = name }
        },
        updateTimezone(state, action) {
            const { timezone } = action?.payload
            if (timezone) { state.timezoneName = timezone }
        },
        updateUserName(state, action) {
            const { firstName, lastName } = action?.payload
            if (firstName) { state.firstName = firstName }
            if (lastName) { state.lastName = lastName }
        },
        updateUserImage(state, action) {
            const { img, businessName } = action?.payload
            if (businessName) { state.businessName = businessName }
            state.businessProfileImage = img
        },
        updateStaffProfile(state, action) {
            let { gender, lastName, image, firstName, dob, title } = action.payload
            return state = { ...state, gender, lastName, firstName, dob, title, profileImage: image }
        }
    },
});
export const { addUserData, updateUserData, updateUserName, updateUserImage, updateTimezone, updateStaffProfile } = reducer.actions;
export default reducer.reducer;
