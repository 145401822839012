import React, { useEffect, useState } from 'react'
import { FilePathName, } from '../../Context/api';
import { useParams } from 'react-router-dom';
import useReduxData from '../../Hooks/useReduxData';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Loading from '../../Component/Loading';
import { NoRecordFound } from '../../Component/NoRecordFound';
import { RoleTitle } from '../../Context/constants';
import { TEST_REPORT_LIST } from '../Repository/PatientRepo';
import useTableSorting from '../../Hooks/useTableSorting';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import viewIcon from "../../Assets/Icons/MicrosoftTeams-image (2).png"
import downloadIcon from "../../Assets/Icons/MicrosoftTeams-image.png"
import { useDownloadImage } from '../../Hooks/useDownloadImage';
const TestReport = ({ type }) => {
    const [testData, setTestData] = useState([])
    const [ShowPDFModal, setShowPDFModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [pdfPathName, setPdfPathName] = useState(null)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [count, setCount] = useState(0)
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const { downloadImage } = useDownloadImage()
    const { id, subpatientId } = useParams()
    useEffect(() => {
        test()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    const test = async () => {
        setLoader(true)
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
        }
        try {
            let response = await TEST_REPORT_LIST(payload)
            setTestData(response?.data?.map((cur)=>{
                return {...cur, doctorName: `${cur?.Doctor} ${cur?.doctorLastName}`}
            }))
            setLoader(false)
            setCount(response?.totalCount)
        } catch (error) {
            setTestData([])
            setLoader(false)
            setCount(0)
        }
    }
    const headCells = [
        { id: "apptNumber", numeric: false, disablePadding: true, label: "Appointment Number", showIcon: true },
        { id: "apptStartDate", numeric: false, disablePadding: true, label: " Appointment Date", showIcon: true },
        { id: "time", numeric: true, disablePadding: false, label: " Appointment Time" },
        { id: "doctorName", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "visitType", numeric: true, disablePadding: false, label: "Report Name", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)


    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div >
                    <TableContainer >
                        {testData?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >
                                <NoRecordFound />
                            </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                testData?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={testData?.length} />
                            }
                            <TableBody>

                                {
                                    stableSort(testData, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow hover id="hover_tr" >
                                                <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                    {row?.appointmentNumber}
                                                </TableCell>
                                                <TableCell id={labelId}  >
                                                    {moment(row.startDate).tz(userData?.timezoneName).format('ll')}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{moment(row.startDate).tz(userData?.timezoneName).format('LT')} - {moment(row.endDate).tz(userData?.timezoneName).format('LT')}</TableCell>
                                                <TableCell id={labelId} align="left">{row.doctorName}</TableCell>
                                                <TableCell id={labelId} align="left">{row.title}</TableCell>
                                                <TableCell id={labelId} align="right" className=''>
                                                    <img src={viewIcon} alt="" className='cursor' width={18} height={18} title='view' onClick={() => {
                                                        setShowPDFModal(true); setPdfPathName(row?.test)
                                                    }} />
                                                    <img src={downloadIcon} alt="" className='mx-1 cursor' width={18} height={18} title='Download' onClick={() => downloadImage(FilePathName + row?.test, row?.test)} />
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className='w-100 3'>
                    {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                </div>
            </div>
            <Modal size={`${pdfPathName?.split('.')?.pop() === "pdf" ? "xl" : "md"}`} show={pdfPathName && ShowPDFModal} onHide={() => { setShowPDFModal(false); setPdfPathName() }} centered>
                <Modal.Body className="p-0" style={{ height: ` ${pdfPathName?.split('.')?.pop() === "pdf" ? "600px" : "400px"} ` }}>
                    {
                        pdfPathName?.split('.')?.pop() === "pdf" ? <iframe src={FilePathName + pdfPathName} title={FilePathName + pdfPathName} style={{ width: '100%', height: '100%', }} /> :
                            <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={FilePathName + pdfPathName} alt="" />
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}
export default TestReport  
