import React, { useEffect, useRef, useState } from "react";
import logo from "../Assets/Images/LOGO 1 (1) 1.png"
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { TeamImageFilePath, userImageFilePath } from "../Context/api";
import { useContext } from "react";
import { NotificationsState } from "../App";
import { Modal } from "react-bootstrap";
import { Avatar, Badge, Button, IconButton, Menu, MenuItem, alpha, styled } from "@mui/material";
import useReduxData from "../Hooks/useReduxData";
import { addUserData } from "../Redux/ReducerFile/UserData";
import { IoIosNotificationsOutline } from "react-icons/io";
import useBroserNotification from "../Hooks/useBroserNotification";
import { RoleTitle } from "../Context/constants";
import { ToastContainer, toast } from "react-toastify";
import userDefaultImage from "../Assets/Images/dummyimg.png"
import { IoIosArrowDown } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { envoirment } from "../Context/envoirment";
import MenuIcon from '@mui/icons-material/Menu';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { LOGOUT } from "../Pages/Repository/CredentialRepo";
import Loading from "../Component/Loading";
import { MdLogout } from "react-icons/md";
import { NOTIFICATION_LIST } from "../Pages/Repository/NotificationsRepo";
import { featurePermission } from "../Utils/Utils";
import { addTeamDetails } from "../Redux/ReducerFile/TeamDetails";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const Private = () => {
  const navigete = useNavigate()
  const [show, setShow] = useState(false)
  const [notificationPermission, setNotificationPermission] = useState(false)
  const [showPhone, setPhoneShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setopen] = useState({ Appointments: false, setting: false })
  const [onmessage, setOnmessage] = useState(false)

  const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
  const { notificationcount, setSocket, setNotificationcount } = useContext(NotificationsState)
  const { showNotification } = useBroserNotification()
  const { pathname } = useLocation();

  const splitLocation = pathname.split("/");
  const pathnameRef = useRef(pathname);
  pathnameRef.current = pathname;
  const OPEN = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const ws = initWebSocket();
  const getColor = (curr) => {
    let newCurr = curr.split("/")
    if (pathname === curr || newCurr[2] === splitLocation[2]) { return "red" }
  }
  const showhide = (event) => {
    switch (event) {
      case "Appointments":
        if (open.setting) {
          open.setting = false
        }
        open.Appointments = !open.Appointments
        setopen({ ...open })

        break;
      case "setting":
        if (open.Appointments) {
          open.Appointments = false
        }
        open.setting = !open.setting
        setopen({ ...open })
        break;
      default:
        break
    }
  }
  useEffect(() => {
    let li = document.querySelectorAll(".sidebar .nav-links .close_line")
    for (var i = 0; i < li.length; i++) {
      li[i].addEventListener("click", (e) => {
        open.setting = false
        open.Appointments = false
        setopen({ ...open })
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let notificationPermission = false
    if (notificationPermission === false) {
      Notification.requestPermission().then((res) =>
        notificationPermission = res === 'granted' ? true : false
      )
      setNotificationPermission(notificationPermission)
    }
  }, [notificationPermission, onmessage])


  const logout = async () => {
    setLoading(true)
    let payload = {
      authToken: userdata?.token,
    }
    try {
      let response = await LOGOUT(payload)
      sessionStorage.clear();
      toast.success(response.message)
      dispatch(addUserData({}));
      navigete('/')
      localStorage.clear();
    } catch (error) {
      toast.error(error.message)
      setLoading(false)
    }
  }

  async function getListNotification() {
    const payload = {
      providerId: userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? userdata?.userId : undefined,
      staffId: (userdata?.roleTitle === RoleTitle?.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.userId : undefined,
      filter: "ALL",
      roleTitle: userdata?.roleTitle
    };
    try {
      let response = await NOTIFICATION_LIST(payload)
      setNotificationcount(response?.unreadCount)
    } catch (error) {

    }
  }
  useEffect(() => {
    let ws;
    let reconnectInterval;
    const connectWebSocket = () => {
      ws = new WebSocket(`${envoirment.socketUrl}/winkdoc-api/websocket`);
      ws.onopen = () => {
        console.log("WebSocket onopen")
        setSocket(ws);
        const data = {
          eventType: "register",
          userId: userdata?.userId,
          roleTitle: userdata?.roleTitle,
        };
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        } else {
          ws.onopen = () => {
            ws.send(JSON.stringify(data));
          };
        }
      };

      ws.onmessage = (event) => {
        console.log("WebSocket onmessage")
        const wsdata = JSON.parse(event.data);
        setOnmessage((pre) => !pre)
        console.log(wsdata, "socket data")
        if (wsdata?.data?.eventType === "newNotification") {
          getListNotification();
          let notificationData = { message: wsdata?.data?.message };
          const setNotificationData = (routes, type) => {
            notificationData.routes = routes;
            notificationData.type = type;
          };

          switch (wsdata?.data?.type) {
            case "ClinicAssigned":
              setNotificationData((userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) ? "/team/details/location" : "profile", "SERVICE ASSIGNED");
              break;
            case "Service_Assignment":
              setNotificationData((userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) ? `/team/specialization/${wsdata?.data?.clinicId}` : "profile", "SERVICE ASSIGNED");
              break;
            case "Waiting":
              setNotificationData("/waiting", "WAITING");
              break;
            case "Appointment":
              setNotificationData(`/appointment/details/${wsdata?.data?.appointmentId}`, "APPOINTMENT");
              break;
            case "StaffAvailability":
              (userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) &&
                dispatch(addTeamDetails({ userId: wsdata?.data?.staffId }));
              setNotificationData(
                (userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) ? "/team/details/availability" : "/availability", "STAFF AVAILABILITY");
              break;
            case "StaffUnAvailabilityCreated":
              (userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) &&
                dispatch(addTeamDetails({ userId: wsdata?.data?.staffId }));
              setNotificationData(
                (userdata?.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle.FRONT_DESK) ? "/team/details/unavailability" : "/availability", "STAFF UNAVAILABILITY");
              break;
            default:
              break;
          }
          showNotification(notificationData);
        }

        if (wsdata?.eventType === "receivedMessage") {
          const chatNotification = {
            type: "CHAT",
            routes: "/chat",
            message: wsdata?.data?.message,
          };
          // Use the pathnameRef to check the current pathname
          if (pathnameRef.current !== "/chat") {
            showNotification(chatNotification);
          }
        }
      };

      ws.onerror = () => {
        console.error('WebSocket error');
        if (userdata?.token) {
          attemptReconnect()
        }
      };

      ws.onclose = () => {
        console.log('WebSocket onclose')
        if (userdata?.token) {
          attemptReconnect()
        }
      };
    };
    const attemptReconnect = () => {
      clearInterval(reconnectInterval);
      reconnectInterval = setInterval(() => {
        if (ws?.readyState === WebSocket?.CLOSED) {
          connectWebSocket();
        } else {
          clearInterval(reconnectInterval);
        }
      }, 5000);
    };
    connectWebSocket();
    getListNotification();
    return () => {
      if (ws) {
        ws?.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //


  const archiveUnarchive = (type) => {
    if (type === "YES") {
      logout()
    } else {
      setShow(false)
    }
  }
  const closeOffcanvas = () => {
    setPhoneShow(false)
  }
  const ProviderImage = userImageFilePath + userdata?.businessProfileImage
  const teamImage = TeamImageFilePath + userdata?.profileImage
  const BusinessName = userdata?.businessName
  const teamName = userdata.firstName + " " + userdata.lastName
  const clinicName = userdata?.location?.clinicName
  return (
    <>
      <ToastContainer />
      {loading && <Loading />}
      <div className="phone_hide tab_hide">
        <nav className="navbar fixed-top shadow" style={{ backgroundColor: "#fff" }}>
          <div className="container-fluid">
            <img src={logo} width="62px" height="50px" alt="" />
            <ul className="d-flex m-0 p-0 align-items-center">
              <li className="mt-2">
                <Badge anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }} badgeContent={notificationcount} color="error" style={{ cursor: "pointer" }} onClick={() => navigete("/notifications")} >
                  <IoIosNotificationsOutline style={{ fontSize: "30px" }} />
                </Badge>
              </li>
              <li>
                <Avatar
                  className="cursor"
                  onClick={handleClick}
                  src={((userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) && userdata?.profileImage) ? teamImage : (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER && userdata?.businessProfileImage) ? ProviderImage : userDefaultImage}
                  sx={{ width: 40, height: 40 }}
                />
              </li>
              <li className="HEADER_PROFILE" >
                <div className="d-flex flex-column">
                  {(userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ?
                    <p className="mb-0" onClick={handleClick}>{teamName?.slice(0, 10) + (teamName?.length > 10 ? "..." : "")}</p>
                    : <p className="mb-0" onClick={handleClick}>{BusinessName?.slice(0, 10) + (BusinessName?.length > 10 ? "..." : "")}</p>
                  }
                  <p className="m-0 LOCATION">{clinicName?.slice(0, 10) + (clinicName?.length > 10 ? "..." : "")}</p>
                </div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={OPEN ? 'long-menu' : undefined}
                  aria-expanded={OPEN ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <IoIosArrowDown />
                </IconButton>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                  }}
                  anchorEl={anchorEl}
                  open={OPEN}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => { handleClose(); navigete("/profile") }} >
                    Profile
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigete('/setting') }} >
                    Settings
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); setShow(true) }} >
                    Logout
                  </MenuItem>
                </StyledMenu>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="phone_show web_hide tab_show">
        <nav className="navbar fixed-top shadow" style={{ backgroundColor: "#fff" }}>
          <div className="container-fluid">
            <img src={logo} width="62px" height="50px" alt="" />
            <ul className="d-flex m-0 p-0 align-items-center">
              <li>
                <MenuIcon
                  className="cursor"
                  // onClick={handleClick}
                  onClick={() => setPhoneShow(true)}
                />
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {
        userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK ?
          <div className={`sidebar close phone_hide tab_hide`}>
            <ul className="nav-links shadow">
              <li className="meun_hover ">
                <NavLink to="#">
                  <i className='bx bx-menu' onClick={() => {
                    document.querySelector(".sidebar").classList.toggle("close")
                  }}></i>
                </NavLink>
              </li>
              <li className={`${splitLocation[1] === "dashboard" ? "isactive" : ""} close_line`}>
                <NavLink to="/dashboard">
                  <i className='bx bx-grid-alt'></i>
                  <span className="link_name">Dashboard</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/dashboard">Dashboard</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "appointment" ? "isactive" : ""}   ${open.Appointments ? "showMenu" : ""}`}>
                <div className="iocn-link " onClick={() => showhide("Appointments")}>
                  <NavLink to="#" >
                    <i className='bx bx-calendar-week' ></i>
                    <span className="link_name">Appointments</span>
                  </NavLink>
                  <i className='bx bxs-chevron-down arrow '></i>
                </div>
                <ul className={`  sub-menu hovermain `}>
                  <li><NavLink className="link_name " to="#">Appointments</NavLink></li>
                  <li onClick={() => document.title = "Appointment"} className={`${pathname === "/appointment/list" ? "isactive" : ""}  d-flex`}>
                    <i className={` ${getColor('/appointment/list')} bx bx-calendar-week mainhover`}></i>
                    <NavLink to="/appointment/list" className={` ${getColor('/appointment/list')} textcolor mainhover`}>
                      Appointment List
                    </NavLink>
                  </li>
                  <li onClick={() => document.title = "Appointment"} className={` d-flex`}>
                    <i className={` ${getColor('/appointment/calender')} bx bxs-calendar mainhover`}></i>
                    <NavLink style={{ color: getColor('/appointment/calender') }} to="/appointment/calender" className={` ${getColor('/appointment/calender')} textcolor mainhover`}>
                      Calendar
                    </NavLink>
                  </li>
                  <li onClick={() => document.title = "Appointment"} className={` d-flex`}>
                    <i className={` ${getColor('/appointment/today-agenda')} fa-solid fa-calendar-day mainhover`} style={{ fontSize: "21px" }}></i>
                    <NavLink style={{ color: getColor('/appointment/today-agenda') }} to="/appointment/today-agenda"
                      className={` ${getColor('/appointment/today-agenda')} textcolor mainhover`}>
                      Today Agenda
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "availability" ? "isactive" : ""} close_line`}>
                <NavLink to="/availability">
                  <i className='bx bx-time-five'></i>
                  <span className="link_name">Availability</span>
                </NavLink>
                <ul className={`${pathname === "/availability" ? "isactive" : ""} sub-menu blank`}>
                  <li><NavLink className="link_name" to="availability">Availability</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "waiting" ? "isactive" : ""} close_line`}>
                <NavLink to="/waiting">
                  <i className='bx bx-handicap'></i>
                  <span className="link_name">Waiting List</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/waiting">Waiting List</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "patients" ? "isactive" : ""} close_line`}>
                <NavLink to="/patients">
                  <i className='bx bx-hotel'></i>
                  <span className="link_name">Patients</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/patients">Patients</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "team" ? "isactive" : ""} close_line`}>
                <NavLink to="/team">
                  <i className='bx bxs-group'></i>
                  <span className="link_name">Team</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/team">Team</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "expertise" ? "isactive" : ""} close_line`}>
                <NavLink to="/expertise">
                  <i className='bx bx-donate-heart'></i>
                  <span className="link_name">Expertise</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/expertise">Expertise</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "insights" ? "isactive" : ""} close_line`}>
                <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" }) ? "/insights" : pathname}>
                  <i className='bx bx-line-chart-down'></i>
                  <span className="link_name">Insights</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" }) ? "/insights" : pathname}>Insights</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "notifications" ? "isactive" : ""} close_line`}>
                {
                  notificationcount > 0 && <div className="notification position-absolute"
                    style={{
                      backgroundColor: "#b92424",
                      height: "15px ",
                      width: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      padding: "9px",
                      color: "#fff",
                      left: "40px",
                      top: "5px",
                      fontSize: "10px",
                      border: "2px solid #b92424",
                      fontWeight: "600"

                    }}> {notificationcount > 99 ? "99+" : notificationcount}</div>
                }

                <NavLink to="/notifications">
                
                  <i className='bx bx-bell'>
                  </i>
                  <span className="link_name">Notifications</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/notifications">Notifications</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "offers" ? "isactive" : ""} close_line`}>
                <NavLink to="/offers">
                  <i className='bx bxs-offer'></i>
                  <span className="link_name">Offers</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/offers">offers</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "vaccination" ? "isactive" : ""} close_line`}>
                <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/vaccination" : pathname}>
                  <i className='bx bx-calendar'></i>
                  <span className="link_name">Vaccination Library</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" }) ? "/vaccination" : pathname}>Vaccination Library</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "chat" ? "isactive" : ""} close_line`}>
                <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/chat" : pathname} >
                  <i className="fa-brands fa-rocketchat"></i>
                  <span className="link_name">Chat</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" }) ? "/chat" : pathname} >Chat</NavLink></li>
                </ul>
              </li>
            </ul>
          </div> :
          <div className={`sidebar close phone_hide tab_hide cx`}>
            <ul className="nav-links">
              <li className="meun_hover ">
                <NavLink to="#">
                  <i className='bx bx-menu' onClick={() => {
                    document.querySelector(".sidebar").classList.toggle("close")
                  }}></i>
                </NavLink>
              </li>
              <li className={`${splitLocation[1] === "dashboard" ? "isactive" : ""} close_line`}>
                <NavLink to="/dashboard">
                  <i className='bx bx-grid-alt'></i>
                  <span className="link_name">Dashboard</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/dashboard">Dashboard</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "appointment" ? "isactive" : ""}   ${open.Appointments ? "showMenu" : ""}`}>
                <div className="iocn-link " onClick={() => showhide("Appointments")}>
                  <NavLink to="#" onClick={() => document.title = "Appointment"}>
                    <i className='bx bxs-calendar' ></i>
                    <span className="link_name">Appointments</span>
                  </NavLink>
                  <i className='bx bxs-chevron-down arrow '></i>
                </div>
                <ul className={`  sub-menu hovermain `}>
                  <li><NavLink className="link_name " to="#">Appointments</NavLink></li>
                  <li onClick={() => document.title = "Appointment"} className={`${pathname === "/appointment/list" ? "isactive" : ""}  d-flex`}>

                    <i className={` ${getColor('/appointment/list')} bx bxs-calendar mainhover`}></i>
                    <NavLink to="/appointment/list" className={` ${getColor('/appointment/list')} textcolor mainhover`}>
                      Appointment List
                    </NavLink>
                  </li>
                  <li onClick={() => document.title = "Appointment"} className={` d-flex`}>
                    <i className={` ${getColor('/appointment/calender')} bx bxs-calendar mainhover `} ></i>
                    <NavLink style={{ color: getColor('/appointment/calender') }} to="/appointment/calender" className={` ${getColor('/appointment/calender')} textcolor mainhover`}>
                      Calendar
                    </NavLink>
                  </li>
                  <li onClick={() => document.title = "Appointment"} className={` d-flex`}>
                    <i className={` ${getColor('/appointment/today-agenda')} fa-solid fa-calendar-day mainhover`} style={{ fontSize: "21px" }}></i>
                    <NavLink style={{ color: getColor('/appointment/today-agenda') }} to="/appointment/today-agenda"
                      className={` ${getColor('/appointment/today-agenda')} textcolor mainhover`}>
                      Today Agenda
                    </NavLink>
                  </li>

                </ul>
              </li>
              <li className={`${splitLocation[1] === "availability" ? "isactive" : ""} close_line`}>
                <NavLink to="/availability">
                  <i className='bx bx-time-five'></i>
                  <span className="link_name">Availability</span>
                </NavLink>
                <ul className={`${pathname === "/availability/staff" ? "isactive" : ""} sub-menu blank`}>
                  <li><NavLink className="link_name" to="/availability">Availability</NavLink></li>
                </ul>
              </li>
              {/* <li className={`${splitLocation[1] === "/waiting-l" ? "isactive" : ""} close_line`}> */}
              <li className={`${splitLocation[1] === "waiting" ? "isactive" : ""} close_line`}>
                <NavLink to="/waiting">
                  <i className='bx bx-handicap'></i>
                  <span className="link_name">Waiting List</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/waiting">Waiting List</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "patients" ? "isactive" : ""} close_line`}>
                <NavLink to="/patients">
                  <i className='bx bx-hotel'></i>
                  <span className="link_name">Patients</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/patients">Patients</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "notifications" ? "isactive" : ""} close_line`}>
                {
                  notificationcount > 0 && <div className="notification position-absolute"
                    style={{
                      backgroundColor: "#b92424",
                      height: "15px ",
                      width: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      padding: "9px",
                      color: "#fff",
                      left: "40px",
                      top: "5px",
                      fontSize: "10px",
                      border: "2px solid #b92424",
                      fontWeight: "600"

                    }}> {notificationcount > 99 ? "99+" : notificationcount}</div>
                }

                <NavLink to="/notifications">
                  <i className='bx bx-bell'>
                  </i>
                  <span className="link_name">Notifications</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to="/notifications">Notifications</NavLink></li>
                </ul>
              </li>
              <li className={`${splitLocation[1] === "chat" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/chat" : pathname}>
                  <i className="fa-brands fa-rocketchat"></i>
                  <span className="link_name">Chat</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/chat" : pathname}>Chat</NavLink></li>
                </ul>
              </li>
            </ul>
          </div>
      }
      <section className="home-section " >
        <div className="home-content">
          <Outlet />
        </div>
      </section>
      <Modal size="sm" show={show} onHide={() => setShow(false)} centered>
        <div className="modal-body text-center ">
          <MdLogout className="fs-5" style={{ color: "#000" }} />
          <h6 className=" mb-0 fs-5" style={{ color: "#000" }}>Logout </h6>
          <p className="mt-2"> Are you sure you want to logout?</p>
          <Button variant="outlined" className="me-3" onClick={() => archiveUnarchive("NO")} style={{ color: "#38C4B9", border: "1px solid #38C4B9", padding: "6px 8px", fontSize: "13px" }}>cancel</Button>
          <Button variant="contained" onClick={() => archiveUnarchive("YES")} style={{ background: "#38C4B9", padding: "6px 8px", fontSize: "13px" }}> logout</Button>
        </div>
      </Modal>

      {/* phone sidebaar start*/}
      <div className="web_hide">
        <Offcanvas show={showPhone} onHide={() => setPhoneShow(false)} responsive="lg" style={{ width: "100%" }}>
          <Offcanvas.Header style={{ backgroundColor: "#ffffff", zIndex: "1000" }} className="shadow">
            <Offcanvas.Title className="py-1">Winkdoc</Offcanvas.Title>
            <IconButton aria-label="close" onClick={closeOffcanvas}>
              <IoClose
                style={{ height: "34px", width: "34px", color: "black" }}
              />
            </IconButton>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {
              userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK ?
                <div className={`sidebar`}>
                  <ul className="nav-links ">
                    <li className={`${splitLocation[1] === "dashboard" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/dashboard">
                        <i className='bx bx-grid-alt'></i>
                        <span className="link_name">Dashboard</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/dashboard">Dashboard</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "appointment" ? "isactive" : ""}   ${open.Appointments ? "showMenu" : ""}`}>
                      <div className="iocn-link " onClick={() => showhide("Appointments")}>
                        <NavLink to="#" >
                          <i className='bx bx-calendar-week' ></i>
                          <span className="link_name">Appointments</span>
                        </NavLink>
                        <i className='bx bxs-chevron-down arrow '></i>
                      </div>
                      <ul className={`  sub-menu hovermain `}>
                        <li><NavLink className="link_name " to="#">Appointments</NavLink></li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={`${pathname === "/appointment/list" ? "isactive" : ""}  d-flex`}>
                          <i className={` ${getColor('/appointment/list')} bx bx-calendar-week mainhover`}></i>
                          <NavLink to="/appointment/list" className={` ${getColor('/appointment/list')} textcolor mainhover`}>
                            Appointment List
                          </NavLink>
                        </li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={` d-flex`}>
                          <i className={` ${getColor('/appointment/calender')} bx bxs-calendar mainhover`}></i>
                          <NavLink style={{ color: getColor('/appointment/calender') }} to="/appointment/calender" className={` ${getColor('/appointment/calender')} textcolor mainhover`}>
                            Calendar
                          </NavLink>
                        </li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={` d-flex`}>
                          <i className={` ${getColor('/appointment/today-agenda')} fa-solid fa-calendar-day mainhover`} style={{ fontSize: "21px" }}></i>
                          <NavLink style={{ color: getColor('/appointment/today-agenda') }} to="/appointment/today-agenda"
                            className={` ${getColor('/appointment/today-agenda')} textcolor mainhover`}>
                            Today Agenda
                          </NavLink>
                        </li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "availability" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/availability">
                        <i className='bx bx-time-five'></i>
                        <span className="link_name">Availability</span>
                      </NavLink>
                      <ul className={`${pathname === "/availability" ? "isactive" : ""} sub-menu blank`}>
                        <li><NavLink className="link_name" to="availability">Availability</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "waiting" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/waiting">
                        <i className='bx bx-handicap'></i>
                        <span className="link_name">Waiting List</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/waiting">Waiting List</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "patients" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/patients">
                        <i className='bx bx-hotel'></i>
                        <span className="link_name">Patients</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/patients">Patients</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "team" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/team">
                        <i className='bx bxs-group'></i>
                        <span className="link_name">Team</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/team">Team</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "expertise" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/expertise">
                        <i className='bx bx-donate-heart'></i>
                        <span className="link_name">Expertise</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/expertise">Expertise</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "insights" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" }) ? "/insights" : pathname}>
                        <i className='bx bx-line-chart-down'></i>
                        <span className="link_name">Insights</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Analytics&Insights" }) ? "/insights" : pathname}>Insights</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "notifications" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      {
                        notificationcount > 0 && <div className="notification position-absolute"
                          style={{
                            backgroundColor: "#b92424",
                            height: "15px ",
                            width: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            padding: "9px",
                            color: "#fff",
                            left: "40px",
                            top: "5px",
                            fontSize: "10px",
                            border: "2px solid #b92424",
                            fontWeight: "600"

                          }}> {notificationcount > 99 ? "99+" : notificationcount}</div>
                      }

                      <NavLink to="/notifications">
                        <i className='bx bx-bell'>
                        </i>
                        <span className="link_name">Notifications</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/notifications">Notifications</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "offers" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/offers">
                        <i className='bx bxs-offer'></i>
                        <span className="link_name">Offers</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/offers">offers</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "vaccination" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/vaccination" : pathname}>
                        <i className='bx bx-calendar'></i>
                        <span className="link_name">Vaccination Library</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/vaccination" : pathname}>Vaccination Library</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "chat" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" }) ? "/chat" : pathname}>
                        <i className="fa-brands fa-rocketchat fs-4"></i>
                        <span className="link_name">Chat</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "Chat" }) ? "/chat" : pathname}>Chat</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "profile" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/profile">
                        {/* <i className="fa-brands fa-rocketchat"></i> */}
                        <i className="fa fa-users fs-5"></i>
                        <span className="link_name">Profile</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/profile">Profile</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "setting" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/setting">
                        <i className="fa fa-cog fs-5"></i>
                        <span className="link_name">Settings</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/setting">Settings</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "logout" ? "isactive" : ""} close_line`} onClick={() => { handleClose(); setShow(true) }} >
                      <NavLink to="/setting">
                        <i className='bx bx-log-out fs-3' style={{ fontSize: "30px", color: "gray" }} ></i>
                        <span className="link_name">Logout</span>
                      </NavLink>
                      {/* <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/setting">Logout</NavLink></li>
                      </ul> */}
                    </li>
                  </ul>
                </div> :
                <div className={`sidebar`}>
                  <ul className="nav-links">
                    <li className={`${splitLocation[1] === "dashboard" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/dashboard">
                        <i className='bx bx-grid-alt'></i>
                        <span className="link_name">Dashboard</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/dashboard">Dashboard</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "appointment" ? "isactive" : ""}   ${open.Appointments ? "showMenu" : ""}`} >
                      <div className="iocn-link " onClick={() => showhide("Appointments")}>
                        <NavLink to="#" onClick={() => document.title = "Appointment"}>
                          <i className='bx bxs-calendar' ></i>
                          <span className="link_name">Appointments</span>
                        </NavLink>
                        <i className='bx bxs-chevron-down arrow '></i>
                      </div>
                      <ul className={`  sub-menu hovermain `}>
                        <li><NavLink className="link_name " to="#">Appointments</NavLink></li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={`${pathname === "/appointment/list" ? "isactive" : ""}  d-flex`}>

                          <i className={` ${getColor('/appointment/list')} bx bxs-calendar mainhover`}></i>
                          <NavLink to="/appointment/list" className={` ${getColor('/appointment/list')} textcolor mainhover`}>
                            Appointment List
                          </NavLink>
                        </li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={` d-flex`}>
                          <i className={` ${getColor('/appointment/calender')} bx bxs-calendar mainhover `} ></i>
                          <NavLink style={{ color: getColor('/appointment/calender') }} to="/appointment/calender" className={` ${getColor('/appointment/calender')} textcolor mainhover`}>
                            Calendar
                          </NavLink>
                        </li>
                        <li onClick={() => { document.title = "Appointment"; closeOffcanvas() }} className={` d-flex`}>
                          <i className={` ${getColor('/appointment/today-agenda')} fa-solid fa-calendar-day mainhover`} style={{ fontSize: "21px" }}></i>
                          <NavLink style={{ color: getColor('/appointment/today-agenda') }} to="/appointment/today-agenda"
                            className={` ${getColor('/appointment/today-agenda')} textcolor mainhover`}>
                            Today Agenda
                          </NavLink>
                        </li>

                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "availability" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/availability">
                        <i className='bx bx-time-five'></i>
                        <span className="link_name">Availability</span>
                      </NavLink>
                      <ul className={`${pathname === "/availability/staff" ? "isactive" : ""} sub-menu blank`}>
                        <li><NavLink className="link_name" to="/availability">Availability</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "waiting" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/waiting">
                        <i className='bx bx-handicap'></i>
                        <span className="link_name">Waiting List</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/waiting">Waiting List</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "patients" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/patients">
                        <i className='bx bx-hotel'></i>
                        <span className="link_name">Patients</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/patients">Patients</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "notifications" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      {
                        notificationcount > 0 && <div className="notification position-absolute"
                          style={{
                            backgroundColor: "#b92424",
                            height: "15px ",
                            width: "15px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            padding: "9px",
                            color: "#fff",
                            left: "40px",
                            top: "5px",
                            fontSize: "10px",
                            border: "2px solid #b92424",
                            fontWeight: "600"

                          }}> {notificationcount > 99 ? "99+" : notificationcount}</div>
                      }

                      <NavLink to="/notifications">
                        <i className='bx bx-bell'>
                        </i>
                        <span className="link_name">Notifications</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/notifications">Notifications</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "chat" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/chat" : pathname}>
                        <i className="fa-brands fa-rocketchat"></i>
                        <span className="link_name">Chat</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to={featurePermission({ permission: userdata?.planDetails?.addOnArray, userdata: userdata, section: "VaccinationCalendar" }) ? "/chat" : pathname}>Chat</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "profile" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/profile">
                        {/* <i className="fa-brands fa-rocketchat"></i> */}
                        <i className="fa fa-users fs-5"></i>
                        <span className="link_name">Profile</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/profile">Profile</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "setting" ? "isactive" : ""} close_line`} onClick={closeOffcanvas}>
                      <NavLink to="/setting">
                        <i className="fa fa-cog fs-5"></i>
                        <span className="link_name">Settings</span>
                      </NavLink>
                      <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/setting">Settings</NavLink></li>
                      </ul>
                    </li>
                    <li className={`${splitLocation[1] === "logout" ? "isactive" : ""} close_line`} onClick={() => { handleClose(); setShow(true) }} >
                      <NavLink to="/setting">
                        <i className='bx bx-log-out fs-3' style={{ fontSize: "30px", color: "gray" }} ></i>
                        <span className="link_name">Logout</span>
                      </NavLink>
                      {/* <ul className="sub-menu blank">
                        <li><NavLink className="link_name" to="/setting">Logout</NavLink></li>
                      </ul> */}
                    </li>
                  </ul>
                </div>
            }
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {/* phone sidebaar end*/}
    </>
  );
};

export default Private;
