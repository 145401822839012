import React, { useEffect, useState } from 'react'
import "../../../styles/CreditCrad.css"
import { Modal } from 'react-bootstrap'
import { loadStripe } from '@stripe/stripe-js';
import {
    Elements, useStripe, useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from '@stripe/react-stripe-js';
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import DebitCard from '../../../Component/DebitCard';
import { toast } from 'react-toastify';
import { ADD_DEBIT_CARD, CHANGE_AUTOMATED_PAYMENT_STATUS, CHANGE_DEFAULT_CARD, DEBIT_CARD_DELETE, DEBIT_CARD_LIST, GET_AUTOMATED_PAYMENT_STATUS } from '../../Repository/SettingRepo';
import useReduxData from '../../../Hooks/useReduxData';
import Loading from '../../../Component/Loading';
import { Button, ButtonGroup } from '@mui/material';
import { stripeKey } from '../../../Context/envoirment';
const stripePromise = loadStripe(stripeKey);
const PaymentSetting = () => {
    const { CustomButton } = useMuiCompontent()
    const { reduxData: UserData } = useReduxData("userDataRDX")
    const [addCard, setAddCard] = useState(false)
    const [isAutomatedPayment, setIsAutomatedPayment] = useState(false)
    const [deleteCard, setDeleteCard] = useState({
        open: false,
        data: null
    })
    const [cardList, setCardList] = useState([])
    const [loader, setLoader] = useState(false)


    const getCardList = async () => {
        setLoader(true)
        let payload = {
            userId: UserData?.userId,

        }
        try {
            let response = await DEBIT_CARD_LIST(payload)
            setCardList(response?.data)
            setDeleteCard({ open: false, data: null })
        } catch (error) {
            setCardList([])
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }
    const getAutomatedPaymentStatus = async () => {
        setLoader(true)
        let payload = {
            userId: UserData?.userId,

        }
        try {
            let response = await GET_AUTOMATED_PAYMENT_STATUS(payload)
            setIsAutomatedPayment(response?.data?.automatedPaymentStatus)
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }



    const handleDeleteCard = async () => {
        setLoader(true)
        let payload = {
            userId: UserData?.userId,
            paymentMethodId: deleteCard?.data
        }
        try {
            let response = await DEBIT_CARD_DELETE(payload)
            toast.success(response?.message)
            getCardList()
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }
    const handleChangeDefaultCard = async (IsActiveCard, cardToken) => {
        setLoader(true)
        let payload = {
            userId: UserData?.userId,
            paymentMethodId: cardToken,
            isDefault: !IsActiveCard
        }
        try {
            let response = await CHANGE_DEFAULT_CARD(payload)
            toast.success(response?.message)
            getCardList()
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }
    const automatedPayment = async (isactive) => {
        if (isAutomatedPayment === isactive) return
        setLoader(true)
        let payload = {
            userId: UserData?.userId,
            activeStatus: isactive,
        }
        try {
            let response = await CHANGE_AUTOMATED_PAYMENT_STATUS(payload)
            toast.success(response?.message)
            getAutomatedPaymentStatus()
        } catch (error) {
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }




    const CardForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const [isLoading, setIsLoading] = useState(false)
        const [cardDetails, setCardDetails] = useState({
            brand: '',
            last4: '',
            exp_month: '',
            exp_year: '',
            holder_name: "",
        });

        const handleCardChange = (event) => {
            if (event.complete) {
                const cardElement = elements.getElement(CardNumberElement);
                stripe.createPaymentMethod({ type: 'card', card: cardElement, }).then(({ paymentMethod }) => {
                    if (paymentMethod) {
                        setCardDetails((pre) => (
                            {
                                ...pre,
                                brand: paymentMethod.card.brand,
                                last4: paymentMethod.card.last4,
                                exp_month: paymentMethod.card.exp_month,
                                exp_year: paymentMethod.card.exp_year,
                            }
                        ));
                    }
                });
            } else {
                setCardDetails((pre) => (
                    {
                        ...pre,
                        brand: '',
                        last4: '',
                        exp_month: '',
                        exp_year: '',
                    }
                ));
            }
        };
        const handleSubmit = async (event) => {
            event.preventDefault();
            setIsLoading(true);
            if (!stripe || !elements) {
                toast.error('Stripe has not loaded yet.');
                setIsLoading(false);
                return;
            }
            if (!cardDetails?.holder_name) {
                setIsLoading(false);
                return toast.error("Please enter holder name")
            }
            const cardElement = elements.getElement(CardNumberElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: cardDetails?.holder_name,
                },
            });
            if (error) {
                toast.error(error.message);
                setIsLoading(false);
                return;
            }
            let payload = {
                paymentMethodId: paymentMethod.id,
                userId: UserData?.userId,
            };
            try {
                let response = await ADD_DEBIT_CARD(payload)
                setAddCard(false)
                toast.success(response.message);
                setCardDetails({
                    brand: '',
                    last4: '',
                    exp_month: '',
                    exp_year: '',
                    holder_name: "",
                })
                getCardList()
            } catch (error) {
                toast.error(error.message)
            }
            setIsLoading(false);
        };

        return (
            <form onSubmit={handleSubmit} className="row d-flex justify-center ADD_CARD_FORM">
                <div className="col-12">
                    <div className="row">
                        <div className='col-12 d-flex justify-content-center mb-3 px-5'>
                            <DebitCard cardDetails={cardDetails} />
                        </div>
                        <div className="col-12">
                            Name
                            <input type="text" maxLength={40} className="form-control" name="street" placeholder="Holder name " value={cardDetails?.holder_name}
                                onChange={(e) => setCardDetails((pre) => ({ ...pre, holder_name: e.target.value }))}
                            />
                        </div>
                        <div className="col-12 mt-3">
                            Card number
                            <CardNumberElement
                                onChange={handleCardChange}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            Expiration date
                            <CardExpiryElement
                                onChange={handleCardChange}
                            />
                        </div>
                        <div className="col-6 mt-3">
                            CVC
                            <CardCvcElement
                                onChange={handleCardChange}
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-end pt-3">
                            <CustomButton variant="contained" type="submit" style={{ textTransform: "capitalize" }} disabled={!stripe || isLoading} >  {isLoading ? 'Processing...' : 'Submit'}</CustomButton>
                        </div>
                    </div>
                </div>
            </form>
        );
    };

    useEffect(() => {
        getCardList()
        getAutomatedPaymentStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {loader && <Loading />}
            <div className='p-2 pt-3'>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled elevation buttons"
                            className='me-3'
                        >
                            <Button className="w-50" variant={`${isAutomatedPayment === false ? "contained" : " outlined"}`} style={isAutomatedPayment === false ? { background: "#38C4B9", padding: "5px 8px", fontSize: "13px", textTransform: "capitalize", whiteSpace: "nowrap" } : { color: "#38C4B9", border: "1px solid #38C4B9", padding: "5px 8px", fontSize: '13px', textTransform: "capitalize", whiteSpace: "nowrap" }} onClick={() => { automatedPayment(false) }}>Deactive Automated Patment</Button>
                            <Button className="w-50" variant={`${isAutomatedPayment === true ? "contained" : " outlined"}`} style={isAutomatedPayment === true ? { background: "#38C4B9", padding: "5px 8px", fontSize: "13px", textTransform: "capitalize", whiteSpace: "nowrap" } : { color: "#38C4B9", border: "1px solid #38C4B9", padding: "5px 8px", fontSize: '13px', textTransform: "capitalize", whiteSpace: "nowrap" }} onClick={() => { automatedPayment(true) }} >Active Automated Patment</Button>
                        </ButtonGroup>
                        <CustomButton variant="contained" type="button" style={{ textTransform: "capitalize" }} onClick={() => setAddCard(true)}   >Add card</CustomButton>
                    </div>
                </div>
                <div className=" row">
                    {
                        cardList?.map((cur, index) => {
                            return <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6 p-2">
                                <div className="col">
                                    <DebitCard key={index} cardDetails={{
                                        brand: cur?.brand,
                                        last4: cur?.last4,
                                        exp_month: cur?.expMonth,
                                        exp_year: cur?.expYear,
                                        holder_name: cur?.name
                                    }}
                                        IsActiveCard={cur?.default}
                                        isList={true}
                                        setDeleteCard={setDeleteCard}
                                        cardToken={cur?.cardToken}
                                        handleChangeDefaultCard={handleChangeDefaultCard}
                                    />
                                </div>
                            </div>
                        })
                    }
                </div>
            </div>
            <Modal show={addCard} onHide={() => { setAddCard(false) }} centered>
                <div className=" p-3">
                    <Elements stripe={stripePromise}>
                        <CardForm />
                    </Elements>
                </div>
            </Modal>
            <Modal show={deleteCard?.open} onHide={() => setDeleteCard({ open: false, data: null })} centered>
                <Modal.Body>
                    <div className="modal-body text-center">
                        <i className="fa-solid fa-trash-can all_icons_color" />
                        <p className="mb-0">Are you sure? </p>
                        <p>You want to delete this card.</p>
                        <CustomButton variant="contained" className="me-3" onClick={handleDeleteCard} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => setDeleteCard({ open: false, data: null })} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentSetting