import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { businesscDetailsValidation } from "../../../../Context/Validation";
import { deleteclinic, } from "../../../../Context/api";
import axios from "axios";
import { toast } from "react-toastify";
import { commonMessages } from "../../../../Context/constants";
import useDisableBrowserBackButton from "../../../../Hooks/useDisableBrowserBackButton";
import useReduxData from "../../../../Hooks/useReduxData";
import { AddBusinessDetails } from "../../../../Redux/ReducerFile/BusinessDetails";
import useUploadImage from "../../../../Hooks/useUploadImage";
import { BUSINESS_CATEGORY, CLINIC_LIST, INSERT_BUSINESS_DETAILS } from "../../../Repository/ProfileRepo";
import { AddBasicDetails } from "../../../../Redux/ReducerFile/BasicDetails";

const useBusinessDetails = () => {
  const [imgUrl, setImgUrl] = useState('');
  const navigate = useNavigate();
  const [clinicList, setClinicList] = useState([])
  const [loader, setLoader] = useState(false)
  const [clinicDelete, SetclinicDetele] = useState(false)
  const [Delete, setDelete] = useState({});
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedCount] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryList, setCategoryList] = useState([])
  useDisableBrowserBackButton()
  const { reduxData: BusinessDetailsData, dispatch } = useReduxData("BusinessDetailsRDX")
  const userData = JSON.parse(localStorage.getItem("S_userdata"))
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const { UploadImage } = useUploadImage("PROVIDER", setLoader, setImgUrl)
  const FileRef = useRef()
  // get category  list 
  const getCategoryList = async () => {
    setLoader(true)
    const payload = {
      activeStatus: true,
    };
    try {
      let response = await BUSINESS_CATEGORY(payload)
      setCategoryList(response.data)
      setLoader(false)
    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      businessName: "",
      businessCategoryId: "",
      about: "",
    },
    validationSchema: businesscDetailsValidation,
    onSubmit: async (values) => {
      if (!clinicList.length) {
        return toast.warn("Please Add Clinic")
      }
      let data = {
        providerId: userData.userId,
        businessName: values.businessName,
        about: values.about,
        image: imgUrl,
        businessCategoryId: values.businessCategoryId,
        businessProfileId: values?.businessProfileId
      };
      setLoader(true)
      try {
        let response = await INSERT_BUSINESS_DETAILS(data)
        toast.success(response?.message);
        setTimeout(() => {
          localStorage.removeItem("S_userdata");
          dispatch(AddBusinessDetails({}))
          dispatch(AddBasicDetails({}));
          // dispatch(addUserData(res.data.data[0]))
          navigate('/')
        }, 1200)
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }

      // 
    },
  });

  //  get provider clinic clist 
  const getListproviderclinics = async (newPage) => {
    const payload = {
      activeStatus: null,
      page: newPage,
      count: selectedCount,
      providerId: userData?.userId,
    }
    try {
      let response = await CLINIC_LIST(payload)
      setClinicList((pre) => [...pre, ...response.data])
      let totalPages = Math.ceil(response.totalCount / selectedCount)
      setTotalPages(totalPages)
    } catch (error) {
      setClinicList([])
    }
  }

  // delete clinic
  const handleClose = (event, ids) => {
    if (event === "YES") {
      setLoader(true)
      axios.post(deleteclinic(), { clinicId: Delete.id, userId: userData?.userId }).then((response) => {
        if (response.data.status === "200") {
          toast.success(response.data.message)
          SetclinicDetele(false)
          setClinicList((pre) => {
            let newClinicList = [...pre]
            let indexClinic = newClinicList?.findIndex((cur) => cur?.clinicId === Delete.id)
            newClinicList.splice(indexClinic, 1)
            return newClinicList
          })
        } else if (response.data.status === "500") {
          toast.error(commonMessages.NetworkError);
        } else {
          toast.error(response.data.message)
          SetclinicDetele(false)
        }
        setLoader(false)
      }).catch(() => {
        toast.error(commonMessages.NetworkError)
        setLoader(false)
      })
    } else {
      SetclinicDetele(false)
    }
  }
  const handleScroll = (e) => {
    const target = e.target;
    if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
      if (selectedPage !== totalPages) setSelectedPage((prevPage) => prevPage + 1);
    }
  };
  useEffect(() => {
    if (clinicList.length === 0 && selectedPage === 1) {
      getListproviderclinics(selectedPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  useEffect(() => {
    if (Object.entries(BusinessDetailsData)?.length !== 0) {
      setImgUrl(BusinessDetailsData?.imgUrl)
      formik.setValues(BusinessDetailsData)
    } else {
      formik.setValues({
        businessName: userData?.businessName,
        businessCategoryId: userData?.businessCategoryId,
        about: userData?.businessAbout,
        businessProfileId: userData?.businessProfileId
      })
      setImgUrl(userData?.businessImage)
    }
    getCategoryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(AddBusinessDetails({ ...formik.values, imgUrl }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, imgUrl])
  return {
    splitLocation,
    loader,
    formik,
    clinicDelete,
    SetclinicDetele,
    handleClose,
    navigate,
    dispatch,
    clinicList,
    handleScroll,
    setDelete,
    imgUrl,
    setImgUrl,
    userData,
    FileRef,
    UploadImage,
    setLoader,
    categoryList
  }
}

export default useBusinessDetails
