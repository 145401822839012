import React, { useCallback, useEffect, useRef, useState } from 'react'
import Sidebar from './Sidebar'
import "../../styles/Chat.css"
import Chatbox from './MainChat'
import { Modal } from 'react-bootstrap'
import { Avatar, Badge, IconButton, styled } from "@mui/material";
import { MdOutlineCameraAlt } from 'react-icons/md'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import useReduxData from '../../Hooks/useReduxData'
import { toast } from 'react-toastify'
import { NotificationsState } from '../../App'
import { useContext } from "react";
import { ADD_AND_REMOVE_ADMIN_ACCESS, ADD_NEW_CONVERSATION, ADD_NEW_MEMBER_TO_GROUP, BLOCK_USER, CONVERSATION_LIST, DELETE_CONVERSATION, GET_ALL_MESSAGE_LIST, GET_GROUP_DETAILS, GET_LAST_MESSAGE, LEAVE_GROUP, READ_MESSAGE, STAFF_LIST, UPDATE_GROUP_INFO, UPDATE_GROUP_SETTING } from '../Repository/ChatRepo'
import Loading from '../../Component/Loading'
import { ChatGroupFilePath, PatientImageFilePath, TeamImageFilePath, userImageFilePath } from '../../Context/api'
import { RoleTitle } from '../../Context/constants'
import useUploadImage from '../../Hooks/useUploadImage'
import { PATIENT_LIST } from '../Repository/PatientRepo'
import moment from 'moment-timezone'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { MdArrowBackIos } from "react-icons/md";
import useWindowWidth from '../../Hooks/useWindowWidth'
import DeleteProfile from '../../Component/DeleteProfile'
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  color: "#fff"
}));

const Chat = () => {
  // const [Tab, setTab] = useState("STAFF")
  const [sendMessage, setSendMessage] = useState('');
  const [conversationList, setConversationList] = useState([])
  const [totalConversationListPages, setTotalConversationListPages] = useState(1);
  const [selectedConversationPage, setSelectedConversationPage] = useState(1);
  const [seachConversationValue, setSeachConversationValue] = useState("")
  const [hasEmptiedOnce, setHasEmptiedOnce] = useState(false);
  const [userInfo, setUserInfo] = useState(null)
  const [newStaffList, setNewStaffList] = useState([])
  const [newGroupList, setNewGroupList] = useState([])
  const [patientList, setPatientList] = useState([])
  const [GroupPicture, setGroupPicture] = useState(null)
  const [groupName, setGroupName] = useState("")
  const [seletedGroupMember, setSeletedGroupMember] = useState([])
  const [attchament, setAttchament] = useState(null)
  const [filter, setFilter] = useState("")
  const [loader, setLoader] = useState(false)
  const [messageList, setMessageList] = useState({})
  const [editMessage, setEditMessage] = useState(null)
  const [groupDetails, setGroupdetails] = useState(null)
  const [addParticipant, setAddParticipant] = useState(false)
  const [isEditGroup, setIsEditGroup] = useState(false)
  const [messageNotification, setMessageNotification] = useState({
    STAFF: false,
    GROUP: false,
    PATIENT: false,
  })
  const [openModal, setOpenModal] = useState({
    STAFF: false,
    GROUP: false,
    PATIENT: false,
    GROUP_INFO: false,
    data: null
  })
  const { GreenSwitch, CustomButton } = useMuiCompontent()
  const { socket, } = useContext(NotificationsState)
  const { reduxData: UserData, } = useReduxData("userDataRDX")
  const { windowWidth } = useWindowWidth();
  const { UploadImage } = useUploadImage()
  const conversationlistRaf = useRef()
  const groupImageRaf = useRef()
  const Tab = useRef("STAFF")
  const userInfoRef = useRef(userInfo)

  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);
  console.log(userInfoRef,"userInfoRef")
  const handleChangeTab = (type) => {
    if (Tab.current !== type) {
      Tab.current = type
      setConversationList([])
      setHasEmptiedOnce(false)
      setSeachConversationValue("")
      setFilter("")
      setUserInfo(null)
      setAttchament(null)
      setMessageList({})
      setEditMessage(null)
      setMessageNotification((pre) => ({ ...pre, [type]: false }))
    }
  }
  //------------------------------------------------------------------ get all message-----------------------------------------------------
  const getALLMessage = async (data) => {
    let payload = {
      clinicId: UserData?.location?.clinicId,
      loggedInUserId: UserData?.userId,
      conversationType: data?.conversationType,
      roleTitle: UserData?.roleTitle,
      count: 100,
      page: 1,
      conversationId: data?.conversationId,
      nowTime: moment().utc(),

    }
    try {
      let response = await GET_ALL_MESSAGE_LIST(payload)
      setMessageList(response.data)
    } catch (error) {
      setMessageList({})
      toast.error(error.message)
    } finally {
    }
  }
  //------------------------------------------------------------------ get last message-----------------------------------------------------
  console.log(Tab.current, 'TabTab')
  const getLastMessage = async (data) => {
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationId: data?.conversationId,
      conversationType: data?.conversationType,
      roleTitle: UserData?.roleTitle,
      senderId: data?.senderId,
      conversationOpened: socket.readyState === WebSocket.OPEN ? true : false,
      nowTime: moment().utc(),
      convOpened: data?.conversationId === userInfoRef.current?.conversationId ? true : false,
      messageId: data?.messageId
    }
    // setLoader(true)
    try {
      let response = await GET_LAST_MESSAGE(payload)
      const lastMessage = response.data.lastMessages[0];
      console.log(lastMessage, "lastMessage")
      setMessageNotification((pre) => {
        let preData = { ...pre }
        console.log(Tab.current, "TabTabTab")
        console.log(response?.data?.conversationType, "response?.data?.conversationTyperesponse?.data?.conversationType")
        if (response?.data?.conversationType !== Tab.current) {
          preData[response.data.conversationType] = true
        }
        return preData
      })
      if (data?.conversationType === Tab.current) {
        console.log(data?.conversationType, "data?.conversationType")
        console.log(Tab.current, 'Tab')
        // setConversationList((pre) => (pre?.map((cur, index) => {
        //   if (cur?.conversationId === response?.data?.conversationId) {
        //     console.log(cur?.conversationId, "cur?.conversationId")
        //     console.log(response?.data?.conversationId, "response?.data?.conversationId")
        //     return {
        //       ...cur,
        //       lastMessage,
        //       unReadMessages: userInfo?.conversationId ? 0 : response.data.unReadMessages
        //     }
        //   }
        //   return cur
        // })))

        setConversationList((pre) => {
          let conversationExists = false;
          const updatedList = pre?.map((cur) => {
            if (cur?.conversationId === response?.data?.conversationId) {
              conversationExists = true;
              return {
                ...cur,
                lastMessage,
                unReadMessages: userInfoRef.current?.conversationId ? 0 : response.data.unReadMessages,
              };
            }
            return cur;
          });
          if (!conversationExists && !data?.activeStatus) {
            let newCoversation = {
              conversationType: response?.data?.conversationType,
              image: null,
              unReadMessages: response?.data?.unReadMessages,
              blockedStatus: response?.data?.blockedStatus,
              activeStatus: true,
              conversationId: response?.data?.conversationId,
              mutedStatus: response?.data?.mutedStatus,
              name: response?.data?.name,
              lastMessage: {
                messageTime: lastMessage?.messageTime,
                clearTime: null,
                messageId: lastMessage?.messageId,
                message: lastMessage?.message,
                editedStatus: lastMessage?.editedStatus,
                deletedStatus: lastMessage?.deletedStatus
              },
              userDetails: {
                ...lastMessage?.senderDetails, userId: lastMessage?.senderDetails?.id
              }
            }

            updatedList.unshift(newCoversation);
          }

          return updatedList;
        });

        if (userInfoRef.current) {
          setMessageList((pre) => {
            let preData = { ...pre }
            if (userInfoRef.current?.conversationId === response?.data?.conversationId) {
              preData?.messagesData?.push(lastMessage)
            }
            return preData
          })
        }
      }

      // setLoader(false)
    } catch (error) {
      toast.error(error.message)
    }
  }

  //------------------------------------------------------------------ conversation list start-----------------------------------------------------
  //conversation list
  const getConversationList = async (newPage) => {
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationType: Tab.current,
      roleTitle: UserData?.roleTitle,
      keyword: seachConversationValue?.trim(),
      // count: 10,
      // page: newPage,
    }
    setLoader(true)
    try {
      let response = await CONVERSATION_LIST(payload)
      setConversationList((pre) => [...pre, ...response.data])
      let totalPages = Math.ceil(response.totalCount / 10)
      setTotalConversationListPages(totalPages)
      setLoader(false)
    } catch (error) {
      setConversationList([])
      toast.error(error.message)
    } finally {
      setLoader(false)
    }
  }
  // get conversation data on scroll
  const handleScrollConversationList = (e) => {
    const target = e.target;
    if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
      if (selectedConversationPage !== totalConversationListPages) {
        setSelectedConversationPage((prevPage) => {
          const newPage = prevPage + 1;
          getConversationList(newPage);
          setLoader(true)
          return newPage;
        });
      }
    }
  };
  // search conversation user
  const handleSearchConvertaion = (e) => {
    setSeachConversationValue(e.target.value)
    setHasEmptiedOnce(true)
  }
  // call conversation list api when we tab change and first time page rerander comp
  useEffect(() => {
    if (conversationList?.length === 0) {
      getConversationList(selectedConversationPage)
    }
    setMessageNotification((pre) => {
      let preData = { ...pre }
      preData[Tab.current] = false
      return preData
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tab.current]);

  // call conversation list api on search input 
  useEffect(() => {
    if (hasEmptiedOnce) {
      const getData = setTimeout(() => {
        getConversationList(1)
        setConversationList([])
      }, 800)
      return () => clearTimeout(getData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seachConversationValue])

  //------------------------------------------------------------------  add staff start-----------------------------------------------------
  // get list staff for new add staff
  const getnewStaff = async () => {
    setLoader(true)
    const payload = {
      clinicId: UserData?.location?.clinicId,
      loggedInUserId: UserData?.userId,
      providerId: (UserData?.roleTitle === RoleTitle.STAFF || UserData?.roleTitle === RoleTitle.FRONT_DESK) ? UserData?.providerId : undefined, // send when roleTitle is STAFF
      roleTitle: UserData?.roleTitle,
    };
    try {
      let response = await STAFF_LIST(payload)
      setNewStaffList(response.data)
      setLoader(false)

    } catch (error) {
      setLoader(false)
      setNewStaffList([])
    }
  }
  // add new conversation staff 
  const addNewConversationStaff = async (data) => {
    setLoader(true)
    const payload = {
      image: data?.image,
      loggedInUserId: UserData?.userId,
      memberArray: [
        {
          userId: data?.userId,
          roleTitle: data?.roleTitle
        }
      ],
      name: data?.firstName,
      conversationType: Tab.current,
      roleTitle: UserData?.roleTitle,
    };
    try {
      let response = await ADD_NEW_CONVERSATION(payload)
      toast.success(response?.message)
      handleClosedModal("STAFF")
      setConversationList((pre) => (!response?.data?.alreadyExist ? [...pre, response?.data] : [...pre]))
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  // disable Button
  const disableButton = () => {
    if (messageList?.adminStatus) {
      return false
    } else if (!groupDetails?.onlyAdminCanAddOrRemove && !groupDetails?.onlyAdminUpdateInfo) {
      return false
    } else {
      return true
    }
  }
  //------------------------------------------------------------------  add new group start-----------------------------------------------------
  // get list staff for new group
  const getnewgroups = async () => {
    setLoader(true)
    const payload = {
      clinicId: UserData?.location?.clinicId,
      loggedInUserId: UserData?.userId,
      providerId: UserData?.providerId, // send when roleTitle is STAFF
      roleTitle: UserData?.roleTitle,
    };
    try {
      let response = await STAFF_LIST(payload)
      setNewGroupList(response.data)
      setLoader(false)

    } catch (error) {
      setLoader(false)
      setNewGroupList([])
    }
  }
  // choose new group member
  const handleSelectGroupMember = (e, item) => {
    const { checked } = e.target
    setSeletedGroupMember(prevSelectedItems =>
      checked ? [...prevSelectedItems, item] : prevSelectedItems.filter(selectedItem => selectedItem !== item)
    );
  }
  // add new  Group 
  const newGroupAdd = async (data) => {
    if (!groupName?.trim()) return toast.warn("Please enter group name")
    if (!seletedGroupMember.length) return toast.warn("Please choose group member")
    setLoader(true)
    const payload = {
      image: GroupPicture,
      name: groupName,
      loggedInUserId: UserData?.userId,
      memberArray: seletedGroupMember?.map((cur, index) => ({ userId: cur?.userId, roleTitle: cur?.roleTitle })),
      conversationType: Tab.current,
      roleTitle: UserData?.roleTitle,
    };
    try {
      let response = await ADD_NEW_CONVERSATION(payload)
      toast.success(response?.message)
      handleClosedModal("GROUP")
      setConversationList((pre) => ([response?.data, ...pre]))
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  //  Group  details
  const getGroupdetails = async () => {
    const payload = {
      loggedInUserId: UserData?.userId,
      conversationId: userInfoRef.current?.conversationId,
    };
    setLoader(true)
    try {
      let response = await GET_GROUP_DETAILS(payload)
      setGroupdetails(response.data)
      setGroupPicture(response?.data?.image)
      setGroupName(response?.data?.name)
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  //  add new member to group
  const addNewMemberToGroup = async (data) => {
    const payload = {
      loggedInUserId: UserData?.userId,
      conversationId: userInfoRef.current?.conversationId,
      nowTime: moment().utc(),
      userId: data?.userId,
      userRole: RoleTitle.STAFF,
    };
    setLoader(true)
    try {
      let response = await ADD_NEW_MEMBER_TO_GROUP(payload)
      toast.success(response?.message)
      setLoader(false)
      getGroupdetails()
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  // add and remove Admin Access
  const addOrRemoveAdminAccess = async (data, action) => {
    const payload = {
      loggedInUserId: UserData?.userId,
      conversationId: userInfoRef.current?.conversationId,
      nowTime: moment().utc(),
      memberId: data?.userId,
      action: action,
    };
    setLoader(true)
    try {
      let response = await ADD_AND_REMOVE_ADMIN_ACCESS(payload)
      toast.success(response?.message)
      setLoader(false)
      getGroupdetails()
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  // remove member in group list
  const handleRemoveGroup = async (data) => {
    setLoader(true)
    let payload = {
      loggedInUserId: UserData?.userId,
      conversation_id: userInfoRef.current?.conversationId,
      member_id: data?.userId,
      nowTime: moment().utc()
    }
    try {
      let response = await LEAVE_GROUP(payload)
      toast.success(response?.message)
      getGroupdetails()
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  // update admin access
  const handleUpdateAdminAccess = async (event) => {
    const { name, checked } = event.target
    setGroupdetails((pre) => ({ ...pre, [name]: checked }))
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationId: userInfoRef.current?.conversationId,
      nowTime: moment().utc(),
      onlyAdminCanAddOrRemove: groupDetails?.onlyAdminCanAddOrRemove,
      onlyAdminCanMessage: groupDetails?.onlyAdminCanMessage,
      onlyAdminUpdateInfo: groupDetails?.onlyAdminUpdateInfo,
    };
    payload[name] = checked
    setLoader(true)
    try {
      let response = await UPDATE_GROUP_SETTING(payload)
      toast.success(response?.message)
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setGroupdetails((pre) => ({ ...pre, [name]: !checked }))
      setLoader(false)
    }
  }
  // update group info
  const updateGroupInfo = async () => {
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationId: userInfoRef.current?.conversationId,
      nowTime: moment().utc(),
      image: GroupPicture,
      name: groupName,
    };
    setLoader(true)
    try {
      let response = await UPDATE_GROUP_INFO(payload)
      setGroupdetails((pre) => ({ ...pre, image: GroupPicture, name: groupName, }))
      setUserInfo((pre) => ({ ...pre, image: GroupPicture, name: groupName, }))
      setConversationList((pre) => pre?.map((cur, index) => {
        if (userInfoRef.current?.conversationId === cur?.conversationId) {
          return { ...cur, image: GroupPicture, name: groupName, }
        }
        return cur

      }))

      // conversationList, setConversationList
      // userInfo, setUserInfo
      toast.success(response?.message)
      setLoader(false)
      setIsEditGroup(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }


  const cancelGroupInfoModal = () => {
    setGroupdetails((pre) => ({ ...pre, image: GroupPicture }))
    setUserInfo((pre) => ({ ...pre, image: GroupPicture }))
    setConversationList((pre) => pre?.map((cur, index) => {
      if (userInfoRef.current?.conversationId === cur?.conversationId) {
        return { ...cur, image: GroupPicture }
      }
      return cur

    }))
  }

  //------------------------------------------------------------------  add patient start-----------------------------------------------------
  // list patients
  const getlistPatient = async () => {
    let payload = {
      providerId: (UserData.roleTitle === RoleTitle.STAFF || UserData.roleTitle === RoleTitle.FRONT_DESK) ? UserData?.providerId : UserData?.userId,
      roleTitle: UserData?.roleTitle,
      staffId: UserData.roleTitle === RoleTitle.STAFF ? UserData?.userId : undefined,
      patientFilter: "ALL",
    }
    setLoader(true)
    try {
      let response = await PATIENT_LIST(payload)
      setPatientList(response.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setPatientList([])
    }
  }
  // add new conversation staff 
  const addNewConversationPatient = async (data) => {
    setLoader(true)
    const payload = {
      image: data?.image,
      loggedInUserId: UserData?.userId,
      memberArray: [
        {
          userId: data?.id,
          roleTitle: RoleTitle.PATIENT
        }
      ],
      name: data?.firstName,
      conversationType: Tab.current,
      roleTitle: UserData?.roleTitle,
    };
    try {
      let response = await ADD_NEW_CONVERSATION(payload)
      toast.success(response?.message)
      handleClosedModal("PATIENT")
      setConversationList((pre) => (!response?.data?.alreadyExist ? [...pre, response?.data] : [...pre]))
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  //-------------------------------------------------------------------- bloack Unblock delete leave group conversation   --------------------------------------------
  const handleDeleteCoversation = async (conversationId) => {
    setLoader(true)
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationId: [conversationId],
      roleTitle: UserData?.roleTitle,
      nowTime: moment().utc()
    }
    try {
      let response = await DELETE_CONVERSATION(payload)
      toast.success(response?.message)
      setConversationList(prevList => prevList.filter(item => item?.conversationId !== conversationId));
      setLoader(false)
      setUserInfo(null)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  const handleBlockUser = async (data) => {
    setLoader(true)
    let payload = {
      loggedInUserId: UserData?.userId,
      conversationId: data?.conversationId,
      blockedStatus: !data?.blockedStatus,
      nowTime: moment().utc()
    }
    try {
      let response = await BLOCK_USER(payload)
      toast.success(response?.message)
      if (userInfoRef.current) {
        setUserInfo((pre) => ({ ...pre, blockedStatus: !pre?.blockedStatus }))
      }
      setConversationList((pre) => (pre?.map((cur, index) => {
        if (cur.conversationId === data?.conversationId) {
          return { ...cur, blockedStatus: !cur?.blockedStatus }
        }
        return cur
      })))
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  const handleLeaveGroup = async (data) => {
    setLoader(true)
    let payload = {
      loggedInUserId: UserData?.userId,
      conversation_id: data?.conversationId,
      member_id: data?.userDetails?.userId,
      nowTime: moment().utc()
    }
    try {
      let response = await LEAVE_GROUP(payload)
      toast.success(response?.message)
      setConversationList(prevList => prevList.filter(item => item?.conversationId !== data?.conversationId));
      setLoader(false)
      setUserInfo(null)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }
  const handleReadMessage = async (conversationId, unReadCount) => {
    if (unReadCount === 0) return
    setLoader(true)
    let payload = {
      providerId: UserData.roleTitle === RoleTitle.SERVICE_PROVIDER ? UserData?.userId : undefined,
      staffId: UserData.roleTitle === RoleTitle.STAFF ? UserData?.userId : undefined,
      frontdeskId: UserData.roleTitle === RoleTitle.FRONT_DESK ? UserData?.userId : undefined,
      roleTitle: UserData?.roleTitle,
      conversationId: conversationId,
      nowTime: moment().utc()
    }
    try {
      await READ_MESSAGE(payload)
      setConversationList(prevList => prevList.map((item) => {
        if (item?.conversationId === conversationId) {
          return { ...item, unReadMessages: 0 }
        }
        else return item
      }));
      setLoader(false)
    } catch (error) {
      toast.error(error?.message)
      setLoader(false)
    }
  }



  const handleDeleteMessageResponse = async (data) => {
    if (data?.conversationType === Tab.current && userInfoRef.current) {
      setMessageList((pre) => ({
        ...pre, messagesData: pre?.messagesData?.map((cur, index) => {
          if (data?.messageId === cur?.messageId) {
            return { ...cur, message: data?.message, deletedStatus: true, }
          }
          return cur
        })
      }))
    }
    if (data?.conversationType === Tab.current) {
      setConversationList((pre) => (pre?.map((cur, index) => {
        if (cur?.conversationId === data?.conversationId) {
          return { ...cur, lastMessage: { ...cur.lastMessage, message: data?.message, deletedStatus: true } }
        }
        return cur
      })))
    }
    if (data?.conversationType !== Tab.current) {
      setMessageNotification((pre) => {
        let preData = { ...pre }
        if (data?.conversationType !== Tab.current) {
          preData[data.conversationType] = true
        }
        return preData
      })
    }
  }
  const handleEditMessage = (data) => {
    if (data?.conversationType === Tab.current && userInfoRef.current) {
      setMessageList((pre) => ({
        ...pre, messagesData: pre?.messagesData?.map((cur, index) => {
          if (data?.messageId === cur?.messageId) {
            return { ...cur, message: data?.message, editedStatus: true }
          }
          return cur
        })
      }))
    }
    if (data?.conversationType === Tab.current) {
      setConversationList((pre) => (pre?.map((cur, index) => {
        if (cur?.conversationId === data?.conversationId) {
          return { ...cur, lastMessage: { ...cur.lastMessage, message: data?.message, editedStatus: true } }
        }
        return cur
      })))
    }
    setMessageNotification((pre) => {
      let preData = { ...pre }
      if (data?.conversationType !== Tab.current) {
        preData[data.conversationType] = true
      }
      return preData
    })
  }
  // active user
  const activeUser = useCallback((data) => {
    setUserInfo((prevUserInfo) => {
      if (!prevUserInfo || prevUserInfo.conversationId !== data.conversationId) {
        getALLMessage(data);
      }
      return data;
    });
    setAttchament(null);
    setSendMessage("");
    setEditMessage(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteMessage = (conversationId, messageId) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      let messageData = {
        eventType: 'deleteMessage',
        conversationId: conversationId,
        conversationType: Tab.current,
        messageId: messageId,
      }
      socket.send(JSON.stringify(messageData));
    }
  }
  const handleSendMessage = (event) => {
    event.preventDefault()
    if (socket && socket?.readyState === WebSocket?.OPEN) {
      let messageData = {
        eventType: 'sendMessage',
        loggedInUserId: UserData?.userId,
        conversationId: userInfoRef.current?.conversationId,
        message: sendMessage,
        messageType: attchament ? "DOCUMENT" : 'TEXT',
        nowTime: moment().utc(),
        conversationType: Tab.current,
        document: attchament,
        editedStatus: editMessage ? true : false,
        messageId: editMessage ? editMessage?.messageId : null,
        roleTitle: UserData?.roleTitle
      }
      socket?.send(JSON.stringify(messageData));
      setSendMessage("")
      setAttchament(null)
      setEditMessage(null)
    }
  };
  useEffect(() => {
    if (socket && socket?.readyState === WebSocket?.OPEN) {
      const handleMessage = (event) => {
        const data = JSON.parse(event?.data);
        if (data?.eventType === "error") {
          toast.error(data?.message);
        }
        if (data.eventType === "receivedMessage") {
          if (data?.data?.editedStatus) {
            handleEditMessage(data?.data)
          } else if (data?.data?.deletedStatus) {
            handleDeleteMessageResponse(data?.data)
          } else {
            getLastMessage(data?.data);
          }
        }
      };
      socket?.addEventListener('message', handleMessage);
      return () => {
        if (socket) {
          socket?.removeEventListener('message', handleMessage);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, userInfoRef.current]);
  const handleClosedModal = (type) => {
    if (type === "STAFF") {
      setNewStaffList([])
      setFilter("")
    }
    if (type === "GROUP") {
      setNewGroupList([])
      setFilter("")
      setGroupName("")
      setGroupPicture(null)
      setSeletedGroupMember([])
    }
    if (type === "PATIENT") {
      setPatientList([])
      setFilter("")
    }
    if (type === "GROUP_INFO") {
      setGroupdetails(null)
      setGroupPicture(null)
      setGroupName("")
      setAddParticipant(false)
      setIsEditGroup(false)
      cancelGroupInfoModal()
    }
    setOpenModal((pre) => ({
      ...pre,
      data: null,
      [type]: false
    }))
  }
  const handleOpenModal = (type, data) => {
    if (type === "STAFF") getnewStaff()
    if (type === "GROUP") getnewgroups()
    if (type === "PATIENT") getlistPatient()
    if (type === "GROUP_INFO") getGroupdetails()
    setOpenModal((prev) => ({
      ...prev,
      data,
      [type]: true,
    }));
  };
  useEffect(() => {
    document.title = "Chat"
  }, [])
  console.log(messageNotification, 'messageNotification')
  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className='page_name'>Chat</h2>
          </div>
        </header>
        <div className="common-white-bg-box  mx-0 shadow px-2">
          <div className="CHAT ">
            {
              (windowWidth > 770 || !userInfo) && <div className="h-100 CHAT_SIDEBAR">
                <Sidebar
                  conversationList={conversationList}
                  handleChangeTab={handleChangeTab}
                  Tab={Tab.current}
                  handleOpenModal={handleOpenModal}
                  conversationlistRaf={conversationlistRaf}
                  handleScrollConversationList={handleScrollConversationList}
                  seachConversationValue={seachConversationValue}
                  handleSearchConvertaion={handleSearchConvertaion}
                  handleDeleteCoversation={handleDeleteCoversation}
                  handleBlockUser={handleBlockUser}
                  userInfo={userInfo}
                  activeUser={activeUser}
                  handleLeaveGroup={handleLeaveGroup}
                  messageNotification={messageNotification}
                  handleReadMessage={handleReadMessage}
                />
              </div>
            }
            {
              (windowWidth > 770 || userInfo) && <div className={`MAIN_CHAT ${windowWidth < 770 ? "w-100" : ""} `}>
                <Chatbox
                  handleOpenModal={handleOpenModal}
                  handleSendMessage={handleSendMessage}
                  setSendMessage={setSendMessage}
                  sendMessage={sendMessage}
                  messageList={messageList}
                  userInfo={userInfo}
                  Tab={Tab.current}
                  handleBlockUser={handleBlockUser}
                  attchament={attchament}
                  setAttchament={setAttchament}
                  setLoader={setLoader}
                  handleDeleteMessage={handleDeleteMessage}
                  setEditMessage={setEditMessage}
                  editMessage={editMessage}
                  windowWidth={windowWidth}
                  setUserInfo={setUserInfo}
                />
              </div>
            }
          </div>
        </div>
      </div>
      <Modal show={openModal?.STAFF} onHide={() => { handleClosedModal("STAFF") }} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-center  w-100'>New Conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='w-100'>
            <div className='px-2'>
              <input type="text" placeholder="User name" value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control filter_input " name="services" />
            </div>
            <div className=" NEW_CONVERSATION_BOX pb-2 px-2 ">
              {newStaffList?.filter((item) => `${item?.firstName} ${item?.lastName}`.toLowerCase().includes(filter?.toLowerCase()))?.map((cur, index) => {
                return <div className=" mb-3 d-flex align-items-center NEW_USER" onClick={() => addNewConversationStaff(cur)} key={index}>
                  <Avatar alt={cur?.firstName + " " + cur?.lastName} src={(cur?.roleTitle === RoleTitle.STAFF || cur?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + cur?.image) : (userImageFilePath + cur?.image)} />
                  {/* userImageFilePath */}
                  <div className='ms-3'>
                    <p className="m-0">{cur?.firstName + " " + cur?.lastName}</p>
                  </div>
                </div>
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={openModal?.GROUP} onHide={() => { handleClosedModal("GROUP") }} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-center  w-100'>Create Group</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="pb-2">
            <div className=" d-flex align-items-center px-2">
              <div className='d-flex align-items-center px-2'>
                <Badge
                  style={{ background: "#fff" }}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp"  >
                      <MdOutlineCameraAlt onClick={() => groupImageRaf.current.click()} style={{ cursor: "pointer" }}></MdOutlineCameraAlt>
                      <input ref={groupImageRaf} type="file" className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, groupImageRaf, "CHATGROUP", setLoader, setGroupPicture, GroupPicture)} />
                    </SmallAvatar>
                  }
                >
                  <Avatar src={ChatGroupFilePath + GroupPicture} sx={{ width: 60, height: 60 }}>
                  </Avatar>
                </Badge>
                {GroupPicture && <DeleteProfile setImgUrl={setGroupPicture} deletedData={{ fileName: GroupPicture, type: "CHATGROUP", id: null }} isDeleted={true} />}
              </div>
              <input maxLength={100} type="text" placeholder="Group name" value={groupName} onChange={(e) => setGroupName(e.target.value)} className="form-control filter_input ms-3" name="services" />
            </div>
            <div className='px-2'>
              <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} placeholder="Search" className="form-control filter_input mt-3 " name="services" />
            </div>
            <div className='NEW_CONVERSATION_BOX  px-2'>
              {newGroupList?.filter((item) => `${item?.firstName} ${item?.lastName}`.toLowerCase().includes(filter?.toLowerCase()))?.map((cur, index) => {
                return <div className=" mb-3 d-flex align-items-center NEW_USER" key={index}>
                  <input
                    className="form-check-input me-4"
                    id={cur?.userId}
                    type="checkbox"
                    checked={seletedGroupMember.some((selected) => selected?.userId === cur?.userId)}
                    onChange={(e) => handleSelectGroupMember(e, cur)} />
                  <label htmlFor={cur?.userId} className=' d-flex align-items-center'>
                    <Avatar alt={cur?.firstName + " " + cur?.lastName} src={(cur?.roleTitle === RoleTitle.STAFF || cur?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + cur?.image) : (userImageFilePath + cur?.image)} />
                    <div className='ms-3'>
                      <p className="m-0">{cur?.firstName + " " + cur?.lastName}</p>
                    </div>
                  </label>
                </div>
              })}
            </div>
            <div className='w-100 text-center'>
              <CustomButton variant="outlined" className="me-3" onClick={() => handleClosedModal('GROUP')}> Cancel</CustomButton>
              <CustomButton variant="contained" onClick={newGroupAdd}  > Create</CustomButton>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={openModal?.PATIENT} onHide={() => { handleClosedModal("PATIENT") }} centered>
        <Modal.Header closeButton>
          <Modal.Title className='text-center  w-100'>New Conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='d-flex justify-content-center flex-column'>
            <div className='px-2'>
              <input type="text" placeholder="User name" value={filter} onChange={(e) => setFilter(e.target.value)} className="form-control filter_input " name="services" />
            </div>
            <div className=" NEW_CONVERSATION_BOX pb-2 px-2 ">
              {patientList?.filter((item) => `${item?.firstName} ${item?.lastName}`.toLowerCase().includes(filter?.toLowerCase()))?.map((cur, index) => {
                return <div className=" mb-3 d-flex align-items-center NEW_USER" onClick={() => addNewConversationPatient(cur)} key={index}>
                  <Avatar alt={cur?.firstName + " " + cur?.lastName} src={PatientImageFilePath + cur?.image} />
                  <div className='ms-3'>
                    <p className="m-0">{cur?.firstName + " " + (cur?.lastName ? cur?.lastName : "")}</p>
                  </div>
                </div>
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size='lg' show={openModal?.GROUP_INFO} onHide={() => { handleClosedModal("GROUP_INFO") }} centered>
        <Modal.Header >
          <Modal.Title className='text-center  w-100'>Group Info</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className='row py-3 GROUP_INFO'>
            <div className='col-lg-6 order-lg-0 order-1 px-0'>
              <div className='d-flex justify-content-center flex-column'>
                <div className='px-2'>
                  {
                    !addParticipant ? <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-1 cursor px-2" style={{ height: "34px" }} onClick={() => { setAddParticipant(true); getnewgroups() }}>
                      <p className='m-0' >+ Add Participant</p>
                    </div> : <IconButton aria-label="back" size="small" onClick={() => setAddParticipant(false)}><MdArrowBackIos /></IconButton>
                  }
                </div>
                <div className=" NEW_CONVERSATION_BOX pb-2 px-2 ">
                  {!addParticipant && groupDetails?.membersData?.map((cur, index) => {
                    return <div className=" mb-3 d-flex align-items-center NEW_USER" key={index}>
                      <Avatar alt={cur?.firstName + " " + cur?.lastName} src={(cur?.roleTitle === RoleTitle.STAFF || cur?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + cur?.image) : (userImageFilePath + cur?.image)} />
                      <div className='ms-3 d-flex justify-content-between w-100'>
                        <p className="m-0">{cur?.firstName + " " + cur?.lastName}</p>
                        <div className=' d-flex justify-content-end  flex-column align-items-end'>
                          {
                            cur?.adminStatus && <p className='m-0 rounded px-2' style={{ background: "#81e9e1", color: '#fff' }}>Admin</p>
                          }
                          {/* (messageList?.adminStatus && UserData?.userId !== cur?.userId && !disableButton()) */}
                          {(UserData?.userId !== cur?.userId && !disableButton()) ? <>
                            <div className='dropdown'>
                              <MoreHorizIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                              <ul className="dropdown-menu ">
                                {
                                  cur?.adminStatus && <li><button className="dropdown-item" onClick={() => addOrRemoveAdminAccess(cur, "REVOKE")} >Dismiss as admin</button></li>
                                }
                                <li><button className="dropdown-item" onClick={() => handleRemoveGroup(cur)} >Remove</button></li>
                                {
                                  (!cur?.adminStatus && messageList?.adminStatus) && <li><button className="dropdown-item" onClick={() => addOrRemoveAdminAccess(cur, "ADD")} >Make Group Admin</button></li>
                                }
                                {/* {
                                  messageList?.adminStatus && <li><button className="dropdown-item" onClick={() => addOrRemoveAdminAccess(cur, "ADD")} >Make Group Admin</button></li>
                                } */}
                              </ul>

                            </div>
                          </> :
                            null
                          }
                        </div>
                      </div>
                    </div>
                  })}
                  {addParticipant && newGroupList?.map((cur, index) => {
                    const isAlreadyMember = groupDetails?.membersData?.some(member => member.userId === cur.userId);
                    return <div className=" mb-3 d-flex align-items-center NEW_USER" key={index}>
                      <Avatar alt={cur?.firstName + " " + cur?.lastName} src={(cur?.roleTitle === RoleTitle.STAFF || cur?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + cur?.image) : (userImageFilePath + cur?.image)} />
                      <div className='ms-3 d-flex justify-content-between  w-100'>
                        <div>
                          <p className="m-0">{cur?.firstName + " " + cur?.lastName}</p>
                          {isAlreadyMember && <p className='m-0 MESSAGE'>Already member of group</p>}
                        </div>
                        <div>
                          {
                            !isAlreadyMember && <CustomButton disabled={disableButton()} variant="contained" style={{ padding: "0px" }} onClick={() => addNewMemberToGroup(cur)} > Add</CustomButton>
                          }
                        </div>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            </div>
            <div className='col-lg-6 order-lg-1 order-0'>
              <div className=" d-flex align-items-center px-2 ">
                <div className=" d-flex align-items-center px-2 ">
                  <Badge
                    style={{ background: "#fff" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp"  >
                        <MdOutlineCameraAlt onClick={() => groupImageRaf.current.click()} style={{ cursor: "pointer" }}></MdOutlineCameraAlt>
                        <input disabled={!(messageList?.adminStatus || !groupDetails?.onlyAdminUpdateInfo)} ref={groupImageRaf} type="file" className='d-none ' accept="image/*" name="file" onChange={(e) => { UploadImage(e, groupImageRaf, "CHATGROUP", setLoader, setGroupPicture, GroupPicture, groupDetails?.conversationId); setIsEditGroup(true) }} />
                      </SmallAvatar>
                    }
                  >
                    <Avatar alt={groupDetails?.name} src={ChatGroupFilePath + GroupPicture} sx={{ width: 60, height: 60 }}>
                    </Avatar>
                  </Badge>
                  {GroupPicture && (messageList?.adminStatus || !groupDetails?.onlyAdminUpdateInfo) && <DeleteProfile setImgUrl={setGroupPicture} deletedData={{ fileName: GroupPicture, type: "CHATGROUP", id: groupDetails?.conversationId }} isDeleted={true} setIsEditGroup={setIsEditGroup} />}
                </div>
                <input type="text" maxLength={100} readOnly={!(messageList?.adminStatus || !groupDetails?.onlyAdminUpdateInfo)} placeholder="Group name" value={groupName} onChange={(e) => { setGroupName(e.target.value); setIsEditGroup(true) }} className="form-control filter_input ms-3" name="services" />
              </div>
              <div className='w-100 d-flex justify-content-end mb-3 pe-2'>
                {

                  isEditGroup && <CustomButton variant="contained" style={{ padding: "5px 0px" }} onClick={updateGroupInfo}  > save</CustomButton>
                }
              </div>
              <div className="row DETAILS">
                <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-3" style={{ height: "34px" }} >
                  <p className='m-0'>Participant</p>
                  <p className='mb-0'>{groupDetails?.membersData?.length}</p>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-3" style={{ height: "34px" }} >
                  <p className='m-0'>Created date</p>
                  <p className='mb-0'>{moment(groupDetails?.createdBy?.dateTime).format('LLL')}</p>
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-3 pe-0" style={{ height: "34px" }} >
                  <p className='m-0'>Only admins can message</p>
                  <GreenSwitch name="onlyAdminCanMessage" disabled={!(messageList?.adminStatus)} onChange={handleUpdateAdminAccess} checked={groupDetails?.onlyAdminCanMessage} />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-3 pe-0" style={{ height: "34px" }} >
                  <p className='m-0'>Only admins can edit group info</p>
                  <GreenSwitch name="onlyAdminUpdateInfo" disabled={!(messageList?.adminStatus)} onChange={handleUpdateAdminAccess} checked={groupDetails?.onlyAdminUpdateInfo} />
                </div>
                <div className="col-12 d-flex justify-content-between align-items-center border rounded-3 mb-3 pe-0" style={{ height: "34px" }} >
                  <p className='m-0'>Only admins can add/remove member</p>
                  <GreenSwitch name="onlyAdminCanAddOrRemove" disabled={!(messageList?.adminStatus)} onChange={handleUpdateAdminAccess} checked={groupDetails?.onlyAdminCanAddOrRemove} />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Chat