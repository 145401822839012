import React from "react";
import Loading from "../../Component/Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IconButton, TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import useTableSorting from "../../Hooks/useTableSorting";
import useTeamLocation from "./Hooks/useTeamLocation";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { motion } from 'framer-motion';
import { MdAssignmentAdd } from "react-icons/md";

const TeamLocation = () => {
    const {
        loader,
        LocationListdata,
        controller,
        paginationTotalCount,
        isFrontDeskProfile,
        setController,
        statuschange,
        navigate,
    } = useTeamLocation()
    const { GreenSwitch } = useMuiCompontent()
    const headCells = [
        { id: "image", numeric: false, disablePadding: true, label: "#" },
        { id: "clinicName", numeric: true, disablePadding: false, label: "Location" },
        { id: "Status", numeric: true, disablePadding: false, showLeft: true, label: "Status" },
        { id: "Action", numeric: true, disablePadding: false, showLeft: true, label: "Action" },
    ];
    if(isFrontDeskProfile){
        headCells.pop() 
    }

    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <motion.div className=" d-flex justify-content-between flex-column" style={{ height: "100%" }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.7 }}
            >
                <TableContainer  >
                    {LocationListdata?.length === 0 &&
                        <div className="col-sm-12 mt-3 text-center " >
                            <NoRecordFound />
                        </div>
                    }
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                    >
                        {
                            LocationListdata?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={LocationListdata?.length} />
                        }
                        <TableBody >
                            {stableSort(LocationListdata, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={index}><TableRow id="hover_tr"  >

                                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                            {controller.rowsPerPage * (controller.page - 1) + index + 1}
                                        </TableCell>

                                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                            {row?.clinicName}
                                        </TableCell>

                                        <TableCell id={labelId} className=" " align="right" >
                                            <GreenSwitch  name="active" defaultChecked={row?.isChecked} onChange={(e) => statuschange(e.target.checked, row)} />
                                        </TableCell>
                                        {
                                            !isFrontDeskProfile&& <TableCell id={labelId} className="" align="right">
                                            <IconButton title="Assign Services" aria-label="Assign Services" color="primary" onClick={() => {
                                                if (row?.isChecked) {
                                                    navigate(`/team/specialization/${row?.clinicId}`)
                                                }
                                            }}>
                                                <MdAssignmentAdd className="fs-5 cursor" />
                                            </IconButton>
                                        </TableCell>
                                        }
                                    </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <nav className="w-100" aria-label="pagination">
                    {
                        paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
                    }

                </nav>
            </motion.div>
        </>
    );
};

export default TeamLocation;
