import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import Patientdetails from './ReducerFile/Patientdetails';
import UserData from './ReducerFile/UserData';
import TeamDetails from './ReducerFile/TeamDetails';
import AgendaDetails from './ReducerFile/AgendaDetails';
import SubMemberType from './ReducerFile/SubMemberType';
import BasicDetails from './ReducerFile/BasicDetails';
import ClinicFildData from './ReducerFile/ClinicFildData';
import BusinessDetails from './ReducerFile/BusinessDetails';

const encryptor = encryptTransform({
  secretKey: 'zimo.one123@123@123',
});
const reducers = combineReducers({
  patientdetailsRDX: Patientdetails,
  userDataRDX: UserData,
  teamDetailsRDX: TeamDetails,
  AgendaDetailsRDX: AgendaDetails,
  SubMemberTypeRDX: SubMemberType,
  BasicDetailsRDX: BasicDetails,
  ClinicFildDataRDX: ClinicFildData,
  BusinessDetailsRDX: BusinessDetails,
});
const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['BasicDetailsRDX'],
  // whitelist: ["BasicDetailsRDX"],
  transforms: [encryptor],
};
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;