import React from "react";
import { ToastContainer } from "react-toastify";
import { Accordion } from "react-bootstrap";

const FAQ = () => {
 

  return <>
 
    <ToastContainer />
    <div className=" pt-3 FAQ" style={{ height: "100%" }} >
      <Accordion flush>
        <Accordion.Item eventKey={1}>
          <Accordion.Header style={{ fontSize: "13px" }}>Why Choose Winkdoc?  </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Winkdoc for its user-friendly interface that streamlines appointment scheduling, automates reminders, and integrates telehealth capabilities. With features like patient profile management, vaccination tracking, pet family member support, and secure data management, Winkdoc enhances practice efficiency, reduces no-shows, and improves overall patient care. It’s the ideal solution for modern healthcare practices looking to optimize their operations and provide exceptional service.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={2}>
          <Accordion.Header style={{ fontSize: "13px" }}>Who can use Winkdoc?   </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Winkdoc is ideal for doctors, healthcare clinics, veterinary practices, and any healthcare provider needing efficient appointment management and patient care solutions.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={3}>
          <Accordion.Header style={{ fontSize: "13px" }}>What are the FEATURES of WinkDoc?    </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Winkdoc is a specialized appointment scheduling software designed specifically for healthcare professionals. It offers a comprehensive suite of features to streamline your practice and enhance patient care:
            </p>
            <ul className="mt-2">
              <li>Efficient Appointment Management </li>
              <li>Multiple Location Management </li>
              <li>Multiple profile Management </li>
              <li>Vaccination Calendar </li>
              <li>Automated SMS and Email Reminders </li>
              <li>Patient Profile Management </li>
              <li>Customizable Notification Settings </li>
              <li>Online Booking Portal 24*7 </li>
              <li>Secure Data Management </li>
              <li>Analytics and Reporting </li>
              <li>In-built Chat  </li>

            </ul>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={4}>
          <Accordion.Header style={{ fontSize: "13px" }}>How does Winkdoc help improve patient care?    </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Winkdoc enhances patient care by providing automated reminders, easy online booking, detailed patient profiles, and telehealth options, ensuring timely and effective patient interactions.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={5}>
          <Accordion.Header style={{ fontSize: "13px" }}>Can Winkdoc manage appointments for pets?     </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Yes, Winkdoc allows users to add pet family members and manage appointments, making it suitable for veterinary practices.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={6}>
          <Accordion.Header style={{ fontSize: "13px" }}>Is there any flexibility in deleting my account?     </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Maybe you have a duplicate account, want to start fresh with a new account? No hard feelings!

              If you do decide to close your account, After that your account will become fully anonymized, and it won't be visible on the app anymore.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={7}>
          <Accordion.Header style={{ fontSize: "13px" }}>Is patient data secure with Winkdoc?   </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Yes, Winkdoc employs advanced security measures to ensure all patient data is protected and compliant with healthcare regulations.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={8}>
          <Accordion.Header style={{ fontSize: "13px" }}>What are the pricing plans for Winkdoc?    </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Winkdoc offers various pricing plans based on the features and scale of your practice. Please visit our pricing page for more details or contact our sales team for a customized quote.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
      <Accordion flush>
        <Accordion.Item eventKey={9}>
          <Accordion.Header style={{ fontSize: "13px" }}>Does Winkdoc support multi-location management?     </Accordion.Header>
          <Accordion.Body style={{ fontSize: "12px", fontWeight: "400", fontFamily: "sans-serif" }}>
            <p className="m-0">Yes, Winkdoc can manage appointments and patient records across multiple clinic locations, making it ideal for practices with several offices.
            </p>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
    </div>
  </>;
};

export default FAQ;
