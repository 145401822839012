import React from 'react'
import moment from "moment";
import { Avatar, Badge, Chip } from '@mui/material';
import Loading from '../../Component/Loading';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import useEditOffers from './Hooks/useEditOffers';
import { OfferImageFilePath } from '../../Context/api';
import TextBox from '../../Component/TextBox';
const OfferView = () => {
    const {
        loader,
        formik,
        imgUrl,
        check,
        // serviceListData,
        // minimumdate,
        // Agecheck,
        // id,
        // UploadImage,
        // setCheck,
        // setAgeCheck,
        // setImgUrl,
        // FileRef,
        //  handalSearchAbleSelectBox,
    } = useEditOffers()
    const { GreenSwitch } = useMuiCompontent()
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <h2>View Offer</h2>
                    </div>
                </header>
                <div className="row common-white-bg-box mx-0 shadow position-relative px-2">
                    {/* <div className="col-4 m-auto mt-4">
                        <div className="bodrerer mb-4" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                            <label htmlhtmlhtmlfor="inputTag">
                                <AiOutlineCloudUpload className="fs-4" />
                                {imgUrl && <img src={OfferImageFilePath + imgUrl} alt="" />}
                                <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef)} />
                                <br />
                                Upload an Image
                                <br />
                                <span id="imageName"></span>
                            </label>
                            {imgUrl && <DeleteIcons setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "OFFER", id: id }} isDeleted={true} />}
                        </div>
                    </div> */}
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik.values.offerName}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Display Picture</label>
                            </div>
                            <div className="col-4 d-flex justify-content-between align-items-center">
                                <Badge
                                    style={{ background: "#fff" }}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                >
                                    <Avatar  sx={{ width: 80, height: 80 }} src={OfferImageFilePath + imgUrl} >
                                    </Avatar>
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Coupon Code<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik.values.couponCode}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={formik.values.serviceId?.map((cur, index) => {
                                    return <Chip size='small' key={cur?.serviceId} label={cur?.serviceName}
                                        style={{ color: `#fff`, background: `#12BABA`, cursor: "pointer" }}
                                        className="me-2"
                                    />
                                })
                                } />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={formik.values.audienceGender?.map((cur, index) => {
                                    return <Chip size='small' key={cur?.value} label={cur?.label}
                                        style={{ color: `#fff`, background: `#12BABA`, cursor: "pointer" }}
                                        className="me-2"
                                    />
                                })
                                } />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3 Slider_offers">
                                <label className="form-label mb-0">
                                    Age Group </label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{`${formik?.values?.age[0]} To ${formik?.values?.age[1]}`}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Date Range<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-2">
                                <TextBox textBoxData={<p>{moment(formik.values.dateFrom).format("YYYY-MM-DD")}</p>} />
                            </div>
                            <div className="col-2">
                                <TextBox textBoxData={<p>{moment(formik.values.dateTo).format("YYYY-MM-DD")}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Discount Type<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik?.values?.discountType}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Minimum Value<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik?.values?.minimumCartValue}</p>} />
                            </div>
                        </div>
                    </div>
                    {formik?.values?.discountType === "FLAT" ? <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0"> Discount Amount<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik?.values?.discountAmount}</p>} />
                            </div>
                        </div>
                    </div> :
                        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Percentage<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <TextBox textBoxData={<p>{formik?.values?.percentage}</p>} />
                                </div>
                            </div>
                        </div>

                    }
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Maximum Discount upto Value<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik?.values?.maxDiscountUpto}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<p>{formik?.values?.description}</p>} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Terms and Conditions<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <TextBox textBoxData={<span dangerouslySetInnerHTML={{ __html: formik?.values?.terms }} />} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">{check ? "Active" : "inActive"}<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-9">
                                <GreenSwitch disabled name="active" defaultChecked={check} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OfferView
