import React, { useState } from "react";
import {useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Errors } from "../../Component/Error";
import { Button, IconButton, Input, InputAdornment, TextField } from "@mui/material";
import Loading from "../../Component/Loading";
import { motion } from 'framer-motion';
import useForgot from "./Hooks/useForgot";
import { MaskedEmail, TIMEZONE } from "../../Utils/Utils";

import { MdPhone } from "react-icons/md";
import { IoMdUnlock } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Forgot = () => {
  const {
    loader,
    active,
    formik,
    userData,
    verifyOTPFun,
    timer,
    createPinFun,
    resenOTP,
  } = useForgot()
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    newPin: false,
    confirmPin: false
  })
  const handleClickShowPassword = (type) => {
    setShowPassword((pre) => ({
      ...pre, [type]: !pre[type]
    }))
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      {loader && <Loading />}
      <ToastContainer />
      <div className="LOGIN_CONTAINER">
        <div className="container">
          <div className="row LOGIN_SECTION shadow_hide">
            <div className="col-lg-6 col-12 INFO_SECTION d-none d-lg-block ">
              {/* <div>
                <h2>Lorem Ipsum Slider Here!</h2>
                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p>
              </div> */}
            </div>
            {
              active === 1 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Forgot PIN </h3>
                <p style={{ width: "80%" }} className="text-center">Enter your Registered {(TIMEZONE() === "Asia/Kolkata")?"Mobile Number":"Email Address"}</p>
                <form className="FORM" onSubmit={formik.handleSubmit}>
                  <div className="col-sm-12 mb-3">
                    <label className="form-label mb-2 ms-4">{(TIMEZONE() === "Asia/Kolkata") ? "Mobile Number" : "Email"}<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      {(TIMEZONE() === "Asia/Kolkata") ? <MdPhone className="me-1 ICON" /> : <MdEmail className="me-1 ICON" />}
                      {
                        (TIMEZONE() === "Asia/Kolkata") ?
                          // <PhoneNumber
                          //   width={100}
                          //   Require={false}
                          //   Name="phone"
                          //   HandleChange={formik}
                          //   Value={formik?.values?.phone}
                          //   countrySelectComponent="null"
                          // /> 
                          <TextField
                            variant="standard"
                            onChange={(e) => { formik.setFieldValue("phone", e.target.value) }}
                            name="phone"
                            value={formik?.values?.phone}
                            type="number"
                            style={{ width: "100%" }}
                            color='primary'
                            onInput={(e) => {
                              let maxValue = 10
                              e.target.maxLength = maxValue;
                              if (e.target.value.length > maxValue) {
                                e.target.value = e.target.value.slice(0, maxValue);
                              }
                              if (parseFloat(e.target.value) < 0) {
                                e.target.value = 0;
                              }
                            }}
                          />
                          
                          : <TextField type="email" className="w-100 " maxLength={50} name="email" id="standard-basic" {...formik.getFieldProps("email")} variant="standard" placeholder="Enter your email " />
                      }
                    </div>
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={formik} name={`${(TIMEZONE() === "Asia/Kolkata") ? "phone" : "email"}`} />
                    </div>
                  </div>
                  <Button type="submit" variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize" }}> Continue</Button>
                </form>
                <p className=" BACK-BUTTON" onClick={() => navigate("/login")} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
            {
              active === 2 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Enter OTP </h3>
                <p style={{ width: "80%" }} className="text-center">{`We have send OTP on your ${TIMEZONE() === "Asia/Kolkata" ? `******${userData?.mobile?.toString()?.slice(8)}` : MaskedEmail(userData?.email)}`}</p>
                <form className="FORM" onSubmit={verifyOTPFun.handleSubmit}>
                  <div className="col-sm-12 mb-3">
                    <label className="form-label mb-2 ms-4">OTP<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <MdPhone className="me-1 ICON" />
                      <TextField type="password" className="w-100 " name="otp" id="standard-basic" variant="standard" placeholder="Enter OTP "
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        value={verifyOTPFun.values.otp}
                        onChange={(e) => verifyOTPFun.setFieldValue("otp", e.target.value)}
                      />
                    </div>
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={verifyOTPFun} name={"otp"} />
                    </div>
                  </div>
                  <p className="text-end RESEND-OTP"  >{timer > 0 && `OTP will expire in ${timer} Second`}</p>
                  <div className="d-flex justify-content-end">
                    {
                      timer > 0 ? null : <p className=" d-inline" onClick={() => { resenOTP() }}>Resend OTP</p>
                    }
                  </div>

                  <Button type="button" onClick={verifyOTPFun.handleSubmit} variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize" }}> Continue</Button>
                </form>
                <p className="BACK-BUTTON" onClick={() => navigate("/login")} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
            {
              active === 3 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Reset PIN</h3>
                <p style={{ width: "80%" }} className="text-center">Set the new PIN for the account so that you
                  can easily login and use features.</p>
                <form action="" className="FORM" >
                  <div className="col-sm-12 mb-2">
                    <label className="form-label mb-0 ms-4">New PIN<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <IoMdUnlock className="me-1 ICON" />
                      <Input
                        onChange={(e) => createPinFun.setFieldValue("newpin", e.target.value)}
                        value={createPinFun.values.newpin}
                        name="password"
                        style={{ width: "100%" }}
                        id="standard-adornment-password"
                        type={showPassword.newPin ? 'number' : 'password'}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword("newPin")}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.newPin ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                    </div >
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={createPinFun} name={"newpin"} />
                    </div>
                  </div>
                  <div className="col-sm-12 mb-2">
                    <label className="form-label mb-0 ms-4">Confirm PIN<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <IoMdUnlock className="me-1 ICON" />
                      <Input
                        onChange={(e) => createPinFun.setFieldValue("confirmpin", e.target.value)}
                        value={createPinFun.values.confirmpin}
                        name="password"
                        style={{ width: "100%" }}
                        id="standard-adornment-password"
                        type={showPassword.confirmPin ? 'number' : 'password'}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword("confirmPin")}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.confirmPin ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                    </div >
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={createPinFun} name={"confirmpin"} />
                    </div>
                  </div>
                  <Button type="button" onClick={createPinFun.handleSubmit} variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize" }}> Continue</Button>
                </form>
                <p className="BACK-BUTTON" onClick={() => navigate("/login")} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
            {
              active === 4 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}>
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>PIN updated successfully</h3>
                <p style={{ width: "80%" }} className="text-center">Your PIN has been updated successfully!</p>
                <form action="" className="FORM" >
                  <div className="SUCCESSFULLY_ICON d-flex justify-content-center w-100">
                    <img src={require("../../Assets/Images/8832119.png")} alt="" width={80} height={80} className="img-fluid" />
                  </div>
                  <Button type="button" onClick={() => navigate("/login")} variant="contained" className="w-100 fs-6 mt-5" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px"  ,textTransform: "capitalize"}}> Back to Login</Button>
                </form>
              </motion.div>
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
