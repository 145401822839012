import React from 'react'
import { Errors } from '../../Component/Error';
import moment from 'moment-timezone';
import Loading from '../../Component/Loading';
import { Avatar, Badge, styled } from '@mui/material';
import useUpdatePet from "../Patients/Hooks/useUpdatePet"
import { PatientImageFilePath } from '../../Context/api';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { SelectBox } from '../../Component/Inputs';
import { PetGenderOptions, PetTypeOptions } from '../../Context/constants';
import { MdOutlineCameraAlt } from 'react-icons/md';
import DeleteProfile from '../../Component/DeleteProfile';
const UpdatePet = ({ type }) => {
    const { loader, imgUrl, updatePetfun, UploadImage, setImgUrl, FileRef, handalDOB, setLoader, patientdetails, id, subpatientId, } = useUpdatePet(type)
    const { CustomButton } = useMuiCompontent()
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex justify-content-between flex-column" style={{ height: "100%" }}>
                <div className=" row">

                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Pet ID<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" disabled={true} maxLength={50} name='patientId'
                                    value={patientdetails?.patientId||""} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Pet ID<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" disabled={true} maxLength={50} name='patientId'
                                            value={patientdetails?.patientId} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" value={updatePetfun?.values?.firstName}
                                    onChange={(e) => updatePetfun.setFieldValue("firstName", e.target.value)} />
                                <Errors formikfun={updatePetfun} name={"firstName"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" maxLength={50} className="form-control" value={updatePetfun?.values?.firstName}
                                            onChange={(e) => updatePetfun.setFieldValue("firstName", e.target.value)} />
                                        <Errors formikfun={updatePetfun} name={"firstName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Pet Type<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Pet Type"
                                    value={updatePetfun.values.petType}
                                    name="petType"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => updatePetfun.setFieldValue("petType", e.target.value)}
                                    option={PetTypeOptions}
                                />
                                <Errors formikfun={updatePetfun} name={"petType"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Pet Type<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Pet Type"
                                            value={updatePetfun.values.petType}
                                            name="petType"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => updatePetfun.setFieldValue("petType", e.target.value)}
                                            option={PetTypeOptions}
                                        />
                                        <Errors formikfun={updatePetfun} name={"petType"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    {
                        updatePetfun?.values?.petType === "Others" &&
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Other<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control" value={updatePetfun?.values?.petTypeField||""}
                                        onChange={(e) => updatePetfun.setFieldValue("petTypeField", e.target.value)} />
                                    <Errors formikfun={updatePetfun} name={"petTypeField"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Other<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" maxLength={50} className="form-control" value={updatePetfun?.values?.petTypeField}
                                                onChange={(e) => updatePetfun.setFieldValue("petTypeField", e.target.value)} />
                                            <Errors formikfun={updatePetfun} name={"petTypeField"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>

                    }
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Breed<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control" value={updatePetfun?.values?.breed||""}
                                    onChange={(e) => updatePetfun.setFieldValue("breed", e.target.value)} />
                                <Errors formikfun={updatePetfun} name={"breed"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Breed<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" maxLength={50} className="form-control" value={updatePetfun?.values?.breed}
                                            onChange={(e) => updatePetfun.setFieldValue("breed", e.target.value)} />
                                        <Errors formikfun={updatePetfun} name={"breed"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="date" value={updatePetfun.values.dob?moment(updatePetfun.values.dob).format("YYYY-MM-DD"):""} className="form-control position-relative"
                                    onChange={(e) => { handalDOB(e); updatePetfun.setFieldValue("dob", e.target.value) }}
                                />
                                <Errors formikfun={updatePetfun} name={"age"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="date" value={moment(updatePetfun.values.dob).format("YYYY-MM-DD")} className="form-control position-relative"
                                            onChange={(e) => { handalDOB(e); updatePetfun.setFieldValue("dob", e.target.value) }}
                                            max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                        />
                                        <Errors formikfun={updatePetfun} name={"age"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="text" className="form-control" name='age' disabled={true} value={updatePetfun.values.age} />
                                        <Errors formikfun={updatePetfun} name={"age"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>

                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SelectBox
                                    placeholder="Select Gender"
                                    value={updatePetfun.values.gender}
                                    name="gender"
                                    keys="value"
                                    label="label"
                                    menuValeu="value"
                                    handleChange={(e) => updatePetfun.setFieldValue("gender", e.target.value)}
                                    option={PetGenderOptions}
                                />
                                <Errors formikfun={updatePetfun} name={"gender"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Gender"
                                            value={updatePetfun.values.gender}
                                            name="gender"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => updatePetfun.setFieldValue("gender", e.target.value)}
                                            option={PetGenderOptions}
                                        />
                                        <Errors formikfun={updatePetfun} name={"gender"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Display Picture</label>
                            </div>
                            <div className="col-4 d-flex  align-items-start flex-column">
                                <Badge
                                    style={{ background: "#fff" }}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="Remy Sharp"  >
                                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                            </MdOutlineCameraAlt>
                                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl)}
                                            />
                                        </SmallAvatar>
                                    }
                                >
                                    <Avatar  sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                    </Avatar>
                                </Badge>
                                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Display Picture</label>
                                    </div>
                                    <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                        <Badge
                                            style={{ background: "#fff" }}
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            badgeContent={
                                                <SmallAvatar alt="Remy Sharp"  >
                                                    <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                    </MdOutlineCameraAlt>
                                                    <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                        onChange={(e) => UploadImage(e, FileRef, "PATIENT", setLoader, setImgUrl, imgUrl, type === "Patients" ? id : subpatientId)}
                                                    />
                                                </SmallAvatar>
                                            }
                                        >
                                            <Avatar sx={{ width: 80, height: 80 }} src={PatientImageFilePath + imgUrl} >
                                            </Avatar>
                                        </Badge>
                                        {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PATIENT", id: null }} isDeleted={true} />}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className=" col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end mt-4 mb-3">
                        <CustomButton variant="contained" onClick={updatePetfun.handleSubmit} >update</CustomButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePet
