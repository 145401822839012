import { useNavigate, useParams } from 'react-router-dom';
import { AddpatientsValidation, pattern } from '../../../Context/Validation';
import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import useReduxData from '../../../Hooks/useReduxData';
import { COUNTRY_LIST, STATE_LIST } from '../../Repository/CommonRepo';
import { INSERT_MEMBER, INSERT_PATIENT } from '../../Repository/PatientRepo';
import { RoleTitle } from '../../../Context/constants';

const useAddPatient = (type) => {
    const [imgUrl, setImgUrl] = useState(null);
    const [ListDataCountry, setListDataCountry] = useState([])
    const [ListState, setListState] = useState([])
    const [loader, setLoader] = useState(false)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
    let { id } = useParams()
    const FileRef = useRef()
    const navigate = useNavigate()
    useEffect(() => {
        listcountrys()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // get list state
    const getStateList = async (countryId, StateId) => {
        let payload = {
            countryId: countryId,
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
            if (countryId && StateId) {
                const StateDetails = response.data.filter((cur) => cur?.stateId === StateId)
                addPatint.setFieldValue("state", StateDetails)
            }

        } catch (error) {
            setListState([])
            toast.error(error.message)
            addPatint.setFieldValue("state", [])
        } finally {
            setLoader(false)
        }
    }
    // get list counry api
    async function listcountrys(countryId) {
        let payload = {
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
            if (countryId) {
                const countryDetails = response.data.filter((cur) => cur?.id === countryId)
                addPatint.setFieldValue("country", countryDetails)
            }
        } catch (error) {
            setListDataCountry([])
        } finally {
            setLoader(false)
        }
    }
    // add patient 
    const addPatint = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            age: "",
            bloodGroup: "",
            maritalStatus: "",
            number: "",
            email: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zipCode: "",
            relation: "",
            // smoking: "",
            // alcohol: ""
        },
        validationSchema: AddpatientsValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.number?.length > 0 && (values?.number.match(pattern?.PHONE_NO))) {
                delete errors.number
            } else {
                errors.number = "Please enter a valid mobile number ";
            }
            if (id && !values.relation) {
                errors.relation = "Please select relation"
            } else {
                delete errors.relation
            }
            return errors;
        },
        onSubmit: async (value) => {
            const { dob, state, country, number, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                mobile: number,
                dob: moment(dob).format('YYYY-MM-DD'),
                state: state[0]?.stateId,
                country: country?.[0]?.id,
                image: imgUrl,
                relation: id ? restValue.relation : undefined,
                authId: id ? patientdetails?.authId : undefined
            }
            setLoader(true)
            try {
                let response = !id ? await INSERT_PATIENT(payload) : await INSERT_MEMBER(payload)
                toast.success(response?.message)
                setLoader(false)
                if (!id) navigate("/patients")
                else navigate(`/patients/${id}/sub-members`)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    // handal change birthDate
    const handalDOB = (e) => {
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        addPatint.setFieldValue("age", age ?? "")
    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            getStateList(newValue?.[0]?.id, name)
            addPatint.setFieldValue(name, newValue)
            addPatint.setFieldValue("state", [])
        } else {
            addPatint.setFieldValue(name, newValue)
        }
    }
    return {
        id,
        addPatint,
        loader,
        imgUrl,
        ListDataCountry,
        ListState,
        handalDOB,
        setImgUrl,
        FileRef,
        handalSearchAbleSelectBox,
        setLoader,
        navigate
    }
}

export default useAddPatient
