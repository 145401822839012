/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { useNavigate, useParams } from "react-router-dom/dist";
import { SPECIALIZATION_LIST_WITH_SERVICES } from "../../Repository/CommonRepo";

const useAddSpecialization = (type) => {
    const [apiSpecialisedValues, setApiSpecializationvalue] = useState([])
    const [SpecializationData, setSpecializationData] = useState([])
    const [loader, setLoader] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const { reduxData: UserData } = useReduxData("userDataRDX")
    const navigate = useNavigate()
    const { id } = useParams()
    const getData = JSON.parse(localStorage?.getItem("service"))
    const SignupUserData = JSON.parse(localStorage.getItem("S_userdata"))
    const getlistservice = async () => {
        const payload = {
            providerId: type === "LOGIN" ? SignupUserData?.userId : UserData.userId,
        }
        if (id) {
            payload.clinicId = id
        }
        setLoader(true)
        try {
            let response = await SPECIALIZATION_LIST_WITH_SERVICES(payload)
            let responseData = response?.data
            if (getData) {
                setApiSpecializationvalue(getData)
                getData.forEach((specialisation) => {
                    responseData.forEach((specialisationAPI) => {
                        if (specialisationAPI.specializationId === specialisation.specializationId) {
                            specialisationAPI.services.forEach((service) => {
                                specialisation.service.includes(service.serviceId) ? service.isChecked = true : service.isChecked = false
                            }
                            )
                        }
                    })
                })
            } else {
                let setValues = []
                responseData.forEach((specialisation) => {
                    let serviceArray = []
                    specialisation.services.forEach((service) => {
                        if (service?.isChecked) {
                            serviceArray.push(service.serviceId)
                        }
                    })
                    setValues.push({ specializationId: specialisation.specializationId, service: serviceArray })
                })
                setApiSpecializationvalue(setValues)
            }
            setSpecializationData(responseData)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
            setOpenModal(true)
        }
    }
    useEffect(() => {
        getlistservice()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (datas, specializationId, serviceIndex, specializationIndex) => {
        const checked = datas.isChecked
        let localData = [...apiSpecialisedValues]
        let apiValues = [...SpecializationData]
        if (checked == false) {
            apiValues[specializationIndex].services[serviceIndex].isChecked = true
            localData.forEach((specialisation) => {
                if (specialisation.specializationId == specializationId) {
                    specialisation.service.length ? specialisation.service.push(datas.serviceId) : specialisation.service = [datas.serviceId]
                }
            })
        } else {
            apiValues[specializationIndex].services[serviceIndex].isChecked = false
            localData.forEach((specialisation) => {
                if (specialisation.specializationId == specializationId) {
                    specialisation.service = specialisation.service.filter((serviceId) => serviceId != datas.serviceId)
                }
            })
        }
        setApiSpecializationvalue(localData)
        setSpecializationData(apiValues)
    }
    const allselect = (e, specializationId, specializationIndex) => {
        const checked = e.target.checked;
        let localData = [...apiSpecialisedValues];
        let apiValues = [...SpecializationData];

        if (checked === false) {
            localData.forEach((specialisation) => {
                if (specialisation.specializationId == specializationId) {
                    specialisation.service = [];
                }
            });
            apiValues[specializationIndex].services.forEach((service) => service.isChecked = false);

        } else {
            localData.forEach((specialisation) => {
                if (specialisation.specializationId == specializationId) {
                    apiValues[specializationIndex].services.forEach((service) => {
                        if (!specialisation.service.includes(service.serviceId)) {
                            specialisation.service.push(service.serviceId);
                        }
                        service.isChecked = true;
                    });
                }
            });
        }

        setApiSpecializationvalue(localData);
        setSpecializationData(apiValues);
    };


    const handleSubmit = () => {
        let servicesdata = []
        let data = [...apiSpecialisedValues]
        data?.forEach((cur, index) => {
            cur.service.forEach((item => {
                servicesdata?.push(item)
            }))
        })
        if (!servicesdata.length) {
            return toast.warn("Please assign at least  one service")
        }
        setLoader(true)
        setTimeout(() => {
            localStorage.setItem("service", JSON.stringify(apiSpecialisedValues))
            toast.success("Add specialization & service successfully")
            setLoader(false)
            if (!id) {
                navigate("/profile/addClinic")
                if (type !== "LOGIN" && UserData.userId) {
                    navigate("/profile/addClinic")
                } else {
                    navigate("/alldetails/business-details/addClinic")
                }
            } else {
                navigate(`/profile/clinic/edit/${id}`)
            }
        }, 800);

    }
    return {
        loader,
        openModal,
        SpecializationData,
        UserData,
        id,
        allselect,
        handleChange,
        handleSubmit,
        navigate,
    }

}

export default useAddSpecialization
