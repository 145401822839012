import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useReduxData from '../../../Hooks/useReduxData';
import { GET_DEMOGRAPHIC_DETAILS } from '../../Repository/InsightsRepo';
import { GendersOptions, RoleTitle } from '../../../Context/constants';
// import { formatNumber } from '../../../Utils/Utils';

const useDemographics = () => {
    const [selectGender, setSelectGender] = useState(GendersOptions)
    const [loader, setloader] = useState(false)
    const [demographicsDetails, setDemographicsDetails] = useState({})
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const [filterPopup, setFilterPopup] = useState(false)
    const [action, setAction] = useState(false)

    const getDemographicDetail = async () => {
        let payload = {
            providerId:userdata.roleTitle === RoleTitle.FRONT_DESK?userdata?.providerId: userdata.userId,
            demographicGenderFilter: selectGender?.map((cur) => cur?.value)
        }
        setloader(true)
        setFilterPopup(false)
        try {
            let response = await GET_DEMOGRAPHIC_DETAILS(payload)
            setloader(false)
            setDemographicsDetails(response?.data)
        } catch (error) {
            toast.error(error?.message)
            setDemographicsDetails({})
            setloader(false)
        }
    }
    const totalfemaleCount = demographicsDetails?.femaleCount
    const totalmaleCount = demographicsDetails?.maleCount
    const totalothersCount = demographicsDetails?.othersCount
    const totalUsersCount = demographicsDetails?.totalUsersCount
    const DemographicAgeGroup = demographicsDetails?.DemographicAgeGroup
    console.log(demographicsDetails, "demographicsDetails")
    useEffect(() => {
        getDemographicDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])
    return {
        setSelectGender,
        setFilterPopup,
        loader,
        selectGender,
        demographicsDetails,
        filterPopup,
        setAction,
        getDemographicDetail,
        totalfemaleCount,
        totalmaleCount,
        totalothersCount,
        totalUsersCount,
        DemographicAgeGroup,

    }
}

export default useDemographics
