export const commonMessages = {
  NetworkError: "Network Error",
};
export const statusCode = {
  OK: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  NotAcceptable: 406,
  RequestTimeout: 408,
  Conflict: 409,
  ContentTooLarge: 413,
  UnsupportedMediaType: 415,
  MaximumOTPRetryAttemptsReached: 429,
  UnableToSendOTP: 430,
  IncorrectOTP: 431,
  InternalServerError: 500,
  BadGateway: 502,
  ServiceUnavailable: 503
};
export const RoleTitle = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SERVICE_PROVIDER: "SERVICE_PROVIDER",
  STAFF: "STAFF",
  PATIENT: "PATIENT",
  FRONT_DESK: "FRONT_DESK"

}
export const paginationCount = 10
export const fileTypes = {
  pdf_File_Type: ["application/pdf"],
  Img_Type: [
    "image/jpg",
    "image/jpeg",
    "image/jfif",
    "image/pjpeg",
    "image/pjp",
    "image/png",
    "image/svg",
    "image/webp",
  ],
};

export const dailogAllMessage = {
  profile: {
    deleteClinic: { p1: "Are you sure?", p2: "you want to delete this clinic?" }
  },
  Services: {
    Archive: " You want to archive this service.",
    UnArchive: " You want to unarchive this service."
  },
  Specialization: {
    Archive: " You want to archive this specialization.",
    UnArchive: " You want to unarchive this specialization."
  },
  Teams: {
    Archive: " You want to archive this teams member.",
    UnArchive: " You want to unarchive this teams member."
  },
  vaccinationCategory: {
    Archive: " you want to archive this vaccination category  ",
    UnArchive: "you want to unarchive this vaccination category "
  },
  vaccinationForm: {
    Archive: " you want to archive this vaccination form  ",
    UnArchive: "you want to unarchive this vaccination form "
  },
  PublicVaccinationForm: {
    public: " you want to publish this vaccination form   ",
    draft: "you want to draft this vaccination form "
  },
}
export const colorsList = [
  { code: '#214559' },
  { code: '#00626f' },
  { code: '#391285' },
  { code: '#0000aa' },
  { code: '#11887b' },
  { code: '#696006' },
  { code: '#004953' },
  { code: '#d1001c' },
  { code: '#900020' },
  { code: '#d5b60a' },
  { code: '#4a0100' },
  { code: '#af2f0d' },
  { code: '#9c004a' },
  { code: '#cb416b' },
  { code: '#d90166' },
  { code: '#490648' },
  { code: '#80444c' },
  { code: '#673a3f' },
  { code: '#605467' },
  { code: '#754600' },
  { code: '#4f1507' },
  { code: '#341c02' },
  { code: '#937a62' },
  { code: '#5c5337' },
  { code: '#415764' },
  { code: '#333333' },
  { code: '#363737' },
  { code: '#646356' },
  { code: '#6f828a' },
  { code: '#171717' },
  { code: '#5729ce' },
  { code: '#5a4fcf' },
  { code: '#e17701' },
  { code: '#e08119' },
  { code: '#e1ad01' },
  { code: '#e27a53' },
  { code: '#fc642d' },
  { code: '#ff7f00' },
  { code: '#9878f8' },
  { code: '#6c3461' },
  { code: '#4b0082' },
  { code: '#871f78' },
  { code: '#871f78' },
  { code: '#412a7a' },
  { code: '#ebe5d0' },
  { code: '#eeebe3' },
  { code: '#014421' },
  { code: '#048243' },
  { code: '#004b49' },
  { code: '#446d46' },
  { code: "#a3fa4b" },
  { code: "#66ffc9" },
  { code: "#48eab1" },
  { code: "#fa5b09" }
];

export const TitleOptions = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
]
export const TicketFilterOptions = [
  { value: "MYTICKETS", label: "My List" },
  { value: "STAFFTICKETS", label: "My Staff List" },
]
export const PriorityOptions = [
  { value: "LOW", label: "Low" },
  { value: "MEDIUM", label: "Medium" },
  { value: "HIGH", label: "High" },
];

export const TimeFrameOptions = [
  { value: "Days", label: "Days" },
  { value: "Weeks", label: "Weeks" },
  { value: "Months", label: "Months" },
];
export const DiscountTypeOptions = [
  { value: "FLAT", label: "Flat" },
  { value: "PERCENTAGE", label: "Percentage" },
]
export const GenderOptions = [
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Other", value: "Others" },
]

export const GenderOptions_TEAM = [
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
  { label: "Prefer not to say", value: "Prefer not to say" },
]
export const GendersOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Others", label: "Others" },
];
export const SortbyAppointment = [
  { value: "TOTAL_BOOKING", label: "Total Booking" },
  { value: "TOTAL_REVENUE", label: "Total Revenue" },

]
export const activeStatusOption = [
  { value: true, label: "Unarchive" },
  { value: false, label: "Archive" },
]
export const teamRolesOption = [
  { value: "ALL", label: "All" },
  { value: "STAFF", label: "Team" },
  { value: "FRONT_DESK", label: "Front Desk" },
]

export const archiveOption = [
  { value: false, label: "Unarchive" },
  { value: true, label: "Archive" },
]
export const publishStatusOption = [
  { value: "ALL", label: "All" },
  { value: false, label: "Draft" },
  { value: true, label: "Publish" },
]
export const StaffPersonalOption = [
  { value: "PERSONAL", label: "PERSONAL" },
  { value: "ALL", label: "ALL" },
]

export const durationOption = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
  { value: 60, label: 60 },
  { value: 70, label: 70 },
  { value: 80, label: 80 },
  { value: 90, label: 90 },
  { value: 100, label: 100 },
  { value: 110, label: 110 },
  { value: 120, label: 120 },
];
export const BloodGroupOptions = [
  { value: "A+", label: "A +" },
  { value: "A-", label: "A -" },
  { value: "AB+", label: "AB +" },
  { value: "AB-", label: "AB -" },
  { value: "B+", label: "B +" },
  { value: "B-", label: "B -" },
  { value: "O+", label: "O +" },
  { value: "O-", label: "O -" },
];
export const LanguageOptions = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
]

export const MaritalStatusOptions = [
  { value: "Divorced", label: "Divorced" },
  { value: "In-Relationship", label: "In-Relationship" },
  { value: "Married", label: "Married" },
  { value: "Not Applicable", label: "Not Applicable" },
  { value: "Single", label: "Single" },
  { value: "Widow", label: "Widow" },
  { value: "Widower", label: "Widower" },
];

export const PatientRelationOptions = [
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Son", label: "Son" },
  { value: "Daughter", label: "Daughter" },
  { value: "Husband", label: "Husband" },
  { value: "Wife", label: "Wife" },
  { value: "Brother", label: "Brother" },
  { value: "Sister", label: "Sister" },
  { value: "Grandfather", label: "Grandfather" },
  { value: "Grandmother", label: "Grandmother" },
  { value: "Father-in-law", label: "Father-in-law" },
  { value: "Mother-in-law", label: "Mother-in-law" },
  { value: "Sister-in-law", label: "Sister-in-law" },
  { value: "Brother-in-law", label: "Brother-in-law" },
  { value: "Daughter-in-law", label: "Daughter-in-law" },
  { value: "Son-in-law", label: "Son-in-law" }
];
export const PetTypeOptions = [
  { value: "Dog", label: "Dog" },
  { value: "Cat", label: "Cat" },
  { value: "Others", label: "Others" },
];
export const PetGenderOptions = [
  { label: "Female", value: "Female" },
  { label: "Male", value: "Male" },
]
export const MergePatient = [
  { label: "Merge As Duplicate", value: "DUPLICATE" },
  { label: "Merge As Family Member", value: "FAMILY" },
]

export const currencyOptions = [
  { label: "USD", value: "USD" },
  { label: "INR", value: "INR" },
  { label: "EUR", value: "EUR" },
  { label: "CAD", value: "CAD" },
  { label: "JPY", value: "JPY" },
]

export const AppointmentStatusOptions = [
  { label: "Booked", value: "BOOKED" },
  { label: "Cancelled", value: "CANCELLED" },
  { label: "Rescheduled", value: "RESCHEDULED" },
  { label: "CheckedOut", value: "CHECKOUT" },
]
export const AppointmentStatus = {
  CANCELLED: "CANCELLED",
  BOOKED: "BOOKED",
  RESCHEDULED: "RESCHEDULED",
  CHECKEDOUT: "CHECKOUT",
  WAITING: "WAITING"
}

export const MedicineTypeOptions = [
  { value: 'tablet', label: 'Tablet' },
  { value: 'capsule', label: 'Capsule' },
  { value: 'syrup', label: 'Syrup' },
  { value: 'injection', label: 'Injection' },
  { value: 'ointment', label: 'Ointment' },
]
export const MedicineDosesUnitOptions = [
  // { value: 'kg', label: 'kg' },
  { value: 'g', label: 'g' },
  { value: 'mg', label: 'mg' },

]
export const VisitTypeOptions = [
  { label: "Online", value: "Online" },
  { label: "Physical", value: "Physical" },
]
export const countOptions = (count, start) => {
  const Options = Array.from({ length: count }, (_, index) => ({
    label: (index + start).toString(),
    value: (index + start).toString(),
  }));
  return Options
} 