import React, { useEffect, useRef, useState } from 'react'
import PdfImage from '../../../Assets/Images/home/pdf.svg';
import xlsImage from "../../../Assets/Images/xlsx.png"
import textImage from "../../../Assets/Images/text-file.webp"
import DocImage from "../../../Assets/Images/doc.png"
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { BsReply } from 'react-icons/bs';
import axios from 'axios';
import { envoirment } from '../../../Context/envoirment';
import { useNavigate, useParams } from 'react-router-dom';
import { Buffer } from "buffer";
import { replyApi, ticketAttachment } from '../../../Context/api';
import { useFormik } from 'formik';
import { replyTicketValidation } from '../../../Context/Validation';
import { toast } from "react-toastify";
import useReduxData from '../../../Hooks/useReduxData';
import { commonMessages } from '../../../Context/constants';
import { Errors } from '../../../Component/Error';
import moment from "moment-timezone";
import Loading from '../../../Component/Loading';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { FaArrowLeft } from 'react-icons/fa';
const TicketDetails = () => {
    const [loader, setLoader] = useState(false)
    const [ticketDetails, setTicketDetails] = useState()
    const { id } = useParams()
    const { CustomButton } = useMuiCompontent()
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const curDateTime = moment().format()
    const scrollRef = useRef()
    const navigate = useNavigate()
    let decodeOutput = (result, delimiter = "@@@") => {
        const components = result.split(delimiter);
        return Buffer.from(components[0], "base64").toString("utf-8");
    };
    let encodeRequest = (input) => {
        let salt = "123";
        const buffer = Buffer.from(salt, "utf-8");
        const base64EncodedSalt = buffer.toString("base64");
        let encodePayload = Buffer.from(JSON.stringify(input), "utf-8").toString("base64");
        let encodeHash = Buffer.from("####", "utf-8").toString("base64");
        let delimiter = "@@@";
        let base64Payload = encodePayload + delimiter + encodeHash + delimiter + base64EncodedSalt;
        return base64Payload;
    };

    const ticketView = () => {
        let delimiter = "@@@";
        setLoader(true)
        axios.get(envoirment.ticketBaseUrl + `/api/external/ticket/${id}`)
            .then((resp) => {
                if (resp.data.code === 200) {
                    let rr = decodeOutput(resp.data.data, delimiter)
                    let ticketData = JSON.parse(rr)
                    let Replies = []
                    ticketData?.replies?.forEach(element => {
                        Replies?.push({
                            name: `${element?.createdByData?.firstName + " " + element?.createdByData?.lastName}`,
                            date: element?.createdAt,
                            reply: element?.reply
                        })
                    });
                    ticketData.newReplies = Replies
                    setTicketDetails(ticketData)
                    if (scrollRef) {
                        scrollRef.current?.addEventListener('DOMNodeInserted', event => {
                            const { currentTarget: target } = event;
                            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                        });
                    }
                } else {
                    toast.error(resp.data.message)
                }
                setLoader(false)
            }).catch(() => {
                toast.error(commonMessages.NetworkError)
            });
    };
    const formik = useFormik({
        initialValues: {
            reply: ""
        },
        validationSchema: replyTicketValidation,
        onSubmit: (values, { resetForm }) => {
            setLoader(true)
            let payload = {
                ticket_id: id,
                reply: values.reply,
                reportedBy_id: userdata?.userId.toString(),
            }
            let newData = { input: encodeRequest(payload) }
            axios.post(replyApi(), newData).then((res) => {
                if (res.data.code === 200) {
                    toast.success(res.data.message)
                    setTicketDetails((pre) => {
                        let newData = { ...pre }
                        newData.newReplies.push({
                            name: userdata?.firstName + " " + userdata?.lastName,
                            date: curDateTime,
                            reply: values.reply
                        })
                        return newData
                    })
                    resetForm()
                    if (scrollRef) {
                        scrollRef.current?.addEventListener('DOMNodeInserted', event => {
                            const { currentTarget: target } = event;
                            target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
                        });
                    }
                } else {
                    toast.error(res.data.message)
                }
                setLoader(false)
            }).catch(() => {
                toast.error(commonMessages.NetworkError)
                setLoader(false)
            })

        }
    })
    useEffect(() => {
        ticketView()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <>
        {loader && <Loading />}
        <div className="common-component-box">
            <header className="body_header">
                <div className="d-flex  align-items-center w-100 ">
                    <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/setting/help-desk")} />
                    <h2 className='page_name'>Tickets Details</h2>
                </div>
            </header>
            <div className="p-2 common-white-bg-box mx-0 shadow ticket_details">
                <div className="col-12">
                    <h3>Subject:</h3>
                    <p>{ticketDetails?.subject ? ticketDetails?.subject : "N/A"}
                    </p>
                </div>
                <div className="col-12">
                    <h3>Description:</h3>
                    <p >
                        {!ticketDetails?.description?.includes("<p><br></p>") ? (
                            <span dangerouslySetInnerHTML={{ __html: ticketDetails?.description }} />
                        ) : (
                            "N/A"
                        )}
                    </p>
                </div>
                <div className="col-12">
                    <h3>Priority:</h3>
                    <p>{ticketDetails?.priority ? ticketDetails?.priority : "N/A"}</p>
                </div>
                {
                    ticketDetails?.attachments.length ? <div className="col-12">
                        <h3>Attachment:</h3>
                        <div className=" Attachment">
                            {
                                ticketDetails?.attachments?.map((cur, index) => {
                                    let filetype = cur?.split('.').pop()
                                    if (filetype === "jpg" || filetype === "jpeg" || filetype === "png") {
                                        return <div className="img_box shadow">
                                            <img src={ticketAttachment + cur} alt="" onClick={() => window.open(ticketAttachment + cur)} />
                                        </div>
                                    }
                                    else if (filetype === "mp4" || filetype === "wmv" || filetype === "mov") {
                                        return <div className="video_box shadow">
                                            <video src={ticketAttachment + cur} alt="" autoplay controls> </video>
                                        </div>
                                    }
                                    else if (filetype === "xlsx" || filetype === "xls") {
                                        return <div className="img_box shadow">
                                            <img src={xlsImage} alt="" onClick={() => window.open(ticketAttachment + cur)} />
                                        </div>
                                    } else if (filetype === "txt" || filetype === "doc") {
                                        return <div className="img_box shadow">
                                            <img src={textImage} alt="" onClick={() => window.open(ticketAttachment + cur)} />
                                        </div>
                                    } else if (filetype === "pdf") {
                                        return <div className="img_box shadow">
                                            <img src={PdfImage} alt="" onClick={() => window.open(ticketAttachment + cur)} />
                                        </div>
                                    } else {
                                        return <div className="img_box shadow">
                                            <img src={DocImage} alt="" onClick={() => window.open(ticketAttachment + cur)} />
                                        </div>
                                    }
                                })
                            }
                        </div>
                    </div> : null
                }
                <div className="col-12 mt-3 " >
                    <div className='w-100 d-flex justify-content-between align-items-end py-2'>
                        <h3 className='mb-1' style={{ color: "#000" }}>Replies:</h3>
                    </div>
                    <hr className='mt-0' />
                    <div className="replies_box" ref={scrollRef}>
                        {
                            ticketDetails?.newReplies?.map((cur, index) => {
                                return <div className='mb-3'>
                                    <div className=''>
                                        <h3>{cur?.name}</h3>
                                        <span><span>Created At :</span>  {`${moment(cur?.date)?.tz(userdata?.timezoneName).calendar()}`}</span>
                                    </div>
                                    <p>{cur?.reply ? <span dangerouslySetInnerHTML={{ __html: cur?.reply }} /> : "-"}</p>
                                    <hr className='my-0 mt-2' />
                                </div>
                            })
                        }
                    </div>

                </div>
                <div className="col-12 mt-3">
                    <CKEditor
                        editor={ClassicEditor}
                        name='reply'
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            formik.setFieldValue("reply", data)
                        }}
                        data={formik.values?.reply}
                        config={{
                            // CKEditor configuration options
                            // Example: Set max height for the editor's content area
                            maxEditorHeight: 200,
                        }}
                    />
                    <Errors formikfun={formik} name={"reply"} />
                    <CustomButton variant="contained" className='mt-2' onClick={formik.handleSubmit} endIcon={<BsReply />}>Reply </CustomButton>
                </div>
            </div>
        </div>
    </>
}

export default TicketDetails
