import React from "react";
import { ToastContainer } from "react-toastify";
// import { userBasicProfileURL } from "../../../Context/api";

import { Errors } from "../../../Component/Error";
// import { Avatar, Badge, styled } from "@mui/material";
import Loading from "../../../Component/Loading";
import PhoneNumber from "../../../Component/PhoneInput";
import { motion } from 'framer-motion';
import useBasicDetails from "./Hooks/useBasicDetails";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { TitleOptions } from "../../../Context/constants";
import { SelectBox } from "../../../Component/Inputs";
// import useUploadImage from "../../../Hooks/useUploadImage";
// import DeleteProfile from "../../../Component/DeleteProfile";
// import { MdOutlineCameraAlt } from "react-icons/md";
const BasicDetails = () => {

  const {
    loader,
    formik,
    // imgUrl, 
    // setImgUrl,
    // setLoader,
    // userdata
  } = useBasicDetails()
  // const { UploadImage } = useUploadImage()
  // const FileRef = useRef()
  const { CustomButton } = useMuiCompontent()
  // const SmallAvatar = styled(Avatar)(({ theme }) => ({
  //   width: 22,
  //   height: 22,
  //   border: `2px solid ${theme.palette.background.paper}`,
  //   color: "#fff"
  // }));
  return (
    <>
      {loader && <Loading />}
      <ToastContainer />
      <div className="common-component-box  ">
        <header className="body_header" >
          <div className="d-flex justify-content-start align-items-center">
            <h2>Basic Details</h2>
          </div>
        </header>
        <div className="row" style={{ overflow: "hidden" }} >
          <motion.div className="col-12 pb-4"
            initial={{ x: 700 }}
            animate={{ x: 0 }}
            exit={{ x: -700 }}
            transition={{ duration: 0.6 }}
          >
            <div className=" common-white-bg-box shadow mx-0 position-relative px-2 pt-3">
              {/* <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Your Image</label>
                    <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                  </div>
                  <div className="col-4 d-flex  align-items-start flex-column">
                    <Badge
                      style={{ background: "#fff" }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp"  >
                          <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                          </MdOutlineCameraAlt>
                          <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "PROVIDER", setLoader, setImgUrl)} />
                        </SmallAvatar>
                      }
                    >
                      <Avatar  sx={{ width: 80, height: 80 }} src={userBasicProfileURL + imgUrl} >
                      </Avatar>
                    </Badge>
                    {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "PROVIDER", id: userdata?.userId }} isDeleted={true} />}

                  </div>
                </div>
              </div> */}
              <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4">
                    <SelectBox
                      placeholder="Select Title"
                      value={formik.values.title}
                      name="title"
                      keys="value"
                      label="label"
                      menuValeu="value"
                      handleChange={(e) => formik.setFieldValue("title", e.target.value)}
                      option={TitleOptions}
                    />
                    <Errors formikfun={formik} name={"title"} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">First Name<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4">
                    <input
                      maxLength={50}
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={formik.values.firstName}
                      onChange={(e) =>
                        formik.setFieldValue("firstName", e.target.value)
                      }
                    />
                    <Errors formikfun={formik} name={"firstName"} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Last Name<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4">
                    <input
                      maxLength={50}
                      type="text"
                      name="lastName"
                      className="form-control"
                      value={formik.values.lastName}
                      onChange={(e) =>
                        formik.setFieldValue("lastName", e.target.value)
                      }
                    />
                    <Errors formikfun={formik} name={"lastName"} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4 mobile_number_input_box">
                    <div className="input_group_mobile mb-0">
                      <PhoneNumber
                        width={false}
                        Require={false}
                        Name="mobile"
                        HandleChange={formik}
                        Value={formik.values.mobile}
                        disabledInput={true}
                        // disabledInput={TIMEZONE() === "Asia/Kolkata" ? true : false}
                        countrySelectComponent={undefined}
                      />
                    </div>
                    <Errors formikfun={formik} name={"mobile"} />
                  </div>
                </div>
              </div>
              <div className="col-sm-12  py-4 " >
                <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-4 ">
                    <input

                      type="email"
                      className="form-control"
                      name="email"
                      // disabled={TIMEZONE() !== "Asia/Kolkata" ? true : false}
                      disabled={true}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue("email", e.target.value)
                      }
                    />
                    <Errors formikfun={formik} name={"email"} />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end position-absolute bottom-0 end-0 p-3">
                <CustomButton type="button" onClick={formik.handleSubmit} variant="contained" className=" " >Next</CustomButton>
              </div>
            </div>
          </motion.div>
        </div>
      </div>


    </>
  );
};

export default BasicDetails;
