import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { createPinApi, resendApi, signUpApi, verifyApi } from "../../../Context/api";
import { signupNumberValidation, signupEmailValidation, signUpOtpValidation, createpinValidation, newUserPinValidation } from "../../../Context/Validation";
import { commonMessages } from "../../../Context/constants";
import { toast } from "react-toastify";

import useDisableBrowserBackButton from "../../../Hooks/useDisableBrowserBackButton";
import { AddBasicDetails } from "../../../Redux/ReducerFile/BasicDetails";
import { AddBusinessDetails } from "../../../Redux/ReducerFile/BusinessDetails";
import useReduxData from "../../../Hooks/useReduxData";
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TIMEZONE } from "../../../Utils/Utils";

const useSignUp = () => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("S_userdata"))
  const [newUser, setNewUser] = useState(false)
  const [timer, setTimer] = useState(60);
  const initValueForIndia = { phone: "", }
  const initValueForOther = { email: "" }
  const [loader, setloader] = useState(false)
  const [active, setActive] = useState(1)
  useDisableBrowserBackButton()
  const { dispatch } = useReduxData("BasicDetailsRDX")

  //////////////////////////////// signUp starat ////////////////////////////////////

  const formik = useFormik({
    initialValues: TIMEZONE() === "Asia/Kolkata" ? initValueForIndia : initValueForOther,
    validationSchema: TIMEZONE() === "Asia/Kolkata" ? signupNumberValidation : signupEmailValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate: (values) => {
      const errors = {};
      if (TIMEZONE() === "Asia/Kolkata") {
        if (values?.phone?.length > 0 && (values?.phone && isValidPhoneNumber(values?.phone))) {
          delete errors.phone
        } else {
          errors.phone = "Please enter a valid mobile number ";
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      let dataIndia = {
        mobileNo: `${values.phone}`,
        timeZone: TIMEZONE()
      }
      let data = { email: values.email, timeZone: TIMEZONE() }
      setloader(true)
      dispatch(AddBasicDetails({}));
      dispatch(AddBusinessDetails({}))
      axios.post(signUpApi(), TIMEZONE() === "Asia/Kolkata" ? dataIndia : data).then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          localStorage.setItem("S_userdata", JSON.stringify(response.data.data));
          setActive(2)
        } else {
          toast.error(response.data.message);
        }
        setloader(false)
      }).catch((error) => {
        if (error.response) {
          const status = error?.response?.status;
          const message = error?.response?.data?.message
          const Data = error?.response?.data?.data
          if (status === 406) {
            localStorage.setItem("S_userdata", JSON.stringify(Data));
            toast.error(message);
            setActive(3)
          }
          else if (status === 403) {
            localStorage.setItem("S_userdata", JSON.stringify(Data));
            toast.success(message);
            setActive(2)
            setNewUser(true)
          }
          else if (status === 420) {
            toast.error(message);
          } else if (status === 500) {
            toast.error(commonMessages.NetworkError);
          } else {
            toast.error(message);
          }
        } else {
          toast.error(commonMessages.NetworkError);
        }
        setloader(false)
      });
    },
  });

  //////////////////////////////// signUp end ////////////////////////////////////

  //////////////////////////////// verifyOTP start ////////////////////////////////////

  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    if (active === 2) {
      timer > 0 && setTimeout(timeOutCallback, 1000)
    }
  }, [timer, timeOutCallback, active])
  const resetTimer = () => {
    setTimer(60)
  }
  const resenOTP = () => {
    const data = (TIMEZONE() === "Asia/Kolkata") ? { mobileNo: userData?.mobile, processType: "SIGNUP_OTP" } : { email: userData.email, processType: "SIGNUP_OTP" }
    setloader(true)
    axios.post(resendApi(), { ...data, userId: userData?.userId }).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message);
        resetTimer()
      }
      setloader(false)
    }).catch((error) => {
      if (error.response) {
        const status = error?.response?.status;
        const message = error?.response?.data?.message
        if (status !== 200) {
          if (status === 500) {
            toast.error(commonMessages.NetworkError);
          } else {
            toast.error(message);
          }
        }
      } else {
        toast.error(commonMessages.NetworkError);
      }
      setloader(false)
    });
  };
  const VerifyOTPFun = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: signUpOtpValidation,
    onSubmit: (values) => {
      setloader(true)
      axios.post(verifyApi(), { userId: userData.userId, otp: values.otp, processType: "SIGNUP_OTP" }).then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setActive(3)
        }
        setloader(false)
      }).catch((error) => {
        if (error.response) {
          const status = error?.response?.status;
          const message = error?.response?.data?.message
          if (status !== 200) {
            if (status === 500) {
              toast.error(commonMessages.NetworkError);
            } else {
              toast.error(message);
            }
          }
        } else {
          toast.error(commonMessages.NetworkError);
        }
        setloader(false)
      });

    },
  });
  //////////////////////////////// verifyOTP end ////////////////////////////////////

  //////////////////////////////// new user enter pin start /////////////////////////

  const NewUserPinFun = useFormik({
    initialValues: {
      pin: "",
    },
    validationSchema: newUserPinValidation,
    onSubmit: (values) => {
      setloader(true)
      axios.post(createPinApi(), { confirmPin: values?.pin, userId: userData?.userId, roleTitle: userData.roleTitle }).then((response) => {
        if (response.data.status === "200") {
          setTimeout(() => navigate("/alldetails/basic-details"), 1500)
          toast.success(response.data.massage);
        } else if (response.data.status === "500") {
          toast.error(commonMessages.NetworkError);
        } else {
          toast.error(response.data.massage);
        }
        setloader(true)
      })
        .catch(() => {
          toast.error(commonMessages.NetworkError);
          setloader(true)
        });
    },
  });

  //////////////////////////////// new user enter pin end /////////////////////////


  //////////////////////////////// create pin start ////////////////////////////////////

  const createPinFun = useFormik({
    initialValues: {
      newpin: "",
      confirmpin: "",
    },
    validationSchema: createpinValidation,
    onSubmit: (values) => {
      setloader(true)
      axios.post(createPinApi(), { processType: "SIGNUP_OTP", confirmPin: values?.confirmpin, userId: userData?.userId, roleTitle: userData?.roleTitle }).then((response) => {
        if (response.status === 200) {
          toast.success(response.data.massage);
          setTimeout(() => navigate("/alldetails/basic-details"), 1500)
        }
      }).catch((error) => {
        if (error.response) {
          const status = error?.response?.status;
          const message = error?.response?.data?.message
          if (status !== 200) {
            if (status === 500) {
              toast.error(commonMessages.NetworkError);
            } else {
              toast.error(message);
            }
          }
        } else {
          toast.error(commonMessages.NetworkError);
        }
        setloader(false)
      });
    },
  });

  //////////////////////////////// create pin end ////////////////////////////////////

  return {
    loader,
    active,
    formik,
    userData,
    VerifyOTPFun,
    timer,
    createPinFun,
    newUser,
    NewUserPinFun,
    resetTimer,
    resenOTP,
  }
}

export default useSignUp
