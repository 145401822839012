import React,{memo} from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas';
import { SearchAbleSelectBox } from "../../../Component/Inputs";
import { AppointmentStatusOptions, RoleTitle } from "../../../Context/constants";
import { IoMdArrowDropdown } from 'react-icons/io';
const FilterAppointment = ({filtershow,setFiltershow,setDateRangeDropdown,dateFilter,setDateFilter,dateRangeDropdown,moment,date,setDate
    ,appointmentFilterData,handalSearchAbleSelectBox,userdata,teamSelected,patientSelected,serviceSelected,statusSelected,CustomButton,
    clearAll,HandleSearchList
}) => {
  return (
    <Offcanvas placement="end" className="shadow Appointment_Offcanvas_style" show={filtershow} onHide={() => {
        setFiltershow(false)
      }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row"  >
            <div className="col-12 mb-2  mb-3  ">
              <label className="form-label mb-0">Date Range </label>
              <div className="Appoinment_date_range ">
                <input onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} value={dateFilter === "TODAY" ? "Today's" : dateFilter === "LAST7" ? "Last 7 Days" : dateFilter === "LAST30" ? "Last 30 Days" : dateFilter === "CUSTOM" ? "Custom" : ""} type="text" />
                <span><IoMdArrowDropdown style={{ fontSize: "21px" }} onClick={(event) => { event.stopPropagation(); setDateRangeDropdown((pre => !pre)) }} /></span>
                {
                  dateRangeDropdown && <div className="Appoinment_date_range_box" onClick={(event) => event.stopPropagation()}>
                    <ul className="w-100  px-3 py-2" >
                      <li className="" onClick={() => { setDateFilter('TODAY'); setDateRangeDropdown(false) }} >
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1"
                          defaultChecked={dateFilter === 'TODAY'} />
                        <label className="form-check-label ps-2" htmlFor="exampleRadios2" >
                          Today's
                        </label>
                      </li>
                      <li onClick={() => { setDateFilter('LAST7'); setDateRangeDropdown(false) }}>
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option1"
                          defaultChecked={dateFilter === 'LAST7'} />
                        <label className="form-check-label ps-2" htmlFor="exampleRadios3" >
                          Last 7 Days
                        </label>
                      </li>
                      <li onClick={() => { setDateFilter('LAST30'); setDateRangeDropdown(false) }}>
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option1"
                          defaultChecked={dateFilter === 'LAST30'} />
                        <label className="form-check-label ps-2" htmlFor="exampleRadios4" >
                          Last 30 Days
                        </label>
                      </li>
                      <li onClick={() => { setDateRangeDropdown(true); setDateFilter('CUSTOM') }}>
                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4Custom" value="option1"
                          defaultChecked={dateFilter === 'CUSTOM'} />
                        <label className="form-check-label ps-2" htmlFor="exampleRadios4Custom">
                          Custom
                        </label>
                      </li>
                      {
                        dateFilter === 'CUSTOM' ?
                          <li className="mt-2 ps-4">
                            <div className="d-flex position-relative">
                              <input type="date" className="form-control position-relative" name='startDate' id="datefield" value={date.startDate?moment(date.startDate).format("YYYY-MM-DD"):""}
                                onChange={(e) => setDate((pre) => ({ ...pre, "startDate": e.target.value }))} />
                              <span style={{ padding: "0 2px" }} className="mt-2"> To</span>
                              <input type="date" className="form-control position-relative" name='endDate' id="datefield" value={date.endDate?moment(date.endDate).format("YYYY-MM-DD"):""}
                                onChange={(e) => setDate((pre) => ({ ...pre, "endDate": e.target.value }))} />
                            </div>
                          </li>
                          : null
                      }
                    </ul>
                  </div>
                }
  
              </div>
            </div>
            {/* <div className="col-12 mb-3 ">
              <label className="form-label mb-0"> Clinic </label>
              <SearchAbleSelectBox
                multiple={true}
                option={appointmentFilterData?.clinicList}
                getOptionLabel="clinicName"
                isOptionEqualToValue="clinicId"
                value={clinicSelected}
                name="Clinic_filter"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
            </div> */}
            {
              (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER||userdata.roleTitle === RoleTitle?.FRONT_DESK) ?
                <div className="col-12 mb-3 ">
                  <label className="form-label mb-0">Team</label>
                  <SearchAbleSelectBox
                    multiple={true}
                    option={appointmentFilterData?.staffList}
                    getOptionLabel="firstName"
                    extraLable="lastName"
                    isOptionEqualToValue="userId"
                    value={teamSelected}
                    name="Staff_filter"
                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                  />
                </div>
                :
                null
            }
            <div className="col-12 mb-3 ">
              <label className="form-label mb-0"> Patients</label>
              <SearchAbleSelectBox
                multiple={true}
                option={appointmentFilterData?.patientList}
                getOptionLabel="firstName"
                extraLable="lastName"
                isOptionEqualToValue="id"
                value={patientSelected}
                name="Patient_filter"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
            </div>
            <div className="col-12 mb-3 ">
              <label className="form-label mb-0"> Services</label>
              <SearchAbleSelectBox
                multiple={true}
                option={appointmentFilterData?.serviceList}
                getOptionLabel="serviceName"
                isOptionEqualToValue="serviceId"
                value={serviceSelected}
                name="Services_filter"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
            </div>
            <div className="col-12 mb-3 ">
              <label className="form-label mb-0"> Status</label>
              <SearchAbleSelectBox
                multiple={true}
                option={AppointmentStatusOptions}
                getOptionLabel="label"
                isOptionEqualToValue="value"
                value={statusSelected}
                name="Status_filter"
                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
              />
            </div>
            <div className='col-lg-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end'>
            <div className="col-12 mb-3 d-flex">
              <CustomButton variant="contained" className="" onClick={() => clearAll()} >Clear All</CustomButton>
              <CustomButton variant="contained" className=" ms-2" onClick={() => { HandleSearchList() }}>search</CustomButton>
            </div>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
  )
}

export default memo(FilterAppointment)
