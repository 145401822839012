import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import { addAvailabilityValidation } from "../../../Context/Validation";
import { useNavigate } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import moment from "moment-timezone";
import useMinimumDate from "../../../Hooks/useMinimumDate";
import { CLINIC_LIST } from "../../Repository/ProfileRepo";
import { TIME_ZONE_LIST } from "../../Repository/SettingRepo";
import { INSERT_AVAILABILITY } from "../../Repository/AvailabilityRepo";

const useSetAvailability = () => {
  const [clinicList, setClinicList] = useState([])
  const navigate = useNavigate()
  const [clinicModal, setClinicModal] = useState(false)
  const [loader, setloader] = useState(false)
  const [listTimeZone, setListTimeZone] = useState([])
  const [availabilityDefault, updateDefaultAvailability] = useState([
    {
      id: 0,
      name: "Sunday",
      isChecked: false,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 1,
      name: "Monday",
      isChecked: true,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 2,
      name: "Tuesday",
      isChecked: true,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 3,
      name: "Wednesday",
      isChecked: true,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 4,
      name: "Thursday",
      isChecked: true,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 5,
      name: "Friday",
      isChecked: true,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
    {
      id: 6,
      name: "Saturday",
      isChecked: false,
      arrayOfTimings: [
        {
          startTime: "",
          endTime: "",
        },
      ],
    },
  ]);
  const { reduxData: userdata } = useReduxData("userDataRDX")
  const { minimumdate } = useMinimumDate()
  const currentDateUtcStart = moment().set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
  const currentDateUtcEnd = moment().set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
  // const currentDateUtcStart = moment().set({ hour: 9, minute: 0, second: 0, millisecond: 0 }).tz('Asia/Kolkata').utc();
  // const currentDateUtcEnd = moment().set({ hour: 18, minute: 0, second: 0, millisecond: 0 }).tz('Asia/Kolkata').utc();
  useEffect(() => {
    getlistTimeZone()
    getListproviderclinics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // add time  button
  const addtimes = (day) => {
    let tempArr = availabilityDefault;
    if (tempArr[day].arrayOfTimings.length > 2)
      return toast.warn("Timings cannot be more than 3");
    tempArr[day].arrayOfTimings.push({
      startTime: "",
      endTime: "",
    });
    updateDefaultAvailability([...tempArr]);
  };
  // close time button
  const removetime = (day, index) => {
    let tempArr = availabilityDefault;
    tempArr[day].arrayOfTimings.splice(index, 1);
    updateDefaultAvailability([...tempArr]);
  };
  // remove section
  const removeSection = (day, index) => {
    switch (day) {
      case "Sunday":
        AvailabilityAdd.setFieldValue(`sunStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`sunEndTime${index}`, undefined)
        break;
      case "Monday":
        AvailabilityAdd.setFieldValue(`monStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`monEndTime${index}`, undefined)
        break;
      case "Tuesday":
        AvailabilityAdd.setFieldValue(`tuesStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`tuesEndTime${index}`, undefined)
        break;
      case "Wednesday":
        AvailabilityAdd.setFieldValue(`wedStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`wedEndTime${index}`, undefined)
        break;
      case "Thursday":
        AvailabilityAdd.setFieldValue(`thursStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`thursEndTime${index}`, undefined)
        break;
      case "Friday":
        AvailabilityAdd.setFieldValue(`friStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`friEndTime${index}`, undefined)
        break;
      case "Saturday":
        AvailabilityAdd.setFieldValue(`satStartTime${index}`, undefined)
        AvailabilityAdd.setFieldValue(`satEndTime${index}`, undefined)
        break;
      default:
        break;
    }
  }
  //  Switch  button open close
  const showHideTimings = (day) => {
    let tempArr = availabilityDefault;
    let check = !tempArr[day].isChecked;
    tempArr[day].isChecked = check;
    if (check) {
      tempArr[day].arrayOfTimings = [{ startTime: "", endTime: "" }]
      switch (day) {
        case 0:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`sunStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`sunEndTime${i}`, undefined)
          }
          break;
        case 1:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`monStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`monEndTime${i}`, undefined)
          }
          break;
        case 2:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`tuesStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`tuesEndTime${i}`, undefined)
          }
          break;
        case 3:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`wedStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`wedEndTime${i}`, undefined)
          }
          break;
        case 4:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`thursStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`thursEndTime${i}`, undefined)
          }
          break;
        case 5:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`friStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`friEndTime${i}`, undefined)
          }
          break;
        case 6:
          for (let i = 0; i <= 3; i++) {
            AvailabilityAdd.setFieldValue(`satStartTime${i}`, undefined)
            AvailabilityAdd.setFieldValue(`satEndTime${i}`, undefined)
          }
          break;
        default:
          break;
      }
    }
    updateDefaultAvailability([...tempArr]);
  };
  //  get  clinic clist 
  const getListproviderclinics = async () => {
    setloader(true)
    const payload = {
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      providerId: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata?.userId : userdata?.providerId,
      roleTitle: userdata.roleTitle,
      activeStatus: true,
    }
    try {
      let response = await CLINIC_LIST(payload)
      setClinicList(response.data)
      setloader(false)
    } catch (error) {
      setClinicList([])
      setloader(false)
      toast.error(error?.message)
    }
  }
  // get time zone
  const getlistTimeZone = async () => {
    setloader(true)
    const payload = {
      activeStatus: true,
    };
    try {
      let response = await TIME_ZONE_LIST(payload)
      setListTimeZone(response.data)
      if (userdata?.timezoneName) {
        let timeZoneDetails = response?.data?.filter((cur, index) => cur?.timezone === userdata?.timezoneName)
        AvailabilityAdd.setFieldValue("timeZone", timeZoneDetails)
      }
    } catch (error) {
      toast.error(error.message)
      setListTimeZone([])
    }
    finally {
      setloader(false)
    }
  }
  const setTime = (Start_Time, End_Time, timeZone) => {
    let startTime = moment(Start_Time).format('HH:mm').split(':')
    let endTime = moment(End_Time).format('HH:mm').split(':')
    let startDateTime = moment.tz(Start_Time, timeZone).set({ h: startTime[0], minute: startTime[1], second: 0 })
    let endDateTime = moment.tz(End_Time, timeZone).set({ h: endTime[0], minute: endTime[1], second: 0 })
    return { startDateTime, endDateTime }
  }

  // Add availability 
  const AvailabilityAdd = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
      timeZone: [],
      // sun
      sunStartTime0: "",
      sunEndTime0: "",
      sunStartTime1: "",
      sunEndTime1: "",
      sunStartTime2: "",
      sunEndTime2: "",

      //mon
      monStartTime0: currentDateUtcStart,
      monEndTime0: currentDateUtcEnd,
      monStartTime1: "",
      monEndTime1: "",
      monStartTime2: "",
      monEndTime2: "",
      // tues
      tuesStartTime0: currentDateUtcStart,
      tuesEndTime0: currentDateUtcEnd,
      tuesStartTime1: "",
      tuesEndTime1: "",
      tuesStartTime2: "",
      tuesEndTime2: "",
      // wed
      wedStartTime0: currentDateUtcStart,
      wedEndTime0: currentDateUtcEnd,
      wedStartTime1: "",
      wedEndTime1: "",
      wedStartTime2: "",
      wedEndTime2: "",
      //thurs

      thursStartTime0: currentDateUtcStart,
      thursEndTime0: currentDateUtcEnd,
      thursStartTime1: "",
      thursEndTime1: "",
      thursStartTime2: "",
      thursEndTime2: "",
      //fri

      friStartTime0: currentDateUtcStart,
      friEndTime0: currentDateUtcEnd,
      friStartTime1: "",
      friEndTime1: "",
      friStartTime2: "",
      friEndTime2: "",

      //sat

      satStartTime0: "",
      satEndTime0: "",
      satStartTime1: "",
      satEndTime1: "",
      satStartTime2: "",
      satEndTime2: "",
    },
    validationSchema: addAvailabilityValidation,
    onSubmit: async (values) => {
      if ((values.fromDate > values.toDate) && (values.fromDate !== values.toDate)) return toast.warn(" Start date can not be greater than end date");
      let data =
      {
        startDate: moment(values.fromDate).startOf('day').utc(),
        endDate: moment(values.toDate).endOf('day').utc(),
        clinicId: userdata?.location?.clinicId,
        staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
        roleTitle: userdata.roleTitle,
        providerId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.providerId : userdata?.userId,
        frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,

        timeZone: values?.timeZone?.[0]?.timezone,
        availableDays: [
          {
            id: 0,
            name: "Sunday",
            isChecked: availabilityDefault[0].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 1,
            name: "Monday",
            isChecked: availabilityDefault[1].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 2,
            name: "Tuesday",
            isChecked: availabilityDefault[2].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 3,
            name: "Wednesday",
            isChecked: availabilityDefault[3].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 4,
            name: "Thursday",
            isChecked: availabilityDefault[4].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 5,
            name: "Friday",
            isChecked: availabilityDefault[5].isChecked,
            arrayOfTimings: [],
          },
          {
            id: 6,
            name: "Saturday",
            isChecked: availabilityDefault[6].isChecked,
            arrayOfTimings: [],
          },
        ],
      };

      //  sunday condition
      if (new Date(values.sunStartTime0).getTime() === new Date(values.sunEndTime0).getTime())
        return toast.warn("Sunday start time and  end time can not be equal");
      if (values.sunStartTime0 > values.sunEndTime0)
        return toast.warn("Sunday start time  can not be greater than end time");
      if (values.sunStartTime0 && values.sunEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.sunStartTime0, values.sunEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[0].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });

      }
      if (new Date(values.sunStartTime1).getTime() === new Date(values.sunEndTime1).getTime())
        return toast.warn("Sunday start time and  end time can not be equal");
      if (values.sunStartTime1 > values.sunEndTime1)
        return toast.warn(
          "Sunday second start time  can not be greater than end  time"
        );
      if (values.sunStartTime1 && values.sunEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.sunStartTime1, values.sunEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[0].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.sunStartTime2).getTime() === new Date(values.sunEndTime2).getTime())
        return toast.warn("Sunday start time and  end time can not be equal");
      if (values.sunStartTime2 > values.sunEndTime2)
        return toast.warn(
          "Sunday third start time  can not be greater than end time"
        );
      if (values.sunStartTime2 && values.sunEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.sunStartTime2, values.sunEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[0].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[0].isChecked === false) {
        data.availableDays[0].arrayOfTimings.splice(0, data.availableDays[0].arrayOfTimings.length)
      }
      if (data.availableDays[0].isChecked === true) {
        if (data.availableDays[0].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }


      // console.log(values.monStartTime0.format("LT"), values.monEndTime0.format("LT"))
      // console.log(values.monEndTime0.diff(values.monStartTime0))


      //  monday condition
      if (new Date(values.monStartTime0).getTime() === new Date(values.monEndTime0).getTime())
        return toast.warn("Monday start time and  end time can not be equal");
      if (values.monStartTime0 > values.monEndTime0)
        return toast.warn(
          "Monday  start time  can not be greater than end "
        );

      if (values.monStartTime0 < values.monEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.monStartTime0, values.monEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[1].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.monStartTime1).getTime() === new Date(values.monEndTime1).getTime())
        return toast.warn("Monday start time and  end time can not be equal");
      if (values.monStartTime1 > values.monEndTime1)
        return toast.warn(
          "Monday second end  time can not be greater than start time"
        );
      if (values.monStartTime1 && values.monEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.monStartTime1, values.monEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[1].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.monStartTime2).getTime() === new Date(values.monEndTime2).getTime())
        return toast.warn("Monday start time and  end time can not be equal");
      if (values.monStartTime2 > values.monEndTime2)
        return toast.warn(
          "Monday third  start time can not be greater than  end  time"

        );
      if (values.monStartTime2 && values.monEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.monStartTime2, values.monEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[1].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[1].isChecked === false) {
        data.availableDays[1].arrayOfTimings.splice(0, data.availableDays[1].arrayOfTimings.length)
      }
      if (data.availableDays[1].isChecked === true) {
        if (data.availableDays[1].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }
      //  tuesday condition
      if (new Date(values.tuesStartTime0).getTime() === new Date(values.tuesEndTime0).getTime())
        return toast.warn("Tuesday start time and  end time can not be equal");
      if (values.tuesStartTime0 > values.tuesEndTime0)
        return toast.warn(
          "Tuesday  start time  can not be greater than end  time "
        );
      if (values.tuesStartTime0 && values.tuesEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.tuesStartTime0, values.tuesEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[2].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.tuesStartTime1).getTime() === new Date(values.tuesEndTime1).getTime())
        return toast.warn("Tuesday start time and  end time can not be equal");
      if (values.tuesStartTime1 > values.tuesEndTime1)
        return toast.warn(
          "Tuesday second start time  can not be greater than end  time "
        );
      if (values.tuesStartTime1 && values.tuesEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.tuesStartTime1, values.tuesEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[2].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.tuesStartTime2).getTime() === new Date(values.tuesEndTime2).getTime())
        return toast.warn("Tuesday start time and  end time can not be equal");
      if (values.tuesStartTime2 > values.tuesEndTime2)
        return toast.warn(
          "Tuesday third start time  can not be greater than end  time "
        );
      if (values.tuesStartTime2 && values.tuesEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.tuesStartTime2, values.tuesEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[2].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[2].isChecked === false) {
        data.availableDays[2].arrayOfTimings.splice(0, data.availableDays[2].arrayOfTimings.length)
      }
      if (data.availableDays[2].isChecked === true) {
        if (data.availableDays[2].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }
      //  wednesday condition
      if (new Date(values.wedStartTime0).getTime() === new Date(values.wedEndTime0).getTime())
        return toast.warn("Wednesday start time and  end time can not be equal");
      if (values.wedStartTime0 > values.wedEndTime0)
        return toast.warn(
          " Wednesday   start time  can not be greater than end  time "
        );
      if (values.wedStartTime0 && values.wedEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.wedStartTime0, values.wedEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[3].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.wedStartTime1).getTime() === new Date(values.wedEndTime1).getTime())
        return toast.warn("Wednesday start time and  end time can not be equal");
      if (values.wedStartTime1 > values.wedEndTime1)
        return toast.warn(
          " Wednesday second  start time  can not be greater than end  time "
        );
      if (values.wedStartTime1 && values.wedEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.wedStartTime1, values.wedEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[3].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.wedStartTime2).getTime() === new Date(values.wedEndTime2).getTime())
        return toast.warn("Wednesday start time and  end time can not be equal");
      if (values.wedStartTime2 > values.wedEndTime2)
        return toast.warn(
          " Wednesday third  start time  can not be greater than end  time "
        );
      if (values.wedStartTime2 && values.wedEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.wedStartTime2, values.wedEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[3].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[3].isChecked === false) {
        data.availableDays[3].arrayOfTimings.splice(0, data.availableDays[3].arrayOfTimings.length)
      }
      if (data.availableDays[3].isChecked === true) {
        if (data.availableDays[3].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }

      //  thursday  condition
      if (new Date(values.thursStartTime0).getTime() === new Date(values.thursEndTime0).getTime())
        return toast.warn("Thursday start time and  end time can not be equal");
      if (values.thursStartTime0 > values.thursEndTime0)
        return toast.warn(
          " Thursday  start time  can not be greater than end  time "
        );
      if (values.thursStartTime0 && values.thursEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.thursStartTime0, values.thursEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[4].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.thursStartTime1).getTime() === new Date(values.thursEndTime1).getTime())
        return toast.warn("Thursday start time and  end time can not be equal");
      if (values.thursStartTime1 > values.thursEndTime1)
        return toast.warn(
          " Thursday second start time  can not be greater than end  time "
        );
      if (values.thursStartTime1 && values.thursEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.thursStartTime1, values.thursEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[4].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.thursStartTime2).getTime() === new Date(values.thursEndTime2).getTime())
        return toast.warn("Thursday start time and  end time can not be equal");
      if (values.thursStartTime2 > values.thursEndTime2)
        return toast.warn(
          " Thursday third start time  can not be greater than end  time "
        );
      if (values.thursStartTime2 && values.thursEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.thursStartTime2, values.thursEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[4].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[4].isChecked === false) {
        data.availableDays[4].arrayOfTimings.splice(0, data.availableDays[4].arrayOfTimings.length)
      }
      if (data.availableDays[4].isChecked === true) {
        if (data.availableDays[4].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }
      //  frisday  condition
      if (new Date(values.friStartTime0).getTime() === new Date(values.friEndTime0).getTime())
        return toast.warn("Friday start time and  end time can not be equal");
      if (values.friStartTime0 > values.friEndTime0)
        return toast.warn(
          " Friday  start time  can not be greater than end  time "
        );
      if (values.friStartTime0 && values.friEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.friStartTime0, values.friEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[5].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.friStartTime1).getTime() === new Date(values.friEndTime1).getTime())
        return toast.warn("Friday start time and  end time can not be equal");
      if (values.friStartTime1 > values.friEndTime1)
        return toast.warn(
          " Friday second start time  can not be greater than end  time "
        );
      if (values.friStartTime1 && values.friEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.friStartTime1, values.friEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[5].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.friStartTime2).getTime() === new Date(values.friEndTime2).getTime())
        return toast.warn("Friday start time and  end time can not be equal");
      if (values.friStartTime2 > values.friEndTime2)
        return toast.warn(
          " Friday third start time  can not be greater than end time "
        );
      if (values.friStartTime2 && values.friEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.friStartTime2, values.friEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[5].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[5].isChecked === false) {
        data.availableDays[5].arrayOfTimings.splice(0, data.availableDays[5].arrayOfTimings.length)
      }
      if (data.availableDays[5].isChecked === true) {
        if (data.availableDays[5].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }
      //  saturday  condition
      if (new Date(values.satStartTime0).getTime() === new Date(values.satEndTime0).getTime())
        return toast.warn("Saturday start time and  end time can not be equal");
      if (values.satStartTime0 > values.satEndTime0)
        return toast.warn(
          " Saturday  start time  can not be greater than end  time "
        );
      if (values.satStartTime0 && values.satEndTime0) {
        const { startDateTime, endDateTime } = setTime(values.satStartTime0, values.satEndTime0, values?.timeZone?.[0]?.timezone)
        data.availableDays[6].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.satStartTime1).getTime() === new Date(values.satEndTime1).getTime())
        return toast.warn("Saturday start time and  end time can not be equal");
      if (values.satStartTime1 > values.satEndTime1)
        return toast.warn(
          " Saturday second start time  can not be greater than end  time "
        );
      if (values.satStartTime1 && values.satEndTime1) {
        const { startDateTime, endDateTime } = setTime(values.satStartTime1, values.satEndTime1, values?.timeZone?.[0]?.timezone)
        data.availableDays[6].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (new Date(values.satStartTime2).getTime() === new Date(values.satEndTime2).getTime())
        return toast.warn("Saturday start time and  end time can not be equal");
      if (values.satStartTime2 > values.satEndTime2)
        return toast.warn(
          " Saturday third start time  can not be greater than end  time "
        );
      if (values.satStartTime2 && values.satEndTime2) {
        const { startDateTime, endDateTime } = setTime(values.satStartTime2, values.satEndTime2, values?.timeZone?.[0]?.timezone)
        data.availableDays[6].arrayOfTimings.push({
          startTime: moment.tz(startDateTime, values?.timeZone?.[0]?.timezone).utc(),
          endTime: moment.tz(endDateTime, values?.timeZone?.[0]?.timezone).utc(),
        });
      }
      if (data.availableDays[6].isChecked === false) {
        data.availableDays[6].arrayOfTimings.splice(0, data.availableDays[6].arrayOfTimings.length)
      }
      if (data.availableDays[6].isChecked === true) {
        if (data.availableDays[6].arrayOfTimings.length === 0) {
          return toast.warn("Please enter time")
        }
      }
      if (data.availableDays[0].isChecked === false && data.availableDays[1].isChecked === false &&
        data.availableDays[2].isChecked === false && data.availableDays[3].isChecked === false &&
        data.availableDays[4].isChecked === false && data.availableDays[5].isChecked === false &&
        data.availableDays[5].isChecked === false) return toast.warn("Please  set availability")
      setloader(true)
      try {
        let response = await INSERT_AVAILABILITY(data)
        toast.success(response?.message)
        setloader(false)
        navigate("/availability")
      } catch (error) {
        setloader(false)
        toast.error(error?.message)
      }
    },
  });
  const handalSearchAbleSelectBox = (event, newValue, name) => {
    if (name === "timeZone") {
      AvailabilityAdd.setFieldValue(name, newValue)
    } else {
      AvailabilityAdd.setFieldValue(name, newValue)
    }
  }
  return {
    loader,
    AvailabilityAdd,
    clinicList,
    availabilityDefault,
    clinicModal,
    listTimeZone,
    minimumdate,
    userdata,
    showHideTimings,
    addtimes,
    removetime,
    removeSection,
    navigate,
    setClinicModal,
    handalSearchAbleSelectBox,
  }
}

export default useSetAvailability
