import React, { memo } from 'react'
import { SearchAbleSelectBox, SelectBox } from "../../../Component/Inputs";
import { Errors } from '../../../Component/Error';
import { Avatar, Chip, } from "@mui/material";
import { RoleTitle, VisitTypeOptions } from '../../../Context/constants';
import { Modal } from 'react-bootstrap';
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import TextBox from '../../../Component/TextBox';
const BookAppointmentPopup = memo(({ setShow2, show2, apptBook, CustomButton }) => {
    return (
        <Modal show={show2} onHide={() => setShow2(false)} centered >
            <Modal.Body>
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-calendar-check all_icons_color mb-2"></i>
                    <p className="mb-0">Are you sure?</p>
                    <p>You want to Book this appointment.</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => apptBook()} > Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => setShow2(false)} > No</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})

const RejectAppointmentModal = memo(({ show, setShow, apptReject, CustomButton }) => {
    return (
        <Modal show={show} onHide={() => setShow(false)} centered >
            <Modal.Body>
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-ban all_icons_color mb-2"></i>
                    <p className="mb-0">Are you sure?</p>
                    <p>You want to Reject this appointment.</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => apptReject()} > Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => setShow(false)} > No</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})
const RemovePatientModal = memo(({ show, handleClosedModal, handleRemovePatinet, CustomButton }) => {
    return (
        <Modal show={show} onHide={() => handleClosedModal("removePatient")} centered >
            <Modal.Body>
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-ban all_icons_color mb-2"></i>
                    <p className="mb-0">Are you sure?</p>
                    <p>You want to remove this patient.</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => handleRemovePatinet()} > Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => handleClosedModal("removePatient")} > No</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})


const CreateWaitingListModal = memo(({ CustomButton, openModal, patientFilterList, servicesList, teamList, clinicList, TimeslotList, SlotLodaer, bookAppointmentModal, handleClosedModal, handalSearchAbleSelectBox, createAppointment, userdata
    , Staff_DETAILS, isActiveSlot, setIsactiveSlot, moment, offersList, applyByCouponCodeOffers, cancelOffer, ApplyOffer, OFERS_DETAILS, SERVICES_DETAILS, check, setCheck }) => {
    const price = OFERS_DETAILS?.discountPrice ?? SERVICES_DETAILS?.variablePrice ?? SERVICES_DETAILS?.fixedPrice ?? "";
    console.log(openModal, 'openModal')
    return (
        <Modal size='xl' modal="true" show={openModal?.addPatient || openModal?.bookAppointment} onHide={() => handleClosedModal(openModal?.bookAppointment ? "bookAppointment" : 'addPatient')} centered >
            <Modal.Body>
                {/* <div className="text-start">
                    <p className="modal-title mb-0">{openModal?.bookAppointment ? "Create Appointment " : "Create Waiting List"} </p>
                </div> */}
                <div className="d-flex justify-content-between">
                    <p className="modal-title mb-0 page_name">{openModal?.bookAppointment ? "Create Appointment " : "Create Waiting List"}</p>
                    <span className='cursor' onClick={() => handleClosedModal(openModal?.bookAppointment ? "bookAppointment" : 'addPatient')}> <ClearOutlinedIcon /></span>
                </div>
                <div className="row modalform">
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="text" className="form-control"
                                    maxLength={25}
                                    value={userdata?.location?.clinicName}
                                    readOnly
                                />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Clinic<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <TextBox textBoxData={<p>{userdata?.location?.clinicName}</p>} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    disabled={!servicesList.length}
                                    multiple={false}
                                    option={servicesList}
                                    getOptionLabel="serviceName"
                                    isOptionEqualToValue="serviceId"
                                    value={createAppointment.values.service}
                                    name="service"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={createAppointment} name={"service"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        {
                                            openModal?.bookAppointment ? <TextBox textBoxData={<p>{`${openModal?.data?.serviceName} (${openModal?.data?.specialisationName})`}</p>} /> :
                                                <SearchAbleSelectBox
                                                    disabled={openModal?.bookAppointment || !servicesList.length ? true : false}
                                                    multiple={false}
                                                    option={servicesList}
                                                    getOptionLabel="serviceName"
                                                    isOptionEqualToValue="serviceId"
                                                    value={createAppointment.values.service}
                                                    name="service"
                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    parenthesesData="specializationName"
                                                />
                                        }
                                        <Errors formikfun={createAppointment} name={"service"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Patients<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    extraLable="lastName"
                                    getOptionLabel="firstName"
                                    multiple={true}
                                    option={patientFilterList}
                                    isOptionEqualToValue="patientId"
                                    value={createAppointment.values.patientName}
                                    name="patient"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={createAppointment} name={"patientName"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Patients<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        {
                                            openModal?.bookAppointment ? <TextBox textBoxData={<p className='d-flex flex-wrap' style={{ gap: "5px" }}>{openModal?.data?.patientDetails?.map((cur, index) => {
                                                return <Chip
                                                    size='small'
                                                    key={cur?.patientId || index}
                                                    label={`${cur?.patientFirstName || ""} ${cur?.patientLastName || ""}`}
                                                    style={{ color: `#fff`, background: `#12BABA`, }}
                                                />
                                            })}</p>} /> :
                                                <SearchAbleSelectBox
                                                    disabled={openModal?.bookAppointment ? true : false}
                                                    extraLable="lastName"
                                                    getOptionLabel="firstName"
                                                    multiple={true}
                                                    option={patientFilterList}
                                                    isOptionEqualToValue="patientId"
                                                    value={createAppointment.values.patientName}
                                                    name="patient"
                                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                />
                                        }

                                        <Errors formikfun={createAppointment} name={"patientName"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    {
                        (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) ?
                            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                {/* <div className="row">
                                    <div className="col-3">
                                        <label className="form-label mb-0">Staff<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-4">
                                        <SearchAbleSelectBox
                                            disabled={!servicesList?.length || !teamList?.length}
                                            multiple={false}
                                            option={teamList}
                                            extraLable="lastName"
                                            getOptionLabel="firstName"
                                            isOptionEqualToValue="userId"
                                            value={createAppointment.values.teamMember}
                                            name="team"
                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                        />
                                        <Errors formikfun={createAppointment} name={"teamMember"} />
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <label className="form-label mb-0">Team<span className='Star_Mark'>*</span></label>
                                            </div>
                                            <div className="col-lg-7">
                                                {
                                                    openModal?.bookAppointment ? <TextBox textBoxData={<p>{`${openModal?.data?.doctorFirstName||""} ${openModal?.data?.doctorLastName||""} `}</p>} /> :
                                                        <SearchAbleSelectBox
                                                            disabled={openModal?.bookAppointment || (!servicesList?.length || !teamList?.length) ? true : false}
                                                            multiple={false}
                                                            option={teamList}
                                                            extraLable="lastName"
                                                            getOptionLabel="firstName"
                                                            isOptionEqualToValue="userId"
                                                            value={createAppointment.values.teamMember}
                                                            name="team"
                                                            handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                        />
                                                }

                                                <Errors formikfun={createAppointment} name={"teamMember"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        {/* <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="date" className="form-control position-relative" name="date"
                                    value={createAppointment.values.date }
                                    value={moment(createAppointment.values.date).format("YYYY-MM-DD")}
                                    // value={createAppointment.values.date }
                                    value={createAppointment.values.date?moment(createAppointment.values.date).format("YYYY-MM-DD"):""}

                                    onChange={(e) => { createAppointment.setFieldValue('date', e.target.value); }} />
                                <Errors formikfun={createAppointment} name={"date"} />
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <input type="date" className="form-control position-relative" name="date"
                                            // value={createAppointment.values.date }
                                            value={moment(createAppointment.values.date).format("YYYY-MM-DD")}

                                            onChange={(e) => { createAppointment.setFieldValue('date', e.target.value); }} />
                                        <Errors formikfun={createAppointment} name={"date"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    {
                        openModal?.addPatient ? <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Appointment Time<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4 d-flex justify-content-between">
                                    <TimePicker placeholder="Select Start Time" use12Hours showSecond={false} focusOnOpen={true} name={`startTime`}
                                        format="hh:mm A"
                                        onChange={(e) => { createAppointment.setFieldValue(`startTime`, e) }} value={createAppointment.values.startTime ? createAppointment.values.startTime : null}
                                    />
                                    <TimePicker placeholder="Select End Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                        name={`endTime`}
                                        onChange={(e) => createAppointment.setFieldValue(`endTime`, e)} value={createAppointment.values.endTime ? createAppointment.values.endTime : null}
                                    />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Appointment Time<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7 d-flex justify-content-between">
                                            <TimePicker placeholder="Select Start Time" className='pe-2' use12Hours showSecond={false} focusOnOpen={true} name={`startTime`}
                                                format="hh:mm A"
                                                onChange={(e) => {
                                                    if (openModal?.addPatient && createAppointment?.values?.service?.length > 0) {
                                                        createAppointment.setFieldValue(`startTime`, e)
                                                        let endDate = moment(e).add(createAppointment?.values?.service[0]?.duration, 'minutes').toISOString();
                                                        createAppointment.setFieldValue(`endTime`, endDate)
                                                    } else {
                                                        createAppointment.setFieldValue(`startTime`, e)
                                                    }
                                                }} value={createAppointment.values.startTime ? moment(createAppointment.values.startTime) : null}
                                            />
                                            <TimePicker disabled placeholder="Select End Time" className='ps-2' use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A"
                                                name={`endTime`}

                                                onChange={(e) => createAppointment.setFieldValue(`endTime`, e)} value={createAppointment.values.endTime ? moment(createAppointment.values.endTime) : null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div> : <div className="col-sm-12  pt-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-3">Slots Available<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-9">
                                    {TimeslotList?.length === 0 && SlotLodaer === false && <p className=" text-start">No Slot Available</p>}
                                    {createAppointment.values.date && ((userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) ? Staff_DETAILS?.userId : userdata.userId) && TimeslotList?.length === 0 && SlotLodaer === true && <div className="w-100 d-flex justify-content-start mb-3">
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    }
                                    <div className="row show_list_time_slot d-flex justify-content-start " style={{ height: TimeslotList?.length > 0 && createAppointment.values.date && "150px" }}>
                                        <div className="col pb-0">
                                            {
                                                createAppointment.values.date && TimeslotList?.map((item, index) => {
                                                    return <React.Fragment key={index}>
                                                        <button
                                                            type="button"
                                                            disabled={item.status === 'Booked' || item.status === 'Unavailable' ? true : false}
                                                            style={{ backgroundColor: (isActiveSlot === index ? '#38C4B9' : '#fff'), color: (item.status === 'Booked' || item.status === 'Unavailable' ? 'grey' : (isActiveSlot === index ? '#fff' : '#38C4B9')), width: '160px', fontSize: "14px", height: "47px", padding: "5px", border: (item.status === 'Booked' || item.status === 'Unavailable' ? '1px solid grey' : '1px solid #38C4B9') }}
                                                            className="btn mb-2 me-2 "
                                                            onClick={() => {
                                                                setIsactiveSlot(index);
                                                                createAppointment.setFieldValue("startTime", item.startTime);
                                                                createAppointment.setFieldValue("endTime", item.endTime)
                                                            }}
                                                        >
                                                            {moment(item.startTime).tz(userdata?.timezoneName).format('LT')} - {moment(item.endTime).tz(userdata?.timezoneName).format('LT')} {(item.status === 'Booked') ? 'Booked' : (item.status === 'Unavailable') ? 'Unavailable' : null}
                                                        </button>
                                                    </React.Fragment>
                                                })
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                    {
                        offersList.length > 0 && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Appointment Date<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control"
                                        maxLength={25}
                                        value={createAppointment?.values?.couponCode}
                                        id="inputAddress" placeholder=""
                                        onChange={(e) => createAppointment?.setFieldValue("couponCode", e.target.value)}
                                    />
                                    <Errors formikfun={createAppointment} name={"couponCode"} />
                                </div>
                                <div className="col-sm-4 text-start">
                                    <CustomButton variant="contained" type="button"
                                        onClick={applyByCouponCodeOffers}>Apply</CustomButton>
                                </div>
                                <div className="col-sm-12  mb-3">
                                    <div className="row px-3 offers_list_avilability">
                                        {offersList?.map((cur, index) => {
                                            return <React.Fragment key={cur?.offerId}>
                                                <div className="col-12 offers_Card_avilability mb-2 ">
                                                    <div className="w-100 d-flex" >
                                                        <Avatar
                                                            alt="Offers"
                                                            src={require("../../../Assets/Images/offers2.png")}
                                                            sx={{ width: 30, height: 30 }}
                                                        />
                                                        <p className="name">{cur?.offerName}</p>
                                                    </div>
                                                    <div className="w-100 d-flex justify-content-between mt-2">
                                                        <p className="code px-2 ">{cur?.couponCode}</p>
                                                        <p className="type">{`${cur?.offerType === "FLAT" ? `Flat ${cur?.currencySymbol}${cur?.discountAmount} discount` : `${cur.percentage}% discount`}`}</p>
                                                    </div>
                                                    <div className="apply" >
                                                        <div className="d-flex">
                                                            <p className="price" style={{ textDecoration: cur?.discountPrice ? "line-through" : "none" }}>{cur?.currencySymbol}{cur?.fixedPrice}</p>
                                                            <p className="discount">{`${cur?.discountPrice ? cur?.currencySymbol : ""}`}{cur?.discountPrice}</p>
                                                        </div>
                                                        {
                                                            cur?.discountPrice ? <CustomButton variant="contained" type="button" style={{ background: "red", padding: "1px 2px 1px 2px" }}
                                                                onClick={() => cancelOffer(cur)}>Remove</CustomButton> :
                                                                <CustomButton variant="contained" type="button" style={{ padding: "1px 2px 1px 2px" }}
                                                                    onClick={() => ApplyOffer(cur)}>Apply</CustomButton>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Total Price<span className='Star_Mark'></span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <TextBox textBoxData={<p>{`${SERVICES_DETAILS?.currencyImage ? SERVICES_DETAILS?.currencyImage : ""}${price}`}</p>} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <label className="form-label mb-0">Visit Type<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-lg-7">
                                        <SelectBox
                                            placeholder="Select Visit Type"
                                            value={createAppointment.values.visitType}
                                            name="visitType"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={(e) => createAppointment.setFieldValue("visitType", e.target.value)}
                                            option={VisitTypeOptions} />
                                        <Errors formikfun={createAppointment} name={"visitType"} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pt-3  " >
                        <CustomButton variant="contained" type="button" onClick={() => { createAppointment.handleSubmit() }}>Submit</CustomButton>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
})
export { BookAppointmentPopup, RejectAppointmentModal, CreateWaitingListModal, RemovePatientModal }
