import React from "react";
import { ToastContainer } from "react-toastify";
import { Errors } from "../../../Component/Error";
import { Avatar, Badge, styled } from "@mui/material";
import Loading from "../../../Component/Loading";
import { motion } from 'framer-motion';
import useBusinessDetails from "./Hooks/useBusinessDetails";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { Modal } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { AddBusinessDetails } from "../../../Redux/ReducerFile/BusinessDetails";
import { clinicPictures, userImageFilePath } from "../../../Context/api";
import DeleteProfile from "../../../Component/DeleteProfile";
import { MdOutlineCameraAlt } from "react-icons/md";
import { SelectBox } from "../../../Component/Inputs";
import { AddClinicFildData } from "../../../Redux/ReducerFile/ClinicFildData";
import { FaIdCard } from "react-icons/fa";
const BusinessDetails = () => {
  const {
    loader,
    formik,
    clinicDelete,
    SetclinicDetele,
    handleClose,
    navigate, dispatch, categoryList,
    clinicList, handleScroll, imgUrl, setImgUrl, UploadImage, userData, FileRef, setLoader
  } = useBusinessDetails()
  const { CustomButton } = useMuiCompontent()
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    color: "#fff"
  }));
  return (
    <>
      {loader && <Loading />}
      <ToastContainer />
      <div className="d-flex justify-content-center" style={{ overflow: "hidden" }}>
        <div className="common-component-box " >
          <header className="body_header" >
            <div className="d-flex justify-content-start align-items-center">
              <h2>Business Details</h2>
            </div>
          </header>
          <motion.div className="common-white-bg-box shadow mx-0 position-relative pt-3"
            initial={{ x: 700 }}
            animate={{ x: 0 }}
            exit={{ x: -700 }}
            transition={{ duration: 0.6 }}
          >

            <div className="h-100 d-flex justify-content-between flex-column">
              <div className="row px-4">
                <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                  <div className="row">
                    <div className="col-3">
                      <label className="form-label mb-0">Business Name<span className='Star_Mark'>*</span></label>
                    </div>
                    <div className="col-4">
                      <input
                        type="text"
                        className="form-control"
                        name="businessName"
                        maxLength={50}
                        {...formik.getFieldProps("businessName")}
                      />
                      <Errors formikfun={formik} name={"businessName"} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12  py-2  " style={{ borderBottom: "2px solid #ededed" }}>
                  <div className="row">
                    <div className="col-3">
                      <label className="form-label mb-0">Display Picture</label>
                      <p style={{ color: "gray", fontSize: "13px" }}>This will be displayed on your profile.</p>
                    </div>
                    <div className="col-4 d-flex  align-items-start flex-column">
                      <Badge
                        style={{ background: "#fff" }}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <SmallAvatar alt="Remy Sharp"  >
                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                            </MdOutlineCameraAlt>
                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "BUSINESSPROFILE", setLoader, setImgUrl,imgUrl,userData?.userId)} />
                          </SmallAvatar>
                        }
                      >
                        <Avatar  sx={{ width: 80, height: 80 }} src={userImageFilePath + imgUrl} >
                        </Avatar>
                      </Badge>
                      {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "BUSINESSPROFILE", id: userData?.userId }} isDeleted={true} />}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                  <div className="row">
                    <div className="col-3">
                      <label className="form-label mb-0">Business Category<span className='Star_Mark'>*</span></label>
                    </div>
                    <div className="col-4">
                      <SelectBox
                        placeholder="Select Category"
                        value={formik?.values?.businessCategoryId}
                        name="businessCategoryId"
                        keys="businessCategoryId"
                        label="categoryName"
                        menuValeu="businessCategoryId"
                        handleChange={(e) => formik.setFieldValue("businessCategoryId", e.target.value)}
                        option={categoryList}
                      />
                      <Errors formikfun={formik} name={"businessCategoryId"} />
                    </div>
                  </div>
                </div>
                <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                  <div className="row">
                    <div className="col-3">
                      <label className="form-label mb-0">About Us<span className='Star_Mark'>*</span></label>
                    </div>
                    <div className="col-4 mobile_number_input_box">
                      <div className="input_group_mobile mb-0">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="2"
                          name="about"
                          {...formik.getFieldProps("about")}

                        ></textarea>
                        <Errors formikfun={formik} name={"about"} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="col-sm-12 my-2">
                    {/* clinic List  */}
                    <div className=" bgclinic">
                      <h5 >Add Clinic</h5>
                      <button>
                        <MdAdd className="addicon" onClick={() => { navigate("addClinic"); localStorage.removeItem("service"); dispatch(AddBusinessDetails({ ...formik.values, imgUrl })); dispatch(AddClinicFildData({})) }} />
                      </button>
                    </div>
                  </div>
                  <div className="col-12 mb-2 " >
                    {
                      clinicList.length === 0 && <>
                        <div className="col-sm-12 text-center "><h3 style={{ color: "gray", fontSize: "20px" }}>No record found  </h3></div>
                      </>
                    }
                    <div className="show_list_time_slot" onScroll={handleScroll} horizontal="true" autohide="false" style={{ maxHeight: '300px', width: '100%' }}>
                      {/* {clinicList?.map((cur, index) => {
                        return <React.Fragment key={index}>
                          <div style={{ backgroundColor: `${cur?.activeStatus ? "#fff" : cur?.activeStatus === false ? "#e8e8e8" : ""}` }} className={`col-sm-12 pe-0 pe-1 py-1 border alreadytext mt-0 mb-4 shadow-sm  d-flex justify-content-between align-items-center`} >
                            <p className=" text-dark mb-0">
                              <i className="fa-solid fa-location-dot" style={{ color: `${cur?.activeStatus ? "#38C4B9" : cur?.activeStatus === false ? "#000" : ""}` }} ></i> &nbsp; {`${cur.clinicName}`}<br /> {`${cur?.address}, ${cur?.street}, ${cur.city}, ${cur.stateName}, ${cur.countryName}, ${cur.zip} `}
                            </p>
                          </div>
                        </React.Fragment>
                      })} */}

                      {
                        clinicList?.map((cur, index) => {
                          return <div key={index} className="col-12 d-flex mt-3">
                            <Avatar
                              alt={cur?.clinicName?.toUpperCase()}
                              sx={{ width: 40, height: 40 }}
                              src={clinicPictures + cur?.image}
                            />
                            <div className='ms-3 d-flex justify-content-between align-items-start ' style={{ borderBottom: "1.2px solid #ededed", width: "100%" }}>
                              <div className=''>
                                <h6 className="">{cur.clinicName}</h6>
                                <ul className='d-lg-flex'>
                                  <li className='me-3' style={{color:"gray",fontSize:"13px"}}><FaIdCard className='me-2' /> {cur?.clinicRegistrationNumber}</li>
                                  <li style={{color:"gray",fontSize:"13px"}}><i className="fa-solid fa-location-dot me-2" style={{ color: `${cur?.activeStatus ? "#38C4B9" : cur?.activeStatus === false ? "#000" : ""}` }} ></i>{` ${cur?.address},${cur?.street},${cur?.city},${cur?.stateName},${cur?.countryName},${cur?.zip} `}</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        })
                      }

                    </div>

                  </div>
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end align-items-end">
                <div className="p-3">
                  <CustomButton type="button" onClick={formik.handleSubmit} variant="contained"  > Save</CustomButton>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {/* delete modal  */}
      <Modal show={clinicDelete} onHide={() => SetclinicDetele(false)} centered>
        <Modal.Body className='px-0'>
          <div className="modal-body text-center cus-i px-0">
            <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
            <p className='mb-0'>Are you sure?</p>
            <p>You want to Delete this clinic</p>
            <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES")} > Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BusinessDetails;
