import { Avatar, Fab } from '@mui/material';
import React from 'react'
import { IoMdAdd } from 'react-icons/io'
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ChatGroupFilePath, PatientImageFilePath, TeamImageFilePath, userImageFilePath } from '../../Context/api';
import moment from 'moment-timezone';
import { RoleTitle } from '../../Context/constants';
const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));
const Sidebar =({ handleReadMessage,messageNotification, handleLeaveGroup, activeUser, userInfo, handleChangeTab, Tab, handleOpenModal, handleScrollConversationList, conversationList, handleSearchConvertaion, seachConversationValue, handleDeleteCoversation, handleBlockUser }) => {
    return (
        <>
            <div className="HEADER">
                <ul className="nav">
                    <li className="nav-item" role="presentation">
                        <button className={`teams_frofile_stepper_btn ${Tab === "STAFF" && "active_teams_frofile"} `} onClick={() => handleChangeTab("STAFF")} type="button" >
                            Team
                            {messageNotification?.STAFF ? <span></span> : null}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`teams_frofile_stepper_btn  ${Tab === "GROUP" && "active_teams_frofile"} `} type="button" onClick={() => handleChangeTab("GROUP")}  >
                            Groups
                            {messageNotification?.GROUP ? <span></span> : null}
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className={`teams_frofile_stepper_btn  ${Tab === "PATIENT" && "active_teams_frofile"}`} type="button" onClick={() => handleChangeTab("PATIENT")}  >
                            Patients
                            {messageNotification?.PATIENT ? <span></span> : null}
                        </button>
                    </li>
                </ul>
            </div>
            <div className='SEARCH_BOX px-2'>
                <input type="text" placeholder="Search" value={seachConversationValue} onChange={handleSearchConvertaion} className="form-control filter_input me-2" name="services" />
                <Fab onClick={() => handleOpenModal(Tab)} size="small" style={{ background: "#38C4B9", color: "#fff", width: "35px", height: "30px", minHeight: "0px" }} aria-label="add" className=''>
                    <IoMdAdd style={{ fontSize: "20px" }} />
                </Fab>
            </div>
            {
                Tab === "STAFF" && <div className="USERS_LIST px-2" onScroll={handleScrollConversationList}>
                    {conversationList?.map((cur, index) => {
                        return <div key={cur?.conversationId} className={`USER_LIST_ITEAM position-relative mb-3 ${userInfo?.conversationId === cur?.conversationId ? "ACTIVE_USER" : ""} `} onClick={() => { activeUser(cur);handleReadMessage(cur?.conversationId,cur?.unReadMessages) }}>
                            <div className='d-flex w-75' >
                                <Avatar alt={cur?.userDetails?.firstName + " " + cur?.userDetails?.lastName} src={(cur?.userDetails?.roleTitle === RoleTitle.STAFF||cur?.userDetails?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + cur?.userDetails?.image) : (userImageFilePath + cur?.userDetails?.image)} />
                                <div className='ms-3 ' style={{width:"70%"}}>
                                    <p className="USER_NAME"> {cur?.userDetails?.firstName + " " + cur?.userDetails?.lastName}   </p>
                                    <p className="LAST_MESSAGE">{cur?.lastMessage?.message}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end pe-2' style={{width:"30%"}}>
                                <p className='LAST_MESSAGE_TIME'>{cur?.lastMessage?.messageTime ? moment(cur?.lastMessage?.messageTime).format('hh:mm A') : ""}</p>
                                {
                                    cur?.unReadMessages > 0 && <div className='NOTIFICATION'> {cur?.unReadMessages}</div>
                                }
                                <>
                                    <MoreHorizIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li style={{ cursor: "pointer" }}><button className="dropdown-item" onClick={() => handleDeleteCoversation(cur?.conversationId)} >Delete Conversation</button></li>
                                        <li style={{ cursor: "pointer" }}><button className="dropdown-item" onClick={() => handleBlockUser(cur)} >{!cur?.blockedStatus ? "Block" : "Unblock"}</button></li>
                                    </ul>
                                </>
                            </div>
                        </div>
                    })}

                </div>
            }
            {
                Tab === "GROUP" && <div className="USERS_LIST px-2">
                    {conversationList?.map((cur, index) => {
                        return <div key={cur?.conversationId}  className={`USER_LIST_ITEAM position-relative mb-3 ${userInfo?.conversationId === cur?.conversationId ? "ACTIVE_USER" : ""} `} onClick={() => { activeUser(cur);handleReadMessage(cur?.conversationId,cur?.unReadMessages) }}>
                            <div className='d-flex w-75'>
                                <Avatar alt={cur?.name} src={ChatGroupFilePath + cur?.image} />
                                <div className='ms-3 'style={{width:"70%"}}>
                                    <p className="USER_NAME">{cur?.name}</p>
                                    <p className="LAST_MESSAGE">{cur?.lastMessage?.message}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end pe-2' style={{width:"30%"}}>
                                <p className='LAST_MESSAGE_TIME'>{cur?.lastMessage?.messageTime ? moment(cur?.lastMessage?.messageTime).format('hh:mm A') : ""}</p>
                                {
                                    cur?.unReadMessages > 0 && <div className='NOTIFICATION'> {cur?.unReadMessages}</div>
                                }
                                <>
                                    <MoreHorizIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu dro">
                                        <li style={{ cursor: "pointer" }}><button className="dropdown-item" onClick={() => handleLeaveGroup(cur)} >Leave Group</button></li>
                                    </ul>
                                </>
                            </div>
                        </div>
                    })}
                </div>
            }
            {
                Tab === "PATIENT" && <div className="USERS_LIST px-2">
                    {conversationList?.map((cur, index) => {
                        return <div key={cur?.conversationId}  className={`USER_LIST_ITEAM  position-relative mb-3 ${userInfo?.conversationId === cur?.conversationId ? "ACTIVE_USER" : ""} `} onClick={() => { activeUser(cur);handleReadMessage(cur?.conversationId,cur?.unReadMessages) }}>
                            <div className='d-flex w-75'>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                // variant="dot"
                                >
                                    <Avatar alt={cur?.userDetails?.firstName + " " + (cur?.userDetails?.lastName ? cur?.userDetails?.lastName : '')} src={PatientImageFilePath + cur?.userDetails?.image} />
                                </StyledBadge>
                                <div className='ms-3 ' style={{width:"70%"}}>
                                    <p className="USER_NAME">{cur?.userDetails?.firstName + " " + (cur?.userDetails?.lastName ? cur?.userDetails?.lastName : '')}</p>
                                    <p className="LAST_MESSAGE">{cur?.lastMessage?.message}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end pe-2' style={{width:"30%"}}>
                                <p className='LAST_MESSAGE_TIME'>{cur?.lastMessage?.messageTime ? moment(cur?.lastMessage?.messageTime).format('hh:mm A') : ""}</p>
                                {
                                    cur?.unReadMessages > 0 && <div className='NOTIFICATION'> {cur?.unReadMessages}</div>
                                }
                                <>
                                    <MoreHorizIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu ">
                                        <li style={{ cursor: "pointer" }}><button className="dropdown-item" onClick={() => handleDeleteCoversation(cur?.conversationId)} >Delete Conversation</button></li>
                                        <li style={{ cursor: "pointer" }}><button className="dropdown-item" onClick={() => handleBlockUser(cur)} >{!cur?.blockedStatus ? "Block" : "Unblock"}</button></li>
                                    </ul>
                                </>
                            </div>
                        </div>
                    })}

                </div>
            }
        </>
    )
};

export default Sidebar;


