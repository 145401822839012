import {accountdeleteAPI, addclinicApi, addclinicSignupApi, businessCategory, clinicProof, deleteclinic, listproviderbasic, listproviderbusiness, listproviderclinics, listproviderclinicsbyid, qrCode, updatebasicdetails, updatebusinessdetails, updateclinicdetails, userProfileList} from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

export const GET_BASIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(listproviderbasic(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const UPDATE_BASIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(updatebasicdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_BUSINESS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(updatebusinessdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_BUSINESS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(listproviderbusiness(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_BUSINESS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(updatebusinessdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const BUSINESS_CATEGORY = async (body) => {
    try {
        let response = await axiosInstance.post(businessCategory(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const INSERT_CLINIC = async (body) => {
    try {
        let response = await axiosInstance.post(addclinicApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_CLINIC_SIGNUP = async (body) => {
    try {
        let response = await axiosInstance.post(addclinicSignupApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const CLINIC_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listproviderclinics(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_CLINIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(listproviderclinicsbyid(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_CLINIC = async (body) => {
    try {
        let response = await axiosInstance.post(updateclinicdetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_CLINIC = async (body) => {
    try {
        let response = await axiosInstance.post(deleteclinic(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPLOAD_FILE_PDF = async (body) => {
    try {
        let response = await axiosInstance.post(clinicProof(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_ACCOUNT = async (body) => {
    try {
        let response = await axiosInstance.post(accountdeleteAPI(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const QR_CODE = async (body) => {
    try {
        let response = await axiosInstance.post(qrCode(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const USER_SWITCH_PROFILE_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(userProfileList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}











