import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { addServicesValidation } from "../../../Context/Validation";
import { useEffect } from "react";
import useReduxData from "../../../Hooks/useReduxData";
import useUploadImage from "../../../Hooks/useUploadImage";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Avatar, styled } from "@mui/material";
import { INSERT_SERVICE, SERVICE_LIST, SPECIALIZATION_LIST, UPDATE_SERVICE } from "../../Repository/ExpertiseRepo";
import { RoleTitle } from "../../../Context/constants";
const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
    color: "#fff"
}));
const useServices = () => {
    const [showAdd, setShowAdd] = useState(false);
    const [showArchiv, setShowArchiv] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [imgUrl, setImgUrl] = useState(null);
    const [servicesListdata, setServicesListdata] = useState([])
    const [active, setActive] = useState(false)
    const [archivedata, setArchiveData] = useState({})
    const [loader, setLoader] = useState(false)
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const [SpecializationListdata, setSpecializationListdata] = useState([])
    const [Filter, setFilter] = useState({
        archive: true,
        services: "",
        disable_button: true
    })
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const { id } = useParams()
    const FileRef = useRef()
    const { state, pathname } = useLocation();
    const navigate = useNavigate()
    const splitLocation = pathname.split("/");
    const headCells = [
        { id: "dummy", numeric: false, disablePadding: true, label: "Image" },
        { id: "serviceName", numeric: true, disablePadding: false, label: "Services", showIcon: true },
        { id: "specializationName", numeric: true, disablePadding: false, label: "Specialization", showIcon: true },
        { id: "noOfPatients", numeric: true, disablePadding: false, label: "Number Of Patient", showIcon: true },
        { id: "description", numeric: true, disablePadding: false, label: "Description", showIcon: true },
        { id: "dummy", numeric: false, disablePadding: false, showLeft: true, label: "Action" },
    ];
    // upload image
    const { UploadImage } = useUploadImage()
    // add services Api
    const addServices = useFormik({
        initialValues: {
            serviceName: "",
            fixedPrice: "",
            variablePrice: "",
            description: "",
            group: false,
            noOfPatients: "1",
            duration: "",
            specializationId: ""
        },
        validationSchema: addServicesValidation,
        onSubmit: async (values) => {
            let { group, ...restValue } = values
            let payload = {
                ...restValue,
                image: imgUrl,
                isGroup: Boolean(group),
                providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                specializationId: +values.specializationId,
                variablePrice: values?.variablePrice?.trim() ? values?.variablePrice : "",
                clinicId: userdata?.location?.clinicId,
                roleTitle: userdata?.roleTitle
            }
            if (+values.variablePrice >= +values.fixedPrice) {
                return toast.warn("Variable price can not be greater than fixed Price")
            }
            setLoader(true)
            try {
                let response = await INSERT_SERVICE(payload)
                toast.success(response.message)
                addServices.resetForm()
                setShowAdd(false)
                navigate("/expertise/services")
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })

    // archive/unarchive Api          
    const archiveUnarchive = async (event, data) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                serviceId: data.serviceId,
                activeStatus: !data.archive,
                userId: userdata.userId,
            }
            try {
                let response = await UPDATE_SERVICE(payload)
                toast.success(response.message)
                if (servicesListdata?.length === 1 && controller.page === 1) {
                    getservicesList()
                }
                else if (servicesListdata?.length === 1) {
                    setController((pre) => ({ ...pre, page: controller.page > 1 ? controller.page - 1 : 1 }))
                } else getservicesList()
                setLoader(false)
                setShowArchiv(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        } else {
            setShowArchiv(false)
        }
    }
    // get list services  Api
    const getservicesList = async () => {
        setLoader(true)
        const { page, rowsPerPage, searchPage } = controller;
        const { archive, services } = Filter;
        const keyword = services.trim();
        const payload = {
            keyword: (searchPage && keyword) ? keyword : undefined,
            activeStatus: archive,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            page,
            count: rowsPerPage,
            clinicId: userdata?.location?.clinicId
        };

        try {
            let response = await SERVICE_LIST(payload)
            setServicesListdata(response?.data?.map((cur) => ({ ...cur, specializationName: cur?.specialization?.specializationName })))
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            if (controller.page > 1) {
                setController((pre) => ({ ...pre, page: pre.page - 1 }))
            }
            setServicesListdata([])
            setPaginationTotalCount(0)
            setLoader(false)
        }

    }

    // get list specialization  Api
    const getspecializationList = async () => {
        let payload = {
            activeStatus: true,
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
        }
        setLoader(true)
        try {
            let response = await SPECIALIZATION_LIST(payload)
            setSpecializationListdata(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            setSpecializationListdata([])
            setPaginationTotalCount(0)
            if (controller.page > 1) {
                setController((pre) => ({ ...pre, page: pre.page - 1 }))
            }
            setLoader(false)
        }
    }

    // view update data Api 
    const upadteservicesView = async (data) => {
        setLoader(true)
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            activeStatus: true,
            serviceId: id
        }
        try {
            let response = await SERVICE_LIST(payload)
            setShowEdit(true)
            upadteservicesfun.setValues(response.data)
            upadteservicesfun.setFieldValue("specializationId", response.data?.specialization?.specializationId)
            upadteservicesfun.setFieldValue("group", response.data?.isGroup?.toString())
            setImgUrl(response.data.image)
            setLoader(false)
        } catch (error) {
            toast.error(error?.message)
            setShowEdit(false)
            setLoader(false)
        }
    }
    //  upadate services Api
    const upadteservicesfun = useFormik({
        initialValues: {
            serviceName: "",
            fixedPrice: "",
            variablePrice: "",
            description: "",
            archive: "",
            group: "false",
            noOfPatients: "1",
            duration: "",
            specializationId: ""
        },
        validationSchema: addServicesValidation,
        onSubmit: async (values) => {
            let { group, specialization, ...restVlaue } = values
            let payload = {
                ...restVlaue,
                image: imgUrl,
                isGroup: Boolean(group),
                serviceId: id,
                specializationId: +values.specializationId,
                variablePrice: values?.variablePrice ? values?.variablePrice : "",
                activeStatus: undefined
            }
            if (+values.variablePrice >= +values.fixedPrice) {
                return toast.warn("Variable price can not be greater than fixed Price")
            }
            setLoader(true)
            try {
                let response = await UPDATE_SERVICE(payload)
                toast.success(response.message)
                addServices.resetForm()
                setShowEdit(false)
                navigate("/expertise/services")
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })

    const handleChangeFilter = (event) => {
        const { name, value } = event.target
        setFilter((pre) => ({ ...pre, disable_button: false, [name]: value }))
    }

    const reset = () => {
        setController((pre) => ({ ...pre, page: pre.searchPage ?? pre.page, searchPage: null }))
        setFilter({ archive: true, services: "", disable_button: true })
    }
    const HandleSearchList = () => {
        setController((pre) => ({ ...pre, searchPage: pre?.page, page: 1 }))
    }

    const DuplicateFun = (data) => {
        setShowAdd(true)
        addServices?.setFieldValue("serviceName", data?.serviceName)
        addServices?.setFieldValue("description", data?.description)
        addServices?.setFieldValue("variablePrice", data?.variablePrice)
        addServices?.setFieldValue("fixedPrice", data?.fixedPrice)
        addServices?.setFieldValue("duration", data?.duration)
        addServices?.setFieldValue("specializationId", data?.specializationId)
        addServices?.setFieldValue("group", data?.group)
        addServices?.setFieldValue("noOfPatients", data?.noOfPatients)
        addServices?.setFieldValue("specializationId", data?.specializationId)
        addServices.setFieldValue("filter", "DUPLICATE")
    }

    useEffect(() => {
        if (splitLocation[splitLocation?.length - 1] !== "services") {
            if (splitLocation[splitLocation?.length - 1] !== "add") {
                upadteservicesView()
            }
            getspecializationList()
        } else {
            getservicesList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])


    return {
        archiveUnarchive,
        upadteservicesfun,
        setShowArchiv,
        setShowAdd,
        UploadImage,
        setShowEdit,
        setImgUrl,
        setArchiveData,
        setController,
        setActive,
        getspecializationList,
        upadteservicesView,
        DuplicateFun,
        handleChangeFilter,
        HandleSearchList,
        CustomButton,
        SpecializationListdata,
        headCells,
        addServices,
        archivedata,
        showArchiv,
        showAdd,
        imgUrl,
        showEdit,
        loader,
        active,
        servicesListdata,
        paginationTotalCount,
        controller,
        reset,
        id,
        FileRef,
        state,
        navigate,
        SmallAvatar,
        getservicesList,
        Filter,
        setLoader,
    }
}

export default useServices
