import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { bookingpdf, cancelPdf, refundPdf, reschedulingPdf } from "../../Context/api";
import Loading from "../../Component/Loading";
import usePolicies from "./Hooks/usePolicies";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { RoleTitle } from "../../Context/constants";
const Policies = () => {
  const { loader, policy, uploadPdf, policiesData, saveBookingPolicy, policyDelete, FileRef, tab, ChangeTab, userData } = usePolicies()
  const { CustomButton } = useMuiCompontent()
  return (
    <>
      {loader && <Loading />}
      <div className="" >
        <div className="row px-1">
          <div className="col-12 my-3 tab_scroll" style={{ borderBottom: "2px solid #ededed" }}>
            <ul className="nav  mb-0  " style={{width:"345px"}}>
              <li className="nav-item" role="presentation">
                <button className={`teams_frofile_stepper_btn me-2 ${tab === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => ChangeTab("Booking")} >Booking</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`teams_frofile_stepper_btn ${tab === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Cancelation")} >Cancellation</button>  
              </li>
              <li className="nav-item" role="presentation">
                <button className={`teams_frofile_stepper_btn ${tab === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Refund")} >Refund</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className={`teams_frofile_stepper_btn ${tab === 4 && "active_teams_frofile"}`} id="education" type="button" onClick={() => ChangeTab("Reschedule")} >Reschedule</button>
              </li>
            </ul>
          </div>
        </div>
        {
          tab === 1 && <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h3 style={{ fontSize: "13px" }}>Booking Policy</h3>
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && (policy?.BookingPolicy || policiesData) && <i className="fa-solid fa-trash-can all_icons_color" onClick={() => policyDelete()} />}
            </div>
            <div className="col-12">
              <div className="outerBox text-center">
                {
                  (policy?.BookingPolicy || policiesData) ?
                    <iframe src={bookingpdf + (policy?.BookingPolicy || policiesData)} title={bookingpdf + (policy?.BookingPolicy || policiesData)} width={1000} height={500} />
                    : userData.roleTitle === RoleTitle.SERVICE_PROVIDER ?
                      <div style={{ width: "40%" }}>
                        <div className="bodrerer mb-3 " style={{ width: "100%", maxWidth: "100%", border: "2px dashed #aaaaaa" }}>
                          <label htmlFor="inputTag" style={{ width: "100%" }}>
                            <AiOutlineCloudUpload className="fs-4" />
                            <input ref={FileRef} id="inputTag" type="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                            <br />
                            Upload Policy Here
                            <br />
                            <span id="imageName"></span>
                          </label>
                        </div>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>Upload only PDF file   </p>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>The size should be upto 10  mb</p>
                      </div> : ""
                }
              </div>
            </div>
            <div className=" col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pb-4" >
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && !policy?.BookingPolicy && <CustomButton variant="contained" onClick={() => saveBookingPolicy()} disabled={policiesData ? false : true}> Save</CustomButton>
              }
            </div>
          </div>
        }
        {
          tab === 2 && <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h3 style={{ fontSize: "13px" }}> Cancellation Policy</h3>
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && (policy?.CancellationPolicy || policiesData) && <i className="fa-solid fa-trash-can all_icons_color" onClick={() => policyDelete()} />}
            </div>
            <div className="col-12">
              <div className="outerBox text-center">
                {
                  (policy?.CancellationPolicy || policiesData) ?
                    <iframe src={cancelPdf + (policy?.CancellationPolicy || policiesData)} title={cancelPdf + (policy?.CancellationPolicy || policiesData)} width={1000} height={500} />
                    : userData.roleTitle === RoleTitle.SERVICE_PROVIDER ?
                      <div style={{ width: "40%" }}>
                        <div className="bodrerer mb-3 " style={{ width: "100%", maxWidth: "100%", border: "2px dashed #aaaaaa" }}>
                          <label htmlFor="inputTag" style={{ width: "100%" }}>
                            <AiOutlineCloudUpload className="fs-4" />
                            <input ref={FileRef} id="inputTag" type="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                            <br />
                            Upload Policy Here
                            <br />
                            <span id="imageName"></span>
                          </label>
                        </div>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>Upload only PDF file   </p>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>The size should be upto 10  mb</p>
                      </div> : ""
                }
              </div>
            </div>
            <div className=" col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pb-4" >
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && !policy?.CancellationPolicy && <CustomButton variant="contained" onClick={() => saveBookingPolicy()} disabled={policiesData ? false : true}> Save</CustomButton>
              }
            </div>
          </div>
        }
        {
          tab === 3 && <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h3 style={{ fontSize: "13px" }}>Refund Policy</h3>
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && (policy?.RefundPolicy || policiesData) && <i className="fa-solid fa-trash-can all_icons_color" onClick={() => policyDelete()} />}
            </div>
            <div className="col-12">
              <div className="outerBox text-center">
                {
                  (policy?.RefundPolicy || policiesData) ?
                    <iframe src={refundPdf + (policy?.RefundPolicy || policiesData)} title={refundPdf + (policy?.RefundPolicy || policiesData)} width={1000} height={500} />
                    : userData.roleTitle === RoleTitle.SERVICE_PROVIDER ?
                      <div style={{ width: "40%" }}>
                        <div className="bodrerer mb-3 " style={{ width: "100%", maxWidth: "100%", border: "2px dashed #aaaaaa" }}>
                          <label htmlFor="inputTag" style={{ width: "100%" }}>
                            <AiOutlineCloudUpload className="fs-4" />
                            <input ref={FileRef} id="inputTag" type="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                            <br />
                            Upload Policy Here
                            <br />
                            <span id="imageName"></span>
                          </label>
                        </div>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>Upload only PDF file   </p>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>The size should be upto 10  mb</p>
                      </div> : ""
                }
              </div>
            </div>
            <div className=" col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pb-4" >
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && !policy?.RefundPolicy && <CustomButton variant="contained" onClick={() => saveBookingPolicy()} disabled={policiesData ? false : true}> Save</CustomButton>
              }
            </div>
          </div>
        }
        {
          tab === 4 && <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <h3 style={{ fontSize: "13px" }}>Rescheduling Policy</h3>
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && (policy?.ReschedulingPolicy || policiesData) && <i className="fa-solid fa-trash-can all_icons_color" onClick={() => policyDelete()} />}
            </div>
            <div className="col-12">
              <div className="outerBox text-center">
                {
                  (policy?.ReschedulingPolicy || policiesData) ?
                    <iframe src={reschedulingPdf + (policy?.ReschedulingPolicy || policiesData)} title={reschedulingPdf + (policy?.ReschedulingPolicy || policiesData)} width={1000} height={500} />
                    : userData.roleTitle === RoleTitle.SERVICE_PROVIDER ?
                      <div style={{ width: "40%" }}>
                        <div className="bodrerer mb-3 " style={{ width: "100%", maxWidth: "100%", border: "2px dashed #aaaaaa" }}>
                          <label htmlFor="inputTag" style={{ width: "100%" }}>
                            <AiOutlineCloudUpload className="fs-4" />
                            <input ref={FileRef} id="inputTag" type="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                            <br />
                            Upload Policy Here
                            <br />
                            <span id="imageName"></span>
                          </label>
                        </div>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>Upload only PDF file   </p>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>The size should be upto 10  mb</p>
                      </div> : ""
                }
              </div>
            </div>
            <div className=" col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end  pb-4" >
              {userData.roleTitle === RoleTitle.SERVICE_PROVIDER && !policy?.ReschedulingPolicy && <CustomButton variant="contained" onClick={() => saveBookingPolicy()} disabled={policiesData ? false : true}> Save</CustomButton>
              }
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Policies;
