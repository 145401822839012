import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "patientDetails",
    initialState: {},
    reducers: {
        addPatinetDetails(state, action) {
           return state = action.payload;
        },
    },
});
export const { addPatinetDetails } = reducer.actions;
export default reducer.reducer;
