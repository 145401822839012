import { useState, useEffect, useRef } from 'react'
import { RoleTitle,  } from "../../../Context/constants";
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useNavigate, useParams } from 'react-router-dom';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import { INSERT_PET } from '../../Repository/PatientRepo';
import moment from 'moment-timezone';


const useAddPet = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [other, setOther] = useState(false)
    const navigate = useNavigate()
    const { id } = useParams()
    const [loader, setLoader] = useState(false)
    const FileRef = useRef()
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { reduxData: patientdetails } = useReduxData("patientdetailsRDX")
    const { UploadImage } = useUploadImage()
    const addpet = useFormik({
        initialValues: {
            firstName: "",
            gender: "",
            petType: "",
            breed: "",
            dob: "",
            otherPetType: "",
            age: "",
            petTypeField:""
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Please enter  name").matches(/^(?![\s-])[\w\s-]+$/, "white space is not required"),
            gender: Yup.string().required("Please select gender "),
            petType: Yup.string().required("Please select pet type "),
            breed: Yup.string().required("Please enter  breed"),
            dob: Yup.string().required("Please choose  age"),
            petTypeField: other ? Yup.string().required("Please enter pet type ") : Yup.string(),
        }),
        onSubmit: async (value) => {
            let data = {
                patientId: id,
                authId:patientdetails?.authId,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
                firstName: value.firstName,
                gender: value.gender,
                petType: value.petType,
                breed: value.breed,
                image: imgUrl,
                petTypeField: value?.petTypeField,
                dob: moment(value?.dob).format('YYYY-MM-DD'),
                age: value.age,
            }
            setLoader(true)
            try {
                let response = await INSERT_PET(data)
                toast.success(response?.message)
                setLoader(false)
                navigate(`/patients/${id}/sub-members`)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }

    })

    // handal change birthDate
    const handalDOB = (e) => {
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        addpet.setFieldValue("age", age ?? "")
    }
    useEffect(() => {
        if (addpet.values.petType === "other") {
            setOther(true)
        }
        else {
            setOther(false)
        }
    }, [addpet.values.petType])
    return {
        loader,
        imgUrl,
        addpet,
        UploadImage,
        setImgUrl,
        FileRef,
        navigate,
        id,
        handalDOB,
        setLoader
    }
}

export default useAddPet
