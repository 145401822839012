import { Avatar } from '@mui/material'
import React from 'react'
import { ChatGroupFilePath, PatientImageFilePath, TeamImageFilePath, userImageFilePath } from '../../../Context/api'
import { FaArrowLeft } from "react-icons/fa";
import { RoleTitle } from '../../../Context/constants';
const Header = ({ handleOpenModal, userInfo, Tab, windowWidth, setUserInfo }) => {
  return (
    <>
      {userInfo ? <div className={`HEADER d-flex  justify-content-${windowWidth > 770 ? "start" : "start"} `} >
        {
          windowWidth > 770 ? "" : <FaArrowLeft className="me-2 cursor" onClick={() => setUserInfo(null)} />
        }
        {
          Tab === "GROUP" && <div className='d-flex align-items-center'>
            <Avatar className='cursor' onClick={() => handleOpenModal("GROUP_INFO")} alt={userInfo?.name} src={ChatGroupFilePath + userInfo?.image} />
            <p className='mb-0 ms-2 cursor' onClick={() => handleOpenModal("GROUP_INFO")}>{userInfo?.name}</p>
          </div>
        }
        {
          Tab === "STAFF" && <div className='d-flex align-items-center'>
            <Avatar className='cursor' alt={userInfo?.userDetails?.firstName + " " + userInfo?.userDetails?.lastName} src={(userInfo?.userDetails?.roleTitle === RoleTitle.STAFF||userInfo?.userDetails?.roleTitle === RoleTitle.FRONT_DESK) ? (TeamImageFilePath + userInfo?.userDetails?.image) : (userImageFilePath + userInfo?.userDetails?.image)} />
            <p className='mb-0 ms-2 cursor' >{userInfo?.userDetails?.firstName + " " + userInfo?.userDetails?.lastName}</p>
          </div>
        }
        {
          Tab === "PATIENT" && <div className='d-flex align-items-center'>
            <Avatar className='cursor' alt={userInfo?.userDetails?.firstName + " " + (userInfo?.userDetails?.lastName ? userInfo?.userDetails?.lastName : '')} src={PatientImageFilePath + userInfo?.image} />
            <p className='mb-0 ms-2 cursor' >{userInfo?.userDetails?.firstName + " " + (userInfo?.userDetails?.lastName ? userInfo?.userDetails?.lastName : '')}</p>
          </div>
        }
      </div> : null}

    </>

  )
}

export default Header