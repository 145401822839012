import { toast } from 'react-toastify';
import { DELETE_FILE } from '../Pages/Repository/CommonRepo';
const useDeleteFile = () => {
    const deleteFileFun = async (setImgUrl, deletedData, isDeleted, setIsEditGroup = () => { }) => {
        if (isDeleted) {
            try {
                let response = await DELETE_FILE({ ...deletedData })
                toast.success(response?.message)
                setImgUrl(null)
                setIsEditGroup(true)
            } catch (error) {
                toast.error(error?.message);
            }
        } else {
            setImgUrl(null)
        }
    }
    return {
        deleteFileFun
    }
}

export default useDeleteFile
