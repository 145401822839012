import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import useReduxData from '../../Hooks/useReduxData';
import { addUserData } from '../../Redux/ReducerFile/UserData';
import Loading from '../../Component/Loading';
import { Button } from '@mui/material';
import { VALIDATE_AUTH_TOKEN } from '../Repository/CredentialRepo';
import { motion } from 'framer-motion';
const PaymentSuccessfull = () => {
    const [userData, setUserdata] = useState(null)
    const [loader, setLoader] = useState(false)
    const [active] = useState(1)
    const { token } = useParams()
    const navigate = useNavigate()
    const { dispatch } = useReduxData("userDataRDX")
    useEffect(() => {
        CheckToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const CheckToken = async () => {
        let payload = { token: token }
        try {
            let response = await VALIDATE_AUTH_TOKEN(payload)
            setUserdata(response.data)
        } catch (error) {
            toast.error(error?.message)
            setUserdata(null)
            setLoader(false)
            navigate("declineInvitation")
        }
    }
    const backToLogin = () => {
        dispatch(addUserData({}))
        navigate("/login")
    }
    if (!userData) return
    return (
        <>
            {loader && <Loading />}
            <ToastContainer />
            <div className="col-lg-12">
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    {
                        active === 1 && <motion.div className="col-sm-12 col-md-8 col-lg-4"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            exit={{ x: -300 }}
                            transition={{ duration: .5 }}
                        >
                            <div className="shadow w-100 p-3 d-flex justify-content-between  flex-column " style={{ height: "450px", borderRadius: "10px" }}>
                                <div>
                                    <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="" className="img-fluid" width={80} height={80} />
                                    <h3 className='text-center mt-5'>Payment successful!</h3>
                                    <p className="text-center">Thank you! Your payment is completed</p>
                                    <form action="" className="FORM" >
                                        <div className="SUCCESSFULLY_ICON d-flex justify-content-center w-100">
                                            <img src={require("../../Assets/Images/8832119.png")} alt="" width={80} height={80} className="img-fluid" />
                                        </div>
                                    </form>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <Button type="button" onClick={backToLogin} variant="contained" className=" fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px", textTransform: "capitalize" }}>Back to Login</Button>
                                </div>
                            </div>
                        </motion.div>
                    }
                </div>
            </div>
        </>
    )
}

export default PaymentSuccessfull