import React, { useEffect, useRef, useState } from 'react'
import { Errors } from '../../Component/Error'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { motion } from 'framer-motion';
import { TeamsEducationValidation } from '../../Context/Validation';
import { useFormik } from 'formik';
import useDragDrop from '../../Hooks/useDrag&Drop';
import PdfImage from '../../Assets/Images/home/pdf.svg';
import { UPLOAD_FILE_PDF } from '../Repository/ProfileRepo';
import { toast } from 'react-toastify';
import useDeleteFile from '../../Hooks/useDeleteFile';
import Loading from '../../Component/Loading';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import useReduxData from '../../Hooks/useReduxData';
import { EDUCATION_LIST, INSERT_EDUCATION } from '../Repository/StaffRepo';
import moment from 'moment-timezone';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleTitle } from '../../Context/constants';
import { FaArrowLeft } from 'react-icons/fa'

const AddDegree = ({ type }) => {
    const [document, setDocumant] = useState(null)
    const [loader, setLoader] = useState(false)
    const FileRef = useRef()
    const { CustomButton } = useMuiCompontent()
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragDrop()
    const { deleteFileFun } = useDeleteFile()
    const navigate = useNavigate()
    const { id } = useParams()
    const uploadPdf = async (e) => {
        if (!e) return
        const fd = new FormData()
        const fileTypes = { pdf_File_Type: ['application/pdf'] }
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!e.type.includes(fileTypes.pdf_File_Type)) return toast.warn('Only PDF format allowed')
        fd.append('file', e)
        fd.append('type', 'EDUCATIONPROOF')
        fd.append('previousFileName', document)
        fd.append('id', id ? +id : null)
        setLoader(true)
        try {
            let response = await UPLOAD_FILE_PDF(fd)
            toast.success(response.message)
            setDocumant(response.data?.db_name)
            FileRef.current.value = null
            setLoader(false)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }
    }
    //get education List
    let getEducationList = async () => {
        let payload = {
            // staffId: StaffDetails?.userId,
            staffQualificationsListId: +id
        }
        setLoader(true)
        try {
            let response = await EDUCATION_LIST(payload)
            degree.setValues(response?.data)
            setDocumant(response?.data?.proof)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }

    const degree = useFormik({
        validationSchema: TeamsEducationValidation,
        initialValues: {
            degree: "",
            university: "",
            yearOfPassing: "",
            registrationNumber: "",
        },
        onSubmit: async (values) => {
            if (!document) {
                return toast.warn("Please upload document")
            }
            let { staffQualificationsId, yearOfPassing, activeStatus, providerIdOfStaff, userId, deletedStatus, roleId, roleName, ...restValue } = values
            let payload = {
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : StaffDetails?.userId,
                providerId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.providerId : userdata?.userId,
                ...restValue,
                proofDoc: document,
                yearOfPassing: moment(yearOfPassing).format("YYYY-MM-DD"),
                staffQualificationsId: id ? staffQualificationsId : undefined
            }
            try {
                let response = await INSERT_EDUCATION(payload)
                toast.success(response?.message)
                if (type === "profile") {
                    navigate("/Profile")
                } else {
                    navigate("/team/details")
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
            }
        }
    })
    const handleChangeInput = (event) => {
        const { name, value } = event.target
        degree.setFieldValue(name, value)
    }
    useEffect(() => {
        if (id) {
            getEducationList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        {loader && <Loading />}
        <motion.div className="common-component-box"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.7 }}>
            <header className="body_header" >
                <div className="d-flex justify-content-start align-items-center">
                    <FaArrowLeft className="me-2 cursor" onClick={() => {
                        if (type === "profile") {
                            navigate("/Profile")
                        } else {
                            navigate("/team/details")
                        }
                    }} />
                    <h2 className='page_name'>{id ? "Update Degree" : "Add Degree"}</h2>
                </div>
            </header>
            <div className="common-white-bg-box d-flex justify-content-between flex-column  mx-0 pt-2   shadow">
                <div className="col-12 px-4">
                    <div className="row">
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Degree<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control"
                                        maxLength={50}
                                        name={`degree`}
                                        value={degree?.values?.degree}
                                        onChange={handleChangeInput}
                                    />
                                    <Errors formikfun={degree} name={"degree"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Degree<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control"
                                                maxLength={50}
                                                name={`degree`}
                                                value={degree?.values?.degree}
                                                onChange={handleChangeInput}
                                            />
                                            <Errors formikfun={degree} name={"degree"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">University<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control" name={`university`} maxLength={100}
                                        value={degree?.values?.university}
                                        onChange={handleChangeInput}
                                    />
                                    <Errors formikfun={degree} name={"university"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">University<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control" name={`university`} maxLength={50}
                                                value={degree?.values?.university}
                                                onChange={handleChangeInput}
                                            />
                                            <Errors formikfun={degree} name={"university"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Year of Passing<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="date" className="form-control position-relative" name={`yearOfPassing`} id="datefield"
                                        max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                        value={degree?.values?.yearOfPassing?moment(degree?.values?.yearOfPassing).format("YYYY-MM-DD"):""}
                                        onChange={handleChangeInput}
                                    />
                                    <Errors formikfun={degree} name={"yearOfPassing"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Year of Passing<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="date" className="form-control position-relative" name={`yearOfPassing`} id="datefield"
                                                max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                value={moment(degree?.values?.yearOfPassing).format("YYYY-MM-DD")}
                                                onChange={handleChangeInput}
                                            />
                                            <Errors formikfun={degree} name={"yearOfPassing"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            {/* <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Registration Number<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="text" className="form-control" name={`registrationNumber`}
                                        maxLength={20}
                                        value={degree?.values?.registrationNumber}
                                        onChange={handleChangeInput}
                                    />
                                    <Errors formikfun={degree} name={"registrationNumber"} />
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Registration Number<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-7">
                                            <input type="text" className="form-control" name={`registrationNumber`}
                                                maxLength={20}
                                                value={degree?.values?.registrationNumber}
                                                onChange={handleChangeInput}
                                            />
                                            <Errors formikfun={degree} name={"registrationNumber"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <label className="form-label mb-0">Document Upload<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="d-flex justify-content-between flex-column align-items-center col-7">
                                            <div className="bodrerer w-100  mx-0" style={{ border: `${!document ? "2px dashed #aaaaaa" : "none"}` }}
                                                onDragEnter={handleDragEnter}
                                                onDragLeave={handleDragLeave}
                                                onDragOver={handleDragOver}
                                                onDrop={(e) => handleDrop(e, uploadPdf)}
                                            >
                                                <label htmlhtmlhtmlfor="inputTag">
                                                    <AiOutlineCloudUpload className="fs-4 " />
                                                    {document && <img src={PdfImage} alt="" />}
                                                    <input id="inputTag" type="file" name="file"
                                                        ref={FileRef}
                                                        onChange={(e) => { uploadPdf(e.target.files[0]) }}
                                                    />
                                                    <br />
                                                    {
                                                        !document && <p className="mb-0">Drag file(s) here or <span>click to upload</span></p>
                                                    }

                                                    <br />
                                                </label>

                                            </div>
                                            {/* {
                                        document && <i className="fa-solid fa-trash ms-3 mt-2 all_icons_color" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setDocumant, { fileName: document, type: "EDUCATIONPROOF", id: id }, true)}></i>
                                    } */}
                                            {
                                                document && <p className=" m-0 ms-2 mt-2 all_icons_color text-danger" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setDocumant, { fileName: document, type: "EDUCATIONPROOF", id: id }, true)}>Delete</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end py-3 pe-3">
                    <CustomButton variant="contained" onClick={degree.handleSubmit} >{id ? "Update " : "save"}</CustomButton>
                </div>
            </div>
        </motion.div>
    </>
}

export default AddDegree