import React from 'react';
import { Bar, BarChart, CartesianGrid, Cell, Label, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import Loading from '../../Component/Loading';
import useDemographics from './Hooks/useDemographics';
import Filters from '../../Component/Insights/Filters';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { IoFilterSharp } from 'react-icons/io5';
import { formatNumber } from '../../Utils/Utils';

const Demographics = () => {
    const {
        setSelectGender,
        selectGender,
        loader,
        demographicsDetails,
        filterPopup,
        setFilterPopup,
        setAction,
        getDemographicDetail,

        totalfemaleCount,
        totalmaleCount,
        totalothersCount,
        totalUsersCount,
    } = useDemographics()
    const { CustomButton } = useMuiCompontent()
    const data2 = [
        { name: 'Male', value: totalmaleCount, color: "#0088FE" },
        { name: 'Female', value: totalfemaleCount, color: "#00C49F" },
        { name: 'Others', value: totalothersCount, color: "#FFBB28" },
    ];
    return <>
        {loader && <Loading />}
        <div className="py-3">
            <div className="row">
                <div className="col-12  position-relative px-0 ">
                    <div className='w-100 text-end'>
                        <CustomButton onClick={() => setFilterPopup((pre) => !pre)} className='' variant="contained" style={{ background: "#f5f5f5", color: "black" }} startIcon={<IoFilterSharp />}  >Filters</CustomButton>
                    </div>
                    <Filters
                        page="DEMOGRAPHICS"
                        setSelectGender={setSelectGender}
                        filterPopup={filterPopup}
                        setFilterPopup={setFilterPopup}
                        selectGender={selectGender}
                        setAction={setAction}
                        getDetail={getDemographicDetail}
                    />
                </div>
                <div className="col-8 ps-0">
                    <div className="col shadow-sm p-4">
                        <h1 className="mb-3 " style={{ fontSize: "13px" }}>Age Group</h1>
                        <div style={{ width: '100%', height: 380 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    width={500}
                                    height={200}
                                    data={demographicsDetails?.DemographicAgeGroup}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                    barSize={40}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="AgeGroup" />
                                    <YAxis yAxisId="left" orientation="left" stroke="grey" />
                                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                                    <Tooltip />
                                    <Tooltip />
                                    <Bar yAxisId="left" dataKey="AppointmentCount" fill="#febb3c" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
                <div className="col-4 pe-0">
                    <div className="col shadow-sm p-4">
                        <h1 className="mb-3 " style={{ fontSize: "13px" }}>Gender</h1>
                        <div style={{ width: '100%', height: 200 }}>
                            <ResponsiveContainer>
                                <PieChart >
                                    <Pie
                                        data={data2}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={5}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {data2.map((cur, index) => (
                                            <Cell key={`cell-${index}`} fill={cur?.color} />
                                        ))}
                                        <Label
                                            value={`${formatNumber(totalUsersCount)} Users`}
                                            position="center"
                                            fontSize={12}
                                            fill="#000"
                                        />

                                    </Pie>
                                    <Tooltip
                                        formatter={(value, name) => [`${value}`, `${name}`]}
                                        contentStyle={{ fontSize: '14px' }}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#0088FE" }}>Male</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalmaleCount ?? 0}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#00C49F" }}>Female</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalfemaleCount ?? 0}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9"><p style={{ fontSize: "13px", color: "#FFBB28" }}>Not to say</p></div>
                            <div className="col-3 text-end"><p style={{ fontSize: "13px" }}>{totalothersCount ?? 0}</p></div>
                        </div>
                        <div className="row mb-2" style={{ borderBottom: "1.5px solid #ededed" }}>
                            <div className="col-9">  <p style={{ fontSize: "13px" }}> Total Users </p></div>
                            <div className="col-3 text-end">  <p style={{ fontSize: "13px" }}>{totalUsersCount ?? 0}</p></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default Demographics;
