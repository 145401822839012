import React from 'react'
import { SearchAbleSelectBox } from '../../../Component/Inputs';
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { motion } from 'framer-motion';
const FilterCom = ({
    userdata,
    setDateRangeDropdown,
    clinicList,
    selectClinic,
    open,
    apply,
    reset,
    dateRange,
    setFilters,
    openModalKey
}) => {

    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === openModalKey) {
            if (Array.isArray(newValue) && newValue.length !== 0) {
                setFilters((pre) => ({ ...pre, [openModalKey]: { ...pre?.[openModalKey], clinic: newValue } }))
            } else {
                // setFilters([{ clinicName: "All", clinicId: "ALL_CLINCI" }]);
                setFilters((pre) => ({ ...pre, [openModalKey]: { ...pre?.[openModalKey], clinic: [{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }], } }))
            }
        }
    }

    const { CustomButton } = useMuiCompontent()
    if (open) {
        return (
            <motion.div className={`FILTER-DASHBOARD shadow`}
                initial={{ opacity: 0, translateY: -10 }}
                animate={{ opacity: 1, translateY: 0 }}
                exit={{ opacity: 0, translateY: 10 }}
                transition={{ duration: 0.5 }}>
                {
                    <div className="row m-1 ">
                        <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                            <h4 style={{ fontSize: '15px' }}>Filters</h4>
                        </div>
                        {
                            <div className="col-12 mt-3" style={{ borderBottom: "2px solid #ededed" }}>
                                <p style={{ fontSize: "13px", color: "gray" }} className='m-0'>Date Range</p>
                                <ul className="w-100   py-2 d-flex p-0" >
                                    <li className="" onClick={() => { setFilters((pre) => ({ ...pre, [openModalKey]: { ...pre?.[openModalKey], dateRange: "TODAY" } })); setDateRangeDropdown(false) }} >
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1"
                                            checked={dateRange === 'TODAY'} />
                                        <label className="form-check-label ps-2" htmlFor="exampleRadios2" >
                                            Today's
                                        </label>
                                    </li>
                                    <li className='mx-3' onClick={() => { setFilters((pre) => ({ ...pre, [openModalKey]: { ...pre?.[openModalKey], dateRange: "LAST7" } })); setDateRangeDropdown(false) }}>
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option1"
                                            checked={dateRange === 'LAST7'} />
                                        <label className="form-check-label ps-2" htmlFor="exampleRadios3" >
                                            Last 7 Days
                                        </label>
                                    </li>
                                    <li onClick={() => { setFilters((pre) => ({ ...pre, [openModalKey]: { ...pre?.[openModalKey], dateRange: "LAST30" } })); setDateRangeDropdown(false) }}>
                                        <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios4" value="option1"
                                            checked={dateRange === 'LAST30'} />
                                        <label className="form-check-label ps-2" htmlFor="exampleRadios4" >
                                            Last 30 Days
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        }
                        <div className="col-6 mt-2" >
                            <p style={{ fontSize: "13px", color: "gray" }} className='mb-0'>Location</p>
                            <SearchAbleSelectBox
                                multiple={false}
                                option={[{ clinicName: "All", clinicId: "ALL_CLINCI" }, ...clinicList]}
                                getOptionLabel="clinicName"
                                isOptionEqualToValue="clinicId"
                                value={selectClinic}
                                name={openModalKey}
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-12 text-end">
                        <CustomButton variant="contained" onClick={() => { reset(openModalKey) }} className='me-3' >Reset</CustomButton>
                        <CustomButton variant="contained" onClick={() => { apply(openModalKey) }} >Apply</CustomButton>
                    </div>
                </div>
            </motion.div>
        )
    }
}

export default FilterCom
