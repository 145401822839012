import {  bookingInsightsAPi, demographicAPi, patientInsightsAPi, rushHourAPi, ServicesInsightsAPi, TeamInsightsAPi} from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"
    
export const GET_BOOKING_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(bookingInsightsAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const GET_PATIENT_REPORT = async (body) => {
    try {
        let response = await axiosInstance.post(patientInsightsAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_TEAM_REPORT = async (body) => {
    try {
        let response = await axiosInstance.post(TeamInsightsAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_SERVICES_REPORT = async (body) => {
    try {
        let response = await axiosInstance.post(ServicesInsightsAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_RUSH_HOURS_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(rushHourAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_DEMOGRAPHIC_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(demographicAPi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}





