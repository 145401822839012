
import React from "react";
import { useLocation, useNavigate, Outlet } from "react-router-dom";
const HelpSupport = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const splitPath = pathname.split("/")
  const ActiveTab = splitPath[splitPath.length - 1]
  return <>
    <div className=" pt-3" style={{ height: "100%" }}>
      <div className="row" style={{ height: "100%" }} >
        <div className="col-12  NESTED-TAB-HEIGHT" style={{ borderBottom: "2px solid #ededed" }}>
          <ul className="nav  mb-0  pb-1 px-2 ">
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "feedback" && 'active_teams_frofile'}`} type="button" onClick={() => navigate(`feedback`)}>Feedback</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className={`teams_frofile_stepper_btn ${ActiveTab === "faq" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`faq`)} >FAQ</button>
            </li>
          </ul>
        </div>
        <div className="col-12 NESTED-CONTAINER-HEIGHT">
          <Outlet />
        </div>
      </div>
    </div>
  </>
};

export default HelpSupport;
