import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { toast, } from "react-toastify";
import { clinicPdf } from "../../../Context/api";
import { addClinicValidation } from "../../../Context/Validation";
import { useNavigate, useParams } from "react-router-dom";
import useReduxData from "../../../Hooks/useReduxData";
import { AddClinicFildData } from "../../../Redux/ReducerFile/ClinicFildData";
import { GET_CLINIC_DETAILS, UPDATE_CLINIC, UPLOAD_FILE_PDF } from "../../Repository/ProfileRepo";
import { COUNTRY_LIST, STATE_LIST } from "../../Repository/CommonRepo";
const useEditClinic = () => {
    const [servicesList, setservicesList] = useState([])
    const [ListState, setListState] = useState([])
    const [ListDataCountry, setListDataCountry] = useState([])
    const [servicesvalue, setServicesvalue] = useState([])
    const [specializationVlaue, setSpecializationVlaue] = useState([])
    const [check, setCheck] = useState(false)
    const [show, setShow] = useState(false)
    const [DisplayPicture, setDisplayPicture] = useState(null)
    const [pdf, setPdf] = useState('')
    const { id } = useParams()
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [clinicToggle, setClinicToggle] = useState(false)
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    const { reduxData: ClinicFildData, dispatch } = useReduxData("ClinicFildDataRDX")
    const getData = JSON.parse(localStorage?.getItem("service"))
    const FileRef = useRef()
    const pdfRep = useRef(null)
    const [specializationWithService, setspecializationWithService] = useState({
        service: [],
        specialization: []
    })
    useEffect(() => {
        getlistClinicData()
        let servicesId = []
        let specializationId = [];
        if (getData?.length) {
            getData?.forEach((cur) => {
                if (cur?.service.length > 0) {
                    specializationId.push(cur?.specializationId)
                }
                cur?.service?.forEach((item) => {
                    servicesId?.push(item)
                })
            })
            setspecializationWithService({
                service: servicesId,
                specialization: specializationId
            })
        }
        localStorage.setItem("addClinic", JSON.stringify(true))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // get clinic list/view
    const getlistClinicData = async () => {
        setLoader(true)
        let payload = {
            providerId: userdata?.userId,
            clinicId: parseInt(id)
        }
        try {
            let response = await GET_CLINIC_DETAILS(payload)
            if (Object.keys(ClinicFildData).length !== 0) {

                formik?.setValues(ClinicFildData)
                setPdf(ClinicFildData?.pdf)
                setCheck(ClinicFildData?.check)
                setServicesvalue(ClinicFildData?.servicesvalue)
                setDisplayPicture(ClinicFildData?.DisplayPicture)
                if (ClinicFildData?.country.length && ClinicFildData?.state?.length) {
                    getStateList(ClinicFildData?.country[0]?.id, ClinicFildData?.state[0]?.stateId)
                }
                if (ClinicFildData?.country.length) {
                    listcountrys(ClinicFildData?.country[0]?.id)
                }
            } else {
                formik.setFieldValue("clinicName", response?.data?.clinicName)
                formik.setFieldValue("street", response?.data?.street)
                formik.setFieldValue("address", response?.data?.address)
                formik.setFieldValue("zipCode", response?.data?.zip)
                formik.setFieldValue("city", response?.data?.city)
                formik.setFieldValue("registrationNumber", response?.data?.clinicRegistrationNumber)
                setServicesvalue(response?.data?.services)
                getStateList(response?.data?.countryId, response?.data?.stateId)
                listcountrys(response?.data?.countryId)
                setPdf(response?.data?.proof)
                setCheck(response?.data?.activeStatus)
                setDisplayPicture(response?.data?.image)
            }
            setLoader(false)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }

    }
    const uploadPdf = async (e) => {
        if (!e) return
        const fd = new FormData()
        const fileTypes = { pdf_File_Type: ['application/pdf'] }
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!e.type.includes(fileTypes.pdf_File_Type)) return toast.warn('Only PDF format allowed')
        fd.append('file', e)
        fd.append('type', 'CLINICPROOF')
        fd.append('previousFileName', pdf ? pdf : null)
        fd.append('id', id)
        setLoader(true)
        try {
            let response = await UPLOAD_FILE_PDF(fd)
            toast.success(response.message)
            setPdf(response.data?.db_name)
            pdfRep.current.value = null
            setLoader(false)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }
    }
    // update clinic list 
    const formik = useFormik({
        initialValues: {
            clinicName: "",
            registrationNumber: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zipCode: "",
        },
        validationSchema: addClinicValidation,

        onSubmit: async (values) => {
            if (userdata.userId && specializationWithService?.service?.length === 0 && servicesvalue?.length === 0) {
                return toast.warn(" Please select services ");
            }
            if (!pdf) {
                return toast.warn(" Please upload proof ");
            }
            let payload = {
                clinicId: id,
                providerId: userdata.userId,
                clinicName: values?.clinicName,
                clinicRegistrationNumber: values.registrationNumber,
                proof: pdf,
                activeStatus: check,
                services: specializationWithService?.service?.length ? specializationWithService?.service : servicesvalue,

                address: values?.address,
                street: values.street,
                city: values.city,
                stateId: values.state?.[0]?.stateId,
                countryId: values?.country?.[0]?.id,
                zip: values.zipCode,
                image: DisplayPicture
            }
            setLoader(true)
            try {
                let response = await UPDATE_CLINIC(payload)
                toast.success(response.message);
                const destination = userdata.userId ? '/profile' : '/alldetails/basic-details';
                navigate(destination);
                setLoader(false)
            } catch (error) {
                toast.error(error?.message);
                setLoader(false)
            }
        },
    });

    // get list state
    const getStateList = async (countryId, StateId) => {
        let payload = {
            countryId: countryId,
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
            if (countryId && StateId) {
                const stateDetails = response.data.filter((cur) => cur?.stateId === StateId)
                formik.setFieldValue("state", stateDetails)
            }
        } catch (error) {
            setListState([])
            toast.error(error.message)
            formik.setFieldValue("state", [])
        } finally {
            setLoader(false)
        }
    }

    async function listcountrys(countryId) {
        let payload = {
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
            if (countryId) {
                const countryDetails = response.data.filter((cur) => cur?.id === countryId)
                formik.setFieldValue("country", countryDetails)
            }
        } catch (error) {
            setListDataCountry([])
        }
        finally {
            setLoader(false)
        }
    }
    const addClinicFildDataFun = () => {
        let data = {
            ...formik?.values,
            pdf,
            check: check,
            DisplayPicture: DisplayPicture,
            servicesvalue: servicesvalue
        }
        dispatch(AddClinicFildData(data))
    }

    const handleClose = (event) => {

        if (event === "YES") {
            setCheck(!check)
            setClinicToggle(false)
        } else {
            setClinicToggle(false)
        }

    }
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            getStateList(newValue?.[0]?.id, name)
            formik.setFieldValue(name, newValue)
            formik.setFieldValue("state", [])
        } else {
            formik.setFieldValue(name, newValue)
        }
    }
    return {
        servicesvalue,
        clinicPdf,
        specializationWithService,
        formik,
        ListDataCountry,
        ListState,
        show,
        loader,
        specializationVlaue,
        servicesList,
        check,
        id,
        pdf,
        userdata,
        pdfRep,
        uploadPdf,
        setSpecializationVlaue,
        setCheck,
        addClinicFildDataFun,
        setservicesList,
        setServicesvalue,
        setShow,
        setPdf,
        handleClose,
        clinicToggle, setLoader,
        setClinicToggle, FileRef, handalSearchAbleSelectBox, setDisplayPicture, DisplayPicture
    }
}

export default useEditClinic
