import React from "react";
import Modal from 'react-bootstrap/Modal';
import { activeStatusOption, dailogAllMessage } from "../../Context/constants";
import Loading from "../../Component/Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IconButton, TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import useTableSorting from "../../Hooks/useTableSorting";
import useServices from "./Hooks/useServices";
import userLogo from "../../Assets/Images/dummyimg.png"
import { ServiceImageFilePath } from "../../Context/api";
import { BiArchiveIn, } from 'react-icons/bi';
import { NoRecordFound } from "../../Component/NoRecordFound";
import { FiRefreshCcw } from "react-icons/fi";
import { SelectBox } from "../../Component/Inputs";
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IoMdAdd } from "react-icons/io";
import ReadMoreButton from "../../Component/ReadMoreButton";
const Services = () => {
  const {
    archiveUnarchive,
    setShowArchiv,
    setShowAdd,
    setImgUrl,
    setArchiveData,
    setController,
    reset,
    navigate,
    CustomButton,
    showArchiv,
    archivedata,
    addServices,
    loader,
    headCells,
    active,
    servicesListdata,
    paginationTotalCount,
    controller,
    id,
    Filter,
    handleChangeFilter,
    HandleSearchList,
  } = useServices()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  return (
    <>
      {loader && <Loading />}
      <div className="py-3  d-flex flex-column justify-content-between">
        <div>
          <header className="body_header" >
            <div className="d-flex justify-content-between align-items-center">
              <ul className="headerlist px-0">
                <li className="me-2 phone_hide">
                  <input type="text" onChange={handleChangeFilter} value={Filter?.services} placeholder="Search" className="form-control filter_input" name="services" />
                </li>
                <li className="me-2" style={{ width: 190 }}>
                  <SelectBox
                    placeholder="Select"
                    value={Filter?.archive}
                    name="archive"
                    keys="value"
                    label="label"
                    menuValeu="value"
                    handleChange={handleChangeFilter}
                    option={activeStatusOption}
                  />
                </li>
                <li className="me-2 phone_hide">
                  <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
                </li>
                <li className="me-2 d-flex justify-content-center align-items-center" >
                  <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                    <FiRefreshCcw />
                  </IconButton>
                </li>
                <li>
                  <CustomButton startIcon={<IoMdAdd />} disabled={active} variant="contained" onClick={() => {
                    addServices.setFieldValue("specializationId", id)
                    setShowAdd(true); setImgUrl(null);
                    navigate("add")
                  }}> Add Service</CustomButton>
                </li>
              </ul>
              {/* <ul className="headerlist px-0 phone_hide">
                <li>
                  <div className="dropdown-center ms-2">
                    <CustomButton variant="contained"
                      style={{ background: "#f5f5f5", color: "black" }}
                      startIcon={<ExitToAppIcon />}
                    >export</CustomButton>
                  </div>
                </li>
              </ul> */}
            </div>
          </header>
          <TableContainer   >
            {servicesListdata?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
            <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table" >
              {
                servicesListdata?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={servicesListdata?.length} />
              }
              <TableBody>
                {stableSort(servicesListdata, getComparator(order, orderBy)).map(
                  (row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return <React.Fragment key={row?.serviceId}>
                      <TableRow id="hover_tr">
                        <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                          {row.image === null ? <img alt={row.serviceName} src={userLogo} width={45} height={45} style={{ borderRadius: "50%" }} /> : <img alt={row.serviceName} src={ServiceImageFilePath + row.image} width={45} height={45} style={{ borderRadius: "50%" }} />}
                        </TableCell>
                        <TableCell id={labelId} align="left" >{row.serviceName}</TableCell>
                        <TableCell id={labelId} align="left" >{row?.specializationName}</TableCell>
                        <TableCell id={labelId} align="left" >{row?.noOfPatients}</TableCell>
                        {/* <TableCell id={labelId} align="left" className={`${row?.variablePrice ? "py-0" : ""}`}> {<p className={`mb-0 ${row?.variablePrice ? "text-decoration-line-through" : ""}`}>{`${row.currency}${row.fixedPrice}`}</p>}
                          <p className="" style={{ marginBottom: "0px" }}>{`${row?.variablePrice == '' ? '' : row.currency}${row?.variablePrice}`}</p></TableCell> */}
                        <TableCell id={labelId} align="left" style={{ whiteSpace: "wrap" }}> <ReadMoreButton text={row.description ? row.description : "-"} maxLength={100} /> </TableCell>
                        <TableCell id={labelId} align="right">
                          <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                          <ul className="dropdown-menu dropdown-menu-end me-3">
                            <li style={{ cursor: "pointer" }}><button className="dropdown-item"
                              onClick={() => {
                                navigate(`edit/${row.serviceId}`)
                              }} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Services</button></li>

                            <li style={{ cursor: "pointer" }}><button className="dropdown-item"
                              onClick={() => { setShowArchiv(true); setArchiveData({ serviceId: row.serviceId, archive: row.activeStatus }) }}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus === true ? "Archive" : "Unarchive"} Service`}</button></li>
                          </ul>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <nav className="w-100" aria-label="pagination">
          {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
        </nav>
      </div>
      <Modal show={showArchiv} onHide={() => setShowArchiv(false)} centered>
        <Modal.Body className="px-0">
          <div className="modal-body text-center px-0">
            <BiArchiveIn className="me-2 all_react_icons_color" />
            <p className=" mb-0">Are you sure?</p>
            <p>{archivedata.archive === true ? dailogAllMessage.Services.Archive : dailogAllMessage.Services.UnArchive}</p>
            <CustomButton variant="contained" className="me-3" onClick={() => archiveUnarchive("YES", archivedata)}> Yes</CustomButton>
            <CustomButton variant="outlined" onClick={() => archiveUnarchive("NO")} > No</CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Services;
