import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { RoleTitle } from "../../../Context/constants";
import { createpinValidation, pattern, signUpOtpValidation } from "../../../Context/Validation";
import { TIMEZONE } from "../../../Utils/Utils";
import { CREATE_PIN, FORGOT_PIN, RESEND_OTP, VERIFY_OTP } from "../../Repository/CredentialRepo";

const useForgot = () => {
    const [loader, setloader] = useState(false)
    const [active, setActive] = useState(1)
    const [timer, setTimer] = useState(60);
    const initValueForIndia = { phone: "", }
    const initValueForOther = { email: "" }
    const userData = JSON.parse(localStorage.getItem("forgot"))


    //////////////////////////////// forgot  start//////////////////////////////

    const formik = useFormik({
        initialValues: TIMEZONE() === "Asia/Kolkata" ? initValueForIndia : initValueForOther,
        validationSchema:
            (TIMEZONE() === "Asia/Kolkata") ?
                Yup.object({ phone: Yup.string().trim().required("Please enter mobile number ") })
                : Yup.object({ email: Yup.string().required("Please enter email ").matches(pattern?.EMAIL,"Please enter correct email ") }),
        validateOnChange: true,
        validate: (values) => {
            const errors = {};
            if (TIMEZONE() === "Asia/Kolkata") {
                if (values?.phone?.length > 0 && (values?.phone.match(pattern?.PHONE_NO))) {
                    delete errors.phone
                } else {
                    errors.phone = "Please enter a valid mobile number ";
                }
            }
            return errors;
        },
        onSubmit: async (values) => {
            const phone = { mobile: `${values.phone}`, timeZone: 'Asia/Calcutta', roleTitle: RoleTitle?.SERVICE_PROVIDER }
            const email = { email: values.email, timeZone: TIMEZONE(), roleTitle: RoleTitle?.SERVICE_PROVIDER }
            setloader(true)
            try {
                let response = await FORGOT_PIN((TIMEZONE() === "Asia/Kolkata") ? phone : email)
                toast.success(response?.message);
                localStorage.setItem("forgot", JSON.stringify(response?.data));
                setActive(2)
                setloader(false)
            } catch (error) {
                toast.error(error.message)
                setloader(false)
            }
        },
    });

    ////////////////////////////////////// forgot  end////////////////////////////////////

    ////////////////////////////////////// verifyOTP start////////////////////////////////

    const timeOutCallback = useCallback(
        () => setTimer((currTimer) => currTimer - 1),
        []
    );
    useEffect(() => {
        if (active === 2) {
            timer > 0 && setTimeout(timeOutCallback, 1000)
        }
    }, [timer, timeOutCallback, active])
    const resetTimer = () => {
        setTimer(60)
    }
    const resenOTP = async () => {
        let payload = {
            mobile: (TIMEZONE() === "Asia/Kolkata") ? userData.mobile : undefined,
            email: (TIMEZONE() !== "Asia/Kolkata") ? userData.email : undefined,
            userId: userData.userId,
            roleTitle: userData?.roleTitle
        }
        setloader(true)
        try {
            let response = await RESEND_OTP(payload)
            toast.success(response?.message);
            resetTimer()
            setloader(false)
            verifyOTPFun.resetForm()
        } catch (error) {
            toast.error(error.message)
            setloader(false)
        }
    };
    const verifyOTPFun = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: signUpOtpValidation,
        onSubmit: async (values) => {
            setloader(true)
            let payload = {
                userId: userData?.userId,
                otp: parseInt(values?.otp)  ,
                roleTitle:userData?.roleTitle
            }
            try {
                let response = await VERIFY_OTP(payload)
                toast.success(response?.message);
                setActive(3)
                setloader(false)
            } catch (error) {
                toast.error(error.message)
                setloader(false)
            }
        },
    });

    //////////////////////////////////////////// verifyOTP end //////////////////////////////

    /////////////////////////////////////////// create pin start/////////////////////////////

    const createPinFun = useFormik({
        initialValues: {
            newpin: "",
            confirmpin: "",
        },
        validationSchema: createpinValidation,
        onSubmit: async (values) => {
            setloader(true)
            let payload = {
                pin: values.newpin,
                confirmPin: values.confirmpin,
                userId: userData.userId,
                roleTitle: userData.roleTitle
            }
            try {
                let response = await CREATE_PIN(payload)
                toast.success(response?.message);
                localStorage.removeItem("forgot");
                setActive(4)
                setloader(false)
            } catch (error) {
                toast.error(error.message)
                setloader(false)
            }
        },
    });
    return {
        loader,
        active,
        formik,
        userData,
        verifyOTPFun,
        timer,
        createPinFun,
        resenOTP,
    }
}

export default useForgot
