import axios from 'axios';
import { envoirment } from "./envoirment";
import store from '../Redux/Store';
const baseUrl = `${envoirment.baseUrl}`
export const axiosInstance = axios.create({ baseURL: baseUrl })
axiosInstance.interceptors.request.use(
    config => {
        const reduxState = store?.getState()
        const SignupUserData = JSON.parse(localStorage.getItem("S_userdata"))
        const Token = SignupUserData?.token ? SignupUserData?.token : reduxState?.userDataRDX?.token
        if (Token) config.headers['Authorization'] = 'Bearer ' + Token;
        return config;
    },
    error => Promise.reject(error));

axiosInstance.interceptors.response.use(
    response => {
        localStorage.removeItem("network")
        return response;
    },
    error => {
        localStorage.setItem("network", (error?.code === "ERR_NETWORK") && JSON.stringify(error.message))
        if (error) {
            if (error.response && error?.response?.status === 401) {
                localStorage?.clear()
                window.location = '/';
            }
        }
        return Promise.reject(error);
    }
);