import React, { useEffect, useState } from 'react'
import { HiViewGridAdd } from "react-icons/hi";
import useMuiCompontent from '../../../Hooks/useMuiCompontent'
import { Modal } from 'react-bootstrap'
import check from "../../../Assets/Icons/check-mark (1).png"
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { ADD_DEBIT_CARD, CANCEL_PLAN, CHARGE_AMOUNT, CREATE_RAZORPAY_ORDER, DEBIT_CARD_LIST, PAYMENT_DETAILS, PLAN_LIST } from '../../Repository/SettingRepo';
import { toast } from 'react-toastify';
import Loading from '../../../Component/Loading';
import useReduxData from '../../../Hooks/useReduxData';
import moment from "moment-timezone";
import DebitCard from '../../../Component/DebitCard';
import TextBox from '../../../Component/TextBox';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements, useStripe, useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js';
import { RAZORPAY_KEYID, stripeKey } from '../../../Context/envoirment';
const stripePromise = loadStripe(stripeKey);


const CardForm = ({ cardDetails, setCardDetails, setErrorMessage, CustomButton, userData, setStripeElements }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (stripe && elements) {
      setStripeElements({ stripe, elements });
    }
  }, [stripe, elements, setStripeElements]);

  const handleCardChange = (event) => {
    if (event.complete) {
      const cardElement = elements.getElement(CardNumberElement);
      stripe.createPaymentMethod({ type: 'card', card: cardElement, }).then(({ paymentMethod }) => {
        if (paymentMethod) {
          setCardDetails((pre) => (
            {
              ...pre,
              brand: paymentMethod.card.brand,
              last4: paymentMethod.card.last4,
              exp_month: paymentMethod.card.exp_month,
              exp_year: paymentMethod.card.exp_year,
              paymentMethod: paymentMethod?.id
            }
          ));
          setErrorMessage("")
        }
      }).catch((err) => setErrorMessage(err.message));;
    } else if (event.error) {
      setErrorMessage(event.error.message);
    } else {
      setCardDetails((pre) => (
        {
          ...pre,
          brand: '',
          last4: '',
          exp_month: '',
          exp_year: '',
          paymentMethod: ""
        }
      ));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      toast.error('Stripe has not loaded yet.');
      setIsLoading(false);
      return;
    }
    if (!cardDetails?.holder_name) {
      setIsLoading(false);
      return toast.error("Please enter holder name")
    }
    const cardElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardDetails?.holder_name,
      },
    });
    if (error) {
      toast.error(error.message);
      setIsLoading(false);
      return;
    }
    let payload = {
      paymentMethodId: paymentMethod.id,
      userId: userData?.userId,
    };
    try {
      let response = await ADD_DEBIT_CARD(payload)
      toast.success(response.message);
    } catch (error) {
      toast.error(error.message)
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit} className="row d-flex justify-center ADD_CARD_FORM">
      <div className="col-12">
        <div className="row">
          <div className='col-12 d-flex justify-content-center mb-3 px-5'>
            <div className='w-50'>
              <DebitCard cardDetails={cardDetails} />
            </div>
          </div>
          <div className="col-12">
            Name
            <input type="text" maxLength={40} className="form-control" name="street" placeholder="Holder name " value={cardDetails?.holder_name}
              onChange={(e) => { setCardDetails((pre) => ({ ...pre, holder_name: e.target.value })) }}
            />
          </div>
          <div className="col-12 mt-3">
            Card number
            <CardNumberElement
              onChange={handleCardChange}
            />
          </div>
          <div className="col-6 mt-3">
            Expiration date
            <CardExpiryElement
              onChange={handleCardChange}
            />
          </div>
          <div className="col-6 mt-3">
            CVC
            <CardCvcElement
              onChange={handleCardChange}
            />
          </div>
          <div className="col-12 d-flex justify-content-end pt-3">
            <CustomButton variant="contained" type="submit" style={{ textTransform: "capitalize" }} disabled={!stripe || isLoading} >  {isLoading ? 'Processing...' : 'Save Card For Future'}</CustomButton>
          </div>
        </div>
      </div>
    </form>
  );
};

const MyPlan = () => {
  const { CustomButton } = useMuiCompontent()
  const [managePlan, setManagePlan] = useState(false)
  const [isCancel, setIscancel] = useState(false)
  const [Loader, setLoading] = useState(false)
  const [PlansDetails, setPlansDetails] = useState(null)
  const [paynowModal, setPaynowModal] = useState(false)
  const [cardList, setCardList] = useState([])
  const [errorMessage, setErrorMessage] = useState("")
  const [isSelectedCard, setIsSelectedCard] = useState(null)
  const navigate = useNavigate()
  const { reduxData: userData } = useReduxData("userDataRDX")
  const [stripeElements, setStripeElements] = useState({ stripe: null, elements: null });

  const [cardDetails, setCardDetails] = useState({
    brand: '',
    last4: '',
    exp_month: '',
    exp_year: '',
    holder_name: "",
    paymentMethod: ""
  });
  const getsubcriptionPlanDetails = async () => {
    setLoading(true)
    let payload = {
      providerId: userData?.userId,
      filter: "MYPLAN"
    }
    try {
      let response = await PLAN_LIST(payload)
      setPlansDetails(response?.data)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const findFeatures = (type) => {
    let Plansdata = { ...PlansDetails }
    const FeaturesNumber = Plansdata?.Features?.find((cur, index) => cur?.type === type)
    return FeaturesNumber?.number
  }

  const handleCancelPlan = async () => {
    setLoading(true)
    let payload = {
      providerId: userData?.userId,
      assignedSubscriptionPlanId: PlansDetails?.assignedSubscriptionPlanId
    }
    try {
      let response = await CANCEL_PLAN(payload)
      toast.success(response?.message)
      setTimeout(() => {
        localStorage?.clear()
        window.location = '/';
      }, 1000)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getCardList = async () => {
    if (userData?.planDetails?.planRegion === "India") return
    setLoading(true)
    let payload = {
      userId: userData?.userId,
    }
    try {
      let response = await DEBIT_CARD_LIST(payload)
      setCardList(response?.data)
    } catch (error) {
      setCardList([])
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const clearForm = () => {
    setCardDetails({
      brand: '',
      last4: '',
      exp_month: '',
      exp_year: '',
      holder_name: "",
      paymentMethod: ""
    });
    setErrorMessage("")
    setIsSelectedCard(null)
    setCardList([])
  }
  const payamount = async () => {
    if (cardList?.length === 0) {
      if (!cardDetails?.holder_name) {
        return toast.error("Please enter holder name")
      }
      if (!cardDetails?.paymentMethod) {
        return toast.error(errorMessage || "Please enter valid card details.");
      }
      if (!stripeElements.stripe || !stripeElements.elements) {
        return toast.error('Stripe has not loaded yet.');
      }
    } else {
      if (!isSelectedCard) {
        return toast.error("Please choose card");
      }
    }
    setLoading(true);
    try {
      let payload = {
        userId: userData?.userId,
        amount: PlansDetails.finalAmount,
        paymentMethodId: cardList?.length === 0 ? cardDetails.paymentMethod : isSelectedCard?.cardToken
      };
      let response = await CHARGE_AMOUNT(payload);
      toast.success(response?.message);
      setPaynowModal(false);
      clearForm()
      getsubcriptionPlanDetails()
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getpaymentDetails = async (paymentdetails) => {
    setLoading(true);
    try {
      let payload = {
        ...paymentdetails,
        providerId: userData?.userId,
        assignedSubscriptionPlanId: PlansDetails?.assignedSubscriptionPlanId
      };
      let response = await PAYMENT_DETAILS(payload);
      toast.success(response?.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }


  const handleRazorpay = async () => {
    setLoading(true);
    try {
      let payload = {
        amount: PlansDetails?.finalAmount,
      };
      let response = await CREATE_RAZORPAY_ORDER(payload);
      setPaynowModal(false)
      const { order } = response.data;
      const options = {
        key: RAZORPAY_KEYID,
        amount: order?.amount,
        currency: order?.currency,
        name: 'Zimo.one',// Your Company Name
        description: 'Subscription plan payment',
        order_id: order?.id,
        handler: function (response) {
          toast.success(`Payment successful`);
          setPaynowModal(false);
          clearForm();
          getsubcriptionPlanDetails();
          getpaymentDetails(response)
        },
        prefill: {
          name: `${userData?.firstName} ${userData?.lastName}`,
          email: userData?.email,
          contact: userData?.mobile
        },
        notes: { address: 'Corporate Office' },
        theme: { color: '#38C4B9' },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.on('payment.failed', function (response) {
        toast.error(`Payment failed! Error: ${response?.error?.description}`);
      });
      rzp1.on('payment.error', function (response) {
        toast.error(`Payment error occurred! Error: ${response?.error?.description}`);
      });
      rzp1.open();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getsubcriptionPlanDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {Loader && <Loading />}
      <div className='d-flex  h-100  justify-content-between flex-column py-3 MY_PLAN'>
        <div className='row'>
          <div className="col-lg-5 px-4 LEFT ">
            <div className="col ">
              <img src={require("../../../Assets/Images/plan-schedule-management-process4_1681880580.jpg")} className='img-fluid ' style={{ width: "100%", height: "200px", borderRadius: "20px" }} alt="" />
              <h5 className='mt-3'>Thanks for using WINKDOC.COM</h5>
              <p className='mt-3'>It is a long established fact that a reader will be
                distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is
                that it has a more-or-less normal distribution.</p>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-5 px-4 RIGHT">
            <div className="col ">
              <div className='d-flex justify-content-between ps-1'>
                <h6>{PlansDetails?.title} Plan</h6>
              </div>
              <div className="row mt-3 px-3">
                <div className="px-0 col-12 d-flex justify-content-between" style={{ borderBottom: "1.9px solid #ededed" }}>
                  <div >
                    <i className="fa-solid fa-location-dot me-2" ></i>
                    <span >Location</span>
                  </div>
                  <p >{findFeatures("Add Multiple Clinic")}</p>
                </div>
                <div className="px-0 col-12 d-flex justify-content-between mt-3" style={{ borderBottom: "1.9px solid #ededed" }}>
                  <div >
                    <i className='bx bx-donate-heart me-2 fs-6'></i>
                    <span >Services</span>
                  </div>
                  <p >{findFeatures("No. Of Service")}</p>
                </div>
                <div className="px-0 col-12 d-flex justify-content-between mt-3" style={{ borderBottom: "1.9px solid #ededed" }}>
                  <div >
                    <i className='bx bxs-group  me-2 fs-6'></i>
                    <span >Teams</span>
                  </div>
                  <p >{findFeatures("No. Of Team")}</p>
                </div>
              </div>
              <h5 className='mt-4 ps-1'> Billing and payment</h5>
              <div className='ps-1'>
                <i className="fa-regular fa-calendar me-2 "></i>
                <span > {PlansDetails?.planType} next payment on {moment(PlansDetails?.endDate)?.format("lll")} </span>
              </div>
              {
                PlansDetails?.planExpiring && <div>
                  <span>
                    Before Existing Plan Expire
                    <span className='cursor' style={{ textDecoration: "underline", color: "#2828bb" }} onClick={() => { setPaynowModal(true); getCardList() }} >  Pay Now </span>
                  </span>
                </div>
              }
              <div className='col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end'>
                <p className='text-end mt-5 cursor d-inline' style={{ color: '#38c4b9' }} onClick={() => navigate("history")}>View billing history</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-2'>
          <CustomButton variant="contained" type="button" style={{ textTransform: "capitalize" }} onClick={() => setManagePlan(true)}  >manage plan</CustomButton>
        </div>
      </div>
      <Modal size='lg' show={paynowModal} onHide={() => { setPaynowModal(false); setIsSelectedCard(null); clearForm() }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Pay Now</Modal.Title>
        </Modal.Header>
        <div className='p-3'>

          {
            userData?.planDetails?.planRegion !== "India" ? <div>
              {
                cardList?.length > 0 ? <div className=" row flex-nowrap horizontal_scroll " style={{ overflowX: "auto" }} >
                  {
                    cardList?.map((cur, index) => {
                      return <div className="col-6 p-2">
                        <div className={`col`} style={{ border: cur?.cardToken === isSelectedCard?.cardToken ? "2px solid #38C4B9" : "2px solid #fff", borderRadius: "17px" }} onClick={() => setIsSelectedCard(cur)}>
                          <DebitCard key={index} cardDetails={{
                            brand: cur?.brand,
                            last4: cur?.last4,
                            exp_month: cur?.expMonth,
                            exp_year: cur?.expYear,
                            holder_name: cur?.name
                          }}
                            IsActiveCard={cur?.default}
                            cardToken={cur?.cardToken}
                          />
                        </div>
                      </div>
                    })
                  }
                </div> : <Elements stripe={stripePromise}>
                  <CardForm
                    cardDetails={cardDetails}
                    setCardDetails={setCardDetails}
                    setErrorMessage={setErrorMessage}
                    CustomButton={CustomButton}
                    userData={userData}
                    setStripeElements={setStripeElements}
                  />
                </Elements>
              }

            </div> : null
          }
          <div className="row">
            <div className="col-sm-6  pb-2 " >
              <label className="form-label mb-0">Title</label>
              <TextBox textBoxData={<p>{PlansDetails?.title ? PlansDetails?.title : "--"}</p>} />
            </div>
            <div className="col-sm-6  pb-2 " >
              <label className="form-label mb-0">Plan Type</label>
              <TextBox textBoxData={<p>{PlansDetails?.planType ? PlansDetails?.planType : "--"}</p>} />
            </div>
            <div className="col-sm-6  pb-2 " >
              <label className="form-label mb-0">Base Price</label>
              <TextBox textBoxData={<p>{PlansDetails?.price ? PlansDetails?.price : "--"}</p>} />
            </div>
            <div className="col-sm-6  pb-2 " >
              <label className="form-label mb-0">Add On Amount</label>
              <TextBox textBoxData={<p>{PlansDetails?.AddOnsAmount ? PlansDetails?.AddOnsAmount : "--"}</p>} />
            </div>
            <div className="col-sm-3  pb-2 " >
              <label className="form-label mb-0">Discount Type</label>
              <TextBox textBoxData={<p>{PlansDetails?.discountType ? PlansDetails?.discountType : "--"}</p>} />
            </div>
            <div className="col-sm-3  pb-2 " >
              <label className="form-label mb-0">Discount</label>
              <TextBox textBoxData={<p>{PlansDetails?.discount ? PlansDetails?.discount : ""}</p>} />
            </div>
            <div className="col-sm-6  pb-2 " >
              <label className="form-label mb-0">Final Price (After Discount)</label>
              <TextBox textBoxData={<p>{PlansDetails?.finalAmount ? PlansDetails?.finalAmount : '--'}</p>} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 d-flex justify-content-end">
              {
                userData?.planDetails?.planRegion === "India" ? <CustomButton variant="contained" type="button" style={{ textTransform: "capitalize" }} onClick={handleRazorpay}>Pay  amount</CustomButton> :
                  <CustomButton variant="contained" type="button" style={{ textTransform: "capitalize" }} onClick={payamount}>Pay  amount</CustomButton>
              }
            </div>
          </div>
        </div>

      </Modal>



      <Modal size='xl' show={managePlan} onHide={() => { setManagePlan(false); setIscancel(false) }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Manage Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <div className="MANAGE_PLAN pb-2">
            <div className="row">
              <div className="col-lg-4">
                <h5 className='mt-3'>Your Current Plan Includes: </h5>
                <div className="mt-3 px-2">
                  <div className="col-12 d-flex justify-content-between" >
                    <div >
                      <i className="fa-solid fa-location-dot me-2" ></i>
                      <span >Location</span>
                    </div>
                    <p >{findFeatures("Add Multiple Clinic")}</p>
                  </div>
                  <div className="col-12 d-flex justify-content-between mt-2" >
                    <div >
                      <i className='bx bx-donate-heart me-2 fs-6'></i>
                      <span >Services</span>
                    </div>
                    <p >{findFeatures("No. Of Service")}</p>
                  </div>
                  <div className="col-12 d-flex justify-content-between mt-2" >
                    <div >
                      <i className='bx bxs-group  me-2 fs-6'></i>
                      <span >Teams</span>
                    </div>
                    <p >{findFeatures("No. Of Team")}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 AVAILABLE_ACTIONS ">
                <h5 className='mt-3'>Available Actions: </h5>
                <h5 className='mt-3'> <HiViewGridAdd className='fs-4' /> Add On: </h5>
                <ul className='w-75 '>
                  {PlansDetails?.AddOns?.map((cur, index) => {
                    return <li key={index}>{cur?.type}</li>
                  })}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <h5 className='mt-3'>Add Ons: </h5>
                <div className="row mt-3 px-2">
                  {PlansDetails?.checkedAddOns?.map((cur, index) => {
                    return <div className="col-12  px-0 " key={index} >
                      <img src={check} width={20} height={20} alt="" />
                      <span className='ms-2'>{cur?.type}</span>
                    </div>
                  })}
                  {/* <div className="col-12 " >
                  <img src={deleteIcon} alt="" width={20} height={20} />
                  <span >Location</span>
                </div> */}

                </div>
              </div>
              <div className="col-lg-4">
                <h5 className='mt-3'> <HiViewGridAdd className='fs-4' />End Your Service: </h5>
                <p>You can drop your subscription anytime</p>
              </div>
              <div className="col-lg-4">
                {
                  isCancel && <motion.div className="mb-3"
                  // animate={isCancel ? "open" : "closed"}
                  // variants={variants}
                  >
                    <div className='border mt-3 rounded p-3'>
                      <p>Are you sure you want to cancel your plan if you cancal this plan then you will recieve $ 0  </p>
                      <div className='d-flex justify-content-between'>
                        <span style={{ background: "red", fontSize: "12px" }} onClick={() => setIscancel(false)} className='px-2 py-1 rounded cursor text-white'>Cancel</span>
                        <span style={{ background: "green", fontSize: "12px" }} className='px-2 py-1 rounded cursor text-white' onClick={handleCancelPlan}>Proceed</span>
                      </div>
                    </div>
                  </motion.div>
                }
              </div>
            </div>

            <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end">
              <div className="d-flex">
                <CustomButton variant="contained" type="button" disabled={isCancel} style={{ textTransform: "capitalize" }} className='me-3' onClick={() => setIscancel(true)}   >Cancel plan</CustomButton>
                <CustomButton variant="contained" type="button" style={{ textTransform: "capitalize" }}  >
                  <a href="https://winkdoc.com/contact/" target="_blank" rel="noreferrer noopener" style={{ color: "#fff", textDecoration: "none" }}>
                    upgrade plan
                  </a>
                </CustomButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MyPlan


