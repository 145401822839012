import { deleteAvailability, deleteunavailability, insertavailability,  insertunavailability, listavailabilityclinic,  listunavailability, updateclinicavailability} from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"
////////////////////////////////////  AVAILABILITY ////////////////////////////////////

export const AVAILABILITY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listavailabilityclinic(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_AVAILABILITY = async (body) => {
    try {
        let response = await axiosInstance.post(insertavailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_AVAILABILITY = async (body) => {
    try {
        let response = await axiosInstance.post(updateclinicavailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_AVAILABILITY = async (body) => {
    try {
        let response = await axiosInstance.post(deleteAvailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

//////////////////////////////// UN-AVAILABILITY//////////////////////////////////////////// 

export const INSERT_UN_AVAILABILITY = async (body) => {
    try {
        let response = await axiosInstance.post(insertunavailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

export const UN_AVAILABILITY_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(listunavailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const DELETE_UN_AVAILABILITY = async (body) => {
    try {
        let response = await axiosInstance.post(deleteunavailability(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}






