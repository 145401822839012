import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "agendaDetails",
    initialState: {},
    reducers: {
        addAgendaDetails(state, action) {
            return state = action.payload;
        },
    },
});
export const { addAgendaDetails } = reducer.actions;
export default reducer.reducer;
