import React from "react";
import { ToastContainer } from "react-toastify";
import { Errors } from "../../Component/Error";
import Loading from "../../Component/Loading";
import { Avatar, Button, IconButton, Input, InputAdornment, TextField } from "@mui/material";
import useLogin from "./Hooks/useLogin";
import { TIMEZONE } from "../../Utils/Utils";
import { MdPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { IoMdUnlock } from "react-icons/io";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { motion } from 'framer-motion';
import { TeamImageFilePath, clinicPictures, userImageFilePath } from '../../Context/api';
import { FaCheckCircle } from "react-icons/fa";
import userImageDummy from "../../Assets/Images/dummyimg.png"
import { RoleTitle } from "../../Context/constants";

const Login = () => {
  const {
    navigate,
    UserLoginFun,
    setActive,
    handleChooseProfile,
    handleChooseLocation,
    Login,
    handleClickShowPassword,
    handleMouseDownPassword,
    showPassword,
    PinCreate,
    ChooseLocationList,
    chooseLocationData,
    UserData,
    loader,
    formik,
    userList,
    active,
    chooseprofile,
  } = useLogin()
  const [ShowHide, setShowHide] = React.useState(false);
  const handleClickPassword = () => setShowHide((show) => !show);
  const handlePassword = (event) => {
    event.preventDefault();
  };


  return (
    <>
      {loader && <Loading />}
      <ToastContainer />
      <div className="LOGIN_CONTAINER">
        <div className="container">
          <div className="row LOGIN_SECTION shadow_hide ">
            <div className="col-lg-6 col-12 INFO_SECTION d-none d-lg-block ">
              <div>
                {/* <h2>Lorem Ipsum Slider Here!</h2>
                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content.</p> */}
              </div>
            </div>
            {
              active === 1 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Welcome Back</h3>
                <p style={{ width: "80%" }} className="text-center">Enter your credentials</p>
                <form action="" className="FORM" onSubmit={formik.handleSubmit}>

                  <div className="col-sm-12 mb-3">
                    <label className="form-label mb-0 ms-4"> {(TIMEZONE() === "Asia/Kolkata") ? "Mobile Number" : "Email"} <span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      {(TIMEZONE() === "Asia/Kolkata") ? <MdPhone className="me-1 ICON" /> : <MdEmail className="me-1 ICON" />}
                      {
                        (TIMEZONE() === "Asia/Kolkata") ?
                          // <PhoneNumber
                          //   width={100}
                          //   Require={false}
                          //   Name="phone"
                          //   HandleChange={formik}
                          //   Value={formik?.values?.phone}
                          //   countrySelectComponent="null"
                          // />
                          <TextField
                            variant="standard"
                            onChange={(e) => { formik.setFieldValue("phone", e.target.value) }}
                            name="phone"
                            value={formik?.values?.phone}
                            type="number"
                            style={{ width: "100%" }}
                            color='primary'
                            onInput={(e) => {
                              let maxValue = 10
                              e.target.maxLength = maxValue;
                              if (e.target.value.length > maxValue) {
                                e.target.value = e.target.value.slice(0, maxValue);
                              }
                              if (parseFloat(e.target.value) < 0) {
                                e.target.value = 0;
                              }
                            }}
                          />

                          : <TextField type="email" className="w-100 " maxLength={50} name="email" id="standard-basic" {...formik.getFieldProps("email")} variant="standard" placeholder="Enter your email " />
                      }
                    </div>
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={formik} name={`${(TIMEZONE() === "Asia/Kolkata") ? "phone" : "email"}`} />
                    </div>
                  </div>
                  <div className="col-sm-12 mb-2">
                    <label className="form-label mb-0 ms-4">PIN<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <IoMdUnlock className="me-1 ICON" />
                      <Input
                        {...formik.getFieldProps("password")}
                        name="password"
                        style={{ width: "100%" }}
                        id="standard-adornment-password"
                        type={ShowHide ? 'number' : 'password'}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickPassword}
                              onMouseDown={handlePassword}
                            >
                              {ShowHide ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div >
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={formik} name={"password"} />
                    </div>
                  </div>
                  <div className="w-100 d-flex justify-content-end">
                    <p className="text-end d-inline" onClick={() => navigate("/forgot-pin")}>Forgot PIN ?</p>
                  </div>
                  <Button type="submit" variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize"}}> Next</Button>
                </form>
              </motion.div>
            }
            {
              active === 2 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Choose Profile</h3>
                <div className="FORM ">
                  <div className="USER-PROFILE CUSTOM_SCROLL_STYLE">
                    {
                     userList?.map((cur, index) => {
                        return <div key={index} className={`USER-PROFILE-CONTAINER  cursor ${chooseprofile?.userId === cur?.userId ? "ACTIVE" : ""}`} onClick={() => handleChooseProfile(cur)}>
                          <div className="IMG shadow mb-2">
                            <img src={cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? (cur?.businessProfileImage ? userImageFilePath + cur?.businessProfileImage : userImageDummy) :
                              (cur?.profileImage ? TeamImageFilePath + cur?.profileImage : userImageDummy)} alt="" />
                            <FaCheckCircle className="ICON" />
                          </div>
                          <p className='text-center' > {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? cur?.businessName : `${cur?.firstName} ${cur?.lastName}`}  </p>
                          <p className='text-center mt-0' >  {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? `` : `(${cur?.businessName})`}  </p>
                          <p className="text-center">{cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? "(Provider)" : cur?.roleTitle === RoleTitle?.STAFF ? "(Team)" : "(Front Desk)"}</p>
                        </div>
                      })
                    }
                  </div>
                  <Button disabled={chooseprofile?.userId ? false : true} onClick={() => UserLoginFun(chooseprofile)} type="button" variant="contained" className="w-100 fs-6 mt-5" style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize" }}> Next</Button>
                </div>
                <p className="BACK-BUTTON" onClick={() => { setActive(1) }} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
            {
              active === 3 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Choose Location</h3>
                <div className="FORM ">
                  <div className="USERS_LOCATION_LIST CUSTOM_SCROLL_STYLE px-4 pb-3">
                    {
                     ChooseLocationList?.map((cur, index) => {
                        return <div key={index} className={`  px-2  LOCATION d-flex justify-content-between align-items-center mt-3 ${chooseLocationData?.clinicId === cur?.clinicId && "ACTIVE"} ${UserData?.location?.clinicId === cur?.clinicId ? "" : "cursor"}`} onClick={() => handleChooseLocation(cur)}>
                          <div className='d-flex align-items-center w-100'>
                            <Avatar
                              alt={cur?.clinicName?.toUpperCase()}
                              sx={{ width: 40, height: 40 }}
                              src={clinicPictures + cur?.image} />
                            <div className='ms-2' style={{ width: 'calc(100% - 40px)' }}>
                              <h6 className='m-0 fs-6 TEXT_ELLIPSIS' style={{ fontSize: "14px" }}>{cur.clinicName}</h6>
                              <h6 className="m-0  mt-1" style={{ fontSize: "12px", }}><i className="fa-solid fa-location-dot me-2 fs-6"></i>{` ${cur?.city}  `} </h6>
                            </div>
                          </div>
                        </div>
                      })
                    }
                  </div>
                  <Button disabled={chooseprofile?.userId ? false : true} onClick={Login} type="button" variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px"  ,textTransform: "capitalize" }}> Login</Button>
                </div>
                <p className="BACK-BUTTON" onClick={() => { setActive(1) }} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
            {
              active === 4 && <motion.div className="col-lg-6 col-12 FORM_SECTION"
                initial={{ x: 300 }}
                animate={{ x: 0 }}
                exit={{ x: -300 }}
                transition={{ duration: .5 }}
              >
                <img src={require("../../Assets/Images/LOGO 1 (1) 1.png")} alt="winkdoclogo.png" className="img-fluid" width={80} height={80} />
                <h3>Create PIN</h3>
                <p style={{ width: "80%" }} className="text-center">Set the new PIN for the account so that you
                  can easily login and use features.</p>
                <form action="" className="FORM" >
                  <div className="col-sm-12 mb-2">
                    <label className="form-label mb-0 ms-4">New PIN<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <IoMdUnlock className="me-1 ICON" />
                      <Input
                        onChange={(e) => PinCreate.setFieldValue("newpin", e.target.value)}
                        value={PinCreate.values.newpin}
                        name="password"
                        style={{ width: "100%" }}
                        id="standard-adornment-password"
                        type={showPassword.newPin ? 'number' : 'password'}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword("newPin")}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.newPin ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                    </div >
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={PinCreate} name={"newpin"} />
                    </div>
                  </div>
                  <div className="col-sm-12 mb-2">
                    <label className="form-label mb-0 ms-4">Confirm PIN<span className='Star_Mark'>*</span></label>
                    <div className="d-flex align-items-center">
                      <IoMdUnlock className="me-1 ICON" />
                      <Input
                        onChange={(e) => PinCreate.setFieldValue("confirmpin", e.target.value)}
                        value={PinCreate.values.confirmpin}
                        name="password"
                        style={{ width: "100%" }}
                        id="standard-adornment-password"
                        type={showPassword.confirmPin ? 'number' : 'password'}
                        onInput={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword("confirmPin")}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword.confirmPin ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />

                    </div >
                    <div className="ms-4" style={{ height: "5px" }}>
                      <Errors formikfun={PinCreate} name={"confirmpin"} />
                    </div>
                  </div>
                  <Button type="button" onClick={PinCreate.handleSubmit} variant="contained" className="w-100 fs-6 mt-5 " style={{ background: "#38C4B9", padding: "10px 30px 10px 30px", borderRadius: "25px" ,textTransform: "capitalize" }}>Save</Button>
                </form>
                <p className="BACK-BUTTON" onClick={() => { setActive(1) }} >Back to <span style={{ color: "#33B0A7", cursor: "pointer" }}>Login</span> </p>
              </motion.div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
