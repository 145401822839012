import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { UPLOAD_FILE_PDF } from "../../Repository/ProfileRepo";
import { UPDATE_SETTING, VIEW_SETTING_DETAILS } from "../../Repository/SettingRepo";
import { DELETE_FILE } from "../../Repository/CommonRepo";
import { RoleTitle } from "../../../Context/constants";

const usePolicies = () => {
    const [tab, setTab] = useState(1)
    const [policiesData, setPoliciesData] = useState("")
    const [policy, setPolicy] = useState(null)
    const [loader, setLoader] = useState(false)
    const [policyType, setPolicyType] = useState('BOOKINGPOLICY')
    const { reduxData: userData } = useReduxData("userDataRDX")
    const [settingDetails, setSettingDetails] = useState(null)
    const FileRef = useRef()
    useEffect(() => {
        getPolicie()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const uploadPdf = async (e) => {
        if (!e) return
        const fd = new FormData()
        const fileTypes = { pdf_File_Type: ['application/pdf'] }
        if (e.size / 1024 / 1024 > 10) return toast.warn('Please upload file less than 10 MB')
        if (!e.type.includes(fileTypes.pdf_File_Type)) return toast.warn('Only PDF format allowed')
        fd.append('file', e)
        fd.append('type', policyType)
        fd.append('previousFileName', policiesData)
        fd.append('id', settingDetails?.settingId)
        setLoader(true)
        try {
            let response = await UPLOAD_FILE_PDF(fd)
            setPoliciesData(response.data?.db_name)
            FileRef.current.value = null
            setLoader(false)
        } catch (error) {
            toast.error(error?.message);
            setLoader(false)
        }

    }

    const saveBookingPolicy = async () => {
        if (!policiesData.length) return
        setLoader(true)
        let payload = {
            providerId: userData.roleTitle === RoleTitle.STAFF ? userData?.providerId : userData?.userId,
            bookingPolicy: policyType === "BOOKINGPOLICY" ? policiesData : undefined,
            cancellationPolicy: policyType === "CANCELLATIONPOLICY" ? policiesData : undefined,
            reschedulingPolicy: policyType === "RESCHEDULINGPOLICY" ? policiesData : undefined,
            refundPolicy: policyType === "REFUNDPOLICY" ? policiesData : undefined
        }
        setLoader(true)
        try {
            let response = await UPDATE_SETTING(payload)
            getPolicie()
            toast.success(response?.message)
            setLoader(false)

        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    const getPolicie = async () => {
        setLoader(true)
        let payload = {
            providerId: (userData.roleTitle === RoleTitle.STAFF||userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        try {
            let response = await VIEW_SETTING_DETAILS(payload)
            setPolicy({
                BookingPolicy: response?.data?.bookingPolicy,
                CancellationPolicy: response?.data?.cancellationPolicy,
                RefundPolicy: response?.data?.refundPolicy,
                ReschedulingPolicy: response?.data?.reschedulingPolicy,
            })
            setLoader(false)
            setSettingDetails(response?.data)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    const policyDelete = async () => {
        setLoader(true);
        let fileName;
        switch (policyType) {
            case "BOOKINGPOLICY":
                fileName = policy?.BookingPolicy;
                break;
            case "CANCELLATIONPOLICY":
                fileName = policy?.CancellationPolicy;
                break;
            case "RESCHEDULINGPOLICY":
                fileName = policy?.ReschedulingPolicy;
                break;
            case "REFUNDPOLICY":
                fileName = policy?.RefundPolicy;
                break;
            default:
                fileName = null;
        }
        const payload = {
            id: settingDetails?.settingId,
            type: policyType,
            fileName: fileName
        };

        try {
            // Make the API call
            const response = await DELETE_FILE(payload);
            toast.success(response?.message);
            setPoliciesData("");
            getPolicie();
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setLoader(false);
        }
    };
    const ChangeTab = (Type) => {
        switch (Type) {
            case "Booking":
                setTab(1)
                setPoliciesData("")
                setPolicyType('BOOKINGPOLICY')
                break;
            case "Cancelation":
                setTab(2)
                setPoliciesData("")
                setPolicyType('CANCELLATIONPOLICY')
                break;
            case "Refund":
                setTab(3)
                setPoliciesData("")
                setPolicyType('REFUNDPOLICY')
                break;
            case "Reschedule":
                setPoliciesData("")
                setPolicyType('RESCHEDULINGPOLICY')
                setTab(4)
                break;
            default: console.log("This is default ")
        }
    }
    return {
        saveBookingPolicy,
        policyDelete,
        ChangeTab,
        uploadPdf,
        userData,
        loader,
        policy,
        policiesData,
        FileRef,
        tab,
    }
}

export default usePolicies
