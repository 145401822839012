import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const SecviceSEction = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const ActiveTab = splitPath[splitPath.length - 1]
    useEffect(() => {
        document.title = "Expertise"
    }, [])
    return (
        <>
            <div className="common-component-box">
                <header className="body_header" >
                    <h2 className='page_name'>Expertise</h2>
                </header>
                <div className="common-white-bg-box mx-0 shadow  position-relative pt-0 ">
                    <div className="col-12 pb-3 position-sticky top-0 z-3 pt-2" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
                        <ul className="nav  mb-0  pb-1 px-2 ">
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${ActiveTab === "specialization" && "active_teams_frofile"}`} onClick={() => navigate(`specialization`)} id="personal" type="button">Specialization</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${ActiveTab === "services" && "active_teams_frofile"}`} id="education" type="button" onClick={() => navigate(`services`)}>Services</button>
                            </li>
                        </ul>
                    </div>
                    <div className='px-2 PATIENT-OUTLET-HIEGHT'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecviceSEction
