import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import Loading from '../../Component/Loading'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import { NoRecordFound } from '../../Component/NoRecordFound'
import useTableSorting from '../../Hooks/useTableSorting'
import { VACCINATION_FORM_LIST } from '../Repository/VaccinationRepo'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FaArrowLeft } from 'react-icons/fa'
import useReduxData from '../../Hooks/useReduxData'
const VaccinationCalendarOverview = () => {
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate()
    const [VaccinationFormList, setVaccinationFormList] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10, searchPage: null });
    const { reduxData: userdata, } = useReduxData("userDataRDX")
    const { vaccinationCategoryId } = useParams()
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "formName", numeric: true, disablePadding: false, label: "Vaccination Form", isShorted: true },
        { id: "action", numeric: false, disablePadding: false, showLeft: true, label: "Action", isShorted: false },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

    /////////////////////////// list vaccination form list //////////////////////////
    async function getVaccinationFormList() {
        const { page, rowsPerPage } = controller;
        const payload = {
            page: page,
            count: rowsPerPage,
            providerId: userdata?.userId,
            activeStatus: true,
            vaccinationCategoryId: vaccinationCategoryId,
            publishStatus: true
        };
        setLoader(true)
        try {
            let response = await VACCINATION_FORM_LIST(payload)
            setVaccinationFormList(response?.data)
            setPaginationTotalCount(response?.totalCount)
            setLoader(false)
        } catch (error) {
            if (error.message !== "No vaccination form found") toast.error(error.message)
            setVaccinationFormList([])
            setPaginationTotalCount(0)
            setLoader(false)
        }
    }
    useEffect(() => {
        getVaccinationFormList()
        navigate({ replace: true, state: null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center ">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate("/appointment/today-agenda/overview") }} />
                        <h2 className='page_name'>Vaccination Calendar </h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0 shadow px-2 pt-3 d-flex justify-content-between flex-column" >
                    <div>
                        <TableContainer  >
                            {VaccinationFormList.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                            <Table
                                id="table_mui"
                                aria-labelledby="tableTitle"
                                size={"medium"}
                                aria-label="enhanced table">
                                {
                                    VaccinationFormList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={VaccinationFormList?.length} />
                                }
                                <TableBody className="table_overflow_scroll" >
                                    {stableSort(VaccinationFormList, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={index}><TableRow id="hover_tr"
                                                style={{ cursor: 'pointer' }}>
                                                <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                    {controller?.rowsPerPage * (controller?.page - 1) + index + 1}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{row?.formName}</TableCell>
                                                <TableCell id={labelId} align="right"  >
                                                        <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul className="dropdown-menu dropdown-menu-end me-3">
                                                            <li><button className={`dropdown-item`} onClick={() => navigate(`${row?.vaccinationFormId}`)}><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Vaccination Calendar</button></li>
                                                        </ul>
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <nav className=" w-100  " aria-label="pagination">
                        {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                    </nav>
                </div>
            </div>
        </>
    )
}

export default VaccinationCalendarOverview
