import Switch from '@mui/material/Switch';
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
import { Button} from '@mui/material';
const useMuiCompontent = () => {
    const GreenSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#38C4B9",
            "&:hover": {
                backgroundColor: alpha(teal[800], theme.palette.action.hoverOpacity),
            },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: " #ACACAC",
            border: "2px solid #38C4B9",
        },
        fontSize: "13px"
    }));
    const CustomButton = styled(Button)(({ theme, variant }) => {
        let styles = {
            textTransform: "capitalize" 
        };
        styles.transition = 'box-shadow 0.3s';
        styles.padding = "5px 8px";
        styles.fontSize = "13px";
        styles['&:hover'] = {
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
            border: `${variant === "outlined" ? "1px solid #38C4B9" : "none"}`,
            backgroundColor: `${variant === "outlined" ? "transparent" : "#38C4B9"}`
        };
        if (variant === 'outlined') {
            styles.border = '1px solid #38C4B9';
            styles.backgroundColor = 'transparent';
            styles.color = "#38C4B9";
            styles.height = "34.75px"
        } else if (variant === 'contained') {
            styles.backgroundColor = '#38C4B9';
            styles.color = "#fff";
        }
        return styles;
    });

    return {
        GreenSwitch,
        CustomButton,
    }
}

export default useMuiCompontent
