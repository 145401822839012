import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import { Errors } from '../../../Component/Error'

const AddSymptoms = memo(({ modal, setModal, newAddSymptoms, CustomButton }) => {
    return (
        <Modal show={modal.Symptoms} onHide={() => setModal((pre) => ({ ...pre, Symptoms: false }))} centered>
            <Modal.Body>
                <div className="text-center">
                    <h4 className="modal-title mb-3">Add Symptoms</h4>
                </div>
                <div className="modal-body  cus-i">
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label mb-0">Symptoms<span className='Star_Mark'>*</span></label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            value={newAddSymptoms?.values?.Symptoms}
                            onChange={(e) => newAddSymptoms?.setFieldValue("Symptoms", e.target.value)}
                        />
                        <Errors formikfun={newAddSymptoms} name={"Symptoms"} />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <CustomButton variant="contained" onClick={newAddSymptoms.handleSubmit} >Save</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})

const AddChronicDisease = memo(({ modal, setModal, newAddChronicDisease, CustomButton }) => {
    return (
        <Modal show={modal.ChronicDisease} onHide={() => setModal((pre) => ({ ...pre, ChronicDisease: false }))} centered>
            <Modal.Body>
                <div className="text-center">
                    <h4 className="modal-title mb-3">Add Chronic Disease</h4>
                </div>
                <div className="modal-body  cus-i">
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label mb-0">Chronic Disease<span className='Star_Mark'>*</span></label>
                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            value={newAddChronicDisease?.values?.ChronicDisease}
                            onChange={(e) => newAddChronicDisease?.setFieldValue("ChronicDisease", e.target.value)}
                        />
                        <Errors formikfun={newAddChronicDisease} name={"ChronicDisease"} />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <CustomButton variant="contained" onClick={newAddChronicDisease.handleSubmit} >Save</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})

const AddTest =memo(({modal, setModal, newAddTestsRequested, CustomButton})=>{
    return (
        <Modal show={modal.Tests} onHide={() => setModal((pre) => ({ ...pre, Tests: false }))} centered>
        <Modal.Body>
            <div className="text-center">
                <h4 className="modal-title mb-3">Add Test</h4>
            </div>
            <div className="modal-body  cus-i">
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label mb-0">Test<span className='Star_Mark'>*</span></label>
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                        value={newAddTestsRequested?.values?.Tests}
                        onChange={(e) => newAddTestsRequested?.setFieldValue("Tests", e.target.value)}
                    />
                    <Errors formikfun={newAddTestsRequested} name={"Tests"} />
                </div>
            </div>
            <div className="text-center mt-3">
                <CustomButton variant="contained" onClick={newAddTestsRequested.handleSubmit} >Save</CustomButton>
            </div>
        </Modal.Body>
    </Modal>
    )
})
const AddAllergy=memo(({modal, setModal, newAddallergies, CustomButton})=>{
    return (
        <Modal show={modal.Allergies} onHide={() => setModal((pre) => ({ ...pre, Allergies: false }))} centered>
            <Modal.Body>
                <div className="text-center">
                    <h4 className="modal-title mb-3">Add Allergy  </h4>
                </div>
                <div className="modal-body  cus-i">
                    <div className="mb-3">
                        <label htmlFor="exampleInputEmail1" className="form-label mb-0">Allergy<span className='Star_Mark'>*</span></label>
                        <input maxLength={50} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            value={newAddallergies?.values?.allergies}
                            onChange={(e) => newAddallergies?.setFieldValue("allergies", e.target.value)}
                        />
                        <Errors formikfun={newAddallergies} name={"allergies"} />
                    </div>
                </div>
                <div className="text-center mt-3">
                    <CustomButton variant="contained" onClick={newAddallergies.handleSubmit} >Save</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})

export { AddSymptoms, AddChronicDisease,AddTest,AddAllergy }
