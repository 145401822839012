import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import { basicDetailsValidation, businesscDetailsValidation } from '../../../Context/Validation';
import useReduxData from '../../../Hooks/useReduxData';
import { addUserData, updateUserImage, updateUserName } from "../../../Redux/ReducerFile/UserData";
import useUploadImage from '../../../Hooks/useUploadImage';
import { BUSINESS_CATEGORY, CLINIC_LIST, DELETE_ACCOUNT, GET_BASIC_DETAILS, GET_BUSINESS_DETAILS, QR_CODE, UPDATE_BASIC_DETAILS, UPDATE_BUSINESS_DETAILS, UPDATE_CLINIC, USER_SWITCH_PROFILE_LIST } from '../../Repository/ProfileRepo';
import { RoleTitle, commonMessages } from '../../../Context/constants';
import { TIMEZONE } from '../../../Utils/Utils';
const useProfile = () => {
    const [clinicList, setClinicList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [switchProfileList, setSwitchProfileList] = useState([])
    const [ChooseLocationList, setChooseLocationList] = useState([])
    const [chooseprofileData, setChooseprofileData] = useState(null)
    const [chooseLocationData, setChooseLocationData] = useState(null)
    const [imgUrl, setImgUrl] = useState(null);
    const [basicDetailsFrofileImage, setBasicDetailsFrofileImage] = useState(null)
    const [Delete, setDelete] = useState({});
    const [clinicDelete, SetclinicDetele] = useState(false)
    const [deleteAccount, setDeleteAccount] = useState(false)
    const [qrCodeData, setQrCodeData] = useState('')
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [selectedPage, setSelectedPage] = useState(1);
    const [selectedCount] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoadingClinicList, setIsLoadingClinicList] = useState(true)
    const [isProfile, setIsProfile] = useState(true)
    const [openModal, setOpenModal] = useState({
        user: false,
        clinic: false,
        data: null
    })
    const { reduxData: userdata, dispatch } = useReduxData("userDataRDX")
    const { UploadImage } = useUploadImage()
    const [tab, setTab] = useState(1)
    const FileRef = useRef()

    useEffect(() => {
        getListproviderbasic()
        getListproviderbusiness()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (clinicList.length === 0 && selectedPage === 1) {
            getListproviderclinics(selectedPage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPage]);
    const ChangeTab = (Type) => {
        switch (Type) {
            case "BasicDetails":
                setTab(1)
                break;
            case "BusinessDetails":
                setTab(2)
                getListproviderbusiness()
                getCategoryList()
                break;
            default: setTab(1)
        }
    }
    const handleScroll = (e) => {
        const target = e.target;
        if (target?.scrollHeight - target?.scrollTop === target?.clientHeight) {
            if (selectedPage !== totalPages) {
                setSelectedPage((prevPage) => {
                    const newPage = prevPage + 1;
                    setIsLoadingClinicList(true)
                    getListproviderclinics(newPage);
                    return newPage;
                });
            }
        }
    };
    //  user switch profile list
    const getUserSwitchProfileList = async () => {
        const payload = {
            roleTitle: userdata?.roleTitle,
            providerId: userdata?.userId,
        };
        setLoader(true)
        try {
            let response = await USER_SWITCH_PROFILE_LIST(payload)
            setSwitchProfileList(response.data)
            setLoader(false)
        } catch (error) {
            setSwitchProfileList([])
            toast.error(error.message)
            setLoader(false)
        }
    }
    // get choose location
    const getChooseLocation = async () => {
        const payload = {
            activeStatus: true,
            providerId: (chooseprofileData?.roleTitle === RoleTitle?.STAFF||chooseprofileData?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofileData?.providerId : chooseprofileData?.userId,
            staffId: (chooseprofileData?.roleTitle === RoleTitle?.STAFF||chooseprofileData?.roleTitle === RoleTitle?.FRONT_DESK) ? chooseprofileData.userId : undefined,
            roleTitle: chooseprofileData?.roleTitle
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setChooseLocationList(response.data)
        } catch (error) {
            setChooseLocationList([])
        } finally {
            setLoader(false)
        }
    }
    // get category  list 
    const getCategoryList = async () => {
        setLoader(true)
        const payload = {
            activeStatus: true,
        };
        try {
            let response = await BUSINESS_CATEGORY(payload)
            setCategoryList(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    // get provider basice details  list 
    const getListproviderbasic = async () => {
        setLoader(true)
        const payload = {
            rolesToList: [userdata?.roleTitle],
            getStaffList: false,
            providerId: userdata?.userId,
            activeStatus: true,
        };
        try {
            let response = await GET_BASIC_DETAILS(payload)
            formik.setValues(response?.data)
            setBasicDetailsFrofileImage(response.data?.image)
            dispatch(updateUserName({ firstName: response?.data?.firstName, lastName: response?.data?.lastName }))
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    //  update basice details 
    const formik = useFormik({
        initialValues: {
            title: "",
            mobile: "",
            email: "",
            firstName: "",
            lastName: ""
        },
        validationSchema: basicDetailsValidation,
        onSubmit: async (values) => {
            const { title, mobile, email, firstName, lastName } = { ...values }
            let payload = {
                title,
                mobile,
                email,
                firstName,
                lastName,
                userId: userdata.userId,
                authId: userdata?.authId,
                image: basicDetailsFrofileImage
            };
            setLoader(true)
            try {
                let response = await UPDATE_BASIC_DETAILS(payload)
                toast.success(response.message);
                getListproviderbasic()
                dispatch(updateUserName({ firstName: values.firstName, lastName: values.lastName }))
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        },
    });
    // get provider business details  list 
    const getListproviderbusiness = async () => {
        setLoader(true)
        let payload = { providerId: userdata.userId }
        try {
            let response = await GET_BUSINESS_DETAILS(payload)
            updateBusinessDetails.setValues(response.data)
            dispatch(updateUserImage({ img: response?.data?.image, businessName: response?.data?.businessName }))
            setImgUrl(response.data.image)
            setLoader(false)
        } catch (error) {
            toast.error(error.message)
            setLoader(false)
        }
    }
    //  update business details 
    const updateBusinessDetails = useFormik({
        initialValues: {
            businessName: "",
            businessCategoryId: "",
            about: "",
        },
        validationSchema: businesscDetailsValidation,
        onSubmit: async (values) => {
            let payload = {
                userId: userdata.userId,
                businessName: values.businessName,
                about: values.about,
                image: imgUrl,
                businessCategoryId: values.businessCategoryId,
                businessProfileId: userdata?.businessProfileId
            };
            setLoader(true)
            try {
                let response = await UPDATE_BUSINESS_DETAILS(payload)
                toast.success(response?.message);
                getListproviderbusiness()
                dispatch(updateUserImage({ img: imgUrl, businessName: values?.businessName }))
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        },
    });
    //  get provider clinic clist 
    const getListproviderclinics = async (newPage) => {
        const payload = {
            activeStatus: null,
            page: newPage,
            count: selectedCount,
            providerId: userdata?.userId,
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList((pre) => [...pre, ...response.data])
            let totalPages = Math.ceil(response.totalCount / selectedCount)
            setTotalPages(totalPages)
            setLoader(false)
        } catch (error) {
            setClinicList([])
            setLoader(false)
        }
    }
    // delete clinic
    const handleClose = async (event, ids) => {
        if (event === "YES") {
            setLoader(true)
            let payload = {
                clinicId: Delete.clinicId,
                providerId: userdata.userId,
                deletedStatus: !Delete?.deletedStatus
            }
            try {
                let response = await UPDATE_CLINIC(payload)
                toast.success(response.message)
                SetclinicDetele(false)
                setClinicList((pre) => {
                    let newClinicList = [...pre]
                    let indexClinic = newClinicList?.findIndex((cur) => cur?.clinicId === Delete.clinicId)
                    newClinicList.splice(indexClinic, 1)
                    return newClinicList
                })
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }

        } else {
            SetclinicDetele(false)
        }
    }
    const handleCloseAccount = async (event) => {
        if (event === "YES") {
            const payload = {
                providerId: userdata?.userId,
                filter: userdata?.roleTitle
            }
            setLoader(true)
            try {
                let response = await DELETE_ACCOUNT(payload)
                toast.success(response.message)
                setTimeout(() => {
                    dispatch(addUserData({}));
                    localStorage.clear();
                    navigate('/')
                }, 1500)
                setLoader(false)
                setDeleteAccount(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }

        } else {
            setDeleteAccount(false)
        }
    }
    const clinicQrCode = async () => {
        let payload = {
            providerId: userdata.userId
        }
        try {
            let response = await QR_CODE(payload)
            toast.success(response.message)
            setQrCodeData(response.data.qrCode)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setQrCodeData("")
            setLoader(false)
        }
    }
    const nextLocationTab = () => {
        if (chooseprofileData?.status === 412) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 206) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 1006) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 403) {
            return toast.error(chooseprofileData?.signupStatus);
        }
        if (chooseprofileData?.status === 500) {
            return toast.error(commonMessages.NetworkError);
        }
        setIsProfile(false)
        getChooseLocation()
    }
    const handleChooseLocation = (data) => {
        setChooseLocationData(data)
        setChooseprofileData((pre) => ({
            ...pre, location: {
                clinicId: data?.clinicId,
                clinicName: data?.clinicName

            }
        }))
    }
    const handleSwitchProfile = () => {
        if (!chooseLocationData) return
        if (!chooseprofileData.timezoneName) {
            chooseprofileData.timezoneName = TIMEZONE()
        }
        dispatch(addUserData(chooseprofileData))
        navigate("/dashboard")
    }
    const handleChooseProfile = (data) => {
        setChooseprofileData(data)
    }
    const handleClosedModal = (type) => {
        if (type === "user") {
            setChooseprofileData(null)
            setChooseLocationData(null)
            setIsProfile(true)
        }
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }
    const backToProfileList = () => {
        setIsProfile(true)
        setChooseLocationData(null)
        setChooseprofileData((pre) => {
            const { location, ...preData } = pre;
            return preData;
        });
    }
    const handleOpenModal = (type, data) => {
        if (type === "user") {
            getUserSwitchProfileList()
        }
        setOpenModal((prev) => ({
            ...prev,
            data,
            [type]: true,
        }));
    };
    useEffect(() => {
        if (tab===3) {
            clinicQrCode()

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])
    return {
        updateBusinessDetails,
        clinicDelete,
        clinicList,
        loader,
        userdata,
        formik,
        imgUrl,
        qrCodeData,
        deleteAccount,
        FileRef,
        tab,
        basicDetailsFrofileImage,
        categoryList,
        isLoadingClinicList,
        switchProfileList,
        openModal,
        chooseprofileData,
        chooseLocationData,
        ChooseLocationList,
        isProfile,
        nextLocationTab,
        backToProfileList,
        getChooseLocation,
        setIsProfile,
        handleChooseProfile,
        setChooseLocationData,
        handleClosedModal,
        handleOpenModal,
        UploadImage,
        clinicQrCode,
        navigate,
        handleScroll,
        setImgUrl,
        dispatch,
        setDelete,
        SetclinicDetele,
        handleClose,
        getListproviderbusiness,
        handleCloseAccount,
        setDeleteAccount,
        ChangeTab,
        setTab,
        setBasicDetailsFrofileImage,
        setLoader,
        handleChooseLocation,
        handleSwitchProfile,
    }
}

export default useProfile
