import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa'

const BillingHistory = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const splitPath = pathname.split("/")
    const ActiveTab = splitPath[splitPath.length - 1]
    return <>
        <div className="common-component-box">
            <header className="body_header">
                <div className="d-flex justify-content-start align-items-center">
                <FaArrowLeft className="me-2 cursor" onClick={() => navigate("/setting/my-plan")} />
                    <h2 className='page_name'>Plan History</h2>
                </div>
            </header>
            <div className="common-white-bg-box mx-0 shadow  position-relative pt-0 ">
                <div className="col-12 pb-2  position-sticky top-0 z-3 pt-3" style={{ borderBottom: "2px solid #ededed", background: "#fff" }}>
                    <ul className="nav  mb-0  pb-1 px-2 ">
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "plan-history" && 'active_teams_frofile'}`} type="button" onClick={() => navigate(`plan-history`)}>Plan History</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={`teams_frofile_stepper_btn ${ActiveTab === "payment-history" && "active_teams_frofile"}`} type="button" onClick={() => navigate(`payment-history`)} >Payment History</button>
                        </li>
                    </ul>
                </div>
                <div className='px-2 SETTING-OUTLET-HIEGHT mx-3 '>
                    <Outlet />
                </div>
            </div>
        </div>
    </>
}

export default BillingHistory