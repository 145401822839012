import React from 'react'
import { Modal } from 'react-bootstrap';
import { Avatar, Badge, Table, TableBody, TableCell, TableContainer, TableRow, styled } from '@mui/material';
import Loading from '../../../Component/Loading';
import { TeamImageFilePath, clinicPictures, userImageFilePath } from '../../../Context/api';
import useMuiCompontent from '../../../Hooks/useMuiCompontent';
import { MdOutlineCameraAlt, MdPhone } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import useTeamProfile from './Hooks/useTeamProfile';
import { SearchAbleSelectBox, SelectBox } from '../../../Component/Inputs';
import { BloodGroupOptions, GenderOptions_TEAM, LanguageOptions, RoleTitle, TitleOptions } from '../../../Context/constants';
import { Errors } from '../../../Component/Error';
import { motion } from 'framer-motion';
import moment from 'moment-timezone';
import DeleteProfile from '../../../Component/DeleteProfile';
import useTableSorting from '../../../Hooks/useTableSorting';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GrDocumentPdf } from 'react-icons/gr';
import PhoneNumber from '../../../Component/PhoneInput';
import { Peginationcomponent } from '../../../Component/Peginationcomponent';
import userImageDummy from "../../../Assets/Images/dummyimg.png"
import { FaArrowLeft, FaIdCard } from 'react-icons/fa';
const TeamProfile = () => {
    const {
        paginationTotalCount,
        controller,
        SpecializationList,
        headCells,
        headCellsServices,
        loader,
        imgUrl,
        showform,
        peresanalInfoFormik,
        ListState,
        contactInfoFormik,
        ListDataCountry,
        teamProof,
        StaffDetails,
        FileRef,
        alldegreeList,
        UserData,
        clinicList,
        deleteAccount,
        openModal,
        switchProfileList,
        chooseprofileData,
        chooseLocationData,
        ChooseLocationList,
        isProfile,
        nextLocationTab,
        backToProfileList,
        handleChooseLocation,
        handleSwitchProfile,
        handleChooseProfile,
        handleClosedModal,
        handleOpenModal,
        handleScroll,
        setDeleteAccount,
        handleCloseAccount,
        UploadImage,
        setController,
        handalDOB,
        setImgUrl,
        setShowForm,
        navigate,
        setLoader,
        handalSearchAbleSelectBox,
    } = useTeamProfile()
    const { CustomButton } = useMuiCompontent()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const { EnhancedTableHead: EnhancedTableHeadServices, order: orderServices, orderBy: orderByServices, stableSort: stableSortServices, getComparator: getComparatorServices, handleRequestSort: handleRequestSortServices } = useTableSorting(headCellsServices)
    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
        color: "#fff"
    }));
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className=" d-flex justify-content-start">
                        <h2 className='page_name'>Profile</h2>
                    </div>
                </header>
                <div className=" common-white-bg-box mx-0  px-4 pt-3 shadow  PROVIDER_PROFILE">
                    {/* <div className="row"> */}
                    <div className="row">
                        <div className="col-lg-10">
                            <div className="d-flex">
                                <div className="USER_PROFILE_IMAGE">
                                    <Avatar alt={UserData?.firstName?.toUpperCase()} sx={{ width: 150, height: 150 }} className='custom_avtar' src={TeamImageFilePath + UserData?.profileImage} />
                                </div>
                                <div className=" USER_INFO ">
                                    <h3>{`${UserData?.title} ${UserData?.firstName} ${UserData?.lastName}`}</h3>
                                    <p> <MdEmail /> {`${UserData?.email}`}</p>
                                    <p><span><MdPhone /></span> {`${UserData?.mobile}`}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="row px-2">
                                <CustomButton variant="contained" startIcon={<i className="fa-solid fa-repeat fs-6"></i>} style={{ whiteSpace: "nowrap", borderRadius: "25px", padding: "5px 12px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }} onClick={() => handleOpenModal("user")}  > switch profile</CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4 tab_scroll">
                        <ul className="nav  mb-0  pb-1 " style={{ width: "809px" }}>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${showform === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setShowForm(1)} >Locations</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn me-2 ${showform === 2 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setShowForm(2)} >Personal Information</button>
                            </li>

                            {
                                UserData.roleTitle !== RoleTitle.FRONT_DESK && <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${showform === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setShowForm(3)} >Education Qualification</button>
                                </li>
                            }
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${showform === 4 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setShowForm(4)} >Contact Information</button>
                            </li>
                            {
                                UserData.roleTitle !== RoleTitle.FRONT_DESK && <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${showform === 5 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setShowForm(5)} >Specialization</button>
                                </li>
                            }
                            <li className="nav-item" role="presentation">
                                <button className={`teams_frofile_stepper_btn ${showform === 6 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setShowForm(6)} >Delete Account</button>
                            </li>
                        </ul>
                        <ul className="nav  mb-0" >
                            {showform === 3 && <li>   <CustomButton variant="contained" startIcon={<IoMdAdd />} onClick={() => navigate('add-degree')}  >Add degree </CustomButton></li>}
                        </ul>
                    </div>
                    {
                        showform === 1 && <div className="col-12 BUSINESS_DETAILS mt-3">
                            <div className="row">
                                <div className="col-12  py-3 d-flex justify-content-between" style={{ borderBottom: "1.2px solid #ededed", borderTop: "1.2px solid #ededed" }}>
                                    <h4 className='mb-0' style={{ fontSize: "20px" }}>My Locations</h4>
                                </div>
                                <div className='CLINIC_LIST' onScroll={handleScroll} style={{ height: "40vh" }}>
                                    {
                                        clinicList?.length === 0 && <p className='text-center mt-5'>No clinic found</p>
                                    }
                                    {
                                        clinicList?.map((cur, index) => {
                                            return <div key={index} className="col-12 d-flex mt-3">
                                                <Avatar
                                                    alt={cur?.clinicName?.toUpperCase()}
                                                    sx={{ width: 40, height: 40 }}
                                                    src={clinicPictures + cur?.image}
                                                />
                                                <div className='ms-3 d-flex justify-content-between align-items-start ' style={{ borderBottom: "1.2px solid #ededed", width: "100%" }}>
                                                    <div className=''>
                                                        <h3>{cur.clinicName}</h3>
                                                        <ul className='d-lg-flex'>
                                                            <li className='me-3'><FaIdCard className='me-2' /> {cur?.clinicRegistrationNumber}</li>
                                                            <li><i className="fa-solid fa-location-dot me-2" style={{ color: `${cur?.activeStatus ? "#38C4B9" : cur?.activeStatus === false ? "#000" : ""}` }} ></i>{` ${cur?.address}, ${cur?.street}, ${cur?.city}, ${cur?.stateName},${cur?.countryName}, ${cur?.zip} `}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {/* <div className='text-center mt-1'>
                                            {
                                                isLoadingClinicList && <SpinnerCompontent />
                                            }

                                        </div> */}
                                </div>
                            </div>
                        </div>
                    }
                    {showform === 2 && <motion.div className=' d-flex justify-content-between  flex-column'
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                    >
                        <div className="col-12" >
                            <div className="row px-0">
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <SelectBox
                                                placeholder="Select Title"
                                                value={peresanalInfoFormik.values.title.trim()}
                                                name="title"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => peresanalInfoFormik.setFieldValue("title", e.target.value)}
                                                option={TitleOptions}
                                            />
                                            <Errors formikfun={peresanalInfoFormik} name={"title"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SelectBox
                                                        placeholder="Select Title"
                                                        value={peresanalInfoFormik.values.title.trim()}
                                                        name="title"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => peresanalInfoFormik.setFieldValue("title", e.target.value)}
                                                        option={TitleOptions}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"title"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0"> First Name<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <input maxLength={50} type="text" className="form-control" name='firstName' value={peresanalInfoFormik.values.firstName}
                                                onChange={(e) => peresanalInfoFormik.setFieldValue("firstName", e.target.value)} />
                                            <Errors formikfun={peresanalInfoFormik} name={"firstName"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0"> First Name<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input maxLength={50} type="text" className="form-control" name='firstName' value={peresanalInfoFormik.values.firstName}
                                                        onChange={(e) => peresanalInfoFormik.setFieldValue("firstName", e.target.value)} />
                                                    <Errors formikfun={peresanalInfoFormik} name={"firstName"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0"> Last Name<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <input maxLength={50} type="text" className="form-control" name='lastName' value={peresanalInfoFormik.values.lastName}
                                                onChange={(e) => peresanalInfoFormik.setFieldValue("lastName", e.target.value)} />
                                            <Errors formikfun={peresanalInfoFormik} name={"lastName"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0"> Last Name<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input maxLength={50} type="text" className="form-control" name='lastName' value={peresanalInfoFormik.values.lastName}
                                                        onChange={(e) => peresanalInfoFormik.setFieldValue("lastName", e.target.value)} />
                                                    <Errors formikfun={peresanalInfoFormik} name={"lastName"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <SelectBox
                                                placeholder="Select Gender"
                                                value={peresanalInfoFormik.values.gender}
                                                name="gender"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => peresanalInfoFormik.setFieldValue("gender", e.target.value)}
                                                option={GenderOptions_TEAM}
                                            />
                                            <Errors formikfun={peresanalInfoFormik} name={"gender"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SelectBox
                                                        placeholder="Select Gender"
                                                        value={peresanalInfoFormik.values.gender}
                                                        name="gender"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => peresanalInfoFormik.setFieldValue("gender", e.target.value)}
                                                        option={GenderOptions_TEAM}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"gender"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <input type="date" className="form-control position-relative" name='dob' max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} value={peresanalInfoFormik.values.dob ? moment(peresanalInfoFormik.values.dob).format("YYYY-MM-DD") : ""}
                                                onChange={handalDOB} />
                                            <Errors formikfun={peresanalInfoFormik} name={"dob"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Date of Birth<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="date" className="form-control position-relative" name='dob' max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]} value={peresanalInfoFormik.values.dob ? moment(peresanalInfoFormik.values.dob).format("YYYY-MM-DD") : ""}
                                                        onChange={handalDOB} />
                                                    <Errors formikfun={peresanalInfoFormik} name={"dob"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" className="form-control" disabled={true} name='age' value={peresanalInfoFormik.values.age}
                                            />
                                            <Errors formikfun={peresanalInfoFormik} name={"age"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Age<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="form-control" disabled={true} name='age' value={peresanalInfoFormik.values.age}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"age"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <SelectBox
                                                placeholder="Select Blood Group"
                                                value={peresanalInfoFormik.values.bloodGroup}
                                                name="bloodgroup"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => peresanalInfoFormik.setFieldValue("bloodGroup", e.target.value)}
                                                option={BloodGroupOptions}
                                            />
                                            <Errors formikfun={peresanalInfoFormik} name={"bloodGroup"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Blood Group<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SelectBox
                                                        placeholder="Select Blood Group"
                                                        value={peresanalInfoFormik.values.bloodGroup}
                                                        name="bloodgroup"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => peresanalInfoFormik.setFieldValue("bloodGroup", e.target.value)}
                                                        option={BloodGroupOptions}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"bloodGroup"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Language<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <SelectBox
                                                placeholder="Select Language"
                                                value={peresanalInfoFormik.values.language}
                                                name="language"
                                                keys="value"
                                                label="label"
                                                menuValeu="value"
                                                handleChange={(e) => peresanalInfoFormik.setFieldValue("language", e.target.value)}
                                                option={LanguageOptions}
                                            />
                                            <Errors formikfun={peresanalInfoFormik} name={"language"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Language<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SelectBox
                                                        placeholder="Select Language"
                                                        value={peresanalInfoFormik.values.language}
                                                        name="language"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => peresanalInfoFormik.setFieldValue("language", e.target.value)}
                                                        option={LanguageOptions}
                                                    />
                                                    <Errors formikfun={peresanalInfoFormik} name={"language"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                {
                                    UserData.roleTitle !== RoleTitle.FRONT_DESK && <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>

                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                        <label className="form-label mb-0">Experience (in year)<span className='Star_Mark'>*</span></label>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <input type="number" className="form-control"
                                                            name='experience' value={peresanalInfoFormik?.values?.experience}
                                                            onChange={(e) => peresanalInfoFormik.setFieldValue("experience", e.target.value)}
                                                            // onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                            onInput={(e) => {
                                                                let maxLength = e.target.value.includes('.') ? 3 : 2;
                                                                e.target.maxLength = maxLength;
                                                                if (e.target.value.length > maxLength) {
                                                                    e.target.value = e.target.value.slice(0, maxLength);
                                                                }
                                                            }}
                                                            max={new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0]}
                                                        />
                                                        <Errors formikfun={peresanalInfoFormik} name={"experience"} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Display Picture</label>
                                        </div>
                                        <div className="col-4 d-flex  align-items-start flex-column">
                                            <Badge
                                                style={{ background: "#fff" }}
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={
                                                    <SmallAvatar alt="Remy Sharp"  >
                                                        <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                        </MdOutlineCameraAlt>
                                                        <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                            onChange={(e) => UploadImage(e, FileRef, "TEAM", setLoader, setImgUrl)}
                                                        />
                                                    </SmallAvatar>
                                                }
                                            >
                                                <Avatar  sx={{ width: 80, height: 80 }} src={TeamImageFilePath + imgUrl} >
                                                </Avatar>
                                            </Badge>
                                            {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: StaffDetails?.userId }} isDeleted={true} />}
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Display Picture</label>
                                                </div>
                                                <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                                                    <Badge
                                                        style={{ background: "#fff" }}
                                                        overlap="circular"
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        badgeContent={
                                                            <SmallAvatar alt="Remy Sharp"  >
                                                                <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                                                </MdOutlineCameraAlt>
                                                                <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file"
                                                                    onChange={(e) => UploadImage(e, FileRef, "TEAM", setLoader, setImgUrl)}
                                                                />
                                                            </SmallAvatar>
                                                        }
                                                    >
                                                        <Avatar sx={{ width: 80, height: 80 }} src={TeamImageFilePath + imgUrl} >
                                                        </Avatar>
                                                    </Badge>
                                                    {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "TEAM", id: StaffDetails?.userId }} isDeleted={true} />}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end py-3" >
                            <CustomButton variant="contained" onClick={peresanalInfoFormik.handleSubmit} >Update</CustomButton>
                        </div>

                    </motion.div>
                    }
                    {showform === 3 &&
                        <motion.div className=' d-flex justify-content-between  NESTED-CONTAINER-HEIGHT flex-column py-3'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.7 }}>
                            <TableContainer >
                                {alldegreeList?.length === 0 &&
                                    <div className="col-sm-12 mt-3 text-center " >

                                        No record found
                                    </div>
                                }
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        alldegreeList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={alldegreeList?.length} />
                                    }
                                    <TableBody className="table_overflow_scroll" >

                                        {stableSort(alldegreeList, getComparator(order, orderBy)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${row?.id}`;
                                                return <React.Fragment key={row?.id}><TableRow id="hover_tr">
                                                    <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                                                        {row?.degree}
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left" >{row?.university}</TableCell>
                                                    <TableCell id={labelId} align="left">{row?.yearOfPassing}</TableCell>
                                                    <TableCell id={labelId} align="left">{row?.registrationNumber}</TableCell>
                                                    <TableCell id={labelId} align="left"><GrDocumentPdf onClick={() => window.open(teamProof + row?.proof)} style={{ fontSize: "25px", color: "red", cursor: "pointer" }} /></TableCell>
                                                    <TableCell id={labelId} align="right">
                                                        <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li onClick={() => navigate(`edit-degree/${row?.staffQualificationsId}`)}><button className="dropdown-item cursor" ><i className="fa-solid fa-pen-to-square all_icons_color me-2" />Edit Degree</button></li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </motion.div>
                    }
                    {showform === 4 && <motion.div className=' d-flex justify-content-between flex-column  '
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.7 }}
                    >
                        <div className="col-12">
                            <div className="row">
                                <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4 mobile_number_input_box">
                                            <div className="input_group_mobile mb-0">
                                                <PhoneNumber
                                                    width={false}
                                                    Require={false}
                                                    Name="mobile"
                                                    HandleChange={contactInfoFormik}
                                                    Value={contactInfoFormik.values.mobile}
                                                    disabledInput={true}
                                                />
                                            </div>
                                            <Errors formikfun={contactInfoFormik} name={"mobile"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Mobile Number<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="input_group_mobile mb-0">
                                                        <PhoneNumber
                                                            width={false}
                                                            Require={false}
                                                            Name="mobile"
                                                            HandleChange={contactInfoFormik}
                                                            Value={contactInfoFormik.values.mobile}
                                                            disabledInput={true}
                                                        />
                                                    </div>
                                                    <Errors formikfun={contactInfoFormik} name={"mobile"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4 ">
                                            <input disabled type="email" className="form-control" name='email' value={contactInfoFormik.values.email}
                                                onChange={(e) => contactInfoFormik.setFieldValue(`email`, e.target.value)} />
                                            <Errors formikfun={contactInfoFormik} name={"email"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Email Address<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input disabled type="email" className="form-control" name='email' value={contactInfoFormik.values.email}
                                                        onChange={(e) => contactInfoFormik.setFieldValue(`email`, e.target.value)} />
                                                    <Errors formikfun={contactInfoFormik} name={"email"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  pb-2 mt-3 " >
                                    {/* <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-4">
                                            <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={contactInfoFormik.values.street}
                                                onChange={(e) => contactInfoFormik.setFieldValue(`street`, e.target.value)}
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"street"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Address Details<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={contactInfoFormik.values.address}
                                                        onChange={(e) => contactInfoFormik.setFieldValue(`address`, e.target.value)}
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"address"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-2 " >
                                    {/* <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-4">
                                            <input type="text" placeholder="Address" maxLength={50} className="form-control" name="address" value={contactInfoFormik.values.address}
                                                onChange={(e) => contactInfoFormik.setFieldValue(`address`, e.target.value)}
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"address"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                </div>

                                                <div className="col-lg-7">
                                                    <input type="text" maxLength={50} className="form-control" name="street" placeholder="Street " value={contactInfoFormik.values.street}
                                                        onChange={(e) => contactInfoFormik.setFieldValue(`street`, e.target.value)}
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"street"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-2 " >
                                    {/* <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-4">
                                            <SearchAbleSelectBox
                                                placeholder="Select Country"
                                                multiple={false}
                                                option={ListDataCountry}
                                                getOptionLabel="countryName"
                                                isOptionEqualToValue="id"
                                                value={contactInfoFormik?.values?.country}
                                                name="country"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"country"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        placeholder="Select Country"
                                                        multiple={false}
                                                        option={ListDataCountry}
                                                        getOptionLabel="countryName"
                                                        isOptionEqualToValue="id"
                                                        value={contactInfoFormik?.values?.country}
                                                        name="country"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"country"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  pt-2 " >
                                    {/* <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-4">
                                            <SearchAbleSelectBox
                                                placeholder="Select State"
                                                multiple={false}
                                                option={ListState}
                                                getOptionLabel="stateName"
                                                isOptionEqualToValue="stateId"
                                                value={contactInfoFormik?.values?.state}
                                                name="state"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"state"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        placeholder="Select State"
                                                        multiple={false}
                                                        option={ListState}
                                                        getOptionLabel="stateName"
                                                        isOptionEqualToValue="stateId"
                                                        value={contactInfoFormik?.values?.state}
                                                        name="state"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"state"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-2 " >
                                    {/* <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-4">
                                            <input
                                                type="text"
                                                maxLength={50}
                                                className="form-control"
                                                name="city"
                                                placeholder="City"
                                                value={contactInfoFormik.values.city}
                                                onChange={(e) => contactInfoFormik.setFieldValue(`city`, e.target.value)}
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"city"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                </div>
                                                <div className="col-lg-7">
                                                    <input
                                                        type="text"
                                                        maxLength={50}
                                                        className="form-control"
                                                        name="city"
                                                        placeholder="City"
                                                        value={contactInfoFormik.values.city}
                                                        onChange={(e) => contactInfoFormik.setFieldValue(`city`, e.target.value)}
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"city"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-2 "  >
                                    {/* <div className="row">
                                        <div className="col-3">
                                        </div>
                                        <div className="col-4">
                                            <input type="text" className="form-control" name="zip"
                                                onChange={(e) => contactInfoFormik.setFieldValue(`zip`, e.target.value)} value={contactInfoFormik.values.zip}
                                                maxLength={10}
                                                placeholder="ZIP Code"
                                            />
                                            <Errors formikfun={contactInfoFormik} name={"zip"} />
                                        </div>
                                    </div> */}
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                </div>
                                                <div className="col-lg-7">
                                                    <input type="text" className="form-control" name="zip"
                                                        onChange={(e) => contactInfoFormik.setFieldValue(`zip`, e.target.value)} value={contactInfoFormik.values.zip}
                                                        maxLength={10}
                                                        placeholder="ZIP Code"
                                                    />
                                                    <Errors formikfun={contactInfoFormik} name={"zip"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end pb-3 " >
                            <CustomButton variant="contained" onClick={contactInfoFormik.handleSubmit} >update</CustomButton>
                        </div>
                    </motion.div>
                    }
                    {showform === 5 &&
                        <motion.div className=' d-flex justify-content-between  NESTED-CONTAINER-HEIGHT flex-column py-3'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.7 }}>
                            <TableContainer  >
                                {SpecializationList?.length === 0 &&
                                    <div className="col-sm-12 mt-3 text-center " >
                                        No record found
                                    </div>
                                }
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        SpecializationList?.length !== 0 && <EnhancedTableHeadServices order={orderServices} orderBy={orderByServices} onRequestSort={handleRequestSortServices} rowCount={SpecializationList?.length} />
                                    }
                                    <TableBody >


                                        {stableSortServices(SpecializationList, getComparatorServices(order, orderBy))?.map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow id="hover_tr"   >
                                                    <TableCell className="ps-3 py-3" id={labelId} scope="row" padding="none" >
                                                        {controller.rowsPerPage * (controller.page - 1) + index + 1}
                                                    </TableCell>
                                                    <TableCell className="ps-3" id={labelId} padding="none" >
                                                        {row?.specializationName}
                                                    </TableCell>
                                                    <TableCell className="ps-3" id={labelId} padding="none" >
                                                        <ul className='d-flex gap-1 flex-wrap ps-0'>
                                                            {row?.services?.map((cur, indexservice, currentArray) => {
                                                                return <li>
                                                                    {`${cur?.serviceName} ${currentArray?.length > indexservice + 1 ? "," : ""} `}
                                                                </li>
                                                            })}
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <nav className="w-100" aria-label="pagination">
                                {
                                    paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
                                }
                            </nav>
                        </motion.div>
                    }
                    {
                        showform === 6 && <div className="col-12 py-3">
                            <p style={{ fontSize: "13px", color: "gray" }}>Deleting your account will remove all of your information
                                from our database. This cannot be undone.</p>
                            <CustomButton onClick={() => setDeleteAccount(true)} variant="contained" className='px-3' style={{ borderRadius: "25px", background: "red" }}>delete account</CustomButton>
                        </div>
                    }
                    {/* </div> */}
                </div>
            </div>
            <Modal show={deleteAccount} onHide={() => setDeleteAccount(false)} centered>
                <Modal.Body className='px-0'>
                    <div className="modal-body text-center cus-i px-0">
                        <i className="fa-solid fa-trash-can all_icons_color mb-2" ></i>
                        <p className='mb-0'>Are you sure?</p>
                        <p>You want to Delete this account</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => handleCloseAccount("YES")} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => handleCloseAccount("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={openModal?.user} onHide={() => handleClosedModal("user")} centered>
                <Modal.Body className='px-0 pt-0'>

                    {
                        isProfile ? <div className="modal-body  cus-i px-0">
                            <div className='d-flex w-100 justify-content-center align-items-center' >
                                <i className="fa-solid me-2 fa-repeat fs-6  " style={{ color: '#3B3B47', fontSize: "17px" }}></i>
                                <h5 className='m-0 ' style={{ color: '#3B3B47', fontSize: "17px" }} >Switch Profile</h5>
                            </div>
                            <hr />
                            <div className="SWITCH_USERS CUSTOM_SCROLL_STYLE">
                                {
                                    switchProfileList?.map((cur, index) => {
                                        return <React.Fragment key={cur?.userId || index}>
                                            <div className={`USER ${chooseprofileData?.userId === cur?.userId ? "ACTIVE" : ""} cursor`} onClick={() => handleChooseProfile(cur)}>
                                                <div className="IMG shadow mb-2">
                                                    <img src={cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? (cur?.businessProfileImage ? userImageFilePath + cur?.businessProfileImage : userImageDummy) :
                                                        (cur?.profileImage ? TeamImageFilePath + cur?.profileImage : userImageDummy)} alt="" />
                                                </div>
                                                <p className='text-center mb-0 TEXT_ELLIPSIS' > {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? cur?.businessName : `${cur?.firstName} ${cur?.lastName}`}  </p>
                                                <p className='text-center mb-0 mt-0 TEXT_ELLIPSIS' >   {cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? `` : `(${cur?.businessName})`}  </p>
                                                <p className="text-center mb-0" >{cur?.roleTitle === RoleTitle?.SERVICE_PROVIDER ? "(Provider)" : cur?.roleTitle === RoleTitle?.STAFF ? "(Team)" : "(Front Desk)"}</p>
                                            </div>
                                        </React.Fragment>
                                    })
                                }
                            </div>
                            <div className='w-100 text-center pt-3'>
                                <CustomButton variant="contained" className='mt-3' disabled={chooseprofileData ? false : true} onClick={() => { nextLocationTab() }} style={{ borderRadius: "25px", padding: "7px 80px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }}  > Next</CustomButton>
                            </div>
                        </div> : <div className="modal-body  cus-i px-0">
                            <div className='d-flex w-100 justify-content-between align-items-center px-3' >
                                <FaArrowLeft className="me-2 cursor" onClick={() => backToProfileList()} />
                                <div className='d-flex w-100 justify-content-center align-items-center'>
                                    <i className="fa-solid fa-location-dot fs-6 me-2  " style={{ color: '#3B3B47', fontSize: "17px" }}></i>
                                    <h5 className='m-0 ' style={{ color: '#3B3B47', fontSize: "17px" }} >Select Location</h5>
                                </div>
                                <p style={{ color: "transparent" }}>Hidden..</p>
                            </div>
                            <hr />
                            <div className="USERS_LOCATION_LIST CUSTOM_SCROLL_STYLE px-4 pb-3">
                                {
                                    ChooseLocationList?.map((cur, index) => {
                                        return <div key={index} className={`  px-2 shadow LOCATION d-flex justify-content-start align-items-center mt-3 ${chooseLocationData?.clinicId === cur?.clinicId && "ACTIVE"} ${UserData?.location?.clinicId === cur?.clinicId ? "" : "cursor"}`} onClick={() => handleChooseLocation(cur)}>

                                            <div className='w-100 d-flex'>
                                                <Avatar
                                                    alt={cur?.clinicName?.toUpperCase()}
                                                    sx={{ width: 40, height: 40 }}
                                                    src={clinicPictures + cur?.image}
                                                />
                                                <div className='d-flex flex-column ps-2' style={{ width: 'calc(100% - 40px)' }}>
                                                    <div className='d-flex align-items-start justify-content-between  w-100'>
                                                        <h6 className='m-0 fs-6 me-3 '>{cur.clinicName} </h6>
                                                        <ul className='p-0 d-flex justify-content-start'>
                                                            <li className='me-2 TEXT_ELLIPSIS'><FaIdCard className='me-2 fs-6' />{cur?.clinicRegistrationNumber}</li>
                                                        </ul>
                                                    </div>
                                                    <div className=' d-flex justify-content-between align-items-start w-100 '>
                                                        <ul className='p-0 w-100'>
                                                            <li><i className="fa-solid fa-location-dot me-2 fs-6"></i>{`${cur?.address}, ${cur?.street}, ${cur.city}, ${cur.stateName}, ${cur.countryName}, ${cur.zip} `}</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                            <div className='w-100 text-center pt-3'>
                                <CustomButton variant="contained" className='mt-3' style={{ borderRadius: "25px", padding: "7px 80px", background: "linear-gradient(to right ,#FF9669, #FF6534)" }} onClick={handleSwitchProfile}  > Switch Profile</CustomButton>
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        </>
    )
}

export default TeamProfile
