import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../Component/Loading";
import useTableSorting from "../../../Hooks/useTableSorting";
import axios from 'axios';
import { ticketAddApi, ticketListAPi } from "../../../Context/api";
import useReduxData from "../../../Hooks/useReduxData";
import { RoleTitle, TicketFilterOptions, commonMessages } from "../../../Context/constants";
import { Buffer } from "buffer";
import { IconButton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { NoRecordFound } from "../../../Component/NoRecordFound";
import { Peginationcomponent } from "../../../Component/Peginationcomponent";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { useFormik } from "formik";
import { createTicketValidation } from "../../../Context/Validation";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";
import { SelectBox } from "../../../Component/Inputs";
import CreateTicket from "./Compentent/CreateTicket";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { projectId } from "../../../Context/envoirment";
const TicketList = () => {
    const [loader, setLoader] = useState(false)
    const [createTicket, setCreateTicket] = useState(false)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [attachmentFile, setAttachFile] = useState([])
    const [ListData, setListData] = useState([])
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const [disable, setdisable] = useState(true)
    const [action, setAction] = useState(false)
    const [filter, setFilter] = useState({
        list: "MYTICKETS",
        search: ""
    })
    const navigate = useNavigate()
    const headCells = [
        { id: "#", numeric: true, disablePadding: false, label: "#" },
        { id: "TicketId", numeric: false, disablePadding: true, label: "Ticket ID", showIcon: true },
        { id: "subject", numeric: true, disablePadding: false, label: "Subject", showIcon: true },
        { id: "createdAt", numeric: true, disablePadding: false, label: "Created On", showIcon: true },
        { id: "CreatedBy", numeric: true, disablePadding: false, label: "Created By", showIcon: false },
        { id: "Status", numeric: true, disablePadding: false, label: "Status", showIcon: false, showLeft: true },
    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    let encodeRequest = (input) => {
        let salt = "123";
        const buffer = Buffer.from(salt, "utf-8");
        const base64EncodedSalt = buffer.toString("base64");
        let encodePayload = Buffer.from(JSON.stringify(input), "utf-8").toString("base64");
        let encodeHash = Buffer.from("####", "utf-8").toString("base64");
        let delimiter = "@@@";
        let base64Payload = encodePayload + delimiter + encodeHash + delimiter + base64EncodedSalt;
        return base64Payload;
    };
    let decodeOutput = (result, delimiter = "@@@") => {
        const components = result.split(delimiter);
        return Buffer.from(components[0], "base64").toString("utf-8");
    };
    const list = () => {
        const data = {
            count: controller?.rowsPerPage,
            page: controller?.page,
            search: filter.search,
            project_id: projectId,
            reportedBy_id: userdata?.userId,
            tab: filter.list,
            parent_id: userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata.userId.toString() : userdata?.providerId.toString()
        };
        setLoader(true);
        let delimiter = "@@@"
        let newData = { input: encodeRequest(data) }
        axios.post(ticketListAPi(), newData).then((resp) => {
            if (resp.data.code === 200) {
                setLoader(false);
                setPaginationTotalCount(resp.data.totalCount);
                let rr = decodeOutput(resp.data.data, delimiter)
                setListData(JSON.parse(rr))
            } else {
                setListData([]);
                setLoader(false);
            }
        }).catch(() => {
            toast.error(commonMessages.NetworkError);
            setLoader(false);
        });

    };

    const handleFileChange = (event) => {
        const data = Array.from(event.target.files);
        const temp = [...attachmentFile]
        for (const file of data) {
            if (data.length > 5) return toast.warn("You can select only 5 items")
            if ((data.length + attachmentFile.length) > 5) return toast.warn("You can select only 5 items")
            temp.push({ path: URL.createObjectURL(file), file: file });
        }
        setAttachFile(temp)
    };
    const addTicket = useFormik({
        initialValues: {
            subject: "",
            description: "",
            priority: ""
        },
        validationSchema: createTicketValidation,
        onSubmit: (values) => {
            let date = moment().format();
            const formData = new FormData();
            formData.append("project_id", projectId);
            formData.append("reportDate", date);
            formData.append("source", "ONLINE");
            formData.append("subject", values.subject);
            formData.append("description", values.description);
            formData.append("priority", values.priority);
            formData.append("parent_id", userdata.roleTitle === RoleTitle.SERVICE_PROVIDER ? userdata.userId.toString() : userdata?.providerId.toString());
            formData.append("reportedBy", JSON.stringify({
                id: userdata?.userId,
                firstName: userdata?.name,
                lastName: "",
                email: userdata?.email,
            })
            );
            formData.append("type", "TICKET");
            let formDataObject = {};
            for (const [key, value] of formData.entries()) {
                formDataObject[key] = value;
            }
            const formDataObjectRR = JSON.parse(formDataObject.reportedBy);
            formDataObject.reportedBy = formDataObjectRR
            const newFormData = new FormData();
            attachmentFile.forEach(element => {
                newFormData.append("files", element.file)
            });
            newFormData.append("input", encodeRequest(formDataObject));
            setLoader(true);
            axios.post(ticketAddApi(), newFormData).then((resp) => {
                if (resp.data.code === 200) {
                    toast.success(resp.data.message);
                    addTicket.resetForm();
                    list();
                    setAttachFile([])
                    setCreateTicket(false);
                } else {
                    toast.error(resp.data.message);
                }
                setLoader(false);
            }).catch(() => {
                toast.error(commonMessages.NetworkError);
                setLoader(false);
            });
        },
    });
    const DeleteAttachmentFile = (index) => {
        setAttachFile((pre) => {
            let temp = [...pre]
            temp.splice(index, 1)
            return temp
        })
    }
    const handleChangeFilter = (e) => {
        let { name, value } = e.target
        setFilter((pre) => ({
            ...pre, [name]: value
        }))
        setdisable(false)
    }
    const search = () => {
        setController({ page: 1, rowsPerPage: 10 }, () => {
            list()
        });
    }
    const reset = () => {
        setdisable(true)
        setAction((pre) => !pre)
        setFilter({ list: "MYTICKETS", search: "" })
    }
    useEffect(() => {
        list()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, action])
    return <>
        {loader && <Loading />}
        <div className=" d-flex  justify-content-between flex-column pt-3" style={{ height: "100%" }}>
            <div>
                <header className="body_header">
                    <div className="d-flex justify-content-between align-items-center w-100 ">
                        <ul className="headerlist px-0">
                            <li className="phone_hide pe-2">
                                <input type="text" value={filter.search} name="search" onChange={handleChangeFilter} id="outlined-basic" placeholder="Search by ticket id/Subject" className="form-control filter_input" />
                            </li>
                            {
                                userdata.roleTitle === RoleTitle.SERVICE_PROVIDER && <li className="pe-2" style={{ width: 200 }}>
                                    <SelectBox
                                        placeholder="Select"
                                        value={filter.list}
                                        name="list"
                                        keys="value"
                                        label="label"
                                        menuValeu="value"
                                        handleChange={handleChangeFilter}
                                        option={TicketFilterOptions}
                                    />
                                </li>
                            }
                            <li className="phone_hide pe-2">
                                <CustomButton variant="contained" className={`ms-${userdata.roleTitle !== RoleTitle.SERVICE_PROVIDER && 3}`} disabled={disable} onClick={search} >search </CustomButton>
                            </li>
                            <li className="d-flex justify-content-center align-items-center pe-2" >
                                <IconButton onClick={reset} size="small" aria-label="delete" disabled={disable} style={{ color: "#000", cursor: "pointer" }}>
                                    <FiRefreshCcw />
                                </IconButton>
                            </li>
                            <li className="pe-2">
                                <CustomButton variant="contained" onClick={() => navigate("create-ticket")} >create ticket </CustomButton>
                            </li>
                        </ul>
                        <ul className="headerlist phone_hide">
                            <li>
                                <CustomButton variant="contained"
                                    style={{ background: "#f5f5f5", color: "black" }}
                                    startIcon={<ExitToAppIcon />}
                                >export</CustomButton>
                            </li>
                        </ul>
                    </div>
                </header>
                <TableContainer >
                    {ListData?.length === 0 && <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /> </div>}
                    <Table
                        id="table_mui"
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table">
                        {
                            ListData?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={ListData?.length} />
                        }
                        <TableBody className="table_overflow_scroll" >
                            {stableSort(ListData, getComparator(order, orderBy)).map(
                                (item, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={index}><TableRow id="hover_tr" onClick={() => navigate(item?._id)}  >
                                        <TableCell style={{ whiteSpace: "normal" }} className="ps-3" id={labelId} scope="row" padding="none" >
                                            {controller.rowsPerPage * (controller.page - 1) + index + 1}
                                        </TableCell>
                                        <TableCell id={labelId} style={{ whiteSpace: "normal" }} align="left">{item?.ticketId}</TableCell>
                                        <TableCell id={labelId} style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }} align="left">{item?.subject}</TableCell>
                                        <TableCell id={labelId} style={{ whiteSpace: "normal" }} align="left">{moment(item?.createdAt)?.tz(userdata?.timezoneName)?.format('ll')} </TableCell>
                                        <TableCell id={labelId} style={{ whiteSpace: "normal" }} align="left">{item?.createdby}</TableCell>
                                        <TableCell id={labelId} style={{ whiteSpace: "normal" }} align="right" className="cursor" >{item?.status} </TableCell>
                                    </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className="w-100">
                {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
            </div>
        </div>
        <CreateTicket createTicket={createTicket} setCreateTicket={setCreateTicket} setAttachFile={setAttachFile} addTicket={addTicket} attachmentFile={attachmentFile} DeleteAttachmentFile={DeleteAttachmentFile} CustomButton={CustomButton} handleFileChange={handleFileChange} />
    </>
};

export default TicketList;
