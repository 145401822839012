import React from 'react'
import { Modal } from 'react-bootstrap'
import { Errors } from '../../Component/Error'
import { Chip, IconButton } from '@mui/material'
import Loading from '../../Component/Loading'
import useLifeStyle from './Hooks/useLifeStyle'
import useMuiCompontent from '../../Hooks/useMuiCompontent'
import { NoRecordFound } from '../../Component/NoRecordFound'
import { Peginationcomponent } from '../../Component/Peginationcomponent'
import { IoMdAdd } from 'react-icons/io'
import { IoIosSearch } from "react-icons/io";
import { FiRefreshCcw } from 'react-icons/fi'
const LifeStyle = () => {
    const {
        deleteLifeStyleFun,
        setShowModal,
        setChooseLifestyle,
        setDeletedModal,
        ChooseLifestyle,
        showMOdal,
        listLifeStyle,
        loader,
        formik,
        deleteModal, controller, setController, paginationTotalCount, Filter, reset, handleChangeFilter, getListLifeStyle
    } = useLifeStyle()
    const { CustomButton } = useMuiCompontent()
    return (
        <>
            {/* <ToastContainer /> */}
            {loader && <Loading />}
            <div className=" py-2" style={{ height: "100%" }}>
                <div className='pb-2 ' style={{ borderBottom: "2px solid #ededed" }}>
                    <ul className="headerlist px-0">
                        <li className='me-2' style={{ width: "210px" }}> <input type="text" placeholder="Search" className="form-control filter_input" name='business_category' value={Filter?.business_category} onChange={handleChangeFilter} /> </li>
                        <li className='me-2'><CustomButton disabled={Filter?.disable_button} startIcon={<IoIosSearch />} variant="contained" onClick={getListLifeStyle}>search</CustomButton></li>
                        <li className="me-2" >
                            <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                <FiRefreshCcw />
                            </IconButton>
                        </li>
                        <li><CustomButton startIcon={<IoMdAdd />} variant="contained" onClick={() => setShowModal(true)}>add new</CustomButton></li>
                    </ul>
                </div>
                <div className="row" >
                    {listLifeStyle.length === 0 &&
                        <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /></div>
                    }
                    <div className="col-12 h-100 pt-3">
                        <div className="row h-100  d-flex justify-content-between ">
                            <div className="col-12 Life_style_scroll" style={{ height: "90%", overflow: "auto" }}>
                                {listLifeStyle.map((cur, index) => {
                                    return <Chip key={cur?.lifeStyleId || index} label={cur?.lifeStyleName}
                                        style={{ color: `#fff`, background: `#12BABA`, cursor: "pointer" }}
                                        className="mb-2 me-2"
                                        onClick={() => { formik.setFieldValue("name", cur?.lifeStyleName); setChooseLifestyle(cur); setShowModal(true) }}
                                        onDelete={() => {
                                            setChooseLifestyle(cur); setDeletedModal(true)
                                        }
                                        }
                                    />
                                })}
                            </div>
                            <div className="col-12" style={{ height: "10%" }}>
                                <nav className=" w-100  " aria-label="pagination">
                                    {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Add   */}
            <Modal show={showMOdal} onHide={() => { setShowModal(false); formik.resetForm(); setChooseLifestyle(null) }} centered>
                <Modal.Body>
                    <div className="row"></div>
                    <div className='pb-3' style={{ borderBottom: "2px solid #ededed" }}>
                        <p className="modal-title mb-0"> {ChooseLifestyle ? "Edit Life Style Habit" : "Add Life Style Habit"} </p>
                    </div>
                    <div className="modal-body  cus-i px-0">
                        <div className="row">
                            <div className="col-sm-12  py-4 ">
                                <div className="row">
                                    <div className="col-4">
                                        <label className="form-label mb-0">Life Style Habit<span className='Star_Mark'>*</span></label>
                                    </div>
                                    <div className="col-8">
                                        <input maxLength={50} type="text" className="form-control" id="exampleInputEmail1" value={formik?.values.name} onChange={(e) => formik.setFieldValue("name", e.target.value)} aria-describedby="emailHelp" />
                                        <Errors formikfun={formik} name={"name"} />
                                    </div>
                                </div>
                            </div>
                            <div className="text-end mt-3 col-12">
                                <CustomButton variant="outlined" type='button' className='me-3' onClick={() => { setShowModal(false); formik.resetForm() }} >cancel</CustomButton>
                                <CustomButton variant="contained" onClick={formik.handleSubmit} >Save</CustomButton>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* life styles habit  */}
            <Modal show={deleteModal} centered onHide={() => setDeletedModal(false)}>
                <Modal.Body className="px-0">
                    <div className="modal-body text-center cus-i">
                        <i className="fa-solid fa-trash-can mb-2 all_icons_color"></i>
                        <p className="mb-0">Are you sure?</p>
                        <p>You want to Delete this habit</p>
                        <CustomButton variant="contained" className="me-3" onClick={() => deleteLifeStyleFun("YES",)} > Yes</CustomButton>
                        <CustomButton variant="outlined" onClick={() => deleteLifeStyleFun("NO")} > No</CustomButton>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LifeStyle
