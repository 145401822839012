import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "BasicDetails",
    initialState: {},
    reducers: {
        AddBasicDetails(state, action) {
            return state = action.payload;
        },
    },
});
export const { AddBasicDetails } = reducer.actions;
export default reducer.reducer;
