import { resendInvitationEmailAPI, TeamLocationListApi, TeamLocationUpdateApi, TeamspecializationListwithServiceApi, TeamspecializationUpdatewithServiceApi, insertstaff, getPeresanalInfoDetails, updatePersonalInfo, getContactInfoDetails, updateContactInfo, getEducationList, insertEducation, updateStaffStatus, updatePhoneNumberApi, clinicWithservicesAPI, insertFrontdesk } from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"

export const INSERT_STAFF = async (body) => {
    try {
        let response = await axiosInstance.post(insertstaff(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_PERESANAL_INFO_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getPeresanalInfoDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_STAFF_STATUS = async (body) => {
    try {
        let response = await axiosInstance.post(updateStaffStatus(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_PERSONAL_INFO = async (body) => {
    try {
        let response = await axiosInstance.post(updatePersonalInfo(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const EDUCATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(getEducationList(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const INSERT_EDUCATION = async (body) => {
    try {
        let response = await axiosInstance.post(insertEducation(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_CONTACT_INFO_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getContactInfoDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const EDIT_CONTACT_INFO = async (body) => {
    try {
        let response = await axiosInstance.post(updateContactInfo(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const RESEND_INVITATION_EMAIL = async (body) => {
    try {
        let response = await axiosInstance.post(resendInvitationEmailAPI(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const STAFF_LOCATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(TeamLocationListApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_STAFF_LOCATION = async (body) => {
    try {
        let response = await axiosInstance.post(TeamLocationUpdateApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const SPECIALIZATION_LIST_WITH_SERVICES_STAFF = async (body) => {
    try {
        let response = await axiosInstance.post(TeamspecializationListwithServiceApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_SPECIALIZATION_WITH_SERVICES = async (body) => {
    try {
        let response = await axiosInstance.post(TeamspecializationUpdatewithServiceApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const UPDATE_STAFF_MOBILE_NUMBER = async (body) => {
    try {
        let response = await axiosInstance.post(updatePhoneNumberApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const CLINIC_WITH_SERVICES_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(clinicWithservicesAPI(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}


export const INSERT_FRONT_DESK = async (body) => {
    try {
        let response = await axiosInstance.post(insertFrontdesk(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}






