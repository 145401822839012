import {  ListNotificationApi, ReadNotificationApi} from "../../Context/api"
import { axiosInstance } from "../../Context/CraeteAxios"
import handleError from "../../Utils/handleError"



export const NOTIFICATION_LIST = async (body) => {
    try {
        let response = await axiosInstance.post(ListNotificationApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const READ_NOTIFICATION = async (body) => {
    try {
        let response = await axiosInstance.post(ReadNotificationApi(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

