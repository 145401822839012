import React, { useEffect, useState } from 'react'
import useTableSorting from '../../../Hooks/useTableSorting'
import { Peginationcomponent } from '../../../Component/Peginationcomponent';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { NoRecordFound } from '../../../Component/NoRecordFound';
import useReduxData from '../../../Hooks/useReduxData';
import { PLAN_HISTORY } from '../../Repository/SettingRepo';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import Loading from '../../../Component/Loading';

const PlanHistory = () => {
    const [History, setHistory] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [Loader, setLoading] = useState(false)
    const headCells = [
        { id: "planType", numeric: true, disablePadding: false, label: "Validity", showIcon: true },
        { id: "finalAmount", numeric: true, disablePadding: false, label: "Price", showIcon: true },
        { id: "startDate", numeric: true, disablePadding: false, label: "Start Date", showIcon: true },
        { id: "endDate", numeric: true, disablePadding: false, label: "End Date", showIcon: true },
        { id: "paymentMethod", numeric: true, disablePadding: false, label: "Payment Mode" },
        { id: "paymentStatus", numeric: true, disablePadding: false, label: "Status", showIcon: true },
    ];
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    async function getPlanHistoryList() {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const payload = {
            page,
            count: rowsPerPage,
            userId: userData?.userId,
            // activeStatus: true
        };
        try {
            let response = await PLAN_HISTORY(payload)
            setHistory(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No assigned subscription plan found") toast.error(error.message)
            setHistory([])
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getPlanHistoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {Loader && <Loading />}
            <div className='d-flex justify-content-between flex-column h-100'>
                <TableContainer   >
                    {History?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                    <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table" >
                        {
                            History?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={History?.length} />
                        }
                        <TableBody>
                            {stableSort(History, getComparator(order, orderBy)).map(
                                (row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return <React.Fragment key={row?.serviceId}>
                                        <TableRow id="hover_tr">
                                            <TableCell id={labelId} align="left" >{row?.planType}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.currencyImage}{row?.finalAmount}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.startDate ? moment(row?.startDate)?.tz(userData?.timezoneName)?.format('ll') : "-"}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.endDate ? moment(row?.endDate)?.tz(userData?.timezoneName)?.format('ll') : "-"}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentMethod}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentStatus}</TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div>
                    <nav aria-label="pagination">
                        {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
                    </nav>
                </div>
            </div>
        </>
    )
}

export default PlanHistory