import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { GET_PROVIDER_DASHBOARD_DETAILS, GET_STAFF_DASHBOARD_DETAILS } from "../../Repository/DashboardRepo"
import useReduxData from "../../../Hooks/useReduxData"
import useDisableBrowserBackButton from "../../../Hooks/useDisableBrowserBackButton"
import { RoleTitle } from "../../../Context/constants"
import { CLINIC_LIST } from "../../Repository/ProfileRepo"
import moment from "moment-timezone"

const useDashboard = () => {
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [clinicList, setClinicList] = useState([])
    const [dashboardData, setDashboardData] = useState({})
    const [loader, setLoader] = useState(false)
    const [filerModal, setFilerModal] = useState({
        overView: false,
        topService: false,
        toMember: false,
        data: null
    })

    const [filters, setFilters] = useState({
        overView: {
            dateRange: "",
            clinic:[{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }],
            
            isApply: true
        },
        topService: {
            dateRange: "",
            clinic: [{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }],
            isApply: true
        },
        toMember: {
            dateRange: "",
            clinic: [{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }],
            isApply: true
        }
    })


    useDisableBrowserBackButton()
    useEffect(() => {
        getDetails(filters)
        getClinicList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        document.title = "Dashboard"
        window.addEventListener("click", () => {
            setDateRangeDropdown(false)
        })
    }, [])
    const getClinicList = async () => {
        let payload = {
            activeStatus: true,
            providerId: (userdata?.roleTitle === RoleTitle?.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            staffId: (userdata?.roleTitle === RoleTitle?.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata.userId : undefined,
            roleTitle: userdata?.roleTitle
        }
        setLoader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setClinicList([])
        }
    }

    const getDetails = async (filter) => {
        const getDateRange = (dateRange) => {
            switch (dateRange) {
                case 'TODAY':
                    return {
                        startDate: moment().startOf('day'),
                        endDate: moment().endOf('day')
                    };
                case 'LAST7':
                    return {
                        startDate: moment().subtract(7, 'days').startOf('day'),
                        endDate: moment().endOf('day')
                    };
                case 'LAST30':
                    return {
                        startDate: moment().subtract(30, 'days').startOf('day'),
                        endDate: moment().endOf('day')
                    };
                default:
                    return {
                        startDate: null,
                        endDate: null
                    };
            }
        };

        let payload = {
            [(userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? "providerId" : "staffId"]:(userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK)?userdata?.providerId: userdata?.userId,
            appointmentCountClinicId: (filters?.overView?.clinic[0]?.clinicId === "ALL_CLINCI" || !filter?.overView?.isApply) ? null : filter?.overView?.clinic[0]?.clinicId,
            topServicesClinicId: (filter?.topService?.clinic[0]?.clinicId === "ALL_CLINCI" || !filter?.topService?.isApply) ? null : filter?.topService?.clinic[0]?.clinicId,
            topMembersClinicId: (filter?.toMember?.clinic[0]?.clinicId === "ALL_CLINCI" || !filter?.toMember?.isApply) ? null : filter?.toMember?.clinic[0]?.clinicId,
            topMembersStartDate: filter?.toMember?.isApply ? getDateRange(filter?.toMember?.dateRange).startDate : null,
            topMembersEndDate: filter?.toMember?.isApply ? getDateRange(filter?.toMember?.dateRange).endDate : null,
            topServicesStartDate: filter?.topService?.isApply ? getDateRange(filter?.topService?.dateRange).startDate : null,
            topServicesEndDate: filter?.topService?.isApply ? getDateRange(filter?.topService?.dateRange).endDate : null,
            appointmentCountStartDate: filter?.overView?.isApply ? getDateRange(filter?.overView?.dateRange).startDate : null,
            appointmentCountEndDate: filter?.overView?.isApply ? getDateRange(filter?.overView?.dateRange).endDate : null,
            frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined
        }

        setLoader(true)
        try {
            let response = (userdata?.roleTitle === RoleTitle?.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK)
                ? await GET_PROVIDER_DASHBOARD_DETAILS(payload)
                : await GET_STAFF_DASHBOARD_DETAILS(payload)
            setDashboardData(response.data)
        } catch (error) {
            setDashboardData(null)
            toast.error(error.message)
        } finally {
            setLoader(false)
        }
    }

    const apply = (type) => {
        setFilters((pre) => {
            let temp = { ...pre };
            temp[type].isApply = true;
            getDetails(temp);
            setFilerModal({
                overView: false,
                topService: false,
                toMember: false,
                data: null
            })
            return temp;
        });

    };

    const reset = (type) => {
        setFilters((pre) => {
            let temp = { ...pre };
            temp[type].isApply = true;
            temp[type].dateRange = "";
            temp[type].clinic = [{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }];
            getDetails(temp);
            setFilerModal({
                overView: false,
                topService: false,
                toMember: false,
                data: null
            })
            return temp;
        });

    }
    const handleClickFilterIcon = (item, data) => {
        setFilerModal((pre) => ({ ...pre, [item]: pre?.[item] ? false : true, ...data }))
    }
    return {
        dashboardData,
        loader,
        dateRangeDropdown,
        clinicList,
        filerModal,
        filters,
        userdata,
        setFilters,
        setFilerModal,
        setDateRangeDropdown,
        getDetails,
        reset,
        apply,
        handleClickFilterIcon

    }
}

export default useDashboard
