import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import useDisableBrowserBackButton from '../Hooks/useDisableBrowserBackButton'
const Public = () => {
    useDisableBrowserBackButton()
    useEffect(() => {
        document.title = "Winkdoc"
    }, [])
    return (
        <>
            <Outlet />
        </>
    )
}

export default Public
