import React, { memo } from 'react'
import { dailogAllMessage } from '../../../Context/constants'
import { Modal } from 'react-bootstrap'
import { BiArchiveIn } from 'react-icons/bi'
import { Errors } from '../../../Component/Error'

const ArchiveModal = memo(({ showArchiv, setShowArchiv, archivedata, archiveUnarchive, CustomButton }) => {
  return (
    <Modal show={showArchiv} onHide={() => setShowArchiv(false)} centered>
      <Modal.Body>
        <div className="modal-body text-center">
          <BiArchiveIn className="all_react_icons_color " />
          <p className="mb-0">Are you sure? </p>
          <p>{archivedata.activeStatus === true ? dailogAllMessage.Teams.Archive : dailogAllMessage.Teams.UnArchive}</p>
          <CustomButton variant="contained" className="me-3" onClick={() => archiveUnarchive("YES", archivedata)} > Yes</CustomButton>
          <CustomButton variant="outlined" onClick={() => archiveUnarchive("NO")} > No</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  )
})
const ResendEmailModal = memo(({ resendInvitation, setResendInvitation, resendInvitationFun, CustomButton, setChangeEmail, changeEmail }) => {
  return (
    <Modal show={resendInvitation} onHide={() => { setResendInvitation(false); resendInvitationFun.resetForm(); setChangeEmail(false) }} centered>
      <Modal.Body>
        <div className="text-center">
          <h5 className="modal-title mb-0">Resend Invitation</h5>
        </div>
        <div className="modal-body  cus-i">
          <div className="col-sm-12 mb-3">
            <label className="form-label mb-0"> {changeEmail ? "Old Email" : "Email"} <span className='Star_Mark'>*</span></label>
            <input disabled type="email" className="form-control" name='oldEmail' value={resendInvitationFun.values.oldEmail}
              onChange={(e) => resendInvitationFun.setFieldValue(`oldEmail`, e.target.value)} />
          </div>
          <Errors formikfun={resendInvitationFun} name={"oldEmail"} />
          <div className="d-flex justify-content-end">
            <CustomButton variant="contained" style={{ textTransform: "capitalize" }} onClick={() => setChangeEmail((pre) => !pre)} > {!changeEmail ? "Change email" : "Dismiss"} </CustomButton>
          </div>
          {
            changeEmail && <div className="col-sm-12 mb-3">
              <label className="form-label mb-0">New Email<span className='Star_Mark'>*</span></label>
              <input maxLength={50} type="email" className="form-control" name='newEmail' value={resendInvitationFun.values.newEmail}
                onChange={(e) => resendInvitationFun.setFieldValue(`newEmail`, e.target.value)} />
              <Errors formikfun={resendInvitationFun} name={"newEmail"} />
            </div>
          }
        </div>
        <div className="text-center mt-3">
          <CustomButton variant="contained" onClick={resendInvitationFun.handleSubmit} >submit</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  )
})

export { ArchiveModal, ResendEmailModal }
