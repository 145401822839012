import React, { memo, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import { BiArchiveIn } from "react-icons/bi";
import { dailogAllMessage } from '../../../Context/constants';
import { Errors } from '../../../Component/Error';
import { Avatar, Badge, styled } from '@mui/material';
import { MdOutlineCameraAlt } from 'react-icons/md';
import DeleteProfile from '../../../Component/DeleteProfile';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
  color: "#fff"
}));
const ArchiveModal = memo(({ showArchiv, setShowArchiv, CustomButton, archivedata, archiveUnarchive }) => {
  return (
    <Modal show={showArchiv} onHide={() => setShowArchiv(false)} centered>
      <Modal.Body>
        <div className="modal-body text-center">
          <BiArchiveIn className="all_react_icons_color " />
          <p className="mb-0">Are you sure?</p>
          <p>{archivedata?.archive === true ? dailogAllMessage.Specialization.Archive : dailogAllMessage.Specialization.UnArchive}</p>
          <CustomButton variant="contained" className="me-3" onClick={() => archiveUnarchive("YES", archivedata)} > Yes</CustomButton>
          <CustomButton variant="outlined" onClick={() => archiveUnarchive("NO")} > No</CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  )
})

const AddSpecializationModal = memo(({ setLoader, showAdd, setShowAdd, addSpecialization, imgUrl, UploadImage, setImgUrl, SpecializationImageFilePath, CustomButton }) => {
  const FileRef = useRef()
  return (
    <Modal show={showAdd} centered>
      <Modal.Body>
        <div className="modal-body adddetail">
          <div className="">
            <div className="row">
              <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                <h4 className="modal-title mb-3 page_name">Add Specialization</h4>
              </div>
              <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                {/* <div className="row">
                  <div className="col-3">
                    <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-9">
                    <input maxLength={50} type="text" className="form-control" name="specializationName" value={addSpecialization.values.specializationName}
                      onChange={(e) => addSpecialization.setFieldValue("specializationName", e.target.value)}
                    />
                    <Errors formikfun={addSpecialization} name={"specializationName"} />
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-5">
                        <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                      </div>
                      <div className="col-lg-7">
                        <input maxLength={50} type="text" className="form-control" name="specializationName" value={addSpecialization.values.specializationName}
                          onChange={(e) => addSpecialization.setFieldValue("specializationName", e.target.value)}
                        />
                        <Errors formikfun={addSpecialization} name={"specializationName"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12  pt-3 pb-2 " style={{ borderBottom: "2px solid #ededed" }}>
                {/* <div className="row">
                  <div className="col-4">
                    <label className="form-label mb-0">Display Picture</label>
                  </div>
                  <div className="col-8 d-flex  align-items-start flex-column">
                    <Badge
                      style={{ background: "#fff" }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp"  >
                          <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                          </MdOutlineCameraAlt>
                          <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "SPECIALIZATION", setLoader, setImgUrl)} />
                        </SmallAvatar>
                      }
                    >
                      <Avatar  sx={{ width: 80, height: 80 }} src={SpecializationImageFilePath + imgUrl} >
                      </Avatar>
                    </Badge>
                    {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SPECIALIZATION", id: null }} isDeleted={true} />}
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-lg-5">
                        <label className="form-label mb-0">Display Picture</label>
                      </div>
                      <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                        <Badge
                          style={{ background: "#fff" }}
                          overlap="circular"
                          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          badgeContent={
                            <SmallAvatar alt="Remy Sharp"  >
                              <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                              </MdOutlineCameraAlt>
                              <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "SPECIALIZATION", setLoader, setImgUrl, imgUrl)} />
                            </SmallAvatar>
                          }
                        >
                          <Avatar sx={{ width: 80, height: 80 }} src={SpecializationImageFilePath + imgUrl} >
                          </Avatar>
                        </Badge>
                        {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SPECIALIZATION", id: null }} isDeleted={true} />}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="row modalform">
          </div>
          <div className="col-sm-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end mt-3">
            <div className='d-flex'>
              <CustomButton variant="outlined" className="me-3" onClick={() => {
                addSpecialization.resetForm();
                setShowAdd(false);
              }} > cancel</CustomButton>
              <CustomButton variant="contained" onClick={addSpecialization.handleSubmit} > Save</CustomButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})

const EditSpecializationModal = memo(({getspecializationList, setLoader, showEdit, setShowEdit, imgUrl, UploadImage, SpecializationImageFilePath, setImgUrl, updateSpecialization, CustomButton }) => {
  const FileRef = useRef()
  return (
    <Modal show={showEdit} centered>
      <Modal.Body>
        <div className="modal-body adddetail">
          <div className="">
            <div className="row">
              <div className="col-12" style={{ borderBottom: "2px solid #ededed" }}>
                <h4 className="modal-title mb-3">Edit Specialization</h4>
              </div>
            </div>
            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
              {/* <div className="row">
                <div className="col-3">
                  <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-9">
                  <input type="text" maxLength={50} className="form-control" name="specializationName" value={updateSpecialization.values.specializationName}
                    onChange={(e) => updateSpecialization.setFieldValue("specializationName", e.target.value)}
                  />
                  <Errors formikfun={updateSpecialization} name={"specializationName"} />
                </div>
              </div> */}
              <div className="row">
                <div className="col-lg-3">
                  <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                </div>
                <div className="col-lg-9">
                  <input type="text" maxLength={50} className="form-control" name="specializationName" value={updateSpecialization.values.specializationName}
                    onChange={(e) => updateSpecialization.setFieldValue("specializationName", e.target.value)}
                  />
                  <Errors formikfun={updateSpecialization} name={"specializationName"} />
                </div>
              </div>
            </div>
            <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
              <div className="row">
                <div className="col-4">
                  <label className="form-label mb-0">Display Picture</label>
                </div>
                <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                  <Badge
                    style={{ background: "#fff" }}
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp"  >
                        <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                        </MdOutlineCameraAlt>
                        <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "SPECIALIZATION", setLoader, setImgUrl, imgUrl, updateSpecialization?.values?.specializationId)} />
                      </SmallAvatar>
                    }
                  >
                    <Avatar sx={{ width: 80, height: 80 }} src={SpecializationImageFilePath + imgUrl} >
                    </Avatar>
                  </Badge>
                  {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SPECIALIZATION", id: updateSpecialization?.values?.specializationId }} isDeleted={true} />}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end mt-3">
            <div className="d-flex">
              <CustomButton variant="outlined" className="me-3" onClick={() => {
                setShowEdit(false);
                getspecializationList()
              }} > cancel</CustomButton>
              <CustomButton variant="contained" onClick={updateSpecialization.handleSubmit} > Save</CustomButton>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
export { ArchiveModal, AddSpecializationModal, EditSpecializationModal }
