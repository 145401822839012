import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useReduxData from "../../../Hooks/useReduxData";
import { AppointmentStatus, RoleTitle } from "../../../Context/constants";
import { useReactToPrint } from 'react-to-print';
import { useFormik } from "formik";
import { cancelApptValidation } from "../../../Context/Validation";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { CHANGE_APPOINTMENT_STATUS, DELETE_PATIENT_TO_GROUP_APPOINTMENT, GET_APPOINTMENT_DETAILS, GET_CHECKOUT_SLIP_DETAILS, INSERT_PATIENT_TO_GROUP_APPOINTMENT, PATIENT_LIST_TO_GROUP_APPOINTMENT, TIME_SLOT_LIST } from "../../Repository/AppointmentsRepo";
import { useNavigate, useParams } from "react-router-dom";
import { GET_EPRESCRIPTION_DETAILS, PATIENT_LIST } from "../../Repository/PatientRepo";
const useAppointmentDetails = () => {
    const [value, setValue] = React.useState('1');
    const [tap, setTap] = useState(1)
    const [modalReschdule, setModalReschdule] = useState(false);
    const [modalCancel, setModalCancel] = useState(false);
    const [patientDetails, setPatientDetails] = useState([])
    const [allpatientList, setAllPatientList] = useState([])
    const [apptDetails, setApptDetails] = useState({})
    const [isMaxPatients, setIsMaxPatients] = useState(null)
    const [AddPatientValue, setAddPatientValue] = useState("")
    const [deletePatientmodal, setDeletePatientmodal] = useState(false)
    const [deletePatientdata, setDeletePatientdata] = useState({})
    const [loader, setLoader] = useState(false)
    const [addpatientModal, setAddpatientModal] = useState(false)
    const [checkoutSlipData, setcheckoutSlipData] = useState({})
    const [ePrescriptionList, setEPrescriptionList] = useState([])
    const [TimeslotList, setTimeSlotList] = useState([])
    const [SlotLodaer, setSlotLodaer] = useState(false)
    const [isActiveSlot, setIsactiveSlot] = useState(null)
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const { apptId } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        apptView()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const apptView = async () => {
        let payload = {
            appointmentId: apptId,
        }
        setLoader(true)
        try {
            let response = await GET_APPOINTMENT_DETAILS(payload)
            setApptDetails(response?.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setApptDetails({})
            setLoader(false)
        }
    }
    // appoinment cancel
    const cancelAppointment = useFormik({
        initialValues: {
            reason: ''
        },
        validationSchema: cancelApptValidation,
        onSubmit: async (values) => {
            setLoader(true)
            const payload = {
                changeType: AppointmentStatus.CANCELLED,
                cancellationReason: values.reason,
                roleTitle: userdata.roleTitle,
                appointmentId: apptDetails?.appointmentId,
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
            }
            try {
                let response = await CHANGE_APPOINTMENT_STATUS(payload)
                cancelAppointment.resetForm()
                toast.success(response.message)
                setModalCancel(false)
                apptView()
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    const appointmentReschedule = useFormik({
        initialValues: {
            serviceId: '',
            clinicId: '',
            teamMemberId: '',
            apptDate: '',
            apptTimeIn: '',
            apptTimeOut: '',
            visitType: '',
        },
        validationSchema: '',
        onSubmit: async (values) => {
            if (!values.apptTimeIn) {
                return toast.warn(`Please select time slot`)
            }
            setLoader(true)
            const data = {
                providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
                staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
                frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,

                startDate: values.apptTimeIn,
                endDate: values.apptTimeOut,
                appointmentId: apptDetails.appointmentId,
                changeType: AppointmentStatus?.RESCHEDULED,
                roleTitle: userdata.roleTitle,
            }
            setLoader(true)
            try {
                let response = await CHANGE_APPOINTMENT_STATUS(data)
                toast.success(response.message)
                setModalReschdule(false)
                appointmentReschedule.resetForm()
                setTimeSlotList([])
                apptView()
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }

    })
    const reschedulefun = () => {
        setModalReschdule(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus?.RESCHEDULED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT ? false : true)
        if ((apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus?.RESCHEDULED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) === false) {
            appointmentReschedule?.setFieldValue("clinicId", apptDetails.clinicId)
            appointmentReschedule?.setFieldValue("serviceId", apptDetails.serviceId)
            appointmentReschedule?.setFieldValue("teamMemberId", apptDetails.teamMemberId)
            appointmentReschedule?.setFieldValue("apptDate", apptDetails.startDate)
            // appointmentReschedule?.setFieldValue("apptTimeIn", apptDetails.startDate)
            // appointmentReschedule?.setFieldValue("apptTimeOut", apptDetails.endDate)
            appointmentReschedule?.setFieldValue("visitType", apptDetails.visitType)
        }
    }
    // slot list
    const getSlotfun = async (date, teamMember, clinic, serviceId) => {
        if (clinic && serviceId && (teamMember || userdata.userId) && date) {
            let payload = {
                staffId: userdata?.roleTitle === RoleTitle.STAFF ? userdata.userId : teamMember,
                serviceId: serviceId,
                date: moment(date).startOf('day'),
                // date: moment(date).startOf("day").utc(),
                timezoneName: userdata?.timezoneName,
                day: moment(date).format('dddd'),
                clinicId: clinic,
            }
            setSlotLodaer(true)
            setTimeSlotList([])
            try {
                let response = await TIME_SLOT_LIST(payload)
                setTimeSlotList(response.data)
                setSlotLodaer(false)
            } catch (error) {
                setTimeSlotList([])
                setSlotLodaer(false)
            }
        }
    }
    useEffect(() => {
        if (appointmentReschedule.values.apptDate && modalReschdule) {
            getSlotfun(appointmentReschedule.values.apptDate, appointmentReschedule.values.teamMemberId, appointmentReschedule.values.clinicId, appointmentReschedule.values.serviceId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentReschedule.values.apptDate, modalReschdule])
    // list patients
    const getlistPatient = async () => {
        let payload = {
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,


            patientFilter: "ALL",
            roleTitle: userdata?.roleTitle,
        }
        setLoader(true)
        try {
            let response = await PATIENT_LIST(payload)
            setAllPatientList(response.data)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            setAllPatientList([])
        }
    }
    // get patient details
    const getpatientDetailsList = async () => {
        setLoader(true)
        let payload = {
            appointmentNumber: apptDetails?.appointmentNumber
        }
        try {
            let response = await PATIENT_LIST_TO_GROUP_APPOINTMENT(payload)
            setLoader(false)
            setPatientDetails(response?.data)
            setIsMaxPatients(response?.isMaxPatients)
        } catch (error) {
            setIsMaxPatients([])
            setPatientDetails([])
            setLoader(false)
        }
    }
    // add patient
    const addpatientfun = async (id) => {
        setLoader(true)
        let payload = {
            staffId: apptDetails?.teamMemberId,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
            patientId: id,
            roleTitle: userdata.roleTitle,
            appointmentNumber: apptDetails?.appointmentNumber,
            appointmentId: apptDetails?.appointmentId
        }
        try {
            let response = await INSERT_PATIENT_TO_GROUP_APPOINTMENT(payload)
            setLoader(false)
            getpatientDetailsList()
            toast.success(response.message)
            setAddpatientModal(false)
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
        }

    }
    // delete patinet
    const deletePatientfun = async (event, data) => {
        if (event === "YES") {
            let payload = {
                appointmentNumber: data?.appointmentNumber,
                patientId: data?.patientId
            }
            setLoader(true)
            try {
                let response = await DELETE_PATIENT_TO_GROUP_APPOINTMENT(payload)
                toast.success(response.message)
                getpatientDetailsList()
                setLoader(false)
                setDeletePatientmodal(false)
            } catch (error) {
                setLoader(false)
                toast.error(error.message)
            }
        } else {
            setDeletePatientmodal(false)
        }
    }
    // check out data
    const ePrescription = async (eId) => {
        const payload = {
            prescriptionNumber: apptDetails?.prescriptionNumber
        }
        try {
            let response = await GET_EPRESCRIPTION_DETAILS(payload)
            setEPrescriptionList(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setEPrescriptionList([])
            setLoader(false)
        }
    }
    const getListSelectedMedicines = async () => {
        setLoader(true)
        let payload = {
            patientId: apptDetails?.patientId,
            providerId: (userdata.roleTitle === RoleTitle.STAFF||userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
            appointmentId: apptDetails?.appointmentId,
            roleTitle: userdata?.roleTitle
        }

        try {
            let response = await GET_CHECKOUT_SLIP_DETAILS(payload)
            setcheckoutSlipData(response.data)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setcheckoutSlipData({})
        }

    }
    useEffect(() => {
        if (tap === 2) {
            getpatientDetailsList()
        }
        if (tap === 3 && apptDetails?.prescriptionNumber) {
            ePrescription()
        }
        if (tap === 4) {
            getListSelectedMedicines()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tap])
    const clossOffcanvas = (type) => {
        if (type === "RESCHEDULE_APPOINTMENT") {
            setModalReschdule(false)
            setTimeSlotList([])
        }
        if (type === "CANCEL_APPOINTMENT") {
            setModalCancel(false)
        }
    }
    return {
        setValue,
        ePrescription,
        reschedulefun,
        setModalCancel,
        handlePrint,
        getListSelectedMedicines,
        setAddpatientModal,
        deletePatientfun,
        setAddPatientValue,
        setDeletePatientdata,
        setModalReschdule,
        setDeletePatientmodal,
        addpatientfun,
        setIsactiveSlot,
        setTimeSlotList,
        setTap,
        clossOffcanvas,
        getlistPatient,
        navigate,
        CustomButton,
        tap,
        loader,
        value,
        apptDetails,
        userdata,
        componentRef,
        checkoutSlipData,
        isMaxPatients,
        ePrescriptionList,
        deletePatientmodal,
        addpatientModal,
        allpatientList,
        patientDetails,
        deletePatientdata,
        AddPatientValue,
        modalCancel,
        cancelAppointment,
        modalReschdule,
        appointmentReschedule,
        TimeslotList,
        SlotLodaer,
        isActiveSlot,
    }
}

export default useAppointmentDetails
