import TimePicker from 'rc-time-picker';
import React, { memo } from 'react'
import { Modal } from 'react-bootstrap';
import { SearchAbleSelectBox } from '../../../Component/Inputs';
import { Errors } from '../../../Component/Error';
import { IoMdAddCircleOutline, IoMdCloseCircleOutline } from 'react-icons/io';
const SetUnAvailabilityModal = memo(({ SetUnavailabilityAdd, UnavailabilityAdd, addUnavailabililty, unAvailabilityDefault, moment, handalSearchAbleSelectBox, clinicList, listTimeZone,
    date, addtimesUnavailability, removeSelection, CustomButton, removetimeUnavailability
}) => {
    return (
        <Modal show={UnavailabilityAdd} onHide={() => SetUnavailabilityAdd(false)} centered backdrop="true || false || static">
            <Modal.Body>
                <div className="modal-body adddetail">
                    <div className="text-center">
                        <h4 className="modal-title mb-3 page_name">Set Unavailability</h4>
                    </div>
                    <div className="row modalform">
                        <div className="col-sm-6 mb-3">
                            <label className="form-label mb-0">Select Clinic<span className='Star_Mark'>*</span></label>
                            <SearchAbleSelectBox
                                multiple={false}
                                option={clinicList}
                                getOptionLabel="clinicName"
                                isOptionEqualToValue="clinicId"
                                value={addUnavailabililty.values.clinicId}
                                name="clinicId"
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                            <Errors formikfun={addUnavailabililty} name={"clinicId"} />

                        </div>
                        <div className="col-sm-6 mb-3">
                            <label className="form-label mb-0">Timezone<span className='Star_Mark'>*</span></label>
                            <SearchAbleSelectBox
                                multiple={false}
                                option={listTimeZone}
                                getOptionLabel="text"
                                isOptionEqualToValue="id"
                                value={addUnavailabililty.values.timeZone}
                                name="timeZone"
                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                            />
                            <Errors formikfun={addUnavailabililty} name={"timeZone"} />
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row mb-0 d-flex justify-content-between">
                                <label className="form-label mb-0">Date Range<span className='Star_Mark'>*</span></label>
                                <div className="col-sm-6 ">
                                    <input type="date" min={date} className="form-control position-relative" name="fromDate" id="datefield" value={addUnavailabililty.values.fromDate ? moment(addUnavailabililty.values.fromDate).format("YYYY-MM-DD") : ""}
                                        onChange={(e) => addUnavailabililty.setFieldValue("fromDate", e.target.value)} />
                                    <Errors formikfun={addUnavailabililty} name={"fromDate"} />
                                </div>
                                <div className="col-sm-6">
                                    <input type="date" min={date} className="form-control position-relative" name="toDate" id="datefield" value={addUnavailabililty.values.toDate ? moment(addUnavailabililty.values.toDate).format("YYYY-MM-DD") : ""}
                                        onChange={(e) => addUnavailabililty.setFieldValue("toDate", e.target.value)} />
                                    <Errors formikfun={addUnavailabililty} name={"toDate"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 mb-3">
                            <div className="row mb-2">
                                <label className="form-label mb-0">Timing<span className='Star_Mark'>*</span></label>
                                {
                                    unAvailabilityDefault.arrayOfTimings.map((cur, index) => {
                                        return <React.Fragment key={index}>
                                            <div className="mb-2 d-flex ">
                                                <div className="col-sm-5">
                                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`StartTime${index}`}
                                                        onChange={(e) => addUnavailabililty.setFieldValue(`StartTime${index}`, e)} value={addUnavailabililty.values[`StartTime${index}`] ? addUnavailabililty.values[`StartTime${index}`] : null}

                                                    />
                                                </div>
                                                <div className="col-sm-1 d-flex align-items-center justify-content-center">To</div>
                                                <div className="col-sm-5">
                                                    <TimePicker placeholder="Select Time" use12Hours showSecond={false} focusOnOpen={true} format="hh:mm A" name={`EndTime${index}`}
                                                        onChange={(e) => addUnavailabililty.setFieldValue(`EndTime${index}`, e)} value={addUnavailabililty.values[`EndTime${index}`] ? addUnavailabililty.values[`EndTime${index}`] : null}
                                                    />
                                                </div>
                                                <div className="col-sm-1 d-flex align-items-center justify-content-center" >
                                                    {index === 0 ? (
                                                        <button className=" d-flex align-items-center justify-content-center" style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => addtimesUnavailability()}  >
                                                            <IoMdAddCircleOutline className="all_react_icons_color" />
                                                        </button>
                                                    ) : (
                                                        <button className=" d-flex align-items-center justify-content-center" style={{ border: "none", backgroundColor: "transparent", fontSize: "30px" }} onClick={() => { removetimeUnavailability(index); removeSelection(index) }}  >
                                                            <IoMdCloseCircleOutline className="all_react_icons_color" />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    })
                                }

                            </div>
                        </div>
                        <div className="text-center mt-3">
                            <CustomButton variant="contained" onClick={addUnavailabililty.handleSubmit} > Save</CustomButton>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
})
const DeleteUnAvailabiltyModal = memo(({ UnavailabilityDetele, SetUnavailabilityDetele, handleClose, CustomButton }) => {
    return (
        <Modal show={UnavailabilityDetele} onHide={() => SetUnavailabilityDetele(false)} centered>
            <Modal.Body>
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-trash-can  all_icons_color"></i>
                    <p className='mb-0'>Are you sure?</p>
                    <p>You want to delete this unavailability</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => handleClose("YES",)}> Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => handleClose("NO")} > No</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})
const DeleteAvailabiltyModal = memo(({ availabilityDetele, SetavailabilityDetele, handleDeleteAvailability, CustomButton }) => {
    return (
        <Modal show={availabilityDetele} onHide={() => SetavailabilityDetele(false)} centered>
            <Modal.Body>
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-trash-can  all_icons_color"></i>
                    <p className='mb-0'>Are you sure?</p>
                    <p>You want to delete this availability</p>
                    <CustomButton variant="contained" className="me-3" onClick={() => handleDeleteAvailability("YES",)}> Yes</CustomButton>
                    <CustomButton variant="outlined" onClick={() => handleDeleteAvailability("NO")} > No</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})

const NotFoundClinicModal = memo(({ clinicModal, setClinicModal, CustomButton, navigate }) => {
    return (
        <Modal show={clinicModal} centered>
            <Modal.Body className="px-0">
                <div className="modal-body text-center cus-i">
                    <i className="fa-solid fa-ban mb-3 all_icons_color mt-2 ms-2"></i>
                    <p className="mb-0" style={{ color: "black", fontSize: "23px" }}>No Clinic Details Found</p>
                    <p className="mb-0" style={{ color: "gray", fontSize: "15px" }}>If you want add clinic , Please </p>
                    <p style={{ color: "gray", fontSize: "15px" }}>click this link <span onClick={() => navigate("/profile")} style={{ color: "#333483", cursor: 'pointer', textDecoration: "underline" }}>#add clinic</span></p>
                    <CustomButton variant="contained" onClick={() => setClinicModal(false)}>Close</CustomButton>
                </div>
            </Modal.Body>
        </Modal>
    )
})


export { SetUnAvailabilityModal, DeleteUnAvailabiltyModal, NotFoundClinicModal, DeleteAvailabiltyModal }