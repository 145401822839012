/* eslint-disable eqeqeq */
import React from "react";
import { durationOption } from "../../Context/constants";
import { Errors } from "../../Component/Error";
import { Avatar, Badge, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import useServices from "./Hooks/useServices";
import { ServiceImageFilePath } from "../../Context/api";
import { SelectBox } from "../../Component/Inputs";
import DeleteProfile from "../../Component/DeleteProfile";
import { MdOutlineCameraAlt } from "react-icons/md";
import Loading from "../../Component/Loading";
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import { pattern } from "../../Context/Validation";

const AddServices = () => {
  const {
    UploadImage,
    setImgUrl,
    setLoader,
    CustomButton,
    SpecializationListdata,
    imgUrl,
    addServices,
    FileRef,
    SmallAvatar,
    loader,
  } = useServices()
  const navigate = useNavigate()

  return <>
    {loader && <Loading />}
    <div className="common-component-box">
      {/* <header className="body_header" >
        <div className="d-flex justify-content-start align-items-center">
          <h2 className="page_name">Add Service</h2>
        </div>
      </header> */}
      <header className="body_header" >
        <div className="d-flex justify-content-start align-items-center ">
          <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(-1) }} />
          <h2 className="page_name">Add Service</h2>
        </div>
      </header>
      <div className="row common-white-bg-box mx-0 shadow position-relative">
        <div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Display Picture</label>
              </div>
              <div className="col-4 d-flex  align-items-start flex-column">
                <Badge
                  style={{ background: "#fff" }}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <SmallAvatar alt="Remy Sharp"  >
                      <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                      </MdOutlineCameraAlt>
                      <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "SERVICE", setLoader, setImgUrl)} />
                    </SmallAvatar>
                  }
                >
                  <Avatar  sx={{ width: 80, height: 80 }} src={ServiceImageFilePath + imgUrl} >
                  </Avatar>
                </Badge>
                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SERVICE", id: null }} isDeleted={true} />}
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Display Picture</label>
                  </div>
                  <div className="col-lg-7 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start">
                    <Badge
                      style={{ background: "#fff" }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar alt="Remy Sharp"  >
                          <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                          </MdOutlineCameraAlt>
                          <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef, "SERVICE", setLoader, setImgUrl,imgUrl)} />
                        </SmallAvatar>
                      }
                    >
                      <Avatar sx={{ width: 80, height: 80 }} src={ServiceImageFilePath + imgUrl} >
                      </Avatar>
                    </Badge>
                    {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "SERVICE", id: null }} isDeleted={true} />}
          
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="text" maxLength={20} className="form-control" name="serviceName" value={addServices.values.serviceName}
                  onChange={(e) => addServices.setFieldValue("serviceName", e.target.value)}
                />
                <Errors formikfun={addServices} name={"serviceName"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="text" maxLength={50} className="form-control" name="serviceName" value={addServices.values.serviceName}
                      onChange={(e) => addServices.setFieldValue("serviceName", e.target.value)}
                    />
                    <Errors formikfun={addServices} name={"serviceName"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Duration (in minutes)<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <SelectBox
                  placeholder="Select Discount Type"
                  value={addServices.values.duration}
                  name="duration"
                  keys="value"
                  label="label"
                  menuValeu="value"
                  handleChange={(e) => addServices.setFieldValue("duration", e.target.value)}
                  option={durationOption}
                />
                <Errors formikfun={addServices} name={"duration"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Duration (in minutes)<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <SelectBox
                      placeholder="Select Discount Type"
                      value={addServices.values.duration}
                      name="duration"
                      keys="value"
                      label="label"
                      menuValeu="value"
                      handleChange={(e) => addServices.setFieldValue("duration", e.target.value)}
                      option={durationOption}
                    />
                    <Errors formikfun={addServices} name={"duration"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <select className='form-select' name="specializationId"
                  value={addServices?.values?.specializationId}
                  onChange={(e) => addServices.setFieldValue("specializationId", e.target.value)} >
                  <option hidden>Select specialization</option>
                  {
                    SpecializationListdata?.map((cur, index) => {
                      return <option key={cur?.specializationId} value={cur?.specializationId}>{cur?.specializationName}</option>
                    })
                  }
                </select>
                <Errors formikfun={addServices} name={"specializationId"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Specialization<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <SelectBox
                      placeholder=" Select specialization"
                      value={addServices?.values?.specializationId}
                      name="specializationId"
                      keys="specializationId"
                      label="specializationName"
                      menuValeu="specializationId"
                      handleChange={(e) => addServices.setFieldValue("specializationId", e.target.value)}
                      option={SpecializationListdata}
                    />
                    <Errors formikfun={addServices} name={"specializationId"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Group<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <div className="d-flex">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="group"
                    row
                    value={addServices.values.group}
                    onChange={(e) => {
                      addServices.setFieldValue("group", e.target.value);
                      (e.target.value == false && addServices.setFieldValue("noOfPatients", 1))
                    }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Group<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <div className="d-flex">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="group"
                        row
                        value={addServices.values.group}
                        onChange={(e) => {
                          addServices.setFieldValue("group", e.target.value);
                          (e.target.value == "false" && addServices.setFieldValue("noOfPatients", 1))
                        }}
                      >
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Number of patients<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <input type="number" disabled={addServices.values.group == "true" ? false : true}
                  value={addServices.values.noOfPatients}
                  className='form-control' name="noOfPatients"
                  onChange={(e) => addServices.setFieldValue("noOfPatients", e.target.value)}
                />
                <Errors formikfun={addServices} name={"noOfPatients"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Number of patients<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <input type="number" disabled={addServices.values.group == "true" ? false : true}
                      value={addServices.values.noOfPatients}
                      className='form-control' name="noOfPatients"
                      onChange={(e) => addServices.setFieldValue("noOfPatients", e.target.value)}
                      maxLength={3}
                      onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                    />
                    <Errors formikfun={addServices} name={"noOfPatients"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Price<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-2">
                <input type="number" placeholder="Fixed Price" className="form-control" name="fixedPrice" value={addServices.values.fixedPrice}
                  onChange={(e) => addServices.setFieldValue("fixedPrice", e.target.value)}
                  maxLength={12}
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                />
                <Errors formikfun={addServices} name={"fixedPrice"} />
              </div>
              <div className="col-2">
                <input type="number" placeholder="Variable Price" className="form-control" name="variablePrice" value={addServices.values.variablePrice}
                  onChange={(e) => addServices.setFieldValue("variablePrice", e.target.value)}
                  maxLength={12}
                  onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Price<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <div className="row">
                      <div className="col-6">
                        <input type="number" placeholder="Fixed Price" className="form-control" name="fixedPrice" value={addServices.values.fixedPrice}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (pattern?.DECIMAL_LIMIT?.test(value)) addServices.setFieldValue("fixedPrice", value);
                          }}
                          maxLength={12}
                          onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                        />
                        <Errors formikfun={addServices} name={"fixedPrice"} />
                      </div>
                      <div className="col-6">
                        <input type="number" placeholder="Variable Price" className="form-control" name="variablePrice" value={addServices.values.variablePrice}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (pattern?.DECIMAL_LIMIT?.test(value)) addServices.setFieldValue("variablePrice", value);
                          }}
                          maxLength={12}
                          onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
          <div className="col-sm-12  py-3" style={{ borderBottom: "2px solid #ededed" }}>
            {/* <div className="row">
              <div className="col-3">
                <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
              </div>
              <div className="col-4">
                <textarea className="form-control" cols="30" name="description" value={addServices.values.description}
                  onChange={(e) => addServices.setFieldValue("description", e.target.value)}>
                </textarea>
                <Errors formikfun={addServices} name={"description"} />
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                  </div>
                  <div className="col-lg-7">
                    <textarea className="form-control" cols="30" name="description" value={addServices.values.description}
                      onChange={(e) => addServices.setFieldValue("description", e.target.value)}>
                    </textarea>
                    <Errors formikfun={addServices} name={"description"} />
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 d-grid justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xl-end justify-content-xxl-end my-3">
          <div>
            <CustomButton variant="contained" onClick={addServices.handleSubmit} >Save</CustomButton>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default AddServices