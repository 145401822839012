import { useEffect, useState } from 'react'
import useReduxData from '../../../Hooks/useReduxData'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { addSurgeryValidation } from '../../../Context/Validation'
import { toast } from 'react-toastify'
import { RoleTitle, } from '../../../Context/constants'
import useMuiCompontent from '../../../Hooks/useMuiCompontent'
import { ALLERGIES_HISTORY_DETAILS, INSERT_MEDICAL_HISTORY, LIFESTYLE_HISTORY_DETAILS, MEDICAL_HISTORY_LIST, VITAL_HISTORY_DETAILS } from '../../Repository/PatientRepo'
import moment from 'moment-timezone'

const useAdditionalDetails = (type) => {
    const [loader, setLoader] = useState(false)
    const [deleteModal, setDeletedModal] = useState(false)
    const [addMadical, setAddMadical] = useState(false)
    const [historyModal, setHistoryModal] = useState({
        vitals: false,
        LifeStyles: false,
        Allergies: false
    })
    const [MedicalHistoryList, setMedicalHistoryList] = useState([])
    const [patientVitalDetails, setPatientvitalDetails] = useState(null)
    const [allPatientVitalHistory, setAllPatientVitalHistory] = useState([])
    const [patientLifestyleDetails, setPatientLifestyleDetails] = useState(null)
    const [allPatientLifestyleHistory, setAllPatientLifestyleHistory] = useState([])
    const [allergiesHistory, setAllergiesHistory] = useState(null)
    const [allergiesAllHistory, setAllergiesAllHistory] = useState([])
    const { id, subpatientId } = useParams()
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { CustomButton } = useMuiCompontent()
    const [tab, setTab] = useState(1)
    const headCells = [
        { id: "#", numeric: false, disablePadding: true, label: "#", isShorted: false },
        { id: "country", numeric: true, disablePadding: false, label: "Name", isShorted: true },
        { id: "State", numeric: true, disablePadding: false, label: "Date", isShorted: true },
        { id: "State", numeric: true, disablePadding: false, label: "Place", isShorted: false, showLeft: true, },
    ];

    const getVitalsDetails = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
            staffId: userData.roleTitle === RoleTitle.STAFF ? userData?.userId : undefined
        }
        setLoader(true)
        try {
            let response = await VITAL_HISTORY_DETAILS(payload)
            setPatientvitalDetails(response?.data)
            setLoader(false)
        } catch (error) {
            setPatientvitalDetails(null)
            setLoader(false)
        }
    }
    const getVitalsDetailsAllHistory = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            filter: "ALL",
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await VITAL_HISTORY_DETAILS(payload)
            setAllPatientVitalHistory(response?.data)
            setLoader(false)
        } catch (error) {
            setAllPatientVitalHistory([])
            setLoader(false)
        }
    }
    const getAllergiesDetails = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await ALLERGIES_HISTORY_DETAILS(payload)
            setAllergiesHistory(response?.data)
            setLoader(false)
        } catch (error) {
            setAllergiesHistory(null)
            setLoader(false)
        }
    }
    const getAllergiesDetailsAllHistory = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            filter: "ALL",
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await ALLERGIES_HISTORY_DETAILS(payload)
            setAllergiesAllHistory(response?.data)
            setLoader(false)
        } catch (error) {
            setAllergiesAllHistory([])
            setLoader(false)
        }
    }
    const getLifestyleDetails = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await LIFESTYLE_HISTORY_DETAILS(payload)
            setPatientLifestyleDetails(response?.data[0])
            setLoader(false)
        } catch (error) {
            setPatientLifestyleDetails(null)
            setLoader(false)
        }
    }
    const getLifestyleDetailsAllHistory = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            // patientId: 168,
            filter: "ALL",
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await LIFESTYLE_HISTORY_DETAILS(payload)
            setAllPatientLifestyleHistory(response?.data)
            setLoader(false)
        } catch (error) {
            setAllPatientLifestyleHistory(null)
            setLoader(false)
        }
    }
    const getMedicalHistoryList = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
        }
        setLoader(true)
        try {
            let response = await MEDICAL_HISTORY_LIST(payload)
            setMedicalHistoryList(response?.data)
            setLoader(false)
        } catch (error) {
            setMedicalHistoryList([])
            setLoader(false)
        }
    }
    const formik = useFormik({
        initialValues: {
            name: "",
            date: "",
            place: ""
        },
        validationSchema: addSurgeryValidation,
        onSubmit: async (values, { resetForm }) => {
            let payload = {
                patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
                name: values?.name,
                date: moment(values?.date).startOf('day').utc(),
                place: values?.place

            }
            setLoader(true)
            try {
                let response = await INSERT_MEDICAL_HISTORY(payload)
                toast.success(response.message)
                getMedicalHistoryList()
                setAddMadical(false)
                resetForm()
                setLoader(false)
            } catch (error) {
                toast.error(error.message)
                setLoader(false)
            }
        }
    })
    // const deleteLifeStyleFun = (event) => {
    //     if (event === "YES") {
    //         const data = { medicalHistoryId: deletedData?.id }
    //         setLoader(true)
    //         axiosInstance.post(MedicalHistoryDeleteApi(), data).then((res) => {
    //             if (res.data.status === "200") {
    //                 toast.success(res.data.message)
    //                 getMedicalHistoryList()
    //                 setDeletedModal(false)
    //             } else if (res.data.status === "500") {
    //                 toast.error(commonMessages.NetworkError);
    //             } else {
    //                 toast.error(res.data.message)
    //             }
    //             setLoader(false)
    //         }).catch(() => {
    //             toast.error(commonMessages.NetworkError)
    //             setLoader(false)
    //         })
    //     } else {
    //         setDeletedModal(false)
    //     }
    // }
    const ChangeTab = (Type) => {
        switch (Type) {
            case "Vitals":
                setTab(1)
                break;
            case "Lifestyle":
                setTab(2)
                break;
            case "Allergies":
                setTab(3)
                break;
            case "MadicalHistory":
                setTab(4)
                break;
            default: setTab(4)
        }
    }

    useEffect(() => {
        if (tab === 1) {
            getVitalsDetails()
        }
        if (tab === 2) {
            getLifestyleDetails()
        }
        if (tab === 3) {
            getAllergiesDetails()
        }
        if (tab === 4) {
            getMedicalHistoryList()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    return {
        CustomButton,
        loader,
        formik,
        headCells,
        MedicalHistoryList,
        addMadical,
        deleteModal,
        historyModal,
        tab,
        patientVitalDetails,
        allPatientVitalHistory,
        patientLifestyleDetails,
        allPatientLifestyleHistory,
        allergiesHistory,
        allergiesAllHistory,
        setHistoryModal,
        // deleteLifeStyleFun,
        setAddMadical,
        setDeletedModal,
        ChangeTab,
        getVitalsDetailsAllHistory,
        getLifestyleDetailsAllHistory,
        getAllergiesDetailsAllHistory,

    }
}

export default useAdditionalDetails
