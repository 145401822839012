
import React from "react";
import useTodayAgenda from './Hooks/useTodayAgenda'
import { IoMdAdd } from 'react-icons/io'
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import moment from "moment-timezone";
import Loading from "../../Component/Loading";
import { Avatar, TableContainer, Tooltip, Table, TableBody, TableCell, TableRow, } from "@mui/material";
import { FaArrowLeft } from 'react-icons/fa';
import { FilePathName, VaccinationImageFilePath } from "../../Context/api";
import { Errors } from "../../Component/Error";
import { AiOutlineCloudUpload } from "react-icons/ai";
import PdfImage from '../../Assets/Images/home/pdf.svg';
import { Modal } from "react-bootstrap";
import Pdfimage from "../../Assets/Images/pdf-svgrepo-com.svg"
import { NoRecordFound } from "../../Component/NoRecordFound";
import useTableSorting from "../../Hooks/useTableSorting";
import { SearchAbleSelectBox, SelectBox } from "../../Component/Inputs";
import { MedicineDosesUnitOptions, MedicineTypeOptions, countOptions } from "../../Context/constants";
import useDragDrop from "../../Hooks/useDrag&Drop";
import { Dosage } from "../../Utils/Utils";
import viewIcon from "../../Assets/Icons/MicrosoftTeams-image (2).png"
import downloadIcon from "../../Assets/Icons/MicrosoftTeams-image.png"
import PrintIcon from "../../Assets/Icons/MicrosoftTeams-image (1).png"
import useDeleteFile from "../../Hooks/useDeleteFile";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Overview = () => {
    const {
        navigate,
        uploadPdf,
        handleOpenVaccinationModal,
        handleDelete,
        handalSearchAbleSelectBox,
        setVaccinationSerialNumberPath,
        setVaccinationSerialNumberImage,
        setShowPDFModal,
        setPdfPathName,
        setUrl,
        // handleChagneGivenon,
        // handleSubmitVaccination,
        setremoveButton,
        medicineList,
        setTab,
        openModalFun,
        closeModalFun,
        handleClosedModal,
        handleClosedVaccinationModal,
        handleOpenModal,
        componentRef,
        contentRef,
        CustomButton,
        loader,
        medicineData,
        addMedicine,
        Url,
        addReport,
        ListVaccinationCategory,
        addvaccination,
        UploadReportType,
        openModal,
        VaccinationFormList,
        removeButton,
        MultipleReportData,
        pdfPathName,
        ShowPDFModal,
        vaccinationSerialNumberImage,
        vaccinationSerialNumberPath,
        EprescriptionList,
        tab,
        headCellsPrescription,
        CreateModal,
        headCellsReport,
        headCellsVaccination,
        openDeleteModal,
        assignVaccinationcalenderList,
        isFrontDeskPermission,
        userdata,
    } = useTodayAgenda()
    const { EnhancedTableHead: EnhancedTableHeadPrescription,
        order: orderPrescription,
        orderBy: orderByPrescription,
        stableSort: stableSortPrescription,
        getComparator: getComparatorPrescription,
        handleRequestSort: handleRequestSortPrescription,
    } = useTableSorting(headCellsPrescription)

    const {
        EnhancedTableHead: EnhancedTableHeadReport,
        order: orderReport,
        orderBy: orderByReport,
        stableSort: stableSortReport,
        getComparator: getComparatorReport,
        handleRequestSort: handleRequestSortReport,
    } = useTableSorting(headCellsReport)
    const {
        EnhancedTableHead: EnhancedTableHeadVaccination,
        order: orderVaccination,
        orderBy: orderByVaccination,
        stableSort: stableSortVaccination,
        getComparator: getComparatorVaccination,
        handleRequestSort: handleRequestSortVaccination,
    } = useTableSorting(headCellsVaccination)
    const { handleDragEnter, handleDragLeave, handleDragOver, handleDrop } = useDragDrop()
    const { deleteFileFun } = useDeleteFile()
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header">
                    <div className="d-flex justify-content-start align-items-center">
                        <FaArrowLeft className="me-2 cursor" onClick={() => { navigate(-1) }} />
                        <h2 className="page_name">Overview</h2>
                    </div>
                </header>
                <div className="common-white-bg-box  mx-0 shadow px-2">
                    <div className="row">
                        <div className="col-12 mt-2 pb-3" style={{ borderBottom: "2px solid #ededed" }}>
                            <ul className="nav  mb-0  pb-1 ">
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn me-2 ${tab === 1 && "active_teams_frofile"}`} id="personal" type="button" onClick={() => setTab(1)} >E-Prescription</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${tab === 2 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTab(2)} >Report</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`teams_frofile_stepper_btn ${tab === 3 && "active_teams_frofile"}`} id="education" type="button" onClick={() => setTab(3)} >Vaccination</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {tab === 1 && <div className="row mt-3 ">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-8">
                                {
                                    !isFrontDeskPermission && <CustomButton variant="contained" className="" startIcon={<IoMdAdd />} onClick={() => { openModalFun("Prescription"); medicineList() }} > create e-Prescription</CustomButton>

                                }

                            </div>
                            {/* <div className="col-4 text-end"><CustomButton variant="contained"
                                style={{ background: "#f5f5f5", color: "black" }} startIcon={<ExitToAppIcon />}  >export</CustomButton>
                                </div> */}
                        </div>
                        <div className="col-12">
                            <TableContainer >
                                {EprescriptionList?.length === 0 && <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /> </div>}
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        EprescriptionList?.length !== 0 && <EnhancedTableHeadPrescription order={orderPrescription} orderBy={orderByPrescription} onRequestSort={handleRequestSortPrescription} rowCount={EprescriptionList?.length} />
                                    }
                                    <TableBody className="table_overflow_scroll" >
                                        {stableSortPrescription(EprescriptionList, getComparatorPrescription(orderPrescription, orderByPrescription)).map(
                                            (item, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow id="hover_tr">
                                                    <TableCell id={labelId} align="left">{item?.prescriptionNumber}</TableCell>
                                                    <TableCell id={labelId} align="left">{moment(item?.startDate).format("DD/MM/YYYY")}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.medicineName}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.medicineType}</TableCell>
                                                    <TableCell id={labelId} align="left">{Dosage(item)}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.doseAmount + item?.doseType}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.medicineIntakeDurationAmount + " " + item?.medicineIntakeDurationType}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.medicineIntakeTime !== "CUSTOM" ? item?.medicineIntakeTime : item?.medicineIntakeCustomTime}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.medicineIntakeAmount}</TableCell>
                                                    <TableCell id={labelId} align="left">
                                                        <ul className="actionul w-100 d-flex justify-content-end">
                                                            <li className="" onClick={() => handleOpenVaccinationModal("viewEPrescription", item)}>
                                                                <Tooltip title="View " placement="top" style={{ cursor: "pointer" }}>
                                                                    <div>
                                                                        <i className="fa-solid fa-eye me-2 all_icons_color"></i>
                                                                    </div>
                                                                </Tooltip>
                                                            </li>
                                                            <li className="" onClick={() => handleOpenModal("Prescription", item)}>
                                                                <Tooltip title="Delete E-prescription " placement="top" style={{ cursor: "pointer" }}>
                                                                    <div>
                                                                        <i className="fa-solid fa-trash-can all_icons_color"></i>
                                                                    </div>
                                                                </Tooltip>
                                                            </li>

                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    }
                    {tab === 2 && <div className="row mt-3 ">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-8">
                                {
                                    !isFrontDeskPermission && <CustomButton variant="contained" className="" startIcon={<IoMdAdd />} onClick={() => openModalFun("Report")} > create Report</CustomButton>
                                }


                            </div>
                            {/* <div className="col-4 text-end"><CustomButton variant="contained"
                                style={{ background: "#f5f5f5", color: "black" }} startIcon={<ExitToAppIcon />}  >export</CustomButton>
                                </div> */}
                        </div>
                        <div className="col-12">
                            <TableContainer >
                                {MultipleReportData?.length === 0 && <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /> </div>}
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        MultipleReportData?.length !== 0 && <EnhancedTableHeadReport order={orderReport} orderBy={orderByReport} onRequestSort={handleRequestSortReport} rowCount={MultipleReportData?.length} />
                                    }
                                    <TableBody className="table_overflow_scroll" >
                                        {stableSortReport(MultipleReportData, getComparatorReport(orderReport, orderByReport)).map(
                                            (item, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                const filetype = item?.test.split('.').pop();
                                                return <React.Fragment key={index}><TableRow id="hover_tr">
                                                    <TableCell id={labelId} align="left">{item?.title}</TableCell>
                                                    <TableCell id={labelId} align="left">{item?.description}</TableCell>
                                                    <TableCell id={labelId} align="left">
                                                        {
                                                            item?.test ? <Avatar
                                                                src={filetype === "pdf" ? Pdfimage : (FilePathName + item?.test)}
                                                                onClick={() => {
                                                                    setShowPDFModal(true);
                                                                    setPdfPathName(item?.test)
                                                                }}
                                                                style={{ cursor: "pointer" }}
                                                                alt=""
                                                                sx={{ width: 25, height: 25 }}
                                                            >
                                                            </Avatar> : "-"
                                                        }
                                                    </TableCell>
                                                    <TableCell id={labelId} align="left">
                                                        <ul className="actionul w-100 d-flex justify-content-end">
                                                            <li className="" onClick={() => handleOpenModal("Report", item)}>
                                                                <Tooltip title="Delete Report " placement="left" style={{ cursor: "pointer" }}>
                                                                    <div>
                                                                        <i className="fa-solid fa-trash-can all_icons_color"></i>
                                                                    </div>
                                                                </Tooltip>
                                                            </li>
                                                        </ul>
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                    }
                    {tab === 3 && <div className="row mt-3 ">
                        <div className="col-12 d-flex justify-content-between">
                            <div className="col-8">
                                {
                                    !isFrontDeskPermission && <CustomButton variant="contained" className="" startIcon={<IoMdAdd />} onClick={() => openModalFun("Vaccination")} > assign vaccination</CustomButton>
                                }

                            </div>
                        </div>
                        <div className="col-12">
                            <TableContainer >
                                {assignVaccinationcalenderList?.length === 0 && <div className="col-sm-12 mt-3 text-center " ><NoRecordFound /> </div>}
                                <Table
                                    id="table_mui"
                                    aria-labelledby="tableTitle"
                                    size={"medium"}
                                    aria-label="enhanced table"
                                >
                                    {
                                        assignVaccinationcalenderList?.length !== 0 && <EnhancedTableHeadVaccination order={orderVaccination} orderBy={orderByVaccination} onRequestSort={handleRequestSortVaccination} rowCount={assignVaccinationcalenderList?.length} />
                                    }
                                    <TableBody className="table_overflow_scroll" >
                                        {stableSortVaccination(assignVaccinationcalenderList, getComparatorVaccination(orderVaccination, orderByVaccination)).map(
                                            (row, index) => {
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return <React.Fragment key={index}><TableRow hover id="hover_tr">
                                                    <TableCell id={labelId} align="left">{row.formName}</TableCell>
                                                    <TableCell id={labelId} align="left">{row.doctorName}</TableCell>
                                                    <TableCell id={labelId} align="left">{(row?.PatientName ? row?.PatientName : "") + " " + (row?.PatientLastName ? row?.PatientLastName : "")}</TableCell>
                                                    <TableCell id={labelId} align="right">
                                                        <img src={viewIcon} alt="" className='cursor' onClick={() => {
                                                            handleOpenVaccinationModal("viewVaccinationForm", row);
                                                            //  navigate(`edit/${row?.vaccinationFormFieldId}`)
                                                        }} width={18} height={18} title='view' />
                                                        <img src={downloadIcon} alt="" className='mx-1 cursor' width={18} height={18} title='Download'
                                                            onClick={() => {
                                                                handleOpenVaccinationModal("downloadVaccinationForm", row);
                                                            }} />
                                                        <img src={PrintIcon} alt="" className='cursor' width={18} height={18} title='Print'
                                                            onClick={() => {
                                                                handleOpenVaccinationModal("printVaccinationForm", row);
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                                </React.Fragment>
                                            }
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>}
                </div>
                <Modal size='xl' show={CreateModal.Prescription} onHide={closeModalFun} centered>
                    <Modal.Body>

                        <div className="d-flex justify-content-between">
                            <p className="modal-title mb-0 page_name">Create E-Prescription</p>
                            <span className='cursor' onClick={closeModalFun}> <ClearOutlinedIcon /></span>
                        </div>
                        <div className="modal-body  cus-i">
                            <div className="row">
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">RX<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        multiple={false}
                                                        option={medicineData}
                                                        getOptionLabel="medicineName"
                                                        isOptionEqualToValue="medicineId"
                                                        value={addMedicine?.values?.rx}
                                                        name="rx"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    // getOptionDisabled={(option) => option?.selected === true}
                                                    />
                                                    <Errors formikfun={addMedicine} name={"rx"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Medicine Type<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SearchAbleSelectBox
                                                        multiple={false}
                                                        option={MedicineTypeOptions}
                                                        getOptionLabel="label"
                                                        isOptionEqualToValue="value"
                                                        value={addMedicine?.values?.type}
                                                        name="type"
                                                        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Strength<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input type="number" className='form-control text-center text-dark' name='dose'
                                                                value={addMedicine?.values?.dose}
                                                                onChange={(e) => addMedicine?.setFieldValue("dose", e.target.value)}
                                                                maxLength={4}
                                                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                            />
                                                        </div>
                                                        <div className="col-9">
                                                            <SearchAbleSelectBox
                                                                multiple={false}
                                                                option={MedicineDosesUnitOptions}
                                                                getOptionLabel="label"
                                                                isOptionEqualToValue="value"
                                                                value={addMedicine?.values?.unit}
                                                                name="unit"
                                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  pt-3 pb-0 " >
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Standard<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <ul className="nav nav-pills mb-3" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                        <li className="nav-item w-50" role="presentation">
                                                            <button className="tab-btn-color active w-100" id="pills-Standard-tab"
                                                                onClick={() => {
                                                                    addMedicine.setFieldValue("medCountType", "STANDARD")
                                                                    addMedicine.setFieldValue("timeofmedicine", "HOURS")
                                                                    addMedicine.setFieldValue("timeofmedicinenumberForHours", "")
                                                                    addMedicine.setFieldValue("timeofmedicinenumberForDays", "")
                                                                    addMedicine.setFieldValue("morningMedCount", true)
                                                                    addMedicine.setFieldValue("afternoonMedCount", false)
                                                                    addMedicine.setFieldValue("nightMedCount", false)
                                                                }}
                                                                data-bs-toggle="pill" data-bs-target="#pills-Standard" type="button" role="tab" aria-controls="pills-Standard" aria-selected="true">Standard</button>
                                                        </li>
                                                        <li className="nav-item w-50" role="presentation">
                                                            <button className="tab-btn-color w-100" id="pills-Custom-tab"
                                                                onClick={() => {
                                                                    addMedicine.setFieldValue("medCountType", "CUSTOM")
                                                                    addMedicine.setFieldValue("morningMedCount", false)
                                                                    addMedicine.setFieldValue("afternoonMedCount", false)
                                                                    addMedicine.setFieldValue("nightMedCount", false)
                                                                }}
                                                                data-bs-toggle="pill" data-bs-target="#pills-Custom" type="button" role="tab" aria-controls="pills-Custom" aria-selected="false">Custom</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                {
                                    addMedicine?.values?.medCountType === "CUSTOM" ? <div className="col-sm-12  pb-3 pt-0 " style={{ borderBottom: "2px solid #ededed" }}>

                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <div className="row">
                                                            <div className="col-3 ">
                                                                {
                                                                    addMedicine?.values?.timeofmedicine === "HOURS" ? <input type="number" className='form-control text-center text-dark' name=''
                                                                        value={addMedicine?.values?.timeofmedicinenumberForHours}
                                                                        onChange={(e) => addMedicine?.setFieldValue("timeofmedicinenumberForHours", e.target.value)}

                                                                    /> : <input type="number" className='form-control text-center text-dark' name=''
                                                                        value={addMedicine?.values?.timeofmedicinenumberForDays}
                                                                        onChange={(e) => addMedicine?.setFieldValue("timeofmedicinenumberForDays", e.target.value)}

                                                                    />
                                                                }

                                                            </div>
                                                            <div className="col-9 ">
                                                                <ul className="nav mb-0 nav-pills px-0 d-flex justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                                    <li className="nav-item" style={{ textAlign: "center", width: "50%" }} role="presentation">
                                                                        <button className="tab-btn-color px-3 active w-100" id="pills-BeforeFood-tab" data-bs-toggle="pill"
                                                                            data-bs-target="#pills-BeforeFood" type="button" role="tab" aria-controls="pills-BeforeFood"
                                                                            aria-selected="true" onClick={(e) => addMedicine?.setFieldValue("timeofmedicine", "HOURS")} >Hours</button>
                                                                    </li>
                                                                    <li className="nav-item" style={{ textAlign: "center", width: "50%" }} role="presentation">
                                                                        <button className="tab-btn-color px-3 w-100 " id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                            data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                            aria-selected="false" onClick={(e) => addMedicine?.setFieldValue("timeofmedicine", "DAYS")}
                                                                        >Days</button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <Errors formikfun={addMedicine} name={"timeofmedicinenumber"} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div> : <div className="col-sm-12  py-3 pt-0 " style={{ borderBottom: "2px solid #ededed" }}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <ul className="nav mb-0  px-0  d-flex justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                            <li className={`tab-btn-color cursor px-3 py-2  ${addMedicine.values.morningMedCount && "active"}`}
                                                                onClick={(e) => addMedicine?.setFieldValue("morningMedCount", !addMedicine.values.morningMedCount)}>
                                                                Morning
                                                            </li>
                                                            <li className={`tab-btn-color cursor px-3 py-2  ${addMedicine.values.afternoonMedCount && "active"}`}
                                                                onClick={(e) => addMedicine?.setFieldValue("afternoonMedCount", !addMedicine.values.afternoonMedCount)} >
                                                                Afternoon
                                                            </li>
                                                            <li className={`tab-btn-color cursor px-3 py-2 ${addMedicine.values.nightMedCount && "active"} `}
                                                                onClick={(e) => addMedicine?.setFieldValue("nightMedCount", !addMedicine.values.nightMedCount)} >
                                                                Evening
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Duration<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <input type="number" className='form-control text-center text-dark' name='duration'
                                                                value={addMedicine?.values?.duration}
                                                                onChange={(e) => addMedicine?.setFieldValue("duration", e.target.value)}
                                                                maxLength={3}
                                                                onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                                            />
                                                        </div>
                                                        <div className="col-9">
                                                            <ul className="nav mb-0 nav-pills px-0 d-flex justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                    <button className="tab-btn-color px-3 py-2 active" id="pills-BeforeFood-tab" data-bs-toggle="pill"
                                                                        data-bs-target="#pills-BeforeFood" type="button" role="tab" aria-controls="pills-BeforeFood"
                                                                        aria-selected="true" onClick={(e) => addMedicine?.setFieldValue("DurationTimeFrame", "DAYS")} >Day</button>
                                                                </li>
                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                    <button className="tab-btn-color px-3 py-2" id="pills-AfterFood-tab" data-bs-toggle="pill"
                                                                        data-bs-target="#pills-AfterFood" type="button" role="tab" aria-controls="pills-AfterFood"
                                                                        aria-selected="false" onClick={(e) => addMedicine?.setFieldValue("DurationTimeFrame", "WEEKS")}
                                                                    >Weeks</button>
                                                                </li>
                                                                <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                                    <button className="tab-btn-color px-3 py-2 " id="pills-Custom-2-tab" data-bs-toggle="pill"
                                                                        data-bs-target="#pills-Custom-2" type="button" role="tab" aria-controls="pills-Custom-2"
                                                                        aria-selected="false" onClick={(e) => addMedicine?.setFieldValue("DurationTimeFrame", "MONTHS")}
                                                                    >Months</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <Errors formikfun={addMedicine} name={"duration"} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className={`col-sm-12  py-3 ${addMedicine?.values?.when === "CUSTOM" && "pb-0"} `} style={{ borderBottom: addMedicine?.values?.when !== "CUSTOM" ? "2px solid #ededed" : "none" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">When<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <ul className="nav nav-pills mb-3 px-0 d-flex justify-content-between" style={{ border: "1px solid #38c4b9", borderRadius: "6px", fontSize: "13px" }} id="pills-tab" role="tablist">
                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                            <button className="tab-btn-color px-3 active" id="pills-BeforeFood-tab" data-bs-toggle="pill" data-bs-target="#pills-BeforeFood"
                                                                type="button" role="tab" aria-controls="pills-BeforeFood" aria-selected="true"
                                                                onClick={(e) => { addMedicine.setFieldValue("whenCustom", ""); addMedicine?.setFieldValue("when", "BEFORE FOOD") }}
                                                            >Before Food</button>
                                                        </li>
                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                            <button className="tab-btn-color px-3" id="pills-AfterFood-tab" data-bs-toggle="pill" data-bs-target="#pills-AfterFood"
                                                                type="button" role="tab" aria-controls="pills-AfterFood" aria-selected="false"
                                                                onClick={(e) => { addMedicine.setFieldValue("whenCustom", ""); addMedicine?.setFieldValue("when", "AFTER FOOD") }}
                                                            >After Food</button>
                                                        </li>
                                                        <li className="nav-item" style={{ textAlign: "center", }} role="presentation">
                                                            <button className="tab-btn-color px-3 " id="pills-Custom-2-tab" data-bs-toggle="pill" data-bs-target="#pills-Custom-2"
                                                                type="button" role="tab" aria-controls="pills-Custom-2" aria-selected="false"
                                                                onClick={(e) => { addMedicine?.setFieldValue("when", "CUSTOM") }}
                                                            >Custom</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                {
                                    addMedicine?.values?.when === "CUSTOM" && <div className="col-sm-12  py-3 pt-0 pt-0 " style={{ borderBottom: "2px solid #ededed" }} >
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <div className="row">
                                                    <div className="col-lg-5">
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <input maxLength={50} type="text" className='form-control  text-dark'
                                                            value={addMedicine?.values?.whenCustom}
                                                            onChange={(e) => addMedicine.setFieldValue("whenCustom", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Quantity<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <SelectBox
                                                        className="mt-2"
                                                        placeholder="Select quantity"
                                                        value={addMedicine?.values?.quantity}
                                                        name="quantity"
                                                        keys="value"
                                                        label="label"
                                                        menuValeu="value"
                                                        handleChange={(e) => addMedicine?.setFieldValue("quantity", e.target.value)}
                                                        option={countOptions(3, 1)}
                                                    />
                                                    <Errors formikfun={addMedicine} name={"quantity"} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " style={{ borderBottom: "2px solid #ededed" }}>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="row">
                                                <div className="col-lg-5">
                                                    <label className="form-label mb-0">Notes<span className='Star_Mark'></span></label>
                                                </div>
                                                <div className="col-lg-7">
                                                    <textarea className="form-control mb-1" placeholder='Add Notes'
                                                        onChange={(e) => addMedicine?.setFieldValue("note", e.target.value)}
                                                        value={addMedicine?.values?.note}
                                                        name='note' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 text-end mt-3">
                            {<CustomButton disabled={(addMedicine?.isValid && addMedicine?.dirty) ? false : true} variant="contained" className="" onClick={addMedicine.handleSubmit}  > submit</CustomButton>}
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={CreateModal.Report} onHide={() => { closeModalFun(); addReport.resetForm() }} centered>
                    <Modal.Body>
                        <div className="d-flex justify-content-between">
                            <p className="modal-title mb-0 page_name">Create Report</p>
                            <span className='cursor' onClick={() => { closeModalFun(); addReport.resetForm() }}> <ClearOutlinedIcon /></span>
                        </div>
                        <div className="modal-body  cus-i">
                            <div className="row">
                                <div className="col-sm-12  py-3 " >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <label className="form-label mb-0">Title<span className='Star_Mark'>*</span></label>
                                                </div>
                                                <div className="col-lg-9">
                                                    <input type="text" maxLength={50} className="form-control"
                                                        value={addReport.values.title}
                                                        onChange={(e) => addReport.setFieldValue(`title`, e.target.value)}
                                                    />
                                                    <Errors formikfun={addReport} name={"title"} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12" >

                                    <div className="row">
                                        <div className="col-lg-3">
                                            <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-lg-9">
                                            <textarea className="form-control"
                                                value={addReport.values.description}
                                                onChange={(e) => addReport.setFieldValue("description", e.target.value)}
                                            />
                                            <Errors formikfun={addReport} name={"description"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-4 " >
                                    <div className="bodrerer col w-100" style={{ border: `${!Url ? "2px dashed #aaaaaa" : "none"}`, width: "100%", maxWidth: "100%" }}
                                        onDragEnter={handleDragEnter}
                                        onDragLeave={handleDragLeave}
                                        onDragOver={handleDragOver}
                                        onDrop={(e) => handleDrop(e, uploadPdf)}
                                    >
                                        <label htmlFor="inputTag">
                                            <AiOutlineCloudUpload className="fs-4" />
                                            {Url && <img src={UploadReportType === "application/pdf" ? PdfImage : FilePathName + Url} alt="" />}
                                            <input id="inputTag" type="file" name="file" onChange={(e) => { uploadPdf(e.target.files[0]) }} />
                                            <br style={{ fontSize: "13px" }} />
                                            {
                                                !Url && <p className="mb-0">Drag file(s) here or <span>click to upload</span></p>
                                            }

                                            <br />
                                        </label>

                                        {/* {Url && <DeleteIcons setImgUrl={setUrl} deletedData={{ fileName: Url, type: "TESTREPORT" }} isDeleted={true} />} */}
                                    </div>
                                    {
                                        Url && <p className=" m-0 ms-3 mt-2 all_icons_color text-center text-danger" style={{ cursor: 'pointer', }} onClick={() => deleteFileFun(setUrl, { fileName: Url, type: "TESTREPORT", id: null }, true)}>Delete</p>
                                    }
                                </div>
                                <div className="col-sm-12 text-center mt-3">
                                    <CustomButton variant="contained" className="" onClick={addReport.handleSubmit} >submit</CustomButton>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                <Modal show={CreateModal.Vaccination} onHide={closeModalFun} centered>
                    <Modal.Body>
                        <div className="">
                            <p className="modal-title mb-0">Create Vaccination</p>
                        </div>
                        <div className="modal-body  cus-i">
                            <div className="row">
                                <div className="col-sm-12  py-3 " >
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Category<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-9">
                                            <SearchAbleSelectBox
                                                multiple={false}
                                                option={ListVaccinationCategory}
                                                getOptionLabel="vaccinationCategoryName"
                                                isOptionEqualToValue="vaccinationCategoryId"
                                                value={addvaccination?.values?.category}
                                                name="category"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={addvaccination} name={"category"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12  py-3 " >
                                    <div className="row">
                                        <div className="col-3">
                                            <label className="form-label mb-0">Form<span className='Star_Mark'>*</span></label>
                                        </div>
                                        <div className="col-9">
                                            <SearchAbleSelectBox
                                                multiple={true}
                                                option={VaccinationFormList}
                                                getOptionLabel="formName"
                                                isOptionEqualToValue="vaccinationFormId"
                                                value={addvaccination?.values?.vaccinationForm}
                                                name="vaccinationForm"
                                                handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                            />
                                            <Errors formikfun={addvaccination} name={"vaccinationForm"} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center mt-3">
                                    <CustomButton variant="contained" className="" onClick={addvaccination.handleSubmit}>Submit</CustomButton>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
                <Modal show={openDeleteModal?.Prescription} onHide={() => handleClosedModal("Prescription")} centered >
                    <Modal.Body>
                        <div className="modal-body text-center cus-i">
                            <i className="fa-solid fa-trash-can all_icons_color"></i>
                            <p className="mb-0">Are you sure?</p>
                            <p>You want to delete this e-prescription</p>
                            <CustomButton variant="outlined" className="me-3 mt-3" onClick={() => handleDelete("NO")} >No</CustomButton>
                            <CustomButton variant="contained" className="mt-3" onClick={() => handleDelete("YES")} >Yes</CustomButton>
                        </div>
                    </Modal.Body>
                </Modal >
                <Modal show={openDeleteModal?.Report} onHide={() => handleClosedModal("Report")} centered >
                    <Modal.Body>
                        <div className="modal-body text-center cus-i">
                            <i className="fa-solid fa-trash-can all_icons_color"></i>
                            <p className="mb-0">Are you sure?</p>
                            <p>You want to delete this  report</p>
                            <CustomButton variant="outlined" className="me-3 mt-3" onClick={() => handleDelete("NO")} >No</CustomButton>
                            <CustomButton variant="contained" className="mt-3" onClick={() => handleDelete("YES")} >Yes</CustomButton>
                        </div>
                    </Modal.Body>
                </Modal >
                <Modal show={openDeleteModal?.Vaccination} onHide={() => handleClosedModal("Vaccination")} centered >
                    <Modal.Body>
                        <div className="modal-body text-center cus-i">
                            <i className="fa-solid fa-trash-can all_icons_color"></i>
                            <p className="mb-0">Are you sure?</p>
                            <p>You want to delete this  vaccination calendar</p>
                            <CustomButton variant="outlined" className="me-3 mt-3" onClick={() => handleDelete("NO")} >No</CustomButton>
                            <CustomButton variant="contained" className="mt-3" onClick={() => handleDelete("YES")} >Yes</CustomButton>
                        </div>
                    </Modal.Body>
                </Modal >
                <Modal size={`${pdfPathName?.split('.')?.pop() === "pdf" ? "xl" : "md"}`} show={pdfPathName && ShowPDFModal} onHide={() => { setShowPDFModal(false); setPdfPathName() }} centered>
                    <Modal.Body className="p-0" style={{ height: ` ${pdfPathName?.split('.')?.pop() === "pdf" ? "600px" : "400px"} ` }}>
                        {
                            pdfPathName?.split('.')?.pop() === "pdf" ? <iframe src={FilePathName + pdfPathName} title={FilePathName + pdfPathName} style={{ width: '100%', height: '100%', }} /> :
                                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={FilePathName + pdfPathName} alt="" />
                        }
                    </Modal.Body>
                </Modal>
                <Modal size={`md`} show={vaccinationSerialNumberImage} onHide={() => { setVaccinationSerialNumberImage(false); setVaccinationSerialNumberPath(null) }} centered>
                    <Modal.Body className="p-0" style={{ height: `400px` }}>
                        <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={VaccinationImageFilePath + vaccinationSerialNumberPath} alt="" />
                    </Modal.Body>
                </Modal>
                <Modal size='xl' className='px-0' show={openModal.viewVaccinationForm || openModal.downloadVaccinationForm || openModal.printVaccinationForm}
                    onHide={() => { handleClosedVaccinationModal(openModal.viewVaccinationForm ? "viewVaccinationForm" : openModal.downloadVaccinationForm ? "downloadVaccinationForm" : openModal.printVaccinationForm ? "printVaccinationForm" : "") }} centered>
                    <Modal.Body className='p-0'>
                        <div className="modal-body p-0">
                            <div className="VACCINATION_FORM_VIEW px-0 " ref={openModal.downloadVaccinationForm ? contentRef : componentRef}>
                                <div className="col-12 HEARDER  d-flex justify-content-center align-items-center">
                                    <h4 className='mb-0 text-white'>{openModal?.data?.formName}</h4>
                                </div>
                                <div className=" col-12 CONTENT p-5 d-flex flex-column justify-content-between">
                                    <div className="row"  >
                                        <div className="col-7  d-flex flex-wrap">
                                            <div className='' style={{ width: "33%" }}>
                                                <p className='mb-0 fw-bold'>Patient Name</p>
                                                <p className='mb-0  text-secondary'>{(openModal?.data?.PatientName ? openModal?.data?.PatientName : "") + " " + (openModal?.data?.PatientLastName ? openModal?.data?.PatientLastName : "")}</p>
                                            </div>
                                            <div className='' style={{ width: "33%" }}>
                                                <p className='mb-0 fw-bold'>Age</p>
                                                <p className='mb-0  text-secondary'>{openModal?.data?.patientage}</p>
                                            </div>
                                            <div className='' style={{ width: "33%" }}>
                                                <p className='mb-0 fw-bold'>Patient ID</p>
                                                <p className='mb-0  text-secondary'>{openModal?.data?.patientIdforForm ? openModal?.data?.patientIdforForm : "--"}</p>
                                            </div>
                                            <div className='mt-3' style={{ width: "33%" }}>
                                                <p className='mb-0 fw-bold'>Date of Birth</p>
                                                <p className='mb-0  text-secondary'> {moment(openModal?.data?.patientDob).format("ll")}</p>
                                            </div>
                                            <div className='mt-3' style={{ width: "33%" }}>
                                                <p className='mb-0 fw-bold'>Gender</p>
                                                <p className='mb-0  text-secondary'>{openModal?.data?.PatientGender}</p>
                                            </div>
                                        </div>
                                        <p className='mb-0 fw-bold mt-4'>Vaccination Record</p>
                                        <div className="col-12 mt-2 tab_scroll">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Vaccine Name</th>
                                                        <th scope="col">Brand Name</th>
                                                        <th scope="col">Given on</th>
                                                        <th scope="col">Due</th>
                                                        <th scope="col">Comments</th>
                                                        <th scope="col">Vaccination Serial Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {openModal?.data?.vaccinationFormFieldArray?.map((cur, index) => {
                                                        return <>
                                                            <tr>
                                                                <th className='text-center'><input className="form-check-input cursor" type="checkbox" disabled checked={cur?.givenOn ? true : false} id="flexCheckDefault" /></th>
                                                                <td>{cur?.vaccineName}</td>
                                                                <td>{cur?.brandName}</td>
                                                                <td>{cur?.givenOn ? cur?.givenOn : "--"
                                                                    // <label id="DatePicker" className="position-relative" >
                                                                    //     <span className=''> <u> {cur?.newGivenOn ? cur?.newGivenOn : "--"} </u> </span>
                                                                    //     {
                                                                    //         openModal.viewVaccinationForm && <input type="date" className="DATEPIKER_NATIVE_INPUT" onChange={(e) => handleChagneGivenon(e, index)} />
                                                                    //     }
                                                                    // </label>
                                                                }
                                                                </td>
                                                                <td>{cur?.dueType === "dueAge" ? `${cur?.dueAge} ${cur?.dueAgeDuration}` : cur?.dueDate}</td>
                                                                <td>{cur?.comment} </td>
                                                                <td className="VACCINATION_SERIAL_NUMBER_IMAGE">
                                                                    {cur?.vaccinationSerialNumber ? <img onClick={() => { setVaccinationSerialNumberImage(true); setVaccinationSerialNumberPath(cur?.vaccinationSerialNumber) }} width={200} height={30} style={{ objectFit: 'cover' }} className="cursor" src={VaccinationImageFilePath + cur?.vaccinationSerialNumber} alt="" /> : "--"}
                                                                </td>
                                                            </tr>
                                                        </>
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                        <p style={{ color: '#5C6881', fontSize: "12px", }}>Please keep this record card, it includes the medical information, details and the vaccine you have received. This card will show the next schedule of your vaccine. It is important to show this card to the next vaccination schedule for health officials to verify.</p>
                                    </div>
                                    <div className="row">
                                        <div className=" col-12 d-flex justify-content-end">
                                            {
                                                // openModal.viewVaccinationForm ? <CustomButton variant="contained" onClick={handleSubmitVaccination}   >submit</CustomButton> :
                                                openModal.viewVaccinationForm ? "" :
                                                    openModal.downloadVaccinationForm ? <CustomButton variant="contained" onClick={() => { setremoveButton(true) }} style={{ display: removeButton ? "none" : "bloack" }}  >download</CustomButton> :
                                                        openModal.printVaccinationForm ? <CustomButton variant="contained" onClick={() => setremoveButton(true)} style={{ display: removeButton ? "none" : "bloack" }}  >Print</CustomButton> : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 FOOTER"></div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={openModal?.viewEPrescription} onHide={() => handleClosedVaccinationModal("viewEPrescription")} centered>
                    <Modal.Body>
                        <div className="modal-body viewdetail">
                            <div className="text-center">
                                <h4 className="modal-title mb-3" >E-prescription Summary</h4>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">E-prescription Number</label>
                                    <p>{openModal?.data?.prescriptionNumber}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Date</label>
                                    <p>{moment(openModal?.data?.startDate).tz(userdata?.timezoneName).format('ll')}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Medicine</label>
                                    <p>{openModal?.data?.medicineName}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Medicine type</label>
                                    <p>{openModal?.data?.medicineType}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Standard</label>
                                    <p>{Dosage(openModal?.data)}</p>
                                </div>

                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Strength</label>
                                    <p>{openModal?.data?.doseAmount + openModal?.data?.doseType}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Duration</label>
                                    <p>{openModal?.data?.medicineIntakeDurationAmount + " " + openModal?.data?.medicineIntakeDurationType}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Dosage Instructions</label>
                                    <p>{openModal?.data?.medicineIntakeTime !== "CUSTOM" ? openModal?.data?.medicineIntakeTime : openModal?.data?.medicineIntakeCustomTime}</p>
                                </div>
                                <div className="col-sm-6">
                                    <label className="form-label mb-0">Quantity</label>
                                    <p>{openModal?.data?.medicineIntakeAmount}</p>
                                </div>
                                <div className="col-sm-12">
                                    <label className="form-label mb-0">Notes</label>
                                    <p>{openModal?.data?.notes ? openModal?.data?.notes : "N/A"}</p>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default Overview

