import React, { useEffect, useState } from 'react'
import useTableSorting from '../../../Hooks/useTableSorting'
import { Peginationcomponent } from '../../../Component/Peginationcomponent';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { NoRecordFound } from '../../../Component/NoRecordFound';
import Loading from '../../../Component/Loading';
import useReduxData from '../../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { PAYMENT_HISTORY } from '../../Repository/SettingRepo';
import moment from 'moment-timezone';

const PaymentHistory = () => {
    const [PaymentHistory, setPaymentHistory] = useState([])
    const [paginationTotalCount, setPaginationTotalCount] = useState(0)
    const [Loader, setLoading] = useState(false)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const headCells = [
        { id: "currency", numeric: true, disablePadding: false, label: " Currency", isShorted: true },
        { id: "amount", numeric: true, disablePadding: false, label: "Amount", isShorted: true },
        { id: "Payment Mode", numeric: true, disablePadding: false, label: "Payment Mode", isShorted: true },
        { id: "Payment Date", numeric: true, disablePadding: false, label: "Payment Date", isShorted: true },
        { id: "Status", numeric: true, disablePadding: false, label: " Status", isShorted: true, showLeft: false, },
    ];
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    async function getPlanHistoryList() {
        setLoading(true)
        const { page, rowsPerPage } = controller;
        const payload = {
            page,
            count: rowsPerPage,
            providerId: userData?.userId,
        };
        try {
            let response = await PAYMENT_HISTORY(payload)
            setPaymentHistory(response?.data)
            setPaginationTotalCount(response?.totalCount)
        } catch (error) {
            if (error.message !== "No assigned subscription plan found") toast.error(error.message)
            setPaymentHistory([])
            setPaginationTotalCount(0)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getPlanHistoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {Loader && <Loading />}
            <TableContainer   >
                {PaymentHistory?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table" >
                    {
                        PaymentHistory?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={PaymentHistory?.length} />
                    }
                    <TableBody>
                        {stableSort(PaymentHistory, getComparator(order, orderBy))?.map(
                            (row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return <React.Fragment key={row?.serviceId}>
                                    <TableRow id="hover_tr">
                                    <TableCell className="ps-3" component="th" id={labelId} scope="row" padding="none" >
                                                {`${row?.currency}`}
                                            </TableCell>
                                            <TableCell id={labelId} align="left" > {row?.currencyImage}{row?.amount} </TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentMethod}</TableCell>
                                            <TableCell id={labelId} align="left" >{row?.paymentDate ? moment(row?.paymentDate).format("DD/MM/YYYY") : "-"}</TableCell>
                                            <TableCell id={labelId} align="left" > {row?.paymentStatus}</TableCell>
                                    </TableRow>
                                </React.Fragment>
                            }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <nav className="w-100" aria-label="pagination">
                {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
            </nav>
        </>
    )
}

export default PaymentHistory