import moment from "moment-timezone";
import React from "react";
import Loading from "../../Component/Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import useTableSorting from "../../Hooks/useTableSorting";
import useWaitingList from "./Hooks/useWaitingList";
import useMuiCompontent from "../../Hooks/useMuiCompontent";
import { BookAppointmentPopup, CreateWaitingListModal, RemovePatientModal } from "./Component";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { IoMdAdd } from "react-icons/io";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import { FiRefreshCcw } from "react-icons/fi";
const WaitingList = () => {
  const {
    headCells,
    Loader,
    waitingListData,
    paginationTotalCount,
    controller,
    show2,
    userdata,
    openModal,
    createAppointment,
    patientFilterList,
    clinicList,
    TimeslotList,
    teamList,
    servicesList,
    SlotLodaer,
    isActiveSlot,
    offersList,
    OFERS_DETAILS,
    SERVICES_DETAILS,
    check,
    Staff_DETAILS,
    getservicesList,
    handleRemovePatinet,
    setCheck,
    cancelOffer,
    ApplyOffer,
    applyByCouponCodeOffers,
    setIsactiveSlot,
    handalSearchAbleSelectBox,
    handleOpenModal,
    handleClosedModal,
    setShow2,
    setController,
    getlistPatient,
    getListproviderclinics,
    handleChangeFilter,
    Filter,
    HandleSearchList,
    reset,
    apptBook } = useWaitingList()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
  const { CustomButton } = useMuiCompontent()
  return (
    <>
      {Loader && <Loading />}
      {/* <ToastContainer /> */}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Waiting List</h2>
          </div>
        </header>
        <div className="common-white-bg-box  mx-0 shadow position-relative">

          <header className="body_header d-flex justify-content-between align-items-center py-2 px-2">
            <ul className="headerlist px-0">
              <li className="me-2">
                <input type="text" onChange={handleChangeFilter} value={Filter?.waitingList} placeholder="Search" className="form-control filter_input search_input_width_phone" name="waitingList" />
              </li>
              <li className="search_button_top_padding">
                <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
              </li>
              <li className="mx-2 d-flex justify-content-center align-items-center" >
                <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                  <FiRefreshCcw />
                </IconButton>
              </li>
              <li>
                <CustomButton variant="contained" className="ms-2" startIcon={<IoMdAdd />} onClick={() => { handleOpenModal("addPatient"); getlistPatient(); getListproviderclinics(); getservicesList(userdata?.location?.clinicId) }}>Add Patient</CustomButton>
              </li>
            </ul>
            {/* <ul className="headerlist px-0 phone_hide">
              <li>
                <CustomButton variant="contained"
                  style={{ background: "#f5f5f5", color: "black" }}
                  // onClick={() => { setFiltershow(!filtershow) }}
                  startIcon={<ExitToAppIcon />}
                >export</CustomButton>
              </li>
            </ul> */}
          </header>
          <TableContainer className="text_box_height" >
            {waitingListData.length === 0 &&
              <div className="col-sm-12 mt-3 text-center " >
                <NoRecordFound />
              </div>
            }
            <Table
              id="table_mui"
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              {
                waitingListData?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={waitingListData?.length} />
              }
              <TableBody className="table_overflow_scroll" >
                {stableSort(waitingListData, getComparator(order, orderBy)).map(
                  (item, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return <React.Fragment key={index}><TableRow id="hover_tr"   >
                      <TableCell className="ps-3" id={labelId} scope="row" padding="none" >
                        {moment(item.startDate)?.tz(userdata?.timezoneName)?.format('ll')}
                      </TableCell>
                      <TableCell id={labelId} align="left" >{moment(item.startDate)?.tz(userdata?.timezoneName)?.format('LT')} - {moment(item?.endDate)?.tz(userdata?.timezoneName)?.format('LT')}</TableCell>
                      <TableCell id={labelId} align="left">{item?.doctorFirstName + " " + item?.doctorLastName}</TableCell>
                      <TableCell id={labelId} align="left">{item?.specialisationName}</TableCell>
                      <TableCell id={labelId} align="left">{item?.serviceName}</TableCell>
                      <TableCell id={labelId} align="left">
                        <ul className="m-0 d-flex flex-wrap p-0">
                          {
                            item?.patientDetails?.map((cur, indexOfPatient, currentArray) => {

                              return <li key={cur?.patientId} className="me-1"> {`${cur?.patientFirstName ? cur?.patientFirstName : ""}  ${cur?.patientLastName ? cur?.patientLastName : ""}${currentArray?.length > indexOfPatient + 1 ? "," : " "}`}</li>
                            })
                          }
                        </ul>
                      </TableCell>
                      <TableCell id={labelId} align="right" className="py-0">
                        <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu dropdown-menu-end me-3">
                          <li><button className="dropdown-item" href="#" onClick={() => { handleOpenModal("bookAppointment", item) }}>
                            <i className="fa-solid fa-calendar-check all_icons_color me-2"></i>Book Appointment</button></li>
                          <li><button className="dropdown-item" href="#" onClick={() => { handleOpenModal("removePatient", item) }}>
                            <i className="fa fa-user me-2 all_icons_color me-2"></i>Remove Patient </button></li>
                        </ul>
                      </TableCell>
                    </TableRow>
                    </React.Fragment>
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <nav className=" w-100  " aria-label="pagination">
            {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
          </nav>
        </div>
      </div>
      {/* Appointment Reject*/}
      <RemovePatientModal show={openModal?.removePatient} handleClosedModal={handleClosedModal} handleRemovePatinet={handleRemovePatinet} CustomButton={CustomButton} />
      {/* Appointment Book  */}
      <BookAppointmentPopup show2={show2} setShow2={setShow2} apptBook={apptBook} CustomButton={CustomButton} />
      <CreateWaitingListModal handleClosedModal={handleClosedModal} openModal={openModal}
        patientFilterList={patientFilterList} servicesList={servicesList} teamList={teamList}
        clinicList={clinicList} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer}
        handalSearchAbleSelectBox={handalSearchAbleSelectBox}
        createAppointment={createAppointment} userdata={userdata}
        isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} moment={moment} offersList={offersList}
        applyByCouponCodeOffers={applyByCouponCodeOffers} cancelOffer={cancelOffer} ApplyOffer={ApplyOffer}
        OFERS_DETAILS={OFERS_DETAILS} SERVICES_DETAILS={SERVICES_DETAILS} check={check} setCheck={setCheck}
        Staff_DETAILS={Staff_DETAILS}
        CustomButton={CustomButton}
      />
    </>
  );
};

export default WaitingList;
