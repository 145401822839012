import React from "react";
import Loading from "../../Component/Loading";
import moment from "moment-timezone";
import { TableContainer } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import { NoRecordFound } from "../../Component/NoRecordFound";
import useAppointment from "./Hooks/useAppointment";
import useTableSorting from "../../Hooks/useTableSorting";
import BookAppointment from "./Component/BookAppointment";
import RescheduleAppointment from "./Component/RescheduleAppointment";
import CancelAppointment from "./Component/CancelAppointment";
import FilterAppointment from "./Component/FilterAppointment";
import { IoFilterSharp } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { AppointmentStatus } from "../../Context/constants";
const Appointments = () => {
  const {
    getlistPatient,
    getListproviderclinics,
    setFiltershow,
    setAppData,
    setModalCancel,
    reschedulefun,
    setApptDetails,
    setController,
    setBookAppointmentModal,
    setIsactiveSlot,
    setCheck,
    ApplyOffer,
    navigate,
    applyByCouponCodeOffers,
    cancelOffer,
    setDateFilter,
    setDateRangeDropdown,
    setDate,
    clearAll,
    HandleSearchList,
    listAppointment,
    handalSearchAbleSelectBox,
    getservicesList,
    Staff_DETAILS,
    CustomButton,
    filtershow,
    apptList,
    loader,
    userdata,
    count,
    controller,
    modalCancel,
    cancelAppointment,
    bookAppointmentModal,
    createAppointment,
    clinicList,
    servicesList,
    teamList,
    TimeslotList,
    SlotLodaer,
    isActiveSlot,
    OFERS_DETAILS,
    SERVICES_DETAILS,
    check,
    appointmentReschedule,
    headCells,
    offersList,
    modalReschdule,
    apptDetails,
    dateFilter,
    dateRangeDropdown,
    date,
    clinicSelected,
    teamSelected,
    patientSelected,
    appointmentFilterData,
    serviceSelected,
    statusSelected,
    patientFilterList,
    clossOffcanvas
  } = useAppointment()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

  return <>
    {loader && <Loading />}
    <div className="common-component-box">
      <header className="body_header">
        <div className="d-flex justify-content-between align-items-center w-100 position-relative ">
          <h2 className="page_name">Appointments List</h2>
        </div>
      </header>
      <div className=" common-white-bg-box mx-0 shadow px-2 d-flex justify-content-between flex-column">
        <div>
          <header className="body_header">
            <div className="d-flex justify-content-between align-items-center w-100 position-relative  ">
              <CustomButton variant="contained"
                startIcon={<IoMdAdd />}
                onClick={() => { setBookAppointmentModal(true); getlistPatient(); getListproviderclinics(); getservicesList(userdata?.location?.clinicId) }} >Create Appointment</CustomButton>
              <ul className="headerlist px-0">
                <li>
                  <div className="dropdown-center ms-2">
                    <CustomButton variant="contained"
                      style={{ background: "#f5f5f5", color: "black" }}
                      onClick={() => { setFiltershow(!filtershow) }}
                      startIcon={<IoFilterSharp />}
                    >Filters</CustomButton>
                  </div>
                </li>
                {/* <li>
                  <div className="dropdown-center ms-2">
                    <CustomButton variant="contained"
                      style={{ background: "#f5f5f5", color: "black" }}
                      // onClick={() => { setFiltershow(!filtershow) }}
                      startIcon={<ExitToAppIcon />}
                    >export</CustomButton>
                  </div>
                </li> */}
              </ul>
              {/* <div className={`APPOINTMENT_FILTER ${filtershow ? "SHOW" : "HIDE"} `}>
            </div> */}
            </div>
          </header>
          <TableContainer  >
            {apptList?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /></div>}
            <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
              {
                apptList?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={apptList?.length} />
              }
              <TableBody className="table_overflow_scroll" >
                {stableSort(apptList, getComparator(order, orderBy))?.map((item, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return <React.Fragment key={index}><TableRow id="hover_tr">
                    <TableCell className="ps-3" id={labelId} scope="row" padding="none">
                      {item?.appointmentNumber}
                    </TableCell>
                    <TableCell id={labelId} align="left">
                      {moment(item?.startDate)?.tz(userdata?.timezoneName)?.format('ll')}
                    </TableCell>
                    <TableCell id={labelId} align="left" >{moment(item?.startDate)?.tz(userdata?.timezoneName)?.format('LT')} - {moment(item?.endDate).tz(userdata?.timezoneName)?.format('LT')}</TableCell>
                    <TableCell id={labelId} align="left">{(item?.patientFirstName ? item?.patientFirstName : "") + " " + (item?.patientLastName ? item?.patientLastName : "")}</TableCell>
                    <TableCell id={labelId} align="left">{item?.specialisationName ? item?.specialisationName : '--'}</TableCell>
                    <TableCell id={labelId} align="left">{item?.serviceName ? item?.serviceName : '--'}</TableCell>
                    <TableCell id={labelId} align="left">{item?.Status}</TableCell>
                    <TableCell id={labelId} align="right">
                      <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                      <ul className="dropdown-menu dropdown-menu-end me-3 ">
                        <li className="cursor"><button className="dropdown-item"
                          onClick={() => {
                            navigate(`/appointment/details/${item?.appointmentId}`)
                          }} ><i className="fa-solid fa-eye me-2 all_icons_color"></i>View Appointment</button></li>
                        {
                          !(item?.Status === AppointmentStatus.CANCELLED || item?.Status === AppointmentStatus.CHECKEDOUT) && <li className="cursor"><button className="dropdown-item"
                            onClick={() => {
                              setAppData(item);
                              setModalCancel((item?.Status === AppointmentStatus.CANCELLED || item?.Status === AppointmentStatus.CHECKEDOUT) ? false : true)
                            }}><i className="fa-solid fa-circle-xmark me-2 all_icons_color"></i>Cancel Appointment</button></li>

                        }

                        {
                          !(item?.Status === AppointmentStatus.CANCELLED || item?.Status === AppointmentStatus.RESCHEDULED || item?.Status === AppointmentStatus.CHECKEDOUT) && <li className="cursor"><button className="dropdown-item"
                            onClick={() => { reschedulefun(item); setApptDetails(item) }}><i className="fa-solid fa-calendar-check all_icons_color me-2"></i>Reschedule Appointment </button></li>
                        }

                      </ul>
                    </TableCell>
                  </TableRow>
                  </React.Fragment>
                }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <nav className=" w-100  " aria-label="pagination">
          {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
        </nav>
      </div>
    </div >
    <CancelAppointment modalCancel={modalCancel} clossOffcanvas={clossOffcanvas} cancelAppointment={cancelAppointment} CustomButton={CustomButton} />
    <BookAppointment patientFilterList={patientFilterList} servicesList={servicesList} teamList={teamList}
      clinicList={clinicList} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer} bookAppointmentModal={bookAppointmentModal}
      clossOffcanvas={clossOffcanvas} handalSearchAbleSelectBox={handalSearchAbleSelectBox}
      createAppointment={createAppointment} userdata={userdata}
      isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} moment={moment} offersList={offersList}
      applyByCouponCodeOffers={applyByCouponCodeOffers} cancelOffer={cancelOffer} ApplyOffer={ApplyOffer}
      OFERS_DETAILS={OFERS_DETAILS} SERVICES_DETAILS={SERVICES_DETAILS} check={check} setCheck={setCheck}
      Staff_DETAILS={Staff_DETAILS}
    />
    <RescheduleAppointment clossOffcanvas={clossOffcanvas} modalReschdule={modalReschdule} apptDetails={apptDetails} userdata={userdata}
      moment={moment} appointmentReschedule={appointmentReschedule} TimeslotList={TimeslotList} SlotLodaer={SlotLodaer}
      isActiveSlot={isActiveSlot} setIsactiveSlot={setIsactiveSlot} CustomButton={CustomButton}
    />
    <FilterAppointment filtershow={filtershow} setFiltershow={setFiltershow} setDateRangeDropdown={setDateRangeDropdown}
      dateFilter={dateFilter} setDateFilter={setDateFilter} dateRangeDropdown={dateRangeDropdown} moment={moment}
      date={date} setDate={setDate} appointmentFilterData={appointmentFilterData} clinicSelected={clinicSelected}
      handalSearchAbleSelectBox={handalSearchAbleSelectBox} userdata={userdata} teamSelected={teamSelected} patientSelected={patientSelected}
      serviceSelected={serviceSelected} statusSelected={statusSelected} CustomButton={CustomButton} clearAll={clearAll} HandleSearchList={HandleSearchList} listAppointment={listAppointment}
    />
  </>;
};

export default Appointments;
