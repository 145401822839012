
import { getProviderDashboardDetails, getStaffDashboardDetails, } from "../../Context/api"
import handleError from "../../Utils/handleError"
import { axiosInstance } from "../../Context/CraeteAxios"
export const GET_PROVIDER_DASHBOARD_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getProviderDashboardDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}
export const GET_STAFF_DASHBOARD_DETAILS = async (body) => {
    try {
        let response = await axiosInstance.post(getStaffDashboardDetails(), body)
        return response.data
    }
    catch (error) {
        handleError(error)
    }
}

