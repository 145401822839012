import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Button, ButtonGroup, TableContainer } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import Loading from "../../Component/Loading";
import moment from "moment-timezone";
import useTableSorting from "../../Hooks/useTableSorting";
import useNotification from "./Hooks/useNotification";
import { NoRecordFound } from "../../Component/NoRecordFound";
import ReadMoreButton from "../../Component/ReadMoreButton";

const Notifications = () => {
  const { headCells, setFilter, setController, notificationList, controller, loader, notificationcount, filter, readNotification, paginationTotalCount, CustomButton, } = useNotification()
  const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)

  return (
    <>
      {loader && <Loading />}
      <div className="common-component-box">
        <header className="body_header" >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="page_name">Notifications</h2>
          </div>
        </header>
        <div className=" d-flex justify-content-between flex-column  common-white-bg-box mx-0 shadow  p-2">
          <div>
            <header className="body_header mb-0" >
              <div className="d-flex justify-content-between align-items-center">
                <div className="headerlist d-flex justify-content-end px-0">
                  <div className="me-3">
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      <Button variant="outlined" onClick={() => { setFilter("UNREAD"); setController((pre) => ({ ...pre, page: 1 })) }} style={{ background: ` ${filter === "UNREAD" ? "#38C4B9" : "#fff"} `, color: ` ${filter === "UNREAD" ? "#fff" : "#38C4B9"} `, border: filter === "UNREAD" ? "done" : "1px solid #38C4B9", padding: "5px 8px", fontSize: "13px" }}>unread</Button>
                      <Button variant="contained" onClick={() => setFilter("ALL")} style={{ background: ` ${filter === "ALL" ? "#38C4B9" : "#fff"} `, color: ` ${filter === "ALL" ? "#fff" : "#38C4B9"} `, padding: "5px 8px", fontSize: "13px", border: filter === "ALL" ? "done" : "1px solid #38C4B9" }}> all</Button>
                    </ButtonGroup>
                  </div>
                  <div>
                    <CustomButton onClick={(e) => { notificationcount > 0 && readNotification("ALL"); setController((pre) => ({ ...pre, page: 1 })) }} variant="contained" className="mark_all_btn">Mark all read</CustomButton>
                  </div>
                </div>
              </div>
            </header>
            <TableContainer >
              {notificationList?.length === 0 &&
                <div className="col-sm-12 mt-3 text-center " >
                  <NoRecordFound />
                </div>
              }
              <Table
                id="table_mui"
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                {
                  notificationList.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={notificationList.length} />
                }
                <TableBody>
                  {stableSort(notificationList, getComparator(order, orderBy)).map(
                    (row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return <React.Fragment key={index}>
                        <TableRow onClick={(e) => row?.readStatus !== true && readNotification(row?.notificationId)} id="hover_tr" style={{ backgroundColor: `${row?.readStatus !== true ? "#f5f5f5" : "transparent"}`, cursor: "pointer" }} >
                          <TableCell className="ps-3" id={labelId} scope="row" padding="none"  >
                            {controller.rowsPerPage * (controller.page - 1) + index + 1}
                          </TableCell>
                          <TableCell id={labelId} align="left" style={{ whiteSpace: "nowrap" }}  >{row?.title}</TableCell>
                          <TableCell id={labelId} align="left" style={{ whiteSpace: "normal" }} >
                            <ReadMoreButton text={row?.message} maxLength={100} />
                          </TableCell>
                          <TableCell id={labelId} align="right" style={{ whiteSpace: "nowrap" }} >{row?.date ? moment(row?.date).format('ll') : "N/A"} </TableCell>
                        </TableRow>
                      </React.Fragment>
                    }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <nav className=" w-100" aria-label="pagination">
            {paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />}
          </nav>

        </div>

      </div>
    </>
  );
};

export default Notifications;
