import React from 'react'
import { Errors } from '../../Component/Error';
import moment from "moment";
import {   Slider } from '@mui/material';
import Loading from '../../Component/Loading';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import useEditOffers from './Hooks/useEditOffers';
import JoditEditor from 'jodit-react';
import { GenderOptions } from '../../Context/constants';
import { SearchAbleSelectBox } from '../../Component/Inputs';
const EditOffers = () => {
    const {
        // imgUrl,
        // id,
        // UploadImage,
        // setImgUrl,
        // FileRef,
        loader,
        formik,
        serviceListData,
        minimumdate,
        check,
        Agecheck,
        setCheck,
        setAgeCheck,
         handalSearchAbleSelectBox,
    } = useEditOffers()
    const { GreenSwitch, CustomButton } = useMuiCompontent()
    return (
        <>
            {loader && <Loading />}
            <div className="common-component-box">
                <header className="body_header" >
                    <div className="d-flex justify-content-start align-items-center">
                        <h2>Update Offer</h2>
                    </div>
                </header>
                <div className="row common-white-bg-box mx-0 shadow position-relative px-2">
                    {/* <div className="col-4 m-auto mt-4">
                        <div className="bodrerer mb-4" style={{ border: `${!imgUrl ? "2px dashed #aaaaaa" : "none"}` }}>
                            <label htmlhtmlhtmlfor="inputTag">
                                <AiOutlineCloudUpload className="fs-4" />
                                {imgUrl && <img src={OfferImageFilePath + imgUrl} alt="" />}
                                <input ref={FileRef} id="inputTag" type="file" name="file" onChange={(e) => UploadImage(e, FileRef)} />
                                <br />
                                Upload an Image
                                <br />
                                <span id="imageName"></span>
                            </label>
                            {imgUrl && <DeleteIcons setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "OFFER", id: id }} isDeleted={true} />}
                        </div>
                    </div> */}
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Name<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input
                                    maxLength={50}
                                    type="text"
                                    className="form-control"
                                    name="offerName"
                                    value={formik.values.offerName}
                                    onChange={(e) => formik.setFieldValue("offerName", e.target.value)} />
                                <Errors formikfun={formik} name={"offerName"} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Display Picture</label>
                            </div>
                            <div className="col-4 d-flex justify-content-between align-items-center">
                                <Badge
                                    style={{ background: "#fff" }}
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    badgeContent={
                                        <SmallAvatar alt="Remy Sharp"  >
                                            <MdOutlineCameraAlt onClick={() => FileRef.current.click()} style={{ cursor: "pointer" }} >
                                            </MdOutlineCameraAlt>
                                            <input type="file" ref={FileRef} className='d-none ' accept="image/*" name="file" onChange={(e) => UploadImage(e, FileRef)} />
                                        </SmallAvatar>
                                    }
                                >
                                    <Avatar  sx={{ width: 80, height: 80 }} src={OfferImageFilePath + imgUrl} >
                                    </Avatar>
                                </Badge>
                                {imgUrl && <DeleteProfile setImgUrl={setImgUrl} deletedData={{ fileName: imgUrl, type: "OFFER", id: null }} isDeleted={true} />}
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Coupon Code<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input
                                    maxLength={10}
                                    type="text"
                                    className="form-control"
                                    name="offerName"
                                    value={formik.values.couponCode}
                                    onChange={(e) => formik.setFieldValue("couponCode", e.target.value)} />
                                <Errors formikfun={formik} name={"couponCode"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Service<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    multiple={true}
                                    option={serviceListData}
                                    getOptionLabel="serviceName"
                                    isOptionEqualToValue="serviceId"
                                    value={formik?.values?.serviceId}
                                    name="serviceId"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={formik} name={"serviceId"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Gender<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <SearchAbleSelectBox
                                    multiple={true}
                                    option={GenderOptions}
                                    getOptionLabel="label"
                                    isOptionEqualToValue="value"
                                    value={formik?.values?.audienceGender}
                                    name="audienceGender"
                                    handalSearchAbleSelectBox={handalSearchAbleSelectBox}
                                />
                                <Errors formikfun={formik} name={"audienceGender"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3 Slider_offers">
                                <label className="form-label mb-0">
                                    <input className="form-check-input me-2" type="checkbox" defaultChecked={Agecheck} onClick={() => {
                                        setAgeCheck(!Agecheck);
                                        formik?.setFieldValue("age", [0, 0])
                                    }} value="" id="flexCheckDefault" />
                                    Age Group </label>
                            </div>
                            <div className="col-4">
                                <Slider
                                    disabled={Agecheck === true ? false : true}
                                    style={{ color: "#38C4B9" }}
                                    getAriaLabel={() => 'Temperature range'}
                                    value={formik?.values?.age}
                                    onChange={(event, newValue) => formik?.setFieldValue("age", newValue)}
                                    valueLabelDisplay="auto"
                                    className='w-100 ms-2'
                                    max={150}
                                />
                                {
                                    Agecheck && <p>{`${formik?.values?.age[0]} To ${formik?.values?.age[1]}`}</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Date Range<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-2">
                                <input type="date" min={minimumdate} className="form-control position-relative" name="dateFrom" id="datefield" value={formik.values.dateFrom?moment(formik.values.dateFrom).format("YYYY-MM-DD"):""}
                                    onChange={(e) => formik.setFieldValue("dateFrom", e.target.value)} />
                                <Errors formikfun={formik} name={"dateFrom"} />
                            </div>
                            <div className="col-2">
                                <input type="date" min={minimumdate} className="form-control position-relative  " name="dateTo" value={formik.values.dateTo?moment(formik.values.dateTo).format("YYYY-MM-DD"):""}
                                    onChange={(e) => formik.setFieldValue("dateTo", e.target.value)} />
                                <Errors formikfun={formik} name={"dateTo"} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Discount Type<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <select className="form-select" value={formik?.values?.discountType}
                                    onChange={(e) => {
                                        formik.setFieldValue("discountType", e.target.value);
                                        formik.setFieldValue("maximumValue", "")
                                        formik.setFieldValue("percentage", "")
                                    }} >
                                    <option hidden value="">Select Discount Type</option>
                                    <option value="FLAT" >Flat </option>
                                    <option value="PERCENTAGE">Percentage</option>
                                </select>
                                <Errors formikfun={formik} name={"discountType"} />
                            </div>
                        </div>
                    </div> */}
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Discount Type<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <select className="form-select" value={formik?.values?.discountType}
                                    onChange={(e) => {
                                        formik.setFieldValue("discountType", e.target.value);
                                        formik.setFieldValue("maximumValue", "")
                                        formik.setFieldValue("percentage", "")
                                    }} >
                                    <option hidden value="">Select Discount Type</option>
                                    <option value="FLAT" >Flat </option>
                                    <option value="PERCENTAGE">Percentage</option>
                                </select>
                                <Errors formikfun={formik} name={"discountType"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Minimum Value<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="number" className="form-control" name="actualPrice"
                                    value={formik?.values?.minimumCartValue}
                                    onChange={(e) => formik.setFieldValue("minimumCartValue", e.target.value)}
                                    maxLength={6} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                />
                                <Errors formikfun={formik} name={"minimumCartValue"} />
                            </div>
                        </div>
                    </div>
                    {formik?.values?.discountType === "FLAT" ? <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0"> Discount Amount<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="number" className="form-control" name="actualPrice"
                                    value={formik?.values?.discountAmount}
                                    onChange={(e) => formik.setFieldValue("discountAmount", e.target.value)}
                                    maxLength={6} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                />
                                <Errors formikfun={formik} name={"discountAmount"} />
                            </div>
                        </div>
                    </div> :
                        <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                            <div className="row">
                                <div className="col-3">
                                    <label className="form-label mb-0">Percentage<span className='Star_Mark'>*</span></label>
                                </div>
                                <div className="col-4">
                                    <input type="number" className="form-control" name="percentage"
                                        value={formik?.values?.percentage}
                                        disabled={formik?.values?.discountType === "FLAT" ? true : false}
                                        onChange={(e) => formik.setFieldValue("percentage", e.target.value)}
                                        maxLength={3} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }} />
                                    <Errors formikfun={formik} name={"percentage"} />
                                </div>
                            </div>
                        </div>

                    }
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Maximum Discount upto Value<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <input type="number" className="form-control" name="actualPrice"
                                    value={formik?.values?.maxDiscountUpto}
                                    disabled={formik?.values?.discountType === "FLAT" ? true : false}
                                    onChange={(e) => formik.setFieldValue("maxDiscountUpto", e.target.value)}
                                    maxLength={6} onInput={(e) => { if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength); }}
                                />
                                <Errors formikfun={formik} name={"maxDiscountUpto"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Description<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-4">
                                <textarea rows="2" className="form-control" value={formik.values.description} name="description"
                                    onChange={(e) => formik.setFieldValue("description", e.target.value)} />
                                <Errors formikfun={formik} name={"description"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">Terms and Conditions<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-9">
                                <JoditEditor
                                    value={formik?.values?.terms}
                                    tabIndex={1}
                                    onBlur={newContent => formik.setFieldValue("terms", newContent)}
                                    onChange={newContent => formik.setFieldValue("terms", newContent)}
                                />
                                <Errors formikfun={formik} name={"terms"} />
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12  py-4 " style={{ borderBottom: "2px solid #ededed" }}>
                        <div className="row">
                            <div className="col-3">
                                <label className="form-label mb-0">{check ? "Active" : "inActive"}<span className='Star_Mark'>*</span></label>
                            </div>
                            <div className="col-9">
                                <GreenSwitch name="active" defaultChecked={check} onClick={() => setCheck(!check)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-end py-3">
                        <CustomButton variant="contained" onClick={formik.handleSubmit} > Save</CustomButton>
                    </div>

                </div>
            </div>

        </>
    )
}

export default EditOffers
