import React from 'react';
import Backdrop from "@mui/material/Backdrop";
export default function Loading() {
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        {/* <CircularProgress color="inherit" /> */}
        <div className="blocks ">
          <div className="block orange"></div>
          <div className="block blue"></div>
        </div>
      </Backdrop>
    </div>

  );
}
