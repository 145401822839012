import { useCallback, useEffect, useState } from "react";
import useReduxData from '../../../Hooks/useReduxData'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import '../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { cancelApptValidation, createAppointmentStaffValidation, createAppointmentValidation } from "../../../Context/Validation";
import { AppointmentStatus, RoleTitle } from "../../../Context/constants";
import useMuiCompontent from "../../../Hooks/useMuiCompontent";
import { useNavigate } from "react-router-dom";
import { GET_BASIC_DETAILS } from "../../Repository/ProfileRepo";
import { APPOINTMENT_LIST, CHANGE_APPOINTMENT_STATUS, INSERT_APPOINTMENT, TIME_SLOT_LIST } from "../../Repository/AppointmentsRepo";
import { PATIENT_LIST } from "../../Repository/PatientRepo";
import { SERVICE_LIST } from "../../Repository/ExpertiseRepo";
import { DELETE_UN_AVAILABILITY } from "../../Repository/AvailabilityRepo";
// import { addAppointmentDetails } from "../../../Redux/ReducerFile/AppointmentDetails";
const useCalendar = () => {
  const { reduxData: userdata, } = useReduxData("userDataRDX")
  const localizer = momentLocalizer(moment)
  const calendarStyle = (event, start, end, isSelected) => {
    var backgroundColor = "#fff";
    // var backgroundColor = event.title === "APPOINTMENT" ? "#fff" : "#ABB2B9";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'black',
      border: '1px solid #38C4B9',
    };
    return {
      style: style
    };
  }

  const myEventsList = (EventsList) => {
    const backgroundColor = EventsList?.type === "APPOINTMENT" ? '#38C4B9' : 'gray';
    const color = EventsList.color ? EventsList.color : '#fff';
    return { style: { backgroundColor, color } }
  }
  const [loader, setLoader] = useState(false)
  const [SlotLodaer, setSlotLodaer] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [modalCancel, setModalCancel] = useState(false)
  const [modalReschdule, setModalReschdule] = useState(false);
  const [OpenCreateModal, setOpenCreateModal] = useState(false)
  const [UnavailabilityDetele, SetUnavailabilityDetele] = useState(false);
  const [bookAppointmentModal, setBookAppointmentModal] = useState(false)
  const [check, setCheck] = useState(false)
  const [isActiveSlot, setIsactiveSlot] = useState(null)
  const [calendarList, setCalendarList] = useState([])
  const [patientFilterList, setPatientFilterList] = useState([])
  const [servicesList, setServicesList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [TimeslotList, setTimeSlotList] = useState([])
  const [apptDetails, setApptDetails] = useState({})
  const [offersList, setOffersList] = useState([])
  const [filter, setFilter] = useState("month")
  const [dayFilter, setDayFilter] = useState(new Date())
  const [filterStaffList, setFilterStaffList] = useState([])
  const [filterStaffValue, SetFilterStaffValue] = useState([{ name: "All", id: "ALL" }])
  const [selectedSlotTime, SetselectedSlotTime] = useState({
    startTime: null,
    endTime: null,
  })
  const [weekfilter, setWeekFilter] = useState({
    start: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()))).startOf("week").utc(),
    end: moment(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6))).endOf("week").utc(),
  })
  const [monthFilter, setMonthFilter] = useState({ start: moment().startOf('month').startOf('week'), end: moment().endOf('month').endOf('week') })

  const navigate = useNavigate()
  const { CustomButton } = useMuiCompontent()
  const curDateTime = moment(new Date()).startOf('day').utc()
  const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
  useEffect(() => {
    getAppointmentCalendarList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, filterStaffValue, weekfilter])
  // handle change day month week
  const handleChangeView = (view) => {
    setFilter(view)
  }
  //  handal select event 
  const handleSelectEvent = useCallback((event) => {
    setApptDetails(event);
    if (event.type === "APPOINTMENT") {
      setViewModal(true);
    } else {
      SetUnavailabilityDetele(true);
    }
  }, []);
  // handal change today next button and back button
  const onRangeChange = (e) => {
    setWeekFilter((pre) => ({
      ...pre,
      start: e[0],
      end: e[e.length - 1]
    }))
    setMonthFilter((pre) => ({
      ...pre,
      start: e.start,
      end: e.end
    }))
    setDayFilter((e[0]))
  }
  // view 
  const opneviewfun = (event) => {
    switch (event) {
      case "view":
        navigate(`/appointment/details/${apptDetails?.appointmentId}`)
        setViewModal(false)
        break;
      case "cancel":
        if (apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) return toast.warn("Your appointment already cancel")
        if (apptDetails?.Status !== AppointmentStatus.CANCELLED) {
          setViewModal(false)
          setModalCancel(true)
        }
        break;
      case "Reschedule":
        setViewModal(false)
        reschedulefun()
        if (apptDetails?.Status === AppointmentStatus.CANCELLED) return toast.warn("Your appointment already cancel")
        if (apptDetails?.Status === AppointmentStatus.RESCHEDULED) return toast.warn("Your appointment already reschedule")
        if (apptDetails?.Status === AppointmentStatus.CHECKEDOUT) return toast.warn("Your appointment already check out")
        break;
      default:
        console.warn("Unhandled event type:", event);
    }
  }
  // this is funcation for set Field Value in reshedule all inputs
  const reschedulefun = () => {
    setModalReschdule(apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.RESCHEDULED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT ? false : true)
    if ((apptDetails?.Status === AppointmentStatus.CANCELLED || apptDetails?.Status === AppointmentStatus.RESCHEDULED || apptDetails?.Status === AppointmentStatus.CHECKEDOUT) === false) {
      appointmentReschedule?.setFieldValue("clinicId", apptDetails.clinicId)
      appointmentReschedule?.setFieldValue("serviceId", apptDetails.serviceId)
      appointmentReschedule?.setFieldValue("teamMemberId", apptDetails.teamMemberId)
      appointmentReschedule?.setFieldValue("apptDate", apptDetails.startDate)
      // appointmentReschedule?.setFieldValue("apptTimeIn", apptDetails.startDate)
      // appointmentReschedule?.setFieldValue("apptTimeOut", apptDetails.endDate)
      appointmentReschedule?.setFieldValue("visitType", apptDetails.visitType)
    }
  }
  //  appointment create
  const createAppointment = useFormik({
    initialValues: {
      patientName: [],
      service: [],
      clinic: [],
      date: '',
      startTime: '',
      endTime: '',
      teamMember: [],
      visitType: '',
      couponCode: "",
    },
    validationSchema: userdata.roleTitle === 'SERVICE_PROVIDER' ? createAppointmentValidation : createAppointmentStaffValidation,
    onSubmit: async (values) => {
      if (values.patientName?.length > SERVICES_DETAILS?.noOfPatients) {
        return toast.warn(`You can select ${SERVICES_DETAILS?.noOfPatients} patient `)
      }
      const data = {
        staffId: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? Staff_DETAILS?.userId : userdata?.userId,
        providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
        frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
        clinicId: userdata?.location?.clinicId,
        serviceDetails: values.service[0],
        visitType: values.visitType,
        clinicName: userdata?.location?.clinicName,
        startDate: values.startTime,
        endDate: values.endTime,
        status: check ? AppointmentStatus.WAITING : AppointmentStatus.BOOKED,
        currrentDateTime: curDateTime,
        totalPrice: OFERS_DETAILS?.discountPrice || SERVICES_DETAILS?.fixedPrice,
        roleTitle: userdata.roleTitle,
        timezoneName: userdata?.timezoneName,
        offerId: OFERS_DETAILS?.offerId,
        patientDetails: values?.patientName?.map((cur) => ({ patientId: cur?.id, firstName: cur?.firstName, lastName: cur?.lastName })),
      }
      setLoader(true)
      try {
        let response = await INSERT_APPOINTMENT(data)
        toast.success(response.message)
        createAppointment.resetForm()
        setTimeSlotList([])
        setBookAppointmentModal(false)
        getAppointmentCalendarList()
        setIsactiveSlot(null)
        setCheck(false)
        setOffersList([])
        setLoader(false)
        setOpenCreateModal(false)

      } catch (error) {
        toast.error(error?.message)
        setLoader(false)
      }
    }

  })
  // all details of selectd offers patient services staff clinic
  const OFERS_DETAILS = offersList?.find((cur, index) => cur?.discountPrice && cur?.discountPrice)
  const SERVICES_DETAILS = createAppointment.values?.service[0]
  const Staff_DETAILS = createAppointment.values.teamMember[0]
  // reschedule appointment
  const appointmentReschedule = useFormik({
    initialValues: {
      serviceId: '',
      clinicId: '',
      teamMemberId: '',
      apptType: '',
      apptDate: '',
      apptTimeIn: '',
      apptTimeOut: '',
      visitType: '',
    },
    validationSchema: '',
    onSubmit: async (values) => {
      const data = {
        providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
        staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
        frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
        startDate: values.apptTimeIn,
        endDate: values.apptTimeOut,
        appointmentId: apptDetails.appointmentId,
        changeType: AppointmentStatus?.RESCHEDULED,
        roleTitle: userdata.roleTitle,
      }
      if (!values.apptTimeIn) {
        return toast.warn(`Please select time slot`)
      }
      setLoader(true)
      try {
        let response = await CHANGE_APPOINTMENT_STATUS(data)
        toast.success(response.message)
        getAppointmentCalendarList()
        setModalReschdule(false)
        appointmentReschedule.resetForm()
        setTimeSlotList([])
        setLoader(false)
      } catch (error) {
        toast.error(error.message);
        setLoader(false)
      }
    }

  })
  // handle select slot
  const handleSelectSlot = (selectSlot) => {
    console.log(selectSlot,"selectSlot")
    setOpenCreateModal(true)
    createAppointment.setFieldValue('date', selectSlot?.start)
    SetselectedSlotTime({
      startTime: selectSlot?.start,
      endTime: selectSlot?.end,
    })
  }
  //#region list appointment
  const getAppointmentCalendarList = async () => {
    let dayData = {
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      clinics: userdata?.location?.clinicId ? [userdata?.location?.clinicId] : [],
      teams: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) && filterStaffValue[0]?.id !== "ALL" ? filterStaffValue?.map((cur, index) => cur?.id) : [],
      patients: [],
      services: [],
      status: [],
      roleTitle: userdata.roleTitle,
      startDate: moment(dayFilter).startOf("day").utc(),
      endDate: moment(dayFilter).endOf("day").utc(),
      sendUnavailability: true,
    }
    const weekData = {
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      clinics: userdata?.location?.clinicId ? [userdata?.location?.clinicId] : [],
      teams: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) && filterStaffValue[0]?.id !== "ALL" ? filterStaffValue?.map((cur, index) => cur?.id) : [],
      patients: [],
      services: [],
      status: [],
      roleTitle: userdata.roleTitle,
      startDate: moment(weekfilter?.start).startOf("day").utc(),
      endDate: moment(weekfilter?.end).endOf("day").utc(),
      sendUnavailability: true,
    }
    const monthData = {
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      clinics: userdata?.location?.clinicId ? [userdata?.location?.clinicId] : [],
      teams: (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) && filterStaffValue[0]?.id !== "ALL" ? filterStaffValue?.map((cur, index) => cur?.id) : [],
      patients: [],
      services: [],
      status: [],
      roleTitle: userdata.roleTitle,
      startDate: moment(monthFilter?.start).startOf("day").utc(),
      endDate: moment(monthFilter?.end).endOf("day").utc(),
      sendUnavailability: true,
    }
    setLoader(true)
    try {
      let response = await APPOINTMENT_LIST(filter === "day" ? dayData : filter === "month" ? monthData : weekData)
      let allEvent = response?.data?.map((cur, index) => {
        let title = cur?.title === "APPOINTMENT" ? `${(cur?.patientFirstName ? cur?.patientFirstName : "")}  ${(cur?.patientLastName ? cur?.patientLastName : "")} (${cur?.Status?.charAt(0).toUpperCase() + cur?.Status?.slice(1)?.toLowerCase()})` :
          ` ${(cur?.doctorFirstName && cur?.doctorLastName) ? `${cur?.doctorFirstName} ${cur?.doctorLastName} (Un-Available)` : `${cur?.clinicName} (Un-Available  Clinic)`}   `
        return { ...cur, start: new Date(cur?.startDate), end: new Date(cur?.endDate), title, type: cur?.title }
      })
      setCalendarList(allEvent)
      setLoader(false)
    } catch (error) {
      toast.error(error.message);
      setCalendarList([])
      setLoader(false)
    }
  }
  // patient list
  const getlistPatient = async () => {
    let payload = {
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      patientFilter: "ALL",
      roleTitle: userdata?.roleTitle,
    }
    setLoader(true)
    try {
      let response = await PATIENT_LIST(payload)
      setPatientFilterList(response.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setPatientFilterList([])
    }
  }
  // staff list
  const getliststaff = async (clinicId, serviceId) => {
    setLoader(true)
    const payload = {
      getStaffList: true,
      activeStatus: true,
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
      clinicId,
      serviceId,
      invitationStatus: "ACCEPTED"
    };
    try {
      let response = await GET_BASIC_DETAILS(payload)
      setTeamList(response.data)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setTeamList([])
    }
  }
  //  staff list for filter
  const staffListForFilter = async () => {
    setLoader(true)
    const payload = {
      getStaffList: true,
      activeStatus: true,
      providerId: userdata?.userId,
      clinicId: userdata?.location?.clinicId,
      invitationStatus: "ACCEPTED"
    };
    try {
      let response = await GET_BASIC_DETAILS(payload)
      setFilterStaffList([{ name: "All", id: "ALL" }, ...response.data?.map((cur, index) => {
        return { name: `${(cur?.firstName ? cur?.firstName : "")} ${(cur?.lastName ? cur?.lastName : "")}`, id: cur.userId }
      })])
      setLoader(false)
    } catch (error) {
      setLoader(false)
      setFilterStaffList([{ name: "All", id: "ALL" }])
    }
  }
  // service list
  const getservicesList = async (clinicId) => {
    setLoader(true)
    const payload = {
      clinicId: clinicId,
      providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
      frontdeskId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.userId : undefined,
      staffId: userdata.roleTitle === RoleTitle.STAFF ? userdata?.userId : undefined,
      roleTitle: userdata.roleTitle === RoleTitle.STAFF ? RoleTitle.STAFF : undefined,
    };

    try {
      let response = await SERVICE_LIST(payload)
      setServicesList(response?.data?.map((cur) => ({ ...cur, specializationName: cur?.specialization?.specializationName })))
      setLoader(false)
    } catch (error) {
      setServicesList([])
      setLoader(false)
    }

  }
  // get offers list
  // const getOffersList = () => {
  //   let data = {
  //     userId: userdata.userId,
  //     serviceId: createAppointment.values?.service,
  //     noOfPatients: SERVICES_DETAILS?.noOfPatients,
  //     patientAge: SERVICES_DETAILS?.noOfPatients == 1 ? PATIENT_DETAILS?.patientage : null,
  //     patientGender: SERVICES_DETAILS?.noOfPatients == 1 ? PATIENT_DETAILS?.patientGender : null,
  //     appointmentDate: new Date(createAppointment.values.date)
  //   }
  //   axiosInstance.post(offersListForApptApi(), { ...data }).then((res) => {
  //     if (res.data.status === "200") {
  //       setOffersList(res?.data?.data)
  //     } else if (res.data.status === "500") {
  //       toast.error(commonMessages.NetworkError);
  //     } else {
  //       setOffersList([])
  //     }
  //   }).catch(() => {
  //     toast.error(commonMessages.NetworkError)
  //   })
  // }
  // cancel appt
  const cancelAppointment = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: cancelApptValidation,
    onSubmit: async (values) => {
      const payload = {
        changeType: AppointmentStatus.CANCELLED,
        cancellationReason: values.reason,
        roleTitle: userdata.roleTitle,
        appointmentId: apptDetails?.appointmentId,
        providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata?.roleTitle === RoleTitle?.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
        frontdeskId: userdata?.roleTitle === RoleTitle?.FRONT_DESK ? userdata?.userId : undefined,
      }
      setLoader(true)
      try {
        let response = await CHANGE_APPOINTMENT_STATUS(payload)
        cancelAppointment.resetForm()
        toast.success(response.message)
        getAppointmentCalendarList()
        cancelAppointment.resetForm()
        setModalCancel(false)
        setLoader(false)
      } catch (error) {
        toast.error(error.message);
        setLoader(false)
      }
    }
  })
  // delete unavailability
  const handleClose = async (event) => {
    if (event === "YES") {
      const data = { unavailabilityId: apptDetails?.appointmentNumber }
      setLoader(true)
      try {
        let response = await DELETE_UN_AVAILABILITY(data)
        setLoader(false)
        toast.success(response.message)
        SetUnavailabilityDetele(false)
        getAppointmentCalendarList()
      } catch (error) {
        toast.error(error.message)
        setLoader(false)
      }
    } else {
      SetUnavailabilityDetele(false)
    }
  }
  // slot list
  const getSlotfun = async (date, teamMember, clinic, serviceId) => {
    if (clinic && serviceId && (teamMember || userdata.userId) && date) {
      let payload = {
        staffId: userdata?.roleTitle === RoleTitle.STAFF ? userdata.userId : teamMember,
        serviceId: serviceId,
        date: moment(date).startOf('day'),
        // date: moment(date).startOf("day").utc(),
        timezoneName: userdata?.timezoneName,
        day: moment(date).format('dddd'),
        clinicId: clinic,
      }
      setSlotLodaer(true)
      setTimeSlotList([])
      try {
        let response = await TIME_SLOT_LIST(payload)
        setTimeSlotList(response.data)
        setSlotLodaer(false)
      } catch (error) {
        setTimeSlotList([])
        setSlotLodaer(false)
      }
    }
  }
  // offers apply
  const ApplyOffer = (data) => {
    const temp = [...offersList]
    let offersIndex = temp.findIndex(x => x.offerId === data?.offerId);
    if (data?.offerType === "FLAT") {
      if (data?.minimumCartValue > data?.fixedPrice) {
        return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
      } else {
        temp.forEach((item, itemindex) => {
          if (item.offerId === data?.offerId) {
            item.discountPrice = data?.fixedPrice - data?.discountAmount
          } else {
            item.discountPrice = null
          }
        })
        toast.success(`Apply offer successful`)
      }
    } else {
      if (data?.minimumCartValue > data?.fixedPrice) {
        return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
      } else {
        temp[offersIndex].discountPrice = data?.fixedPrice - data?.discountAmount
        let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
        let result = data?.fixedPrice - price
        let newPrice = result.toFixed(2)
        if (newPrice > data?.maxDiscountUpto) {
          temp.forEach((item, itemindex) => {
            if (item.offerId === data?.offerId) {
              item.discountPrice = data?.maxDiscountUpto
            } else {
              item.discountPrice = null
            }
          })
          toast.success(`Apply offer successful`)
        } else {
          temp.forEach((item, itemindex) => {
            if (item.offerId === data?.offerId) {
              item.discountPrice = newPrice
            } else {
              item.discountPrice = null
            }
          })
          // temp[offersIndex].discountPrice = newPrice
          toast.success(`Apply offer successful`)
        }
      }
    }
    setOffersList(temp)
  }
  // apply offer when enter coupon code in input  
  const applyByCouponCodeOffers = () => {
    const offersData = [...offersList]
    let index = offersData.findIndex(x => x.couponCode === createAppointment?.values?.couponCode);
    if (index === -1) {
      return toast.warn("your coupon code in not matching")
    }
    let data = offersData.find(x => x.couponCode === createAppointment?.values?.couponCode);
    if (data.discountPrice) {
      return toast.warn("your coupon already apply")
    }
    if (data?.offerType === "FLAT") {
      if (data?.minimumCartValue > data?.fixedPrice) {
        return toast.warn(`Service price must be greater than  ${data?.minimumCartValue}`)
      } else {
        offersData.forEach((item, itemindex) => {
          if (item.offerId === data?.offerId) {
            item.discountPrice = data?.fixedPrice - data?.discountAmount
          } else {
            item.discountPrice = null
          }
        })
        toast.success(`Apply offer successful`)
        createAppointment?.setFieldValue("couponCode", "")
      }
    } else {
      if (data?.minimumCartValue > data?.fixedPrice) {
        return toast.warn(`Service price must be greater than ${data?.minimumCartValue}`)
      } else {
        offersData[index].discountPrice = data?.fixedPrice - data?.discountAmount
        let price = ((data?.fixedPrice * data?.percentage) / 100).toFixed(2);
        let result = data?.fixedPrice - price
        let newPrice = result.toFixed(2)
        if (newPrice > data?.maxDiscountUpto) {
          offersData.forEach((item, itemindex) => {
            if (item.offerId === data?.offerId) {
              item.discountPrice = data?.maxDiscountUpto
            } else {
              item.discountPrice = null
            }
          })
          toast.success(`Apply offer successful`)
          createAppointment?.setFieldValue("couponCode", "")
        } else {
          // offersData[index].discountPrice = newPrice
          offersData.forEach((item, itemindex) => {
            if (item.offerId === data?.offerId) {
              item.discountPrice = newPrice
            } else {
              item.discountPrice = null
            }
          })
          toast.success(`Apply offer successful`)
          createAppointment?.setFieldValue("couponCode", "")
        }
      }
    }
    setOffersList(offersData)
  }
  // cancel offers
  const cancelOffer = (data) => {
    const temp2 = [...offersList]
    let offersIndex2 = temp2.findIndex(x => x.offerId === data?.offerId);
    temp2[offersIndex2].discountPrice = null
    setOffersList(temp2)
    toast.success(`Cancel offer successful`)
  }
  const handalSearchAbleSelectBox = (event, newValue, name) => {
    if (name === "staff_filter") {
      SetFilterStaffValue(newValue)
    }
    if (name === "service") {
      createAppointment.setFieldValue('service', newValue);
      createAppointment.setFieldValue('teamMember', userdata.roleTitle === 'SERVICE_PROVIDER' ? [] : userdata?.userId);
      getliststaff(userdata?.location?.clinicId, newValue?.[0]?.serviceId)
    }
    if (name === "patient") createAppointment.setFieldValue('patientName', newValue)
    if (name === "team") {
      createAppointment.setFieldValue('teamMember', newValue);
    }
  }
  const clossOffcanvas = (type) => {
    if (type === "BOOK_APPOINTMENT") {
      setBookAppointmentModal(false)
      setIsactiveSlot(null)
      createAppointment.resetForm()
      setCheck(false)
      setOffersList([])
      setTimeSlotList([])
      setOpenCreateModal(false)
    }
    if (type === "RESCHEDULE_APPOINTMENT") {
      setModalReschdule(false)
      setTimeSlotList([])
    }
    if (type === "CANCEL_APPOINTMENT") {
      setModalCancel(false)
    }

  }
  useEffect(() => {
    if (userdata?.roleTitle === RoleTitle.STAFF) {
      if (createAppointment.values.date && userdata?.userId && SERVICES_DETAILS?.serviceId) {
        getSlotfun(createAppointment.values.date, Staff_DETAILS?.userId, userdata?.location?.clinicId, SERVICES_DETAILS?.serviceId)
        // getOffersList()
      }
    } else {
      if (createAppointment.values.date && Staff_DETAILS?.userId) {
        getSlotfun(createAppointment.values.date, Staff_DETAILS?.userId, userdata?.location?.clinicId, SERVICES_DETAILS?.serviceId)
        // getOffersList()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAppointment.values.date, Staff_DETAILS?.userId, SERVICES_DETAILS?.serviceId])

  useEffect(() => {
    if (appointmentReschedule.values.apptDate && modalReschdule) {
      getSlotfun(appointmentReschedule.values.apptDate, appointmentReschedule.values.teamMemberId, userdata?.location?.clinicId, appointmentReschedule.values.serviceId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentReschedule.values.apptDate, modalReschdule])

  useEffect(() => {
    if (userdata.roleTitle === RoleTitle.SERVICE_PROVIDER || userdata.roleTitle === RoleTitle.FRONT_DESK) {
      staffListForFilter()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    calendarStyle,
    myEventsList,
    handleSelectEvent,
    handleSelectSlot,
    setViewModal,
    opneviewfun,
    onRangeChange,
    handleChangeView,
    setCheck,
    setIsactiveSlot,
    setModalCancel,
    applyByCouponCodeOffers,
    cancelOffer,
    ApplyOffer,
    handalSearchAbleSelectBox,
    clossOffcanvas,
    setOpenCreateModal,
    setBookAppointmentModal,
    getlistPatient,
    navigate,
    SetUnavailabilityDetele,
    handleClose,
    getservicesList,
    SetFilterStaffValue,
    selectedSlotTime,
    filterStaffValue,
    filterStaffList,
    UnavailabilityDetele,
    OpenCreateModal,
    patientFilterList,
    CustomButton,
    localizer,
    userdata,
    loader,
    calendarList,
    viewModal,
    cancelAppointment,
    bookAppointmentModal,
    createAppointment,
    modalCancel,
    SlotLodaer,
    Staff_DETAILS,
    SERVICES_DETAILS,
    servicesList,
    teamList,
    TimeslotList,
    isActiveSlot,
    offersList,
    OFERS_DETAILS,
    check,
    modalReschdule,
    apptDetails,
    appointmentReschedule,
    isFrontDeskPermission,
  }
}

export default useCalendar
