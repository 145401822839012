import { createSlice } from "@reduxjs/toolkit";
const reducer = createSlice({
    name: "BasicDetails",
    initialState: {},
    reducers: {
        AddClinicFildData(state, action) {
            return state = action.payload;
        },
    },
});
export const { AddClinicFildData } = reducer.actions;
export default reducer.reducer;
