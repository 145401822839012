import { useState, useEffect, useRef } from 'react'
import { addOffersValidation } from "../../../Context/Validation";
import { toast } from "react-toastify";
import { commonMessages } from "../../../Context/constants";
import { insertoffers, listservices } from "../../../Context/api";
import moment from "moment";
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../Context/CraeteAxios';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import useMinimumDate from '../../../Hooks/useMinimumDate';
const useAddOffers = () => {
    const [check, setCheck] = useState(true)
    const [imgUrl, setImgUrl] = useState(null)
    const [Agecheck, setAgeCheck] = useState(false)
    const [serviceListData, setServiceListData] = useState([])
    const navigate = useNavigate()
    const FileRef = useRef()
    const [loader, setLoader] = useState(false)
    const [Gender] = useState([
        { label: "Male", value: "MALE" },
        { label: "Female", value: "FEMALE" },
        { label: "Other", value: "OTHER" },
    ])
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { UploadImage } = useUploadImage("OFFER", setLoader, setImgUrl)
    const { minimumdate } = useMinimumDate()
    useEffect(() => {
        getservicesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //services list
    const getservicesList = () => {
        let data = {
            userId: userdata.userId,
            archive: false,
            keyword: '',
            page: "",
            count: "",
            roleTitle: userdata.roleTitle
        }
        axiosInstance.post(listservices(), { ...data }).then((res) => {
            if (res.data.status === "200") {
                setServiceListData(res.data.data)
            } else if (res.data.status === "500") {
                toast.error(commonMessages.NetworkError);
            } else {
                setServiceListData([])
            }
        }).catch(() => {
            toast.error(commonMessages.NetworkError);
        })
    }
    // add offers
    const formik = useFormik({
        initialValues: {
            offerName: "",
            audienceGender: [],
            age: [0, 0],
            serviceId: [],
            dateFrom: "",
            dateTo: "",
            discountType: "",
            percentage: "",
            maxDiscountUpto: "",
            minimumCartValue: "",
            terms: "",
            description: "",
            couponCode: "",
            discountAmount: ""
        },
        validationSchema: addOffersValidation,
        // validationSchema: "",
        onSubmit: value => {
            if ((value.dateFrom > value.dateTo) && (value.dateFrom !== value.dateTo)) return toast.warn("Start date cannot be greater than  end date");
            value.dateFrom = moment(value.dateFrom).startOf('day').utc()
            value.dateTo = moment(value.dateTo).endOf('day').utc()
            if (value.percentage >= 100) {
                return toast.warn("Percentage cannot be more than 100%")
            }
            let data = {
                ...value,
                active: check,
                userId: userdata.userId,
                image: imgUrl,
                age: Agecheck ? value?.age : null,
                serviceId: value?.serviceId?.map((cur, index) => cur?.value),
                audienceGender: value?.audienceGender?.map((cur, index) => cur?.serviceId),
                maxDiscountUpto: value?.discountType === "FLAT" ? "" : value?.maxDiscountUpto,
                percentage: value?.discountType === "FLAT" ? "" : value.percentage,
                discountAmount: value?.discountType === "FLAT" ? value?.discountAmount : ""
            }
            setLoader(true)
            axiosInstance.post(insertoffers(), data).then((res) => {
                if (res.data.status === "200") {
                    toast.success(res.data.message)
                    setTimeout(() => {
                        navigate('/offers')
                    }, 1000)
                } else if (res.data.status === "500") {
                    setLoader(false)
                    toast.error(commonMessages.NetworkError);
                } else {
                    setLoader(false)
                    toast.error(res.data.message)
                }
            }).catch(() => {
                toast.error(commonMessages.net)
            })
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "audienceGender") {
            formik?.setFieldValue("audienceGender", newValue)
        } else {
            formik.setFieldValue("serviceId", newValue)
        }
    }
    return {
        loader,
        formik,
        imgUrl,
        serviceListData,
        check,
        Gender,
        Agecheck,
        minimumdate,
        setCheck,
        UploadImage,
        setAgeCheck,
        setImgUrl,
        FileRef, handalSearchAbleSelectBox
    }
}

export default useAddOffers
