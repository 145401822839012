import { useEffect, useState } from 'react';
import useReduxData from '../../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { RoleTitle } from '../../../Context/constants';
import moment from 'moment-timezone';
import { CLINIC_LIST } from '../../Repository/ProfileRepo';
import { GET_SERVICES_REPORT } from '../../Repository/InsightsRepo';
const useServiceReports = () => {
    const { reduxData: userdata } = useReduxData("userDataRDX")

    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [dateFilter, setDateFilter] = useState('TODAY')
    // const [selectClinic, setSelectClinic] = useState([{ clinicName: "All", clinicId: "ALL_CLINCI" }])
    const [selectClinic, setSelectClinic] = useState([{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }])
    const [clinicList, setClinicList] = useState([])
    const [Reports, setReports] = useState({})
    const [loader, setloader] = useState(false)
    const [filterPopup, setFilterPopup] = useState(false)
    const [action, setAction] = useState(false)
    const [customDate, setCustomDate] = useState({
        startDate: "",
        endDate: ""
    })
    const [appointmentFilter, setAppointmentFilter] = useState([
        { label: "Booked", value: "BOOKED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "CheckedOut", value: "CHECKOUT" },
    ])
    const headCells = [
        { id: "Rank", numeric: false, disablePadding: true, label: "Rank" },
        { id: "Images", numeric: false, disablePadding: true, label: "Image" },
        { id: "Service", numeric: true, disablePadding: false, label: "Service" },
        { id: "Total Booking", numeric: true, disablePadding: false, label: "Total Booking" },
        { id: "Total Revenue", numeric: true, disablePadding: false, label: "Total Revenue" },
    ];

    // get list clinic 
    const getClinicList = async () => {
        let payload = {
            activeStatus: true,
            staffId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.userId : undefined,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            roleTitle: userdata?.roleTitle
        }
        setloader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setloader(false)
        } catch (error) {
            setloader(false)
            setClinicList([])
        }
    }

    const getServicesDetail = async () => {
        let payload = {
            providerId:userdata.roleTitle === RoleTitle.FRONT_DESK?userdata?.providerId: userdata.userId,
            clinicId: selectClinic[0]?.clinicId === "ALL_CLINCI" ? null : selectClinic[0]?.clinicId,
        }
        if (dateFilter === 'TODAY') {
            payload.start_Date = moment().startOf('day')
            payload.end_Date = moment().endOf('day')
        }
        if (dateFilter === 'LAST7') {
            payload.start_Date = moment().startOf('day').subtract(7, 'days')
            payload.end_Date = moment().endOf('day')
        }
        if (dateFilter === 'LAST30') {
            payload.start_Date = moment().startOf('day').subtract(30, 'days')
            payload.end_Date = moment().endOf('day')
        }

        if (dateFilter === 'CUSTOM' && customDate?.startDate === '' && customDate?.endDate === '') return toast.warn('Please select start date and end date ')
            if (dateFilter === 'CUSTOM' && customDate?.startDate === '') return toast.warn('Please select start date')
            if (dateFilter === 'CUSTOM' && customDate?.endDate === '') return toast.warn('Please select end date')
            if (customDate?.startDate > customDate?.endDate) return toast.warn('Start date can not be greater than end date ')
            else if (dateFilter === 'CUSTOM') {
                payload.start_Date = moment(customDate?.startDate).startOf('day')
                payload.end_Date = moment(customDate?.endDate).endOf('day')
            }
            setloader(true)
            setFilterPopup(false)
    
        try {
            let response = await GET_SERVICES_REPORT(payload)
            setReports(response?.data)
            setloader(false)

        } catch (error) {
            toast.error(error?.message)
            setloader(false)
            setReports({})
        }
    }
    useEffect(() => {
        getServicesDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])
    useEffect(() => {
        getClinicList()
        window.addEventListener("click", () => {
            setDateRangeDropdown(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        loader,
        dateFilter,
        selectClinic,
        Reports,
        headCells,
        clinicList,
        dateRangeDropdown,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        filterPopup,
        setFilterPopup,
        customDate, setCustomDate,
        userdata,
        setAction,
        getServicesDetail,
        setAppointmentFilter,
        appointmentFilter,
    }
}

export default useServiceReports
