import { useState, useEffect, useRef } from 'react'
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import { contactInfoValidation, pattern, personalInfoValidation } from '../../../Context/Validation';
import { teamProof } from '../../../Context/api';
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom';
import useReduxData from '../../../Hooks/useReduxData';
import useUploadImage from '../../../Hooks/useUploadImage';
import { EDIT_CONTACT_INFO, EDIT_PERSONAL_INFO, EDUCATION_LIST, GET_CONTACT_INFO_DETAILS, GET_PERESANAL_INFO_DETAILS } from '../../Repository/StaffRepo';
import { COUNTRY_LIST, STATE_LIST } from '../../Repository/CommonRepo';
import { RoleTitle } from '../../../Context/constants';

const useEditTeam = () => {
    const [imgUrl, setImgUrl] = useState(null);
    const [showform, setShowForm] = useState(1)
    const [ListState, setListState] = useState([])
    const [ListDataCountry, setListDataCountry] = useState([])
    const [alldegreeList, setAlldegreeList] = useState([1, 2])
    const [proofData, setProofData] = useState('')
    const [specializationVlaue, setSpecializationVlaue] = useState([])
    const [specialization] = useState([])
    const [loader, setLoader] = useState(false)
    const [viewPDF, setViewPDF] = useState(false)
    const navigate = useNavigate()
    const { reduxData: StaffDetails } = useReduxData("teamDetailsRDX")
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const { UploadImage } = useUploadImage()
    const isFrontDeskPermission = userdata?.roleTitle === RoleTitle?.FRONT_DESK
    const FileRef = useRef()
    useEffect(() => {
        listcountrys()
        // getdataTeams()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (showform === 1) {
            getPeresanalInfo()
        } if (showform === 2) {
            getEducationList()
        }
        if (showform === 3) {
            getContactInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showform])

    // get list state
    const getStateList = async (countryId, StateId) => {
        let payload = {
            countryId: countryId,
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await STATE_LIST(payload)
            setListState(response.data)
            if (countryId && StateId) {
                const StateDetails = response.data.filter((cur) => cur?.stateId === StateId)
                contactInfoFormik.setFieldValue("state", StateDetails)
            }

        } catch (error) {
            setListState([])
            toast.error(error.message)
            contactInfoFormik.setFieldValue("state", [])
        } finally {
            setLoader(false)
        }
    }
    // get list counry api
    async function listcountrys(countryId) {
        let payload = {
            activeStatus: true
        }
        setLoader(true)
        try {
            let response = await COUNTRY_LIST(payload)
            setListDataCountry(response.data)
            if (countryId) {
                const countryDetails = response.data.filter((cur) => cur?.id === countryId)
                contactInfoFormik.setFieldValue("country", countryDetails)
            }
        } catch (error) {
            setListDataCountry([])
        } finally {
            setLoader(false)
        }
    }

    // veiw staff 
    // const getdataTeams = () => {
    //     setLoader(true)
    //     axiosInstance.post(viewstaffbyid(), { userId: userdata.userId, staffId: userdata.roleTitle === 'STAFF' ? '' : StaffDetails?.id, roleTitle: userdata.roleTitle }).then((res) => {
    //         if (res.data.status === "200") {
    //             setImgUrl(res.data.data?.profileImage)
    //             setAlldegreeList(res.data.data?.education)
    //             setSpecialization(res.data.data?.specialization)
    //             setSpecializationVlaue(res.data.data?.specialization)
    //             peresanalInfoFormik.setValues(res.data.data?.personalInformation)
    //             setPersanalInfo(res.data.data?.personalInformation)
    //             contactInfoFormik.setValues(res.data.data?.contactInformation)
    //             contactInfoFormik.setFieldValue("mobile", res.data.data?.contactInformation.mobile)
    //             getStateList(res.data.data?.contactInformation.country)
    //             degree.setFieldValue("education", res.data.data?.education)
    //             {
    //                 userdata.roleTitle === 'STAFF' && dispatch(updateUserData({ img: res.data.data?.profileImage, name: res.data.data?.personalInformation.name }))
    //             }
    //         } else {
    //             toast.error(res.data.message)
    //         }
    //         setLoader(false)
    //     }).catch(() => {

    //     })
    // }

    // get peresanal info
    const getPeresanalInfo = async () => {
        let payload = {
            staffId: StaffDetails?.userId
        }
        setLoader(true)
        try {
            let response = await GET_PERESANAL_INFO_DETAILS(payload)
            peresanalInfoFormik.setValues(response.data)
            setImgUrl(response.data?.image)
            setLoader(false)
        } catch (error) {
            toast.error(error.message);
            setLoader(false)
        }
    }
    // get contact details
    let getContactInfo = async () => {
        let payload = {
            staffId: StaffDetails?.userId
        }
        setLoader(true)
        try {
            let response = await GET_CONTACT_INFO_DETAILS(payload)
            contactInfoFormik.setValues(response.data)
            contactInfoFormik.setFieldValue("country", [])
            contactInfoFormik.setFieldValue("state", [])
            setImgUrl(response.data?.image)
            setLoader(false)
            if (response?.data?.countryId) {
                listcountrys(response?.data?.countryId)
            }
            if (response?.data?.countryId && response?.data?.stateId) {
                getStateList(response?.data?.countryId, response?.data?.stateId)
            }
        } catch (error) {
            toast.error(error.message);
            setLoader(false)
        }
    }
    //get education List
    let getEducationList = async () => {
        let payload = {
            staffId: StaffDetails?.userId
        }
        setLoader(true)
        try {
            let response = await EDUCATION_LIST(payload)
            setAlldegreeList(response?.data)
            setLoader(false)
        } catch (error) {
            setAlldegreeList([])
            setLoader(false)
        }
    }
    // handal change birthDate
    const handalDOB = (e) => {
        peresanalInfoFormik.setFieldValue("dob", e.target.value)
        let today = new Date();
        let birthDate = new Date(e.target.value);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        peresanalInfoFormik.setFieldValue("age", age ?? "")
    }
    //add Peresonal Information
    const peresanalInfoFormik = useFormik({
        initialValues: {
            title: "",
            firstName: "",
            lastName: "",
            gender: "",
            dob: "",
            age: "",
            bloodGroup: "",
            language: "",
            experience: ""
        },
        validationSchema: personalInfoValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (StaffDetails?.roleId === RoleTitle?.STAFF) {
                if (values?.experience === undefined || values?.experience === null || values.experience === '') {
                    errors.experience = "Please enter experience";
                } else if (Number(values.experience) < 0) {
                    errors.experience = "Experience must be zero or greater";
                } else {
                    delete errors.experience;
                }
            }
            return errors;
        },
        onSubmit: async (value) => {
            let { experience, activeStatus, providerIdOfStaff, userId, deletedStatus, roleId, roleName, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: providerIdOfStaff,
                staffId: userId,
                image: imgUrl,
                experience: StaffDetails?.roleId === RoleTitle?.STAFF ? experience : undefined,
                dob: moment(value.dob).format("YYYY-MM-DD"),
                roleTitle:StaffDetails?.roleId
            }
            setLoader(true)
            try {
                let response = await EDIT_PERSONAL_INFO(payload)
                toast.success(response?.message)
                setLoader(false)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    //Contact Information
    const contactInfoFormik = useFormik({
        initialValues: {
            mobile: "",
            email: "",
            street: "",
            address: "",
            city: "",
            state: [],
            country: [],
            zip: "",
        },
        validationSchema: contactInfoValidation,
        validateOnChange: true,
        validateOnBlur: true,
        validate: (values) => {
            const errors = {};
            if (values?.mobile?.length > 0 && (values?.mobile.match(pattern?.PHONE_NO))) {
                delete errors.mobile
            } else {
                errors.mobile = "Please enter a valid mobile number ";
            }
            return errors;
        },
        onSubmit: async (value) => {
            // peresanalInfo.dob = moment(peresanalInfo.dob).format("YYYY-MM-DD")
            // let data = {
            //     userId: userdata.userId,
            //     staffId: userdata.roleTitle === 'STAFF' ? '' : StaffDetails?.id,
            //     ...peresanalInfo,
            //     ...value,
            //     mobile: `${value.mobile}`,
            //     profileImage: imgUrl,
            //     education: alldegree,
            //     roleTitle: userdata.roleTitle
            // }
            // setLoader(true)
            // axiosInstance.post(updatestaffdetails(), data).then((res) => {
            //     if (res.data.status === "200") {
            //         setLoader(false)
            //         {
            //             userdata.roleTitle === 'STAFF' && dispatch(updateUserData({ img: imgUrl, name: peresanalInfoFormik.values.name }))
            //         }
            //         toast.success(res.data.message)
            //         setTimeout(() => {
            //             if (userdata.roleTitle !== 'STAFF') {
            //                 setShowForm(1)
            //                 navigate('/team/details')
            //             } else {
            //                 getdataTeams()
            //                 setShowForm(1)
            //             }
            //         }, 1200)
            //     } else if (res.data.status === "500") {
            //         toast.error(commonMessages.NetworkError);
            //         setLoader(false)
            //     } else {
            //         toast.error(res.data.message)
            //         setLoader(false)
            //     }
            // }).catch(() => {
            //     setLoader(false)
            //     toast.error(commonMessages.NetworkError)
            // })
            let { authId, activeStatus, providerIdOfStaff, userId, deletedStatus, roleId, roleName, ...restValue } = value
            let payload = {
                ...restValue,
                providerId: providerIdOfStaff,
                staffId: userId,
                state: value?.state[0]?.stateId,
                country: value?.country?.[0]?.id
            }
            setLoader(true)
            try {
                let response = await EDIT_CONTACT_INFO(payload)
                toast.success(response?.message)
                setLoader(false)
                setShowForm(1)
            } catch (error) {
                toast.error(error.message);
                setLoader(false)
            }
        }
    })
    const handalSearchAbleSelectBox = (event, newValue, name) => {
        if (name === "country") {
            getStateList(newValue?.[0]?.id, name)
            contactInfoFormik.setFieldValue(name, newValue)
            contactInfoFormik.setFieldValue("state", [])
        } else {
            contactInfoFormik.setFieldValue(name, newValue)
        }
    }

    return {
        UploadImage,
        handalDOB,
        setViewPDF,
        setProofData,
        navigate,
        setLoader,
        handalSearchAbleSelectBox,
        setSpecializationVlaue,
        setImgUrl,
        setShowForm,
        alldegreeList,
        loader,
        imgUrl,
        showform,
        peresanalInfoFormik,
        specialization,
        specializationVlaue,
        contactInfoFormik,
        ListState,
        proofData,
        ListDataCountry,
        teamProof,
        StaffDetails,
        viewPDF,
        FileRef,
        isFrontDeskPermission,
    }
}

export default useEditTeam
