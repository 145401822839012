import { useEffect, useState } from 'react';
import moment from "moment-timezone";
import { RoleTitle } from '../../../Context/constants';
import useReduxData from '../../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { CLINIC_LIST } from '../../Repository/ProfileRepo';
import { GET_BOOKING_DETAILS } from '../../Repository/InsightsRepo';
import { formatNumber } from '../../../Utils/Utils';

const useBookingDetails = () => {
    const { reduxData: userdata } = useReduxData("userDataRDX")
    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [loader, setloader] = useState(false)
    // const [selectClinic, setSelectClinic] = useState([{ clinicName: "All", clinicId: "ALL_CLINCI" }])
    const [selectClinic, setSelectClinic] = useState([{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }])
    const [clinicList, setClinicList] = useState([])
    const [pillsActive, setPillsActive] = useState("Booking")
    const [dateFilter, setDateFilter] = useState('TODAY')
    const [BookingDetails, setBookingDetails] = useState({})
    const [filterPopup, setFilterPopup] = useState(false)
    const [action, setAction] = useState(false)
    const [appointmentFilter, setAppointmentFilter] = useState([
        { label: "Booked", value: "BOOKED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "CheckedOut", value: "CHECKOUT" },
    ])
    const [customDate, setCustomDate] = useState({
        startDate: "",
        endDate: ""
    })

    // get list clinic 
    const getClinicList = async () => {
        let payload = {
            activeStatus: true,
            staffId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.userId : undefined,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            roleTitle: userdata?.roleTitle
        }
        setloader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setloader(false)
        } catch (error) {
            setloader(false)
            setClinicList([])
        }
    }
    const getBookingDetail = async () => {
        let payload = {
            providerId: userdata.roleTitle === RoleTitle.FRONT_DESK ? userdata?.providerId : userdata.userId,
            clinicId: selectClinic[0]?.clinicId === "ALL_CLINCI" ? null : selectClinic[0]?.clinicId,
            appointmentType: pillsActive.toUpperCase(),
            appointmentTypeFilter: appointmentFilter?.map((cur) => cur?.value)
        }
        if (dateFilter === 'TODAY') {
            payload.start_Date = moment().startOf('day')
            payload.end_Date = moment().endOf('day')
            // payload.todayDate = moment().endOf('day').format('YYYY-MM-DD')
        }
        if (dateFilter === 'LAST7') {
            payload.start_Date = moment().startOf('day').subtract(7, 'days')
            payload.end_Date = moment().endOf('day')
            payload.todayDate = undefined
        }
        if (dateFilter === 'LAST30') {
            payload.start_Date = moment().startOf('day').subtract(30, 'days')
            payload.end_Date = moment().endOf('day')
            payload.todayDate = undefined
        }
        if (dateFilter === 'CUSTOM' && customDate?.startDate === '' && customDate?.endDate === '') return toast.warn('Please select start date and end date ')
        if (dateFilter === 'CUSTOM' && customDate?.startDate === '') return toast.warn('Please select start date')
        if (dateFilter === 'CUSTOM' && customDate?.endDate === '') return toast.warn('Please select end date')
        if (customDate?.startDate > customDate?.endDate) return toast.warn('Start date can not be greater than end date ')
        else if (dateFilter === 'CUSTOM') {
            payload.start_Date = moment(customDate?.startDate).startOf('day')
            payload.end_Date = moment(customDate?.endDate).endOf('day')
        }
        setloader(true)
        setFilterPopup(false);
        try {
            let response = await GET_BOOKING_DETAILS(payload)
            setloader(false)
            let data = response?.data
            let insightsAppointmentBooked = data?.insightsAppointmentBooked?.map((cur, index) => {
                return { ...cur, month: cur?.Date }
            })
            setBookingDetails({ ...data, insightsAppointmentBooked })
        } catch (error) {
            toast.error(error?.message)
            setloader(false)
            setBookingDetails({})
        }
    }

    useEffect(() => {
        getBookingDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    useEffect(() => {
        getClinicList()
        window.addEventListener("click", () => {
            setDateRangeDropdown(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const totalBookedAppointments = BookingDetails?.bookedAppointmentCount
    const totalcancelledAppointments = BookingDetails?.cancelledAppointmentCount
    const totalrescheduledAppointment = BookingDetails?.rescheduledAppointmentCount
    const totalAppointmentCount = BookingDetails?.totalAppointmentCount
    const totalcheckoutAppointmentCount = BookingDetails?.checkoutAppointmentCount
    const graphData = BookingDetails?.insightsAppointmentBooked
    const totalRevenueCount = formatNumber(BookingDetails?.totalRevenueCount)
    const currencyImage = BookingDetails?.currencyImage


    return {
        loader,
        dateFilter,
        selectClinic,
        clinicList,
        dateRangeDropdown,
        totalBookedAppointments,
        totalcancelledAppointments,
        totalrescheduledAppointment,
        totalAppointmentCount,
        totalRevenueCount,
        graphData,
        filterPopup,
        customDate,
        setCustomDate,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        setPillsActive,
        setFilterPopup,
        getBookingDetail,
        setAction,
        setAppointmentFilter,
        appointmentFilter,
        userdata,
        currencyImage,
        totalcheckoutAppointmentCount
    }
}

export default useBookingDetails
