import React, { useState } from 'react'
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
const useTableSorting = (headCells) => {
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("calories");
    //sorting start
    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis?.map(el => el[0]);
    }

    function EnhancedTableHead(props) {
        const {
            order,
            orderBy,
            onRequestSort
        } = props;
        const createSortHandler = property => event => {
            onRequestSort(event, property);
        };
        return (
            <TableHead>
                <TableRow>
                    {headCells?.map((headCell,index) => (
                        <TableCell
                            key={index}
                            sortDirection={orderBy === headCell.id ? order : false}
                            align={headCell.showLeft ? "right" : "left"}
                            style={{fontSize:"13px"}}
                        // padding="50px"
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                sx={
                                    {
                                        '& .MuiTableSortLabel-icon': {
                                            color: `${headCell.showIcon === true ? "#38C4B9" : "#103a4000"}  !important`,
                                        },
                                    }
                                }
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    //sorting end
    return {
        EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort
    }
}

export default useTableSorting
