/* eslint-disable no-useless-concat */
import * as Yup from "yup";
export const pattern = {
  NAME: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
  NAMENumber: /^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/,
  CLAIMNUMBER: /^[ A-Za-z0-9]*$/,
  REPORTNAME: /^[ A-Za-z]*$/,
  CODE: /^[ A-Za-z_@./#&+-/'/"]*$/,
  DURATION: /^[0-9]{0,3}$/,
  PRICING: /^[0-9.]{0,30}$/,
  CITY: /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
  // eslint-disable-next-line no-useless-escape
  EMAIL: /^(([^<>()\[\]\\.,,:\s@"]+(\.[^<>()\[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  POSTAL_CODE: /^\d{5}-\d{4}|\d{4}|[A-Z]\d[A-Z] \d[A-Z]\d$/,
  PHONE_NO: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,4}$/,
  FIRM_NUMBER: /^[a-z0-9-]+$/,
  ALPHANUM: /^[a-zA-Z0-9]+$/,
  MOB_NO: /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$#!%*?&])[A-Za-z\d$@$!%*?&]{6,}/,
  DESCRIPTION: /^[ !@#$%^&*()~:;{}?'"=<>A-Za-z0-9_@./#&+-,-]*$/,
  REFNO: /^[ 0-9_@./#&+-,-]*$/,
  TASK_CODE: /^[0-9999]{1,4}$/,
  SUB_DOMAIN: /^[/a-z/A-Z][a-zA-Z0-9-]*[^/-/./0-9]$/,
  PHONE_NO_MASK: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  IVR_ACTION_KEY: /^[0-9]*$/,
  IVR_NUMBER: /^[1-9]*$/,
  RADIUS: /^[0-9]*(?:.)([0-9])+$/,
  LATLONG: /^\s*(-?\d+(\.\d+)?)$/,
  SSN: /^((\d{3}-?\d{2}-?\d{4})|(X{3}-?X{2}-?X{4}))$/,
  SSN_MASK: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  PRACTICE_PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
  USERNAME: /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){1,14}[a-zA-Z0-9]$/,
  USERNAME_MIN_SIZ: /^[a-zA-Z0-9_](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9_]){4,18}[a-zA-Z0-9_]$/,
  WICARE_USERNAME: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{1,}/,
  YEAR_MASK: /d{4}/,
  DECIMAL: /\d+(\.\d{1,2})?/,
  NUMBERnDECIMAL: '^\\d+(\\.\\d+)?$',
  WHITESPACE: /^(?!\s).+(?<!\s)$/,
  MAXLENGTH: 50,
  MINLENGTH: 3,
  PASSWORDMINLENGTH: 6,
  PASSWORDMAXLENGTH: 15,
  MINIMUMVACANCY: 1,
  MAXIMUMVACANCY: 2,
  BACKSPACE: /^((?!\s{2,}).)*$/,
  URL: '^(https?:\\/\\/)?' + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + '((\\d{1,3}\\.){3}\\d{1,3}))' + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + '(\\?[;&a-z\\d%_.~+=-]*)?' + '(\\#[-a-z\\d_]*)?$',
  PASSWORD_PIN: "(?=(.*?[0-9]){4})",
  DECIMAL_LIMIT: /^\d*\.?\d{0,2}$/
}

export const signupNumberValidation = Yup.object({
  phone: Yup.string().trim()
    .required("Please enter mobile number ")
  // .matches(phoneRegExp, "Mobile number is not valid")
  // .min(10, "Mobile number must be at least 10 digit")
  // .max(10, "Mobile number must be at least 10 digit"),
});
export const signupEmailValidation = Yup.object({
  email: Yup.string().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email")
});


export const createpinValidation = Yup.object({
  newpin: Yup.string().trim().required("Please enter New PIN").min(4, "Minimum 4 digit PIN").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  confirmpin: Yup.string().trim().required("Please enter confirm New PIN").min(4, "Minimum 4 digit PIN").matches(pattern.PASSWORD_PIN, "Please enter a numerical value").oneOf(
    [Yup.ref("newpin"), null],
    "New PIN and Confirm PIN don't match"

    // "PIN does not match"
  ),
});
export const signUpOtpValidation = Yup.object({
  otp: Yup.string().trim().required("Please enter OTP").min(4, "Minimum 4 digit OTP").matches(pattern.PASSWORD_PIN, "Please enter correct OTP"),
});
export const newUserPinValidation = Yup.object({
  pin: Yup.string().trim().required("Please enter OTP"),
});

export const loginvalidation = Yup.object({
  phone: Yup.string().trim()
    .required("Please enter mobile number "),
  // .matches(phoneRegExp, " Mobile number is not valid")
  // .matches(pattern.WHITESPACE, "White space is not required")
  // .min(10, "Mobile number must be at least 10 digit")
  // .max(10, "Mobile number must be at least 10 digit"),
  password: Yup.string()
    .required("Please enter PIN")
    .matches(pattern.WHITESPACE, "White space is not required")
    .min(4, "Minimum 4 digit ").matches(pattern.PASSWORD_PIN, "Please enter correct PIN"),
});
export const loginvalidationWithEmail = Yup.object({
  email: Yup.string().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email"),
  password: Yup.string()
    .required("Please enter PIN")
    .matches(pattern.WHITESPACE, "White space is not required")
    .min(4, "Minimum 4 digit ").matches(pattern.PASSWORD_PIN, "Please enter correct PIN"),
});

export const basicDetailsValidation = Yup.object({
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  title: Yup.string().nullable().required("Please select title "),
  mobile: Yup.string().trim()
    .required("Please enter mobile number "),
  email: Yup.string().nullable().required("Please enter email")
    .matches(pattern.EMAIL, "Please enter correct email")
});

export const businesscDetailsValidation = Yup.object({
  businessName: Yup.string().nullable().required("Please enter business name").matches(pattern.WHITESPACE, "White space is not required"),
  businessCategoryId: Yup.string().nullable().required("Please enter category ").matches(pattern.WHITESPACE, "White space is not required"),
  about: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter about us "),
});
export const addClinicValidation = Yup.object({
  clinicName: Yup.string().required("Please enter clinic name ").matches(pattern.WHITESPACE, "White space is not required"),
  registrationNumber: Yup.string().required("Please enter registration number").matches(pattern.WHITESPACE, "White space is not required"),
  street: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  address: Yup.string().nullable().required("Please enter address  ").matches(pattern.WHITESPACE, "White space is not required"),
  city: Yup.string().nullable().required("Please enter city  ").matches(pattern.WHITESPACE, "White space is not required"),
  state: Yup.array()
    .required('Please select state')
    .min(1, 'Please select state'),
  country: Yup.array()
    .required('Please select country')
    .min(1, 'Please select country'),
  zipCode: Yup.string().nullable().required("Please enter ZIP code").matches(pattern.WHITESPACE, "White space is not required"),
});
// setting 
export const ChangePinValidation = Yup.object({
  oldpin: Yup.string().required("Please enter old PIN").min(4, "Minimum 4 digit PIN").matches(pattern.WHITESPACE, "White space is not required").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  newpin: Yup.string().required("Please enter new PIN").min(4, "Minimum 4 digit PIN").matches(pattern.WHITESPACE, "White space is not required").matches(pattern.PASSWORD_PIN, "Please enter a numerical value"),
  confirmpin: Yup.string().required("Please enter confirm PIN").matches(pattern.WHITESPACE, "White space is not required").min(4, "Minimum 4 digit PIN").matches(pattern.PASSWORD_PIN, "Please enter a numerical value").oneOf(
    [Yup.ref("newpin"), null],
    "New PIN and Confirm PIN don't match"
  ),
});

export const CurrencyValidation = Yup.object({
  currency: Yup.array().min("Please select currency"),

});
export const settingValidation = Yup.object({
  currency: Yup.array().min(1, "Please select currency"),
  timezone: Yup.array().min(1, "Please select time zone"),
});

export const TimeZoneValidation = Yup.object({
  timezone: Yup.string().nullable().trim().required("Please select time zone").matches(pattern.WHITESPACE, "White space is not required"),

});
export const createTicketValidation = Yup.object({
  subject: Yup.string().nullable().trim().required("Please enter subject").matches(pattern.WHITESPACE, "White space is not required"),
  description: Yup.string().nullable().trim().required("Please enter description").matches(pattern.WHITESPACE, "White space is not required"),
  // priority: Yup.string().trim().required("Please select priority").matches(pattern.WHITESPACE, "White space is not required"),
});
export const replyTicketValidation = Yup.object({
  reply: Yup.string().trim().required("Please enter reply").matches(pattern.WHITESPACE, "White space is not required"),

});

// services

export const addServicesValidation = Yup.object({
  serviceName: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter services name"),
  fixedPrice: Yup.string().nullable().required("Please enter price"),
  specializationId: Yup.string().nullable().required("Please select specialization"),
  // variablePrice: Yup.string(),
  description: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter description"),
  duration: Yup.string().nullable().required("Please select duration"),
  noOfPatients: Yup.number().required("Please enter number ").moreThan(0, 'Number must be greater than zero'),

})
export const addSpecializationValidation = Yup.object({
  specializationName: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter specialization"),
})
export const addduration = Yup.object({
  duration: Yup.string().nullable().trim("White space is not required").strict(true).required("Please enter duration"),
})

// Offers

export const addOffersValidation = Yup.object({
  offerName: Yup.string().trim("White space is not required").strict(true).required("Please enter offers name"),
  audienceGender: Yup.array(),
  discountType: Yup?.string().required("Please select discount type"),
  couponCode: Yup?.string().required("Please enter coupon code").test('no-space', 'Name must not contain spaces', (value) => !/\s/.test(value)),
  maxDiscountUpto: Yup.string()
    .when('discountType', {
      is: (discountType) => discountType && discountType === "PERCENTAGE",
      then: Yup.string().required("Please enter maximum discount upto value").matches(pattern.WHITESPACE, "White space is not required"),
      otherwise: Yup.string().notRequired()
    }),
  age: Yup.array().min(1, 'Please select age'),
  serviceId: Yup.array().min(1, 'Please select service'),
  dateFrom: Yup.string().required("Please choose start date "),
  dateTo: Yup.string().required("Please choose start date "),
  terms: Yup.string().trim("White space is not required").strict(true).required("Please enter term and conditions"),
  percentage: Yup.string()
    .when('discountType', {
      is: (discountType) => discountType && discountType === "PERCENTAGE",
      then: Yup.string().required("Please enter percentage").matches(pattern.WHITESPACE, "White space is not required"),
      otherwise: Yup.string().notRequired()
    }),
  discountAmount: Yup.string()
    .when('discountType', {
      is: (discountType) => discountType && discountType === "FLAT",
      then: Yup.string().required("Please enter discount amount").matches(pattern.WHITESPACE, "White space is not required"),
      otherwise: Yup.string().notRequired()
    }),
  description: Yup.string().trim("White space is not required").strict(true).required("Please enter description"),
  minimumCartValue: Yup.string().required("Please enter minimum value").matches(pattern.WHITESPACE, "White space is not required"),
})

// availability
export const addAvailabilityValidation = Yup.object({
  clinicId: Yup.array().min(1, "Please select clinic "),
  fromDate: Yup.string().required("Please choose start date "),
  toDate: Yup.string().required("Please choose end date "),
  timeZone: Yup.array().nullable().min(1, "Please enter time zone")
})
//update availability
export const UpdateAvailabilityValidation = Yup.object({
  fromDate: Yup.string().required("Please choose start date "),
  toDate: Yup.string().required("Please choose end date "),
  timeZone: Yup.array().nullable().min(1, "Please enter time zone")
})
// Unavailability
export const addUnavailabilityValidation = Yup.object({
  // clinicId: Yup.array().min(1, "Please select clinic "),
  timeZone: Yup.array().min(1, "Please select time zone"),
  fromDate: Yup.string().required("Please start date "),
  toDate: Yup.string().nullable().required("Please choose end date")
})

//Teams 
export const addTeamsValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title"),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  gender: Yup.string().nullable().required("Please select gender "),
  dob: Yup.string().nullable().required("Please select date of birth  "),
  age: Yup.string().nullable().required("Age is required "),
  bloodGroup: Yup.string().nullable().required("Please select blood group "),
  number: Yup.string().nullable().required("Please enter  mobile number "),
  // .matches(phoneRegExp, "mobile number is not valid").min(10, "mobile number must be at least 10 digit")
  // .max(10, "mobile number must be at least 10 digit"),
  email: Yup.string().nullable().required("Please enter email")
    .matches(pattern.EMAIL, "Please enter correct email"),
  // services: Yup.array()
  //   .min(1, 'Please select specialization'),
  // clinicId: Yup.array().min(1, 'Please select clinic')
})

export const TeamsEducationValidation = Yup.object().shape({
  degree: Yup.string().nullable().required("Please enter degree ").matches(pattern.WHITESPACE, "White space is not required"),
  university: Yup.string().nullable().required("Please enter  university ").matches(pattern.WHITESPACE, "White space is not required"),
  yearOfPassing: Yup.string().nullable().required("Please enter passing year "),
  registrationNumber: Yup.string().nullable().required("Please enter registration number  ").matches(pattern.WHITESPACE, "White space is not required"),
});
export const resendEmailValidation = Yup.object({
  newEmail: Yup.string().nullable().required("Please enter new email address")
    .matches(pattern.EMAIL, "Please enter correct email"),

})

export const personalInfoValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title "),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  gender: Yup.string().nullable().required("Please select gender "),
  dob: Yup.string().nullable().required("Please select date of birth  "),
  age: Yup.string().nullable().required("Age is required "),
  bloodGroup: Yup.string().nullable().required("Please select blood group "),
  language: Yup.string().nullable().required("Please select language "),
  // experience: Yup.string().nullable().required("Please enter experience ").test('greater-than-or-equal-to-zero', 'Experience must be greater than zero and equal to zero', value => {
  //   const numericValue = Number(value);
  //   return !isNaN(numericValue) && numericValue >= 0;
  // }),

})
export const contactInfoValidation = Yup.object({
  mobile: Yup.string().nullable().trim()
    .required("Please enter mobile number "),
  // .min(10, "Phone must be at least 10 digit")
  // .max(10, "Phone must be at least 10 digit")
  // .matches(phoneRegExp, "Phone number is not Valid")
  // .matches(pattern.WHITESPACE, "White space is not required"),

  email: Yup.string().nullable().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email"),
  street: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  address: Yup.string().nullable().required("Please enter address  ").matches(pattern.WHITESPACE, "White space is not required"),
  city: Yup.string().nullable().required("Please enter city  ").matches(pattern.WHITESPACE, "White space is not required"),
  state: Yup.array().nullable()
    .required('Please select state')
    .min(1, 'Please select state'),
  country: Yup.array().nullable()
    .required('Please select country')
    .min(1, 'Please select country'),
  zip: Yup.string().nullable().required("Please enter ZIP code").matches(pattern.WHITESPACE, "White space is not required"),
})

export const TeamsSettingValidation = Yup.object({
  clinicId: Yup.string().nullable().required("Please select clinic")
})


//  patients
export const AddpatientsValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title "),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  gender: Yup.string().nullable().required("Please select gender "),
  dob: Yup.string().nullable().required("Please select date of birth  "),
  age: Yup.string().nullable().required("Age is required "),
  bloodGroup: Yup.string().nullable().required("Please select blood group "),
  maritalStatus: Yup.string().nullable().required("Please select marital  status "),
  number: Yup.string().trim()
    .required("Please enter mobile number "),
  // .matches(phoneRegExp, "mobile number is not Valid")
  // .matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email"),
  street: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  address: Yup.string().nullable().required("Please enter address  ").matches(pattern.WHITESPACE, "White space is not required"),
  city: Yup.string().nullable().required("Please enter city  ").matches(pattern.WHITESPACE, "White space is not required"),
  state: Yup.array().nullable()
    .required('Please select state')
    .min(1, 'Please select state'),
  country: Yup.array().nullable()
    .required('Please select country')
    .min(1, 'Please select country'),
  zipCode: Yup.string().nullable().required("Please enter ZIP code").matches(pattern.WHITESPACE, "White space is not required"),
  // smoking: Yup.string().required("Smoking is required "),
  // alcohol: Yup.string().required("Alcohol is required ")
})
export const updatepatientsValidation = Yup.object({
  title: Yup.string().nullable().required("Please select title "),
  firstName: Yup.string().nullable().required("Please enter first name").matches(pattern.WHITESPACE, "White space is not required"),
  lastName: Yup.string().nullable().required("Please enter last name").matches(pattern.WHITESPACE, "White space is not required"),
  gender: Yup.string().nullable().required("Please select gender "),
  age: Yup.string().nullable().required("Age is required "),
  bloodGroup: Yup.string().nullable().required("Please select blood group "),
  maritalStatus: Yup.string().nullable().required("Please select marital  status "),
  mobile: Yup.string().trim()
    .required("Please enter mobile number "),
  // .matches(phoneRegExp, "mobile number is not valid")
  // .matches(pattern.WHITESPACE, "White space is not required"),
  email: Yup.string().nullable().required("Please enter email address")
    .matches(pattern.EMAIL, "Please enter correct email"),
  street: Yup.string().nullable().matches(pattern.WHITESPACE, "White space is not required"),
  address: Yup.string().nullable().required("Please enter address  ").matches(pattern.WHITESPACE, "White space is not required"),
  city: Yup.string().nullable().required("Please enter city  ").matches(pattern.WHITESPACE, "White space is not required"),
  state: Yup.array().nullable()
    .required('Please select state')
    .min(1, 'Please select state'),
  country: Yup.array().nullable()
    .required('Please select country')
    .min(1, 'Please select country'),
  zipCode: Yup.string().nullable().required("Please enter ZIP code").matches(pattern.WHITESPACE, "White space is not required"),
})

export const addpetvalidatio = Yup.object({
  name: Yup.string().nullable().required("Please enter  name").matches(pattern.WHITESPACE, "White space is not required"),
  gender: Yup.string().nullable().required("Please select gender "),
  petType: Yup.string().nullable().required("Please select pet type "),
  breed: Yup.string().nullable().required("Please enter  breed"),
  age: Yup.string().nullable().required("Please select  age"),
})

export const addAppointmentTypeValidation = Yup.object({
  appointmentType: Yup.string().nullable().required("Please enter  name").matches(pattern.WHITESPACE, "White space is not required"),
  duration: Yup.string().nullable().required("Please select duration"),
})

export const createAppointmentValidation = Yup.object({
  patientName: Yup.array().nullable().min(1, 'Please select patient'),
  date: Yup.string().required("Please select date"),
  service: Yup.array().nullable().min(1, "Please select service"),
  visitType: Yup.string().nullable().required("Please select visit type"),
  teamMember: Yup.array().nullable().min(1, "Please select team"),
})
export const createAppointmentStaffValidation = Yup.object({
  patientName: Yup.array().nullable().min(1, 'Please select patient'),
  date: Yup.string().nullable().required("Please select date"),
  service: Yup.array().nullable().min(1, "Please select service"),
  visitType: Yup.string().nullable().required("Please select visit type"),
})

export const cancelApptValidation = Yup.object({
  reason: Yup.string().nullable().required("Please enter reason"),
})
export const rescheduleApptValidation = Yup.object({
  clinic: Yup.string().nullable().required("Please select clinic"),
  date: Yup.string().required("Please select date"),
  startTime: Yup.string().required("Please select start time "),
  endTime: Yup.string().required("Please select end time"),
})

export const VitalValidation = Yup.object({
  bp: Yup.string().required("Please enter bp"),
  pulse: Yup.string().required("Please enter pulse"),
  weight: Yup.string().required("Please enter weight"),
  height: Yup.string().required("Please enter height"),
  temperature: Yup.string().required("Please enter temperature"),
})

export const addMedicineValidation = Yup.object({
  duration: Yup.string().required("Please enter duration"),
  quantity: Yup.string().required("Please select quantity"),
  note: Yup.string().required("Please enter note"),
})
// Additional Details/Vitals
export const addSurgeryValidation = Yup.object({
  name: Yup.string().required("Please enter name"),
  date: Yup.string().required("Please choose a date"),
  place: Yup.string().required("Please enter place"),
})
export const addLifeStyleValidation = Yup.object({
  name: Yup.string().required("Please enter habit").matches(pattern.WHITESPACE, "White space is not required"),
})


// Vaccination
export const CreaterecordValidation = Yup.object({
  category: Yup.string().required(" Please enter category").matches(pattern.WHITESPACE, "White space is not required"),
})
export const CreateCalendarFormValidation = Yup.object({
  calendarArray: Yup.array()
    .of(
      Yup.object().shape({
        vaccineName: Yup.string().nullable().required("Please enter vaccine name ").matches(pattern.WHITESPACE, "White space is not required"),
        brandName: Yup.string().nullable().required("Please enter  brand name ").matches(pattern.WHITESPACE, "White space is not required"),
        givenOn: Yup.string().nullable(),
        dueDate: Yup.string().nullable()
          .when('dueType', {
            is: (dueType) => dueType && dueType === "dueDate",
            then: Yup.string().nullable().required("Please enter due date  ").matches(pattern.WHITESPACE, "White space is not required"),
            otherwise: Yup.string().nullable().notRequired()
          }),
        dueType: Yup.string().nullable(),
        dueAge: Yup.string().nullable().when('dueType', {
          is: (dueType) => dueType && dueType === "dueAge",
          then: Yup.string().nullable().required("Please enter age  ").matches(pattern.WHITESPACE, "White space is not required").test('greater-than-or-equal-to-zero', 'Age  must be greater than zero', value => {
            const numericValue = Number(value);
            if (value) {
              return !isNaN(numericValue) && numericValue > 0;
            } else {
              return true
            }
          }),
          otherwise: Yup.string().nullable().notRequired()
        }),
        dueAgeDuration: Yup.string().nullable()
          .when('dueType', {
            is: (dueType) => dueType && dueType === "dueAge",
            then: Yup.string().nullable().required("Please select time frame"),
            otherwise: Yup.string().nullable().notRequired()
          }),
        comment: Yup.string().nullable().required("Please enter comment  ").matches(pattern.WHITESPACE, "White space is not required"),
        vaccinationSerialNumber: Yup.string().nullable().required("Please choose vaccination serial number"),
      })
    ),
  vaccinationFormName: Yup.string().nullable().required("Please enter form name").matches(pattern.WHITESPACE, "White space is not required"),
})

export const VaccinationFormVaidation = Yup.object({
  vaccineName: Yup.string().nullable().required("Please enter vaccine name ").matches(pattern.WHITESPACE, "White space is not required"),
  brandName: Yup.string().nullable().required("Please enter  brand name ").matches(pattern.WHITESPACE, "White space is not required"),
  givenOn: Yup.string().nullable(),
  dueDate: Yup.string().nullable()
    .when('dueType', {
      is: (dueType) => dueType && dueType === "dueDate",
      then: Yup.string().nullable().required("Please enter due date  ").matches(pattern.WHITESPACE, "White space is not required"),
      otherwise: Yup.string().nullable().notRequired()
    }),
  dueType: Yup.string().nullable(),
  dueAge: Yup.string().nullable()
    .when('dueType', {
      is: (dueType) => dueType && dueType === "age",
      then: Yup.string().nullable().required("Please enter age  ").matches(pattern.WHITESPACE, "White space is not required"),
      otherwise: Yup.string().nullable().notRequired()
    }),
  dueAgeDuration: Yup.string().nullable()
    .when('dueType', {
      is: (dueType) => dueType && dueType === "age",
      then: Yup.string().nullable().required("Please select time frame"),
      otherwise: Yup.string().nullable().notRequired()
    }),
  comment: Yup.string().nullable().required("Please enter comment  ").matches(pattern.WHITESPACE, "White space is not required"),
})

export const importVaccinationformValidation = Yup.object({
  form: Yup.array().min(1, "Please select vaccination form")
})

export const GivenDateValidation = Yup.object({
  givenOn: Yup.string().required("Please select date"),
})
//agenda
export const E_prescriptionValidation = Yup.object({
  duration: Yup.string().matches(/^[1-9][0-9]*$/, "Please enter a valid integer for duration").required("Please enter duration").test('greater-than-or-equal-to-zero', 'Duration  must be greater than zero', value => {
    const numericValue = Number(value);
    if (value) {
      return !isNaN(numericValue) && numericValue > 0;
    } else {
      return true
    }
  }),
  dose: Yup.string().matches(/^[1-9][0-9]*$/, "Please enter a valid integer for duration").required("Please enter duration").test('greater-than-or-equal-to-zero', 'Dose  must be greater than zero', value => {
    const numericValue = Number(value);
    if (value) {
      return !isNaN(numericValue) && numericValue > 0;
    } else {
      return true
    }
  }),
  quantity: Yup.string().required("Please select quantity"),
  rx: Yup.array().min(1, "Please select rx"),
  type: Yup.array().min(1, "Please select type"),
  unit: Yup.array().min(1, "Please select unit"),
  medCountType: Yup.string(),
  timeofmedicine: Yup.string(),
  timeofmedicinenumberForDays: Yup.number()
    .when(['medCountType', "timeofmedicine"], {
      is: (medCountType, timeofmedicine) => medCountType && medCountType === "CUSTOM" && timeofmedicine === "DAYS",
      then: Yup.number().required("")
        .min(1, `You must be at least `),
      // .max(30, ``),  
      otherwise: Yup.number().notRequired()
    }
    ),
  timeofmedicinenumberForHours: Yup.number()
    .when(['medCountType', "timeofmedicine"], {
      is: (medCountType, timeofmedicine) => medCountType && medCountType === "CUSTOM" && timeofmedicine === "HOURS",
      then: Yup.number().required("")
        .min(1, `You must be at least `),
      // .max(24, ``),
      otherwise: Yup.number().notRequired()
    }
    ),
  when: Yup.string(),
  whenCustom: Yup.string()
    .when('when', {
      is: (when) => when === "CUSTOM",
      then: Yup.string().required("Please enter"),
      otherwise: Yup.string().notRequired()
    })

})

export const addReportValidation = Yup.object({
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
})
export const addvaccinationValidation = Yup.object({
  category: Yup.array().min(1, "Please select category"),
  vaccinationForm: Yup.array().min(1, "Please select vaccination form")
})
export const addNewSymptomsValidation = Yup.object({
  Symptoms: Yup.string().required("Please enter symtom"),
})
export const addNewChronicDiseaseValidation = Yup.object({
  ChronicDisease: Yup.string().required("Please enter chronic disease"),
})
export const addNewTestsValidation = Yup.object({
  Tests: Yup.string().required("Please enter test"),
})
export const addNewAllergiesValidation = Yup.object({
  allergies: Yup.string().required("Please enter allergy"),
})


