import { useEffect, useState } from 'react';
import useReduxData from '../../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { RoleTitle } from '../../../Context/constants';
import moment from 'moment-timezone';
import { CLINIC_LIST } from '../../Repository/ProfileRepo';
import { GET_PATIENT_REPORT } from '../../Repository/InsightsRepo';

const usePatientReport = () => {
    const { reduxData: userdata } = useReduxData("userDataRDX")

    const [dateRangeDropdown, setDateRangeDropdown] = useState(false)
    const [dateFilter, setDateFilter] = useState('TODAY')
    // const [selectClinic, setSelectClinic] = useState([{ clinicName: "All", clinicId: "ALL_CLINCI" }])
    const [selectClinic, setSelectClinic] = useState([{ clinicName: userdata?.location?.clinicName, clinicId: userdata?.location?.clinicId }])
    const [clinicList, setClinicList] = useState([])
    const [Reports, setReports] = useState({})
    const [loader, setloader] = useState(false)
    const [action, setAction] = useState(false)
    const [sorting, SetSorting] = useState("TOTAL_BOOKING")
    const [customDate, setCustomDate] = useState({
        startDate: "",
        endDate: ""
    })
    const [appointmentFilter, setAppointmentFilter] = useState([
        { label: "Booked", value: "BOOKED" },
        { label: "Cancelled", value: "CANCELLED" },
        { label: "CheckedOut", value: "CHECKOUT" },
    ])
    const [filterPopup, setFilterPopup] = useState(false)
    const headCells = [
        { id: "Rank", numeric: false, disablePadding: true, label: "Rank" },
        { id: "Images", numeric: false, disablePadding: true, label: "Images" },
        { id: "Patient", numeric: true, disablePadding: false, label: "Patient" },
        { id: "Total Booking", numeric: true, disablePadding: false, label: "No. of Bookings" },
        { id: "Total Revenue", numeric: true, disablePadding: false, label: " Revenue Earned" },
    ];

    // get list clinic 
    const getClinicList = async () => {
        let payload = {
            activeStatus: true,
            staffId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.userId : undefined,
            providerId: (userdata.roleTitle === RoleTitle.STAFF || userdata.roleTitle === RoleTitle.FRONT_DESK) ? userdata?.providerId : userdata?.userId,
            roleTitle: userdata?.roleTitle
        }
        setloader(true)
        try {
            let response = await CLINIC_LIST(payload)
            setClinicList(response.data)
            setloader(false)
        } catch (error) {
            setloader(false)
            setClinicList([])
        }
    }
    const getPatientDetail = async () => {
        let payload = {
            providerId:userdata.roleTitle === RoleTitle.FRONT_DESK?userdata?.providerId: userdata.userId,
            clinicId: selectClinic[0]?.clinicId === "ALL_CLINCI" ? null : selectClinic[0]?.clinicId,
        }
        if (dateFilter === 'TODAY') {
            payload.startDate = moment().startOf('day')
            payload.endDate = moment().endOf('day')
        }
        if (dateFilter === 'LAST7') {
            payload.startDate = moment().startOf('day').subtract(7, 'days')
            payload.endDate = moment().endOf('day')
        }
        if (dateFilter === 'LAST30') {
            payload.startDate = moment().startOf('day').subtract(30, 'days')
            payload.endDate = moment().endOf('day')
        }
        if (dateFilter === 'CUSTOM' && customDate?.startDate === '' && customDate?.endDate === '') return toast.warn('Please select start date and end date ')
        if (dateFilter === 'CUSTOM' && customDate?.startDate === '') return toast.warn('Please select start date')
        if (dateFilter === 'CUSTOM' && customDate?.endDate === '') return toast.warn('Please select end date')
        if (customDate?.startDate > customDate?.endDate) return toast.warn('Start date can not be greater than end date ')
        else if (dateFilter === 'CUSTOM') {
            payload.startDate = moment(customDate?.startDate).startOf('day')
            payload.endDate = moment(customDate?.endDate).endOf('day')
        }
        setloader(true)
        setFilterPopup(false)

        try {
            let response = await GET_PATIENT_REPORT(payload)
            setloader(false)
            setReports(response?.data)
        } catch (error) {
            toast.error(error?.message)
            setReports({})
            setloader(false)
        }
    }
    useEffect(() => {
        getPatientDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])
    useEffect(() => {
        getClinicList()
        window.addEventListener("click", () => {
            setDateRangeDropdown(false)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return {
        loader,
        dateFilter,
        selectClinic,
        dateRangeDropdown,
        Reports,
        headCells,
        clinicList,
        setDateRangeDropdown,
        setSelectClinic,
        setDateFilter,
        filterPopup,
        setFilterPopup,
        getPatientDetail,
        customDate,
        setCustomDate,
        setAction,
        userdata,
        sorting,
        SetSorting,
        setAppointmentFilter,
        appointmentFilter,
    }
}

export default usePatientReport
