import React, { useEffect, useRef, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal';
import { TableContainer } from '@mui/material';
import { Peginationcomponent } from '../../Component/Peginationcomponent';
import Loading from '../../Component/Loading';
import useTableSorting from '../../Hooks/useTableSorting';
import useReduxData from '../../Hooks/useReduxData';
import { toast } from 'react-toastify';
import { NoRecordFound } from '../../Component/NoRecordFound';
import useMuiCompontent from '../../Hooks/useMuiCompontent';
import { RoleTitle } from '../../Context/constants';
import { UPDATE_VACCINATION_CALENDAR_PATIENT, VACCINATION_CALENDAR_PATIENT } from '../Repository/PatientRepo';
import viewIcon from "../../Assets/Icons/MicrosoftTeams-image (2).png"
import downloadIcon from "../../Assets/Icons/MicrosoftTeams-image.png"
import PrintIcon from "../../Assets/Icons/MicrosoftTeams-image (1).png"
import useHtml2canvas from '../../Hooks/useHtml2canvas';
import { useReactToPrint } from 'react-to-print';
import { VaccinationImageFilePath } from '../../Context/api';
const VaccineCalender = ({ type }) => {
    const { id, subpatientId } = useParams()
    const [appointmentData, setAppointmentData] = useState([])
    const [vaccinationSerialNumberImage, setVaccinationSerialNumberImage] = useState(false)
    const [vaccinationSerialNumberPath, setVaccinationSerialNumberPath] = useState(null)
    const [removeButton, setremoveButton] = useState(false)
    const [count, setCount] = useState(null)
    const [controller, setController] = useState({ page: 1, rowsPerPage: 10 });
    const [loader, setloader] = useState(false)
    const { reduxData: userData } = useReduxData("userDataRDX")
    const { reduxData: MemberType, } = useReduxData("SubMemberTypeRDX")
    const { contentRef, downloadContent } = useHtml2canvas()
    const { CustomButton } = useMuiCompontent()
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    const [openModal, setOpenModal] = useState({
        viewVaccinationForm: false,
        downloadVaccinationForm: false,
        printVaccinationForm: false,
        data: null
    })
    useEffect(() => {
        appointments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller])
    useEffect(() => {
        if (removeButton) {
            if (openModal.downloadVaccinationForm) {
                downloadContent()
                handleClosedModal("downloadVaccinationForm")
            }
            if (openModal.printVaccinationForm) {
                handlePrint()
                handleClosedModal("printVaccinationForm")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeButton])

    const appointments = async () => {
        let payload = {
            patientId: type === "Patients" ? id : MemberType ? subpatientId : subpatientId,
            providerId: (userData.roleTitle === RoleTitle.STAFF || userData.roleTitle === RoleTitle.FRONT_DESK) ? userData?.providerId : userData?.userId,
            frontdeskId: userData.roleTitle === RoleTitle.FRONT_DESK ? userData?.userId : undefined,
        }
        setloader(true)
        try {
            let response = await VACCINATION_CALENDAR_PATIENT(payload)
            setAppointmentData(response?.data.map((cur) => {
                return { ...cur, doctorName: `${cur?.Doctor} ${cur?.doctorLastName}` }
            }))
            setloader(false)
            setCount(response.totalCount)
        } catch (error) {
            setAppointmentData([])
            setloader(false)
            setCount(0)
        }
    }

    const handleChagneGivenon = (e, formIndex) => {
        setOpenModal((pre) => ({
            ...pre, data: {
                ...pre?.data, vaccinationFormFieldArray: pre.data.vaccinationFormFieldArray.map((cur, index) => {
                    if (formIndex === index) {
                        return { ...cur, newGivenOn: e.target.value }
                    }
                    return cur
                })
            }
        }))

    }
    const handleSubmitVaccination = async () => {
        let payload = {
            vaccinationFormArray: openModal?.data?.vaccinationFormFieldArray?.map((cur, index) => {
                if (cur?.newGivenOn) {
                    return { ...cur, givenOn: cur?.newGivenOn, newGivenOn: undefined }
                }
                return cur
            })
        }
        setloader(true)
        try {
            let response = await UPDATE_VACCINATION_CALENDAR_PATIENT(payload)
            toast.success(response.message)
            setloader(false)
            handleClosedModal("viewVaccinationForm")
            appointments()
        } catch (error) {
            setloader(false)
            toast.error(error.message)
        }
    }

    const headCells = [
        { id: "apptNumber", numeric: false, disablePadding: true, label: "Appointment No", showIcon: true },
        { id: "apptStartDate", numeric: false, disablePadding: true, label: "Date", showIcon: true },
        { id: "time", numeric: true, disablePadding: false, label: "Time" },
        { id: "doctorName", numeric: true, disablePadding: false, label: "Doctor", showIcon: true },
        { id: "action", numeric: false, disablePadding: false, label: "Action", showLeft: true }

    ];
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    const handleOpenModal = (type, data = null) => {
        setOpenModal((prev) => ({
            ...prev,
            data: data,
            [type]: true,
        }));
    };
    const handleClosedModal = (type) => {
        setremoveButton(false)
        setOpenModal((pre) => ({
            ...pre,
            data: null,
            [type]: false
        }))
    }

    return (
        <>
            {loader && <Loading />}
            <div className=" d-flex flex-column justify-content-between " style={{ height: "100%" }}>
                <div >
                    <TableContainer >
                        {appointmentData?.length === 0 &&
                            <div className="col-sm-12 mt-3 text-center " >
                                <NoRecordFound />
                            </div>
                        }
                        <Table
                            id="table_mui"
                            aria-labelledby="tableTitle"
                            size={"medium"}
                            aria-label="enhanced table"
                        >
                            {
                                appointmentData.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={appointmentData.length} />
                            }
                            <TableBody>
                                {
                                    stableSort(appointmentData, getComparator(order, orderBy)).map(
                                        (row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return <React.Fragment key={row?.vaccinationFormId}><TableRow hover id="hover_tr">
                                                <TableCell id={labelId} align="left">{row.appointmentNumber}</TableCell>
                                                <TableCell className="ps-3" id={labelId} padding="none" >
                                                    {moment(row.startDate).tz(userData?.timezoneName).format('ll')}
                                                </TableCell>
                                                <TableCell id={labelId} align="left" >{moment(row.startDate).tz(userData?.timezoneName).format('LT')} - {moment(row.endDate).tz(userData?.timezoneName).format('LT')}</TableCell>
                                                <TableCell id={labelId} align="left">{row.doctorName}</TableCell>
                                                <TableCell id={labelId} align="right">
                                                    <img src={viewIcon} alt="" className='cursor' onClick={() => {
                                                        handleOpenModal("viewVaccinationForm", row);
                                                        //  navigate(`edit/${row?.vaccinationFormFieldId}`)
                                                    }} width={18} height={18} title='view' />
                                                    <img src={downloadIcon} alt="" className='mx-1 cursor' width={18} height={18} title='Download'
                                                        onClick={() => {
                                                            handleOpenModal("downloadVaccinationForm", row);
                                                        }} />
                                                    <img src={PrintIcon} alt="" className='cursor' width={18} height={18} title='Print'
                                                        onClick={() => {
                                                            handleOpenModal("printVaccinationForm", row);
                                                        }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            </React.Fragment>
                                        }
                                    )
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className='w-100 3'>
                        {count > 10 && <Peginationcomponent count={count} controller={controller} setController={setController} />}
                    </div>
                </div>
            </div>

            {/* ModalView  */}
            <Modal size='xl' className='px-0' show={openModal.viewVaccinationForm || openModal.downloadVaccinationForm || openModal.printVaccinationForm}
                onHide={() => { handleClosedModal(openModal.viewVaccinationForm ? "viewVaccinationForm" : openModal.downloadVaccinationForm ? "downloadVaccinationForm" : openModal.printVaccinationForm ? "printVaccinationForm" : "") }} centered>
                <Modal.Body className='p-0'>
                    <div className="modal-body p-0">
                        <div className="VACCINATION_FORM_VIEW px-0 " ref={openModal.downloadVaccinationForm ? contentRef : componentRef}>
                            <div className="col-12 HEARDER  d-flex justify-content-center align-items-center">
                                <h4 className='mb-0 text-white'>{openModal?.data?.formName}</h4>
                            </div>
                            <div className=" col-12 CONTENT p-5 d-flex flex-column justify-content-between">
                                <div className="row"  >
                                    <div className="col-7  d-flex flex-wrap">
                                        <div className='' style={{ width: "33%" }}>
                                            <p className='mb-0 fw-bold'>Patient Name</p>
                                            <p className='mb-0  text-secondary'>{(openModal?.data?.PatientName ? openModal?.data?.PatientName : "") + " " + (openModal?.data?.PatientLastName ? openModal?.data?.PatientLastName : "")}</p>
                                        </div>
                                        <div className='' style={{ width: "33%" }}>
                                            <p className='mb-0 fw-bold'>Age</p>
                                            <p className='mb-0  text-secondary'>{openModal?.data?.patientage}</p>
                                        </div>
                                        <div className='' style={{ width: "33%" }}>
                                            <p className='mb-0 fw-bold'>Patient ID</p>
                                            <p className='mb-0  text-secondary'>{openModal?.data?.patientIdforForm ? openModal?.data?.patientIdforForm : "--"}</p>
                                        </div>
                                        <div className='mt-3' style={{ width: "33%" }}>
                                            <p className='mb-0 fw-bold'>Date of Birth</p>
                                            <p className='mb-0  text-secondary'> {moment(openModal?.data?.patientDob).format("ll")}</p>
                                        </div>
                                        <div className='mt-3' style={{ width: "33%" }}>
                                            <p className='mb-0 fw-bold'>Gender</p>
                                            <p className='mb-0  text-secondary'>{openModal?.data?.PatientGender}</p>
                                        </div>
                                    </div>
                                    <p className='mb-0 fw-bold mt-4'>Vaccination Record</p>
                                    <div className="col-12 mt-2 tab_scroll">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Vaccine Name</th>
                                                    <th scope="col">Brand Name</th>
                                                    <th scope="col">Given on</th>
                                                    <th scope="col">Due</th>
                                                    <th scope="col">Comments</th>
                                                    <th scope="col">Vaccination Serial Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {openModal?.data?.vaccinationFormFieldArray?.map((cur, index) => {
                                                    return <>
                                                        <tr>
                                                            <th className='text-center'><input className="form-check-input cursor" type="checkbox" disabled checked={cur?.givenOn ? true : false} id="flexCheckDefault" /></th>
                                                            <td>{cur?.vaccineName}</td>
                                                            <td>{cur?.brandName}</td>
                                                            <td>{cur?.givenOn ? cur?.givenOn :
                                                                <label id="DatePicker" className="position-relative" >
                                                                    <span className='text-primary'> <u> {cur?.newGivenOn ? cur?.newGivenOn : "Given On"} </u> </span>
                                                                    {
                                                                        openModal.viewVaccinationForm && <input type="date" className="DATEPIKER_NATIVE_INPUT" onChange={(e) => handleChagneGivenon(e, index)} />
                                                                    }
                                                                </label>
                                                            }
                                                            </td>
                                                            <td>{cur?.dueType === "dueAge" ? `${cur?.dueAge} ${cur?.dueAgeDuration}` : cur?.dueDate}</td>
                                                            <td>{cur?.comment} </td>
                                                            <td className="VACCINATION_SERIAL_NUMBER_IMAGE">
                                                                {cur?.vaccinationSerialNumber ? <img onClick={() => { setVaccinationSerialNumberImage(true); setVaccinationSerialNumberPath(cur?.vaccinationSerialNumber) }} width={200} height={30} style={{ objectFit: 'cover' }} className="cursor" src={VaccinationImageFilePath + cur?.vaccinationSerialNumber} alt="" /> : "--"}
                                                            </td>
                                                        </tr>
                                                    </>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                    <p style={{ color: '#5C6881', fontSize: "12px", }}>Please keep this record card, it includes the medical information, details and the vaccine you have received. This card will show the next schedule of your vaccine. It is important to show this card to the next vaccination schedule for health officials to verify.</p>
                                </div>
                                <div className="row">
                                    <div className=" col-12 d-flex justify-content-end">
                                        {
                                            openModal.viewVaccinationForm && openModal?.data?.vaccinationFormFieldArray?.some((curValue) => curValue?.newGivenOn) ? <CustomButton variant="contained" onClick={handleSubmitVaccination}   >submit</CustomButton> :
                                                openModal.downloadVaccinationForm ? <CustomButton variant="contained" onClick={() => { setremoveButton(true) }} style={{ display: removeButton ? "none" : "bloack" }}  >download</CustomButton> :
                                                    openModal.printVaccinationForm ? <CustomButton variant="contained" onClick={() => setremoveButton(true)} style={{ display: removeButton ? "none" : "bloack" }}  >Print</CustomButton> : ""
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 FOOTER"></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal size={`md`} show={vaccinationSerialNumberImage} onHide={() => { setVaccinationSerialNumberImage(false); setVaccinationSerialNumberPath(null) }} centered>
                <Modal.Body className="p-0" style={{ height: `400px` }}>
                    <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={VaccinationImageFilePath + vaccinationSerialNumberPath} alt="" />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default VaccineCalender
