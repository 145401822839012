import  { useEffect, useState } from 'react'
const useMinimumDate = () => {
    const [minimumdate, setMinimumdate] = useState(null)
    useEffect(() => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        today = yyyy + '-' + mm + '-' + dd;
        setMinimumdate(today)
    }, [])
    return {
        minimumdate
    }
}
export default useMinimumDate

