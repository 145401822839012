import React from 'react'
import TablePagination from '@mui/material/TablePagination';
export const Peginationcomponent = ({ count, setController, controller }) => {

    const handleChangePage = (event, newPage) => {
        setController({
            ...controller,
            page: newPage + 1
        });
    };
    const handleChangeRowsPerPage = (event) => {
        setController({
            ...controller,
            rowsPerPage: parseInt(event.target.value),
            page: 0 + 1
        });
    };
    return (
        <TablePagination
            sx={{
                '.MuiTablePagination-toolbar': {
                    color: 'rgb(41, 39, 39)',
                    height: '35px',
                    display: "flex",
                    alignItems: "baseline"
                },
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            component="div"
            count={count}
            rowsPerPage={controller.rowsPerPage}
            page={controller.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    )
}
