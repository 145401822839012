import React from "react";

import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/LOGO 1 (1) 1.png"
import useReduxData from "../../../Hooks/useReduxData";
import { AddBusinessDetails } from "../../../Redux/ReducerFile/BusinessDetails";
import { AddBasicDetails } from "../../../Redux/ReducerFile/BasicDetails";
import { AddClinicFildData } from "../../../Redux/ReducerFile/ClinicFildData";

const AllDetails = () => {
  const navigate = useNavigate()
  // const [open, setopen] = useState({
  //   Appointments: false,
  //   setting: false
  // })
  const { pathname } = useLocation();
  const splitLocation = pathname.split("/");
  const { reduxData: BasicDetailsData, dispatch } = useReduxData("BasicDetailsRDX")
  // useEffect(() => {
  //   let li = document.querySelectorAll(".sidebar .nav-links .close_line")
  //   for (var i = 0; i < li.length; i++) {
  //     li[i].addEventListener("click", (e) => {
  //       open.setting = false
  //       open.Appointments = false
  //       setopen({ ...open })
  //     });
  //   }
  // }, [])
  const navigateFun = (type) => {
    if (type === "Basic_Details") {
      navigate("/alldetails/basic-details")
    }
    if (type === "Business_Details") {
      if (BasicDetailsData?.firstName) {
        navigate("/alldetails/business-details")
      }
    }
  }
  const logout = () => {
    navigate("/login")
    localStorage.clear()
    dispatch(AddBusinessDetails({}))
    dispatch(AddBasicDetails({}));
    dispatch(AddClinicFildData({}));
  }
  return (
    <>
      <nav className="navbar fixed-top shadow" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          <img alt="logo" src={logo} width="62px" height="50px" />
          <form className="d-flex" role="search">
            <i className='bx bx-bell'></i>
          </form>
        </div>
      </nav>
      <div className={`sidebar `}>
        <ul className="nav-links shadow">
          <li className="meun_hover mt-3 ">
            <NavLink to="#">
            </NavLink>
          </li>
          <li className={`${splitLocation[2] === "basic-details" ? "isactive" : ""} close_line`} onClick={() => navigateFun("Basic_Details")}>
            <div className="d-flex  align-items-center" style={{ cursor: "pointer" }}>
              <i className='bx bx-grid-alt'></i>
              <span className="link_name">Basic Details</span>
            </div>

          </li>
          <li className={`${splitLocation[2] === "business-details" ? "isactive" : ""} close_line`} onClick={() => navigateFun("Business_Details")}>
            <div className="d-flex  align-items-center" style={{ cursor: "pointer" }} >
              <i className='bx bxs-business'></i>
              <span className="link_name ">Business Details</span>
            </div>
          </li>
          <div className="profile-details-signup" style={{ zIndex: 1, backgroundColor: "#fff !important" }} onClick={() => logout()}>
            <div className="profile-content border-0">
              <img src={require("../../../Assets/Images/dummyimg.png")} alt="" />
            </div>
            <div className="name-job">
              <div className="profile_name"> <p>Logout</p></div>
            </div>
            <i className='bx bx-log-out' id="" ></i>
          </div>
        </ul>
      </div>
      <section className="home-section " >
        <div className="home-content">
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default AllDetails;

