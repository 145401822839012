import { useSelector, useDispatch } from 'react-redux'
const useReduxData = (userDataRDX) => {
    const dispatch = useDispatch()
    let reduxData = useSelector((store) => store[userDataRDX])
    return {
        dispatch,
        reduxData
    }
}
export default useReduxData
