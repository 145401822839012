import React from "react";
import { activeStatusOption } from "../../Context/constants";
import Loading from "../../Component/Loading";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IconButton, TableContainer, } from "@mui/material";
import { Peginationcomponent } from "../../Component/Peginationcomponent";
import useTableSorting from "../../Hooks/useTableSorting";
import useSpecialization from "./Hooks/useSpecialization";
import userLogo from "../../Assets/Images/dummyimg.png"
import { SpecializationImageFilePath } from "../../Context/api";
import { BiArchiveIn } from "react-icons/bi";
import { NoRecordFound } from "../../Component/NoRecordFound";
import { FiRefreshCcw } from "react-icons/fi";
import { SelectBox } from "../../Component/Inputs";
import { AddSpecializationModal, ArchiveModal, EditSpecializationModal } from "./Component/Specialization";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { IoMdAdd } from "react-icons/io";
import MoreVertIcon from '@mui/icons-material/MoreVert';
const Specialization = () => {
    const {
        headCells,
        loader,
        showEdit,
        showAdd,
        addSpecialization,
        updateSpecialization,
        imgUrl,
        SpecializationListdata,
        paginationTotalCount,
        archivedata,
        showArchiv,
        controller,
        Filter,
        CustomButton,
        previoursImage,
        getspecializationList,
        setprevioursImage,
        setShowAdd,
        setImgUrl,
        upadteservices,
        setShowArchiv,
        archiveUnarchive,
        UploadImage,
        setShowEdit,
        setArchiveData,
        setController,
        reset,
        handleChangeFilter,
        HandleSearchList,
        setLoader
    } = useSpecialization()
    const { EnhancedTableHead, order, orderBy, stableSort, getComparator, handleRequestSort } = useTableSorting(headCells)
    return (
        <>
            {loader && <Loading />}
            <div className="py-3  d-flex flex-column justify-content-between ">
                <div>
                    <header className="body_header" >
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between w-100">
                                <ul className="headerlist px-0">
                                    <li className="phone_hide me-2">
                                        <input type="text" name="specialization" onChange={handleChangeFilter} value={Filter?.specialization} placeholder="Search" className="form-control filter_input" />
                                    </li>
                                    <li className="me-2" style={{ width: 150 }}>
                                        <SelectBox
                                            placeholder="Select"
                                            value={Filter?.archive}
                                            name="archive"
                                            keys="value"
                                            label="label"
                                            menuValeu="value"
                                            handleChange={handleChangeFilter}
                                            option={activeStatusOption}
                                        />
                                    </li>
                                    <li className="phone_hide me-2">
                                        <CustomButton variant="contained" disabled={Filter?.disable_button} onClick={HandleSearchList} >search </CustomButton>
                                    </li>
                                    <li className="d-flex justify-content-center align-items-center me-2" >
                                        <IconButton onClick={reset} size="small" aria-label="delete" disabled={Filter?.disable_button} style={{ color: "#000", cursor: "pointer" }}>
                                            <FiRefreshCcw />
                                        </IconButton>
                                    </li>
                                    <li >
                                        <CustomButton variant="contained" onClick={() => { setShowAdd(true); setImgUrl(null) }} startIcon={<IoMdAdd />} > Add Specialization</CustomButton>
                                    </li>
                                </ul>
                                {/* <ul className="headerlist px-0 phone_hide">
                                    <li>
                                        <div className="dropdown-center ms-2">
                                            <CustomButton variant="contained"
                                                style={{ background: "#f5f5f5", color: "black" }}
                                                startIcon={<ExitToAppIcon />}
                                            >export</CustomButton>
                                        </div>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </header>
                    <TableContainer  >
                        {SpecializationListdata?.length === 0 && <div className="col-sm-12 mt-3 text-center " > <NoRecordFound /> </div>}
                        <Table id="table_mui" aria-labelledby="tableTitle" size={"medium"} aria-label="enhanced table">
                            {
                                SpecializationListdata?.length !== 0 && <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={SpecializationListdata?.length} />
                            }
                            <TableBody >
                                {stableSort(SpecializationListdata, getComparator(order, orderBy)).map(
                                    (row, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return <React.Fragment key={index}><TableRow id="hover_tr"  >
                                            <TableCell style={{ cursor: "pointer" }} className="ps-3" id={labelId} scope="row" padding="none" >
                                                {!row.image ? <img alt={""} src={userLogo} width={45} height={45} style={{ borderRadius: "50%" }} /> : <img alt={""} src={SpecializationImageFilePath + row.image} width={45} height={45} style={{ borderRadius: "50%" }} />}
                                            </TableCell>
                                            <TableCell style={{ cursor: "pointer" }} id={labelId} align="left" >{row.specializationName}  </TableCell>
                                            <TableCell id={labelId} align="right">
                                                <MoreVertIcon type="button" data-bs-toggle="dropdown" aria-expanded="false" />
                                                <ul className="dropdown-menu dropdown-menu-end me-3">
                                                    <li><button className="dropdown-item"
                                                        onClick={() => {
                                                            upadteservices({ specializationId: row.specializationId, archive: row.activeStatus })
                                                        }} ><i className="fa-solid fa-pen-to-square all_icons_color me-2"></i>Edit Speciazation</button></li>
                                                    <li><button className="dropdown-item"
                                                        onClick={() => {
                                                            setShowArchiv(true); setArchiveData({ specializationId: row.specializationId, archive: row.activeStatus })
                                                        }}><BiArchiveIn className="all_react_icons_color me-2" />{`${row?.activeStatus === true ? "Archive" : "Unarchive"} Speciazation`}</button></li>
                                                </ul>
                                            </TableCell>
                                        </TableRow>
                                        </React.Fragment>
                                    }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <nav className="w-100" aria-label="pagination">
                    {
                        paginationTotalCount > 10 && <Peginationcomponent count={paginationTotalCount} controller={controller} setController={setController} />
                    }
                </nav>
            </div>
            <ArchiveModal setLoader={setLoader} archivedata={archivedata} showArchiv={showArchiv} setShowArchiv={setShowArchiv} archiveUnarchive={archiveUnarchive} CustomButton={CustomButton} />
            <AddSpecializationModal previoursImage={previoursImage} setprevioursImage={setprevioursImage} setLoader={setLoader} CustomButton={CustomButton} showAdd={showAdd} setShowAdd={setShowAdd} addSpecialization={addSpecialization} imgUrl={imgUrl} UploadImage={UploadImage} setImgUrl={setImgUrl} SpecializationImageFilePath={SpecializationImageFilePath} />
            <EditSpecializationModal getspecializationList={getspecializationList} previoursImage={previoursImage} setprevioursImage={setprevioursImage} setLoader={setLoader} showEdit={showEdit} setShowEdit={setShowEdit} imgUrl={imgUrl} UploadImage={UploadImage} SpecializationImageFilePath={SpecializationImageFilePath} setImgUrl={setImgUrl} updateSpecialization={updateSpecialization} CustomButton={CustomButton} />
        </>
    );
};

export default Specialization;