import React, { memo } from 'react'
import { Modal } from 'react-bootstrap'
import { Errors } from '../../../Component/Error'

const CancelAppointment = ({ modalCancel, clossOffcanvas, cancelAppointment, CustomButton }) => {
  return (
    <Modal modal="true" show={modalCancel} onHide={() => clossOffcanvas("CANCEL_APPOINTMENT")} centered >
      <Modal.Body>
        <div className="modal-body text-center cus-i">
          <i className="fa-solid fa-circle-xmark all_icons_color mb-2"></i>
          <p className="mb-0">Are you sure?</p>
          <p>You want to cancel this appointment</p>
          <textarea placeholder='Enter Reason' className="form-control" cols="30" rows="3" name="reason" value={cancelAppointment.values.reason} onChange={cancelAppointment.handleChange}  ></textarea>
          <div className='text-start'>
            <Errors formikfun={cancelAppointment} name={"reason"} />
          </div>
          <CustomButton variant="outlined" className="me-3 mt-3" onClick={() => clossOffcanvas("CANCEL_APPOINTMENT")} >No</CustomButton>
          <CustomButton variant="contained" className="mt-3" onClick={cancelAppointment.handleSubmit} >Yes</CustomButton>
        </div>
      </Modal.Body>
    </Modal >
  )
}

export default memo(CancelAppointment)
